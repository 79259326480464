<template lang="pug">
  mp-multiselect(
    :options='statuses',
    :track-by='null',
    :label='null',
    :searchable='false',
    :allow-empty='false',
    v-model='status')
</template>

<script>
import MpMultiselect from "./MpMultiselect.vue";

export default {
  components: {
    MpMultiselect
  },
  props: {
    type: {
      type: String,
      default: "task",
      validator: value =>
        ["task", "mp_milestone", "project"].indexOf(value) !== -1
    },
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    statuses() {
      if (this.type === "mp_milestone") {
        return ["Not started", "Complete"];
      }

      return ["Not started", "Started", "Complete"];
    },
    status: {
      get() {
        return this.value;
      },
      set(newStatus) {
        this.$emit("input", newStatus);
      }
    }
  }
};
</script>
