export default [
  {
    label: "Urgent",
    value: "URGENT",
    icon: "warning",
    iconColour: "var(--withered-cherry)"
  },
  {
    label: "High",
    value: "HIGH",
    icon: "warning",
    iconColour: "var(--laughing-amber)"
  },
  {
    label: "Normal",
    value: "NORMAL",
    icon: "normalPriority",
    iconColour: "var(--tribal-aqua)"
  },
  {
    label: "Low",
    value: "LOW",
    icon: "lowPriority",
    iconColour: "var(--grey-80)"
  }
];
