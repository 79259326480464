<template lang="pug">
  app-panel.panel.subscription-card.mb-4(v-if="!subscription.isTrial")
    h2.font-bold.mb-4 Billing and tax
    .flex.flex-row.text-grey-60
      .flex-initial.mr-4
        div Name
        .font-bold {{fullName}}

      .flex-1
        div Address
        .font-bold {{address}}

    .mt-4(v-if="subscription.managedByStripe")
      a(href="#", @click.prevent="onSubmit" data-cy="open-billing-submit") Update billing details

    .h-px.bg-grey-30.my-4

    .flex.flex-row.text-grey-60
      .flex-initial.mr-4.break-all.bill-td
        div Company Name
        .font-bold {{company.name}}

      .flex-initial.mr-4.break-all.bill-td
        div Company number
        .font-bold {{companyNumber}}

      .flex-1.bill-td
        div Tax number
        .font-bold {{taxNumber}}

    .mt-4(v-if="subscription.plan.name.toLowerCase() != 'free' && subscription.managedByStripe")
      a(href="#", @click.prevent="$emit('open-tax')") Update tax information

</template>

<script>
export default {
  name: "SubscriptionBill",
  props: {
    subscription: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    company: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      payment: {
        price: 471.6,
        lastDigits: 4234
      }
    };
  },
  computed: {
    fullName() {
      const { billingForename, billingSurname } = this.subscription;
      const name = billingForename ? billingForename : "";
      const fullName = billingSurname ? `${name} ${billingSurname}` : name;
      return fullName.trim() === "" ? "--" : fullName;
    },
    address() {
      const {
        billingAddress1,
        billingAddress2,
        billingAddressCity,
        billingAddressRegion,
        billingAddressPostcode,
        billingAddressCountry
      } = this.subscription;

      const countryCode = billingAddressCountry
        ? billingAddressCountry.code
        : "";
      if (!billingAddress1) return "--";

      const zipcode = billingAddressRegion
        ? `${billingAddressRegion} ${billingAddressPostcode}`
        : `${billingAddressPostcode}`;
      const address = billingAddress2
        ? `${billingAddress1}, ${billingAddress2}, ${billingAddressCity}, ${zipcode}, ${countryCode}`
        : `${billingAddress1}, ${billingAddressCity}, ${zipcode}, ${countryCode}`;

      return address.length > 0 ? address : "--";
    },
    companyNumber() {
      const { companyNumber } = this.subscription;
      return companyNumber && companyNumber.length > 4
        ? "*".repeat(companyNumber.length - 4) +
            companyNumber.slice(companyNumber.length - 4, companyNumber.length)
        : "";
    },
    taxNumber() {
      const { taxNumber } = this.subscription;
      return taxNumber && taxNumber.length > 4
        ? "*".repeat(taxNumber.length - 4) +
            taxNumber.slice(taxNumber.length - 4, taxNumber.length)
        : "";
    }
  },
  methods: {
    onSubmit() {
      this.$emit("submit");
    }
  }
};
</script>
<style scoped>
.bill-td {
  min-width: 110px;
}
</style>
