<script>
import { Components } from "manageplaces-ui-kit";

const getOption = (h, { option }) => {
  const icon = h(Components.AppIcon, {
    props: { icon: option.icon, size: "medium" },
    staticClass: "mr-8"
  });
  const title = h("span", { staticClass: "font-bold" }, option.label);
  const desc = h("span", { staticClass: "text-grey-60" }, option.description);

  const content = h("div", { staticClass: "flex-1 flex flex-col" }, [
    title,
    desc
  ]);
  return h(
    "div",
    {
      staticClass: "flex flex-row cursor-pointer help-item",
      on: {
        click: () => {
          window.open(option.href);
        }
      }
    },
    [icon, content]
  );
};

const getTitle = h => {
  return h("h3", { staticClass: "font-bold" }, "How can we help?");
};

export default {
  functional: true,
  render(h) {
    const options = [
      {
        type: "content",
        slot: "title"
      },
      { type: "divider" },
      {
        type: "content",
        slot: "option",
        label: "Knowledge base",
        description:
          "Find short articles on how to set up your account and how to best use it to your advantage.",
        href: "https://support.manageplaces.com",
        icon: "globe"
      },
      { type: "divider" },
      {
        type: "content",
        slot: "option",
        label: "ManagePlaces YouTube channel",
        description:
          "Watch our short video tutorials on how our platform works and how to use it more effectively.",
        href: "https://www.youtube.com/channel/UCf8329dOZdgWwpJio5VM_aw",
        icon: "youtube"
      },
      { type: "divider" },
      {
        type: "content",
        slot: "option",
        label: "Create a support ticket",
        description:
          "If you don’t find the information you’re looking for, you can always contact us by creating a ticket.",
        href: "https://desk.zoho.eu/portal/manageplaces/en/newticket",
        icon: "support"
      }
    ];

    const icon = h(Components.AppIcon, {
      staticClass: "cursor-pointer",
      props: { icon: "question", colour: "white", size: "medium" }
    });
    const dropdown = h(Components.AppDropdown, {
      staticClass: "help-menu",
      props: { options: options, pointing: true },
      scopedSlots: {
        trigger: () => icon,
        option: props => getOption(h, props),
        title: () => getTitle(h)
      }
    });

    return dropdown;
  }
};
</script>

<style lang="postcss" scoped>
.help-item {
  width: 28rem;
}
</style>
