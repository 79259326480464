<template lang="pug">
  app-panel.checklist.mb-4(:loading="!task" secondary)
    template(#header)
      .flex.justify-between.cursor-pointer(@click="toggleExpanded")
        app-header(size="h3" :margin="expanded ? 'mb-2' : ''" )
          | Checklist
          template(v-if="expanded" slot="subheader") {{ subheader }}
        app-icon(:icon="collapseIcon")

    template(#default)
      .flex.flex-col(v-show="expanded")
        template(v-if="hasChecklist")
          .mb-4(class="w-1/2")
            app-progress(:max="checklistLength", :progress="completedItems")

          draggable(
            v-model="internalTask.checklistItems"
            handle=".handle"
            @start="dragStarted"
            @end="dragEnded"
            @change="reordered")

            transition-group.relative(:name="drag ? 'no' : 'checklist-items'", @before-leave="beforeLeave", type="transition")
              task-checklist-item.checklist-item(
                v-for="(item, idx) in internalTask.checklistItems"
                :key="item.id"
                :class="idx === checklistLength - 1 ? '' : 'border-b border-1 border-grey-30'"
                :can-edit="internalTask.canEdit"
                :value="item"
                @toggled="itemToggled(item)"
                @delete="deleteItem(item)",
                @input="changeName(item, $event)")


        template(v-else)
          p.py-8 A checklist will let you track individual steps required to complete this task.

        template(v-if="internalTask && internalTask.canEdit")
          app-button.mt-8.self-start(v-if="!editing", primary, @click="editing = true") Add checklist item

          template(v-else)
            .h-16.flex.flex-row.items-center.mt-8
              .flex-1
                app-text-input.w-full(
                  v-model="newItem.name"
                  v-focus
                  :error="$v.newItem.name.$error"
                  @keyup.enter="addItem"
                  @keyup.esc="stopEditing")

            .flex.flex-row.items-center(ref="newItemActions")
              app-button(primary, @click="addItem", :loading="creatingItem", loading-text="Saving..." slim) Save item
              app-button.ml-4(@click="stopEditing" slim) Cancel

</template>

<script>
import clone from "ramda/src/clone";
import { requiredIf } from "vuelidate/lib/validators";
import AddChecklistItem from "@/graphql/mutations/tasks/AddChecklistItem.gql";
import DeleteChecklistItem from "@/graphql/mutations/tasks/DeleteChecklistItem.gql";
import MoveChecklistItem from "@/graphql/mutations/tasks/MoveChecklistItem.gql";
import SetChecklistItemName from "@/graphql/mutations/tasks/SetChecklistItemName.gql";
import TaskChecklistItem from "./checklist/TaskChecklistItem.vue";
import ToggleChecklistItem from "@/graphql/mutations/tasks/ToggleChecklistItem.gql";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import CollapsableSection from "@/mixins/CollapsableSection.js";

export default {
  components: {
    TaskChecklistItem
  },

  mixins: [CollapsableSection],

  props: {
    task: {
      type: Object,
      required: false,
      default: null
    }
  },

  data() {
    return {
      internalTask: clone(this.task),
      creatingItem: false,
      drag: false,
      editing: false,
      editingItem: null,
      expanded: this.task?.checklistItems?.length ? true : null,
      newItem: {
        name
      }
    };
  },

  computed: {
    editButtonText() {
      if (this.hasChecklist) {
        return "Edit checklist";
      }

      return "Add checklist";
    },

    hasChecklist() {
      if (!this.internalTask) {
        return false;
      }

      return this.checklistLength > 0;
    },

    checklistLength() {
      return this.internalTask.checklistItems.length;
    },

    completedItems() {
      return this.internalTask.checklistItems.filter(item => item.completed)
        .length;
    },

    subheader() {
      if (this.hasChecklist) {
        return `${this.completedItems} out of ${this.pluralised(
          this.checklistLength
        )} completed`;
      } else {
        return "This task does not have a checklist";
      }
    }
  },

  validations() {
    return {
      newItem: {
        name: {
          required: requiredIf(() => this.editing)
        }
      }
    };
  },

  methods: {
    addItem() {
      this.$v.newItem.$touch();
      if (this.$v.$invalid) return;

      this.creatingItem = true;
      this.$apollo
        .mutate({
          mutation: AddChecklistItem,
          variables: {
            input: {
              task: {
                id: this.internalTask.id
              },

              name: this.newItem.name
            }
          }
        })
        .then(
          ({
            data: {
              addChecklistItem: { checklistItem }
            }
          }) => {
            this.newItem.name = "";
            this.$refs.newItemActions.scrollIntoView();
            this.internalTask.checklistItems.push(checklistItem);
            this.creatingItem = false;
            this.$v.$reset();
            this.checklistChanged();
          }
        )
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
          this.creatingItem = false;
        });
    },

    beforeLeave(el) {
      const { marginLeft, marginTop, width, height } = window.getComputedStyle(
        el
      );
      el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`;
      el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`;
      el.style.width = width;
      el.style.height = height;
    },

    changeName(item, name) {
      this.$apollo
        .mutate({
          mutation: SetChecklistItemName,
          variables: {
            input: {
              id: item.id,
              name: name
            }
          },

          optimisticResponse: {
            __typename: "Mutation",
            setChecklistItemName: {
              __typename: "SetChecklistItemNameMutationPayload",
              checklistItem: {
                __typename: "ChecklistItem",
                id: item.id,
                name: name
              }
            }
          }
        })
        .then(
          ({
            data: {
              setChecklistItemName: { checklistItem }
            }
          }) => {
            const updatedItem = this.internalTask.checklistItems.find(
              i => i.id === checklistItem.id
            );
            updatedItem.name = checklistItem.name;
            this.checklistChanged();
          }
        )
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    },

    deleteItem(item) {
      this.$apollo
        .mutate({
          mutation: DeleteChecklistItem,
          variables: {
            input: {
              id: item.id
            }
          }
        })
        .then(
          ({
            data: {
              deleteChecklistItem: { checklistItem }
            }
          }) => {
            const idx = this.internalTask.checklistItems.findIndex(
              ci => ci.id === checklistItem.id
            );
            this.internalTask.checklistItems.splice(idx, 1);
            this.checklistChanged();
          }
        )
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    },

    dragStarted() {
      this.drag = true;
    },

    dragEnded() {
      this.drag = false;
    },

    reordered({ moved }) {
      const { element, newIndex } = moved;
      this.$apollo
        .mutate({
          mutation: MoveChecklistItem,
          variables: {
            input: {
              id: element.id,
              position: newIndex + 1
            }
          }
        })
        .catch(e => {
          // Uh oh that failed. Undo the reordering
          this.$flash.error(gqlErrorMessage(e));
        });
    },

    itemToggled(item) {
      this.$set(item, "loading", true);
      this.$apollo
        .mutate({
          mutation: ToggleChecklistItem,
          variables: {
            input: {
              id: item.id,
              completed: !item.completed
            }
          }
        })
        .then(
          ({
            data: {
              toggleChecklistItem: { checklistItem }
            }
          }) => {
            const idx = this.internalTask.checklistItems.findIndex(
              ci => ci.id === checklistItem.id
            );

            const itemToToggle = this.internalTask.checklistItems[idx];
            itemToToggle.completed = checklistItem.completed;
            itemToToggle.completedBy = checklistItem.completedBy;
            itemToToggle.completedAt = checklistItem.completedAt;

            this.$set(item, "loading", false);
            this.checklistChanged();
          }
        )
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
          this.$set(item, "loading", false);
        });
    },

    pluralised(count) {
      if (count > 1) {
        return `${count} tasks`;
      }

      return `${count} task`;
    },

    stopEditing() {
      this.newItem.name = "";
      this.editing = false;
    },
    checklistChanged() {
      this.$emit("checklist-changed", this.internalTask);
    }
  }
};
</script>

<style lang="postcss" scoped>
.checklist-item {
  transition: all 0.5s;
}

.checklist-items-enter,
.checklist-items-leave-to {
  opacity: 0;
  transform: scale3d(0.8, 0.8, 0.8);
}

.checklist-items-leave-active {
  position: absolute;
}

.no-move {
  transition: all 0s;
}
</style>
