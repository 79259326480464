<template lang="pug">
  app-dialog(@cancel="$emit('cancel')" :dismissable="false")
    template(#title) Choose a new role

    p.text-grey-70.mb-4 You are trying to delete a role that is used by one or more users. All users must have a role, so you must choose a new role to assign to these users.

    app-message.mb-4(type="warning")
      template(#title) Warning!
      p Assigning a new role will change the permissions that those users have, including the permissions on projects they are assigned to

    form-wrapper(:validator="$v.role")
      template
        app-dropdown-field(name="newRole",
          v-model="role.newRole",
          description="All Users will be assigned this new role",
          attribute="new role"
          label="New Role",
          :options="roles",
          track-by="id",
          value-attr="id",
          label-attr="name")

    .text-right
      app-button.mr-4(secondary @click="$emit('cancel')") Keep role
      app-button.bg-grey-40(danger @click="ok") Assign & delete


</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    roles: Array
  },
  data() {
    return { role: { newRole: null } };
  },
  methods: {
    ok() {
      this.$v.role.$touch();
      if (this.$v.role.$invalid) return;

      this.$emit("ok", { newRoleId: this.role.newRole });
    }
  },
  validations() {
    return {
      role: {
        newRole: { required }
      }
    };
  }
};
</script>
<style lang="postcss" scoped>
.share {
  @apply p-8;
  @apply shadow-lg;
  width: 500px;
  min-height: 200px;
}

.button.close {
  top: 30px;
  right: 30px;
}
</style>
