<template lang="pug">
  .status.w-full
    .plaintext(v-if="total === 0")
      | No tasks
    .bar.flex.w-full.h-3(v-if="total !== 0")
      .status.h-3.bg-subatomic-sky.complete(:style="`width: ${status_percentage(params.value.complete)}%`")
      .status.h-3.bg-tribal-aqua.started(:style="`width: ${status_percentage(params.value.started)}%`")
      .status.h-3.bg-grey-60.not-started(:style="`width: ${status_percentage(params.value.notStarted)}%`")

</template>

<script>
import Vue from "vue";

export default Vue.extend({
  computed: {
    total() {
      const { complete, started, notStarted } = this.params.value;
      return complete + started + notStarted;
    }
  },
  methods: {
    status_percentage(status) {
      if (this.total == 0 || status == 0) return 0;

      return (this.total / this.total) * 100.0;
    }
  }
});
</script>
