<template lang="pug">
  app-dialog(@cancel="$emit('cancel')")
    template(#header)
      app-header(margin='')
        | Add to group
        template(#subheader) Pick one or more groups to add to

    app-text-input.mt-4(icon="search" v-focus v-model="search")
    app-panel.mt-4.overflow-auto.h-48(secondary)
      template(v-if="filteredGroups.length")
        .flex.flex-row.mb-4(v-for="group in filteredGroups" :key="group.id")
          app-checkbox(:value="group.selected" @input="toggleGroupSelection(group, $event)")
          | {{ group.name }}

      template(v-else-if="search")
        | No results

      template(v-else)
        | No groups

    .text-right.mt-8
      app-button.mr-4(@click="$emit('cancel')") Cancel
      app-button.bg-grey-40(primary @click="assignToGroups") {{ confirmLabel }}
  

</template>
<script>
import ContactGroups from "@/graphql/queries/contact_management/ContactGroups.gql";
import { pluralize } from "@/helpers/StringHelpers";
import ContactManager from "@/components/contact_management/ContactManager";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

export default {
  apollo: {
    contactGroups: {
      query: ContactGroups,
      update(data) {
        return data.contactGroups.map(group => ({ ...group, selected: false }));
      }
    }
  },
  props: {
    contacts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      search: "",
      contactGroups: [],
      groupSelections: {}
    };
  },
  computed: {
    confirmLabel() {
      return `Add to ${pluralize("group", this.groupCount)}`;
    },
    filteredGroups() {
      if (this.search === "") {
        return this.contactGroups;
      }

      return this.contactGroups.filter(group =>
        group.name.toLowerCase().startsWith(this.search.toLowerCase())
      );
    },
    groupCount() {
      return Object.keys(this.groupSelections).length;
    }
  },
  methods: {
    assignToGroups() {
      if (this.groupCount === 0) return;

      const groups = this.contactGroups.filter(g => g.selected);
      const manager = new ContactManager();

      manager
        .assignToGroups(this.contacts, groups)
        .then(() => {
          this.$flash.success("Contacts successfully added to group");
          this.$emit("cancel");
          this.$emit("added", {
            groups
          });
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    },
    toggleGroupSelection(group, selected) {
      if (selected) {
        this.$set(this.groupSelections, group.id, selected);
      } else {
        this.$delete(this.groupSelections, group.id);
      }

      group.selected = selected;
    }
  }
};
</script>
