<template lang="pug">
  app-dialog(@cancel="$emit('cancel')")
    template(#title) Are you sure?

    p.text-grey-70
      | Assigning a license will grant #[b.font-bold {{ user.name }}] access to their ManagePlaces account.

    p.block.mt-2(v-if="!isTrial")
      | You will have #[b.font-bold {{ textPlural(licensesAfterAssign, 'license') }}] remaining
      | if you continue

    .text-right.mt-8
      app-button.mr-3(default @click="$emit('cancel')") Don't assign
      app-button(primary @click="$emit('ok', { action: 'assign license' })") Assign license
</template>

<script>
import { pluralize } from "@/helpers/StringHelpers";

export default {
  name: "AssignLicenseDialog",
  props: {
    user: {
      type: Object,
      required: true
    },

    licensesRemaining: {
      type: Number,
      required: true
    },

    isTrial: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    companyOwner() {
      return this.$store.state.companyOwner;
    },

    licensesAfterAssign() {
      return this.licensesRemaining - 1;
    }
  },
  methods: {
    textPlural(count, text) {
      return pluralize(text, count);
    }
  }
};
</script>
