export default [
  {
    label: "Task",
    value: "TASK",
    icon: "task"
  },
  {
    label: "Milestone",
    value: "MILESTONE",
    icon: "milestone"
  }
];
