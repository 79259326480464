<script>
import { Components, Helpers } from "manageplaces-ui-kit";

import RolesQuery from "@/graphql/queries/core/company/Roles.gql";

import { ACTIONS, rolesMenuItems } from "./ContextMenuItems.js";
import DeleteDialog from "./DeleteDialog.vue";
import RolesManager from "./RolesManager.js";

import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import gql from "graphql-tag";

export default {
  extends: Components.BaseTable,
  mixins: [],
  apollo: {
    roles: {
      query: RolesQuery,

      update(data) {
        return data.companyRoles.edges.map(({ node }) => {
          return node;
        });
      },

      variables() {
        return { search: this.searchTerm || "*" };
      },

      result() {
        this.setRowData(this.roles);
        this.stopLoading();
      },

      debounce: 1000,

      fetchPolicy: "no-cache"
    },
    permissions: {
      query: gql`
        query permissions {
          rolePermissions {
            canDestroy
            canCreate
            canEdit
          }
        }
      `,
      update({ rolePermissions }) {
        return rolePermissions;
      },
      result() {
        // Ensure the action cells are updated
        this.gridApi().redrawRows();
      }
    }
  },

  data() {
    const vm = this;

    return {
      rolesManager: new RolesManager(),
      roles: [],
      rolesNames: [],
      selection: "multiple",
      permissions: {
        canDestroy: false,
        canCreate: false,
        canEdit: false
      },
      roleAttrs: ["id", "name", "description", "usersCount"],
      columns: [
        {
          headerName: "Name",
          field: "name",
          flex: 1.5,
          editable: true
        },
        {
          headerName: "Description",
          field: "description",
          flex: 1
        },
        {
          headerName: "Users with role",
          field: "usersCount",
          sortable: false,
          flex: 1.5
        },
        Helpers.table.actionsCell()
      ],
      listeners: {
        cellValueChanged(evt) {
          if (evt.colDef.field === "name") {
            vm.onRoleRenamed(evt);
          }
        }
      },
      config: {
        actionBar: false,
        resourceType: "role"
      }
    };
  },

  mounted() {
    this.startLoading();
  },

  methods: {
    isSystem(data) {
      return data.isSystem;
    },

    performSearch() {
      this.startLoading();
    },

    addRole() {
      window.location.href = "/roles/new";
    },

    duplicateRole(id) {
      window.location.href = `/roles/${id}/clone`;
    },

    editRole(id) {
      window.location.href = `/roles/${id}/edit`;
    },

    extractRoleAttrs(role) {
      if (!role) return null;

      const selectedRole = {};

      this.roleAttrs.forEach(attr => (selectedRole[attr] = role[attr]));

      return selectedRole;
    },

    onRoleRenamed(event) {
      const role = event.data;
      this.rolesManager.rename(role.id, event.newValue).catch(e => {
        this.$flash.error(gqlErrorMessage(e));
        role.name = event.oldValue;
        this.gridApi().applyTransaction({ update: [role] });
      });
    },

    getButtons(h) {
      const buttons = [
        h(
          Components.AppButton,
          {
            props: { icon: "add", primary: true },
            on: { click: this.addRole }
          },
          "Add role"
        )
      ];

      return buttons;
    },

    getContextMenuItems(node) {
      return rolesMenuItems(node.data, this.permissions);
    },

    contextMenuItemClicked(item, row) {
      this.performRoleAction(item, row);
    },

    performRoleAction(menuItem, row) {
      const { data: role } = row;

      this.selectedRole = this.extractRoleAttrs(role);

      switch (menuItem.action) {
        case ACTIONS.RENAME:
          this.gridApi().startEditingCell({
            rowIndex: row.rowIndex,
            colKey: "name"
          });
          break;
        case ACTIONS.EDIT:
          this.editRole(row.data.id);
          break;
        case ACTIONS.DUPLICATE:
          this.duplicateRole(row.data.id);
          break;
        case ACTIONS.DELETE:
          this.delete(row);
          break;
      }
    },

    delete(row) {
      let dialog = null;
      let success = "Role successfully deleted";
      if (row.data.usersCount == 0)
        dialog = this.$dialog.confirm({
          title: "Are you sure you want to delete this role?",
          message: "Deleting a role cannot be undone.",
          danger: true
        });
      else
        dialog = this.$dialog.show(DeleteDialog, {
          props: {
            roles: this.roles.filter(r => r.id != row.data.id)
          }
        });

      dialog.onOk(({ api, data }) => {
        api.hide();
        this.rolesManager
          .delete(row.data, typeof data == "object" ? data.newRoleId : null)
          .then(response => {
            if (response.data.deleteRole.newRole)
              this.updateRows([response.data.deleteRole.newRole]);
            this.$flash.success(success);
            this.removeRows([row]);
          })
          .catch(e => {
            this.$flash.error(gqlErrorMessage(e));
          });
      });
    },

    removeRows(rows) {
      this.gridApi().applyTransaction({
        remove: rows
      });
    },

    updateRows(rows) {
      this.gridApi().applyTransaction({
        update: rows
      });
    }
  }
};
</script>
