const state = () => {
  return {
    lockVersion: 0,
    requestsInProgress: 0,
    readOnly: false,
    sidebarProps: {
      taskId: null,
      isOpen: false,
      reload: false,
      layout: null,
      renaming: false,
      actions: {}
    }
  };
};

const actions = {
  openSidebar({ commit }, { taskId, renaming }) {
    commit("setSidebarRenaming", renaming);
    commit("setSidebarTaskId", taskId);
  }
};

const mutations = {
  setLockVersion(state, lockVersion) {
    state.lockVersion = lockVersion;
  },

  setSidebarActions(state, handlers) {
    const actions = { ...state.sidebarProps.actions };
    for (const action in handlers) {
      if (!actions[action]) {
        actions[action] = [];
      }
      actions[action].push(handlers[action]);
    }

    state.sidebarProps.actions = actions;
  },

  setSidebarTaskId(state, taskId) {
    state.sidebarProps.isOpen = !!taskId;
    state.sidebarProps.taskId = taskId;
  },

  setSidebarRenaming(state, renaming) {
    state.sidebarProps.renaming = renaming;
  },

  setSelectedTasksLayout(state, layout) {
    state.sidebarProps.layout = layout;
  },

  setSidebarReload(state, reload) {
    state.sidebarProps.reload = reload;
  },

  requestStarted(state) {
    state.requestsInProgress++;
  },

  requestFinished(state) {
    state.requestsInProgress--;
  },

  setReadOnly(state, readOnly) {
    state.readOnly = readOnly;
  }
};

const getters = {
  workingWeek(_state, _getters, rootState) {
    return rootState.workingWeek;
  },
  // Defines functions that makes sure an action called on a layout is also called
  // on the other layout so that they stay synchronized
  actionsCallers(state) {
    const { actions } = state.sidebarProps;
    const actionsCallers = {};

    for (const action in actions) {
      actionsCallers[action] = function() {
        if (actions[action])
          actions[action].forEach(handler => handler(...arguments));
      };
    }
    return actionsCallers;
  },

  isLoading(state) {
    return state.requestsInProgress > 0;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
