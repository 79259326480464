/* global gantt, $ */

import { baselineClass } from "@/components/projects/gantt/ProjectGanttConfig";
import { getPdfName } from "@/components/projects/gantt/ProjectGanttUtils";

export default {
  data() {
    return {
      currentZoom: 0
    };
  },
  methods: {
    setupInteractions() {
      gantt.attachEvent("onColumnResizeEnd", (index, column, new_width) => {
        const resizableColumns = ["text", "start_date", "duration"];

        if (resizableColumns.indexOf(column.name) === -1) {
          return;
        }

        this.columnSizeData[column.name] = {
          index: index,
          label: column.label,
          new_width: new_width
        };

        $.ajax("/api/projects/" + this.projectId + "/update_user_preferences", {
          method: "POST",
          data: {
            preference: "gantt_columns",
            value: this.columnSizeData
          }
        });
      });

      gantt.ext.fullscreen.getFullscreenElement = () => {
        return document.getElementById("gantt-container");
      };
    },

    applyZoomConfig(config) {
      gantt.config.scale_unit = config.scale_unit;
      if (config.date_scale) {
        gantt.config.date_scale = config.date_scale;
        gantt.templates.date_scale = null;
      } else {
        gantt.templates.date_scale = config.template;
      }

      gantt.config.step = config.step;
      gantt.config.subscales = config.subscales;
    },

    collapseAllTasks() {
      let taskIds = [];
      gantt.eachTask(task => {
        task.$open = false;
        taskIds.push(task.id);
      });
      gantt.render();
      this.changeTaskExpandedStatus(taskIds, false);
    },

    expandAllTasks() {
      let taskIds = [];
      gantt.eachTask(task => {
        task.$open = true;
        taskIds.push(task.id);
      });
      gantt.render();
      this.changeTaskExpandedStatus(taskIds, true);
    },

    exportToExcel() {
      gantt.config.scales = [{ unit: "day", step: 1, format: "%d" }];
      gantt.exportToExcel({
        name: "document.xlsx",
        columns: [
          { id: "text", header: "Title" },
          { id: "start_date", header: "Start date", type: "date" },
          { id: "end_date", header: "Deadline", type: "date" }
        ],
        visual: true,
        cellColors: true,
        date_format: "dddd d, mmmm yyyy"
      });
      gantt.ext.zoom.setLevel(gantt.ext.zoom.getCurrentLevel());
    },

    changeTaskExpandedStatus(taskIds, expanded) {
      const projectId = this.projectId;
      const keys = taskIds.map(id => {
        return "task_" + id + "_expanded";
      });

      $.ajax("/api/projects/" + projectId + "/update_user_preferences", {
        method: "POST",
        data: {
          preference: keys,
          value: expanded,
          preference_type: "gantt_expanded_status"
        }
      });
    },

    print() {
      // Tidy the name, otherwise it will default to gantt.pdf
      const name = getPdfName(this.project);

      gantt.templates.grid_header_class = colName => {
        return colName === "add" ? "hide_col" : "";
      };

      const selectedTask = gantt.getSelectedId();
      gantt.config.smart_rendering = false;
      gantt.config.static_background = false;
      gantt.unselectTask();
      gantt.render();

      // It doesn't seem to work if we do this bit in the
      // main gantt stylesheet, so we're doing it here instead.
      const iconImport =
        '@font-face { \
            font-family:"Icons"; \
            src:url(https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.10/themes/basic/assets/fonts/icons.eot); \
            src:url(https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.10/themes/basic/assets/fonts/icons.eot?#iefix) format("embedded-opentype"), url(https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.10/themes/default/assets/fonts/icons.woff2) format("woff2"), url(https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.10/themes/default/assets/fonts/icons.woff) format("woff"), url(https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.10/themes/default/assets/fonts/icons.ttf) format("truetype"), url(https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.10/themes/default/assets/fonts/icons.svg#icons) format("svg"); \
        }';

      gantt.exportToPDF({
        name: name,
        header:
          "\
            <style>" +
          iconImport +
          " " +
          document.getElementById("ganttStyles").innerHTML +
          '</style>\
            <h3 id="pdf-title">' +
          name.replace(/.pdf/g, "") +
          "</h3>",
        raw: true
      });

      gantt.config.smart_rendering = true;
      gantt.config.static_background = true;
      gantt.selectTask(selectedTask);
      gantt.render();
    },

    toggleBaselines(shouldShow = undefined, persist = true) {
      const ganttEl = this.$refs.gantt;
      const rootLayoutEl = ganttEl.querySelector(".gantt_layout_root");
      const showBaselines =
        shouldShow === undefined ? this.showBaselines : !shouldShow;

      if (showBaselines) {
        this.showBaselines = false;
        rootLayoutEl.classList.remove(baselineClass);
      } else {
        this.showBaselines = true;
        rootLayoutEl.classList.add(baselineClass);
      }

      if (persist) {
        this.updateProjectBaslinesSetting();
      }

      gantt.render();
    },

    toggleFullscreen() {
      const isFullScreen = gantt.getState().fullscreen;

      if (isFullScreen) {
        gantt.collapse();
      } else {
        gantt.expand();
      }
    },

    toolbarListeners() {
      return {
        "collapse-all": this.collapseAllTasks,
        "expand-all": this.expandAllTasks,
        export: this.exportToExcel,
        print: this.print,
        "toggle-baselines": this.toggleBaselines,
        "toggle-fullscreen": this.toggleFullscreen,
        "zoom-in": this.zoomIn,
        "zoom-out": this.zoomOut
      };
    },

    zoomIn() {
      gantt.ext.zoom.zoomIn();
    },

    zoomOut() {
      gantt.ext.zoom.zoomOut();
    }
  }
};
