import { runMutation } from "@/helpers/GraphQLHelpers";
import DeleteInvoices from "@/graphql/mutations/finance/invoices/DeleteInvoices.gql";

export default class InvoicesManager {
  delete(invoices) {
    const ids = invoices.map(co => co.id);

    return runMutation(DeleteInvoices, { ids });
  }
}
