import RolesForm from "@/components/roles/RolesForm.vue";

export default [
  {
    path: "/roles/new",
    name: "new_role",
    component: RolesForm
  },
  {
    path: "/roles/:id/edit",
    name: "edit_role",
    component: RolesForm
  },
  {
    path: "/roles/:id/clone",
    name: "clone_role",
    component: RolesForm
  }
];
