<template lang="pug">
  div
    .page.loader.text-center.page-loader(v-if="loading")
      .circle
        .ring
    .checkout-box.text-grey-60(:class="loading ? 'opacity-25' : ''")
      .checkout-box-content
        app-panel.mb-4
          h2.font-bold Payment information
          span Enter your card details

          #payment-form.checkout-card.mt-12
            app-text-field(name="fullName",
                          label="Cardholder name",
                          type="text",
                          description="As it appears on the card",
                          v-model="fullName",
                          :validator="$v.fullName",
                          :messages="messages",
                          @blur="validateField('billingForename')",
                          data-cy="fullName-input")

            label.text-lg(:class="errors.card.status ? 'text-withered-cherry font-bold' : ''",
                  for='card-element') Card number
            div
              #card-element.card-elements(data-cy="cardNumber-input",
                :class="errors.card.status ? 'border-withered-cherry font-bold StripeElement StripeElement--empty' : 'StripeElement'")
              .text-right.text-withered-cherry.font-bold.mt-1.mb-4 {{ errors.card.status ? errors.card.message : '' }}

            .flex.flex-row
              .flex-initial.mr-4
                label.text-lg(:class="errors.expiry.status ? 'text-withered-cherry font-bold' : ''",
                      for='expiry-element') Expiration date
                div
                  #expiry-element.card-elements(data-cy="expiry-input",
                    :class="errors.expiry.status ? 'border-withered-cherry font-bold StripeElement StripeElement--empty' : 'StripeElement'")
                  .text-right.text-withered-cherry.font-bold.mt-1.mb-4 {{ errors.expiry.status ? errors.expiry.message : '' }}
              .flex-initial
                label.text-lg(:class="errors.cvc.status ? 'text-withered-cherry font-bold' : ''",
                      for='cvc-element') CVV
                div
                  #cvc-element.card-elements(data-cy="cvc-input",
                    :class="errors.cvc.status ? 'border-withered-cherry font-bold StripeElement StripeElement--empty' : 'StripeElement'")
                  .text-right.text-withered-cherry.font-bold.mt-1.mb-4 {{ errors.cvc.status ? errors.cvc.message : '' }}
      .checkout-box-sidebar.mt-4(class="md:mt-0")
        checkout-sidebar(v-model="checkout"
          @submit="onSubmit()" :icon="lockIcon" :footer-text="footerText")

    app-dialog-container(v-model="isStripeModal")
      app-dialog.stripe-modal(@cancel="isStripeModal = false", :dismissable="false")
        iframe(:src="redirect_to_url", width="600", height="350")
</template>
<script>
/* global $, Stripe */
/* eslint-disable */
import { required } from "vuelidate/lib/validators";
import CheckoutMutation from "@/graphql/mutations/subscriptions/CreateSubscription.gql";
import {
  UPDATE_SUCCES,
  INPUT_TYPE_ERROR,
  INVALID_CARD_ERROR
} from "@/config/help_messages/SubscriptionMessages";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import {
  STRIPE_ELEMENT_STYLE,
  USER_LIMIT,
  FINANCE_MANAGEMENT
} from "@/config/constants";
import CheckoutSidebar from "./CheckoutSidebar.vue";

export default {
  name: "CheckoutPayment",
  components: {
    CheckoutSidebar
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      checkout: { ...this.value },
      loading: true,
      fullName: this.getFullName(
        this.value.billingForename,
        this.value.billingSurname
      ),
      card: {
        username: null,
        number: null,
        cvv: null
      },
      elements: {
        card: null,
        expiry: null,
        cvc: null,
        ready: {
          card: false,
          expiry: false,
          cvc: false
        }
      },
      errors: {
        card: {
          status: false,
          message: "Card number is required!",
          label: "Card number"
        },
        expiry: {
          status: false,
          message: "Expiration date is required!",
          label: "Expiration date"
        },
        cvc: {
          status: false,
          message: "CVV is required!",
          label: "CVV"
        }
      },
      messages: {
        required: "Cardholder name is required"
      },
      lockIcon: "padlock",
      footerText: "All payments will be secure and encrypted",
      isStripeModal: false,
      redirect_to_url: ""
    };
  },
  validations() {
    return {
      fullName: { required }
    };
  },
  created() {
    this.form = $("#payment-form");

    const vm = this;
    let script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/";
    document.body.append(script);

    script.onload = () => {
      this.loading = false;

      vm.stripe = Stripe(this.checkout.gatewayKey);
      vm.setupPaymentForm();
    };
  },
  computed: {
    stripeDetails() {
      return {
        name: `${this.fullName}`,
        address_line1: this.checkout.billingAddress1 || "",
        address_line2: this.checkout.billingAddress2 || "",
        address_city: this.checkout.billAddressCity || "",
        address_state: this.checkout.billingAddressRegion || "",
        address_zip: this.checkout.billingAddressPostcode || "",
        address_country: this.country || ""
      };
    },
    isCardError() {
      const { elements, errors } = this;
      return (
        (elements.card && elements.card._empty) || errors.card.status == true
      );
    },
    isExpiryError() {
      const { elements, errors } = this;
      return (
        (elements.expiry && elements.expiry._empty) ||
        errors.expiry.status == true
      );
    },
    isCvcError() {
      const { elements, errors } = this;
      return (elements.cvc && elements.cvc._empty) || errors.cvc.status == true;
    }
  },
  methods: {
    touchStripeElements() {
      if (this.isCardError) this.errors.card.status = true;
      if (this.isExpiryError) this.errors.expiry.status = true;
      if (this.isCvcError) this.errors.cvc.status = true;
    },
    validateField() {
      if (this.$v.fullName.$invalid) {
        this.$v.fullName.$touch();
      }
    },
    onSubmit() {
      const { errors } = this;

      this.$v.$touch();
      this.touchStripeElements();

      if (errors.card.status || errors.expiry.status || errors.cvc.status) {
        this.$flash.error(INPUT_TYPE_ERROR);
      } else if (this.$v.fullName.$invalid) {
        this.$flash.error(INPUT_TYPE_ERROR);
      } else {
        this.loading = true;
        this.stripe
          .createToken(this.elements.card, this.stripeDetails)
          .then(result => {
            this.submitCheckout(result.token.id);
          });
      }
    },
    checkCardValidation(e, type, eventType = "change") {
      const isComplete =
        eventType === "change" ? e.complete : this.elements[type]._complete;
      const isEmpty =
        eventType === "change" ? e.empty : this.elements[type]._empty;

      if (!isComplete) {
        this.errors[type].status = true;
        document
          .getElementById(`${type}-element`)
          .classList.add("border-withered-cherry");

        if (isEmpty === true) {
          this.errors[type].message = `${this.errors[type].label} is required`;
        } else {
          this.errors[type].message = `${this.errors[type].label} is invalid`;
        }
      } else {
        this.errors[type].status = false;
        document
          .getElementById(`${type}-element`)
          .classList.remove("border-withered-cherry");
      }
    },
    setupPaymentForm() {
      const vm = this;
      const elements = this.stripe.elements();
      const baseInputStyles = STRIPE_ELEMENT_STYLE;

      this.elements.card = elements.create("cardNumber", {
        style: baseInputStyles
      });
      this.elements.expiry = elements.create("cardExpiry", {
        style: baseInputStyles
      });
      this.elements.cvc = elements.create("cardCvc", {
        style: baseInputStyles
      });

      this.elements.card.on("ready", () => {
        vm.elements.ready.card = true;
        vm.updateReadyState();
      });

      this.elements.card.on("blur", e => {
        this.checkCardValidation(e, "card", "blur");
      });
      this.elements.card.on("change", e => {
        this.checkCardValidation(e, "card", "change");
      });

      this.elements.expiry.on("ready", () => {
        vm.elements.ready.expiry = true;
        vm.updateReadyState();
      });

      this.elements.expiry.on("blur", e => {
        this.checkCardValidation(e, "expiry", "blur");
      });
      this.elements.expiry.on("change", e => {
        this.checkCardValidation(e, "expiry", "change");
      });

      this.elements.cvc.on("ready", () => {
        vm.elements.ready.cvc = true;
        vm.updateReadyState();
      });

      this.elements.cvc.on("blur", e => {
        this.checkCardValidation(e, "cvc", "blur");
      });
      this.elements.cvc.on("change", e => {
        this.checkCardValidation(e, "cvc", "change");
      });

      this.elements.card.mount("#card-element");
      this.elements.expiry.mount("#expiry-element");
      this.elements.cvc.mount("#cvc-element");
    },
    updateReadyState() {
      const { card, cvc, expiry } = this.elements.ready;

      if (card && cvc && expiry) {
        this.loading = false;
      }
    },
    getFullName(billingForename, billingSurname) {
      const name = billingForename ? billingForename : "";
      const fullName = billingSurname ? `${name} ${billingSurname}` : name;
      return fullName.trim() === "" ? "" : fullName;
    },
    submitCheckout(
      token,
      secureConfirm = false,
      stripeCustomer = null,
      stripeSubscription = null
    ) {
      const vm = this;
      const {
        financeManagementStatus,
        userLicenses,
        country,
        taxNumber,
        companyNumber,
        billingEmail,
        billingForename,
        billingSurname,
        billingAddress1,
        billingAddressPostcode,
        billingAddressCity,
        billingAddressCountry
      } = this.checkout;
      const params = {
        cardToken: token,
        billingFrequency: this.checkout.billingType.value
      };

      if (this.checkout.voucher) {
        params["voucher"] = this.checkout.voucher.name;
      }

      if (billingAddressCountry) {
        delete billingAddressCountry.__typename;
      }

      const companyFeatures = [
        {
          typeName: FINANCE_MANAGEMENT,
          quantity: financeManagementStatus ? 1 : 0
        },
        {
          typeName: USER_LIMIT,
          quantity: userLicenses
        }
      ];

      const input = {
        ...params,
        companyFeatures,
        country: country,
        taxNumber: taxNumber,
        companyNumber,
        billingEmail,
        billingForename,
        billingSurname,
        billingAddress1,
        billingAddressPostcode,
        billingAddressCity,
        billingAddressCountry,
        secureConfirm
      };
      if (stripeCustomer) {
        input.stripeCustomer = stripeCustomer;
      }
      if (stripeSubscription) {
        input.stripeSubscription = stripeSubscription;
      }

      this.loading = true;
      this.$apollo
        .mutate({
          mutation: CheckoutMutation,
          variables: { input }
        })
        .then(({ data: { createSubscription } }) => {
          this.loading = false;

          if (createSubscription && createSubscription.status) {
            this.$flash.success(UPDATE_SUCCES);
            window.location.href = "/settings/subscriptions";
          } else if (createSubscription.isSecure) {
            this.loading = true;

            this.stripe
              .confirmCardPayment(
                createSubscription.paymentIntentClientSecret,
                {
                  payment_method: { card: this.elements.card },
                  return_url: `${window.location.origin}/subscriptions/3d-process`
                },
                { handleActions: true }
              )
              .then(result => {
                if (result.error) {
                  this.loading = false;
                  this.$flash.error(INVALID_CARD_ERROR);
                } else {
                  if (
                    result.paymentIntent &&
                    result.paymentIntent.status === "succeeded"
                  ) {
                    this.submitCheckout(
                      token,
                      true,
                      createSubscription.customer,
                      createSubscription.subscription
                    );
                  } else {
                    this.loading = false;
                  }
                }
              });
          }
        })
        .catch(error => {
          this.loading = false;
          this.$flash.error(gqlErrorMessage(error));
        });
    }
  }
};
</script>
<style lang="postcss" scoped>
.card-elements {
  margin-top: 0.75rem;
}
.StripeElement--invalid .InputElement {
  color: @apply (text-withered-cherry);
}
.page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
}
.stripe-modal {
  width: 600px !important;
}
</style>
