<template lang="pug">
  td(:class='classes')
    div(style='min-width: 100px', v-if='cell.label.field_type === "date"')
      | {{ this.cell.value.content }}

    div(style='min-width: 100px', v-else-if='cell.label.field_type === "textarea"')
      | {{ this.cell.value.content }}

    div(style='min-width: 100px', v-else-if='cell.label.field_type === "dropdown"')
      | {{ this.cell.value.content }}

    div(style='min-width: 100px', v-else-if='cell.label.field_type === "text"')
      | {{ this.cell.value.content }}

    div(style='min-width: 100px', v-else-if='cell.label.field_type === "currency"')
      | {{ '&#163; ' + showFormattedValue(this.cell.value.content) }}

    div(style='min-width: 100px', v-else-if='cell.label.field_type === "integer"')
      | {{ showFormattedValue(this.cell.value.content) }}
</template>

<script>
export default {
  props: ["cell", "currency", "row", "canEdit", "classes"],
  methods: {
    showFormattedValue(value) {
      let val = 0;

      if (value && !Number.isNaN(value)) {
        val = value;
      }

      return parseFloat(val).toFixed(this.cell.label.decimal_places);
    }
  }
};
</script>
