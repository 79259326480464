<template lang="pug">
  div
    .page.loader.text-center.z-10(v-if="loading")
      .circle
        .ring
    .text-grey-60(:class="loading ? 'opacity-25' : ''")
      form-wrapper(:validator="$v.user")
        .pointer-events-none.flex.fixed.left-0.top-0.bottom-0(class="xs:w-0 md:w-1/2 left bg-tribal-aqua")
          img.z-10.flex-none(:src="logo", class="signup-logo")
          div.z-0.absolute.top-0.left-0.right-0.bottom-0.left-image.w-full.h-full.opacity-50

        .flex.justify-end
          div(class="xs:w-full md:w-1/2 signup-form-content")
            div(class="xs:p-16 sm:p-24 mx-auto inner-content")
              .mb-8.text-center
                h2
                  strong Start your free trial
                h3 No credit required. Access all of our features
              .flex.mt-4
                app-text-field.mr-2(v-model="$v.user.firstName.$model"
                                    name="firstName"
                                    label="First name"
                                    class="w-1/2"
                                    description=""
                                    type="text"
                                    :validator="$v.user.firstName"
                                    :messages="messages"
                                    @keyup.enter="register"
                                    v-on:blur="validateField('firstName')")

                app-text-field(v-model="$v.user.lastName.$model"
                              name="lastName"
                              label="Last name"
                              description=""
                              class="w-1/2"
                              type="text"
                              :validator="$v.user.lastName"
                              :messages="messages"
                              @keyup.enter="register"
                              v-on:blur="validateField('lastName')")

              app-text-field(v-model="$v.user.companyName.$model"
                            name="companyName"
                            label="Company name" description=""
                            type="text"
                            :validator="$v.user.companyName"
                            :messages="messages"
                            @keyup.enter="register"
                            v-on:blur="validateField('companyName')")

              app-text-field(v-model="$v.user.jobTitle"
                            name="jobTitle"
                            label="Job title"
                            description=""
                            type="text"
                            :messages="messages"
                            @keyup.enter="register"
                            v-on:blur="validateField('jobTitle')")

              .mb-8
                .mb-4
                  label.text-grey-60.text-lg(:for="user.companySize") How many employees do you have?
                .flex
                  .square.mr-12.flex(v-for="number in numberOfEmployees",
                                    :class="isActive(number)",
                                    @click="changeCompanySize(number)")
                    span.m-auto.text-lg(:class="isActive(number) ? 'text-diving-sapphire' : ''") {{ number }}

              app-text-field(v-model="$v.user.email.$model"
                            name="email"
                            label="Email address"
                            type="email"
                            description="This will be used to login to ManagePlaces"
                            :validator="$v.user.email"
                            :messages="messages"
                            @keyup.enter="register"
                            v-on:blur="validateField('email')")

              app-text-field(v-model="$v.user.password.$model"
                            name="password"
                            label="Password"
                            type="password"
                            description="Min 8 characters, with 1 number, upper and lower case letter, and 1 special letter"
                            :messages="messages"
                            @keyup.enter="register"
                            v-on:blur="validateField('password')")

                template(#errors)
                  span
              .flex.password-alert
                .mb-6(class="w-1/2")
                  .w-full.bg-grey-30.h-1.mt-2.group
                    template(v-if="pwdErrorStatus >= 1")
                      .progress-step.first.h-full.bg-withered-cherry.transition-width.ease-in-out.duration-500(:style="{ width: '33%', zIndex: 20 }")
                    template(v-if="pwdErrorStatus >= 2")
                      .progress-step.first.h-full.bg-white.transition-width.ease-in-out.duration-500(:style="{ width: '33.5%', zIndex: 19 }")
                      .progress-step.second.h-full.bg-laughing-amber.transition-width.ease-in-out.duration-500(:style="{ width: '66.5%', zIndex: 18 }")
                    template(v-if="pwdErrorStatus == 3")
                      .progress-step.second.h-full.bg-white.transition-width.ease-in-out.duration-500(:style="{ width: '67%', zIndex: 17 }")
                      .progress-step.third.h-full.bg-subatomic-sky.transition-width.ease-in-out.duration-500(:style="{ width: '100%', zIndex: 16 }")
                    template(v-if="pwdErrorStatus == 0")
                      .progress-step.third.h-full.bg-grey-3.transition-width.ease-in-out.duration-500(:style="{ width: '100%' }")
                span.ml-2.font-bold.flex-1.text-right(:class="getPwdMessageColor") {{getPwdMessage}}

              app-text-field(v-model="$v.user.passwordConfirmation.$model"
                            name="passwordConfirmation"
                            label="Confirm your password"
                            description=""
                            type="password"
                            :validator="$v.user.passwordConfirmation"
                            :messages="messages"
                            @keyup.enter="register"
                            v-on:blur="validateField('passwordConfirmation')")

              .flex.mb-4
                app-checkbox(:value="user.tAndC"
                            @input="toggleTermsStatus"
                            :error="termsAndServiceError !== null")
                span.ml-1.mr-1.terms-link.cursor-pointer(@click.prevent="toggleTermsStatus") I have read and agreed to the
                a.terms-link(href="#"
                            target="_blank"
                            @click.prevent="showModal") Terms & Conditions

              .fond-bold.text-withered-cherry.terms-service-error(v-if="termsAndServiceError") {{termsAndServiceError}}

              vue-recaptcha(
                ref="recaptcha"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                size="invisible"
                :sitekey="recaptchaSiteKey")

              div
                app-button(primary
                  class="w-full text-center focus:outline-none focus:shadow-outline mt-5"
                  @click.prevent="register"
                ) Get started


              p.mt-4
                | Already have an account? 
                a(href="/users/sign_in") Log in 

              base-modal(
                :name='modalName',
                classes='form',
                :title='modalTitle',
                :state='modalState')
                terms-and-service
                app-button(primary
                  class="w-full text-center focus:outline-none focus:shadow-outline mt-4"
                  @click.prevent='hideModal'
                ) close

</template>
<script>
import VueRecaptcha from "vue-recaptcha";
import BaseModal from "@/components/modals/BaseModal.vue";
import TermsAndService from "@/components/docs/TermsAndService.vue";
import VerifyRegistrationCaptcha from "@/graphql/mutations/users/VerifyRegistrationCaptcha.gql";
import RegisterUser from "@/graphql/mutations/users/Signup.gql";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import { getSiteKey } from "@/config/constants.js";

import {
  required,
  minLength,
  email,
  maxLength,
  sameAs
} from "vuelidate/lib/validators";

const TERMS_AND_SERVICE_ERROR = "You must agree to the terms and conditions";
const INPUT_TYPE_ERROR = "Please correct the errors and try again";

export default {
  components: {
    VueRecaptcha,
    BaseModal,
    TermsAndService
  },
  data() {
    return {
      logo: require("@/assets/images/ManagePlaces-logo_2014-light-03.png"),
      loading: false,
      user: {
        firstName: "",
        lastName: "",
        companyName: "",
        companySize: "1-4",
        jobTitle: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        tAndC: false
      },
      numberOfEmployees: ["1-4", "5-14", "15+"],
      messages: {
        minLength: "{label} must contain at least 8 characters",
        maxLength: "{label} must contain less than 30 characters",
        required: "{label} is required",
        email: "Email is not a valid email address",
        sameAsPassword: "Confirm password should be same as password"
      },
      modalName: "terms-and-service-modal",
      modalTitle: "",
      modalState: "none",
      recaptchaSiteKey: getSiteKey(window.location.host),
      recaptchaStatus: null,
      termsAndServiceError: null
    };
  },
  validations() {
    return {
      user: {
        firstName: { required, maxLength: maxLength(30) },
        lastName: { required, maxLength: maxLength(30) },
        companyName: { required, maxLength: maxLength(30) },
        companySize: { required, maxLength: maxLength(30) },
        email: { required, email, maxLength: maxLength(80) },
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(30),
          containsUppercase: function(value) {
            return /[A-Z]/.test(value);
          },
          containsLowercase: function(value) {
            return /[a-z]/.test(value);
          },
          containsNumber: function(value) {
            return /[0-9]/.test(value);
          },
          containsSpecial: function(value) {
            return /[!@#$%^&*`~,.<>;':"/[\]|{}()=_+-]/.test(value);
          }
        },
        passwordConfirmation: {
          required,
          sameAsPassword: sameAs("password")
        },
        tAndC: {
          checked(val) {
            return val;
          }
        }
      }
    };
  },
  computed: {
    pwdErrorStatus() {
      if (this.user.password === "") {
        return 0;
      } else if (this.user.password && this.user.password.length < 8) {
        return 1;
      } else if (
        /[A-Z]/.test(this.user.password) &
        /[a-z]/.test(this.user.password) &
        /[0-9]/.test(this.user.password) &
        /[!@#$%^&*`~,.<>;':"/[\]|{}()=_+-]/.test(this.user.password)
      ) {
        return 3;
      } else {
        return 2;
      }
    },
    getPwdMessage() {
      let errorMsg = null;

      switch (this.pwdErrorStatus) {
        case 1:
          errorMsg = "Weak!";
          break;
        case 2:
          errorMsg = "Nice!";
          break;
        case 3:
          errorMsg = "Nice and strong!";
          break;
        default:
          errorMsg =
            this.$v.user.password.$dirty && this.$v.user.password.$invalid
              ? "Password is required!"
              : "";
          break;
      }

      return errorMsg;
    },
    getPwdMessageColor() {
      switch (this.pwdErrorStatus) {
        case 2:
          return "text-rough-mustard";
        case 3:
          return "text-subatomic-sky";
        default:
          return "text-withered-cherry";
      }
    }
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    recaptchaScript.async = true;
    recaptchaScript.defer = true;
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    register() {
      this.$v.user.$touch();

      if (this.$v.user.$invalid) {
        this.$flash.error(INPUT_TYPE_ERROR);

        if (this.$v.user.tAndC.$invalid) {
          this.termsAndServiceError = TERMS_AND_SERVICE_ERROR;
        }
      } else {
        // execute google recaptcha
        this.$refs.recaptcha.execute();
      }
    },
    isActive(number) {
      return number === this.user.companySize ? "active" : "";
    },
    changeCompanySize(number) {
      this.user.companySize = number;
    },
    toggleTermsStatus() {
      this.user.tAndC = !this.user.tAndC;
      if (this.termsAndServiceError !== null) {
        this.termsAndServiceError = null;
      }
    },
    showModal() {
      this.$modal.show("terms-and-service-modal");
    },
    hideModal() {
      this.$modal.hide("terms-and-service-modal");
    },
    async onCaptchaVerified(response) {
      const self = this;
      this.$refs.recaptcha.reset();

      if (this.$v.user.$invalid) {
        this.$flash.error(INPUT_TYPE_ERROR);

        if (this.$v.user.tAndC.$invalid) {
          this.termsAndServiceError = TERMS_AND_SERVICE_ERROR;
        }
      } else {
        // Validate the token and continue
        this.$loading = true;
        let verified = true;
        await this.$apollo
          .mutate({
            mutation: VerifyRegistrationCaptcha,
            variables: {
              token: response
            }
          })
          .then(
            ({
              data: {
                verifyRegistrationCaptcha: { valid }
              }
            }) => {
              if (!valid) {
                verified = false;
                this.$flash.error("You failed the Captcha. Please try again");
              }
            }
          );

        if (!verified) return;

        this.$apollo
          .mutate({
            mutation: RegisterUser,
            variables: {
              user: {
                ...this.user,
                firstName: this.user.firstName.trim(),
                lastName: this.user.lastName.trim()
              }
            }
          })
          .then(resp => {
            this.loading = false;
            if (resp.data && resp.data.registerUser) {
              window.location.href = "/welcome";
            }
          })
          .catch(error => {
            this.loading = false;
            self.$flash.error(gqlErrorMessage(error));
          });
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    validateField(fieldName) {
      const vField = this.$v.user[fieldName];
      if (vField && vField.$touch) {
        vField.$touch();
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.left-image {
  background: url("~@/assets/images/signup.jpg");
  background-size: cover;
  background-repeat: none;
  background-position: center;
}

.signup-logo {
  position: absolute;
  top: 50%;
  left: calc(50% - 140px);
}

.signup-form-content .inner-content {
  max-width: 700px;
}

.group {
  position: relative;
}

.square {
  width: 70px;
  height: 70px;
  border: 1px solid var(--grey-60);
  cursor: pointer;
  outline: none;
  border-radius: 3px;

  &.active {
    background-color: rgba(58, 150, 235, 0.1);
    border: 1px solid var(--tribal-aqua);
    opacity: 0.8;
    @apply shadow-lg;

    span {
      font-weight: bold;
    }
  }
}

.progress-step {
  position: absolute;

  &.first {
    z-index: 10;
  }
  &.second {
    z-index: 9;
  }
  &.third {
    z-index: 8;
  }
}

.password-alert {
  margin-top: -0.75rem;
}

.terms-link {
  line-height: 1.5;
}

.terms-service-error {
  margin-top: -0.5rem;
}
</style>
<style>
.grecaptcha-badge {
  visibility: hidden !important;
}
</style>
