<template lang="pug">
  .field
    label(v-if='label.length > 0') {{ label }}
    .ui.error.message(v-if='hasErrors')
      ul.ui.list
        li(v-for='error in errors') {{ error }}

    slot
</template>

<script>
export default {
  props: {
    label: {
      default: "",
      type: String
    },
    errors: {
      default() {
        return [];
      },
      type: Array
    }
  },
  computed: {
    hasErrors() {
      return this.errors.length !== 0;
    }
  }
};
</script>
