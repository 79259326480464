<template lang="pug">
  .wide.content#project-snapshot
    app-header
      | {{ title }}
      template(#subheader) Generated on {{ generated }}

    button.ui.blue.toggle-editor.button(@click='toggleEditorMode')
      template(v-if='editorMode')
        | Save Customised Snapshot
      template(v-else)
        | Customise Snapshot

    h3 Project Details
    table.ui.celled.unstackable.compact.table
      tbody
        template(v-for='(value, key) in projectDetails')
          tr(v-if='value && (editorMode || showProjectField(key))')
            td.label
              strong
                label(v-if='editorMode && !alwaysShowProjectField(key)')
                  input.mr-4(type='checkbox', v-model='projectFieldsEnabled[key]')
                  | {{ key }}
                template(v-else)
                  | {{ key }}
            td {{ value }}

    template(v-if="includeTasks")
      h3 Milestones
      table.ui.celled.unstackable.compact.table
        thead
          tr
            template(v-for='field in milestoneFields')
              td(v-if='editorMode || showMilestoneField(field)')
                strong
                  label(v-if='editorMode && !alwaysShowMilestoneField(field)')
                    input.mr-4(type='checkbox', v-model='milestoneFieldsEnabled[field]')
                    | {{ field }}
                  template(v-else)
                    | {{ field }}

        tbody
          tr(v-for='milestone in milestonesTable', :class='taskRowClass(milestone)')
            template(v-for='field in milestoneFields')
              td(
                v-if='editorMode || showMilestoneField(field)',
                :class='["field-" + (field.toLowerCase().replace(" ", "_"))]')
                template(v-if='field === "MS number"')
                  a.wbs-link(
                    :href='taskAnchorName(milestone["MS number"], true)') {{ milestone['MS number'] }}
                  a(:href='milestone.url', target='_blank')
                    app-icon(icon="external")
                template(v-else-if='field === "Name"')
                  | {{ milestone['Name'] }}
                template(v-else-if='field === "Status"')
                  template(v-if='milestone[field]')
                    .ui.task.label(:class='[milestoneStatusClass(milestone)]') {{ milestone[field] }}
                    .ui.task.red.label(v-if='milestone.overdue') Overdue

                template(v-else-if='field === "Dependencies"')
                  template(v-for='dependency in milestone.dependencies')
                    a(:href='taskAnchorName(dependency[1], true)') {{ dependency[1] }}
                    a(:href='dependency[2]', target='_blank')
                      app-icon(icon="external")
                template(v-else)
                  | {{ milestone[field] }}


    template(v-if="includeTasks")
      h3 Tasks
      table.ui.celled.unstackable.compact.table
        thead
          tr
            template(v-for='field in tasksFields')
              td(v-if='editorMode || showTaskField(field)')
                strong
                  label(v-if='editorMode && !alwaysShowTaskField(field)')
                    input.mr-4(type='checkbox', v-model='taskFieldsEnabled[field]')
                    | {{ field }}
                  template(v-else)
                    | {{ field }}

        tbody
          tr(v-for='task in tasksTable', :class='taskRowClass(task)')
            template(v-for='field in tasksFields')
              td(
                v-if='editorMode || showTaskField(field)',
                :class='["field-" + (field.toLowerCase().replace(" ", "_"))]')

                template(v-if='field == "WBS number"')
                  a(:name='taskAnchorName(task["WBS number"], false)')
                  a.wbs-link(
                    :href='taskAnchorName(task["WBS number"], true)') {{ task['WBS number'] }}
                  a(:href='task.url', target='_blank')
                    app-icon(icon="external")
                template(v-else-if='field == "Name"')
                  span.task-indentation(v-html='taskIndentation(task)')
                  | {{ task['Name'] }}
                template(v-else-if='field == "Task Status"')
                  template(v-if='task[field]')
                    .ui.task.label(:class='[taskStatusClass(task)]') {{ task[field] }}
                    .ui.task.red.label(v-if='task.overdue') Overdue
                template(v-else-if='field == "Dependencies"')
                  template(v-for='dependency in task.dependencies')
                    a(:href='taskAnchorName(dependency[1], true)') {{ dependency[1] }}
                    a(:href='dependency[2]', target='_blank')
                    app-icon(icon="external")
                template(v-else-if='field == "Last Comment"')
                  div(v-html='task[field]')
                template(v-else)
                  | {{ task[field] }}

    template(v-if='showKpi')
      h3 KPIs
      table.ui.celled.unstackable.compact.table
        tbody
          thead
            tr
              td(v-for='field in kpiFields')
                strong
                  template
                    | {{ field }}

          tbody
            tr(v-for='data in kpiFieldData')
              td(v-for='field in kpiFields')
                template
                  | {{ data[field] }}

</template>

<script>
/* global $ */
export default {
  props: {
    title: String,
    generated: String,
    showKpi: Boolean,
    updateUrl: String,
    includeTasks: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return Object.assign(window.snapshotContent, {
      taskAlwaysShow: ["Name", "WBS number"],
      milestoneAlwaysShow: ["Name", "MS number"],
      projectAlwaysShow: ["Name"],
      editorMode: false
    });
  },
  methods: {
    toggleEditorMode() {
      if (this.editorMode) {
        this.savePreferences();
      }
      this.editorMode = !this.editorMode;
    },
    taskRowClass(task) {
      return {
        task: !task.has_children,
        "task-group": task.has_children,
        milestone: task["Is Milestone?"] === "Yes"
      };
    },
    taskStatusClass(task) {
      return this.statusClass(task, "Task Status");
    },
    milestoneStatusClass(milestone) {
      return this.statusClass(milestone, "Status");
    },
    statusClass(task, field) {
      const status = task[field];
      if (!status) {
        return "";
      }

      return status.toLowerCase().replace(" ", "_");
    },
    taskAnchorName(wbs, includeHash) {
      return `${includeHash ? "#" : ""}resource_${wbs
        .toLowerCase()
        .replace(" ", "_")}`;
    },
    alwaysShowTaskField(column) {
      return this.taskAlwaysShow.indexOf(column) > -1;
    },
    showTaskField(column) {
      return this.alwaysShowTaskField(column) || this.taskFieldsEnabled[column];
    },
    showMilestoneField(column) {
      return (
        this.alwaysShowMilestoneField(column) ||
        this.milestoneFieldsEnabled[column]
      );
    },
    alwaysShowMilestoneField(column) {
      return this.milestoneAlwaysShow.indexOf(column) > -1;
    },
    alwaysShowProjectField(column) {
      return this.projectAlwaysShow.indexOf(column) > -1;
    },
    showProjectField(column) {
      return (
        this.alwaysShowProjectField(column) || this.projectFieldsEnabled[column]
      );
    },
    taskIndentation(task) {
      const wbs = task["WBS number"];
      const count = wbs.split(".").filter(a => a !== "").length;
      let result = "";
      let i;

      for (i = 1; i < count; i += 1) {
        result += "&nbsp;&nbsp;&nbsp;";
      }

      return result;
    },
    savePreferences() {
      const projectFields = [];
      const taskFields = [];
      const milestoneFields = [];
      let keys;

      keys = Object.keys(this.projectFieldsEnabled);
      for (let i = 0; i < keys.length; i += 1) {
        const f = keys[i];

        if (this.projectFieldsEnabled[f]) {
          projectFields.push(f);
        }
      }

      keys = Object.keys(this.taskFieldsEnabled);
      for (let i = 0; i < keys.length; i += 1) {
        const f = keys[i];

        if (this.taskFieldsEnabled[f]) {
          taskFields.push(f);
        }
      }

      keys = Object.keys(this.milestoneFieldsEnabled);
      for (let i = 0; i < keys.length; i += 1) {
        const f = keys[i];

        if (this.milestoneFieldsEnabled[f]) {
          milestoneFields.push(f);
        }
      }

      $.post(this.updateUrl, {
        task_fields_enabled: taskFields.join(","),
        project_fields_enabled: projectFields.join(","),
        milestone_fields_enabled: milestoneFields.join(",")
      });
    }
  }
};
</script>
