<script>
/* global accounting */
/* eslint-disable */

import { Components, Helpers } from "manageplaces-ui-kit";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

import PurchaseOrders from "@/graphql/queries/finance/PurchaseOrders.gql";
import ProjectsQuery from "@/graphql/queries/core/projects/Projects.gql";
import PurchaseOrderManager from "./PurchaseOrderManager";
import PurchaseOrderStatusCellRenderer from "./PurchaseOrderStatusCellRenderer.vue";
import { orderMenuItems, ACTIONS } from "./PurchaseContextMenuItems";

export default {
  extends: Components.BaseTable,
  apollo: {
    orders: {
      query: PurchaseOrders,
      variables() {
        let vars = {};

        if (this.searchTerm) {
          vars.search = this.searchTerm;
        }

        vars.where = this.filters;
        if (this.projectId) {
          vars.where.project = this.projectId;
        }

        return vars;
      },
      update({ purchaseOrders }) {
        return purchaseOrders;
      },
      result() {
        this.setRowData(this.orders);
        this.stopLoading();
      },
      fetchPolicy: "no-cache"
    },
    projects: {
      query: ProjectsQuery,

      update(data) {
        return data.projects.edges.map(({ node }) => {
          return { label: node.name, value: node.id };
        });
      },

      fetchPolicy: "no-cache"
    }
  },

  props: {
    projectId: {
      type: [String, Number],
      required: false
    }
  },

  data() {
    const vm = this;
    let columns = [
      {
        headerName: "#",
        field: "number",
        cellRenderer: "link",
        cellRendererParams: {
          onClick: order =>
            (window.location.href = `/projects/${order.project.id}/purchase_orders/${order.id}`)
        }
      },
      {
        headerName: "Date",
        field: "issuedDate",
        cellRenderer: "date",
        width: 150
      },
      {
        headerName: "Delivery date",
        field: "deliveryDate",
        cellRenderer: "date",
        width: 150
      },
      {
        headerName: "Contact",
        field: "contact",
        valueGetter({ data }) {
          return data.contact.name;
        }
      }
    ];

    if (!this.projectId) {
      columns.push({
        headerName: "Project",
        field: "project",
        cellRenderer: "link",
        cellRendererParams: {
          onClick: order =>
            (window.location.href = `/projects/${order.project.id}`)
        },
        comparator(a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        },
        valueGetter({ data }) {
          if (!data.project) {
            return "";
          }

          return `${data.project.sequence} - ${data.project.name}`;
        }
      });
    }

    columns.push(
      {
        headerName: "Amount",
        field: "amount",
        valueGetter({ data }) {
          return vm.formatMoney(data.amount, data.currency);
        }
      },
      {
        headerName: "Status",
        field: "status",
        cellRenderer: "status",
        width: 150,
        valueGetter(params) {
          return params.data.status;
        }
      },
      Helpers.table.actionsCell()
    );

    return {
      filters: {},
      orderManager: new PurchaseOrderManager(),
      columns,
      config: {
        actionBar: false,
        resourceType: "Purchase order",
        filters: {
          name: {
            type: "text",
            title: "Number",
            description: "Filter orders by number",
            field: "number"
          },
          date: {
            type: "date",
            title: "Date",
            description: "Filter orders by date",
            field: "issuedDate"
          },
          due_date: {
            type: "date",
            title: "Due date",
            description: "Filter orders by due date",
            field: "deliveryDate"
          },
          status: {
            type: "option",
            title: "Status",
            description: "Filter orders by status",
            field: "status",
            options: () => [
              {
                label: "Draft",
                value: "draft"
              },
              {
                label: "Submitted",
                value: "submitted"
              },
              {
                label: "Authorised",
                value: "authorised"
              },
              {
                label: "Voided",
                value: "voided"
              }
            ]
          },
          project: {
            type: "option",
            title: "Projects",
            description: "Filter orders by project",
            field: "project",
            hidden: this.projectId,
            options: () => this.projects
          }
        }
      },
      components: {
        vue: {
          status: PurchaseOrderStatusCellRenderer
        }
      }
    };
  },

  computed: {},

  mounted() {
    this.startLoading();
  },

  methods: {
    performFiltering(filters) {
      this.startLoading();
      this.filters = filters;
      this.$apollo.queries.orders.refetch();
    },
    formatMoney(money, currency) {
      return accounting.formatMoney(money, this.moneyOpts(currency));
    },
    moneyOpts(currency) {
      if (currency === null) {
        return {};
      }

      return {
        symbol: currency.symbol,
        decimal: currency.decimal_mark,
        thousand: currency.thousands_separator,
        precision: currency.decimal_places
      };
    },
    getButtons(h) {
      const buttons = [
        h(
          Components.AppButton,
          { props: { primary: true }, on: { click: this.newOrder } },
          "New purchase order"
        )
      ];

      return buttons;
    },
    newOrder() {
      if (this.projectId) {
        window.location.href = `/projects/${this.projectId}/purchase_orders/new`;
      } else {
        window.location.href = "/purchase_orders/new";
      }
    },
    getContextMenuItems(row) {
      return orderMenuItems(row);
    },
    contextMenuItemClicked(item, row) {
      switch (item.action) {
        case ACTIONS.EDIT:
          window.location.href = `/purchase_orders/${row.data.id}/edit`;
          break;

        case ACTIONS.DELETE:
          this.delete(row, {
            title: "Are you sure you want to delete this purchase order?",
            message: "Deleting a purchase order cannot be undone",
            success: "Purchase order successfully deleted"
          });
          break;
        case ACTIONS.VOID:
          this.void(row, {
            title: "Are you sure you want to void this purchase order?",
            message: "Voiding a purchase order cannot be undone",
            success: "Purchase order successfully voided"
          });
          break;
      }
    },
    delete(rows, opts) {
      if (rows.constructor !== Array) {
        rows = [rows];
      }

      const orders = rows.map(row => row.data);
      this.$dialog
        .confirm({
          title: opts.title,
          message: opts.message
        })
        .onOk(({ api }) => {
          api.hide();
          this.orderManager
            .delete(orders)
            .then(() => {
              this.$flash.success(opts.success);
              this.removeRows(rows);
            })
            .catch(e => {
              this.$flash.error(gqlErrorMessage(e));
            });
        });
    },
    void(rows, opts) {
      if (rows.constructor !== Array) {
        rows = [rows];
      }

      const orders = rows.map(row => row.data);
      this.$dialog
        .confirm({
          title: opts.title,
          message: opts.message
        })
        .onOk(({ api }) => {
          api.hide();
          this.orderManager
            .void(orders)
            .then(() => {
              this.$flash.success(opts.success);
              this.$apollo.queries.orders.refetch();
            })
            .catch(e => {
              this.$flash.error(gqlErrorMessage(e));
            });
        });
    },
    removeRows(rows) {
      this.gridApi().applyTransaction({
        remove: rows
      });
    }
  }
};
</script>
