<template lang="pug">
  .flex.flex-row
    app-icon(icon="phone").mr-4
    .flex.flex-col.flex-grow

      .flex.flex-row
        strong.flex-grow Phone numbers
        a(@click.prevent="editPhoneNumberToggle") Edit

      template(v-if="!editPhoneNumber")
        span.italic(v-if="!phoneNumbers.length") No phone numbers 
        .mt-4(v-else-if="readOnly")
          .phone.flex.flex-row.border-b.border-grey-30.py-2(v-if="readOnly" v-for="phone in phoneNumbers" :key="phone.key")
            strong.flex-grow-0.mr-8.w-24 {{ humanize(phone.phoneNumberType) }}
            .flex.flex-col.flex-grow 
              a(:href="`tel:${phone.phoneNumber}`") {{ phone.phoneNumber}}

      app-categorised-list-editor.mt-4(
        v-if="editPhoneNumber"
        :categories="categories"
        :value="editingPhoneNumbers"
        resource-type-label="Phone number"
        @cancel="cancelEdit"
        @input="updatePhoneNumbers"
    )
</template>

<script>
import { humanize } from "@/helpers/StringHelpers";
import SetContactPhoneNumbersMutation from "@/graphql/mutations/contact_management/contact_phone_numbers/setContactPhoneNumbers.gql";

export default {
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editPhoneNumber: false,
      readOnly: true,
      phoneNumberTypes: {
        SALES: { label: "Sales", value: "SALES" },
        SUPPORT: { label: "Support", value: "SUPPORT" },
        INFO: { label: "Info", value: "INFO" }
      },
      editingPhoneNumbers: [],
      phoneNumbers: []
    };
  },
  computed: {
    categories() {
      return Object.values(this.phoneNumberTypes);
    }
  },
  mounted() {
    this.phoneNumbers = this.contact.phoneNumbers.map(phoneNumbers => ({
      ...phoneNumbers,
      key: Math.random()
        .toString(36)
        .substr(2, 9)
    }));
  },
  methods: {
    humanize,
    editPhoneNumberToggle() {
      this.editingPhoneNumbers = this.phoneNumbers.map(phoneNumber => ({
        value: phoneNumber.phoneNumber,
        category: this.phoneNumberTypes[phoneNumber.phoneNumberType],
        key: phoneNumber.key
      }));
      (this.editPhoneNumber = true), (this.readOnly = false);
    },
    cancelEdit() {
      (this.editPhoneNumber = false),
        (this.readOnly = true),
        (this.editingPhoneNumbers = []);
    },
    updatePhoneNumbers(newPhoneNumbers) {
      let inputPhoneNumbers = newPhoneNumbers.map(phoneNumber => ({
        phoneNumberType: phoneNumber.category.value,
        phoneNumber: phoneNumber.value
      }));
      return this.$apollo
        .mutate({
          mutation: SetContactPhoneNumbersMutation,
          variables: {
            input: {
              contactId: this.contact.id,
              phoneNumbers: inputPhoneNumbers
            }
          }
        })
        .then(() => {
          this.$flash.success("Contact phone numbers updated");
          this.phoneNumbers = inputPhoneNumbers.map(phoneNumber => ({
            ...phoneNumber,
            key: Math.random()
              .toString(36)
              .substr(2, 9)
          }));
          this.editPhoneNumber = false;
          this.readOnly = true;
          this.editingPhoneNumbers = [];
          this.$emit("input", this.phoneNumbers);
        });
    }
  }
};
</script>
<style type="text/css">
.phone:last-child {
  border: none;
}
</style>
