import { runMutation } from "@/helpers/GraphQLHelpers";
import DeleteProjects from "@/graphql/mutations/projects/DeleteProjects.gql";
import CloseProjects from "@/graphql/mutations/projects/CloseProjects.gql";

export default class ProjectManager {
  close(projects) {
    const projectIds = projects.map(project => project.id);

    return runMutation(CloseProjects, { projectIds });
  }

  delete(projects) {
    const projectIds = projects.map(project => project.id);

    return runMutation(DeleteProjects, { projectIds });
  }
}
