<template lang="pug">
  td(:class='classes')
    date-picker(
      classes='datepicker-container',
      :value='computedValue',
      @input='updateValue',
      :placeholder='placeholder',
      v-if='cell.label.field_type === "date"'
    )

    textarea(
      :value='computedValue',
      @input='updateValue($event.target.value)',
      :placeholder='placeholder',
      v-else-if='cell.label.field_type === "textarea"'
    )

    mp-multiselect(
      :value='computedValue',
      :options='cell.label.options',
      :placeholder='placeholder',
      :label='null',
      :track-by='null',
      @input='updateValue',
      v-else-if='cell.label.field_type === "dropdown"'
    )

    text-input(
      :value='computedValue',
      @input='updateValue',
      :placeholder='placeholder',
      v-else-if='cell.label.field_type === "text"'
    )

    number-input(
      :value='computedValue',
      @input='updateValue',
      :placeholder='placeholder',
      :currency='currency',
      :disabled='!!formula',
      type='currency',
      classes='input-number',
      v-else-if='cell.label.field_type === "currency"'
    )

    number-input(
      :value='computedValue',
      @input='updateValue',
      :placeholder='placeholder',
      :precision='cell.label.decimal_places',
      :disabled='!!formula',
      classes='input-number',
      v-else-if='cell.label.field_type === "integer"'
    )
</template>

<script>
import NumberInput from "@/components/inputs/NumberInput.vue";
import MpMultiselect from "@/components/inputs/dropdowns/MpMultiselect.vue";
import DatePicker from "@/components/inputs/inputs/DatePicker.vue";
import { evalFormula, RowWrapper } from "./CellHelpers";
import TextInput from "../inputs/TextInput.vue";

export default {
  components: {
    NumberInput,
    TextInput,
    MpMultiselect,
    DatePicker
  },
  props: ["cell", "currency", "row", "classes"],
  computed: {
    formula() {
      return this.cell.label.formula;
    },
    placeholder() {
      return this.cell.label.label;
    },
    computedValue() {
      let rowWrapper;

      if (this.formula !== null) {
        rowWrapper = new RowWrapper(this.row);
        return evalFormula(rowWrapper, this.cell.label.formula);
      }
      return this.cell.value.content;
    }
  },
  methods: {
    updateValue(value) {
      this.cell.value.content = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.tabular-form {
  td > .multiselect {
    min-width: 14em;
  }
}
</style>
