<template lang="pug">
  base-modal(
    :name='modalName',
  )
</template>

<script>
import BaseModal from "@/components/modals/BaseModal.vue";

export default {
  components: {
    BaseModal
  },
  props: {
    resourceType: String
  },
  computed: {
    modalName() {
      return `${this.resourceType}-file-generating-modal`;
    }
  }
};
</script>
