<template lang="pug">
  .block.my-3
    app-label.capitalize(:colour="color") {{ params.value }}

</template>

<script>
import Vue from "vue";

const COLORS = {
  draft: "grey",
  submitted: "yellow",
  authorised: "blue",
  rejected: "red",
  deleted: "red"
};

export default Vue.extend({
  computed: {
    color() {
      return COLORS[this.params.value];
    }
  }
});
</script>
