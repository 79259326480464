<script>
/* global $ */

import { Components } from "manageplaces-ui-kit";
import store from "@/store";

const signOut = () => {
  const csrfToken = $.rails.csrfToken();
  const csrfParam = $.rails.csrfParam();
  const form = document.createElement("form");
  let formContent = `<input name='_method' value='DELETE' type='hidden' />`;

  formContent += `<input name='${csrfParam}' value='${csrfToken}' type='hidden' />`;
  formContent += '<input type="submit" />';

  form.method = "post";
  form.action = "/users/sign_out";
  form.innerHTML = formContent;
  form.style.display = "none";

  document.body.appendChild(form);
  form.querySelector('[type="submit"]').click();
};
const getCurrentUser = (h, user) => {
  return [
    h("p", { staticClass: "mr-16" }, "Logged in as"),
    h("strong", { staticClass: "mr-8" }, user.name)
  ];
};

export default {
  functional: true,
  render(h) {
    const user = store.state.currentUser;
    const options = [
      { type: "content", slot: "currentUser" },
      { type: "divider" },
      { label: "My profile", icon: "user", href: "/profile" },
      { label: "Sign out", icon: "padlock", signOut: true }
    ];

    const avatar = h(Components.AppAvatar, {
      staticClass: "cursor-pointer",
      props: { avatar: user.avatar }
    });
    const dropdown = h(Components.AppDropdown, {
      props: { options: options, pointing: true },
      scopedSlots: {
        trigger: () => avatar,
        currentUser: () => getCurrentUser(h, user)
      },
      on: {
        click: item => {
          if (item.signOut) {
            signOut();
          } else {
            window.location.href = item.href;
          }
        }
      }
    });

    return dropdown;
  }
};
</script>
