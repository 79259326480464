<script>
/* global $, $D, Stripe, accounting */
/* eslint-disable */

import path from 'ramda/src/path';
import SubscriptionQuery from '@/graphql/queries/core/company/Subscription.gql';
import UpdateCardMutation from '@/graphql/mutations/subscriptions/UpdatePaymentCard.gql';
import CountryPicker from '@/legacy/inputs/CountryDropdownInput.vue';

export default {
  components: {
    CountryPicker
  },
  data() {
    return {
      cardToken: null,

      subscription: {
        billingForename: null,
        billingSurname: null,
        billingEmail: null,
        billingAddress1: null,
        billingAddress2: null,
        billingAddressCity: null,
        billingAddressCountry: null,
        billingAddressRegion: null,
        billingAddressPostcode: null,
        taxNumber: null,
        voucher: null,
      },

      taxRate: {
        rate: null,
        name: null,
        summary: null,
        description: null,
      },

      elements: {
        card: null,
        expiry: null,
        cvc: null,
        ready: {
          card: false,
          expiry: false,
          cvc: false,
        },
      },

      regions: [],
      currency: null,
      quantity: 1,
      form: null,
      stripe: null,

      loading: true,
      loadingMessage: null,
      errors: {
        billingDetails: {},
        cardDetails: {}
      }
    };
  },
  props: {
    plan: Number,
    frequency: String,
    gatewayKey: String,
    mode: {
      type: String,
      default: 'full'
    },
  },

  apollo: {
    subscription: SubscriptionQuery,
  },

  created() {
    this.form = $('#payment-form');

    const vm = this;
    let script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/'
    document.body.append(script);

    script.onload = () => {
      vm.loadingMessage = 'Connecting to payment partner...';
      vm.stripe = Stripe(this.gatewayKey);
      vm.setupPaymentForm();
    };
  },

  mounted() {
    this.currency = this.form.data('currency');
  },

  methods: {
    countryChanged(newCountry) {
      this.subscription.billingAddressCountry = newCountry;
    },

    regionChanged(newRegion) {
      this.subscription.billingAddressRegion = newRegion;
    },

    onSubmit(e) {
      this.validate()

      if(this.billingDetailsInvalid){
        this.$refs.bdErrorsBox.scrollIntoView()
        $D.fn.flash.show($D.constants.FLASH_ERROR, 'Please ensure you have entered all required information');
      } else {
        this.loading = true;
        this.stripe.createToken(this.elements.card, this.stripeDetails)
                  .then((result) => {
                    this.handleStripeResponse(result);
                  });
      }

      return false;
    },

    validate() {
      this.errors.billingDetails = {}
      let { errors: { billingDetails: bdErrors }, subscription: sub } = this

      if(!sub.billingForename)        { this.$set(bdErrors, 'firstName', 'First name is required') }
      if(!sub.billingSurname)         { this.$set(bdErrors, 'lastName', 'Last name is required') }
      if(!sub.billingEmail)           { this.$set(bdErrors, 'email', 'Email is required') }
      if(!sub.billingAddress1)        { this.$set(bdErrors, 'address_line1', 'Billing Address Line 1 is required') }
      if(!sub.billingAddressCity)     { this.$set(bdErrors, 'city', 'Billing Address City is required') }
      if(!sub.billingAddressCountry)  { this.$set(bdErrors, 'country', 'Billing Address Country is required') }
      if(!sub.billingAddressPostcode) { this.$set(bdErrors, 'postcode', 'Billing Address Postcode is required') }
    },

    handleStripeResponse(response) {
      const {  error } = response
      this.errors.cardDetails = {}

      if (error) {
        this.loading = false;
        this.$set(this.errors.cardDetails, error.code, error.message)
        $D.fn.flash.show($D.constants.FLASH_ERROR, error.message);
      } else {
        if (this.mode === 'card') {
          this.loadingMessage = 'Updating your card...';
          this.updateCard(response);
        } else {
          this.loadingMessage = 'Setting up your subscription...';
          this.createSubscription(response);
        }
      }
    },

    createSubscription(response) {
      const vm = this;

      this.loading = true;
      fetch('/subscriptions', {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': this.getCSRF(),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify({
          subscription: {
            billingFrequency: this.frequency,
            planId: this.plan,
            cardToken: response.token.id,
            ...this.subscription,
          },
        }),
      })
        .then(response => response.json())
        .then(response => vm.handleSubscriptionResponse(response));
    },

    updateCard(response) {
      const vm = this;

      this.$apollo.mutate({
        mutation: UpdateCardMutation,
        variables: {
          input: {
            cardToken: response.token.id,
          },
        },
      })
        .then((_data) => {
          window.location.href = '/subscriptions';
        })
        .catch((_error) => {
          vm.loading = false;
          $D.fn.flash.show($D.constants.FLASH_ERROR, 'There was a problem updating your card. Please try again');
        })
    },

    getCSRF() {
      let element = document.querySelector('meta[name="csrf-token"]');

      if (element) {
        return element.getAttribute('content');
      } else {
        return '';
      }
    },

    handleSubscriptionResponse(response) {
      const vm = this;

      if (response.success) {
        // All done and setup
        window.location.href = '/subscriptions';
      } else if (response.action === 'payment_method_failed') {
        this.loading = false;
        $D.fn.flash.show($D.constants.FLASH_ERROR, 'Your card was declined. Please try again or try a different card.');
      } else if (response.action === 'authorization_required') {
        this.stripe.handleCardPayment(response.client_secret)
          .then((result) => {
            if (result.error) {
              vm.loading = false;
              $D.fn.flash.show($D.constants.FLASH_ERROR, result.error.message);
            } else {
              // All done
              vm.fulfillSubscription();
            }
          });
      } else {
        this.loading = false;
        $D.fn.flash.show($D.constants.FLASH_ERROR, response.error);
      }
    },

    fulfillSubscription() {
      const vm = this;
      fetch('/subscriptions/fulfill', {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': this.getCSRF(),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
        method: 'POST',
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            window.location.href = '/subscriptions'
          } else {
            vm.loading = false;
            $D.fn.flash.show($D.constants.FLASH_ERROR, 'An unexpected error occurred. Please try again');
          }
        });
    },

    reCalculateTaxes() {
      const countryCode = path(['billingAddressCountry', 'code'], this.subscription);
      if (countryCode === null || countryCode === undefined) {
        return;
      }

      const vm = this;
      const url = '/subscriptions/calculate_tax'
      const params = [
        `country=${countryCode}`,
        `postcode=${this.subscription.billingAddressPostcode}`,
        `tax_number=${this.subscription.taxNumber || ''}`
      ];

      fetch(`${url}?${params.join('&')}`)
        .then(response => response.json())
        .then((response) => {
          const subtotal = vm.form.data('amount');
          const tax = subtotal * (response.rate / 100.0);

          vm.taxRate.rate = response.rate;
          vm.taxRate.name = response.name;
          vm.taxRate.summary = response.summary;
          vm.taxRate.description = response.description;
        });
    },

    setupPaymentForm() {
      const baseInputStyles = {
        base: {
          fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
          color: '#506470',
          fontSize: '14px',
          lineHeight: '1.21428571em',
        }
      };

      const vm = this;
      const elements = this.stripe.elements();

      this.elements.card = elements.create('cardNumber', {});
      this.elements.expiry = elements.create('cardExpiry', { style: { base: { textAlign: 'center' }}});
      this.elements.cvc = elements.create('cardCvc', { style: { base: { textAlign: 'center' }}});

      this.elements.card.on('ready', () => {
        vm.elements.ready.card = true;
        vm.updateReadyState();
      });

      this.elements.card.on('error', () => {
        vm.elements.ready.card = false
        this.$set(this.errors, 'elements.card', 'Card number is invalid')
        vm.updateReadyState()
      })

      this.elements.expiry.on('ready', () => {
        vm.elements.ready.expiry = true;
        vm.updateReadyState();
      });

      this.elements.cvc.on('ready', () => {
        vm.elements.ready.cvc = true;
        vm.updateReadyState();
      });

      this.elements.card.mount('#card-element');
      this.elements.expiry.mount('#expiry-element');
      this.elements.cvc.mount('#cvc-element');
    },

    updateReadyState() {
      const { card, cvc, expiry } = this.elements.ready;

      if (card && cvc && expiry) {
        this.loading = false;
      }
    },
  },

  computed: {
    billingDetailsInvalid() {
      return !!Object.keys(this.errors.billingDetails).length
    },

      cardDetailsInvalid() {
        return !!Object.keys(this.errors.cardDetails).length
      },

      moneyOpts() {
        if (this.currency === null) { return {}; }

        return {
          symbol: this.currency.symbol,
          decimal: this.currency.decimal_mark,
          thousand: this.currency.thousands_separator,
          precision: this.currency.decimal_places
        };
      },
      stripeDetails() {
        return {
          name: `${this.subscription.billingForename} ${this.subscription.billingSurname}`,
          address_line1: this.subscription.billingAddress1 || '',
          address_line2: this.subscription.billingAddress2 || '',
          address_city: this.subscription.billAddressCity || '',
          address_state: this.subscription.billingAddressRegion || '',
          address_zip: this.subscription.billingAddressPostcode || '',
          address_country: this.subscription.billingAddressCountry.code || '',
        };
      },

      subtotal() {
        return accounting.formatMoney(this.subtotalAmount, this.moneyOpts);
      },

      subtotalAmount() {
        return this.form.data('amount');
      },

      taxSummary() {
        return `${accounting.formatMoney(this.taxAmount, this.moneyOpts)} ${this.taxRate.summary || ''}`;
      },

      invoiceTotal() {
        return accounting.formatMoney(this.totalAmount, this.moneyOpts);
      },

      taxAmount() {
        return this.subtotalAmount * (this.taxRate.rate / 100.0);
      },

      totalAmount() {
        return this.subtotalAmount + this.taxAmount;
      },
  },

  watch: {
    'subscription.billingAddressCountry': function(newVal) {
      this.reCalculateTaxes();
    },

      'subscription.billingAddressPostcode': function(newVal) {
        this.reCalculateTaxes();
      },

      'subscription.taxNumber': function(newVal) {
        this.reCalculateTaxes();
      },
  },
};
</script>
