<template lang="pug">
  app-panel.panel.subscription-card.mb-4(v-if="!subscription.isTrial")
    h2.font-bold.mb-4 Your add-ons
    .flex.flex-row.justify-between.flex-initial
      .flex-1
        .font-bold Finance Management
        span.text-grey-60 {{ financeManagementStatus ? "Enabled" : "Disabled" }}

      .flex-initial
        span.font-bold.text-xl {{currency}}{{price}}
        span.ml-1 {{billingTypeLabel}}

    template(v-if="subscription.plan.name.toLowerCase() != 'free' && subscription.managedByStripe")
      app-button(primary
        class="text-center focus:outline-none focus:shadow-outline mt-5 card-submit"
        @click.prevent="onSubmit"
        data-cy="open-addon-submit"
      ) Manage add-ons

</template>

<script>
import { normalizeFloat } from "@/helpers/StringHelpers";

export default {
  name: "SubscriptionAddOns",
  props: {
    subscription: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    subscriptionFee: {
      type: Number,
      required: true
    },
    userLicenses: {
      type: Number,
      required: true,
      default: 1
    },
    financeManagementStatus: {
      type: Boolean,
      required: true
    },
    currency: {
      type: String,
      required: true
    }
  },
  computed: {
    billingTypeLabel() {
      const { billingFrequency } = this.subscription;
      return billingFrequency === "a" ? "/ year" : "/ month";
    },
    price() {
      const { plan, isTrial } = this.subscription;
      if (plan.name.toLowerCase() === "free") return 0;
      return isTrial || !this.financeManagementStatus
        ? "--"
        : normalizeFloat(this.subscriptionFee);
    }
  },
  methods: {
    onSubmit() {
      this.$emit("submit");
    }
  }
};
</script>
