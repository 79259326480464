import { render, staticRenderFns } from "./DocumentsUploadModal.vue?vue&type=template&id=15f569aa&scoped=true&lang=pug&"
import script from "./DocumentsUploadModal.vue?vue&type=script&lang=js&"
export * from "./DocumentsUploadModal.vue?vue&type=script&lang=js&"
import style0 from "./DocumentsUploadModal.vue?vue&type=style&index=0&id=15f569aa&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15f569aa",
  null
  
)

export default component.exports