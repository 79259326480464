export default {
  rows: [
    {
      cols: [
        {
          view: "grid",
          id: "grid",
          scrollX: "scrollHor",
          scrollY: "scrollVer"
        },
        { resizer: true, width: 1 },
        {
          view: "timeline",
          id: "timeline",
          scrollX: "scrollHor",
          scrollY: "scrollVer"
        },
        { view: "scrollbar", scroll: "y", id: "scrollVer" }
      ]
    },
    { view: "scrollbar", scroll: "x", id: "scrollHor", height: 20 }
  ]
};
