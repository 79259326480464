export default {
  data() {
    return {
      expanded: true
    };
  },
  computed: {
    collapseIcon() {
      return this.expanded ? "caretDown" : "caretRight";
    }
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    }
  }
};
