import App from "../App.vue";

const NewPurchaseOrder = () =>
  import(
    /* webpackChunkName: "new-purchase-order" */ "../views/finances/purchase_orders/New.vue"
  ).then(m => m.default);
const EditPurchaseOrder = () =>
  import(
    /* webpackChunkName: "edit-purchase-order" */ "../views/finances/purchase_orders/Edit.vue"
  ).then(m => m.default);

export default [
  {
    name: "Purchase orders",
    path: "/purchase_orders",
    component: App,
    children: [
      {
        path: "/projects/:id/purchase_orders/new",
        component: NewPurchaseOrder,
        meta: { title: "Add purchase order" }
      },
      {
        path: "new",
        component: NewPurchaseOrder,
        meta: { title: "Add purchase order" }
      },
      {
        path: ":id/edit",
        component: EditPurchaseOrder,
        meta: { title: "Edit purchase order" }
      }
    ]
  }
];
