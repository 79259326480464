import ReportsIndex from "../views/reports/Index.vue";

const TradesDiary = () =>
  import(
    /* webpackChunkName: "reports-trades-diary" */ "../views/reports/TradesDiary.vue"
  );
const Reports = () =>
  import(
    /* webpackChunkName: "reports-trades-diary" */ "../views/reports/Reports.vue"
  );

export default [
  {
    path: "/reports",
    component: ReportsIndex,
    children: [
      {
        name: "reports",
        path: "",
        component: Reports,
        meta: { title: "Reports" }
      },
      {
        name: "trades_diary",
        path: "trades-diary",
        component: TradesDiary,
        meta: { title: "Trades Diary" }
      }
    ]
  }
];
