<template lang="pug">
  div.addon-tab
    div.addon-tab-content
      .page.loader.text-center.page-loader(v-if="loading")
        .circle
          .ring
      .text-grey-60(:class="loading ? 'bg-grey-10 opacity-25' : ''")
        app-panel.panel.addon-item(secondary)
          .flex.flex-row.justify-between.flex-initial
            .flex-1
              h2.text-grey-80.font-bold User license
              span 1 license per user
            .flex.flex-initial
              span.text-grey-80.font-bold.text-xl {{currency}}{{userLicensePrice}}
              span.ml-1 {{billingTypeLabel}}

          .mt-8
            span How many additional user licenses do you need?
            app-stepper-field.addon-input(
                  class="mb-0"
                  data-cy="licenses-input"
                  description="",
                  v-model="licenses",
                  :min="minNumberOfLicenses")

        app-panel.panel.mt-4.addon-item(secondary)
          h3.text-grey-80.font-bold.mb-4 Summary
          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              .text-grey-80.font-bold {{numberOfChangedLicenses >= 0 ? "Licenses added" : "Licenses removed"}}
            .flex-initial {{Math.abs(numberOfChangedLicenses)}}
          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              .text-grey-80.font-bold {{billingLabel}} cost change
            .flex-initial {{costChange}}

          .h-px.bg-grey-30.my-4

          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              .text-grey-80.font-bold Owner license
            .flex-initial {{currency}}{{normalizePrice(userLicensePrice)}}
          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              .text-grey-80.font-bold Additional licenses
            .flex-initial {{currency}}{{normalizePrice(userLicensePrice * (totalLicenses - 1))}}
          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              .text-grey-80.font-bold Add-ons
            .flex-initial {{currency}}{{ financeManagementStatus ? normalizePrice(addOnPrice) : 0 }}
          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              span.text-grey-80.font-bold.mr-1 Voucher
              span {{voucherName}}
            .flex-initial -{{currency}}{{normalizePrice(voucherPrice)}}
          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              span.text-grey-80.font-bold {{taxLabel}}
            .flex-initial
              | {{currency}}{{normalizePrice(taxPrice)}}

          .h-px.bg-grey-30.my-4

          .flex.flex-row.justify-between.flex-initial(:class="{ 'text-sm': chargedAmountAtToday !== 0 }")
            .flex-1
              .text-grey-80.font-bold {{newTotalLabel}}
            .flex-initial {{currency}}{{normalizePrice(totalPrice + taxPrice)}}
          .flex.flex-row.justify-between.flex-initial(v-if="chargedAmountAtToday !== 0")
            .flex-1
              .text-grey-80.font-bold Charged today
            .flex-initial {{currency}}{{normalizePrice(chargedAmountAtToday)}}

        .addon-item.my-8
          .text-grey-60 I authorise ManagePlaces to charge my credit / debit card {{currency}}{{normalizePrice(chargedAmountAtToday != 0 ?chargedAmountAtToday : totalPrice + taxPrice)}}
          template(v-if="!inUserSidebar")
            app-button(primary
              class="w-full text-center focus:outline-none focus:shadow-outline mt-1 mb-4"
              @click.prevent="onSubmit"
              data-cy="licenses-submit"
            ) Update licenses
            a(href="#", @click.prevent="$emit('close')",
              data-cy="close-btn") Cancel

</template>

<script>
import { debounce } from "lodash";
import UpcomingInvoiceMutation from "@/graphql/mutations/subscriptions/UpcomingInvoice.gql";
import AppAddInput from "@/components/collections/AppAddInput";
import { normalizeFloat } from "@/helpers/StringHelpers";
import FiananceMixin from "@/mixins/FinanceValidator";
import { taxLabel } from "@/helpers/SubscriptionHelpers";

export default {
  name: "LicenseTab",
  components: {
    AppAddInput
  },
  mixins: [FiananceMixin],
  props: {
    inUserSidebar: {
      type: Boolean,
      default: false
    },
    subscription: {
      type: Object,
      required: true
    },
    userLicensePrice: {
      type: Number,
      required: true
    },
    addOnPrice: {
      type: Number,
      required: true
    },
    additionalLicenses: {
      type: Number,
      required: true
    },
    financeManagementStatus: {
      type: Boolean,
      required: true
    },
    voucher: {
      type: Object,
      required: false,
      default: null
    },
    taxRate: {
      type: Object,
      required: true,
      default() {
        return { name: "VAT", rate: 20.0 };
      }
    },
    userLicense: {
      type: Object,
      required: false,
      default: null
    },
    currency: {
      type: String,
      required: true
    },
    nextPayment: {
      type: Number,
      required: true,
      default: 0
    },
    previousLicenses: {
      type: Number,
      required: true,
      default: 0
    },
    usedLicenses: {
      type: Number,
      required: true,
      default: 0
    },
    onlyAdditional: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      licenses: this.onlyAdditional ? 1 : this.additionalLicenses,
      loading: false,
      upcomingInvoice: null
    };
  },
  computed: {
    // Calculates the total number of licenses for the subscription.
    // This differs depending on whether this component is handling
    // only additional licenses, or the total number.
    totalLicenses() {
      if (!this.onlyAdditional) {
        // + 1 for the owner license
        return this.licenses + 1;
      }

      return this.additionalLicenses + this.licenses + 1;
    },
    minNumberOfLicenses() {
      if (this.onlyAdditional) {
        return 1;
      }

      return this.usedLicenses > 0 ? this.usedLicenses - 1 : 0;
    },
    sum() {
      let sum = this.userLicensePrice * this.totalLicenses;
      if (this.financeManagementStatus && this.addOnPrice) {
        sum += this.addOnPrice;
      }
      return sum;
    },
    voucherName() {
      return this.voucher ? `(${this.voucher.name})` : "";
    },
    voucherPrice() {
      let voucherPrice = 0;
      if (this.voucher) {
        if (this.voucher.percentOff) {
          voucherPrice = (this.sum * this.voucher.percentOff) / 100;
        } else {
          voucherPrice = this.voucher.amountOff;
        }
      }
      return voucherPrice;
    },
    totalPrice() {
      return this.voucherPrice > 0 ? this.sum - this.voucherPrice : this.sum;
    },
    taxLabel() {
      return taxLabel(this.taxRate);
    },
    billingTypeLabel() {
      const { billingFrequency } = this.subscription;
      return billingFrequency === "a" ? "/ year" : "/ month";
    },
    taxPrice() {
      return (this.totalPrice * this.taxRate.rate) / 100;
    },
    billingLabel() {
      const { billingFrequency } = this.subscription;
      return billingFrequency === "a" ? "Annual" : "Monthly";
    },
    costChange() {
      // const diff =
      //   this.userLicensePrice * (this.licenses + 1 - this.previousLicenses);
      const diff = this.userLicensePrice * this.numberOfChangedLicenses;
      const cost = `${this.currency}${normalizeFloat(Math.abs(diff))}`;
      return diff >= 0 ? `+${cost}` : `-${cost}`;
    },
    numberOfChangedLicenses() {
      if (this.onlyAdditional) {
        return this.licenses;
      }

      return this.licenses + 1 - this.previousLicenses;
    },
    newTotalLabel() {
      const { billingFrequency } = this.subscription;
      return billingFrequency === "a"
        ? "New annual total"
        : "New monthly total";
    },
    chargedAmountAtToday() {
      return this.upcomingInvoice ? this.upcomingInvoice.amountDue : 0;
    }
  },
  watch: {
    licenses(newVal) {
      this.debounceGetUpcomingInvoice(newVal);
      if (this.inUserSidebar) this.onSubmit();
    }
  },
  mounted() {
    if (this.onlyAdditional) {
      this.debounceGetUpcomingInvoice(1);
      if (this.inUserSidebar) {
        this.onSubmit();
      }
    }
  },
  created() {
    this.debounceGetUpcomingInvoice = debounce(this.getUpcomingInvoice, 300);
  },
  methods: {
    onSubmit() {
      this.$emit("update-feature", {
        quantity: this.totalLicenses,
        licenseId: this.userLicense.licenseId
      });
    },
    getUpcomingInvoice() {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: UpcomingInvoiceMutation,
          variables: {
            input: {
              quantity: this.totalLicenses,
              licenseId: this.userLicense.licenseId
            }
          }
        })
        .then(({ data: { upcomingInvoice } }) => {
          this.loading = false;
          this.upcomingInvoice = upcomingInvoice.invoice;
        })
        .catch(() => {
          this.loading = false;
          this.upcomingInvoice = null;
        });
    }
  }
};
</script>
<style lang="postcss" scoped>
.page-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
}
</style>
