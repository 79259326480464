export const taskStatusOptions = [
  {
    label: "Not started",
    value: "NOT_STARTED"
  },
  {
    label: "Started",
    value: "STARTED"
  },
  {
    label: "Complete",
    value: "COMPLETE"
  }
];

export const milestoneStatusOptions = [
  {
    label: "Not started",
    value: "NOT_STARTED"
  },
  {
    label: "Complete",
    value: "COMPLETE"
  }
];
