import { runMutation } from "@/helpers/GraphQLHelpers";
import DeletePurchaseOrders from "@/graphql/mutations/finance/purchase_orders/DeletePurchaseOrders.gql";
import VoidPurchaseOrders from "@/graphql/mutations/finance/purchase_orders/VoidPurchaseOrders.gql";

export default class PurchaseOrderManager {
  delete(purchaseOrders) {
    const ids = purchaseOrders.map(co => co.id);

    return runMutation(DeletePurchaseOrders, { ids });
  }

  void(purchaseOrders) {
    const ids = purchaseOrders.map(co => co.id);

    return runMutation(VoidPurchaseOrders, { ids });
  }
}
