<template lang="pug">
  .welcome-setup
    .welcome-text(class="xs:p-16 sm:p-24 mx-auto")
      h1.text-2xl We're here to help!
      p.text-xl If you encounter difficulties using ManagePlaces, head to our Support pages and raise a ticket if you cannot find the answer to your question. We are also available to answer questions via the channels below:
      .flex.mt-4.ml-8.text-xl
        app-icon.mr-8(icon="envelope", colour="white", size="medium")
        a(href="mailto:support@manageplaces.com") support@manageplaces.com
      .flex.mt-4.ml-8.text-xl
        app-icon.mr-8(icon="mobilePhone", colour="white", size="medium")
        p.mb-0 +44 (0) 203 988 7950
      .flex.mt-4.ml-8.text-xl
        app-icon.mr-8(icon="globe", colour="white", size="medium")
        a(href="https://support.manageplaces.com" target="_blank") support.manageplaces.com
      p.text-xl.mt-8 We’re also on social media
      .flex.mt-4.ml-8
        a(href="https://www.facebook.com/manageplaces/" target="_blank")
          app-icon.mr-10(icon="facebookSolid", colour="white", size="medium")
        a(href="https://www.youtube.com/channel/UCf8329dOZdgWwpJio5VM_aw" target="_blank")
          app-icon.mr-10(icon="youtubeSolid", colour="white", size="medium")
        a(href="https://twitter.com/manageplaces" target="_blank")
          app-icon.mr-10(icon="twitterSolid", colour="white", size="medium")
        a(href="https://www.linkedin.com/company/manageplaces" target="_blank")
          app-icon.mr-10(icon="linkedInSolid", colour="white", size="medium")

    .flex.justify-end
      div(class="xs:w-full md:w-1/2 signup-form-content")
        div(class="xs:p-16 sm:p-24 mx-auto inner-content")
          app-header.pt-20 You're all set
            template(#subheader) Is there anything we can do to help you get started?
          p If you’d like some help getting up and running, or just want to have a chat about the platform, we can get in touch with you during your trial and help you get up to speed

          app-checkbox.mt-10(option-label="Select me" label="Arrange a free training session with our team", name="tAndC", v-model="arrangeTrainingSession")

          app-button(primary class="w-full text-center focus:outline-none focus:shadow-outline mt-20" @click="nextStep") Create your first project
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      arrangeTrainingSession: this.value?.arrangeTrainingSession
    };
  },
  methods: {
    nextStep() {
      this.$emit("step-increase", {
        arrangeTrainingSession: this.arrangeTrainingSession
      });
    }
  }
};
</script>
<style lang="postcss" scoped>
.welcome-text {
  position: absolute;
  top: 5%;
  color: white;
  width: 50%;
  p:not(.mb-0) {
    margin-bottom: 30px;
  }
  h1 {
    color: white;
    margin-bottom: 30px;
  }
  h2 {
    font-weight: bold;
    color: white;
  }
  a {
    color: white;
  }
}

.signup-form-content .inner-content {
  max-width: 700px;
  background-color: white;
}
</style>
