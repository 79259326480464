<template lang="pug">
  .flex.flex-row.w-full.z-20.bg-white.border-b.border-grey-40.p-5
    .flex.flex-col.flex-1.cursor-pointer(v-for="(step, idx) in steps", :key="step", @click="stepClicked(step)")
      .flex.flex-row.flex-1.items-center
        .flex-1.line(v-if="!isFirstStep(step)", :class="lineBeforeStepClasses(step)")
        .flex-1(v-else)

        app-icon.step(v-if="stepPassed(step)" :class="stepClasses(step)" :icon="stepIcon(step)" size="small" colour="white")
        .step(v-else :class="stepClasses(step)")


        .flex-1.line(v-if="!isLastStep(step)", :class="lineAfterStepClasses(step)")
        .flex-1(v-else)

      .flex.flex-1.justify-center.mt-2(:class="labelClasses(step)") {{ labels[idx] }}
</template>

<script>
import WizardStepsMixin from "@/mixins/WizardSteps.js";

export default {
  mixins: [WizardStepsMixin],

  props: {
    stepLabels: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    labels() {
      if (this.stepLabels.length) {
        return this.stepLabels;
      } else {
        return this.steps;
      }
    }
  },
  methods: {
    labelClasses(step) {
      if (this.stepIs(step)) {
        return this.hasError(step) ? "label active error" : "label active";
      } else if (this.stepPassed(step)) {
        return this.hasError(step) ? "label prev error" : "label prev";
      } else {
        return "label next";
      }
    },

    lineBeforeStepClasses(step) {
      if (this.stepPassed(step) || this.stepIs(step)) {
        const prevStep = this.stepBefore(step);
        return this.hasError(step) || this.hasError(prevStep)
          ? "line active error"
          : "line active";
      } else {
        return "line inactive";
      }
    },

    lineAfterStepClasses(step) {
      if (this.stepPassed(step)) {
        return this.hasError(step) ? "line active error" : "line active";
      } else {
        return "line inactive";
      }
    },

    stepBefore(step) {
      return this.steps[this.stepIdx(step) - 1];
    },

    stepClasses(step) {
      if (this.stepIs(step)) {
        return this.hasError(step) ? "active error" : "active";
      } else if (this.stepPassed(step)) {
        return this.hasError(step) ? "prev error" : "prev";
      } else {
        return "next";
      }
    },

    stepIcon(step) {
      return this.hasError(step) ? "close" : "tick";
    },

    stepPassed(step) {
      return this.currentStepIdx > this.stepIdx(step);
    },

    hasError(step) {
      return this.failedSteps.indexOf(step) != -1;
    },

    stepClicked(step) {
      return this.$emit("step-clicked", step);
    }
  }
};
</script>

<style lang="postcss" scoped>
.line {
  height: 2px;
  &.active {
    @apply bg-tribal-aqua;
    &.error {
      @apply bg-withered-cherry;
    }
  }

  &.inactive {
    @apply bg-grey-40;
  }
}

.step {
  @apply rounded-full h-8 w-8 m-1;
  padding: 6px;

  &.active {
    @apply border-2 border-tribal-aqua shadow-md;
    &.error {
      @apply border-withered-cherry;
    }
  }

  &.prev {
    @apply bg-tribal-aqua;
    &.error {
      @apply bg-withered-cherry;
    }
  }

  &.next {
    @apply border-2 border-grey-40;
  }
}

.label {
  &.active {
    @apply font-bold text-grey-80;
  }

  &.prev {
    @apply text-grey-80;
  }

  &.next {
    @apply text-grey-60;
  }

  &.error {
    @apply text-withered-cherry font-bold;
  }
}
</style>
