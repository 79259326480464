const DocumentShare = () =>
  import(
    /* webpackChunkName: "document-share" */ "../views/documents/DocumentShare.vue"
  ).then(m => m.default);

export default [
  {
    path: "/shares/:id",
    name: "DocumentShare",
    component: DocumentShare
  }
];
