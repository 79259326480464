const configEl = document.getElementById("appConfig");
const config = JSON.parse(configEl.innerHTML);

const projectWizardSteps = [
  "Basic details",
  "Parameters",
  "Template"
  // "Template settings"
];

const taskWizardSteps = ["Basic details", "Timescale", "Advanced"];
const checkoutWizardSteps = ["Plan", "Add-ons", "Billing", "Payment"];
const checkoutOverdueSteps = ["Billing", "Payment"];
const stripePlans = [];

config["wizardState"] = {
  currentStep: "",
  steps: null,
  failedSteps: [],
  failedFields: new Map()
};

config.projectWizardSteps = projectWizardSteps;
config.taskWizardSteps = taskWizardSteps;
config.checkoutWizardSteps = checkoutWizardSteps;
config.activeTab = null;
config.checkoutOverdueSteps = checkoutOverdueSteps;
config.stripePlans = stripePlans;

export default config;
