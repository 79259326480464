<template lang="pug">
  ul
    li.py-2.px-2(:class="isActive ? 'bg-grey-20' : ''")
      .inline-flex.flex-row.items-center(v-if="isNewFolder(folder)" :style="{ paddingLeft: `${depth}rem` }")
        app-icon.flex-none.mr-2.caret-icon.cursor-pointer(:icon="expanded ? 'caretDown' : 'caretRight'", size="xs", @click.native="expanded = !expanded")
        app-text-input.flex-none.pr-8(class="w-2/3" ref="folderNameInput" :value="folder.name", @input="updateNewFolderName" @keyup.enter="saveNewFolderName" )

      .inline-flex.flex-row.items-center(v-else :style="{ paddingLeft: `${depth}rem` }")
        app-icon.flex-none.mr-2.caret-icon.cursor-pointer(:icon="expanded ? 'caretDown' : 'caretRight'", size="xs", @click.native="expanded = !expanded")
        app-file-icon.flex-none.mr-2(extension="folder", size="small")

        span.flex-none.text-black.text-lg.cursor-pointer.pr-8.whitespace-no-wrap(@click="setActiveFolder", :class="isActive ? 'font-bold': ''") {{ folder.name }}

    li(v-for="subfolder in subfolders(folder.path)", :key="subfolder.id", v-show="expanded")
      folder(v-if="isNewFolder(folder)" ref="newFolder" :folder="subfolder", :depth="depth + 1")
      folder(v-else :folder="subfolder", :depth="depth + 1")

</template>

<script>
import { mapGetters, mapState } from "vuex";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import DocumentManager from "@/components/documents/DocumentManager.js";

export default {
  name: "Folder",
  props: {
    depth: {
      type: Number,
      required: false,
      default: 0
    },
    folder: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      expanded: false,
      folderOldName: ""
    };
  },
  computed: {
    ...mapGetters("documentUploadModal", ["subfolders"]),
    ...mapState("documentManagement", ["project"]),
    ...mapState("documentUploadModal", ["newFolder", "activeFolder"]),
    isActive() {
      if (!this.activeFolder) {
        return this.folder.isRoot;
      }

      return this.folder.id === this.activeFolder.id;
    },

    docManager() {
      return new DocumentManager(this.project);
    },

    isParentOfNewFolder() {
      return this.newFolder && this.newFolder.parentPath === this.folder.path;
    }
  },
  watch: {
    newFolder(newValue) {
      if (newValue) {
        this.expanded = this.shouldExpand();
      }
    }
  },
  mounted() {
    this.expanded = this.shouldExpand();
    if (this.isNewFolder()) {
      this.focus();
    }
  },
  methods: {
    setActiveFolder() {
      this.$store.commit("documentUploadModal/setActiveFolder", {
        folder: this.folder
      });
    },

    focus() {
      this.$refs.folderNameInput.$el.focus();
    },
    // Check if this folder should be expanded, based on
    // which folder is active
    shouldExpand() {
      if (!this.activeFolder) {
        return false;
      }

      if (this.isParentOfNewFolder) {
        return true;
      }

      const activePath = this.activeFolder.path;
      return activePath.indexOf(this.folder.path) === 0;
    },

    isNewFolder(folder = this.folder) {
      if (!this.newFolder) return false;
      return this.newFolder.path === folder.path;
    },

    saveNewFolderName(event) {
      const newName = event.target.value;

      this.docManager
        .rename(this.folder, newName)
        .then(() => {
          this.$store.commit("documentUploadModal/clearNewFolder");
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
          this.$store.commit("documentUploadModal/updateNewFolder", {
            ...this.folder,
            name: this.folderOldName
          });
        });
    },

    updateNewFolderName(name) {
      if (!this.folderOldName) this.folderOldName = this.folder.name;

      this.$store.commit("documentUploadModal/updateNewFolderName", name);
    }
  }
};
</script>
