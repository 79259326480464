<template lang="pug">
  .flex.w-full.my-3
    template(v-if="editing")
      .block.w-full
        .block.font-bold Type of task
        app-dropdown-field(class="w-3/4" name="taskTypeId" v-model="taskTypeId" :show-optional="false"
                           :options="taskTypes || []" track-by="id" value-attr="id" label-attr="name")
          template(v-slot:option="taskType")
            .text-lg.text-grey-70.pb-2 {{ taskType.name }}
        .buttons
          app-button.mr-2(primary @click="updateTaskType(taskTypeId)") Save
          app-button(default @click="cancelEditing") Cancel

    template(v-else)
      .block.flex-1
        .w-full.font-bold.mb-2 Task type
        .w-full {{ taskType.name || "N/A" }}

      .flex.font-bold(v-if="task.canEdit")
        app-link.mr-2(@click="startEditing") {{ taskTypeId ? "Edit" : "Add" }}
        app-link(danger @click="clearTaskType" v-if="taskTypeId") Delete
</template>
<script>
import sortBy from "ramda/src/sortBy";
import prop from "ramda/src/prop";
import TaskTypes from "@/graphql/queries/core/tasks/TaskTypes.gql";
import UpdateTaskType from "@/graphql/mutations/tasks/UpdateTaskType.gql";

export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },

  apollo: {
    taskTypes: {
      query: TaskTypes,
      update({ taskTypes }) {
        const sortByName = sortBy(prop("name"));
        return sortByName(taskTypes.edges.map(({ node }) => node));
      }
    }
  },

  data() {
    return {
      editing: false,
      taskType: null,
      taskTypeId: null,
      oldTaskType: null,
      taskTypes: []
    };
  },

  watch: {
    "task.taskType": {
      deep: true,
      immediate: true,
      handler(newTaskType) {
        this.setTaskType(newTaskType);
      }
    }
  },

  methods: {
    setTaskType(taskType) {
      this.taskType = taskType || {};
      this.oldTaskType = this.taskType;
      this.taskTypeId = this.taskType.id;
      this.oldTaskTypeId = this.taskTypeId;
    },

    startEditing() {
      this.editing = true;
    },

    cancelEditing() {
      this.setTaskType(this.oldTaskType);
      this.editing = false;
    },

    clearTaskType(confirm = true) {
      if (confirm) {
        this.$dialog
          .confirm({
            title: "Are you sure you want to clear the type of this task?",
            // Not sure what the message should be,
            // I think the title is enough in this case
            message: "You can set it again anytime you want."
          })
          .onOk(({ api }) => {
            api.hide();
            this.updateTaskType(null, false);
          });
      } else {
        this.updateTaskType(null, false);
      }
    },

    updateTaskType(taskTypeId, confirmDeleteIfNull = true) {
      const { task } = this;
      const isClearingTaskType = this.oldTaskTypeId && !taskTypeId;

      if (isClearingTaskType && confirmDeleteIfNull) {
        this.clearTaskType();
      } else {
        const message = isClearingTaskType
          ? "Task type cleared successfully"
          : "Task type updated successfully";
        this.$apollo
          .mutate({
            mutation: UpdateTaskType,
            variables: {
              input: {
                taskId: task.id,
                taskTypeId
              }
            }
          })
          .then(({ data }) => {
            this.setTaskType(data.updateTaskType.task.taskType || {});
            this.editing = false;
            this.$flash.success(message);
          });
      }
    }
  }
};
</script>
