<template lang="pug">
  app-dialog(@cancel="$emit('cancel')")
    template(#title) Share your document

    p.mt-5 Simply copy the link below and send it to the people you want to share with, and they will be given access
    .flex.justify-between.border-tribal-aqua.border-solid.border.p-1.mt-2.rounded-lg
      input.mr-5(:value="share" class="w-4/5")
      app-button(primary @click="copy") Copy

    app-message.mt-4
      template(#title) Expiration
      p Please note that the link will expire on the {{ expires }} unless you choose to expire it sooner

</template>
<script>
const clipboardy = require("clipboardy");

export default {
  props: {
    share: String,
    expires: String
  },
  methods: {
    copy() {
      clipboardy.write(this.share);
    }
  }
};
</script>
