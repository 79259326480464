<template lang="pug">
  thead
    tr(v-if="groups.length > 0")
      th(v-if="sortable || fixedColumn", :colspan="fixedColumn && sortable ? 2 : 1")
      th(
        v-for="group in groups",
        v-if="groupSize(group.id) > 0",
        :key="group.id",
        :colspan="groupSize(group.id)") {{ group.name }}

      th.collapsing

    tr
      th(v-if="sortable")
      th(v-for="label in labels", :class='classForLabel(label)') {{ label.label }}
      th.collapsing

</template>

<script>
import isNil from "ramda/src/isNil";

export default {
  props: ["groups", "labels", "sortable", "fixedColumn"],
  methods: {
    classForLabel(label) {
      let labels = label.fixed_column ? "fixed" : "";
      labels += label.field_type === "textarea" ? "" : " collapsing";

      return labels;
    },
    groupSize(groupId) {
      let size = 0;
      let i;
      let label;

      for (i = 0; i < this.labels.length; i += 1) {
        label = this.labels[i];

        if (groupId === label.field_group_id) {
          size += 1;
        }
      }

      if (
        !isNil(this.fixedColumn) &&
        this.fixedColumn.field_group_id === groupId
      ) {
        size -= 1;
      }

      return size;
    }
  }
};
</script>
