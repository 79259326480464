<template lang="pug">
  app-panel(compact)
    .ui.form.tabular-form(:class='{ "fixed column": hasFixedColumn && canEdit }')
      .horizontal.scroll
        tabular-form-table(
          :labels='labels',
          :groups='groups',
          :can-edit='canEdit',
          :rows='rows',
          :deleted-rows='deletedRows',
          :currency='currency',
          :sortable='sortable',
          :fixedColumn='getFixedColumn()',
          v-if='canEdit')

        tabular-form-table-readonly(
          :labels='labels',
          :groups='groups',
          :can-edit='canEdit',
          :rows='rows',
          :deleted-rows='deletedRows',
          :currency='currency',
          :sortable='sortable',
          :fixedColumn='getFixedColumn()',
          v-else)

        .p-8.mt-4

          tabular-form-footer(@addRow='addRow')

          .ui.divider

          tabular-form-save-button(:save-in-progress='saveInProgress', @click='save')
          tabular-form-cancel-button(:cancel-url='cancelUrl')
</template>

<script>
/* global $, $D */

import find from "ramda/src/find";
import propEq from "ramda/src/propEq";
import flatten from "ramda/src/flatten";
import addIndex from "ramda/src/addIndex";
import merge from "ramda/src/merge";
import map from "ramda/src/map";
import reject from "ramda/src/reject";
import isNil from "ramda/src/isNil";
import groupBy from "ramda/src/groupBy";
import sort from "ramda/src/sort";
import prop from "ramda/src/prop";
import values from "ramda/src/values";
import mapObjIndexed from "ramda/src/mapObjIndexed";
import TabularFormTable from "./TabularFormTable.vue";
import TabularFormTableReadonly from "./read_only/TabularFormTableReadonly.vue";
import TabularFormFooter from "./TabularFormFooter.vue";
import TabularFormSaveButton from "./TabularFormSaveButton.vue";
import TabularFormCancelButton from "./TabularFormCancelButton.vue";

export default {
  components: {
    TabularFormTable,
    TabularFormTableReadonly,
    TabularFormFooter,
    TabularFormSaveButton,
    TabularFormCancelButton
  },
  props: [
    "get-url",
    "update-url",
    "cancel-url",
    "sortable",
    "has-fixed-column"
  ],
  data() {
    return {
      groups: [],
      labels: [],
      rows: [],
      deletedRows: [],
      currency: {
        symbol: "",
        decimal_mark: "",
        thousands_separator: "",
        decimal_places: 0
      },
      saveInProgress: true,
      canEdit: false,
      fixedColumn: null
    };
  },
  mounted() {
    const vm = this;

    $.get(this.getUrl, this.loadDataFromResponse).done(data => {
      const $scroller = $(vm.$el).find(".horizontal.scroll");
      const $addButton = $(vm.$el).find("#add-button-tabular");
      const $saveButton = $(vm.$el).find("#save-button-tabular");
      const $cancelButton = $(vm.$el).find("#cancel-button-tabular");

      if ($scroller.length) {
        vm.$nextTick(() => {
          $scroller.floatingScroll("update");
        });
      }

      if (!data.can_edit) {
        $addButton.hide();
        $saveButton.hide();
        $cancelButton.hide();
      }
    });
  },
  methods: {
    addRow() {
      this.rows.push(this.buildEmptyRow());
    },
    buildEmptyValue(formLabelId) {
      return {
        id: null,
        content: "",
        form_label_id: formLabelId
      };
    },
    buildEmptyRow() {
      return {
        cells: map(
          label => ({
            label,
            value: this.buildEmptyValue(label.id)
          }),
          this.labels
        )
      };
    },
    buildRows(response) {
      const vm = this;
      const byRowNumber = groupBy(prop("row_number"));

      const getValue = (allValues, formLabelId) => {
        let result = find(propEq("form_label_id", formLabelId), allValues);

        if (result === undefined) {
          result = vm.buildEmptyValue(formLabelId);
        }

        return result;
      };

      const v = values(
        mapObjIndexed(
          allValues => ({
            cells: map(
              formLabel => ({
                label: formLabel,
                value: getValue(allValues, formLabel.id)
              }),
              response.form_labels
            )
          }),
          byRowNumber(response.form_values)
        )
      );

      const index = window.location.pathname.lastIndexOf("/");
      const pathname = window.location.pathname.substr(index);
      if (pathname.startsWith("/project-updates")) {
        const difference = (a, b) => {
          if (a.cells[0].value.content === "") {
            return 1;
          }
          if (b.cells[0].value.content === "") {
            return -1;
          }
          return (
            new Date(a.cells[0].value.content) -
            new Date(b.cells[0].value.content)
          );
        };

        const sortedRows = sort(difference, v);

        return sortedRows;
      }

      return v;
    },
    getFixedColumn() {
      return this.hasFixedColumn ? this.fixedColumn : null;
    },
    loadDataFromResponse(response) {
      this.groups = response.field_groups;
      this.labels = response.form_labels;
      this.fixedColumn = find(propEq("fixed_column", true), this.labels);
      this.rows = this.buildRows(response);
      this.currency = response.currency;
      this.deletedRows = [];
      this.deletedRows.splice(0, this.deletedRows.length);
      this.saveInProgress = false;
      this.canEdit = response.can_edit;
    },
    save() {
      this.saveInProgress = true;
      const vm = this;
      $.ajax(this.updateUrl, {
        method: "PUT",
        data: vm.serializeForm(),
        complete() {
          vm.saveInProgress = false;
        },
        error() {
          $D.fn.flash.show(
            $D.constants.FLASH_ERROR,
            "Error saving data. Please try again."
          );
        },
        success(response) {
          vm.loadDataFromResponse(response);
          $D.fn.flash.show($D.constants.FLASH_SUCCESS, "Save successful.");
        }
      });
    },
    serializeForm() {
      const mapIndexed = addIndex(map);

      const formValues = flatten(
        mapIndexed(
          (row, idx) =>
            map(cell => merge(cell.value, { row_number: idx + 1 }), row.cells),
          this.rows
        )
      );

      const valuesToDelete = reject(
        isNil,
        flatten(
          map(row => map(cell => cell.value.id, row.cells), this.deletedRows)
        )
      );

      return {
        values_to_delete: valuesToDelete,
        form_values: formValues
      };
    }
  }
};
</script>
