<template lang="pug">
  div
    app-header Basic details
      template(v-slot:subheader)
        | Provide a name and a description for your project

    .grid.grid-cols-4.m-auto
      .col-span-4
        app-text-field(name="name" label="Project name" :messages="messages" v-model="project.name")

      .col-span-4.pt-4
        app-text-field(name="description" label="Description" type="richtext" v-model="project.description")

      .col-span-1.pt-4
        app-dropdown-field(name="phaseId" attribute="phase" label="Phase" v-model="project.phaseId" :options="phases" label-attr="name" value-attr="id")

</template>
<script>
import ProjectWatchers from "@/mixins/ProjectWatchers.js";
import ProjectPhasesQuery from "@/graphql/queries/core/company/ProjectPhases.gql";

export default {
  mixins: [ProjectWatchers],

  apollo: {
    phases: {
      query: ProjectPhasesQuery,
      update({ projectPhases }) {
        return projectPhases.edges.map(({ node }) => node);
      }
    }
  },

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      nameTaken: false,
      project: { ...this.value },
      messages: {
        required: "You must give this project a name",
        unique: "Project name already taken"
      }
    };
  },
  mounted() {
    if (this.$route.query.estimate_name) {
      this.project.name = this.$route.query.estimate_name;
    }
  }
};
</script>
