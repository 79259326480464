import Vue from "vue";
import Vuex from "vuex";

import state from "./root/state";
import actions from "./root/actions";
import mutations from "./root/mutations";

import documentManagement from "./root/modules/documentManagement";
import subscription from "./root/modules/subscription";
import taskManagement from "./root/modules/taskManagement";

import createTabPlugin from "./plugins/tab";

Vue.use(Vuex);

const plugins = [createTabPlugin()];

export default new Vuex.Store({
  state,
  actions,
  mutations,
  modules: {
    documentManagement,
    subscription,
    taskManagement
  },
  plugins
});
