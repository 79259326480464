<template lang="pug">
  vue-numeric(
    :class='inputClasses'
    v-model='numberValue',
    :currency='symbol',
    :separator='thousandsSeparator',
    :decimal-separator='decimalPoint',
    :precision='numberPrecision',
    :currency-symbol-position='symbolPosition',
    :empty-value='null',
    :read-only='readOnly',
    :disabled='disabled',
    :minus='true',
    v-on='$listeners',
  )
</template>

<script>
import VueNumeric from "vue-numeric";

export default {
  components: {
    VueNumeric
  },
  props: {
    value: {
      type: [Number, String],
      default: ""
    },
    type: {
      type: String,
      default: "number"
    },
    currency: {
      type: Object
    },
    readOnly: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    precision: {
      type: Number,
      default: 2
    },
    classes: String
  },
  computed: {
    inputClasses() {
      let allClasses = "ui input";
      if (this.classes) {
        allClasses += ` ${this.classes}`;
      }
      if (this.disabled) {
        allClasses += " disabled";
      }
      return allClasses;
    },
    numberValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    isCurrency() {
      return this.type === "currency" && !!this.currency;
    },
    symbol() {
      if (this.isCurrency) {
        return this.currency.symbol;
      }
      if (this.type === "percentage") {
        return "%";
      }
      return "";
    },
    symbolPosition() {
      if (this.isCurrency) {
        return this.currency.symbolFirst ? "prefix" : "suffix";
      }
      if (this.type === "percentage") {
        return "suffix";
      }
      return "prefix";
    },
    decimalPoint() {
      return this.isCurrency ? this.currency.decimalMark : ".";
    },
    thousandsSeparator() {
      return this.isCurrency ? this.currency.thousandsSeparator : ",";
    },
    numberPrecision() {
      return this.isCurrency ? this.currency.precision : this.precision;
    }
  },
  watch: {
    numberValue(newValue) {
      this.$emit("input", newValue);
    }
  },
  methods: {
    onBlurHandler(e) {
      this.$emit("blur", e);
    }
  }
};
</script>
