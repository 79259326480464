<template lang="pug">
  app-panel(secondary)
    app-header(size="h3") Tasks
      template(#subheader)
        | Permissions for managing tasks

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")
      template(#options)
        app-checkbox.mb-6(label="View tasks" :value="true")
        app-checkbox.mb-6(v-if="selected !== 'canManageAssignedTasks'" label="Create new tasks" :value="permissions.createTask" @input="permissionChanged('createTask', $event)")
        app-checkbox.mb-6(label="Edit tasks" :value="permissions.editTask" @input="permissionChanged('editTask', $event)")
        app-checkbox.mb-6(label="Delete tasks" :value="permissions.removeTask" @input="permissionChanged('removeTask', $event)")
        app-checkbox.mb-6(label="View approvers" :value="permissions.viewApprovers" @input="permissionChanged('viewApprovers', $event)")

</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> access tasks",
          value: "cannotManage"
        },
        {
          label: "Can access <strong>assigned tasks</strong> only",
          value: "canManageAssignedTasks",
          slot: "options"
        },
        {
          label:
            "Can access tasks from <strong>assigned projects</strong> only",
          value: "canManageAssignedProjects",
          slot: "options"
        },
        {
          label: "Can access tasks from <strong>any project</strong>",
          value: "canManageAny",
          slot: "options"
        }
      ],
      scopes: {
        cannotManage: "global",
        canManageAssignedTasks: "task",
        canManageAssignedProjects: "project",
        canManageAny: "global"
      },
      selected: "cannotManage",
      permissions: {
        viewTask: true,
        createTask: false,
        editTask: false,
        removeTask: false,
        viewApprovers: false
      },
      scope: "global"
    };
  },
  computed: {
    selectedPermissions() {
      if (!this.permissions.viewTask) {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: this.scope
      };
    }
  },
  mounted() {
    if (this.value.permissions.viewTask) {
      if (this.value && this.value.scope === "project") {
        this.selected = "canManageAssignedProjects";
        this.scope = "project";
      } else if (this.value && this.value.scope === "task") {
        this.selected = "canManageAssignedTasks";
        this.scope = "task";
      } else {
        this.selected = "canManageAny";
        this.scope = "global";
      }
    }
  },
  methods: {
    optionChanged(value) {
      if (value === "canManageAssignedTasks") {
        this.permissions.createTask = false;
      }

      this.permissions.viewTask = value !== "cannotManage";
      this.scope = this.scopes[value];
      this.selected = value;
      this.notifyChange();
    },
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
