<template lang="pug">
  div.addon-tab
    div.addon-tab-content
      app-panel.panel.addon-item(secondary)
        app-text-field(name="companyNumber",
                      class="mb-2",
                      label="Company number",
                      type="text",
                      v-model="taxDetail.companyNumber",
                      @keyup.enter="onSubmit",
                      data-cy="companyNumber-input")

        app-radio-field.mt-8(name="option",
                            label="Do you have a valid tax number?",
                            :class="taxOption == 1 ? 'mb-8' : 'mb-0'"
                            :options="options",
                            v-model="option",
                            data-cy="option-switch")

        template(v-if="taxOption == 1")
          app-text-field.mt-8(name="taxNumber",
                            class="my-2 no-bottom",
                            label="Tax number",
                            type="text",
                            v-model="taxDetail.taxNumber",
                            :validator="$v.taxDetail.taxNumber",
                            :messages="messages",
                            @keyup.enter="onSubmit",
                            data-cy="taxNumber-input")

      .addon-item.my-8
        app-button(primary
          class="w-full text-center focus:outline-none focus:shadow-outline mt-1 mb-4"
          @click.prevent="onSubmit()"
          data-cy="tax-submit"
        ) Update tax information
        a(href="#", @click.prevent="$emit('close')"
          data-cy="close-btn") Cancel

</template>

<script>
import { required } from "vuelidate/lib/validators";
import { INPUT_TYPE_ERROR } from "@/config/help_messages/SubscriptionMessages";

export default {
  name: "TaxTab",
  props: {
    company: {
      type: Object,
      required: true
    },
    subscription: {
      type: Object,
      required: true
    },
    availableCountries: {
      type: Array,
      required: true
    },
    isTaxInvalid: {
      type: Boolean,
      required: true
    },
    taxOption: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      taxDetail: {
        companyNumber: this.subscription.companyNumber,
        taxNumber: this.subscription.taxNumber
      },
      country: this.subscription.billingAddressCountry
        ? this.subscription.billingAddressCountry.code
        : null,
      option: 0,
      options: [
        {
          name: "Yes",
          value: 1
        },
        {
          name: "No",
          value: 0
        }
      ],
      messages: {
        validField: "This field is invalid"
      }
    };
  },
  validations() {
    return {
      taxDetail: {
        taxNumber: {
          required,
          validField: function() {
            return !this.isTaxInvalid;
          }
        }
      },
      country: {
        required
      }
    };
  },
  watch: {
    isTaxInvalid: {
      immediate: true,
      handler() {
        if (this.isTaxInvalid) this.$v.$touch();
      }
    },
    option: {
      immediate: true,
      handler() {
        this.$emit("set-option", this.option);
      }
    }
  },
  created() {
    this.option = this.taxOption;
  },
  methods: {
    onSubmit() {
      const params =
        this.taxOption === 1
          ? { ...this.taxDetail, country: this.country }
          : { companyNumber: this.taxDetail.companyNumber };

      if (this.taxOption === 1) {
        this.$v.$touch();
        if (this.$v.country.$invalid || this.$v.taxDetail.taxNumber.$invalid) {
          this.$flash.error(INPUT_TYPE_ERROR);
          return;
        }
      } else {
        this.$v.country.$reset();
        this.$v.taxDetail.taxNumber.$reset();
      }
      this.$emit("submit", params);
    }
  }
};
</script>
