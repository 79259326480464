/* globals moment */
/* eslint-disable */

/**
 * This directive allows an input to accept a duration
 * formatted string, and automatically convert it into
 * a usable value. It also ensures that only a valid
 * format is entered into the field. This can be either
 * hh:mm, or Xh Ym
 */
export default {
  bind(el, binding) {
    const elem = el;
    if (binding.value) {
      binding.def.initValue(el, binding.value);
    }

    elem.onblur = () => {
      const val = elem.value;
      if (!val) {
        return;
      }
      binding.def.durationUpdated(elem, binding, val);
    };
  },

  /**
   * On page load, if there is already a duration stored it will
   * be stored in seconds. This needs to be transformed into the
   * Xh Ym format.
   */
  initValue(el, value) {
    const elem = el;
    const duration = moment.duration({ seconds: value });
    const hours = duration.hours();
    const mins = duration.minutes();

    elem.value = `${hours}h ${mins}m`;
    elem.setAttribute("data-duration", duration.asMilliseconds());
  },

  /**
   * Once the input has changed, we need to parse the value and
   * make sure it is a valid format. If not, we will revert to
   * the previous value
   */
  durationUpdated(el, binding, value) {
    const elem = el;
    const duration = binding.def.parseDuration(value);
    const previous = elem.getAttribute("data-duration");

    if (duration) {
      elem.value = binding.def.formatDuration(duration);
      elem.setAttribute("data-duration", duration.asMilliseconds());
    } else if (!!previous) {
      // Invalid format, revert to previous duration
      elem.value = binding.def.formatDuration(
        moment.duration({ milliseconds: previous })
      );
    } else {
      // No previous and invalid format. Clear out
      elem.value = null;
      elem.setAttribute("data-duration", null);
    }
  },

  /**
   * Formats the duration into a string that is used as the value
   * of the form input
   */
  formatDuration(duration) {
    const hours =
      duration.hours() === 0 && duration.days() === 1 ? 24 : duration.hours();
    const mins = duration.minutes();

    return `${hours}h ${mins}m`;
  },

  /**
   * Performs the parsing of the value entered into the input
   * field. This will return a moment duration if valid, or null
   * if not.
   */
  parseDuration(value) {
    let matched;
    let parsed;
    let hours;
    let mins;

    if (value === "") {
      return null;
    }
    if (
      (matched = value.match(/(\d{1,2})h ?(\d{1,2})m/i)) !== null ||
      (matched = value.match(/(\d{1,2}:(\d{1,2}))/i)) !== null
    ) {
      hours = parseInt(matched[1]);
      mins = parseInt(matched[2]);

      if (hours > 24) {
        hours = 24;
      }
      if (hours === 24 && mins > 0) {
        mins = 0;
      }
      if (mins >= 60) {
        mins = 59;
      }
      if (hours < 0) {
        hours = 0;
      }
      if (mins < 0) {
        mins = 0;
      }

      return moment.duration({ hours: hours, minutes: mins });
    } else if (!isNaN((parsed = parseFloat(value)))) {
      if (parsed > 24) {
        parsed = 24;
      }
      if (parsed < 0) {
        parsed = 0;
      }

      return moment.duration(parsed, "hours");
    }

    return null;
  }
};
