export default {
  setStepsList(state, stepsListName) {
    state.wizardState.steps = state[stepsListName];
    state.wizardState.currentStep = state[stepsListName][0];
  },

  clearWizardState(state) {
    Object.assign(state.wizardState, {
      currentStep: "",
      failedSteps: [],
      failedFields: new Map()
    });
  },

  setCurrentStep(state, step) {
    state.wizardState.currentStep = step;
  },

  setStepFailed(state, step) {
    const { failedSteps } = state.wizardState;
    if (failedSteps.indexOf(step) !== -1) return;

    state.wizardState.failedSteps = [...failedSteps, step];
  },

  setStepSucceeded(state, step) {
    const { failedSteps } = state.wizardState;
    const idx = failedSteps.indexOf(step);
    if (idx === -1) return;

    failedSteps.splice(idx, 1);
  },

  initInvalidFieldsFor(state, step) {
    const { failedFields } = state.wizardState;

    if (!failedFields.has(step)) {
      failedFields.set(step, new Set());
    }
  },

  setFieldInvalid(state, stepField) {
    const { step, field } = stepField;
    const failedFields = state.wizardState.failedFields.get(step);

    if (failedFields) {
      failedFields.add(field);
    }
  },

  setFieldValid(state, stepField) {
    const { step, field } = stepField;
    const failedFields = state.wizardState.failedFields.get(step);
    if (failedFields) {
      failedFields.delete(field);
    }
  },

  setActiveTab(state, tab) {
    state.activeTab = tab;
  },

  setStripePlans(state, stripePlans) {
    state.stripePlans = Object.assign([], stripePlans);
  }
};
