export default {
  watch: {
    task: {
      deep: true,
      handler(updatedTask) {
        this.$emit("input", updatedTask);
      }
    },

    value: {
      deep: true,
      handler(updatedValue) {
        this.task = updatedValue;
      }
    },

    isSubtask(newVal) {
      if (newVal === "no") {
        this.task.parentId = null;
      }
    }
  }
};
