/* global gantt */
import { getTaskStatusClass } from "@/components/projects/gantt/ProjectGanttUtils";
import { isOverdue } from "./ProjectGanttUtils";

export default {
  methods: {
    configureTemplates() {
      gantt.templates.grid_file = this.getGridFile;
      gantt.templates.grid_folder = () => "";
      gantt.templates.rightside_text = this.getRightsideText;
      gantt.templates.leftside_text = this.getLeftsideText;
      gantt.templates.grid_row_class = gantt.templates.task_class = this.getTaskClass;
      gantt.templates.task_text = () => "";

      this.setDurationColumnTemplate();
    },

    getGridFile(/*task*/) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // if (task.end_date <= today && task.status != "Complete") {
      //   return "<div class='gantt_tree_icon gantt_overdue'></div>";
      // }

      return "";
    },

    getLeftsideText(start, end, task) {
      if (task.type === "mp_milestone") {
        return null;
      }

      return task.duration + (task.duration > 1 ? " days" : " day");
    },

    /**
     * Returns the text shown to the right hand side of each
     * bar in the gantt chart
     **/
    getRightsideText(_start, _end, task) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (
        (task.type === "task" || task.type === "mp_milestone") &&
        isOverdue(task)
      ) {
        const overdue_by =
          Math.floor(
            (Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) -
              Date.UTC(
                task.end_date.getFullYear(),
                task.end_date.getMonth(),
                task.end_date.getDate()
              )) /
              (1000 * 60 * 60 * 24)
          ) + 1;
        return (
          task.text +
          ' <span style="position: relative; left: 0.5em;" class="overdue">(' +
          overdue_by +
          (overdue_by > 1 ? " days" : " day") +
          " overdue)</span>"
        );
      } else {
        return task.text;
      }
    },

    getTaskClass(start, end, task) {
      let css = [];
      if (gantt.hasChild(task.id)) {
        css.push("task-parent");
      }
      if (!task.$open && gantt.hasChild(task.id)) {
        css.push("task-collapsed");
      }
      if (task.type === "mp_milestone") {
        css.push("hide-add-button");
      }

      // automatically add classes to tasks in the timeline area depending on status
      css.push(getTaskStatusClass(task));

      return css.join(" ");
    },

    setDurationColumnTemplate() {
      const durationColumn = gantt.config.columns.filter(
        col => col.name === "duration"
      )[0];
      durationColumn.template = obj => {
        if (obj.type === gantt.config.types.mp_milestone) {
          return "";
        } else {
          return obj.duration + (obj.duration > 1 ? " days" : " day");
        }
      };
    }
  }
};
