<script>
/* global accounting */
/* eslint-disable */

import { Components, Helpers } from "manageplaces-ui-kit";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

import Bills from "@/graphql/queries/finance/bills/Bills.gql";
import ProjectsQuery from "@/graphql/queries/core/projects/Projects.gql";
import BillsManager from "./BillsManager";
import BillStatusCellRenderer from "./BillStatusCellRenderer.vue";
import { billsMenuItems, ACTIONS } from "./BillMenuItems";

export default {
  extends: Components.BaseTable,
  apollo: {
    bills: {
      query: Bills,
      variables() {
        let vars = {};

        if (this.searchTerm) {
          vars.search = this.searchTerm;
        }

        vars.where = this.filters;
        if (this.projectId) {
          vars.where.project = this.projectId;
        }

        return vars;
      },
      update({ bills }) {
        return bills;
      },
      result() {
        this.setRowData(this.bills);
        this.stopLoading();
      },
      fetchPolicy: "no-cache"
    },
    projects: {
      query: ProjectsQuery,

      update(data) {
        return data.projects.edges.map(({ node }) => {
          return { label: node.name, value: node.id };
        });
      },

      fetchPolicy: "no-cache"
    }
  },

  props: {
    projectId: {
      type: [String, Number],
      required: false
    }
  },

  data() {
    const vm = this;
    let columns = [
      {
        headerName: "#",
        field: "reference",
        cellRenderer: "link",
        cellRendererParams: {
          onClick: bill =>
            (window.location.href = `/projects/${bill.project.id}/bills/${bill.id}`)
        },
        valueGetter(params) {
          if (params.data.reference) {
            return params.data.reference;
          }

          if (params.data.number) {
            return params.data.number;
          }

          return "No reference";
        }
      },
      {
        headerName: "Contact",
        field: "contact",
        valueGetter({ data }) {
          return data.contact.name;
        }
      },
      {
        headerName: "Date",
        field: "issuedDate",
        cellRenderer: "date",
        width: 150
      },
      {
        headerName: "Due date",
        field: "dueDate",
        width: 150,
        cellRenderer: "date",
        cellRendererParams: {
          highlightOverdue: true
        }
      }
    ];

    if (!this.projectId) {
      columns.push({
        headerName: "Project",
        field: "project",
        cellRenderer: "link",
        cellRendererParams: {
          onClick: bill =>
            (window.location.href = `/projects/${bill.project.id}`)
        },
        comparator(a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        },
        valueGetter({ data }) {
          if (!data.project) {
            return "";
          }

          return `${data.project.sequence} - ${data.project.name}`;
        }
      });
    }

    columns.push(
      {
        headerName: "Purchase amount",
        field: "totalVal",
        valueGetter({ data }) {
          return vm.formatMoney(data.totalVal, data.currency);
        }
      },
      {
        headerName: "Status",
        field: "status",
        cellRenderer: "status",
        width: 150,
        valueGetter(params) {
          return params.data.status;
        }
      },
      Helpers.table.actionsCell()
    );
    return {
      filters: {},
      billsManager: new BillsManager(),
      columns,
      config: {
        actionBar: false,
        resourceType: "Bill",
        filters: {
          name: {
            type: "text",
            title: "Reference",
            description: "Filter bills by reference",
            field: "reference"
          },
          date: {
            type: "date",
            title: "Date",
            description: "Filter bills by date",
            field: "issueDate"
          },
          due_date: {
            type: "date",
            title: "Due date",
            description: "Filter bills by due date",
            field: "dueDate"
          },
          status: {
            type: "option",
            title: "Status",
            description: "Filter bills by status",
            field: "status",
            options: () => [
              {
                label: "Draft",
                value: "draft"
              },
              {
                label: "Submitted",
                value: "submitted"
              },
              {
                label: "Authorised",
                value: "authorised"
              },
              {
                label: "Voided",
                value: "voided"
              }
            ]
          },
          project: {
            type: "option",
            title: "Projects",
            description: "Filter bills by project",
            field: "project",
            hidden: this.projectId,
            options: () => this.projects
          }
        }
      },
      components: {
        vue: {
          status: BillStatusCellRenderer
        }
      }
    };
  },

  computed: {},

  mounted() {
    this.startLoading();
  },

  methods: {
    performFiltering(filters) {
      this.startLoading();
      this.filters = filters;
      this.$apollo.queries.bills.refetch();
    },
    formatMoney(money, currency) {
      return accounting.formatMoney(money, this.moneyOpts(currency));
    },
    moneyOpts(currency) {
      if (currency === null) {
        return {};
      }

      return {
        symbol: currency.symbol,
        decimal: currency.decimal_mark,
        thousand: currency.thousands_separator,
        precision: currency.decimal_places
      };
    },
    getButtons(h) {
      const buttons = [
        h(
          Components.AppButton,
          { props: { primary: true }, on: { click: this.newBill } },
          "New bill"
        )
      ];

      return buttons;
    },
    newBill() {
      if (this.projectId) {
        window.location.href = `/projects/${this.projectId}/bills/new`;
      } else {
        window.location.href = "/bills/new";
      }
    },
    getContextMenuItems(row) {
      return billsMenuItems(row);
    },
    contextMenuItemClicked(item, row) {
      switch (item.action) {
        case ACTIONS.EDIT:
          window.location.href = `/bills/${row.data.id}/edit`;
          break;

        case ACTIONS.DELETE:
          this.delete(row, {
            title: "Are you sure you want to delete this bill?",
            message: "Deleting a bill cannot be undone",
            success: "Bill successfully deleted"
          });
          break;

        case ACTIONS.Void:
          this.void(row, {
            title: "Are you sure you want to void this bill?",
            message: "Voiding a bill cannot be undone",
            success: "Bill successfully voided"
          });
          break;
      }
    },
    delete(rows, opts) {
      if (rows.constructor !== Array) {
        rows = [rows];
      }

      const bills = rows.map(row => row.data);
      this.$dialog
        .confirm({
          title: opts.title,
          message: opts.message
        })
        .onOk(({ api }) => {
          api.hide();
          this.billsManager
            .delete(bills)
            .then(() => {
              this.$flash.success(opts.success);
              this.removeRows(rows);
            })
            .catch(e => {
              this.$flash.error(gqlErrorMessage(e));
            });
        });
    },
    void(rows, opts) {
      if (rows.constructor !== Array) {
        rows = [rows];
      }

      const bills = rows.map(row => row.data);
      this.$dialog
        .confirm({
          title: opts.title,
          message: opts.message
        })
        .onOk(({ api }) => {
          api.hide();
          this.billsManager
            .void(bills)
            .then(() => {
              this.$flash.success(opts.success);
              this.$apollo.queries.bills.refetch();
            })
            .catch(e => {
              this.$flash.error(gqlErrorMessage(e));
            });
        });
    },
    removeRows(rows) {
      this.gridApi().applyTransaction({
        remove: rows
      });
    }
  }
};
</script>
