<script>
import ProjectsQuery from "@/graphql/queries/core/projects/Projects.gql";
import { Components } from "manageplaces-ui-kit";
import ProjectTypeCellRenderer from "./ProjectWizardTemplateListProjectTypeCellRenderer.vue";

export default {
  extends: Components.BaseTable,
  props: {
    value: {
      type: Object
    },

    completedProjectsCount: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      projectType: null,
      selection: "single",
      columns: [
        {
          colId: "checkbox",
          checkboxSelection: true,
          suppressMenu: true,
          resizable: false,
          sortable: false,
          width: 50
        },
        {
          headerName: "Name",
          field: "name",
          flex: 1.5,
          editable: false,
          sortable: true
        },
        {
          headerName: "Owner",
          field: "owner",
          cellRenderer: "teamMember",
          cellRendererParams: {
            onClick(user) {
              this.currentUser = user;
            }
          },
          editable: false,
          sortable: true,
          flex: 1
        },
        {
          headerName: "Started",
          field: "startDate",
          cellRenderer: "date",
          editable: false,
          sortable: true,
          flex: 1.5
        },
        {
          headerName: "Deadline",
          field: "deadline",
          cellRenderer: "date",
          editable: false,
          sortable: true,
          flex: 1.5
        },
        {
          headerName: "Project or template",
          field: "type",
          cellRenderer: "projectType",
          editable: false,
          sortable: true,
          flex: 1.5
        }
      ],
      components: {
        vue: {
          projectType: ProjectTypeCellRenderer
        }
      },
      config: {
        embedded: true,
        actionBar: false
      }
    };
  },
  apollo: {
    templates: {
      query: ProjectsQuery,
      update: ({ projects }) => projects.edges.map(({ node }) => node),
      variables: {
        where: {
          isTemplate: [true, false]
        }
      },

      result() {
        this.setRowData(this.templates);
        this.stopLoading();
      }
    }
  },

  computed: {
    listClasses() {
      return template => {
        const classes = ["col-span-1 border cursor-pointer hover:shadow-lg"];

        if (this.isSelected(template)) {
          classes.push(
            "text-white bg-tribal-aqua border-tribal-aqua shadow-lg"
          );
        } else {
          classes.push("border-grey-30");
        }

        return classes;
      };
    }
  },
  mounted() {
    this.startLoading();
  },
  methods: {
    getButtons(h) {
      const vm = this;
      return [
        h(Components.AppToggleButton, {
          props: {
            options: [
              { label: "All", value: "all" },
              { label: "Projects", value: "Project" },
              { label: "Templates", value: "Template" }
            ],
            value: vm.projectType
          },
          on: {
            input(projectType) {
              vm.projectType = projectType;
              let filter = null;
              if (projectType.value !== "all") {
                filter = {
                  type: {
                    type: "equals",
                    filter: projectType.value,
                    filterType: "text"
                  }
                };
              }
              vm.gridApi().setFilterModel(filter);
            }
          }
        })
      ];
    },
    rowSelectionChanged() {
      const selected = this.gridApi().getSelectedNodes()[0];
      if (selected) {
        this.$emit("input", { id: selected.data.id });
      } else {
        this.$emit("input", null);
      }
    }
  }
};
</script>
