<template lang="pug">
  app-panel
    FormProgress(@step-clicked="setCurrentStep")

    .p-4.pt-8(ref="grid")
      form-wrapper(:validator="$v.project")
        form(@submit.prevent="goNext")
          keep-alive
            component(:is="currentComponent" @cancel="cancel" v-model="project")

          .mt-8.flex.flex-row
            app-button.inline-block.mr-4(v-if="!isFirstStep()" @click.prevent="goBack")
              | Back
            app-button.inline-block.mr-4(:loading="saving" primary @click.prevent="goNext") {{ nextBtnText }}
          a.block.my-4(@click.prevent="cancel") Cancel

</template>

<script>
import { isDate, parse } from "date-fns";
import { required, /*integer,*/ not } from "vuelidate/lib/validators";
import { templates } from "vuelidate-error-extractor";

import FormProgress from "@/components/elements/FormProgress.vue";

import ProjectBasicDetails from "@/components/projects/wizard/ProjectBasicDetails.vue";
import ProjectParameters from "@/components/projects/wizard/ProjectParameters.vue";
import ProjectTemplate from "@/components/projects/wizard/ProjectTemplate.vue";
import TemplateSettings from "@/components/projects/wizard/TemplateSettings.vue";

import WizardStepsMixin from "@/mixins/WizardSteps.js";

import { nextWorkingDay, formatDateApi } from "@/helpers/DateHelpers";
import { isValidDate, before /*, positive*/ } from "@/helpers/Validators";

import ProjectQuery from "@/graphql/queries/core/projects/Project.gql";
import ProjectsNamesQuery from "@/graphql/queries/core/projects/ProjectsNames.gql";
import CreateProject from "@/graphql/mutations/projects/CreateProject.gql";
import UpdateProject from "@/graphql/mutations/projects/UpdateProject.gql";

import FreePlanNumberOfProjectsExcededDialog from "@/components/projects/wizard/FreePlanNumberOfProjectsExcededDialog.vue";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

export default {
  components: {
    FormWrapper: templates.FormWrapper,
    FormProgress,
    ProjectBasicDetails,
    ProjectParameters,
    ProjectTemplate,
    TemplateSettings
  },

  mixins: [WizardStepsMixin],

  data() {
    const workingWeekAttributes = this.$store.state.workingWeek;
    const startDate = formatDateApi(nextWorkingDay(workingWeekAttributes));
    const deadline = startDate;

    return {
      resourceType: "Project",
      error: null,
      saving: false,
      requiredFields: {
        Name: ["name"],
        Parameters: ["start_date", "deadline"],
        Template: ["template"]
      },

      prevProjectName: "",
      projectsNames: [],

      project: {
        name: "",
        description: "",
        phaseId: null,
        workingWeekAttributes,
        deadline,
        startDate,
        template: { id: null },
        trackingTargetsAttributes: [],
        resourceFieldsAttributes: [],
        // templateSettings: [],
        estimateId: this.$route.query.estimate_id
      }
    };
  },

  apollo: {
    // Since no company would have 10k projects it's better to load all the projects' names once
    // when the component first loads and check against them for name uniqueness. Better than hitting
    // the API on every name change, which will delay the validation and thus require more work to
    // prevent the user advance to next step.
    projectsNames: {
      query() {
        return ProjectsNamesQuery;
      },

      update: ({ projects }) => {
        const projectsNames = projects.edges.map(({ node: { name } }) => name);

        return projectsNames;
      }
    },

    project: {
      query() {
        return ProjectQuery;
      },

      skip() {
        return this.isNewProject;
      },

      variables() {
        return {
          id: this.projectId
        };
      },

      update(data) {
        let {
          id,
          name,
          description,
          workingWeek,
          deadline,
          startDate,
          trackingTargets,
          resourceFields,
          phase
        } = data.project;

        [startDate, deadline] = [startDate, deadline].map(d => {
          return formatDateApi(nextWorkingDay(workingWeek, d));
        });

        delete workingWeek.__typename;

        const project = {
          id,
          name,
          description,
          deadline,
          startDate,
          phaseId: phase.id,
          workingWeekAttributes: workingWeek
        };

        project.trackingTargetsAttributes = trackingTargets.map(
          ({ id, quantity, trackable: { id: trackableId } }) => ({
            id,
            quantity,
            trackableId
          })
        );

        project.resourceFieldsAttributes = resourceFields.map(
          ({ id, valueForInput, customField: { id: customFieldId } }) => ({
            id,
            valueForInput,
            customFieldId
          })
        );

        this.prevProjectName = name;

        return project;
      },
      // No need to cache. If we do, it'll override any changes
      // made at unusual times
      fetchPolicy: "no-cache"
    }
  },
  validations() {
    return {
      project: {
        name: {
          required,
          unique(name) {
            if (this.isNewProject || name !== this.prevProjectName) {
              return this.projectsNames.indexOf(name) == -1;
            }

            return true;
          }
        },

        workingWeekAttributes: {
          atLeastOneDay(workingWeek) {
            const days = { ...workingWeek };
            delete days.id;

            return Object.values(days).some(value => value);
          }
        },

        startDate: {
          required,
          isValidDate,
          beforeDeadline: before("deadline", d => {
            return parse(d, "yyyy/MM/dd", new Date());
          })
        },
        deadline: {
          required,
          isValidDate,
          afterStartDate: not(
            before("startDate", d => parse(d, "yyyy/MM/dd", new Date()), true)
          )
        },
        phaseId: {
          required
        }
      }
    };
  },

  computed: {
    steps() {
      const steps = this.wizardState.steps;

      if (!this.isNewProject) {
        const idx = steps.indexOf("Template");
        steps.splice(idx, 1);
      }

      return steps;
    },

    isNewProject() {
      return !this.projectId;
    },

    projectId() {
      return this.$route.params.id;
    },

    stepComponent() {
      return {
        "Basic details": "ProjectBasicDetails",
        "Template settings": "TemplateSettings"
      };
    },

    validatedFields() {
      return {
        "Basic details": ["name", "phaseId"],
        Parameters: ["workingWeekAttributes", "startDate", "deadline"]
      };
    },

    nextBtnText() {
      if (this.stepIs("Template") && this.project.template?.id) {
        return "Use selected template";
      } else if (this.saving) {
        return "Saving";
      } else if (this.isLastStep()) {
        return this.isNewProject ? "Create project" : "Update project";
      }

      return "Next";
    }
  },

  watch: {
    project: {
      deep: true,
      handler() {
        this.findFailedStepsBefore(this.currentStep);
      }
    }
  },

  beforeCreate() {
    this.$store.commit("clearWizardState");
    this.$store.commit("setStepsList", "projectWizardSteps");
  },

  methods: {
    cancel() {
      const path = this.isNewProject
        ? "/projects"
        : `/projects/${this.projectId}`;
      window.location = path;
    },

    save() {
      if (this.saving) return;

      this.$v.$touch();

      if (this.$v.$invalid) return;
      let project = this.formatDates(this.project);
      // project = this.formatNumbers(project);

      // If the selected template is the empty project
      if (!project.template || !project.template.id) {
        delete project.template;
      }

      this.saving = true;
      this.error = null;

      this.$apollo
        .mutate({
          mutation: this.isNewProject ? CreateProject : UpdateProject,

          variables: {
            project: project
          }
        })
        .then(resp => {
          const { data } = resp;
          let projectId, showFlash;

          if (this.isNewProject) {
            projectId = data.createProject.project.id;
            showFlash = "t";
          } else {
            projectId = this.projectId;
            showFlash = "f";
          }

          window.location = `/projects/${projectId}?show_flash=${showFlash}`;
        })
        .catch(error => {
          const errorMessage = gqlErrorMessage(error);
          if (
            errorMessage ===
            "The free plan only allows you to have one active project at a time."
          ) {
            this.$dialog
              .show(FreePlanNumberOfProjectsExcededDialog)
              .onOk(({ api }) => {
                api.hide();
              });
          } else {
            this.saving = false;
            this.error = error;
          }
        });
    },

    formatDates(project) {
      const attrs = { ...project };

      if (isDate(attrs.startDate)) {
        attrs.startDate = formatDateApi(attrs.startDate);
      }

      if (isDate(attrs.deadline)) {
        attrs.deadline = formatDateApi(attrs.deadline);
      }

      return attrs;
    },

    formatNumbers(project) {
      const attrs = { ...project };

      for (let field of ["gdv", "expectedBudget"]) {
        if (attrs[field]) {
          attrs[field] = Number(attrs[field]);
        } else {
          delete attrs[field];
        }
      }

      attrs.expectedBudgetVal = attrs.expectedBudget;
      delete attrs.expectedBudget;

      return attrs;
    }
  }
};
</script>

<style lang="postcss" scoped>
.grid {
  grid-template-rows: auto 1fr;
}
</style>
