export const flattenCustomFieldsToObject = customFields => {
  return customFields.reduce((result, field) => {
    result[field.customField.name] = field.valueForInput;
    return result;
  }, {});
};

export const customFieldColumnDefinition = field => {
  return {
    headerName: field.name,
    field: field.name,
    editable: false,
    sortable: true,
    hide: true,
    comparator(a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    }
  };
};

export const customFieldColumnDefinitions = customFields => {
  const blacklist = ["text", "list", "predefined_list"];
  let columns = [];

  customFields.forEach(field => {
    if (blacklist.indexOf(field.type) === -1) {
      columns.push(customFieldColumnDefinition(field));
    }
  });

  return columns;
};
