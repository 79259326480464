import Vue from "vue";
import { createFilepond } from "../../../config/FilePond";

const state = () => ({
  // General tracking
  mode: null,
  currentFolder: null,
  project: null,
  formLabel: null,
  formMultiInstance: null,
  uploadCallbacks: [],
  standalone: false,

  // Filepond tracking
  filepond: createFilepond(),
  newVersionDocument: null,
  mountEl: null,
  showingUploadSummary: false,

  // Upload tracking
  activeUploads: 0,
  totalFileSize: 0,
  increments: {},
  uploadedBytes: 0
});

const mutations = {
  decreaseUploadTotalSize(state, { fileSize }) {
    state.totalFileSize -= fileSize;
  },
  mountUploadUI(state, { el }) {
    if (state.mountEl) {
      // Unmount from the original place
      state.filepond.restoreElement(state.mountEl);
    }

    state.mountEl = el;

    // If there as a new element and it's not being reset,
    // then mount it
    if (el) {
      // Setting a mount
      state.filepond.replaceElement(state.mountEl);
    }
  },
  increaseUploadTotalSize(state, { fileSize }) {
    state.totalFileSize += fileSize;
  },
  registerUploadCallback(state, callback) {
    const wrappedCallback = (error, file) => {
      if (!error) {
        const documentID = file.serverId;
        callback(documentID, file);
      }
    };
    state.uploadCallbacks.push(callback);
    state.filepond.on("processfile", wrappedCallback);
  },
  removeUploadCallback(state, callback) {
    state.uploadCallbacks = state.uploadCallbacks.filter(cb => cb !== callback);
  },
  resetState(state) {
    state.filepond.destroy();
    state.filepond = createFilepond();
    state.activeUploads = 0;
    state.totalFileSize = 0;
    state.increments = {};
    state.uploadedBytes = 0;
    state.uploadCallbacks.forEach(callback => {
      const wrappedCallback = (error, file) => {
        if (!error) {
          const documentID = file.serverId;
          callback(documentID);
        }
      };

      state.filepond.on("processfile", wrappedCallback);
    });
  },
  setCurrentFolder(state, folder) {
    state.currentFolder = folder;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setNewVersionDocument(state, document) {
    state.newVersionDocument = document;
  },
  setProject(state, { project }) {
    state.project = project;
  },
  setShowingUploadSummary(state, showing) {
    // Only show if there's 1 or more files
    state.showingUploadSummary = showing;
  },
  setStandalone(state) {
    state.standalone = true;
  },
  startUpload(state, { files }) {
    files.forEach(f => state.filepond.addFile(f));
  },
  stopTrackingFileUpload(state, { id }) {
    Vue.delete(state.increments, id);
  },
  trackFileUploadProgress(state, { id }) {
    state.increments[id] = 0;
  },
  updateActiveUploadCounter(state, count) {
    state.activeUploads += count;
  },
  updateUploadProgress(state, { progress, file: { fileSize, id } }) {
    const size = progress * fileSize;
    const currentProgress = state.increments[id];

    state.uploadedBytes += size - currentProgress;
    state.increments[id] += size;
  }
};

const actions = {
  /**
   * Call when a file is added to the filepond instance.
   * Updates the count of the number of files being uploaded,
   * the total size of those uploads, and starts tracking
   * the upload
   */
  addFile({ state, commit }, { id, fileSize }) {
    commit("updateActiveUploadCounter", 1);
    commit("increaseUploadTotalSize", { fileSize });
    commit("trackFileUploadProgress", { id });

    if (state.mode === "version") {
      commit("setShowingUploadSummary", true);
    }
  },

  browseForNewVersion({ state, commit }, document) {
    commit("setMode", "version");
    commit("setNewVersionDocument", document);
    state.filepond.setOptions({ allowMultiple: false });
    state.filepond.browse();
  },

  browseForDocument({ state }, multiselect = true) {
    state.filepond.setOptions({ allowMultiple: multiselect });
    state.filepond.browse();
  },

  // Cancel and upload and stop tracking it. Use if the
  // upload fails or is aborted by the user
  cancelUpload({ commit }, { id, fileSize }) {
    commit("decreaseUploadTotalSize", { fileSize });
    commit("updateActiveUploadCounter", -1);
    commit("stopTrackingFileUpload", { id });
  },

  filesDropped({ commit, state }, { files }) {
    state.filepond.setOptions({ allowMultiple: true });
    commit("setMode", "create");
    commit("startUpload", { files });
    commit("setShowingUploadSummary", true);
  },

  hideUploadSummary({ commit }) {
    commit("setShowingUploadSummary", false);
    commit("resetState");
  },

  showUploadSummary({ commit }) {
    commit("setShowingUploadSummary", true);
  },

  mountUploadUI({ commit }, el) {
    commit("mountUploadUI", { el });
  },

  /**
   * Registers a callback with the filepond instance to be
   * notified when a file has been uploaded. Calls the
   * callback function with the ID of the document that was
   * uploaded so it can be queried
   */
  registerUploadCallback({ commit }, callback) {
    commit("registerUploadCallback", callback);
  },

  removeUploadCallback({ commit }, callback) {
    commit("removeUploadCallback", callback);
  },

  setProject({ commit }, project) {
    commit("setProject", { project });
  },

  setStandalone({ commit }) {
    commit("setStandalone");
  },

  uploadComplete({ commit }, { id, fileSize }) {
    commit("decreaseUploadTotalSize", { fileSize });
    commit("updateActiveUploadCounter", -1);
    commit("stopTrackingFileUpload", { id });
  },

  uploadModalShown({ commit }) {
    commit("setShowingUploadSummary", false);
  },

  uploadModalHidden({ state, commit }) {
    if (state.activeUploads > 0) {
      commit("setShowingUploadSummary", true);
    } else {
      // Modal is closed and we're not uploading, so we'll clear out the state
      commit("resetState");
    }
  },

  uploadProgressChanged({ commit }, { file, progress }) {
    commit("updateUploadProgress", { file, progress });
  }
};

const getters = {
  uploadPercentage(state) {
    return state.totalFileSize > 0
      ? Math.round((state.uploadedBytes / state.totalFileSize) * 100.0)
      : 0;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
