<template lang="pug">
  .checkout-box
    .checkout-box-content
      app-panel.mb-4
        div
          .flex.flex-col.justify-between.flex-initial(class="sm:flex-row")
            .flex-1
              .font-bold User licenses
              span.text-grey-60 Each license allows a user to login
            .flex-initial.px-0.pt-4.my-auto(class="sm:px-4 sm:pt-0")
              app-stepper-field.addon-input(
                data-cy="user-license-input"
                description="User licenses",
                :value="licenses"
                @input="updateLicenses",
                :min="minLicenses")
            .flex-initial.price-section.my-auto.pt-4(class="sm:pt-0")
              span.font-bold.addon-price {{checkout.currency}}{{checkout.userLicensePrice}}

          .h-px.bg-grey-30.my-8.addon-item

          .flex.flex-col.justify-between.flex-initial(class="sm:flex-row")
            .flex-1
              .font-bold Finance Management
              span.text-grey-60 Track your project costs
            .flex-initial.px-0.pt-4(class="sm:px-4 sm:pt-0")
              app-toggle-button(v-model="checkout.financeManagementStatus" data-cy="finance-management-input")
            .flex-initial.price-section.my-auto.pt-4(class="sm:pt-0")
              span.font-bold.addon-price {{checkout.currency}}{{checkout.financeManagementPrice}}

          //- .h-px.bg-grey-30.my-8.addon-item

          //- .flex.flex-row.justify-between.flex-initial
          //-   .flex-1
          //-     .font-bold Document storage
          //-     span.text-grey-60 The pro plan gives you 50GB of document storage already, but if you need more you can add mutiples of 50GB.
          //-   .flex-initial.pr-4.pl-20.my-auto
          //-     app-stepper-field.addon-input(description="document storage",
          //-                   suffix="GB",
          //-                   v-model="checkout.documentStorageLimit",
          //-                   :step-size="checkout.documentStorageUnit",
          //-                   :min="0"
          //-                   data-cy="document-storage-input")
            .flex-initial.price-section.my-auto
              span.font-bold.addon-price {{checkout.currency}}{{totalDocumentPrice}}

      .mt-4.text-center Prices quoted are monthly without annual discounts

    .checkout-box-sidebar.mt-4(class="md:mt-0")
      checkout-sidebar(v-model="checkout" @submit="goToNext")
</template>
<script>
import CheckoutWatchers from "@/mixins/CheckoutWatchers";
import CheckoutSidebar from "./CheckoutSidebar.vue";

export default {
  name: "CheckoutAddons",
  components: {
    CheckoutSidebar
  },
  mixins: [CheckoutWatchers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      checkout: { ...this.value },
      financeOptions: [
        {
          name: "Enabled",
          value: true
        },
        {
          name: "Disabled",
          value: false
        }
      ],
      minLicenses: this.value.usedLicenses,
      licenses: this.value.userLicenses
    };
  },
  computed: {
    totalDocumentPrice() {
      const {
        documentStoragePrice,
        documentStorageLimit,
        documentStorageUnit
      } = this.checkout;
      return (
        (documentStoragePrice * documentStorageLimit) / documentStorageUnit
      );
    }
  },
  methods: {
    goToNext() {
      this.$emit("submit");
    },
    updateLicenses(val) {
      this.value.userLicenses = val;
    }
  }
};
</script>
<style lang="postcss" scoped>
.addon-price {
  line-height: 1.5;
}
.price-section {
  width: 30px;
  text-align: right;
}
.addon-input {
  margin-bottom: 0;
}
</style>
