<template lang="pug">
  .checklist-item.flex.flex-row.items-center
    .flex-initial.pr-4(v-if="canEdit")
      app-icon.handle.cursor-move(icon="dragHandle")

    .flex-initial
      app-loading-spinner.mr-4.text-xl(v-if="checklistItem.loading")
      app-checkbox(v-else, label="", :value="checklistItem.completed", @input="$emit('toggled')")

    .flex-1
      app-text-input.w-full(
        v-if="editing"
        :value="checklistItem.name"
        @input="name = $event"
        v-focus
        @change="updateItem"
        v-click-outside="stopEditing"
        @keyup.enter="stopEditing")

      .flex.flex-col(v-else)
        span.cursor-text(:class="{ 'line-through': checklistItem.completed }", @click="startEditing") {{ checklistItem.name }}
        span.text-sm.text-grey-60(v-if="checklistItem.completed") {{ formattedDate(checklistItem.completedAt) }}


    template(v-if="!editing")
      .flex-initial(v-if="checklistItem.completed")
        app-avatar(:user="checklistItem.completedBy")

    .flex-initial.pl-8
      app-icon.cursor-pointer(icon="delete", colour="red", @click.native="$emit('delete')")
</template>

<script>
import { format, parseISO } from "date-fns";

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      editing: false,
      name: this.value.name
    };
  },
  computed: {
    checklistItem() {
      return this.value;
    }
  },
  methods: {
    formattedDate(date) {
      if (date === undefined || date === null) {
        return "";
      }

      return format(
        parseISO(date),
        `${this.$store.state.dateFormats.dateFns} HH:mm`
      );
    },
    startEditing() {
      this.editing = true;
    },
    stopEditing() {
      this.editing = false;
    },
    updateItem() {
      this.$emit("input", this.name);
    }
  }
};
</script>

<style lang="postcss" scoped>
.checklist-item {
  min-height: 4rem;
}
</style>
