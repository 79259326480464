<template lang="pug">
  app-dialog(@cancel="$emit('cancel')")
    template(#header)
      app-header
        | {{ capitalize(mode) }} documents
        template(#subheader)
          | Choose where to {{ mode }} the selected documents.
          template(v-if="project")
            | This could be to another project or a different folder in the same project

    template(v-if="project")
      app-dropdown-field(name="project" label="Project" v-model="selectedProject" :show-optional="false"
                         :options="projects" track-by="id" label-attr="name")

    .move.flex.flex-col.w-full
      .text-lg Folder
      .text-grey-70 Which folder do you want to {{ mode }} to?
      directory-listing.mt-4.flex-initial.w-full.relative.border.border-grey-50.rounded-lg(:project="selectedProject")

    .my-3(v-if="isCopy")
      app-checkbox(v-model="options.shouldOnlyCopyFolderStructure", label="Only copy the folder structure (no files)")

    template(#footer)
      .flex.flex-row.items-center.justify-end
        .flex-initial
          app-button.w-32.text-center(@click="$emit('cancel')") Cancel
        .flex-initial.ml-8
          app-button.w-32.text-center(primary @click="move") {{ capitalize(mode) }}
</template>

<script>
import { mapState } from "vuex";
import DirectoryListing from "@/components/elements/DirectoryListing.vue";
import documentUploadModal from "@/store/root/modules/documentUploadModal";

import DuplicateConfirmDialog from "./DuplicateConfirmDialog";

import DocumentManager from "./DocumentManager";
import { isFolder } from "@/helpers/DocumentHelpers";
import { initCap } from "@/helpers/StringHelpers";

import ProjectsQuery from "@/graphql/queries/core/projects/Projects.gql";
import FolderQuery from "@/graphql/queries/document_management/Folder.gql";
import CompanyDocumentsQuery from "@/graphql/queries/core/company/CompanyDocuments.gql";
import ProjectDocumentsQuery from "@/graphql/queries/core/projects/ProjectDocuments.gql";

export default {
  components: {
    DirectoryListing
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    project: {
      type: Object,
      required: false,
      default: null
    },
    documents: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      default: "move",
      validator: val => val === "move" || val === "copy"
    }
  },
  data() {
    let options = {};

    if (this.isCopy) {
      options.shouldOnlyCopyFolderStructure = false;
    }

    return {
      isShowing: this.value,
      selectedProject: this.project,
      options
    };
  },
  apollo: {
    projects: {
      query: ProjectsQuery,

      update(data) {
        const { selectedProject } = this;

        return data.projects.edges.map(({ node: { id, name } }) => {
          if (id === selectedProject.id) selectedProject.name = name;

          return { id, name };
        });
      },

      skip() {
        return !this.project;
      },

      // Do not remove this, it will cause the data param of the update method to be empty
      fetchPolicy: "no-cache"
    }
  },

  beforeCreate() {
    this.$store.registerModule("documentUploadModal", documentUploadModal);
  },

  beforeDestroy() {
    this.$store.unregisterModule("documentUploadModal");
  },

  methods: {
    capitalize(text) {
      return initCap(text);
    },

    async move() {
      const {
        selectedProject,
        selectedFolder,
        confirmMove,
        isMove,
        isCopy,
        options
      } = this;

      if (isMove && this.isMoveToSameFolder()) {
        this.isShowing = false;
        return;
      }

      let { documents } = this;

      if (isCopy && options.shouldOnlyCopyFolderStructure) {
        documents = documents.filter(doc => isFolder(doc));
      }

      documents = documents.filter(
        doc => !isFolder(doc) || doc.path !== this.selectedFolder.path
      );

      // We need to reject moves of folders into a sub folder. This would create
      // a circular hierarchy which is invalid!

      let rejections = [];
      if (isMove) {
        documents = documents.filter(doc => {
          if (
            isFolder(doc) &&
            this.selectedFolder.path.indexOf(doc.path) === 0
          ) {
            // We're attempting to move a folder into a child. This is not valid!
            rejections.push(doc);
            return false;
          } else {
            // It isn't a folder or parent folder so we don't care. Keep this one
            return true;
          }
        });

        if (rejections.length && !documents.length) {
          // We're rejecting everything so we'll just show an error
          this.$flash.error("You cannot move a folder into a sub-folder");
          return;
        }
      }

      let nonDups = [],
        dups = [];

      const selectedFolderDocs = await this.getSelectedFolderDocs();

      if (selectedFolderDocs.length) {
        documents.forEach(doc => {
          if (selectedFolderDocs.some(d => d.name == doc.name)) {
            dups.push(doc);
          } else {
            nonDups.push(doc);
          }
        });
      } else {
        nonDups = [...documents];
      }

      // this.isShowing = false;
      confirmMove(
        dups,
        selectedFolder,
        async (toOverwrite = [], toKeepBoth = []) => {
          const docManager = new DocumentManager(selectedProject, this.mode);

          let nonDupsComplete = false;
          let overwritesComplete = false;
          let keepBothComplete = false;

          let nonDupsCopy = docManager
            .move(nonDups, selectedFolder, options)
            .then(() => {
              nonDupsComplete = true;
            });
          let toOverwriteCopy = docManager
            .move(toOverwrite, selectedFolder, {
              ...options,
              shouldOverwrite: true
            })
            .then(() => {
              overwritesComplete = true;
            });
          let toKeepBothCopy = docManager
            .move(toKeepBoth, selectedFolder, {
              ...options,
              shouldKeepBoth: true
            })
            .then(() => {
              keepBothComplete = true;
            });

          await Promise.all([nonDupsCopy, toOverwriteCopy, toKeepBothCopy]);

          const action = this.isCopy ? "copying" : "moving";
          if (!nonDupsComplete || !overwritesComplete || !keepBothComplete) {
            // One or more failed so present a warning
            this.$flash.warn(
              `It was not possible to ${this.mode} one or more item`
            );
          } else if (rejections.length) {
            // The other moves worked, but there was a rejection
            this.$flash.warn(
              `It was not possible to ${this.mode} the folder ${rejections[0].path} because you are ${action} it into a subfolder of itself`
            );
          }

          const allDocs = [...nonDups, ...toOverwrite, ...toKeepBoth];
          const messageAction = this.isCopy ? "copied" : "moved";
          this.$flash.success(`Items successfully ${messageAction}`);
          this.$emit("moved", allDocs);
          this.isShowing = false;
        }
      );
    },

    isMoveToSameFolder() {
      const { selectedProject, project, selectedFolder, documents } = this;

      const sameFolder = selectedFolder.path === documents[0].parentPath;

      if (project) {
        const sameProject = project ? selectedProject.id == project.id : false;
        return sameProject && sameFolder;
      } else {
        return sameFolder;
      }
    },

    confirmMove(duplicates, folder, confirmedCallback) {
      if (!duplicates.length) {
        confirmedCallback();
        return;
      }
      let dups = [...duplicates],
        toKeepBoth = [],
        toOverwrite = [];

      const doc = dups.shift();

      this.$dialog
        .show(DuplicateConfirmDialog, {
          props: {
            mode: this.mode,
            document: doc,
            folder: folder
          }
        })
        .onOk(({ data, api }) => {
          switch (data.action) {
            case "skip":
              if (data.doSameForAll) {
                dups = [];
              }
              break;
            case "keepBoth":
              if (data.doSameForAll) {
                toKeepBoth = toKeepBoth.concat(dups, doc);
                dups = [];
              } else {
                toKeepBoth.push(doc);
              }
              break;
            case "overwrite":
              if (data.doSameForAll) {
                toOverwrite = toOverwrite.concat(dups, doc);
                dups = [];
              } else {
                toOverwrite.push(doc);
              }
              break;
          }
          api.hide();

          this.confirmMove(
            dups,
            folder,
            (overwriteDups = [], keepBothDups = []) => {
              confirmedCallback(
                toOverwrite.concat(overwriteDups),
                toKeepBoth.concat(keepBothDups)
              );
            }
          );
        });
    },

    async getSelectedFolderDocs() {
      const { selectedFolder, project } = this;
      const { data } = await this.$apollo.query(this.selectedFolderDocsQuery);
      let documents;

      if (project) {
        documents = data.project.documentsConnection.edges;
      } else if (selectedFolder.isRoot) {
        documents = data.documentsConnection.edges;
      } else {
        return (data.folder && data.folder.children) || [];
      }

      return documents.map(e => e.node);
    }
  },
  computed: {
    ...mapState("documentUploadModal", ["activeFolder"]),
    selectedFolder() {
      return this.activeFolder;
    },

    selectedFolderDocsQuery() {
      let query, variables;

      const { selectedFolder, selectedProject } = this;

      if (selectedProject) {
        query = ProjectDocumentsQuery;
        variables = {
          projectId: selectedProject.id,
          folder: selectedFolder.path
        };
      } else if (selectedFolder.isRoot) {
        query = CompanyDocumentsQuery;
      } else {
        query = FolderQuery;
        variables = { id: selectedFolder.id, project: null };
      }

      return {
        query,
        variables,
        fetchPolicy: "no-cache"
      };
    },

    isCopy() {
      return this.mode === "copy";
    },
    isMove() {
      return this.mode === "move";
    },
    uploadPath() {
      if (!this.activeFolder) {
        return "/";
      }

      return this.activeFolder.path;
    }
  }
};
</script>

<style lang="postcss" scoped>
.move {
  height: 250px;
}
</style>
