<template lang="pug">
  .flex.flex-row.h-full
    app-avatar.flex-none.self-center(v-if="params.value" :avatar="params.data.logo" :name="params.data.name")
    .flex-1.ml-4.self-center(v-if="params.value")
      a.leading-none.block(@click.prevent="params.onClick(params.data)") {{ params.data.name }}
      p.leading-none.text-grey-60.text-sm.mt-1 {{ params.data.primaryContact }}

</template>

<script>
import Vue from "vue";

export default Vue.extend({});
</script>
