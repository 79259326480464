<template lang="pug">
app-sidebar(@close="$emit('close')")
  template(#title) Add a contact

  app-loader(:loading="loading")
    .p-8
      form-wrapper(:validator="$v.contact")
        app-panel(secondary)
          app-text-field(v-model="contact.name" label="Contact name" description="The name of the company" name="name")
          app-text-field(v-model="contact.primaryPerson" label="Primary person" description="Who is the main point of contact in this company?" name="primaryPerson")

        app-panel.mt-4(secondary)
          app-header(size="h3")
            | Email addresses (optional)
            template(#subheader) Add one or more email addresses


          app-categorised-list-editor(
            :categories="emailAddressCategories"
            :value="contact.emailAddresses"
            resource-type-label="Email address"
            :can-cancel="false",
            @input="updateEmailAddresses"
          )

        app-panel.mt-4(secondary)
          app-header(size="h3")
            | Telephone (optional)
            template(#subheader) Add one or more phone numbers 

          app-categorised-list-editor(
            :categories="phoneNumberCategories"
            :value="contact.phoneNumbers"
            resource-type-label="Phone number"
            :can-cancel="false",
            @input="updatePhoneNumbers"
          )

        app-panel.mt-4(secondary)
          app-header(size="h3")
            | Address (optional)
            template(#subheader) Add an optional address for this contact

          app-text-field(v-model="contact.address.name" label="Name" description="Give the address a name to identify it by later" name="address.name" attribute="Name")
          app-text-field(v-model="contact.address.line1" label="Street address" description="Building name/number, street name, etc" name="address.line1" attribute="Street address")
          app-text-field(v-model="contact.address.line2" label="Apartment, suite, etc" description="Example: 3rd floor" name="address.line2")
          app-text-field(v-model="contact.address.city" label="City" name="address.city")
          app-text-field(v-model="contact.address.region" label="State/province" name="address.region")
          app-text-field(v-model="contact.address.country" label="Country" name="address.country")
          app-text-field(v-model="contact.address.postalCode" label="Zip/postal code" name="address.postalCode" attribute="Zip/postal code")

        .mt-4
          app-button.inline-block.mr-4(primary @click.prevent="save") Add contact 
          a.block.my-4.underline.cursor-pointer(@click.prevent="$emit('close')") Cancel
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import createContactMutation from "@/graphql/mutations/contact_management/contacts/CreateContact.gql";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import { isBlank } from "@/helpers/OtherHelpers";

export default {
  data() {
    return {
      contact: {
        name: "",
        primaryPerson: "",
        phoneNumbers: [],
        emailAddresses: [],
        address: {
          addressType: "WORK",
          name: null,
          line1: null,
          line2: null,
          line3: null,
          line4: null,
          city: null,
          region: null,
          postalCode: null,
          country: null
        }
      },
      emailAddressTypes: {
        SALES: { label: "Sales", value: "SALES" },
        SUPPORT: { label: "Support", value: "SUPPORT" },
        INFO: { label: "Info", value: "INFO" }
      },
      phoneNumberTypes: {
        SALES: { label: "Sales", value: "SALES" },
        SUPPORT: { label: "Support", value: "SUPPORT" },
        INFO: { label: "Info", value: "INFO" }
      },
      loading: false
    };
  },
  validations() {
    return {
      contact: {
        name: { required },
        address: {
          name: { required: requiredIf(this.addressPresent) },
          line1: { required: requiredIf(this.addressPresent) },
          postalCode: { required: requiredIf(this.addressPresent) }
        }
      }
    };
  },
  computed: {
    phoneNumberCategories() {
      return Object.values(this.phoneNumberTypes);
    },
    emailAddressCategories() {
      return Object.values(this.emailAddressTypes);
    }
  },
  methods: {
    addressPresent() {
      return (
        !isBlank(this.contact.address.name) ||
        !isBlank(this.contact.address.line1) ||
        !isBlank(this.contact.address.postalCode)
      );
    },
    save() {
      this.$v.contact.$touch();
      if (!this.$v.contact.$invalid) {
        this.loading = true;
        const input = { ...this.contact };

        if (!this.addressPresent()) {
          delete input.address;
        }

        this.$apollo
          .mutate({
            mutation: createContactMutation,
            variables: {
              input
            }
          })
          .catch(e => {
            this.loading = false;
            this.$flash.error(gqlErrorMessage(e));
          })
          .then(({ data: { createContact } }) => {
            this.loading = false;
            this.$emit("created", createContact.contact);
            this.$emit("close");
          });
      }
    },
    updateEmailAddresses(newEmailAddresses) {
      this.contact.emailAddresses = newEmailAddresses.map(emailAddress => ({
        emailType: emailAddress.category.value,
        emailAddress: emailAddress.value
      }));
    },
    updatePhoneNumbers(newPhoneNumbers) {
      this.contact.phoneNumbers = newPhoneNumbers.map(phoneNumber => ({
        phoneNumberType: phoneNumber.category.value,
        phoneNumber: phoneNumber.value
      }));
    }
  }
};
</script>
