<template lang="pug">
user-picker(:value="value", trigger="manual", :origin="origin", @close="finishEditing()", @input="setValue")
</template>

<script>
import { Mixins } from "manageplaces-ui-kit";
import Vue from "vue";
import UserPicker from "@/components/collections/AppUserPicker.vue";

export default Vue.extend({
  components: {
    UserPicker
  },
  mixins: [Mixins.tableCellEditor],
  data() {
    return {
      origin: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.origin = this.calculateOrigin();
    });
  },
  methods: {
    calculateOrigin() {
      const position = this.$el.getBoundingClientRect();
      const offset = 50;

      return {
        getBoundingClientRect() {
          return {
            x: position.x + offset,
            y: position.y,
            width: position.width,
            height: position.height,
            top: position.top,
            right: position.right + offset,
            bottom: position.bottom,
            left: position.left + offset
          };
        }
      };
    },
    isPopup() {
      return true;
    }
  }
});
</script>
