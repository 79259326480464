import App from "../App.vue";

const NewChangeOrder = () =>
  import(
    /* webpackChunkName: "new-change-order" */ "../views/finances/change_orders/New.vue"
  ).then(m => m.default);
const EditChangeOrder = () =>
  import(
    /* webpackChunkName: "edit-change-order" */ "../views/finances/change_orders/Edit.vue"
  ).then(m => m.default);

export default [
  {
    name: "Change orders",
    path: "/change_orders",
    component: App,
    children: [
      {
        path: "/projects/:id/change_orders/new",
        component: NewChangeOrder,
        meta: { title: "Add change order" }
      },
      {
        path: "new",
        component: NewChangeOrder,
        meta: { title: "Add change order" }
      },
      {
        path: ":id/edit",
        component: EditChangeOrder,
        meta: { title: "Edit change order" }
      }
    ]
  }
];
