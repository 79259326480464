<template lang="pug">
  .flex.flex-col(ref='description')
    .block(v-if="editing")
      app-text-field(type="richtext" v-model="description" :show-optional="false")
      .flex.flex-row
        app-button.mr-3(primary slim @click="save") Save
        app-button(default slim @click="cancelEdit") Cancel

    .relative.flex.flex-col(v-else)
      .block.italic.overflow-hidden(v-html="description || 'No description'" :class="{ 'h-24': description && !expanded  }")
      .block.mt-3(v-if="!isLengthy || expanded")
        app-button(slim icon="edit" @click="startEditing" v-if="task.canEdit") Edit description
      .block.absolute.bottom-0.right-0.left-0.h-20.bg-gradient-to-t.from-grey-10(v-if="isLengthy && !expanded")

    .flex.justify-center.mt-4(v-if="isLengthy && !editing")
      app-link(@click="toggleFull") {{ toggleBtnText }}

</template>
<script>
import UpdateTaskDescription from "@/graphql/mutations/tasks/UpdateTaskDescription.gql";
import AppLink from "@/components/elements/AppLink.vue";
import SidebarComponentMixin from "@/mixins/tasks/TaskSidebarComponentMixin";

export default {
  name: "TaskDescriptionArea",

  components: {
    AppLink
  },

  mixins: [SidebarComponentMixin],

  props: {
    task: {
      type: Object,
      default: null
    },
    value: {
      type: String,
      required: false,
      default: ""
    },
    maxLength: {
      type: Number,
      default: 200
    }
  },

  data() {
    return {
      expanded: false,
      editing: false,
      description: "",
      descBeforeEdit: ""
    };
  },

  computed: {
    sanitizedDescription() {
      return this.$sanitize(this.value) || "";
    },

    isLengthy() {
      return this.description.length >= this.maxLength;
    },

    toggleBtnText() {
      return this.expanded ? "Hide description" : "View full description";
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.description = this.descBeforeEdit = this.$sanitize(newValue || "");
        this.editing = false;
        this.expanded = false;
      }
    }
  },

  methods: {
    startEditing() {
      this.editing = true;
    },

    save() {
      this.ifNotReadOnly(() => {
        const { task, description } = this;

        this.$apollo
          .mutate({
            mutation: UpdateTaskDescription,
            variables: {
              input: {
                taskId: task.id,
                description
              }
            }
          })
          .then(({ data }) => {
            this.editing = false;

            const { description } = data.updateTaskDescription.task;
            this.descBeforeEdit = description;
            this.$emit("input", description);
            this.$flash.success("Task description updated successfully");
          });
      });
    },

    cancelEdit() {
      this.description = this.descBeforeEdit;
      this.editing = false;
    },

    toggleFull() {
      this.expanded = !this.expanded;
      const overflowElem = this.$refs.description.closest(
        ".overflow-auto,.overflow-y-auto"
      );
      this.$scrollTo(".description", 500, { container: overflowElem });
    }
  }
};
</script>
