/**
 *  Allows a vue component to be notified of changes to
 *  the global navigation menu. this menu lives outside
 *  of vue, so it is monitored a little differently
 **/
export default {
  data() {
    return {
      tabMenu: null
    };
  },
  mounted() {
    this.tabMenu = document.getElementsByClassName(
      "primary history tabs menu"
    )[0];
    this.registerTabListener();
  },
  beforeDestroy() {
    this.unregisterTabListener();
  },
  methods: {
    registerTabListener() {
      if (this.tabMenu && this.tabChanged) {
        this.tabMenu.addEventListener("change", this.tabChanged);
      }
    },
    unregisterTabListener() {
      if (this.tabMenu && this.tabChanged) {
        this.tabMenu.removeEventListener("change", this.tabChanged);
      }
    }
  }
};
