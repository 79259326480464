<template lang="pug">
  app-panel(secondary)
    app-header(size="h3") Timesheets
      template(#subheader)
        | Permissions for timesheets

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")

</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> enter timesheets",
          value: "cannotManage"
        },
        {
          label: "Can enter <strong>personal</strong> timesheet",
          value: "canManagePersonal"
        },
        {
          label: "Can enter <strong>every users</strong> timesheet",
          value: "canManageCompany"
        }
      ],
      selected: "cannotManage",
      permissions: {
        timesheet: false,
        companyTimesheet: false
      }
    };
  },
  computed: {
    selectedPermissions() {
      if (this.selected === "cannotManage") {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: "global"
      };
    }
  },
  mounted() {
    if (this.value.permissions.timesheet) {
      this.selected = "canManagePersonal";
      this.timesheet = true;
    } else if (this.value.permissions.companyTimesheet) {
      this.selected = "canManageCompany";
      this.companyTimesheet = true;
    }
  },
  methods: {
    optionChanged(value) {
      this.permissions.timesheet = value === "canManagePersonal";
      this.permissions.companyTimesheet = value === "canManageCompany";
      this.selected = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
