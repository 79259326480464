<template lang="pug">
.add-input-section(:class="inputClasses")
  input.add-input(:type="type",
                  :value="value",
                  v-on="listeners",
                  v-bind="$attrs",
                  @keyup.enter="$emit('submit')")

  .add-button(@click="calculate('add')")
    app-icon(icon="add" class="m-auto")
  .add-button(@click="calculate('subtract')")
    app-icon(icon="subtract" class="m-auto")
</template>

<script>
export default {
  name: "AppAddInput",
  props: {
    value: {
      type: [Number],
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isInputFocus: false
    };
  },
  computed: {
    inputClasses() {
      let classes = ["outline-none"];

      if (this.isInputFocus) {
        classes.push("active");
      }

      return classes;
    },
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: evt => {
          this.$emit("input", evt.target.value);
        },
        focus: () => {
          this.isInputFocus = true;
        },
        blur: () => {
          this.isInputFocus = false;
        }
      });
    }
  },
  methods: {
    calculate(type) {
      this.isInputFocus = true;
      if (type === "add") {
        this.$emit("input", this.value + 1);
      } else if (this.value >= 1) {
        this.$emit("input", this.value - 1);
      }
    },
    onFocus() {
      this.isInputFocus = true;
    },
    onBlur() {
      this.isInputFocus = false;
    }
  }
};
</script>

<style lang="postcss" scoped>
.add-input-section {
  display: inline-flex;
  height: 30px;
  border: 1px solid var(--grey-40);

  &.active,
  &:focus {
    border: 1px solid var(--tribal-aqua);
  }
}
.add-input {
  outline: none;
  text-indent: 5px;
}
.add-button {
  display: flex;
  height: 100%;
  padding: 0 0.5rem;
  border-left: 1px solid var(--grey-40);
  cursor: pointer;
}
</style>
