import sum from "ramda/src/sum";
import filter from "ramda/src/filter";
import map from "ramda/src/map";
import { RowWrapper } from "./CellHelpers";

export default class DataWrapper {
  constructor(rows) {
    this.rows = rows;
  }

  average(slug) {
    const values = this.nonZeroValues(slug);

    if (values && values.length > 0) {
      return sum(values) / values.length;
    }

    return null;
  }

  sum(slug) {
    return sum(this.nonZeroValues(slug));
  }

  sumIf(sumSlug, ifSlug, ifFn) {
    const filteredValues = map(row => {
      const rowWrapper = new RowWrapper(row);
      const sumValue = rowWrapper.value(sumSlug);
      const ifValue = rowWrapper.value(ifSlug);

      if (ifFn.call(rowWrapper, ifValue)) {
        return sumValue;
      }

      return 0;
    }, this.rows);

    const nonZeroValues = filter(
      v => !Number.isNaN(v) && v !== 0,
      filteredValues
    );

    return sum(nonZeroValues);
  }

  nonZeroValues(slug) {
    return filter(v => !Number.isNaN(v) && v !== 0, this.values(slug));
  }

  values(slug) {
    return map(row => {
      const rowWrapper = new RowWrapper(row);

      return rowWrapper.value(slug);
    }, this.rows);
  }
}
