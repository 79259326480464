<template lang="pug">
  app-sidebar(@close="$emit('close')", :tabs="tabs" :actions="actions" @action-clicked="actionClicked")
    template(#header)
      app-avatar.mr-4(:name="contactName")
      app-header(v-if="!renaming") {{ contactName }}
      .pr-8.w-full(v-else)
        app-text-input.w-full(v-focus v-model="newContactName" ref="nameInput"
                                   @keyup.enter="renameContact", @keyup.esc="stopRenaming" v-click-outside="renameContact")
    template(#overview)
      app-loader.relative(:loading="loading")
        .p-8 
          app-panel.mb-4(secondary)
            .flex.flex-row
              app-icon.flex-initial(icon="contact").mr-4
              .flex-1.flex.flex-col
                strong Primary contact
                span.text-grey-70.mb-8 {{ primaryContact }}
            contact-sidebar-email-address.mb-8(:contact="contact" @input="contact.emailAddresses = $event")
            contact-sidebar-phone-number.mb-8(:contact="contact" @input="contact.phoneNumbers = $event")
            contact-sidebar-address(:contact="contact")
          contact-sidebar-people.mb-4(:contact="contact" @primary-contact-updated="primaryContactUpdated")
          // custom-fields(:resource="contact" :task-type="false" resource-type="ContactManagement::Contact")

    template(#comments)
      comments-panel(:comments="comments" :resource-id="contact.id" resource-type="ContactManagement::Contact" v-on="commentListeners")

</template>
<script>
import ContactQuery from "@/graphql/queries/contact_management/Contact.gql";
import ContactSidebarPeople from "@/components/contact_management/sidebar/ContactSidebarPeople.vue";
import ContactSidebarEmailAddress from "@/components/contact_management/sidebar/ContactSidebarEmailAddress.vue";
import ContactSidebarPhoneNumber from "@/components/contact_management/sidebar/ContactSidebarPhoneNumber.vue";
import ContactSidebarAddress from "@/components/contact_management/sidebar/ContactSidebarAddress.vue";
import CustomFields from "@/components/resource/CustomFields.vue";
import CommentsPanel from "@/components/resource/CommentsPanel.vue";
import { confirmDelete } from "@/helpers/DialogHelpers";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import ContactManager from "@/components/contact_management/ContactManager";

export default {
  components: {
    ContactSidebarEmailAddress,
    ContactSidebarPhoneNumber,
    ContactSidebarAddress,
    ContactSidebarPeople,
    CustomFields,
    CommentsPanel
  },
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      renaming: false,
      newContactName: null,
      contact: null,
      comments: [],
      tabs: [
        { name: "Overview", slot: "overview" },
        { name: "Comments", slot: "comments" }
      ],
      contactManager: new ContactManager()
    };
  },
  apollo: {
    contact: {
      query: ContactQuery,
      variables() {
        return {
          id: this.contactId
        };
      },
      result({ data }) {
        this.comments = data.contact.comments;
        this.loading = false;
      },
      error() {
        // We'll just close the sidebar and present an error
        this.$flash.error(
          "There was an unexpected error loading the contact. Please try again"
        );
        this.$emit("close");
      },

      fetchPolicy: "no-cache"
    }
  },
  computed: {
    actions() {
      let actions = [];

      if (this.contact?.canUpdate) {
        actions.push({ label: "Rename", icon: "edit", action: "RENAME" });
      }

      if (this.contact?.canDestroy) {
        actions.push({
          label: "Delete",
          icon: "delete",
          iconColour: "red",
          action: "DELETE"
        });
      }
      return actions;
    },
    commentListeners() {
      const vm = this;
      return Object.assign(
        {},
        {
          comment(comment) {
            vm.comments.push(comment);
          }
        },
        this.$listeners
      );
    },
    contactName() {
      return this.contact?.name;
    },
    primaryContact() {
      return this.contact?.primaryContact;
    }
  },
  methods: {
    actionClicked(action) {
      switch (action.action) {
        case "RENAME":
          this.newContactName = this.contact.name;
          this.renaming = true;
          break;

        case "DELETE":
          this.deleteContact();
          break;
      }
    },
    deleteContact() {
      confirmDelete.call(
        this,
        this.contact,
        () => {
          this.contactManager
            .delete([this.contact])
            .then(() => {
              this.$flash.success("Contact successfully deleted");
              this.$emit("deleted", this.contact);
            })
            .catch(e => {
              this.$flash.error(gqlErrorMessage(e));
            });
        },
        "contact"
      );
    },
    primaryContactUpdated(name) {
      this.contact.primaryContact = name;
    },
    renameContact() {
      this.contactManager
        .rename(this.contact, this.newContactName)
        .then(() => {
          this.contact.name = this.newContactName;
          this.$flash.success("Contact successfully renamed");
          this.$emit("renamed", this.contact);
          this.stopRenaming();
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
          this.stopRenaming();
        });
    },
    stopRenaming() {
      this.newContactName = null;
      this.renaming = false;
    }
  }
};
</script>
