<template lang="pug">
  div.addon-tab
    div.addon-tab-content
      .page.loader.text-center.page-loader(v-if="loading")
        .circle
          .ring
      .text-grey-60(:class="loading ? 'bg-grey-10 opacity-25' : ''")
        app-panel.panel.addon-item(secondary)
          .flex.flex-row.justify-between.flex-initial
            .flex-1
              h2.text-grey-80.font-bold Finance Management
              span Track your expenditure on the project
            .flex.flex-initial.mt-1
              span.text-grey-80.font-bold.text-xl {{currency}}{{price}}
              span.ml-1 {{billingTypeLabel}}
          .mt-4 {{ financeServiceDescription }}
          .mt-4
            app-toggle-button(v-model="addonStatus",
                              data-cy="billing-type-switch")

        app-panel.panel.mt-8.addon-item(secondary)
          h3.text-grey-80.font-bold.mb-4 Summary
          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              .text-grey-80.font-bold Add-ons
            .flex-initial {{financeManagementStatus ? 'Enabled' : 'disabled'}}
          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              .text-grey-80.font-bold {{billingLabel}} cost change
            .flex-initial {{costChange}}

          .h-px.bg-grey-30.my-4

          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              .text-grey-80.font-bold Owner license
            .flex-initial {{currency}}{{normalizePrice(userLicensePrice)}}
          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              .text-grey-80.font-bold Additional licenses
            .flex-initial {{currency}}{{normalizePrice(userLicensePrice * additionalLicenses)}}
          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              .text-grey-80.font-bold Add-ons
            .flex-initial {{currency}}{{ financeManagementStatus ? price : 0 }}
          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              span.text-grey-80.font-bold.mr-1 Voucher
              span {{voucherName}}
            .flex-initial -{{currency}}{{normalizePrice(voucherPrice)}}
          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              span.text-grey-80.font-bold {{taxLabel}}
            .flex-initial
              | {{currency}}{{normalizePrice(taxPrice)}}

          .h-px.bg-grey-30.my-4

          .flex.flex-row.justify-between.flex-initial.text-sm
            .flex-1
              .text-grey-80.font-bold New total
            .flex-initial {{currency}}{{normalizePrice(totalPrice + taxPrice)}}
          .flex.flex-row.justify-between.flex-initial(v-if="chargedAmountAtToday != 0")
            .flex-1
              .text-grey-80.font-bold Charged today
            .flex-initial {{currency}}{{normalizePrice(chargedAmountAtToday)}}

        .addon-item.my-8
          .text-grey-60 I authorise ManagePlaces to charge my credit / debit card {{currency}}{{normalizePrice(chargedAmountAtToday != 0 ?chargedAmountAtToday : totalPrice + taxPrice)}}
          app-button(primary
            class="w-full text-center focus:outline-none focus:shadow-outline mt-1 mb-4"
            @click.prevent="onSubmit"
            data-cy="addons-submit"
          ) Update add-ons
          a(href="#", @click.prevent="$emit('close')",
            data-cy="close-btn") Cancel

</template>

<script>
import { debounce } from "lodash";
import UpcomingInvoiceMutation from "@/graphql/mutations/subscriptions/UpcomingInvoice.gql";
import { normalizeFloat } from "@/helpers/StringHelpers";
import FiananceMixin from "@/mixins/FinanceValidator";
import { taxLabel } from "@/helpers/SubscriptionHelpers";

export default {
  name: "AddOnsTab",
  mixins: [FiananceMixin],
  props: {
    subscription: {
      type: Object,
      required: false,
      default: null
    },
    userLicensePrice: {
      type: Number,
      required: true
    },
    addOnPrice: {
      type: Number,
      required: true
    },
    additionalLicenses: {
      type: Number,
      required: true
    },
    totalPrice: {
      type: Number,
      required: true
    },
    financeManagementStatus: {
      type: Boolean,
      required: true
    },
    voucher: {
      type: Object,
      required: false,
      default: null
    },
    taxRate: {
      type: Object,
      required: true
    },
    addOnLicense: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    voucherPrice: {
      type: Number,
      requried: true,
      default: 0
    },
    taxPrice: {
      type: Number,
      required: true
    },
    nextPayment: {
      type: Number,
      required: true,
      default: 0
    },
    previousFinanceStatus: {
      type: Boolean,
      requried: true,
      default: false
    }
  },
  data() {
    return {
      financeServiceDescription: `The finance management module allows you to keep track of how much your project is costing for its development. You can also track income, and integrate it with your Xero accounting package.`,
      loading: false,
      upcomingInvoice: null,
      addonStatus: this.financeManagementStatus,
      addonOptions: [
        {
          label: "Enabled",
          value: true
        },
        {
          label: "Disabled",
          value: false
        }
      ]
    };
  },
  computed: {
    voucherName() {
      return this.voucher ? `(${this.voucher.name})` : "";
    },
    taxLabel() {
      return taxLabel(this.taxRate);
    },
    billingTypeLabel() {
      if (!this.subscription) return "";
      const { billingFrequency } = this.subscription;
      return billingFrequency === "a" ? "/ year" : "/ month";
    },
    billingLabel() {
      if (!this.subscription) return "";
      const { billingFrequency } = this.subscription;
      return billingFrequency === "a" ? "Yearly" : "Monthly";
    },
    price() {
      return normalizeFloat(this.addOnPrice);
    },
    costChange() {
      if (this.previousFinanceStatus === this.addonStatus) {
        return `+${this.currency}0`;
      }

      return this.addonStatus
        ? `+${this.currency}${this.price}`
        : `-${this.currency}${this.price}`;
    },
    chargedAmountAtToday() {
      return this.upcomingInvoice ? this.upcomingInvoice.amountDue : 0;
    }
  },
  watch: {
    addonType(oldVal, newVal) {
      this.debounceGetUpcomingInvoice(newVal);
    }
  },
  created() {
    this.debounceGetUpcomingInvoice = debounce(this.getUpcomingInvoice, 300);
  },
  methods: {
    onSubmit() {
      this.$emit("update-feature", {
        quantity: this.addonStatus ? 1 : 0,
        licenseId: this.addOnLicense.licenseId
      });
    },
    getUpcomingInvoice(input) {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: UpcomingInvoiceMutation,
          variables: {
            input: {
              quantity: !input.value ? 1 : 0,
              licenseId: this.addOnLicense.licenseId
            }
          }
        })
        .then(({ data: { upcomingInvoice } }) => {
          this.loading = false;
          this.upcomingInvoice = upcomingInvoice.invoice;
        })
        .catch(() => {
          this.loading = false;
          this.upcomingInvoice = null;
        });
    }
  }
};
</script>
