export const resourceWatchers = (resourceName, deep = true) => {
  return {
    [resourceName]: {
      deep,
      handler(updatedResource) {
        this.$emit("input", updatedResource);
      }
    },

    value: {
      deep,
      handler(updatedValue) {
        this.resource = updatedValue;
      }
    }
  };
};
