<script>
import Vue from "vue";
import { Components } from "manageplaces-ui-kit";

export default Vue.extend({
  render(h) {
    const team = this.params.value;
    const avatars = team.map((user, idx) => {
      let classes = "flex-1 self-center";
      if (idx !== 0) {
        classes += " -ml-4";
      }

      return h(Components.AppAvatar, {
        props: { user, bordered: true },
        staticClass: classes
      });
    });

    return h("span", { staticClass: "flex flex-row" }, avatars);
  }
});
</script>
