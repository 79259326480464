export const ACTIONS = {
  EDIT: "edit",
  DELETE: "delete"
};

export const invoicesMenuItems = row => {
  let actions = [];

  if (!row.data.hasPayments) {
    if (row.data.editable) {
      actions.push({
        label: "Edit invoice",
        icon: "edit",
        action: ACTIONS.EDIT
      });
      actions.push({ type: "divider" });
    }

    if (row.data.voidable) {
      actions.push({
        label: "Void",
        icon: "void",
        iconColour: "red",
        action: ACTIONS.VOID
      });
    }

    if (row.data.status !== "AUTHORISED" && row.data.status !== "VOIDED") {
      actions.push({
        label: "Delete",
        icon: "delete",
        iconColour: "red",
        action: ACTIONS.DELETE
      });
    }
  }

  return actions;
};

export const bulkActions = [
  {
    label: "Delete",
    icon: "delete",
    iconColour: "red",
    action: ACTIONS.DELETE
  }
];
