<template lang="pug">
  app-dialog(@cancel="$emit('cancel')")
    template(#title) No licenses remaining

    p.text-grey-70.mb-8
      | You don't currently have any licenses remaining to assign. Please contact
      | #[span.font-bold {{ companyOwner.name }}] to request more licenses

    .text-right
      app-button(primary @click="$emit('cancel')") Dismiss
</template>

<script>
export default {
  name: "NoLicensesDialog",
  computed: {
    companyOwner() {
      return this.$store.state.companyOwner;
    }
  }
};
</script>

<style lang="postcss" scoped>
.dialog {
  min-width: 400px;
  width: 400px;
}
</style>
