<template lang="pug">
  app-dropdown(pointing, v-bind="$attrs", v-on="$listeners")
    template(#trigger)
      slot(name="trigger")
        app-button(primary, round)
          app-icon(icon="edit", colour="white")

    template(#content)
      .users.p-8.flex.flex-col
        app-header.flex-initial(size="h4")
          slot(name="title") Add or remove users
        app-text-input.w-full.mb-4.flex-initial(v-focus, v-model="searchTerm", icon="search")

        p.text-grey-60.flex-initial(v-if="!filteredUsers.length") No users found

        .overflow-y-auto.flex-1(v-else)
          .flex.flex-row.items-center.cursor-pointer(v-for="(user, idx) in filteredUsers", :key="user.id", @click="toggleUser(user)", :class="{ 'mt-6': idx !== 0 }")
            app-checkbox(:value="user.selected")
            app-team-member.ml-4(:user="user")


</template>

<script>
import UsersQuery from "@/graphql/queries/core/company/UsersSummary.gql";
import clone from "ramda/src/clone";

export default {
  apollo: {
    companyUsers: {
      query: UsersQuery,
      update(data) {
        return data.companyUsersSummary.edges.map(edge => ({
          ...edge.node,
          selected: this.isSelected(edge.node)
        }));
      }
    }
  },
  props: {
    single: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: [Object, Array],
      required: false,
      default() {
        if (this.single) {
          return {};
        } else {
          return [];
        }
      }
    }
  },
  data() {
    return {
      companyUsers: [],
      selectedUsers: clone(this.value) || (this.single ? null : []),
      searchTerm: ""
    };
  },
  computed: {
    filteredUsers() {
      if (this.searchTerm === "") {
        return this.companyUsers;
      }

      return this.companyUsers.filter(
        u => u.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1
      );
    }
  },
  watch: {
    value(newValue) {
      this.selectedUsers = clone(newValue);
      this.refreshSelected();
    }
  },
  methods: {
    deselectUser() {
      const selected = this.companyUsers.find(u => u.selected);
      if (selected) {
        selected.selected = false;
      }
    },
    isSelected(user) {
      if (this.single) {
        return this.selectedUsers ? this.selectedUsers.id === user.id : false;
      } else {
        return this.selectedUsers.some(u => u.id === user.id);
      }
    },
    refreshSelected() {
      this.companyUsers.forEach(
        user => (user.selected = this.isSelected(user))
      );
    },
    toggleUser(user) {
      if (this.single) {
        this.toggleSingleUser(user);
      } else {
        this.toggleMultiUser(user);
      }

      if (user.selected) {
        this.$emit("selected", user);
      } else {
        this.$emit("deselected", user);
      }
      this.$emit("input", this.selectedUsers);
    },
    toggleMultiUser(user) {
      // Find the index of the user to see if they're already selected
      if (user.selected) {
        // Deselecting them
        user.selected = false;

        // Find their index and remove from selected users
        const idx = this.selectedUsers.findIndex(
          selected => selected.id === user.id
        );
        this.selectedUsers.splice(idx, 1);
      } else {
        user.selected = true;
        this.selectedUsers.push(user);
      }
    },
    toggleSingleUser(user) {
      if (user.selected) {
        // Deselecting the user. No user will be selected now
        user.selected = false;
        this.selectedUsers = null;
      } else {
        // Selecting this user. Deselect existing first though
        this.deselectUser();
        this.selectedUsers = user;
        user.selected = true;
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.users {
  min-width: 330px;
  max-height: 400px;
}
</style>
