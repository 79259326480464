<template lang="pug">
  div
    .flex
      .h-16.w-full.flex.flex-row.items-center
        .flex-1
          app-text-input.w-full(
            v-model="searchDocument"
            v-focus
            icon="search")

      .flex.flex-row.items-center.ml-10(ref="attach")
        app-split-button(@click="attach" :options="actions") Attach

    app-dropdown.mt-5.mb-5(@click="filter" :options="filters")
      template(#trigger="{ open }")
        .flex.w-full.h-full.text-center.justify-between.cursor-pointer.items-center
          app-file-icon.mr-2(size="small" :extension="filterSelected.icon")
          | {{ selectedTitle }}
          app-icon.ml-2(size="small", :icon="open ? 'caretUp': 'caretDown'", block)

    div(v-if='attached_documents && attached_documents.length > 0')
      .div.mb-4(v-for="(document, index) in attached_documents")
        .flex.justify-between.items-center
          .flex
            app-file-icon.mr-4(v-if="document.currentVersion", :extension="document.currentVersion.upload.fileExtension")
            app-file-icon.mr-4(v-else, extension="folder")
            .icon
              img(:src="document.icon")
            .info
              .mb-1 {{ document.name }}
              .path {{ document.parentPath }}
          app-dropdown.flex-shrink-0(:options="options", @click="(option) => action(option, document)")
            template(#trigger)
              .w-full.h-full.text-center.flex.flex-col.justify-center.cursor-pointer.items-center
                app-icon(icon="actions", block)
        .h-px.bg-grey-30.w-full.my-4
    .flex.justify-between.items-center.w-full(v-if='attached_documents && attached_documents.length == 0')
      | No documents

</template>
<script>
import { mapState } from "vuex";
import CollapsableSection from "@/mixins/CollapsableSection";
import SidebarComponentMixin from "@/mixins/tasks/TaskSidebarComponentMixin";
import AttachDocument from "@/components/projects/dialogs/AttachDocument.vue";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import PreviewDialog from "@/components/documents/PreviewDialog.vue";
import DocumentManager from "@/components/documents/DocumentManager";
import AttachedDocuments from "@/graphql/queries/document_management/AttachedDocuments.gql";
import DocumentsUploadModal from "@/components/documents/DocumentsUploadModal.vue";

export default {
  components: { DocumentsUploadModal },

  mixins: [CollapsableSection, SidebarComponentMixin],
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  apollo: {
    attached_documents: {
      query: AttachedDocuments,
      variables() {
        let vars = {
          id: this.task.id,
          search: this.searchDocument || "*"
        };

        if (this.filterSelected && this.filterSelected.name != "all") {
          if (this.filterSelected.name === "OTHER") {
            vars.where = {
              fileCategory: { not: ["FOLDER", "IMAGE", "PDF", "TEXT", "VIDEO"] }
            };
          } else {
            vars.where = { fileCategory: this.filterSelected.name };
          }
        }

        return vars;
      },
      update(data) {
        return data.attachedDocuments.edges.map(({ node }) => node);
      },
      fetchPolicy: "no-cache",
      debounce: 200
    }
  },

  data() {
    return {
      searchDocument: "",
      docManager: new DocumentManager(this.task?.project),
      attaching: false,
      options: [
        {
          icon: "download",
          name: "download",
          label: "Download"
        },
        {
          icon: "preview",
          name: "preview",
          label: "Preview"
        },
        { type: "divider" },
        {
          icon: "link",
          name: "detach",
          label: "Detach"
        }
      ],
      actions: [
        {
          icon: "link",
          name: "attach",
          label: "Attach"
        },
        {
          icon: "upload",
          name: "upload",
          label: "Upload"
        }
      ],
      filters: [
        {
          icon: "default",
          name: "all",
          label: "All files",
          iconType: "file"
        },
        {
          icon: "folder",
          name: "FOLDER",
          label: "Folders",
          iconType: "file"
        },
        { type: "divider" },
        {
          icon: "bmp",
          name: "IMAGE",
          label: "Images",
          iconType: "file"
        },
        {
          icon: "pdf",
          name: "PDF",
          label: "PDF",
          iconType: "file"
        },
        {
          icon: "doc",
          name: "TEXT",
          label: "Documents",
          iconType: "file"
        },
        {
          icon: "mov",
          name: "VIDEO",
          label: "Videos",
          iconType: "file"
        },
        {
          icon: "default",
          name: "OTHER",
          label: "Other files",
          iconType: "file"
        }
      ],
      filterSelected: {
        icon: "default",
        name: "all",
        label: "All files",
        iconType: "file"
      },
      pendingAttachment: false
    };
  },

  computed: {
    selectedTitle() {
      return this.filterSelected?.label || "All files";
    },
    ...mapState("documentManagement", ["activeUploads"])
  },

  mounted() {
    this.$store.dispatch(
      "documentManagement/registerUploadCallback",
      this.onDocumentUploaded
    );
    if (this.task.project) {
      this.$store.dispatch("documentManagement/setProject", this.task.project);
    }
  },

  methods: {
    callAttach(params) {
      this.docManager
        .attachDocument(params)
        .then(response => {
          if (
            response.data.attachDocumentsToTasks &&
            response.data.attachDocumentsToTasks.attachedDocuments
          ) {
            let existing_doc_ids = this.attached_documents.map(ad => ad.id);
            this.attached_documents.push(
              ...response.data.attachDocumentsToTasks.attachedDocuments.edges
                .map(({ node }) => node)
                .filter(doc => existing_doc_ids.indexOf(doc.id) == -1)
            );
          }
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    },
    onDocumentUploaded(documentID) {
      if (this.pendingAttachment) {
        let params = {
          taskIds: [this.task.id],
          documentIds: [{ id: documentID, resourceType: "file" }]
        };
        this.callAttach(params);

        if (this.activeUploads === 0) {
          // No more uplaods so stop listening for them
          this.pendingAttachment = false;
        }
      }
    },
    attach(option) {
      if (option && option.name == "upload") {
        this.openFileUploadModal();
        return;
      }
      let dialog = this.$dialog.show(AttachDocument, {
        props: {
          projectId: this.task.project.id
        }
      });

      dialog.onOk(({ api, data }) => {
        let params = {
          taskIds: [this.task.id],
          ...data
        };
        api.hide();
        this.callAttach(params);
      });
    },
    openFileUploadModal() {
      this.$store.commit("documentManagement/setMode", "upload");
      this.pendingAttachment = true;
      this.$dialog.show(DocumentsUploadModal, {
        on: {
          cancel() {
            // If not uploads are actually taking place, then we don't
            // need to listen out for completed uploads anymore
            if (this.activeUploads === 0) {
              this.pendingAttachment = false;
            }
          }
        }
      });
    },
    action(action, document) {
      switch (action.name) {
        case "download":
          this.docManager.downloadDocuments(document);
          break;
        case "preview":
          this.$dialog.show(PreviewDialog, {
            props: {
              document: document
            }
          });
          break;
        case "detach":
          this.docManager.detachDocument(document, this.task).then(response => {
            if (
              response.data.detachDocumentFromTask &&
              response.data.detachDocumentFromTask.detachedDocuments
            ) {
              const idx = this.attached_documents
                .map(ad => ad.id)
                .indexOf(
                  response.data.detachDocumentFromTask.detachedDocuments.edges.map(
                    ({ node }) => node
                  )[0].id
                );
              this.attached_documents.splice(idx, 1);
            }
          });
          break;
      }
    },
    filter(value) {
      this.filterSelected = value;
    }
  }
};
</script>
