<template lang="pug">
  app-panel(secondary)
    .flex.flex-col.flex-grow
      .flex.flex-col
        .flex.flex-col
          strong.flex-grow People
          span.text-grey-70 Key people within {{contact.name}}
          .flex.flex-row.mt-8(v-if="peopleCount()") You haven't added any people to #[span.font-bold.ml-1 {{contact.name}}]

        .mt-8.person.border-b.border-grey-30(v-for="person in sortedPeople")
          template(v-if="!isEditing(person)")
            .flex.flex-row
              app-avatar.flex-initial(:name="person.firstName + ' ' + person.lastName")
              .flex-1.flex.flex-col.ml-4
                .flex.flex-row
                  .flex.flex-col.flex-grow
                    .flex-grow-0 {{ person.firstName }} {{ person.lastName }}
                    .flex.flex-col.flex-grow.mb-4.text-sm.text-grey-700 {{ person.contactRole || "No role" }} 
                  app-label.mr-4.self-start(v-if="person.isPrimary") Primary
                  a(@click.prevent="editPersonToggle(person)") Edit

                .flex.flex-row
                  app-icon(icon="email").mr-4
                  .flex.flex-col.mb-4
                    strong.flex-grow-0.mr-8 Email address
                    .flex.flex-col.flex-grow
                      a(v-if="person.email" :href="`mailto:${person.email}`") {{ person.email }}
                      .italic(v-else) No email address
        
                .flex.flex-row
                  app-icon(icon="phone").mr-4
                  .flex.flex-col.mb-4
                    strong.flex-grow-0.mr-8 Phone number
                    .flex.flex-col.flex-grow
                      a(v-if="person.phoneNumber" :href="`tel:${person.phoneNumber}`") {{ person.phoneNumber}}
                      .italic(v-else) No phone number

          template(v-if="isEditing(person)")
            app-panel(nested)
              .flex.flex-row.mb-6
                span.font-bold.text-lg.flex-1 Editing {{ editingPerson.firstName }} {{ editingPerson.lastName }}
                a.text-withered-cherry(@click.prevent="deleteContactPerson") Delete

              form-wrapper(:validator="$v.editingPerson")
                form.new
                  app-text-field(name="firstName", label="First name", v-model="editingPerson.firstName" v-focus)
                  app-text-field(name="lastName", label="Last name", v-model="editingPerson.lastName")
                  app-text-field(name="contactRole", label="Job title", v-model="editingPerson.contactRole")
                  app-text-field(name="email", label="Email Address", v-model="editingPerson.email")
                  app-text-field(name="phoneNumber", label="Phone Number", v-model="editingPerson.phoneNumber")
                  app-checkbox(name="isPrimary", label="This is primary contact",v-model="editingPerson.isPrimary")

                  .mt-8
                    app-button(slim primary, @click.prevent="updateContactPerson") Update
                    a.mt-4.block(@click.prevent="cancelEdit") Cancel

        
      div.mt-8(v-if="!editingPerson && !createPersonFormToggle")
        app-button.border-tribal-aqua.flex-initial.text-center(primary slim @click="createPersonToggle") Add person

      app-panel.mb-4(nested v-if="createPersonFormToggle")
        app-header(size="h3") Add a new person

        form-wrapper(:validator="$v.createPerson")
          form.new
            app-text-field(name="firstName", label="First name", v-model="createPerson.firstName" attribute="first name" v-focus)
            app-text-field(name="lastName", label="Last name", v-model="createPerson.lastName")
            app-text-field(name="contactRole", label="Job title", v-model="createPerson.contactRole")
            app-text-field(name="email", label="Email Address", v-model="createPerson.email")
            app-text-field(name="phoneNumber", label="Phone Number", v-model="createPerson.phoneNumber")
            app-checkbox.mb-4(name="isPrimary", label="This is primary contact",v-model="createPerson.isPrimary")

          .mt-8
            app-button(slim primary, @click.prevent="createContactPerson") Add person
            a.block.mt-4(@click.prevent="cancelCreate") Cancel

</template>

<script>
import CreateContactPersonMutation from "@/graphql/mutations/contact_management/contact_people/createContactPerson.gql";
import UpdateContactPersonMutation from "@/graphql/mutations/contact_management/contact_people/updateContactPerson.gql";
import DeleteContactsMutation from "@/graphql/mutations/contact_management/contacts/deleteContacts.gql";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      people: [],
      editingPerson: null,
      createPerson: {
        firstName: null,
        lastName: null,
        contactRole: null,
        email: null,
        phoneNumber: null,
        isPrimary: false
      },
      createPersonFormToggle: false
    };
  },
  computed: {
    sortedPeople() {
      if (!this.people?.length) {
        return [];
      }

      return this.people.slice().sort((first, second) => {
        if (first.isPrimary) return -1;
        if (second.isPrimary) return 1;

        const firstName = `${first.lasName} ${first.firstName}`.trim();
        const secondName = `${second.lasName} ${second.firstName}`.trim();

        return firstName.localeCompare(secondName);
      });
    }
  },
  mounted() {
    this.people = this.contact.people.map(person => ({
      ...person,
      key: Math.random()
        .toString(36)
        .substr(2, 9)
    }));
  },
  methods: {
    editPersonToggle(person) {
      this.editingPerson = { ...person };
    },
    isEditing(person) {
      if (this.editingPerson) {
        return this.editingPerson.key === person.key;
      }

      return false;
    },
    updateContactPerson() {
      this.$v.editingPerson.$touch();
      if (this.$v.editingPerson.$invalid) {
        return;
      }
      let inputPerson = { ...this.editingPerson };
      delete inputPerson.__typename;
      delete inputPerson.key;
      if (inputPerson.isPrimary) {
        this.people.forEach(person => (person.isPrimary = false));
        this.$emit(
          "primary-contact-updated",
          `${inputPerson.firstName} ${inputPerson.lastName}`
        );
      }
      return this.$apollo
        .mutate({
          mutation: UpdateContactPersonMutation,
          variables: {
            input: {
              parentId: this.contact.id,
              ...inputPerson
            }
          }
        })
        .then(() => {
          this.$flash.success("Contact person updated");
          let person = this.people.find(
            person => person.key === this.editingPerson.key
          );
          Object.assign(person, this.editingPerson);
          this.editingPerson = null;
        });
    },
    deleteContactPerson() {
      this.$dialog
        .confirm({
          title: "Delete person?",
          message: "Deleting a person cannot be undone",
          danger: true,
          confirmLabel: "Delete"
        })
        .onOk(({ api }) => {
          api.hide();
          return this.$apollo
            .mutate({
              mutation: DeleteContactsMutation,
              variables: {
                input: {
                  id: [this.editingPerson.id]
                }
              }
            })
            .then(() => {
              if (this.editingPerson.isPrimary) {
                this.$emit("primary-contact-updated", "No primary contact");
              }
              this.$flash.success("Contact person deleted");
              let personIndex = this.people.findIndex(
                person => person.key === this.editingPerson.key
              );
              this.people.splice(personIndex, 1);
              this.editingPerson = null;
            });
        });
    },
    cancelEdit() {
      this.editingPerson = null;
    },
    cancelCreate() {
      this.createPersonFormToggle = false;
    },
    createPersonToggle() {
      this.createPersonFormToggle = true;
    },
    createContactPerson() {
      this.$v.createPerson.$touch();
      if (this.$v.createPerson.$invalid) {
        return;
      }

      return this.$apollo
        .mutate({
          mutation: CreateContactPersonMutation,
          variables: {
            input: {
              companyId: this.contact.companyId,
              parentId: this.contact.id,
              ...this.createPerson
            }
          }
        })
        .then(response => {
          const person = response.data.createContactPerson.contactPerson;
          delete person.parentId;
          delete person.companyId;
          this.$flash.success("Contact person created");
          if (person.isPrimary) {
            this.people.forEach(person => (person.isPrimary = false));
            this.$emit(
              "primary-contact-updated",
              `${person.firstName} ${person.lastName}`
            );
          }
          var key = Math.random()
            .toString(36)
            .substr(2, 9);
          this.people.push({
            key: key,
            ...person
          });
          this.createPersonFormToggle = false;
          this.createPerson = {
            firstName: null,
            lastName: null,
            contactRole: null,
            email: null,
            phoneNumber: null,
            isPrimary: false
          };
        });
    },
    peopleCount() {
      if (this.people.length === 0) {
        return true;
      }
    }
  },
  validations() {
    return {
      editingPerson: {
        firstName: {
          required
        }
      },
      createPerson: {
        firstName: {
          required
        }
      }
    };
  }
};
</script>
<style type="text/css">
.person:last-child {
  border: none;
}
</style>
