export const ACTIONS = {
  RENAME: "renameRole",
  EDIT: "editRole",
  DUPLICATE: "duplicate",
  DELETE: "delete"
};

export const rolesMenuItems = (role, permissions) => {
  let actions = [];
  const { isSystem } = role;

  if (isSystem) return [];

  if (permissions.canEdit) {
    actions.push({
      label: "Rename role",
      icon: "edit",
      action: ACTIONS.RENAME
    });

    actions.push({
      label: "Edit premissions",
      icon: "padlock",
      action: ACTIONS.EDIT
    });
  }

  if (actions.length) {
    actions.push({ type: "divider" });
  }

  if (permissions.canCreate) {
    actions.push({
      label: "Duplicate",
      icon: "copy",
      action: ACTIONS.DUPLICATE
    });
  }

  if (actions.length) {
    actions.push({ type: "divider" });
  }

  if (permissions.canDestroy) {
    actions.push({
      label: "Delete",
      icon: "delete",
      iconColour: "red",
      action: ACTIONS.DELETE
    });
  }

  return actions;
};
