<template lang="pug">
  app-dropdown-field(v-if="task.canEdit" :options="priorities", @input="optionClicked", :value="priority", value-attr="value", :allow-empty="false", deselect-label="", :show-optional="false")
  .flex.flex-row.items-center.mb-4(v-else)
    app-icon.mr-2(:icon="priorityObject.icon" :colour="priorityObject.iconColour")
    span {{ priorityObject.label }}
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    task: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      priority: this.value,
      priorities: [
        {
          label: "Urgent",
          icon: "warning",
          iconColour: "red",
          value: "URGENT"
        },
        {
          label: "High",
          icon: "warning",
          iconColour: "yellow",
          value: "HIGH"
        },
        {
          label: "Normal",
          icon: "normalPriority",
          iconColour: "blue",
          value: "NORMAL"
        },
        {
          label: "Low",
          icon: "lowPriority",
          iconColour: "grey",
          value: "LOW"
        }
      ]
    };
  },
  computed: {
    priorityObject() {
      return this.priorities.find(p => p.value === this.priority);
    }
  },
  // computed: {
  //   ...mapGetters("taskManagement", ["actionsCallers"])
  // },
  watch: {
    value(newPriority) {
      this.priority = newPriority;
    }
  },
  methods: {
    optionClicked(opt) {
      this.$emit("input", opt);
    }
  }
};
</script>
