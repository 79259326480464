/**
 * Given a URL, triggers the browser to download it. This is done
 * by creating an invisible anchor tag and "clicking" it. The
 * browser will handle the rest
 */
export function triggerDownload(url) {
  const a = document.createElement("a");
  a.style = "display: none;";
  a.href = url;

  if (a.download !== undefined) {
    a.download = "";
  } else {
    a.target = "_blank";
  }

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function triggerZipDownload(
  { folders, documents },
  relativePath,
  { project, share }
) {
  const form = _elem("form");
  const pathInp = _elem("input");
  const parentInp = _elem("input");
  const parent = _zipDownloadParent(project, share);

  form.style = "display: none;";
  _appendDocInputs(form, folders, "folders");
  _appendDocInputs(form, documents, "documents");

  pathInp.setAttribute("name", "download[path]");
  pathInp.value = relativePath || "/";
  form.appendChild(pathInp);

  if (parent) {
    parentInp.setAttribute("name", `download[${parent.param}]`);
    parentInp.value = parent.value;
    form.appendChild(parentInp);
  }

  form.setAttribute("action", "/download");
  form.setAttribute("method", "POST");

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

function _elem(type) {
  return document.createElement(type);
}

function _zipDownloadParent(project, share) {
  if (project) {
    return { param: "project_id", value: project.id };
  } else if (share) {
    return { param: "share_id", value: share.id };
  }
}

function _appendDocInputs(form, docs_or_folders, input_name) {
  docs_or_folders.forEach(doc_or_folder => {
    const input = _elem("input");
    input.setAttribute("name", `download[${input_name}][]`);
    input.value = doc_or_folder.id;
    form.appendChild(input);
  });
}
