<template lang="pug">
.flex.flex-row.bg-grey-80.rounded-t-lg
  app-tooltip(label="Expand all" v-if="isVisible('expand')")
    .menu-item(@click="$emit('expand-all')")
      app-icon(colour="white",icon="expandAll")

  app-tooltip(label="Collapse all" v-if="isVisible('collapse')")
    .menu-item(@click="$emit('collapse-all')")
      app-icon(colour="white", icon="collapseAll")

  .divider(v-if="isVisible('expand') || isVisible('collapse')")

  app-tooltip(label="Zoom in" v-if="isVisible('zoom')")
    .menu-item(@click="$emit('zoom-in')")
      app-icon(colour="white", icon="zoomIn")

  app-tooltip(label="Zoom out" v-if="isVisible('zoom')")
    .menu-item(@click="$emit('zoom-out')")
      app-icon(colour="white", icon="zoomOut")

  app-tooltip(label="Toggle fullscreen" v-if="isVisible('fullscreen')")
    .menu-item(@click="$emit('toggle-fullscreen')")
      app-icon(colour="white", icon="fullscreen")

  .divider(v-if="isVisible('zoom') || isVisible('fullscreen')")

  app-tooltip(label="Print" v-if="isVisible('print')")
    .menu-item(@click="$emit('print')")
      app-icon(colour="white", icon="print")

  app-tooltip(label="Export to Excel" v-if="isVisible('export')")
    .menu-item(@click="$emit('export')")
      app-icon(colour="white", icon="export")

  .divider(v-if="isVisible('print')")

  app-tooltip(label="Toggle baselines" v-if="isVisible('baselines')")
    .menu-item(@click="$emit('toggle-baselines')")
      app-icon(colour="white", icon="baselines")

  .self-center.ml-auto.mr-4.flex-none
    i.inverted.loading.refresh.icon(v-if="loading")
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    exclude: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      visible: {
        expand: true,
        collapse: true,
        zoom: true,
        fullscreen: true,
        print: true,
        export: true,
        baselines: true
      }
    };
  },
  mounted() {
    this.exclude.forEach(opt => {
      this.visible[opt] = false;
    });
  },
  methods: {
    isVisible(option) {
      return this.visible[option];
    }
  }
};
</script>

<style lang="postcss">
.menu-item {
  @apply p-4;
  @apply cursor-pointer;

  &:hover {
    @apply bg-grey-60;
  }
}

.divider {
  @apply flex-none;
  @apply border-grey-60;
  @apply border;
  @apply my-2;
  @apply mx-2;
}
</style>
