import { mapState } from "vuex";
import TaskManager from "@/components/projects/tasks_table/TaskManager";

export const readOnlyError =
  "Updates are still being processed, please try again.";

export default {
  computed: {
    ...mapState("taskManagement", ["readOnly"]),
    taskManager() {
      return new TaskManager({ id: this.task?.projectId });
    }
  },

  methods: {
    ifNotReadOnly(fn, fnElse, warn = true) {
      if (this.readOnly) {
        if (warn) {
          this.$flash.warn(readOnlyError);
        }
        fnElse();
      } else {
        fn();
      }
    }
  }
};
