<template lang="pug">
  app-panel.panel.subscription-card.mb-4(v-if="!loading")
    h2.font-bold Your plan
    .flex.mt-4
      .cursor-pointer.inline-block.rounded-full.w-12.h-12.inline-flex.flex-row.items-center.justify-center.text-white.font-bold.shadow-lg.bg-white
        app-icon.handle(icon="company")
      .flex-1.ml-4.my-auto
        .font-bold {{planLabel}}
        span.text-grey-60 {{maxTeamSize}} {{userLabel}} | {{maxProjects}} {{projectLabel}}

    .progress.mt-8.text-center
      .justify-center
        template(v-if="subscription.isTrial")
          span.font-bold.text-xl.mr-1 {{currency}}0
            span *
        template(v-else-if="subscription.plan.name.toLowerCase() == 'free'")
          span.font-bold.text-xl.mr-1 {{currency}}0
        template(v-else)
          span.font-bold.text-xl.mr-1 {{currency}}{{subscriptionFee}}
        span.text-grey-60 / user {{billingTypeLabel}}
      template(v-if="subscription.isTrial")
        .flex.progress-bar.mt-3
          app-progress.custom-progress(:progress="progress",
            :min="0", :max="max", :colour="colours")
        .text-right
          div.mt-1.text-grey-60 {{trialRemainingLabel}}

    template(v-if="subscription.isTrial")
      app-button(primary
        class="w-full text-center focus:outline-none focus:shadow-outline mt-3"
        @click.prevent="onSubmit"
        data-cy="new-subscription-submit"
      ) Subscribe now
      .mt-2.text-center.text-grey-60.text-sm *{{currency}}{{subscriptionFee}} / user {{billingTypeLabel}} at the end of the trial

    template(v-else-if="subscription.managedByStripe || subscription.plan.name.toLowerCase() === 'free'")
      app-button(v-if="subscription.plan.name.toLowerCase() === 'free'"
        primary
        class="w-full text-center focus:outline-none focus:shadow-outline mt-8"
        @click.prevent="onSubmit"
        data-cy="new-subscription-submit"
      ) Upgrade to Pro now
      .text-center.mt-8(v-else)
        a(href="#" class="text-withered-cherry"
          @click.prevent="goToDowngradePage()"
          data-cy="new-subscription-submit"
        ) Downgrade to the Free plan

</template>

<script>
import moment from "moment";
import { initCap } from "@/helpers/StringHelpers";

export default {
  name: "SubscriptionCard",
  props: {
    subscription: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    subscriptionFee: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentTab: 0
    };
  },
  computed: {
    planLabel() {
      const { isTrial, plan } = this.subscription;
      return isTrial ? `${plan.name} plan - Trial` : `${plan.name} plan`;
    },
    maxProjects() {
      const { maxProjects } = this.subscription;
      if (!maxProjects.includes("unlimited")) {
        return maxProjects;
      }
      return initCap(maxProjects);
    },
    maxTeamSize() {
      const { maxTeamSize } = this.subscription;
      if (!maxTeamSize.includes("unlimited")) {
        return maxTeamSize;
      }
      return initCap(maxTeamSize);
    },
    billingTypeLabel() {
      const { billingFrequency } = this.subscription;
      return billingFrequency === "a" ? "/ year" : "/ month";
    },
    userLabel() {
      if (!isNaN(this.maxTeamSize) && parseInt(this.maxTeamSize) === 1) {
        return "user";
      }
      return "users";
    },
    projectLabel() {
      if (!isNaN(this.maxProjects) && parseInt(this.maxProjects) === 1) {
        return "project";
      }
      return "projects";
    },
    trialRemainingLabel() {
      if (!this.subscription) return "";
      return `${this.max - this.progress} days remaining`;
    },
    max() {
      const { startDate, endDate } = this.subscription;
      return this.getDaysDiff(startDate, endDate);
    },
    progress() {
      const { startDate } = this.subscription;
      return this.getDaysDiff(startDate) - 1;
    },
    colours() {
      const colours = {};
      colours[this.max] = "red";
      colours[Math.ceil(this.max * 0.75)] = "yellow";
      colours[Math.ceil(this.max * 0.25)] = "blue";
      return colours;
    }
  },
  methods: {
    onSubmit() {
      this.$emit("submit");
    },
    goToDowngradePage() {
      this.$router.push("/subscriptions/downgrade");
    },
    getDaysDiff(startDate, endDate = null) {
      const start = moment(startDate);
      const end = endDate ? moment(endDate) : moment(new Date());
      const duration = moment.duration(end.diff(start));
      return Math.ceil(duration.asDays());
    }
  }
};
</script>
