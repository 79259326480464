<template lang="pug">
  div.addon-tab
    div.addon-tab-content
      app-panel.panel.addon-item(secondary)
        app-text-field(name="firstName",
                        class="mb-2",
                        label="First name",
                        type="text",
                        v-model="billing.billingForename",
                        :validator="$v.billing.billingForename",
                        :messages="messages",
                        @keyup.enter="onSubmit",
                        @blur="validateField('billingForename')",
                        data-cy="firstName-input")

        app-text-field(name="lastName",
                      class="my-2",
                      label="Last name",
                      type="text",
                      :validator="$v.billing.billingSurname",
                      :messages="messages",
                      v-model="billing.billingSurname",
                      @keyup.enter="onSubmit",
                      @blur="validateField('billingSurname')",
                      data-cy="lastName-input")

        app-dropdown-field(name="country",
                          label="Country",
                          value-attr="code",
                          label-attr="name",
                          v-model="country",
                          :validator="$v.country",
                          :messages="messages",
                          :options="availableCountries",
                          data-cy="country-input",
                          @input="$v.country.$touch()")

        app-text-field(name="address1",
                      class="my-2",
                      label="Address1",
                      type="text",
                      :validator="$v.billing.billingAddress1",
                      :messages="messages",
                      v-model="billing.billingAddress1",
                      @keyup.enter="onSubmit",
                      @blur="validateField('billingAddress1')",
                      data-cy="address1-input")

        app-text-field(name="address2",
                      class="my-2",
                      label="Address2",
                      type="text",
                      :validator="$v.billing.billingAddress2",
                      :messages="messages",
                      v-model="billing.billingAddress2",
                      @keyup.enter="onSubmit",
                      @blur="validateField('billingAddress2')",
                      data-cy="address2-input")

        app-text-field(name="citry",
                      class="my-2",
                      label="City",
                      type="text",
                      :validator="$v.billing.billingAddressCity",
                      :messages="messages",
                      v-model="billing.billingAddressCity",
                      @keyup.enter="onSubmit",
                      @blur="validateField('billingAddressCity')",
                      data-cy="city-input")

        app-text-field(name="postalCode",
                      class="my-2 no-bottom",
                      label="Postal code",
                      type="text",
                      :validator="$v.billing.billingAddressPostcode",
                      :messages="messages",
                      v-model="billing.billingAddressPostcode",
                      @keyup.enter="onSubmit",
                      @blur="validateField('billingAddressPostcode')",
                      data-cy="postalCode-input")

      .addon-item.my-8
        app-button(primary
          class="w-full text-center focus:outline-none focus:shadow-outline mt-1 mb-4"
          @click.prevent="onSubmit()"
          data-cy="billing-submit"
        ) Change billing details
        a(href="#", @click.prevent="$emit('close')",
          data-cy="close-btn") Cancel

</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { INPUT_TYPE_ERROR } from "@/config/help_messages/SubscriptionMessages";

export default {
  name: "BillingTab",
  props: {
    subscription: {
      type: Object,
      required: true
    },
    availableCountries: {
      type: Array,
      required: true
    }
  },
  data() {
    const {
      billingForename,
      billingSurname,
      billingAddress1,
      billingAddress2,
      billingEmail,
      billingAddressCity,
      billingAddressCountry,
      billingAddressPostcode
    } = this.subscription;

    return {
      billing: {
        billingForename: billingForename,
        billingSurname: billingSurname,
        billingAddress1: billingAddress1,
        billingAddress2: billingAddress2,
        billingEmail: billingEmail,
        billingAddressCity: billingAddressCity,
        billingAddressPostcode: billingAddressPostcode,
        billingAddressCountry: billingAddressCountry
      },
      country:
        billingAddressCountry && billingAddressCountry.code
          ? billingAddressCountry.code
          : null,
      messages: {
        maxLength: "{label} must contain less than {max} characters",
        required: "{label} is required"
      }
    };
  },
  validations() {
    return {
      billing: {
        billingForename: { required, maxLength: maxLength(30) },
        billingSurname: { required, maxLength: maxLength(30) },
        billingAddress1: { required },
        billingAddressCity: { required },
        billingAddressPostcode: { required, maxLength: maxLength(10) }
      },
      country: { required }
    };
  },
  methods: {
    onSubmit() {
      this.$v.billing.$touch();
      this.$v.country.$touch();

      if (this.$v.billing.$invalid || this.$v.country.$invalid) {
        this.$flash.error(INPUT_TYPE_ERROR);
      } else {
        this.billing.billingAddressCountry = {
          code: this.country,
          name: this.country
        };

        this.$emit("submit", this.billing);
      }
    },
    validateField(fieldName) {
      if (this.$v.billing[fieldName]) {
        this.$v.billing[fieldName].$touch();
      }
    }
  }
};
</script>
