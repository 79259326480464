<template lang="pug">
  a.small.blue.ui.button#save-button-tabular(
    href='#',
    @click.prevent='$emit("click", $event)',
    :disabled='saveInProgress') Save
</template>

<script>
export default {
  props: ["saveInProgress"]
};
</script>
