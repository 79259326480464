<template lang="pug">
  app-panel(secondary)
    app-header(size="h3") Contacts
      template(#subheader)
        | Permissions for financial contacts

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")
      template(#options)
        app-checkbox.mb-6(label="View contacts" :value="true")
        app-checkbox.mb-6(label="Edit existing contacts" :value="permissions.editContact" @input="permissionChanged('editContact', $event)")
        app-checkbox.mb-6(label="Delete contacts" :value="permissions.removeContact" @input="permissionChanged('removeContact', $event)")
        app-checkbox.mb-6(label="Create new contacts" :value="permissions.createContact" @input="permissionChanged('createContact', $event)")

</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> access contacts",
          value: "cannotManage"
        },
        {
          label: "<strong>Can</strong> access contacts",
          value: "canManage",
          slot: "options"
        }
      ],
      selected: "cannotManage",
      permissions: {
        viewContact: false,
        createContact: false,
        editContact: false,
        removeContact: false
      }
    };
  },
  computed: {
    selectedPermissions() {
      if (this.selected === "cannotManage") {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: "global"
      };
    }
  },
  mounted() {
    if (this.value.permissions.viewContact) {
      this.selected = "canManage";
    }
  },
  methods: {
    optionChanged(value) {
      this.permissions.viewContact = value !== "cannotManage";
      this.selected = value;
      this.notifyChange();
    },
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
