<template lang="pug">
  app-dropdown.notifications(pointing)
    template(#trigger)
      .relative
        app-icon.cursor-pointer(icon="notificationSolid", colour="white", size="medium")
        .bg-tribal-aqua.rounded-full.absolute.h-5.w-5.top-0.right-0.border.border-white(v-if="unreadNotificationsCount > 0")

    template(#content)
      .flex.flex-row.p-4.pt-2
        .flex-1
          app-header(size="h3", margin="") Notifications

        .flex-1.text-right
          a(@click="markAllAsRead") Mark all as read

      .h-px.bg-grey-30.px-4

      .notifications
        .notifications-content
          template(v-for="(notification, idx) in notifications")
            .h-px.bg-grey-30.px-4(v-if="idx !== 0")

            .flex.flex-row.p-4(:class="notificationClasses(notification)", @click="notificationClicked(notification)")


              .flex-none
                app-avatar.flex-0.self-center(:user="notification.sender")

              .flex-1.flex.flex-col.ml-4
                .flex-none(v-html="notification.title")
                .flex-1.text-grey-60(v-html="notification.content")

              .flex-none.ml-4.text-grey-60.self-end {{ createdAt(notification) }}

</template>

<script>
import { format, parseISO, isToday, isYesterday } from "date-fns";
import { formatDate } from "@/helpers/DateHelpers";
import NotificationsQuery from "@/graphql/queries/core/users/Notifications.gql";
import MarkAsRead from "@/graphql/mutations/users/MarkNotificationsAsRead.gql";

export default {
  apollo: {
    notifications: {
      query: NotificationsQuery
    }
  },
  data() {
    return {
      notifications: []
    };
  },
  computed: {
    unreadNotificationsCount() {
      return this.notifications.filter(n => !n.read).length;
    }
  },
  methods: {
    notificationClicked(notification) {
      if (notification.read) {
        return;
      }

      this.markAsRead([notification]);
    },
    notificationClasses(notification) {
      let classes = ["border-l-4"];
      if (notification.read) {
        classes.push("border-white");
      } else {
        classes.push("border-tribal-aqua", "cursor-pointer");
      }

      return classes;
    },
    markAllAsRead() {
      this.markAsRead(this.notifications);
    },
    markAsRead(notifications) {
      this.$apollo
        .mutate({
          mutation: MarkAsRead,
          variables: {
            input: {
              ids: notifications.filter(n => !n.read).map(n => n.id)
            }
          }
        })
        .then(() => {
          notifications.forEach(n => (n.read = true));
        });
    },

    createdAt(notification) {
      const date = parseISO(notification.createdAt);
      const time = format(date, "H:mm");
      let displayDate;

      if (isToday(date)) {
        displayDate = "Today";
      } else if (isYesterday(date)) {
        displayDate = "Yesterday";
      } else {
        displayDate = formatDate(date, this.$store.state.dateFormats.dateFns);
      }

      return `${displayDate}, ${time}`;
    }
  }
};
</script>

<style lang="postcss" scoped>
.notifications {
  max-width: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}
.notifications-content {
  max-height: 344px;
}
</style>
