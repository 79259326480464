<template lang="pug">
  tr.top.aligned
    td.handle.centered.middle.aligned(v-if='sortable')
      app-icon(icon="dragHandle" colour="lightGrey")

    template(v-if='canEdit')
      tabular-form-cell(
        v-for='cell in row.cells',
        :key='cell.value.id',
        :classes='{ fixed: cell.label.fixed_column }',
        :cell='cell',
        :currency='currency',
        :row='row',
      )

    template(v-else)
      tabular-form-cell-readonly(
        v-for='cell in row.cells',
        :key='cell.value.id',
        :classes='{ fixed: cell.label.fixed_column }',
        :cell='cell',
        :currency='currency',
        :row='row',
      )

    td
      delete-button(
        v-if='canEdit',
        @click='deleteRow',
        confirm-title='Are you sure you want to delete this row?',
        confirm-message='')
</template>

<script>
import DeleteButton from "@/legacy/buttons/DeleteButton.vue";
import TabularFormCell from "./TabularFormCell.vue";
import TabularFormCellReadonly from "./read_only/TabularFormCellReadonly.vue";

export default {
  components: {
    TabularFormCell,
    TabularFormCellReadonly,
    DeleteButton
  },
  props: ["row", "currency", "index", "sortable", "canEdit"],
  methods: {
    deleteRow() {
      this.$emit("row-deleted", this.index);
    }
  }
};
</script>

// TODO - set properly :key // for newly created rows there are duplicates
because cell.value.id is null
