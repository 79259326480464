<template lang="pug">
  div(class="folder item" :folder_id="folder.id")
    app-file-icon.image.w-12(extension="folder")
    .content
      .header(@click="expand(); folderQuery(folder.id);")
        h4 {{ folder.name }}
        a(v-if="folder.__typename == 'Document'", :href="folder.url", target="_blank")
          app-icon(icon="download" size="medium")
      .description
        p {{ humanFileSize(folder.size) }}

    ul(v-if="children.children && children.children.length > 0"
       v-show="children.expanded"
       class="sub-folders"
      )

      folder(v-for="child in children.children"
             :key="child.name"
             :folder="child"
             :depth="depth + 1"
            )

</template>

<script>
import gql from "graphql-tag";

export default {
  name: "Folder",
  props: {
    folder: {
      type: Object,
      required: true
    },
    depth: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      children: []
    };
  },
  methods: {
    expand() {
      this.folder.expanded = !this.folder.expanded;
    },
    humanFileSize(bytes, dp = 1) {
      const thresh = 1000;

      if (Math.abs(bytes) < thresh) {
        return bytes + " B";
      }

      const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let u = -1;
      const r = 10 ** dp;

      do {
        bytes /= thresh;
        ++u;
      } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
      );

      return bytes.toFixed(dp) + " " + units[u];
    },
    folderQuery(folder_id) {
      return this.$apollo
        .query({
          query: gql`query {
                    folder(id: ${folder_id}) {
                      name
                      id
                      children {
                        ... on Document {
                          name
                          id
                          size
                          currentVersion {
                            url
                          }
                        }
                        ... on Folder {
                          name
                          id
                          size
                        }
                      }
                    }
                  }`
        })
        .then(response => {
          this.children = response.data.folder;
          this.children.expanded = true;
        });
    }
  }
};
</script>

<style scoped>
.ui.list > .item .content .header {
  display: flex;
  cursor: pointer;
}

.ui.list > .item .content .description {
  padding-top: 2px;
}

.ui.list > .item .content {
  padding: 0 0 0 0.5em;
  vertical-align: top;
}

i.file.text.outline.icon,
.content {
  display: table-cell;
}

.ui.divided.list > .item {
  border-top: none;
}

a {
  position: absolute;
  right: 50px;
  font-size: 20px;
}

.sub-folders {
  padding-top: 20px;
}

i.file.text.outline.icon {
  font-size: 34px;
  padding-top: 12px;
}

.sub-folders .folder.item {
  padding-bottom: 12px;
  padding-left: 40px;
}
</style>
