<template lang="pug">
  .upload(v-show="showingUploadSummary")
    #progress-box.fixed.bottom-0.right-0.max-h-full.bg-white.z-50(class="w-1/4")
      .bg-grey-90.px-4.pt-5.pb-4
        div
          p.inline.text-white Uploading {{ activeUploads }} files...
          app-icon.text-white.float-right.cursor-pointer(v-if="uploading", :icon="isExpanded ? 'caretDown' : 'caretUp'" size="small" colour="white" @click.native="isExpanded = !isExpanded")
          app-icon.text-white.float-right.cursor-pointer(v-else, icon="close", size="small", colour="white", @click.native="dismiss")
        div.mt-2.pb-2(v-show="!isExpanded")
          p.text-white.inline {{ formattedFileSize }}
          p.text-white.inline.float-right {{ uploadPercentage }}%
          app-progress.mt-1(:progress="uploadPercentage" :max="totalFileSize")

      .pb-4(v-show="isExpanded")
        div(ref="uploads")
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "UploadBox",
  props: {},
  data() {
    return {
      isOpen: false,
      isExpanded: true
    };
  },
  mounted() {
    // var filepond = this.$store.state.documentManagement.filepond;
    // filepond.appendTo(document.querySelector(".uploads"));
    // const pond = document.querySelector(".filepond--root");
    // pond.addEventListener("FilePond:addfile", () => {
    //   this.isOpen = true;
    // this.$store.state.documentManagement.filesCount = this.$store.state.documentManagement.filepond.getFiles().length;
    // this.$store.state.documentManagement.fileSize =
    //   this.$store.state.documentManagement.fileSize + e.detail.file.fileSize;
    // this.$store.state.documentManagement.increments[e.detail.file.id] = 0;
    // });
    // pond.addEventListener("FilePond:processfileprogress", e => {
    //   var size = e.detail.progress * e.detail.file.fileSize;
    //   this.$store.state.documentManagement.uploadPercentage =
    //     this.$store.state.documentManagement.uploadPercentage +
    //     (size -
    //       this.$store.state.documentManagement.increments[e.detail.file.id]);
    //   this.$store.state.documentManagement.increments[e.detail.file.id] =
    //     e.detail.progress * e.detail.file.fileSize;
    // });
    // pond.addEventListener("FilePond:error", () => {
    //   this.$store.state.documentManagement.fileSize = 0;
    //   this.$store.state.documentManagement.uploadPercentage = 0;
    //   this.$store.state.documentManagement.filesCount = 0;
    // });
  },
  computed: {
    ...mapState("documentManagement", [
      "activeUploads",
      "totalFileSize",
      "showingUploadSummary"
    ]),
    ...mapGetters("documentManagement", ["uploadPercentage"]),
    formattedFileSize() {
      return this.$options.filters.prettyBytes(this.totalFileSize);
    },
    uploading() {
      return this.activeUploads > 0;
    }
  },
  methods: {
    dismiss() {
      this.$store.dispatch("documentManagement/hideUploadSummary");
    }
  },
  watch: {
    showingUploadSummary(isShowing) {
      if (isShowing) {
        this.isExpanded = true;
        this.$store.dispatch(
          "documentManagement/mountUploadUI",
          this.$refs.uploads
        );
      }
    }
  }
};
</script>

<style lang="postcss">
.filepond--panel-root {
  background-color: white;
}

/* .filepond--drop-label {
  display: none;
} */

.filepond--list {
  width: 100%;
  left: 0;
  right: 0;
}

.filepond--item {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.filepond--browser {
  display: none;
}

.filepond--root {
  margin-bottom: 0;
}
</style>
