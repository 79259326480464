<template lang="pug">
  div
    app-loader(:loading="loading")
      template(v-if="isDowngraded")
        app-panel.text-center.mb-4.mt-8
          .text-left
            h2.font-bold Cancellation completed
            h3.text-grey-60 You've got some time to change your mind
          .text-left.my-12
            span You still have {{subscriptionRemainingDays}} days remaining in this billing cycle, so you will remain on the <strong>Pro</strong> plan&nbsp;
            | until {{subscriptionEndDate}}. After this date, you will revert to the <strong>Free</strong> plan automatically.
            | If you change your mind and wish to upgrade, you can do this at any time.
          app-button(primary
              class="w-64 text-center focus:outline-none focus:shadow-outline"
              @click.prevent="goToSubscription()"
            ) Continue
      template(v-else)
        app-panel
          app-message.mb-8
            p We are sorry to see you go, would you please take the time to tell us why you are leaving so we can improve our product and hopefully see you again in the future.
          div
            div(v-for="(option, index) in options")
              template(v-if="option.inputType == 'dropdown'")
                app-dropdown-field(name="reason",
                                v-model="option.value",
                                :label="option.surveyQuestion",
                                :options="getOptions(option.types)")
                app-text-field(v-if="showAdditionalInput(option)",
                            class="my-2",
                            type="text",
                            v-model="option.additionalInput",
                            :label="showAdditionLabel(option)")
              app-text-field(v-if="option.inputType == 'text'"
                              name="feedback",
                              class="my-2",
                              type="text",
                              v-model="option.value",
                              :label="option.surveyQuestion",
                              @keyup.enter="onSubmit")

          .flex.flex-row.justify-between.flex-initial
            app-button(primary  @click.prevent="onSubmit()") Yes, cancel subscription
            app-button(default  @click.prevent="goToSubscription()") No, don't cancel subscription
</template>

<script>
import moment from "moment";
import Subscription from "@/graphql/queries/core/company/Subscription.gql";
import SurveyQuestions from "@/graphql/queries/subscription/SurveyQuestions.gql";
import EndSubscriptionMutation from "@/graphql/mutations/subscriptions/EndSubscription.gql";
import { END_SUBSCRIPTION_SUCCESS } from "@/config/help_messages/SubscriptionMessages";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

export default {
  components: {},
  data() {
    return {
      options: [],
      loading: true,
      isDowngraded: false
    };
  },
  apollo: {
    surveyQuestions: {
      query: SurveyQuestions,
      result(res) {
        if (res.data && res.data.subscription) {
          this.subscription = res.data.subscription;
          this.loading = false;
          this.error = null;
        } else {
          this.error = res.error;
          this.loading = false;
        }
      }
    },
    subscription: {
      query: Subscription
    }
  },
  computed: {
    subscriptionRemainingDays() {
      if (!this.subscription) return 0;
      const { endDate } = this.subscription;
      const start = moment(new Date());
      const end = moment(endDate);
      const duration = moment.duration(end.diff(start));
      return Math.ceil(duration.asDays());
    },
    subscriptionEndDate() {
      if (!this.subscription) return "";
      const { endDate } = this.subscription;
      return moment(endDate).format("MMM DD, YYYY");
    }
  },
  watch: {
    surveyQuestions: function(val) {
      this.options = val.map(option => {
        return { ...option, value: null };
      });
    }
  },
  methods: {
    goToSubscription() {
      this.$router.push("/subscriptions");
    },
    onSubmit() {
      const surveys = this.options.map(option => {
        return {
          questionId: option.id,
          answer: option.value && option.value.label ? option.value.label : "",
          additionalInput: option.additionalInput ? option.additionalInput : ""
        };
      });

      this.loading = true;
      this.$apollo
        .mutate({
          mutation: EndSubscriptionMutation,
          variables: {
            input: {
              data: surveys
            }
          }
        })
        .then(({ data: { endSubscription } }) => {
          if (endSubscription.status) {
            this.$flash.success(END_SUBSCRIPTION_SUCCESS);
            if (this.subscription.isTrial) {
              window.location.href = "/settings/subscriptions";
            } else {
              this.isDowngraded = true;
            }
          } else {
            this.$flash.error(endSubscription.message);
          }
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.$flash.error(gqlErrorMessage(error));
        });
    },
    getOptions(types) {
      return types && types.length !== 0
        ? types.map(type => ({ label: type.option, value: type.index }))
        : [];
    },
    showAdditionalInput(option) {
      if (option.types.length === 0) return false;
      if (!option.value) return false;

      return option.types[option.value.value].additionalInput
        ? option.types[option.value.value].additionalInput
        : false;
    },
    showAdditionLabel(option) {
      if (this.showAdditionalInput) return option.value.label;
      return "";
    }
  }
};
</script>

<style lang="postcss" scoped>
.end-subscription-page {
  max-width: 800px;
}
</style>
