<template lang="pug">
app-panel.mb-12
  app-header
    | Project phases
    template(#subheader) {{ subheader }}

  app-loader.relative(:loading="loading")
    template(v-if="enabled")
      draggable(
        v-model="phases"
        handle=".handle"
        @start="dragStarted"
        @end="dragEnded"
        @change="reordered")

        transition-group.relative(:name="drag ? 'no' : 'phase-items'", type="transition")
          project-phase-item.phase-item(
            v-for="(phase, idx) in phases"
            :key="phase.id"
            :class="idx === phases.length - 1 ? '' : 'border-b border-1 border-grey-30'"
            :permissions="permissions"
            :value="phase"
            @delete="deletePhase(phase)",
            @input="changeName(phase, $event)")

      template(v-if="permissions.canCreate")
        app-button.mt-8(v-if="!editing", primary, @click="editing = true") Add phase

        template(v-else)
          .h-16.flex.flex-row.items-center.mt-8
            .flex-1
              app-text-input.w-full(
                v-model="newPhase.name"
                v-focus
                :error="$v.newPhase.name.$error"
                @keyup.enter="addPhase"
                @keyup.esc="stopEditing")

          .flex.flex-row.items-center(ref="newPhaseActions")
            app-button(primary, @click="addPhase", :loading="creatingPhase", loading-text="Saving..." slim) Add
            app-button.ml-4(@click="stopEditing" slim) Cancel


    template(v-else)
      p You don't currently have custom project phases enabled.
      p Below is a list of available phases.

      .bg-grey-10.p-4.border.border-grey-30.mt-8
        ul.list-disc.ml-4
          li(v-for="phase in phases") {{ phase.name }}

      template(v-if="permissions.canCreate")
        p.mt-8 Click the button below to customise the phases you can use

        app-button.mt-8(primary @click="enablePhases") Customise phases
</template>

<script>
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import { requiredIf } from "vuelidate/lib/validators";
import ProjectPhasesQuery from "@/graphql/queries/settings/ProjectPhases.gql";
import EnablePhasesMutation from "@/graphql/mutations/settings/EnableProjectPhases.gql";
import DeletePhase from "@/graphql/mutations/settings/DeleteProjectPhase.gql";
import RenamePhaseMutation from "@/graphql/mutations/settings/RenameProjectPhase.gql";
import CreatePhaseMutation from "@/graphql/mutations/settings/CreateProjectPhase.gql";
import MovePhaseItemMutation from "@/graphql/mutations/settings/MoveProjectPhase.gql";

import ProjectPhaseItem from "@/components/settings/project_phases/ProjectPhaseItem.vue";
import gql from "graphql-tag";

export default {
  components: {
    ProjectPhaseItem
  },
  apollo: {
    phases: {
      query: ProjectPhasesQuery,
      // variables: {
      //   excludeDefaults: true
      // },
      update({ projectPhases }) {
        this.canEdit = projectPhases.canCreate;

        return projectPhases.edges.map(({ node }) => node);
      }
    },
    permissions: {
      query: gql`
        query permissions {
          rolePermissions {
            canDestroy
            canCreate
            canEdit
          }
        }
      `,
      update({ rolePermissions }) {
        return rolePermissions;
      }
    }
  },
  data() {
    return {
      canEdit: true,
      drag: false,
      editing: false,
      enabling: false,
      phases: [],
      newPhase: {
        name: ""
      },
      permissions: {
        canDestroy: false,
        canCreate: false,
        canEdit: false
      },
      creatingPhase: false
    };
  },
  computed: {
    enabled() {
      const companyPhases = this.phases.filter(phase => !phase.default);
      return companyPhases.length > 0;
    },
    loading() {
      return this.$apollo.queries.phases.loading || this.enabling;
    },
    subheader() {
      if (this.loading) return "Loading data...";
      if (!this.enabled) return "Project phases are not currently enabled";

      return "";
    }
  },
  validations() {
    return {
      newPhase: {
        name: {
          required: requiredIf(() => this.editing)
        }
      }
    };
  },
  methods: {
    changeName(phase, newName) {
      const oldName = phase.name;
      phase.name = newName;

      this.$apollo
        .mutate({
          mutation: RenamePhaseMutation,
          variables: {
            input: {
              id: phase.id,
              name: newName
            }
          }
        })
        .catch(error => {
          phase.name = oldName;
          this.$flash.error(gqlErrorMessage(error));
        });
    },
    enablePhases() {
      this.enabling = true;

      this.$apollo
        .mutate({
          mutation: EnablePhasesMutation
        })
        .then(() => {
          this.$flash.success("Project phases have been enabled");
          this.$apollo.queries.phases.refetch();
        })
        .catch(error => {
          this.$flash.error(gqlErrorMessage(error));
        })
        .then(() => (this.enabling = false));
    },
    dragStarted() {
      this.drag = true;
    },

    dragEnded() {
      this.drag = false;
    },

    deletePhase(phase, confirmed = false) {
      // Mutation
      if (!confirmed) {
        if (phase.usedBy !== 0) {
          this.$flash.error(
            "This phase is currently being used by one or more projects"
          );
        } else {
          this.$dialog
            .confirm({
              title: "Confirm deletion",
              message: "Are you sure you want to delete this phase?",
              danger: true
            })
            .onOk(({ api }) => {
              api.hide();
              this.deletePhase(phase, true);
            });
        }
      } else {
        this.$apollo
          .mutate({
            mutation: DeletePhase,
            variables: {
              input: {
                id: phase.id
              }
            }
          })
          .then(() => {
            this.$flash.success(`${phase.name} was successfully deleted`);
            const idx = this.phases.findIndex(p => p.id == phase.id);
            this.phases.splice(idx, 1);
          })
          .catch(error => {
            this.$flash.error(gqlErrorMessage(error));
          });
      }
    },

    reordered({ moved }) {
      const { element, newIndex } = moved;
      this.$apollo
        .mutate({
          mutation: MovePhaseItemMutation,
          variables: {
            input: {
              id: element.id,
              position: newIndex + 1
            }
          }
        })
        .catch(e => {
          // Uh oh that failed. Undo the reordering
          this.$flash.error(gqlErrorMessage(e));
        });
    },

    addPhase() {
      this.$v.newPhase.$touch();
      if (this.$v.$invalid) return;

      this.creatingItem = true;
      this.$apollo
        .mutate({
          mutation: CreatePhaseMutation,
          variables: {
            input: {
              name: this.newPhase.name
            }
          }
        })
        .then(({ data: { createProjectPhase } }) => {
          this.phases.push(createProjectPhase.projectPhase);
          this.newPhase.name = "";
          this.$refs.newPhaseActions.scrollIntoView();
          this.creatingPhase = false;
          this.$v.$reset();
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
          this.creatingItem = false;
        });
    },

    stopEditing() {
      this.newPhase.name = "";
      this.editing = false;
    }
  }
};
</script>
