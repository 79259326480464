<template lang="pug">
  .remodal(data-remodal-id='task-editor-remodal', v-if='isOpen')
    h2.ui.header
      span {{ windowTitle }}
      a(:href='taskHref', v-if='!isNew')
        app-icon(icon="external")

    .compact.ui.secondary.pointing.menu(v-if='isNew')
      a.item(@click="setTaskType('task')", :class="tabClasses('task')") Task
      a.item(@click="setTaskType('mp_milestone')", :class="tabClasses('mp_milestone')") Milestone

    .ui.form
      gantt-modal-field-row(label='Name', :errors='errors.name')
        input#gantt_modal_name(
          type='text',
          v-model.lazy.trim='task.text',
          @change='titleChanged = true'
        )

      .field(v-if='isTask')
        .ui.stackable.three.column.grid
          .row
            .column
              gantt-modal-field-row(label='Starts At', :errors='errors.starts_at')
                date-picker(
                  :value='formatDate(task.start_date)',
                  @input='startDateChanged',
                  :disable='[isWeekend]')

            .column
              gantt-modal-field-row(label='Deadline', :errors='errors.end_date')
                date-picker(
                  :value='formatDate(endDateNormalised)',
                  @input='endDateChanged',
                  :disable='[isWeekend]')

            .column
              gantt-modal-field-row(label='Duration', :errors='errors.duration')
                number-input(:value='task.duration', :precision='0', @input='durationChanged')

      .field(v-if='isMilestone')
        gantt-modal-field-row(label='Deadline', :errors='errors.end_date')
          date-picker(
            :value='formatDate(task.start_date)',
            @input='milestoneDateChanged',
            :disable='[isWeekend]')

      .field(v-if="this.task.disable_base_line_dates || this.task.baseline_locked")
        .field(v-if='showDates')
          .ui.checkbox
            input#task-baseline-toggle(
              type='checkbox',
              @input='toggleBaselineDates',
              :checked='!task.baseline_locked')

            label(for='task-baseline-toggle') Set planned (baseline) dates to current dates

        .field(v-if='showPlannedDates && isTask')
          .ui.stackable.two.column.grid
            .row
              .column
                gantt-modal-field-row(label='Planned Start', :errors='errors.planned_start')
                  date-picker(
                    :value='formatDate(task.planned_start)',
                    @input='plannedStartChanged',
                    :disable='[isWeekend]')

              .column
                gantt-modal-field-row(label='Planned End', :errors='errors.planned_end')
                  date-picker(
                    :value='formatDate(plannedEndNormalised)',
                    @input='plannedEndChanged',
                    :disable='[isWeekend]')

        .field(v-else-if='(showPlannedDates && isMilestone)')
          gantt-modal-field-row(label='Planned Deadline')
            date-picker(
              :value='formatDate(plannedEndNormalised)',
              @input='plannedEndChanged',
              :disable='[isWeekend]')

        .field(v-else-if='isTask')
          .ui.stackable.two.column.grid
            .row
              .column
                gantt-modal-field-row(label='Planned Start')
                  input(:value='startDateFormatted', disabled)

              .column
                gantt-modal-field-row(label='Planned Deadline')
                  input(:value='endDateFormatted', disabled)

        .field(v-else-if='isTask')
          gantt-modal-field-row(label='Planned Deadline')
            input(:value='endDateFormatted', disabled)

      gantt-modal-field-row(label='Status', :errors='errors.status')
        task-status-multiselect(v-model='task.status', :type='task.type')

      gantt-modal-field-row#gantt_modal_assignee(
        label='Assignee(s)',
        :errors='errors.assignees_inp'
      )
        mp-multiselect(
          multiple,
          :value='currentAssignees',
          @input='changedAssignees',
          track-by='id',
          label='name',
          option-direction='',
          :options='assigneeOptions',
          group-label='role',
          group-values='users',
          group-select=true
        )

      gantt-modal-field-row(
        label='Task Type',
        :errors='errors.task_type_id',
        v-if='isTask')

        mp-multiselect(
          :options='taskTypeOptions',
          track-by='id',
          label='type',
          open-direction='',
          :value='currentTaskType',
          @input='taskTypeChanged')

    .actions
      button.basic.ui.button.remodal-cancel(@click='cancelled') Cancel
      button.blue.ui.button.remodal-confirm(@click='saved') Save
      button.basic.red.ui.button.remodal-delete(v-if='!isNew', @click='deleted') Delete
</template>

<script>
/* global $, $D */
/* eslint-disable */

import forEachObjIndexed from "ramda/src/forEachObjIndexed";
import groupBy from "ramda/src/groupBy";
import map from "ramda/src/map";
import prop from "ramda/src/prop";
import NumberInput from "@/components/inputs/NumberInput.vue";
import DatePicker from "@/components/inputs/inputs/DatePicker.vue";
import TaskStatusMultiselect from "@/components/inputs/dropdowns/TaskStatusMultiselect.vue";
import MpMultiselect from "@/components/inputs/dropdowns/MpMultiselect.vue";
import GanttModalFieldRow from "@/legacy/gantt_modal/GanttModalFieldRow.vue";
import clone from "ramda/src/clone";

const blankTask = {
  status: null,
  task_type_id: null,
  assignees_inp: [],
  end_date: null,
  start_date: null,
  planned_end: null,
  planned_start: null,
  type: null,
  baseline_locked: null,
  id: null,
  disable_base_line_dates: null,
  duration: null
};

export default {
  props: {
    workingWeek: {
      required: true,
      type: String
    },
    taskTypes: {
      required: true,
      type: String
    },
    users: {
      required: true,
      type: String
    }
  },
  components: {
    NumberInput,
    DatePicker,
    TaskStatusMultiselect,
    MpMultiselect,
    GanttModalFieldRow
  },
  data() {
    return {
      isOpen: false,
      task: clone(blankTask),
      errors: {},
      titleChanged: false
    };
  },
  computed: {
    isTask() {
      return this.task.type === "task" || this.task.type === "project";
    },
    isMilestone() {
      return this.task.type === "mp_milestone";
    },
    currentTaskType() {
      const currentType = this.taskTypesParsed[this.task.task_type_id];
      if (!currentType) {
        return null;
      }

      return { id: this.task.task_type_id, type: currentType };
    },
    currentAssignees() {
      if (!this.task.assignees_inp) {
        return [];
      }
      // The Assignees field currently does not retain values from db. Need to fix.
      let selected_values = [];

      map(userId => {
        this.usersParsed.map((object, index) => {
          if (object.id === userId) {
            selected_values.push({ id: object.id, name: object.name });
          }
        });
      }, this.task.assignees_inp);

      return selected_values;
    },
    endDateNormalised() {
      // need to convert the end date from the internal Gantt chart representation
      // to the human-readable one
      if (this.task.end_date) {
        return moment(this.task.end_date).subtract(1, "day");
      } else {
        return null;
      }
    },
    plannedEndNormalised() {
      // need to convert the planned end from the internal Gantt chart representation
      // to the human-readable on
      if (this.task.planned_end) {
        return moment(this.task.planned_end)
          .subtract(1, "day")
          .toDate();
      } else {
        return null;
      }
    },
    taskTypeOptions() {
      let options = [];

      forEachObjIndexed((type, id) => {
        options.push({ id: id, type: type });
      }, this.taskTypesParsed);

      return options;
    },
    assigneeOptions() {
      const groupByRole = groupBy(user => user.role);
      const groupedUsers = groupByRole(this.usersParsed);
      return Object.keys(groupedUsers).map(roleName => ({
        role: roleName,
        users: groupedUsers[roleName]
      }));
    },

    startDateFormatted() {
      if (!this.task || !this.task.start_date) {
        return null;
      }

      return moment(this.task.start_date).format(
        $D.fn.datepicker.momentDateFormat
      );
    },
    endDateFormatted() {
      var endDate = this.endDateNormalised;

      if (!endDate) {
        return null;
      }

      return moment(endDate).format($D.fn.datepicker.momentDateFormat);
    },
    isNew() {
      if (!this.task) {
        return null;
      }

      return !!this.task.$new;
    },
    showDates() {
      if (!this.task) {
        return null;
      }

      return this.isTask || this.isMilestone;
    },
    showPlannedDates() {
      if (!this.task) {
        return null;
      }

      return this.showDates && this.task.baseline_locked;
    },
    taskHref() {
      if (!this.task) {
        return null;
      }

      if (this.isTask) {
        return "/tasks/" + this.task.id;
      } else if (this.isMilestone) {
        return "/milestones/" + this.task.id;
      }
    },
    weekends() {
      var result = [],
        i;

      for (i in this.workingWeekParsed) {
        if (!this.workingWeekParsed[i]) {
          result.push(parseInt(i));
        }
      }

      return result;
    },
    windowTitle() {
      if (!this.isNew) {
        return this.task.text;
      }
      if (this.titleChanged) {
        return this.task.text;
      }

      if (this.isMilestone) {
        return "New Milestone";
      } else {
        return "New Task";
      }
    }
  },
  mounted() {
    this.workingWeekParsed = JSON.parse(this.workingWeek);
    this.taskTypesParsed = JSON.parse(this.taskTypes);
    this.usersParsed = JSON.parse(this.users);
  },
  methods: {
    setTaskType(type) {
      this.task.type = type;

      if (this.isNew && !this.titleChanged) {
        const titleType = type == "task" ? "Task" : "Milestone";

        this.task.text = `New ${titleType}`;
      }
    },
    tabClasses(type) {
      return {
        active: this.task.type === type
      };
    },
    formatDate(date) {
      return moment(date).format("YYYY/MM/DD");
    },
    isWeekend(date) {
      return this.weekends.indexOf(date.getDay()) !== -1;
    },
    open(task, savedCb, deletedCb, cancelledCb) {
      this.titleChanged = false;

      this.task = Object.assign({}, blankTask, task);
      this.task.$new = task.$new;

      this.savedCb = savedCb;
      this.deletedCb = deletedCb;
      this.cancelledCb = cancelledCb;

      let vm = this;
      this.isOpen = true;
      this.$nextTick(
        function() {
          $(this.$el)
            .remodal()
            .open();
          $(this.$el).on(
            "closed",
            function(e) {
              vm.isOpen = false;
              $(this.$el).off("closed");

              if (!e.reason || e.reason == "cancellation") {
                this.cancelledCb();
              }

              return true;
            }.bind(this)
          );
        }.bind(this)
      );
    },
    close(success) {
      var reason = success ? "confirmation" : "cancellation";

      $(this.$el)
        .remodal()
        .close(reason);
    },
    cancelled() {
      this.close(false);
    },
    saved() {
      if ((this.isMilestone || this.isTask) && !this.task.baseline_locked) {
        this.task.planned_start = this.task.start_date;
        this.task.planned_end = this.task.end_date;
      }

      this.savedCb(clone(this.task));
      this.close(true);
    },
    deleted() {
      $D.fn.confirm(
        "Please confirm",
        "Are you sure you want to delete this task?",
        "I'm sure",
        "Cancel",
        false,
        function() {
          this.deletedCb();
          this.close(true);
        }.bind(this)
      );
    },

    toggleBaselineDates(e) {
      this.task.baseline_locked = !e.target.checked;
      this.task.disable_base_line_dates = true;
    },

    plannedStartChanged(value) {
      const currentPlannedEnd = moment(this.task.planned_end);
      const newPlannedStart = moment(value, "YYYY/MM/DD");
      let plannedEnd;

      this.task.planned_start = newPlannedStart.toDate();
      if (newPlannedStart >= currentPlannedEnd) {
        plannedEnd = newPlannedStart.clone();
        this.task.planned_end = plannedEnd.add(1, "day").toDate();
      }
    },

    plannedEndChanged(value) {
      const currentPlannedStart = moment(this.task.planned_start);
      // we need to convert the planned end from human readable representation
      // to the Gantt chart one
      const newPlannedEnd = moment(value, "YYYY/MM/DD").add(1, "day");
      let plannedStart;

      this.task.planned_end = newPlannedEnd.toDate();
      if (newPlannedEnd <= currentPlannedStart) {
        plannedStart = newPlannedEnd.clone();
        this.task.planned_start = plannedStart.subtract(1, "day").toDate();
      }
    },

    startDateChanged(value) {
      const newStartDate = moment(value, "YYYY/MM/DD");
      let duration;
      let endDate;

      if (!value) {
        return;
      }

      duration = this.task.duration;
      endDate = $D.fn.dates
        .addDurationWithWorkingDays(newStartDate, duration - 1)
        .add(1, "day");

      this.task.start_date = newStartDate.toDate();
      this.task.end_date = endDate.toDate();
    },

    endDateChanged(value) {
      if (!value) {
        return;
      }

      // Need to convert the end date from the human-facing value
      // to the internal Gantt chart representation
      const newEndDate = moment(value, "YYYY/MM/DD").add(1, "day");
      const currentStartDate = moment(this.task.start_date);
      const newDuration = $D.fn.dates.distanceInWorkingDays(
        currentStartDate,
        newEndDate
      );

      this.task.end_date = newEndDate.toDate();

      if (newDuration > 0) {
        // keep the start date and set the duration
        this.task.duration = newDuration;
      } else {
        // the new end date is before the old start date
        // set the duration to 1 and move the start date
        this.task.duration = 1;
        this.task.start_date = newEndDate
          .clone()
          .subtract(1, "day")
          .toDate();
      }
    },

    milestoneDateChanged(value) {
      if (!value) {
        return;
      }

      const newDate = moment(value, "YYYY/MM/DD");

      this.task.duration = 1;
      this.task.start_date = newDate.toDate();
      this.task.end_date = newDate
        .clone()
        .add(1, "day")
        .toDate();
    },

    durationChanged(value) {
      if (!value || value <= 0) {
        return;
      }

      this.task.duration = value;
      this.task.end_date = $D.fn.dates
        .addDurationWithWorkingDays(moment(this.task.start_date), value - 1)
        .add(1, "day")
        .toDate();
    },

    taskTypeChanged(newTaskType) {
      this.task.task_type_id = newTaskType.id;
    },

    changedAssignees(newIds) {
      this.task.assignees_inp = map(prop("id"), newIds);
    }
  }
};
</script>
