<template lang="pug">
  .flex.flex-col
    app-header Basic details

    .my-4
      app-text-field(name="name" label="Task name" :messages="messages" v-model="task.name")

    .my-4
      app-text-field.pb-8(name="description" label="Description" type="richtext" v-model="task.description")

    .mt-8(v-if="!isNewSubtask")
      app-radio-field(name="isSubtaskValue" label="Is this a sub-task?" :options="yesNoOptions"
              v-model="isSubtaskValue" :messages="messages")

    .my-4(v-if="isNewSubtask || isSubtaskValue === 'yes'" class="w-2/3")
      app-dropdown-field(name="parentId" label="Parent task" description="Which task is this a sub-task of?" v-model="task.parentId"
                    :options="tasks || []" track-by="id" value-attr="id" label-attr="name")
        template(v-slot:option="task")
          .text-lg.text-grey-70.pb-2 {{ task.name }}

    .my-4(class="w-1/3")
      app-dropdown-field(name="taskTypeId" label="Type" description="What type of task is this?" v-model="task.taskTypeId"
                    :options="taskTypes || []" track-by="id" value-attr="id" label-attr="name")
        template(v-slot:option="taskType")
          .text-lg.text-grey-70.pb-2 {{ taskType.name }}

    .mt-8
      app-radio-field(name="isBillable" label="Is this a billable task?" :options="yesNoOptions"
              v-model="isBillable" :messages="messages")

</template>
<script>
import Tasks from "@/graphql/queries/core/tasks/Tasks.gql";
import TaskTypes from "@/graphql/queries/core/tasks/TaskTypes.gql";

import TaskWatchersMixin from "@/mixins/TaskWatchers.js";

export default {
  mixins: [TaskWatchersMixin],

  props: {
    value: {
      type: Object,
      default: () => {}
    },
    projectId: {
      type: String,
      default: null
    },
    isSubtask: {
      type: Boolean,
      default: false
    }
  },

  apollo: {
    tasks: {
      query: Tasks,
      skip() {
        return !this.task.projectId;
      },
      variables() {
        return {
          projectId: this.task.projectId,
          excludeMilestones: true
        };
      },
      update({ tasks }) {
        const taskId = this.task.id;

        if (tasks) {
          tasks = tasks.edges.map(({ node }) => node);

          if (taskId) {
            tasks = tasks.filter(({ id, parent }) => {
              const notSameTask = id !== taskId;
              const notASubTask = !parent || parent.id !== taskId;
              const canBeParent = notSameTask && notASubTask;

              return canBeParent;
            });
          }

          return tasks;
        } else {
          return [];
        }
      }
    },

    taskTypes: {
      query: TaskTypes,
      update({ taskTypes }) {
        return taskTypes.edges.map(({ node }) => node);
      }
    }
  },

  data() {
    return {
      nameTaken: false,
      task: { ...this.value },
      messages: {
        required: "You must give this task a name"
      },
      isSubtaskValue: "no",
      yesNoOptions: [
        { name: "No", value: "no" },
        { name: "Yes", value: "yes" }
      ]
    };
  },

  computed: {
    isNewSubtask() {
      return this.$route.name == "newSubtaskWizard";
    },

    isBillable: {
      get() {
        return this.task.billable ? "yes" : "no";
      },

      set(value) {
        this.task.billable = value == "yes";
        this.$emit("input", this.task);
      }
    }
  },

  mounted() {
    this.$watch(
      "isSubtask",
      value => {
        this.isSubtaskValue = value ? "yes" : "no";
      },
      { immediate: true }
    );
  }
};
</script>
