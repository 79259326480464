<template lang="pug">
  .downgrade-page.px-4
    .flex.justify-center(v-if="isDowngraded")
      app-panel.text-center.mb-4.mt-8
        .text-left
          h2.font-bold Downgrade completed
          h3.text-grey-60 You've got some time to change your mind
        .text-left.my-12
          span You still have {{subscriptionRemainingDays}} days remaining in this billing cycle, so you will remain on the <strong>Pro</strong> plan&nbsp;
           | until {{subscriptionEndDate}}. After this date, you will revert to the <strong>Free</strong> plan automatically.
           | If you change your mind and wish to upgrade, you can do this at any time.
        app-button(primary
            class="w-64 text-center focus:outline-none focus:shadow-outline"
            @click.prevent="goToSubscription()"
          ) Continue
    template(v-else)
      .page.loader.page-loader(v-if="loading")
        .circle
          .ring
      .text-grey-60(:class="loading ? 'opacity-25' : ''")
        app-panel
          .mb-8
            h2 Are you sure you want to downgrade?
            .text-grey-60 You'll lose out on some great features

          .mb-4(class="sm:mb-8")
            | if you downgrade to the Free plan, you will lose out on access
            | to some great features, and he limited on what you can do in the system

          .py-4(class="mb-4 sm:mb-8")
            div(class="sm:flex sm:justify-center")
              div(class="w-full sm:w-1/2 mb-4 sm:mb-0")
                .hidden(class="sm:block")
                  .font-bold.mb-4.pr-8.text-right You will lose
                  .relative.px-8.py-2.text-right
                    .flex.mb-2.justify-end
                      .mr-2 Unlimited projects
                      app-icon.rounded-full.error(icon="error"
                        size="small" colour="red")
                    .flex.mb-2.justify-end
                      .mr-2 Unlimited users
                      app-icon.rounded-full.error(icon="error"
                        size="small" colour="red")
                    .flex.mb-2.justify-end
                      .mr-2 50GB document storage
                      app-icon.rounded-full.error(icon="error"
                        size="small" colour="red")
                    .flex.justify-end
                      .mr-2 Optional add-ons
                      app-icon.rounded-full.error(icon="error"
                        size="small" colour="red")
              .block.mb-4(class="sm:hidden")
                  .font-bold.mb-4.text-left You will lose
                  .relative.py-2
                    .flex.mb-2
                      app-icon.rounded-full.error(icon="error"
                        size="small" colour="red")
                      .ml-2 Unlimited projects
                    .flex.mb-2
                      app-icon.rounded-full.error(icon="error"
                        size="small" colour="red")
                      .ml-2 Unlimited users
                    .flex.mb-2
                      app-icon.rounded-full.error(icon="error"
                        size="small" colour="red")
                      .ml-2 50GB document storage
                    .flex
                      app-icon.rounded-full.error(icon="error"
                        size="small" colour="red")
                      .ml-2 Optional add-ons

              div(class="w-full sm:w-1/2")
                div
                  .font-bold.mb-4(class="pl-0 sm:pl-8") You will only have
                  .relative.py-2.text-left.grey-border(
                    class="border-0 px-0 sm:px-8 sm:border sm:border-t-0 sm:border-b-0 sm:border-r-0")
                    .flex.mb-2
                      app-icon.rounded-full.bg-grey-40(icon="success"
                        size="small" )
                      .ml-2 1 active project
                    .flex.mb-2
                      app-icon.rounded-full.bg-grey-40(icon="success"
                        size="small")
                      .ml-2 1 User
                    .flex.mb-2
                      app-icon.rounded-full.bg-grey-40(icon="success"
                        size="small")
                      .ml-2 5GB document storage
                    .flex
                      app-icon.rounded-full.error(icon="error"
                        size="small" colour="red")
                      .ml-2 No optional add-ons

          .mb-8
            | If you currently have any add-ons enabled,
            | you will lose access to any data related to this.

          .text-center
            app-button.mb-3(primary @click.prevent="goToSubscription")
              | No, I don't want to lose anything important
            div
              a(href="#" class="text-withered-cherry"
                @click.prevent="downgrade"
              ) Yes, downgrade and lose vital features
</template>

<script>
import moment from "moment";
import Subscription from "@/graphql/queries/core/company/Subscription.gql";
import DowngradePlanMutation from "@/graphql/mutations/subscriptions/DowngradePlan.gql";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

export default {
  components: {},
  data() {
    return {
      loading: false,
      isDowngraded: false
    };
  },
  apollo: {
    subscription: {
      query: Subscription
    }
  },
  computed: {
    subscriptionRemainingDays() {
      if (!this.subscription) return 0;
      const { endDate } = this.subscription;
      const start = moment(new Date());
      const end = moment(endDate);
      const duration = moment.duration(end.diff(start));
      return Math.ceil(duration.asDays());
    },
    subscriptionEndDate() {
      if (!this.subscription) return "";
      const { endDate } = this.subscription;
      return moment(endDate).format("MMM DD, YYYY");
    }
  },
  methods: {
    goToSubscription() {
      window.location.href = "/settings/subscriptions";
    },
    downgrade() {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: DowngradePlanMutation
        })
        .then(({ data: { downgradePlan } }) => {
          if (downgradePlan.status) {
            this.$flash.success("Successfully downgraded");
            this.isDowngraded = true;
          }
          this.loading = false;
        })
        .catch(error => {
          this.$flash.error(gqlErrorMessage(error));
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="postcss" scoped>
.downgrade-page {
  position: relative;
  width: initial !important;
  max-width: 650px;
}
.page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
}
.grey-border {
  border-color: var(--grey-40);
}
</style>
