export const ACTIONS = {
  EDIT: "edit",
  DELETE: "delete",
  VOID: "void"
};

export const orderMenuItems = row => {
  let actions = [];

  actions.push({ label: "Edit order", icon: "edit", action: ACTIONS.EDIT });

  actions.push({ type: "divider" });

  if (row.data.status !== "authorised") {
    actions.push({
      label: "Delete",
      icon: "delete",
      iconColour: "red",
      action: ACTIONS.DELETE
    });
  } else {
    actions.push({
      label: "Void",
      icon: "delete",
      iconColour: "red",
      action: ACTIONS.VOID
    });
  }

  return actions;
};
