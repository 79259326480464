export const ACTIONS = {
  UPLOAD: "upload",
  DOWNLOAD: "download",
  PREVIEW: "preview",
  COPY: "copy",
  MOVE: "move",
  LINK: "link",
  ATTACH_TASKS: "attachTasks",
  SHARE_URL: "shareUrl",
  RENAME: "rename",
  DELETE: "delete"
};

export const documentMenuItems = (canAttach, permissions = {}) => {
  let actions = [
    { label: "Download", icon: "download", action: ACTIONS.DOWNLOAD },
    { type: "divider" },
    { label: "Preview", icon: "preview", action: ACTIONS.PREVIEW },
    { type: "divider" }
  ];

  if (permissions.canCreate) {
    actions.push(
      { label: "Upload new version", icon: "upload", action: ACTIONS.UPLOAD },
      { label: "Copy", icon: "copy", action: ACTIONS.COPY },
      { label: "Move", icon: "moveFolder", action: ACTIONS.MOVE }
    );
  }

  if (canAttach) {
    actions.push(
      { label: "Attach tasks", icon: "link", action: ACTIONS.ATTACH_TASKS },
      { type: "divider" }
    );
  }

  if (permissions.canShare) {
    actions.push(
      { label: "Share public URL", icon: "share", action: ACTIONS.SHARE_URL },
      { type: "divider" }
    );
  }

  if (permissions.canUpdate) {
    actions.push({ label: "Rename", icon: "edit", action: ACTIONS.RENAME });
  }

  if (permissions.canDestroy) {
    actions.push({
      label: "Delete",
      icon: "delete",
      iconColour: "red",
      action: ACTIONS.DELETE
    });
  }

  if (actions[actions.length - 1].type === "divider") {
    actions.pop();
  }

  return actions;
};

export const readOnlyDocumentMenuItems = [
  { label: "Download", icon: "download", action: ACTIONS.DOWNLOAD },
  { type: "divider" },
  { label: "Preview", icon: "preview", action: ACTIONS.PREVIEW }
];

export const folderMenuItems = [
  // { label: "Upload document", icon: "upload", action: ACTIONS.UPLOAD },
  { label: "Download", icon: "download", action: ACTIONS.DOWNLOAD },
  { type: "divider" },
  { label: "Copy", icon: "copy", action: ACTIONS.COPY },
  { label: "Move", icon: "moveFolder", action: ACTIONS.MOVE },
  // { type: "divider" },
  { label: "Attach tasks", icon: "link", action: ACTIONS.ATTACH_TASKS },
  { type: "divider" },
  { label: "Share public URL", icon: "share", action: ACTIONS.SHARE_URL },
  { type: "divider" },
  { label: "Rename", icon: "edit", action: ACTIONS.RENAME },
  {
    label: "Delete",
    icon: "delete",
    iconColour: "red",
    action: ACTIONS.DELETE
  }
];

export const bulkActions = [
  { label: "Download", icon: "download", action: ACTIONS.DOWNLOAD },
  { type: "divider" },
  { label: "Copy", icon: "copy", action: ACTIONS.COPY },
  { label: "Move", icon: "moveFolder", action: ACTIONS.MOVE },
  // { type: "divider" },
  // { label: "Link to resources", icon: "link" },
  // { type: "divider" },
  {
    label: "Share public URL",
    icon: "share",
    action: ACTIONS.SHARE_URL
  },
  {
    label: "Delete",
    icon: "delete",
    iconColour: "red",
    action: ACTIONS.DELETE
  }
];
