<template lang="pug">
  app-loader(:loading="loading")
    .flex.flex-col
      app-panel.mb-4
        app-header
          | {{ isEditing }}
          template(v-slot:subheader)
            | Roles define what a team member can and can't do
        form-wrapper(:validator="$v.role")
          app-message.mb-8(type="info" v-if="isClone && oldRole")
            template(#title) You are duplicating the "{{ oldRole.name }}" role

            | The permissions below have been copied from the #[strong {{ oldRole.name }}] role.
          app-text-field(name="name",
                        type="text",
                        v-model="role.name",
                        label="Name")
          app-text-field(name="description",
                        type="textarea",
                        v-model="role.description",
                        label="Description")

          app-dropdown-field(
            v-if="isNewRole || isClone"
            label="Team members"
            description="Choose existing team members to assign this role"
            :options="users"
            :multiple="true"
            v-model="role.users"
            select-label=""
            deselect-label=""
            label-attr="name"
            track-by="id")
              template(#option="option")
                .flex.flex-row.justify-start.text-grey-80
                  app-avatar.flex-none.self-center.mr-4(:avatar="option.avatar" size="small")
                  span {{ option.name }}

              template(#tag="{ option, remove }")
                app-label.mb-2.mr-2.inline-flex.items-center(removable @remove-click.prevent="remove(option)")
                  app-avatar.flex-none.self-center.mr-2.p-1(:avatar="option.avatar" size="small")
                  span.pr-2.py-1 {{ option.name }}

      app-split-view.permissions(:menu-options="menuOptions")
        template(#user-management)
          .panel
            app-header
              | User management
              template(#subheader)
                | Permissions related to managing users and permissions

            .flex.flex-row.gap-4
              users-permissions(v-model="permissions.users")
              roles-permissions(v-model="permissions.roles")

        template(#project-management)
          .panel
            app-header
              | Project management
              template(#subheader)
                | Permissions related to projects and tasks

            .flex.flex-row.gap-4
              project-permissions.flex-1(v-model="permissions.projects" :features="features")
              task-permissions.flex-1(v-model="permissions.tasks")

        template(#document-management)
          .panel
            app-header
              | Document management
              template(#subheader)
                | Permissions related to managing documents

            .flex.flex-row.gap-4
              company-document-permissions.flex-1(v-model="permissions.companyDocuments")
              project-document-permissions.flex-1(v-model="permissions.projectDocuments")

        template(#finances v-if="features.financeManagement")
          .panel
            app-header
              | Finances
              template(#subheader)
                | Permissions related to finance management

            .flex.flex-row.gap-4
              .flex-1.flex.flex-col.gap-4
                bills-permissions.flex-1(:value="permissions.bills" @input="financePermissionsChanged('bills', $event)")
                change-orders-permissions.flex-1(:value="permissions.changeOrders" @input="financePermissionsChanged('changeOrders', $event)")
                estimates-permissions.flex-1(:value="permissions.estimates" @input="financePermissionsChanged('estimates', $event)")
                items-permissions.flex-1(:value="permissions.items" @input="financePermissionsChanged('items', $event)")
                tax-rates-permissions.flex-1(:value="permissions.taxRates" @input="financePermissionsChanged('taxRates', $event)")

              .flex-1.flex.flex-col.gap-4
                invoices-permissions.flex-1(:value="permissions.invoices" @input="financePermissionsChanged('invoices', $event)")
                purchase-orders-permissions.flex-1(:value="permissions.purchaseOrders" @input="financePermissionsChanged('purchaseOrders', $event)")
                accounts-permissions.flex-1(:value="permissions.accounts" @input="financePermissionsChanged('accounts', $event)")
                contacts-permissions.flex-1(:value="permissions.contacts" @input="financePermissionsChanged('contacts', $event)")

        template(#timesheets)
          .panel
            app-header
              | Timesheets
              template(#subheader)
                | Permissions related to managing timesheets

            .flex.flex-row.gap-4
              timesheet-entries-permissions.flex-1(v-model="permissions.timesheetEntries")
              timesheets-permissions.flex-1(v-model="permissions.timesheets")

        template(#customisation)
          .panel
            app-header
              | Customisation
              template(#subheader)
                | Permissions for customising your account

            .flex.flex-row.gap-4
              .flex-1.flex.flex-col.gap-4
                custom-field-permissions(v-model="permissions.customFields")
                kpi-permissions(v-model="permissions.kpi")

              .flex-1.flex.flex-col.gap-4
                task-types-permissions(v-model="permissions.taskTypes")
                project-phases-permissions(v-model="permissions.projectPhases")

        template(#other)
          .panel
            app-header
              | Other
              template(#subheader)
                | Uncategorised permissions

            .flex.flex-row.gap-4
              .flex-1.flex.flex-col.gap-4
                company-details-permissions(v-model="permissions.company")
                miscellaneous-permissions(v-model="permissions.miscellaneous")

              .flex-1.flex.flex-col.gap-4
                software-integrations-permissions(v-model="permissions.softwareIntegrations")
                reporting-permissions(v-if="features.reporting" v-model="permissions.reporting")

      .flex.flex-row.mt-8
        app-button.mr-4(:loading="saving" primary @click.prevent="save") {{ saveBtnText }}
      a.block.mt-4(href="#" @click.prevent="cancel()") Cancel
</template>

<script>
import { required } from "vuelidate/lib/validators";
import RoleQuery from "@/graphql/queries/settings/roles/Role.gql";
import UsersQuery from "@/graphql/queries/core/company/Users.gql";
import RolesManager from "./roles_table/RolesManager.js";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

import AccountsPermissions from "./form/RolesFormAccountsPermissions.vue";
import BillsPermissions from "./form/RolesFormBillsPermissions.vue";
import ChangeOrdersPermissions from "./form/RolesFormChangeOrdersPermissions.vue";
import CompanyDetailsPermissions from "./form/RolesFormCompanyDetailsPermissions.vue";
import CompanyDocumentPermissions from "./form/RolesFormCompanyDocumentsPermissions.vue";
import ContactsPermissions from "./form/RolesFormContactsPermissions.vue";
import CustomFieldPermissions from "./form/RolesFormCustomFieldPermissions.vue";
import EstimatesPermissions from "./form/RolesFormEstimatesPermissions.vue";
import InvoicesPermissions from "./form/RolesFormInvoicesPermissions.vue";
import ItemsPermissions from "./form/RolesFormItemsPermissions.vue";
import KpiPermissions from "./form/RolesFormKpiPermissions.vue";
import MiscellaneousPermissions from "./form/RolesFormMiscellaneousPermissions.vue";
import ProjectDocumentPermissions from "./form/RolesFormProjectDocumentsPermissions.vue";
import ProjectPhasesPermissions from "./form/RolesFormProjectPhasesPermissions.vue";
import ProjectPermissions from "./form/RolesFormProjectPermissions.vue";
import PurchaseOrdersPermissions from "./form/RolesFormPurchaseOrdersPermissions.vue";
import ReportingPermissions from "./form/RolesFormReportingPermissions.vue";
import RolesPermissions from "./form/RolesFormRolesPermissions.vue";
import SoftwareIntegrationsPermissions from "./form/RolesFormSoftwareIntegrationsPermissions.vue";
import TaskPermissions from "./form/RolesFormTaskPermissions.vue";
import TaxRatesPermissions from "./form/RolesFormTaxRatesPermissions.vue";
import TaskTypesPermissions from "./form/RolesFormTaskTypesPermissions.vue";
import TimesheetEntriesPermissions from "./form/RolesFormTimesheetEntriesPermissions.vue";
import TimesheetsPermissions from "./form/RolesFormTimesheetsPermissions.vue";
import UsersPermissions from "./form/RolesFormUsersPermissions.vue";

import { categories } from "./form/RolesFormPermissionCategories";

import gql from "graphql-tag";

const REQUIRED_ERROR = "Please fill required fields and try again";

// let vm;
export default {
  components: {
    AccountsPermissions,
    BillsPermissions,
    ChangeOrdersPermissions,
    CompanyDetailsPermissions,
    CompanyDocumentPermissions,
    ContactsPermissions,
    CustomFieldPermissions,
    EstimatesPermissions,
    InvoicesPermissions,
    ItemsPermissions,
    KpiPermissions,
    MiscellaneousPermissions,
    ProjectDocumentPermissions,
    ProjectPhasesPermissions,
    ProjectPermissions,
    PurchaseOrdersPermissions,
    ReportingPermissions,
    RolesPermissions,
    SoftwareIntegrationsPermissions,
    TaskPermissions,
    TaxRatesPermissions,
    TaskTypesPermissions,
    TimesheetEntriesPermissions,
    TimesheetsPermissions,
    UsersPermissions
  },
  apollo: {
    features: {
      query: gql`
        query company {
          company {
            features {
              reporting
              financeManagement
              developmentManagement
            }
          }
        }
      `,
      update(data) {
        return data.company.features;
      }
    },
    users: {
      query: UsersQuery,

      variables() {
        return {
          owners: false
        };
      },

      update(data) {
        return data.companyUsers.edges.map(({ node }) => node);
      },

      fetchPolicy: "no-cache"
    },

    role: {
      query: RoleQuery,
      skip() {
        return this.isNewRole;
      },
      variables() {
        return {
          id: this.role.id
        };
      },
      result() {
        this.categorisePermissions();

        if (this.isClone) {
          this.oldRole = {
            name: this.role.name
          };
        }

        this.loading = false;
      },
      fetchPolicy: "no-cache"
    }
  },
  data() {
    return {
      menuOptions: [
        {
          label: "Project management",
          icon: "project",
          slot: "project-management"
        },
        {
          label: "Document management",
          icon: "document",
          slot: "document-management"
        },
        { label: "Finances", icon: "finance", slot: "finances" },
        { label: "Timesheets", icon: "timesheetEntry", slot: "timesheets" },
        { label: "Customisation", icon: "settings", slot: "customisation" },
        { label: "User management", icon: "users", slot: "user-management" },
        { label: "Other", icon: "normalPriority", slot: "other" }
      ],
      rolesManager: new RolesManager(),
      role: {
        id: null,
        name: "",
        description: "",
        users: [],
        globalPermissions: [],
        projectPermissions: [],
        taskPermissions: []
      },
      oldRole: null,
      users: [],
      financePermissions: {},
      permissions: {
        accounts: {},
        bills: {},
        changeOrders: {},
        company: {},
        companyDocuments: {},
        contacts: {},
        customFields: {},
        estimates: {},
        invoices: {},
        items: {},
        kpi: {},
        miscellaneous: {},
        projectDocuments: {},
        projectPhases: {},
        projects: {},
        purchaseOrders: {},
        reporting: {},
        roles: {},
        softwareIntegrations: {},
        tasks: {},
        taxRates: {},
        taskTypes: {},
        timesheetEntries: {},
        timesheets: {},
        users: {}
      },
      features: {
        reporting: false,
        financeManagement: false
      },
      saving: false,
      loading: true
    };
  },
  computed: {
    isNewRole() {
      return !this.role.id;
    },
    isClone() {
      return this.$route.name == "clone_role";
    },
    saveBtnText() {
      if (this.isClone) return "Clone role";

      if (this.isNewRole) return "Create role";

      return "Update role";
    },
    createProjectPermission() {
      return this.permissions.permissionsProject.filter(
        x => x.name == "create_project"
      )[0];
    },
    isEditing() {
      if (this.$route.name === "edit_role") {
        return "Edit role";
      } else {
        return "Add new role";
      }
    }
  },
  mounted() {
    this.initPermissions();
  },
  validations() {
    return {
      role: {
        name: { required }
      }
    };
  },
  created() {
    if (this.$route.params && this.$route.params.id) {
      this.role.id = this.$route.params.id;
      this.loading = true;
    } else {
      this.loading = false;
    }
  },
  methods: {
    financePermissionsChanged(type, permissions) {
      this.permissions[type] = permissions;
      if (permissions.permissions) {
        const anyEnabled = Object.values(permissions).some(v => v);
        this.financePermissions[type] = {
          enabled: anyEnabled,
          scope: permissions.scope
        };
      } else if (this.financePermissions[type]) {
        this.financePermissions[type].enabled = false;
        this.financePermissions[type].scope = "global";
      }
    },
    categorisePermissions() {
      const reducer = (res, permission) => {
        res[permission.name] = true;
        return res;
      };

      const globalPermissions = this.role.globalPermissions.reduce(reducer, {});
      const projectPermissions = this.role.projectPermissions.reduce(
        reducer,
        {}
      );
      const taskPermissions = this.role.taskPermissions.reduce(reducer, {});

      const permissionSets = {
        global: globalPermissions,
        project: projectPermissions,
        task: taskPermissions
      };

      // Loop over each category and find if each permission is enabled in
      // the global, project, or task permissions. Set `scope `accordingly
      Object.keys(categories).forEach(category => {
        let keys = ["global", "project", "task"];
        let scope = null;
        let permissionSet;

        categories[category].forEach(permission => {
          if (!permissionSet) {
            // We don't currently have a selected set of permissions.
            // All categorised permissions belong to a single set, so
            // we need to find one, and then just keep using that one
            for (let i = 0; i < keys.length; i++) {
              const temporarySet = permissionSets[keys[i]];
              if (temporarySet[permission]) {
                // Found a match, this our set
                permissionSet = temporarySet;
                scope = keys[i];
              }
            }
          }

          if (!this.permissions[category].permissions) {
            this.permissions[category] = {
              permissions: {},
              scope
            };
          }

          this.permissions[category].permissions[permission] = permissionSet
            ? permissionSet[permission] || false
            : false;
        });

        this.permissions[category].scope = scope;
      });

      // Record all finance related permissions. They're a little different
      const recordFinancePermission = type => {
        const capitalisedType = type.charAt(0).toUpperCase() + type.slice(1);
        const permissionName = `view${capitalisedType}`;

        if (permissionSets["global"][permissionName]) {
          this.financePermissions[type] = {
            scope: "project",
            enabled: true
          };
        } else if (permissionSets["project"][permissionName]) {
          this.financePermissions[type] = {
            scope: "global",
            enabled: true
          };
        }
      };

      recordFinancePermission("invoice");
      recordFinancePermission("bill");
      recordFinancePermission("changeOrder");
      recordFinancePermission("purchaseOrder");
      recordFinancePermission("estimate");
    },
    initPermissions() {
      Object.keys(categories).forEach(category => {
        this.permissions[category] = {
          permissions: {},
          scope: "global"
        };

        categories[category].forEach(permission => {
          this.permissions[category].permissions[permission] = false;
        });
      });
    },
    cancel() {
      window.location.href = "/settings#/users_roles/roles";
    },
    save() {
      this.$v.role.$touch();

      if (this.$v.role.$invalid) {
        this.$flash.error(REQUIRED_ERROR);
        return;
      }

      let role = {
        name: this.role.name,
        description: this.role.description,
        globalPermissions: [],
        projectPermissions: [],
        taskPermissions: []
      };

      if (this.isNewRole) {
        role.userIds = this.role.users.map(u => u.id);
      }

      if (!this.isNewRole && !this.isClone) {
        role.id = this.role.id;
      }

      const method = this.isNewRole || this.isClone ? "create" : "update";
      this.saving = true;

      Object.values(this.permissions).forEach(permissions => {
        if (permissions.permissions) {
          let target = permissions.scope || "global";
          const enabled = Object.keys(permissions.permissions).filter(
            key => permissions.permissions[key]
          );

          role[`${target}Permissions`].push(...enabled);
        }
      });

      // Now check our finance permissions. Ensure we correctly send
      // the `viewFinance` permission if applicable
      const enabled = Object.values(this.financePermissions).some(
        permission => permission.enabled
      );
      const global = Object.values(this.financePermissions).some(
        permission => permission.scope === "global"
      );

      if (enabled) {
        const key = global ? "globalPermissions" : "projectPermissions";
        role[key].push("viewFinances");
      }

      this.rolesManager[method](role)
        .then(() => {
          let message;

          this.saving = false;

          if (method === "create") {
            message = "Role created successfully";
            if (this.isClone) {
              message = "Role cloned successfully";
            }
          } else {
            message = "Role updated successfully";
          }

          this.$flash.success(message);

          location.href = "/settings#/users_roles/roles";
        })
        .catch(error => {
          this.saving = false;
          this.$flash.error(gqlErrorMessage(error));
        });
    }
  }
};
</script>

<style lang="postcss" scoped>
.permissions {
  height: 500px;

  .panel {
    @apply p-8;
    @apply overflow-auto;
    @apply h-full;
  }
}
</style>
