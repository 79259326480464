<template lang="pug">
  app-sidebar(@close="$emit('input', false)" :actions="actions" :tabs="tabs" @action-clicked="sidebarActionClicked")
    template(#header)
      .name.flex-1.flex.flex-row.overflow-hidden
        app-file-icon.flex-none.mr-4(v-if="!editing" :extension="document.currentVersion.upload.fileExtension")
        app-header.truncate.mr-4(v-if="!editing" :title="document.name")
          | {{ document.name }}
          template(#subheader) Version {{ document.currentVersion.version }}

        app-text-input.mr-4.flex-1(
          v-else
          v-model="newDocument.name"
          v-focus
          :error="$v.newDocument.name.$error"
          @keyup.enter="renameDocument"
          @keyup.esc="editing = false")

    template(#summary)
      .p-8
        .flex.bg-grey-20(v-if="showPreview")
          document-preview(:document="document")
          //- img.m-auto.p-3(:src="document.preview" alt="Preview" width="200" height="300")

        .flex.flex-row(:class="{ 'mt-8': listingView }")
          app-button.flex-1.text-center(primary, @click="downloadDocument") Download
          app-button.flex-initial.ml-4(@click="preview") Preview
          app-dropdown.flex-initial.ml-4(:options="actionMenuItems", @click="contextMenuItemClicked")
            template(#trigger)
              app-button
                app-icon(icon="actions")


        .flex.justify-between.flex-col.h-64.mt-6
          .flex.justify-between
            p.font-black.text-grey-60 Properties
          .flex.justify-between
            p.font-black Type
            p {{ document.currentVersion.upload.fileExtension }}
          .flex.justify-between
            p.font-black Size
            p {{ formattedFileSize() }}
          .flex.justify-between
            p.font-black Uploaded by
            p {{ uploaderName }}
          .flex.justify-between
            p.font-black Created
            p {{ validateDate(document.uploadCreatedAt) }}
          .flex.justify-between
            p.font-black Modified
            p {{ validateDate(document.uploadUpdatedAt) }}

    template(#comments)
      comments-panel(:comments="comments" :resource-id="document.id" resource-type="Document" v-on="commentListeners")

    template(#versions)
      .p-8
        versions-tab(:document="document" v-on="$listeners" @delete="deleteVersion" :permissions="permissions")
</template>

<script>
import DocumentURL from "@/graphql/queries/document_management/DocumentURL.gql";
import DocumentPreview from "@/components/documents/DocumentPreview.vue";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import DateValidateMixin from "@/mixins/DateValidator.js";
import CommentsPanel from "@/components/resource/CommentsPanel.vue";
import VersionsTab from "./VersionsTab.vue";
import ShareDialog from "./ShareDialog.vue";
import DocumentManager from "./DocumentManager";
const clipboardy = require("clipboardy");
import {
  documentMenuItems,
  readOnlyDocumentMenuItems,
  ACTIONS
} from "./ContextMenuItems";
import PreviewDialog from "./PreviewDialog";
import { requiredIf } from "vuelidate/lib/validators";
import gql from "graphql-tag";

export default {
  name: "Sidebar",
  components: {
    CommentsPanel,
    DocumentPreview,
    ShareDialog,
    VersionsTab
  },
  mixins: [DateValidateMixin],
  props: {
    document: {
      type: Object,
      required: true
    },
    listingView: {
      type: Boolean,
      required: false,
      default: false
    },
    project: {
      type: Object,
      required: false,
      default: null
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      docManager: new DocumentManager(this.project),
      activetab: 1,
      shareUrl: "",
      editing: false,
      newDocument: {
        name
      },
      comments: [],
      actions: [{ label: "Rename", icon: "edit", action: "RENAME" }],
      tabs: [
        { name: "Summary", slot: "summary" },
        { name: "Comments", slot: "comments" },
        { name: "Versions", slot: "versions" }
      ],
      permissions: {
        canShare: false,
        canUpdate: false,
        canDelete: false,
        canCreate: false
      }
    };
  },
  apollo: {
    document: {
      query: DocumentURL,
      variables() {
        return {
          id: this.document.id,
          external: true
        };
      },
      update(data) {
        this.comments = data.document.comments;
        return data.document;
      }
    },
    permissions: {
      query() {
        const query = `
          query permissions($id: GlobalID) {
            ${
              this.project
                ? "documentPermissions"
                : "companyDocumentPermissions"
            } {
              canShare(id: $id)
              canUpdate(id: $id)
              canDestroy(id: $id),
              canCreate
            }
          }
        `;

        return gql(query);
      },
      variables() {
        return {
          id: this.document.id
        };
      },
      update(data) {
        if (this.project) {
          return data.documentPermissions;
        }

        return data.companyDocumentPermissions;
      }
    }
  },
  validations() {
    return {
      newDocument: {
        name: {
          required: requiredIf(() => this.editing)
        }
      }
    };
  },
  computed: {
    actionMenuItems() {
      if (this.readOnly) {
        return readOnlyDocumentMenuItems;
      } else {
        return documentMenuItems(!!this.project, this.permissions);
      }
    },
    showPreview() {
      return (
        this.listingView &&
        this.document.currentVersion.upload.fileType === "IMAGE" &&
        this.document.currentVersion.upload.fileExtension !== "svg"
      );
    },
    uploaderName() {
      const uploader = this.document.currentVersion.uploader;
      if (uploader) {
        return uploader.name;
      }

      return "[Deleted user]";
    },
    commentListeners() {
      const vm = this;
      return Object.assign(
        {},
        {
          comment(comment) {
            vm.comments.push(comment);
          }
        },
        this.$listeners
      );
    }
  },
  mounted() {
    this.$store.dispatch(
      "documentManagement/registerUploadCallback",
      this.onVersionUpdated
    );
  },
  beforeDestroy() {
    this.$store.dispatch(
      "documentManagement/removeUploadCallback",
      this.onDocumentUploaded
    );
  },
  methods: {
    delete(doc, opts) {
      this.$dialog
        .confirm({
          title: opts.title,
          message: opts.message,
          danger: true
        })
        .onOk(({ api }) => {
          api.hide();
          this.docManager
            .delete(doc)
            .then(() => {
              this.$emit("deleted", doc);
              this.$flash.success(opts.success);
            })
            .catch(e => {
              this.$flash.error(gqlErrorMessage(e));
            });
        });
    },
    renameDocument() {
      var originalName = this.document.name;
      this.$v.newDocument.$touch();
      if (this.$v.$invalid) return;

      this.docManager
        .rename(this.document, this.newDocument.name)
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
          this.document.name = originalName;
        })
        .then(() => {
          this.document.name = this.newDocument.name;

          const {
            currentVersion: { url }
          } = this.document;
          const indexOfFileName = url.indexOf("download_name=");
          this.document.currentVersion.url =
            url.substr(0, indexOfFileName) + this.newDocument.name;

          this.editing = false;
          this.$emit("renamed", this.document.name);
        });
    },
    sidebarActionClicked(item) {
      if (item.action === "RENAME") {
        this.startRenaming();
      }
    },
    startRenaming() {
      this.newDocument.name = this.document.name;
      this.editing = true;
    },
    contextMenuItemClicked(item) {
      switch (item.action) {
        case ACTIONS.COPY:
          this.$emit("copy");
          break;
        case ACTIONS.MOVE:
          this.$emit("move");
          break;

        case ACTIONS.ATTACH_TASKS:
          this.$emit("attachTasks");
          break;

        case ACTIONS.DOWNLOAD:
          this.docManager.downloadDocuments(this.document);
          break;

        case ACTIONS.SHARE_URL:
          this.createShare();
          break;

        case ACTIONS.DELETE:
          this.delete(this.document, {
            title: "Are you sure you want to delete this document?",
            message: "Deleting a document cannot be undone",
            success: "Document successfully deleted"
          });
          break;

        case ACTIONS.PREVIEW:
          this.preview();
          break;

        case ACTIONS.UPLOAD:
          this.$store.dispatch(
            "documentManagement/browseForNewVersion",
            this.document
          );
          break;

        case ACTIONS.RENAME:
          this.startRenaming();
          break;
      }
    },
    downloadDocument() {
      this.docManager.downloadDocuments(this.document);
    },
    formattedFileSize() {
      return this.$options.filters.prettyBytes(
        this.document.currentVersion.upload.fileSize
      );
    },
    openDocument() {
      window.location.href = `/documents/${this.document.id}`;
    },
    createShare() {
      this.docManager.generateDocumentShareUrl(this.document).then(share => {
        this.shareUrl = share.data.generateDocumentShareUrl.share.url;
        clipboardy.write(this.shareUrl);
        this.$dialog.show(ShareDialog, {
          props: {
            share: this.shareUrl,
            expires: share.data.generateDocumentShareUrl.share.expires
          }
        });
      });
    },
    preview() {
      this.$dialog.show(PreviewDialog, {
        props: {
          document: this.document
        }
      });
    },
    deleteVersion(version, opts) {
      this.$dialog
        .confirm({
          title: opts.title,
          message: opts.message
        })
        .onOk(({ api }) => {
          api.hide();
          this.docManager
            .deleteDocumentVersion({
              version: version.version.toString(),
              id: this.document.id
            })
            .then(() => {
              this.$emit("updated", version);
              this.$flash.success(opts.success);
            })
            .catch(e => {
              this.$flash.error(gqlErrorMessage(e));
            });
        });
    },
    onVersionUpdated() {
      this.$apollo.queries.document.refetch();
    }
  }
};
</script>

<style lang="postcss" scoped>
.document .name {
  span {
    bottom: 15px;
    right: 7px;
  }
}

.preview {
  height: 200px;

  img {
    height: 200px;
  }
}
</style>
