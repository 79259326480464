<template lang="pug">
  .flex.flex-row
    app-icon(icon="email").mr-4
    .flex.flex-col.flex-grow

      .flex.flex-row
        strong.flex-grow Email addresses
        a(@click.prevent="editEmailToggle") Edit

      template(v-if="!editEmail")
        span.italic(v-if="!emailAddresses.length") No email addresses
        .mt-4(v-else-if="readOnly")
          .address.flex.flex-row.border-b.border-grey-30.py-2(v-for="emailAddress in emailAddresses" :key="emailAddress.key")
            strong.flex-grow-0.mr-8.w-24 {{ humanize(emailAddress.emailType) }}
            .flex.flex-col.flex-grow
              a(:href="`mailto:${emailAddress.emailAddress}`") {{ emailAddress.emailAddress }}

      app-categorised-list-editor.mt-4(
        v-else
        :categories="categories"
        :value="editingEmailAddresses"
        resource-type-label="Email address"
        @cancel="cancelEdit"
        @input="updateEmailAddresses"
    )
</template>

<script>
import { humanize } from "@/helpers/StringHelpers";
import SetContactEmailAddressesMutation from "@/graphql/mutations/contact_management/contact_email_addresses/setContactEmailAddresses.gql";

export default {
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editEmail: false,
      readOnly: true,
      emailAddressTypes: {
        SALES: { label: "Sales", value: "SALES" },
        SUPPORT: { label: "Support", value: "SUPPORT" },
        INFO: { label: "Info", value: "INFO" }
      },
      editingEmailAddresses: [],
      emailAddresses: []
    };
  },
  computed: {
    categories() {
      return Object.values(this.emailAddressTypes);
    }
  },
  mounted() {
    this.emailAddresses = this.contact.emailAddresses.map(emailAddress => ({
      ...emailAddress,
      key: Math.random()
        .toString(36)
        .substr(2, 9)
    }));
  },
  methods: {
    humanize,
    editEmailToggle() {
      this.editingEmailAddresses = this.emailAddresses.map(emailAddress => ({
        value: emailAddress.emailAddress,
        category: this.emailAddressTypes[emailAddress.emailType],
        key: emailAddress.key
      }));
      (this.editEmail = true), (this.readOnly = false);
    },
    cancelEdit() {
      (this.editEmail = false),
        (this.readOnly = true),
        (this.editingEmailAddresses = []);
    },
    updateEmailAddresses(newEmailAddresses) {
      let inputEmailAddresses = newEmailAddresses.map(emailAddress => ({
        emailType: emailAddress.category.value,
        emailAddress: emailAddress.value
      }));
      return this.$apollo
        .mutate({
          mutation: SetContactEmailAddressesMutation,
          variables: {
            input: {
              contactId: this.contact.id,
              emailAddresses: inputEmailAddresses
            }
          }
        })
        .then(() => {
          this.$flash.success("Contact email addresses updated");
          this.emailAddresses = inputEmailAddresses.map(emailAddress => ({
            ...emailAddress,
            key: Math.random()
              .toString(36)
              .substr(2, 9)
          }));
          this.editEmail = false;
          this.readOnly = true;
          this.editingEmailAddresses = [];
          this.$emit("input", this.emailAddresses);
        });
    }
  }
};
</script>
<style type="text/css">
.address:last-child {
  border: none;
}
</style>
