import Vue from "vue";
import VueApollo from "vue-apollo";
import createApolloClient from "./apollo";

const { protocol } = window.location;
const slashes = protocol.concat("//");
const host = slashes.concat(window.location.host);

// Install the vue plugin
Vue.use(VueApollo);

// Config
const options = {
  ssr: false,
  base: host,
  endpoints: {
    graphql: "/graphql",
    subscription: "/graphql"
  },
  persisting: false,
  subscriptions: false
};

// Create apollo client
export const apolloClient = createApolloClient(options);

// Create vue apollo provider
export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});
