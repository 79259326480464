<template lang="pug">
  app-panel(secondary)
    app-header(size="h3") Custom fields
      template(#subheader)
        | Permissions for custom fields

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")
      template(#options)
        app-checkbox.mb-6(label="View custom fields" :value="true")
        app-checkbox.mb-6(label="Edit existing custom fields" :value="permissions.editProperty" @input="permissionChanged('editProperty', $event)")
        app-checkbox.mb-6(label="Delete custom fields" :value="permissions.removeProperty" @input="permissionChanged('removeProperty', $event)")
        app-checkbox.mb-6(label="Create new custom fields" :value="permissions.createProperty" @input="permissionChanged('createProperty', $event)")

</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> access custom fields",
          value: "cannotManage"
        },
        {
          label: "<strong>Can</strong> access custom fields",
          value: "canManage",
          slot: "options"
        }
      ],
      selected: "cannotManage",
      permissions: {
        viewProperty: false,
        createProperty: false,
        editProperty: false,
        removeProperty: false
      }
    };
  },
  computed: {
    selectedPermissions() {
      if (this.selected === "cannotManage") {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: "global"
      };
    }
  },
  mounted() {
    if (this.value.permissions.viewProperty) {
      this.selected = "canManage";
    }
  },
  methods: {
    optionChanged(value) {
      this.permissions.viewProperty = value !== "cannotManage";
      this.selected = value;
      this.notifyChange();
    },
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
