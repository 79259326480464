const state = () => ({
  // General tracking
  cardToken: null,
  stripeCustomer: null,
  stripeSubscription: null,
  paymentIntentClientSecret: null,
  require3DSecure: false
});

const mutations = {
  storeCardInformations(state, payload) {
    state.cardToken = payload.cardToken;
    state.stripeCustomer = payload.stripeCustomer;
    state.stripeSubscription = payload.stripeSubscription;
    state.paymentIntentClientSecret = payload.paymentIntentClientSecret;
    state.require3DSecure = payload.require3DSecure;
  },
  setRerequire3DSecure(state, { require3DSecure }) {
    state.require3DSecure = require3DSecure;
  }
};

const actions = {
  // Set the card informations
  storeCardInformations({ commit }, payload) {
    commit("storeCardInformations", payload);
  },
  setRerequire3DSecure({ commit }, payload) {
    commit("setRerequire3DSecure", payload);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
