import { render, staticRenderFns } from "./LicenseTab.vue?vue&type=template&id=0891bd94&scoped=true&lang=pug&"
import script from "./LicenseTab.vue?vue&type=script&lang=js&"
export * from "./LicenseTab.vue?vue&type=script&lang=js&"
import style0 from "./LicenseTab.vue?vue&type=style&index=0&id=0891bd94&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0891bd94",
  null
  
)

export default component.exports