<template lang="pug">
  div
</template>
<script>
export default {
  created() {
    window.top.postMessage("3DS-authentication-complete");
  }
};
</script>
