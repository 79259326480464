<script>
import { Components, Helpers } from "manageplaces-ui-kit";
import ProjectsQuery from "@/graphql/queries/core/projects/Projects.gql";
import ProjectPhases from "@/graphql/queries/settings/ProjectPhases.gql";
import StatusCellRenderer from "./projects_table/StatusCellRenderer.vue";
import { ACTIONS, projectMenuItems, bulkActions } from "./ContextMenuItems.js";
import ProjectManager from "./ProjectManager.js";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import {
  customFieldColumnDefinitions,
  flattenCustomFieldsToObject
} from "@/helpers/CustomFieldHelpers";
import gql from "graphql-tag";

export default {
  extends: Components.BaseTable,
  apollo: {
    customFields: {
      query: gql`
        {
          customFields(scope: ["project", "all"]) {
            edges {
              node {
                name
                type
              }
            }
          }
        }
      `,
      update(data) {
        return data.customFields.edges.map(({ node }) => node);
      },
      result() {
        // Add each custom field to the list of table columns. Only
        // do this for allowed types however
        const columns = customFieldColumnDefinitions(this.customFields);
        this.columns.push(...columns);
      }
    },
    projects: {
      query: ProjectsQuery,

      update(data) {
        return data.projects.edges.map(({ node }) => {
          const customFields = flattenCustomFieldsToObject(node.customFields);
          return {
            ...node,
            ...customFields
          };
        });
      },

      variables() {
        return {
          search: this.searchTerm || "*",
          where: this.filters
        };
      },

      result() {
        this.setRowData(this.projects);
        this.stopLoading();
      },

      debounce: 1000,

      fetchPolicy: "no-cache"
    },
    phases: {
      query: ProjectPhases,
      update(data) {
        return data.projectPhases.edges.map(({ node }) => ({
          label: node.name,
          value: node.id
        }));
      }
    }
  },
  data() {
    const vm = this;

    return {
      filters: {},
      customFields: [],
      projectManager: new ProjectManager(),
      projects: [],
      phases: [],
      selection: "multiple",
      columns: [
        {
          colId: "checkbox",
          checkboxSelection: true,
          suppressMenu: true,
          headerCheckboxSelection: true,
          resizable: false,
          sortable: false,
          canToggle: false,
          width: 50
        },
        {
          headerName: "#",
          field: "sequence",
          flex: 1,
          editable: false,
          sortable: true,
          comparator(a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          },
          canToggle: false
        },
        {
          headerName: "Name",
          field: "name",
          editable: true,
          sortable: true,
          cellRenderer: "link",
          cellRendererParams: {
            onClick: project =>
              (window.location.href = `/projects/${project.id}`)
          },
          comparator(a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          },
          flex: 2
        },
        {
          headerName: "Status",
          field: "taskStatusSummary",
          cellRenderer: "taskStatusSummary",
          editable: false,
          sortable: false,
          flex: 2,
          canToggle: false,
          valueGetter(params) {
            return params.data.taskStatusSummary;
          }
        },
        {
          headerName: "Phase",
          field: "phase",
          sortable: true,
          flex: 1,
          cellEditor: "dropdownCellEditor",
          cellEditorParams: {
            options: () => this.phases,
            dropdownProps: {
              allowEmpty: false,
              deselectLabel: "",
              valueAttr: "value",
              labelAttr: "name"
            }
          },
          valueGetter(params) {
            return params.data.phase.name;
          }
        },
        {
          headerName: "Target deadline",
          field: "deadline",
          cellRenderer: "date",
          cellRendererParams: {
            highlightOverdue: true
          }
        },
        Helpers.table.actionsCell()
      ],
      components: {
        vue: {
          taskStatusSummary: StatusCellRenderer
        }
      },
      selectedProject: null,
      selectedProjects: [],
      config: {
        resourceType: "project",
        actionBar: false,
        filters: {
          name: {
            type: "text",
            title: "Name",
            description: "Filter projects by name",
            field: "name"
          },
          phase: {
            type: "option",
            title: "Phase",
            description: "Filter projects based on their development phase",
            field: "phase",
            options: () => vm.phases
          },
          deadline: {
            type: "date",
            title: "Target deadline",
            description: "Your target for completing the project",
            field: "deadline"
          },
          includeClosed: {
            type: "singleChoice",
            title: "Closed",
            description: "Closed projects are hidden by default",
            options: [
              {
                label: "Include closed projects",
                value: "includeClosed",
                field: "closed",
                filterCondition: "in",
                filterValue: [true, false]
              },
              {
                label: "Show only closed projects",
                value: "onlyClosed",
                field: "closed",
                filterCondition: "eq",
                filterValue: true
              }
            ]
          },
          includeTemplates: {
            type: "singleChoice",
            title: "Templates",
            description: "Project templates are hidden by default",
            options: [
              {
                label: "Include templates",
                value: "includeTemplates",
                field: "isTemplate",
                filterCondition: "in",
                filterValue: [true, false]
              },
              {
                label: "Show only templates",
                value: "onlyTemplates",
                field: "isTemplate",
                filterCondition: "eq",
                filterValue: true
              }
            ]
          }
        }
      }
    };
  },

  mounted() {
    this.startLoading();
  },

  methods: {
    performFiltering(filters) {
      this.startLoading();
      this.filters = filters;
      this.$apollo.queries.projects.refetch();
    },
    performSearch() {
      this.startLoading();
    },

    extractProjectAttrs: project => project || null,

    getBulkActions() {
      return bulkActions;
    },

    getContextMenuItems(node) {
      return projectMenuItems(node.data);
    },

    contextMenuItemClicked(item, row) {
      this.performUserAction(item, row);
    },

    performUserAction(menuItem, row) {
      const { data: project } = row;

      this.selectedProject = this.extractProjectAttrs(project);

      switch (menuItem.action) {
        case ACTIONS.VIEW:
          window.location.href = `/projects/${row.data.id}`;
          break;
        case ACTIONS.EDIT:
          window.location.href = `/projects/${row.data.id}/edit`;
          break;
        case ACTIONS.EXPORT:
          window.location.href = `/projects/${row.data.id}/export.xlsx`;
          break;
        case ACTIONS.SNAPSHOT:
          window.open(`/projects/${row.data.id}/snapshot`);
          break;
        case ACTIONS.CLOSE:
          this.close(row, {
            title: "Please confirm",
            message:
              'Closed projects are locked, allowing no modification, and are hidden on the Projects page. You can use the filters in the table on the Projects page to see closed projects. Closed projects can be re-opened again from the "Actions" menu when viewing the project.',
            success: "Project successfully closed",
            confirmLabel: "Close project"
          });
          break;
        case ACTIONS.DELETE:
          this.delete(row, {
            title: "Are you sure you want to delete this project?",
            message: "Deleting a project cannot be undone.",
            success: "Project successfully deleted",
            confirmLabel: "Delete project"
          });
          break;
      }
    },

    performBulkAction(item) {
      switch (item.action) {
        case ACTIONS.CLOSE:
          this.close(this.gridApi().getSelectedNodes(), {
            title: "Please confirm",
            message:
              'Closed projects are locked, allowing no modification, and are hidden on the Projects page. You can use the filters in the table on the Projects page to see closed projects. Closed projects can be re-opened again from the "Actions" menu when viewing the project.',
            success: "Projects successfully closed",
            confirmLabel: "Close projects"
          });
          break;

        case ACTIONS.DELETE:
          this.delete(this.gridApi().getSelectedNodes(), {
            title: "Are you sure you want to delete these projects?",
            message: "Deleting a project cannot be undone",
            success: "Projects successfully deleted",
            confirmLabel: "Delete projects"
          });
          break;
      }
    },

    delete(rows, opts) {
      rows = Array.isArray(rows) ? rows : [rows];

      const projects = rows.map(row => row.data);
      this.$dialog.confirm({ ...opts, danger: true }).onOk(({ api }) => {
        api.hide();
        this.projectManager
          .delete(projects)
          .then(() => {
            this.$flash.success(opts.success);
            this.removeRows(rows);
          })
          .catch(e => {
            this.$flash.error(gqlErrorMessage(e));
          });
      });
    },

    close(rows, opts) {
      rows = Array.isArray(rows) ? rows : [rows];

      const projects = rows.map(row => row.data);
      this.$dialog.confirm({ ...opts, danger: true }).onOk(({ api }) => {
        api.hide();
        this.projectManager
          .close(projects)
          .then(() => {
            this.$flash.success(opts.success);
            this.removeRows(rows);
          })
          .catch(e => {
            this.$flash.error(gqlErrorMessage(e));
          });
      });
    },

    removeRows(rows) {
      this.gridApi().applyTransaction({
        remove: rows
      });
    }
  }
};
</script>
