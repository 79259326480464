<template lang="pug">
  .grid.m-auto.col-gap-6.row-gap-4(class="grid-cols-1 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-9")
    app-header.row-start-1.col-span-1(class="md:col-span-5 lg:col-span-6 xl:col-span-9" margin="mb-4")
      | Choose a template

      template(v-slot:subheader)
        | Create a blank project, or pick a previous project or template to start from


    p.row-start-2.col-span-1(class="md:col-span-5 lg:col-span-6 xl:col-span-9")
      | If you choose a previous project or template as the basis of your new project, we will copy all of the tasks, folders, custom fields, team members, and other key details to your new project. This will save you a lot of time if you use the same structure for all of your projects

    .row-start-4.col-span-1(class="md:col-span-5 lg:col-span-6 xl:col-span-9")
      app-radio-field(name="showProjectTemplates" label="Do you want to copy a previous project or template?" :options="showProjectTemplateOptions" v-model="showProjectTemplates" @input="templatetoggled")

    .row-start-5.col-span-1(v-if="showProjectTemplates === 'yes'" class="md:col-span-5 lg:col-span-6 xl:col-span-9")
      div(style= "height: 500px")
        TemplatesList(:templates="templates" v-model="project.template" :completed-projects-count="completedProjectsCount")

    .row-start-5.p-3.flex.flex-row.bg-grey-30.w-full(v-else class="md:col-span-5 lg:col-span-6 xl:col-span-")
      .mr-3
        app-icon(icon="info" size="large")
      .text
        .font-bold Your project will be blank
        .block Because you've chosen not to use a previous project or template, your new project will be completely blank, and contain no task structure of any kind

</template>
<script>
import TemplatesList from "@/components/projects/wizard/TemplatesList.vue";
import ProjectWatchers from "@/mixins/ProjectWatchers.js";

export default {
  components: { TemplatesList },

  mixins: [ProjectWatchers],

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data() {
    const showProjectTemplates = "no";
    return {
      project: { ...this.value },
      loadedTemplates: [],
      completedProjectsCount: null,
      showProjectTemplates,
      showProjectTemplateOptions: [
        { name: "Yes", value: "yes" },
        { name: "No", value: "no" }
      ]
    };
  },

  computed: {
    templates() {
      return [this.emptyProjectTemplate, ...this.loadedTemplates];
    },

    emptyProjectTemplate() {
      return {
        name: "Empty project",
        description: `Start a new project from scratch with no data.
        Use this option if you wish to create an entirely new project with
        no predefined data or don't have any previous projects or templates.`
      };
    }
  },

  methods: {
    setLoadedTemplates(templates) {
      this.loadedTemplates = templates;
      if (!this.completedProjectsCount) {
        // the first load gets all the completed projects, so we can use that
        this.completedProjectsCount = this.loadedTemplates.length;
      }
    },
    templatetoggled(showProjectTemplates) {
      if (showProjectTemplates === "no") {
        this.project.template = null;
      }
    }
  }
};
</script>
