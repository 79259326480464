<template lang="pug">
  .welcome-setup
    .welcome-text(class="xs:p-16 sm:p-24 mx-auto")
      h1.text-2xl Invite your team
      p.text-xl To speed up project planning and communication, invite your team members using the form on the right. All we need is an email address and a role assigned to each team member. You can control and change user permissions later on, but for now you can choose one of the following roles:
      h2.text-xl.pl-8 Admin
      p.text-xl.pl-8 Full access to everything in your company
      h2.text-xl.pl-8 Team member
      p.text-xl.pl-8 Full access to all projects or tasks they are assigned to, but nothing else
      h2.text-xl.pl-8 Guest
      p.text-xl.pl-8 Read-only access to projects or tasks they are assigned to
    .flex.justify-end
      div(class="xs:w-full md:w-1/2 signup-form-content")
        div(class="xs:p-16 sm:p-24 mx-auto inner-content")
          app-header.pt-20 Who is a part of {{ company.name }}?
            template(#subheader) Make the most out of your trial by inviting other people. If you do not wish to invite anyone else at this time, click Next
          .user(class="border-b-2 border-grey-30")
            .flex.mt-4
              p.mr-5(class="w-1/2") Teammate email
              p(class="w-2/2") Role
          .flex.mt-4
            p.mr-5(class="w-1/2 font-bold text-black") You
            p(class="w-2/2") Owner
          .flex.mt-4(v-for="user in users")
            app-text-field.mr-5(class="w-2/3" name="email" :show-optional="false" v-model="user.email")
            app-dropdown-field(class="w-2/3" name="role" v-model="user.role"
                               :options="companyRoles" value-attr="id" label-attr="name" :show-optional="false" deselectLabel="" :allow-empty="false")
          a(@click="addUserField") Add more people...

          app-button(primary class="w-full text-center focus:outline-none focus:shadow-outline mt-20" @click="nextStep") Next
</template>
<script>
import CompanyQuery from "@/graphql/queries/core/company/Company.gql";
import CompanyRolesQuery from "@/graphql/queries/core/company/Roles.gql";
import { initCap } from "@/helpers/StringHelpers";
import { email } from "vuelidate/lib/validators";

export default {
  apollo: {
    company: CompanyQuery,
    companyRoles: {
      query: CompanyRolesQuery,
      update(data) {
        return data.companyRoles.edges.map(edge => {
          const { id, name } = edge.node;
          return { id, name: initCap(name.replace("_", " ")) };
        });
      },
      result() {
        this.defaultRole = this.adminRole();
        this.users.forEach(user => (user.role = this.defaultRole));
      }
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    let users = [...(this.value?.users || [])];
    if (users.length < 3) {
      const userLength = users.length;
      let i = 0;
      for (i; i < 3 - userLength; i++) {
        users.push({ email: null, role: null });
      }
    }
    return { users };
  },
  methods: {
    addUserField() {
      this.users.push({
        email: null,
        role: this.adminRole()
      });
    },
    nextStep() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$flash.error("an invalid email address has been entered");
      } else {
        this.$emit("step-increase", {
          users: this.users.filter(
            value => value.email !== null && value.email !== ""
          )
        });
      }
    },
    adminRole() {
      const option = this.companyRoles.find(role => role.name === "Admin");
      return option.id;
    }
  },
  validations: {
    users: {
      $each: {
        email: {
          email
        }
      }
    }
  }
};
</script>
<style lang="postcss" scoped>
.welcome-text {
  position: absolute;
  top: 5%;
  color: white;
  width: 50%;
  p {
    margin-bottom: 30px;
  }
  h1 {
    color: white;
    margin-bottom: 30px;
  }
  h2 {
    font-weight: bold;
    color: white;
  }
}

.signup-form-content .inner-content {
  max-width: 700px;
  background-color: white;
}
</style>
