<template lang="pug">
  tbody(
    is='draggable',
    element='tbody',
    :list='rows',
    :options='{ draggable: "tr", handle: ".handle" }')

    tabular-form-row-data(
      v-for="(row, index) in rows",
      :row='row',
      :can-edit='canEdit',
      :key='row.rowNumber',
      @row-deleted='rowDeleted',
      :currency='currency',
      :index='index',
      :sortable='sortable')
</template>

<script>
import TabularFormRowData from "./TabularFormRowData.vue";

export default {
  components: {
    TabularFormRowData
  },
  props: ["rows", "deletedRows", "currency", "sortable", "canEdit"],
  methods: {
    rowDeleted(rowIndex) {
      this.deletedRows.push(this.rows[rowIndex]);
      this.$delete(this.rows, rowIndex);
    }
  }
};
</script>
