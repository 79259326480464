import { normalizeFloat } from "@/helpers/StringHelpers";

const FieldMixin = {
  methods: {
    normalizePrice(price) {
      return normalizeFloat(price);
    }
  }
};

export default FieldMixin;
