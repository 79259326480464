<template lang="pug">
  .welcome-setup
    .welcome-text(class="xs:p-16 sm:p-24 mx-auto")
      h1.text-2xl Let's get you setup
      p.text-xl Follow the next 3 steps to kick start a more tailored ManagePlaces experience. You can change these settings at any time, once you start using the platform.
      p.text-xl You can adjust your projects to fit your time zone, local currency and more. Use the drop-down menus on the right to get started.
      p.text-xl We'd also love to personally hear about your first impressions of ManagePlaces, so please leave us a phone number. This won’t be used for any other purpose, and we will never share this with anyone else.
    .flex.justify-end
      div(class="xs:w-full md:w-1/2 signup-form-content")
        div(class="xs:p-16 sm:p-24 mx-auto inner-content")
          form-wrapper(:validator="$v")
            form.new
              app-text-field.pt-20(name="telephone" label="Telephone number" v-model="telephone" description="We'd love to find out how you’re getting on")

              app-dropdown-field(name="country" label="What country are you in?" v-model="country"
                                          :options="availableCountries" value-attr="code" label-attr="name")

              app-dropdown-field(name="currency" label="What currency do you want to use?" v-model="currency"
                                          :options="availableCurrencies" value-attr="code" label-attr="name" description="This will be used for financial tracking")

              app-dropdown-field(name="timezone" label="What timezone are you in?" v-model="timezone"
                                          :options="timezones" value-attr="tzinfo" label-attr="name" description="Choose your nearest city")

              app-button(primary class="w-full text-center focus:outline-none focus:shadow-outline mt-20" @click="nextStep") Next
</template>
<script>
import CurrencyQuery from "@/graphql/queries/core/company/AvailableCurrencies.gql";
import CountryQuery from "@/graphql/queries/core/company/AvailableCountries.gql";
import CompanyQuery from "@/graphql/queries/core/company/Company.gql";
import { required } from "vuelidate/lib/validators";
import { findIndex } from "lodash";

export default {
  apollo: {
    availableCountries: CountryQuery,
    availableCurrencies: CurrencyQuery,
    company: CompanyQuery
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      telephone: this.value?.telephone,
      country: this.value?.country,
      currency: this.value?.currency,
      timezone: this.value?.timezone
    };
  },
  computed: {
    timezones() {
      if (!this.country) {
        return [];
      } else {
        const idx = findIndex(
          this.availableCountries.map(country => country.code === this.country)
        );

        if (idx > -1) {
          return this.availableCountries[idx].timezones;
        }

        return this.country.timezones;
      }
    }
  },
  methods: {
    nextStep() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$flash.error("Please fill in all the fields");
      } else {
        this.$emit("step-increase", {
          telephone: this.telephone,
          country: this.country,
          currency: this.currency,
          timezone: this.timezone
        });
      }
    }
  },
  validations: {
    telephone: {
      required
    },
    country: {
      required
    },
    currency: {
      required
    },
    timezone: {
      required
    }
  }
};
</script>
<style lang="postcss" scoped>
.welcome-text {
  position: absolute;
  top: 5%;
  color: white;
  width: 50%;
  p {
    margin-bottom: 30px;
  }
  h1 {
    color: white;
    margin-bottom: 30px;
  }
}

.signup-form-content .inner-content {
  max-width: 700px;
  background-color: white;
}
</style>
