import { render, staticRenderFns } from "./TabularFormHeader.vue?vue&type=template&id=0f6eb647&lang=pug&"
import script from "./TabularFormHeader.vue?vue&type=script&lang=js&"
export * from "./TabularFormHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports