<template lang="pug">
  .h-full.w-full.overflow-hidden(ref="container")
    .h-full.inline-flex.flex-row.items-center.text-lg
      app-dropdown(v-if="dropdownFolders.length > 0", :inline="false")
        template(#trigger="{ open }")
          .relative.trigger
            app-file-icon.relative.cursor-pointer(extension="folder")
            app-icon.caret.absolute.transform.pointer-events-none(:icon="open ? 'caretUp' : 'caretDown'", colour="white", class="-translate-y-1/2 -translate-x-1/2", size="xs")


        template(#content="{ close }")
          .dropdown-items
            .item(v-for="folder in dropdownFolders", @click="folderClicked(folder) && close()")
              app-file-icon.mr-4(extension="folder")
              span {{ folder }}

      span.text-grey-80.font-bold(v-else-if="isRoot") Documents
      a(v-else, href="#", @click.prevent="folderClicked()") Documents

      span.breadcrumb(v-for="(folder, idx) in visiblePath", :key="folder.id", ref="breadrumbs")
        app-icon.relative.mx-1(icon="caretRight", colour="lightGrey", size="xs")

        a.text-lg.flex-initial(v-if="idx !== visiblePath.length - 1", @click.prevent="folderClicked(folder)") {{ folder }}
        span.text-lg.flex-initial.text-grey-80.font-bold(v-else) {{ folder }}
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      internalPath: "/",
      hiddenCount: 0
    };
  },
  computed: {
    dropdownFolders() {
      if (this.hiddenCount === 0) {
        return [];
      }

      const dropdown = this.pathParts.slice(0, this.hiddenCount - 1).reverse();

      dropdown.push("Documents");

      return dropdown;
    },
    pathParts() {
      if (this.isRoot) {
        return [];
      }

      return this.path.substring(1).split("/");
    },
    isRoot() {
      return this.internalPath === "/";
    },
    visiblePath() {
      if (this.hiddenCount > 0) {
        return this.pathParts.slice(this.hiddenCount - 1);
      }

      return this.pathParts;
    }
  },
  watch: {
    path: {
      handler(newPath) {
        const lengthDiff = newPath.length - this.internalPath.length;
        this.internalPath = newPath;
        if (lengthDiff < 0) {
          // Decrease the count of hidden elements before we recalculate
          this.hiddenCount += lengthDiff;
          this.hiddenCount = Math.max(this.hiddenCount, 0);
        }
        this.$nextTick(this.recalculateVisibleOptions);
      },
      immediate: true
    }
  },
  methods: {
    folderClicked(pathPart = "/") {
      let folderPath = pathPart;

      if (pathPart !== "/") {
        const idx = this.pathParts.indexOf(pathPart);
        folderPath = "/" + this.pathParts.slice(0, idx + 1).join("/");
      }

      this.$emit("click", folderPath);
    },
    isOverflowing() {
      const container = this.$refs.container;
      return container.clientWidth < container.scrollWidth;
    },
    recalculateVisibleOptions() {
      if (this.isOverflowing()) {
        this.hiddenCount++;

        this.$nextTick(() => {
          this.recalculateVisibleOptions();
        });
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.dropdown-items {
  min-width: 150px;
  max-height: 300px;
  overflow: auto;

  .item {
    @apply text-grey-80;
    @apply py-4;
    @apply px-4;
    @apply flex;
    @apply flex-row;
    @apply items-center;
    @apply cursor-pointer;

    &:hover {
      @apply bg-grey-20;
    }
  }
}

.trigger .caret {
  top: 50%;
  left: 50%;
}

.breadcrumb {
  @apply flex;
  @apply flex-row;
  @apply flex-none;
  @apply items-center;
  @apply ml-2;
}
</style>
