import { runMutation } from "@/helpers/GraphQLHelpers";
import DeleteEstimates from "@/graphql/mutations/finance/estimates/DeleteEstimates.gql";

export default class EstimatesManager {
  delete(estiamtes) {
    const ids = estiamtes.map(co => co.id);

    return runMutation(DeleteEstimates, { ids });
  }
}
