export const INPUT_TYPE_ERROR = "Please correct the errors and try again";
export const UPDATE_SUCCES = "Successfully updated";
export const UPGRADE_SUCCES = "Successfully upgraded";
export const END_SUBSCRIPTION_SUCCESS = "Your subscription has been cancelled";
export const BILLING_INFO_UPDATE_SUCCES =
  "Billing information has updated successfully";
export const TAX_INFO_UPDATE_SUCCES =
  "Tax information has updated successfully";
export const TAX_INPUT_TYPE_ERROR =
  "Please correct the errors on tax information and try again";
export const BILLING_INPUT_TYPE_ERROR =
  "Please correct the errors on billing information and try again";
export const INVALID_CARD_ERROR =
  "Unable to use your card number. Please use other card";
