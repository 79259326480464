/**
 *  Creates a Vuex plugin for tracking the currently active tab
 *  on any page with javascript-based tabs. This will update the
 *  vuex store with the tab that is selected
 */
export default function createTabPlugin() {
  return store => {
    document.addEventListener("DOMContentLoaded", () => {
      const tabMenu = document.getElementsByClassName(
        "primary history tabs menu"
      )[0];

      if (tabMenu) {
        tabMenu.addEventListener("change", evt => {
          store.commit("setActiveTab", evt.detail.tab);
        });
      }
    });
  };
}
