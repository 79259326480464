<template lang="pug">
  app-sidebar(@close="$emit('close')" :actions="actions" @action-clicked="actionClicked" :tabs="tabs" :active-tab="activeTab" @tab-changed="tabChanged")
    template(#header)
      .flex.flex-row.justify-between.flex-initial
        task-sidebar-title(:task="task" v-on="$listeners" @renamed="renameTask" @rename-cancelled="isRenaming = false" :renaming="isRenaming")
          template(#back-btn v-if="previousTask")
            a.block.mb-4(@click.prevent="goBack" href="#") <- Back to {{ previousTask.name }}

    template(#details)
      app-loader(:loading="loading")
        template(v-if="task")
          .p-8
            task-sidebar-basic-details(:task="task" v-on="$listeners" @field-changed="taskFieldChanged")
            task-sidebar-relationships(:task="task" v-on="$listeners" @task-clicked="setSidebarTask" v-if="!task.isMilestone && hasRelationships")
            task-checklist.bg-grey-10(:task="task" v-if="!task.isMilestone" @checklist-changed="taskChecklistChanged")
            custom-fields(:resource="task" resource-type="Task" :task-type="true" v-if="!task.isMilestone")
            task-sidebar-settings(v-if="task.canEdit" :task="task" @field-changed="taskFieldChanged")

    template(#documents)
      app-loader(:loading="loading")
        template(v-if="task")
          .p-8
            task-sidebar-documents(:task="task")

    template(#approvers)
      app-loader(:loading="loading")
        template(v-if="task")
          .p-8
            task-approvers.bg-grey-10(:task="task" @input="task.approvers = $event.approvers")

    template(#comments)
      app-loader(:loading="loading")
        template(v-if="task")
          comments-panel(:comments="comments" :resource-id="task.id" resource-type="Task" v-on="commentListeners")
</template>

<script>
import { ACTIONS } from "@/components/projects/ProjectTasksTableContextMenuItems";

import TaskQuery from "@/graphql/queries/core/tasks/Task.gql";
import TaskSidebarTitle from "./TaskSidebarTitle.vue";
import TaskSidebarBasicDetails from "./TaskSidebarBasicDetails.vue";
import TaskSidebarRelationships from "./TaskSidebarRelationships.vue";
import CustomFields from "@/components/resource/CustomFields.vue";
import TaskSidebarSettings from "./TaskSidebarSettings.vue";
import TaskSidebarDocuments from "./TaskSidebarDocuments.vue";
import TaskApprovers from "../TaskApprovers.vue";
import CommentsPanel from "@/components/resource/CommentsPanel.vue";
import TaskManager from "@/components/projects/tasks_table/TaskManager";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import { confirmDelete } from "@/helpers/DialogHelpers";

import gql from "graphql-tag";

export default {
  name: "Sidebar",
  components: {
    TaskSidebarTitle,
    TaskSidebarBasicDetails,
    TaskSidebarRelationships,
    CustomFields,
    TaskSidebarSettings,
    TaskApprovers,
    TaskSidebarDocuments,
    CommentsPanel
  },

  props: {
    taskId: {
      type: String,
      required: true
    },
    reload: {
      type: Boolean,
      default: false
    },
    renaming: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      activeTab: "Details",
      loading: true,
      editing: false,
      previousTasks: [],
      currentTaskId: this.taskId,
      comments: [],
      isRenaming: this.renaming,
      permissions: {
        copyTask: false
      }
    };
  },

  apollo: {
    permissions: {
      query: gql`
        query projectPermissions($id: GlobalID) {
          projectPermissions(id: $id) {
            canCreateTask
          }
        }
      `,
      variables() {
        return {
          id: this.task?.project?.id
        };
      },
      update({ projectPermissions }) {
        return {
          copyTask: projectPermissions.canCreateTask
        };
      },
      skip() {
        return !this.task;
      }
    },
    task: {
      query: TaskQuery,
      variables() {
        return { id: this.currentTaskId };
      },

      result({ data }) {
        this.loading = false;
        this.comments = data.task.comments;
        this.$apollo.queries.permissions.refetch();
      },

      error() {
        // We'll just close the sidebar and present an error
        this.$flash.error(
          "There was an unexpected error loading the task. Please try again"
        );
        this.$emit("close");
      },

      fetchPolicy: "no-cache"
    }
  },

  computed: {
    actions() {
      let actions = [];

      if (this.task?.canEdit) {
        actions.push({ label: "Rename", icon: "edit", action: ACTIONS.RENAME });
      }

      if (this.permissions.copyTask) {
        actions.push({
          label: "Copy",
          icon: "copy",
          action: ACTIONS.COPY_TASK
        });
      }

      if (this.task?.canDestroy) {
        actions.push(
          { type: "divider" },
          {
            label: "Delete",
            icon: "delete",
            iconColour: "red",
            action: ACTIONS.DELETE
          }
        );
      }

      return actions;
    },
    tabs() {
      let tabs = [{ name: "Details", slot: "details" }];

      if (this.task?.canViewDocuments) {
        tabs.push({ name: "Documents", slot: "documents" });
      }

      if (this.task?.canViewApprovers) {
        tabs.push({ name: "Approvers", slot: "approvers" });
      }

      tabs.push({ name: "Comments", slot: "comments" });

      return tabs;
    },
    taskManager() {
      return new TaskManager({ id: this.task?.project?.id });
    },

    hasRelationships() {
      return this.task.parent || this.task.dependencies.length;
    },

    previousTask() {
      let lastIdx = this.previousTasks.length;
      lastIdx = lastIdx == 0 ? 0 : lastIdx - 1;
      return this.previousTasks[lastIdx];
    },

    commentListeners() {
      const vm = this;
      return Object.assign(
        {},
        {
          comment(comment) {
            vm.comments.push(comment);
          }
        },
        this.$listeners
      );
    }
  },

  watch: {
    taskId(newId) {
      this.currentTaskId = newId;
      this.previousTasks = [];
      this.setRouteHash(newId);
      this.loading = true;
      this.$apollo.queries.task.refetch();
      this.activeTab = "Details";
    },
    reload(newVal) {
      if (newVal) {
        this.loading = true;
        this.$apollo.queries.task.refetch();
        this.$emit("reloaded");
      }
    }
  },

  mounted() {
    this.setRouteHash(this.taskId);
  },

  methods: {
    actionClicked(item) {
      switch (item.action) {
        case ACTIONS.RENAME:
          this.isRenaming = true;
          break;
        case ACTIONS.MOVE:
          this.$emit("move", this.task);
          break;
        case ACTIONS.DELETE:
          this.deleteTask();
          break;
        case ACTIONS.COPY_TASK:
          window.location.href = `/tasks/${this.task.id}/duplicate`;
          break;
        default:
          break;
      }
    },
    setRouteHash(taskId) {
      let hash = "#/tasks";

      if (taskId) {
        hash = `#/tasks/${taskId}`;
      }
      window.location.hash = hash;
    },

    renameTask(task) {
      this.taskFieldChanged({ task, field: "name", saved: false });
      this.isRenaming = false;
    },

    deleteTask() {
      confirmDelete.call(this, this.task, () => {
        this.taskManager
          .delete([this.task])
          .then(() => {
            this.setRouteHash();
            this.$emit("deleted", this.task);
          })
          .catch(e => {
            this.$flash.error(gqlErrorMessage(e));
          });
      });
    },

    setSidebarTask(taskId) {
      if (this.previousTask !== this.task) this.previousTasks.push(this.task);
      this.currentTaskId = taskId;
    },

    goBack() {
      this.currentTaskId = this.previousTasks.pop().id;
    },

    closeSidebar() {
      this.setRouteHash();
      this.$emit("close");
    },

    taskFieldChanged(change) {
      const { task, field, saved } = change;

      if (!saved) {
        this.taskManager
          .updateTable(task, field)
          .then(tasks => {
            this.task[field] = task[field];
            this.$emit("tasks-updated", tasks);
          })
          .catch(() => {
            this.$flash.error(
              "There was an unexpected error updating the task. Please try again"
            );
          });
      } else {
        this.task[field] = task[field];
        this.$emit("tasks-updated", [task]);
      }
    },
    taskChecklistChanged(task) {
      this.task.checklistItems = task.checklistItems;
    },
    tabChanged(tab) {
      this.activeTab = tab.name;
    }
  }
};
</script>
