<template lang="pug">
  app-panel(secondary)
    app-header(size="h3") Miscellaneous
      template(#subheader)
        | Permissions not specifically related to anything

    app-checkbox.mb-6(label="Comment on resources" :value="permissions.createComment" @input="permissionChanged('createComment', $event)")
    app-checkbox.mb-6(label="Import data" :value="permissions.importData" @input="permissionChanged('importData', $event)")

</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      permissions: {
        createComment: false,
        importData: false
      }
    };
  },
  computed: {
    selectedPermissions() {
      return {
        permissions: { ...this.permissions },
        scope: "global"
      };
    }
  },
  methods: {
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
