<template lang="pug">
  app-dialog(@cancel="$emit('cancel')" :dismissable="false")
    template(#title) No licenses remaining

    p.mb-8.text-grey-70
      | You don't currently have any licenses remaining to assign. Would you
      | like to purchase a new license?

    .text-right
      app-button.mr-3(default @click="$emit('cancel')") Cancel
      app-button(primary @click="$emit('ok', { action: 'purchase license' })") Purchase a license
</template>

<script>
export default {
  name: "PurchaseLicensesDialog",
  computed: {
    companyOwner() {
      return this.$store.state.companyOwner;
    }
  }
};
</script>
