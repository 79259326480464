export const ACTIONS = {
  EDIT: "edit",
  DELETE: "delete"
};

export const orderMenuItems = () => {
  let actions = [];

  actions.push({ label: "Edit order", icon: "edit", action: ACTIONS.EDIT });

  actions.push({ type: "divider" });

  actions.push({
    label: "Delete",
    icon: "delete",
    iconColour: "red",
    action: ACTIONS.DELETE
  });

  return actions;
};

export const bulkActions = [
  {
    label: "Delete",
    icon: "delete",
    iconColour: "red",
    action: ACTIONS.DELETE
  }
];
