<template lang="pug">
  multiselect(
    :placeholder='placeholder',
    :searchable='searchable',
    v-model='internalValue',
    :options='filteredOptions',
    :internal-search='isGrouped',
    :select-label='selectLabel',
    :select-group-label='selectGroupLabel',
    :deselect-label='deselectLabel',
    :deselect-group-label='deselectGroupLabel',
    :tag-placeholder='tagPlaceholder',
    :loading='loading',
    :open-direction='openDirection',
    :allow-empty='allowEmpty',
    :track-by='trackBy',
    :label='label',
    :taggable='taggable',
    :disabled='disabled',
    :multiple='multiple',
    :close-on-select='!multiple',
    :custom-label='customLabel',
    :group-label='groupLabel',
    :group-values='groupValues',
    :group-select='groupSelect',
    v-on='inputListeners',
    :tabIndex='0',
    :hide-selected="true"
  )

    template(slot='beforeList', v-if='showCreateButton')
      .quick.create.multiselect__option(@click='$emit("create")')
        app-icon(icon='add', colour='green')
        | {{ createLabel }}
</template>

<script>
import filter from "ramda/src/filter";

export default {
  props: {
    placeholder: String,
    searchable: {
      type: Boolean,
      default: true
    },
    value: [Object, String, Number, Array],
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    selectLabel: {
      type: String,
      default: ""
    },
    selectGroupLabel: {
      type: String,
      default: ""
    },
    deselectLabel: {
      type: String,
      default: ""
    },
    deselectGroupLabel: {
      type: String,
      default: ""
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "label"
    },
    customLabel: Function,
    trackBy: {
      type: String,
      default: "id"
    },
    openDirection: {
      type: String,
      default: "bottom"
    },
    showCreateButton: {
      type: Boolean,
      default: false
    },
    createLabel: String,
    taggable: {
      type: Boolean,
      default: false
    },
    tagPlaceholder: {
      type: String,
      default: "Press enter to add"
    },
    disabled: Boolean,
    multiple: Boolean,
    groupLabel: String,
    groupSelect: Boolean,
    groupValues: String,
    searchFields: {
      type: Array,
      required: false,
      default() {
        return ["label"];
      }
    }
  },
  data() {
    return {
      filteredOptions: this.options,
      internalValue: this.value
    };
  },
  computed: {
    isGrouped() {
      return !!this.groupLabel;
    },
    inputListeners() {
      const vm = this;

      return Object.assign({}, this.$listeners, {
        select: evt => vm.$emit("selected", evt),
        input: evt => vm.$emit("input", evt),
        tag: evt => vm.$emit("add-option", evt),
        "search-change": vm.searchOptions
      });
    }
  },
  watch: {
    options(newOptions) {
      this.filteredOptions = newOptions;
    },
    value(newValue) {
      this.internalValue = newValue;
    }
  },
  methods: {
    searchOptions(searchQuery) {
      if (this.isGrouped) {
        return;
      }

      const vm = this;
      const queryTerm = searchQuery.toLowerCase();
      const search = resource => {
        if (typeof resource === "object") {
          const searchFn = field => {
            if (!resource[field]) return false;

            return resource[field].toLowerCase().indexOf(queryTerm) !== -1;
          };

          return vm.searchFields.some(searchFn);
          // return resource[vm.label].toLowerCase().indexOf(queryTerm) !== -1;
        }

        return resource.toLowerCase().indexOf(queryTerm) !== -1;
      };

      this.filteredOptions = filter(search, this.options);
    }
  }
};
</script>
