<template lang="pug">
  .text-center
    app-loader(:loading="loading" class="loader2")
      template(v-if="error")
        h3 {{errorMessage}}
      template(v-else)
        .flex.justify-center
          .mb-8
            app-toggle-button(:options="checkout.billingOptions",
                              v-model="checkout.billingType",
                              data-cy="billing-type-switch")
        .flex.flex-row.justify-center
          .flex.justify-center(class="w-full flex-col sm:flex-row")
            .plan-card.flex.flex-col(class="mr-0 sm:mr-8")
              app-panel.plan-card-content
                .flex-initial
                  .mb-4
                    .font-bold.text-xl.mb-4.uppercase Free
                    .font-bold.text-xl {{checkout.currency}}0
                    div user per {{checkout.billingType.value == 'a' ? 'year' : 'month'}}

                  .mb-4 For individuals or small companies working on a single project at a time

                  .flex.flex-row
                    span.mr-2.mb-2
                      app-icon.bg-subatomic-sky.custom-icon(icon="success"
                        size="small" colour="darkGreen")
                    span 1 active project
                  .flex.flex-row
                    span.mr-2.mb-2
                      app-icon.bg-subatomic-sky.custom-icon(icon="success"
                        size="small" colour="darkGreen")
                    span 1 user
                  .flex.flex-row
                    span.mr-2.mb-2
                      app-icon.bg-subatomic-sky.custom-icon(icon="success"
                        size="small" colour="darkGreen")
                    span 5GB document storage
                .flex-initial(v-if="showFreePlan")
                  app-button.border-tribal-aqua.text-tribal-aqua.w-full.mt-4.font-bold(
                    class="hover:bg-diving-sapphire hover:border-diving-sapphire hover:text-white"
                    @click.prevent="onSubmit('Free')"
                    data-cy="free-plan-submit"
                  ) Get Started
                .flex-initial(v-else)
                  app-label.py-3(colour="blue") Current Plan
              div

            .plan-card.flex.flex-col
              app-panel.plan-card-content
                .flex-initial
                  .justify-center.mb-4
                    .font-bold.text-xl.mb-4.uppercase Pro
                    .font-bold.text-xl {{checkout.currency}}{{proPrice}}
                    div user per {{checkout.billingType.value == 'a' ? 'year' : 'month'}}

                  .mb-4 For most companies that work on multiple projects or track finances

                  .flex.flex-row
                    span.mr-2.mb-2
                      app-icon.bg-subatomic-sky.custom-icon(icon="success"
                        size="small" colour="darkGreen")
                    span Unlimited projects
                  .flex.flex-row
                    span.mr-2.mb-2
                      app-icon.bg-subatomic-sky.custom-icon(icon="success"
                        size="small" colour="darkGreen")
                    span Unlimited users
                  .flex.flex-row
                    span.mr-2.mb-2
                      app-icon.bg-subatomic-sky.custom-icon(icon="success"
                        size="small" colour="darkGreen")
                    span 50GB document storage
                  .flex.flex-row
                    span.mr-2.mb-2
                      app-icon.bg-subatomic-sky.custom-icon(icon="success"
                        size="small" colour="darkGreen")
                    span Finance management*
                .flex-initial
                  app-button.w-full.mt-4(primary
                    @click.prevent="onSubmit('Pro')"
                    data-cy="pro-plan-submit"
                  ) Get Started
              .text-sm.text-center *Add-ons charged in addition to user licenses
</template>

<script>
import CheckoutWatchers from "@/mixins/CheckoutWatchers";
import StripePlansQuery from "@/graphql/queries/subscription/StripePlans.gql";
import ActivateFreePlanMutation from "@/graphql/mutations/subscriptions/ActivateFreePlan.gql";
import { UPDATE_SUCCES } from "@/config/help_messages/SubscriptionMessages";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

export default {
  components: {},
  mixins: [CheckoutWatchers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      checkout: { ...this.value },
      resourceType: "checkout",
      loading: true,
      error: false,
      errorMessage: ""
    };
  },
  apollo: {
    stripePlans: {
      query: StripePlansQuery,
      result(res) {
        if (res.data && res.data.stripePlans) {
          this.$store.commit("setStripePlans", res.data.stripePlans);
        }
        this.loading = false;
      },
      error(error) {
        this.error = true;
        this.$flash.error(
          `Unable to show subscription plans - ${error.message}`
        );
      }
    }
  },
  computed: {
    priceForMonth() {
      const plan = this.stripePlans
        ? this.stripePlans.filter(plan => plan.interval === "month")
        : [];
      return plan.length > 0 ? plan[0].amount : 0;
    },
    priceForYear() {
      const plan = this.stripePlans
        ? this.stripePlans.filter(plan => plan.interval === "year")
        : [];
      return plan.length > 0 ? plan[0].amount : 0;
    },
    proPrice() {
      return this.checkout.billingType.value === "a"
        ? this.priceForYear
        : this.priceForMonth;
    },
    showFreePlan() {
      const { plan, status } = this.checkout;
      if (
        !plan ||
        (plan && plan.name.toLowerCase() !== "free") ||
        (plan.name.toLowerCase() === "free" &&
          status.toLowerCase() === "cancelled")
      ) {
        return true;
      }

      return false;
    }
  },
  watch: {
    proPrice: {
      handler: function() {
        this.checkout.userLicensePrice = this.priceForMonth;
        this.checkout.userAnnualPrice = this.priceForYear;
      }
    }
  },
  methods: {
    onSubmit(type) {
      if (type === "Free") {
        this.loading = true;

        this.$apollo
          .mutate({
            mutation: ActivateFreePlanMutation,
            variables: {
              input: {
                billingFrequency: this.checkout.billingType.value
              }
            }
          })
          .then(({ data: { activateFreePlan } }) => {
            if (activateFreePlan && activateFreePlan.status) {
              this.value.checkoutStatus = "ACTIVE";
              this.$flash.success(UPDATE_SUCCES);
              window.location.href = "/settings/subscriptions";
            } else {
              this.$flash.error(activateFreePlan.error);
            }
            this.loading = false;
          })
          .catch(error => {
            this.$flash.error(gqlErrorMessage(error));
            this.loading = false;
          });
      } else {
        this.checkout.checkoutType = type;
        this.$emit("submit");
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.plan-card {
  position: relative;
  max-width: 265px;
  border-radius: 5px;
  .plan-card-content {
    min-height: 355px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  @media (max-width: 579px) {
    margin-left: auto;
    margin-right: auto;
  }
}
.custom-icon {
  background-color: #6dffb1;
  color: var(--subatomic-sky);
  border-radius: 100%;
}
.discount-badge {
  position: absolute;
  top: 0;
  right: 0;
  background: #6dffb1;
  color: #177d0c;
  padding: 5px 8px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
}
.loader {
  top: -9rem !important;
}
</style>
