import { caseCompare } from "@/helpers/StringHelpers";

export default {
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },

    isCompanyOwner() {
      return caseCompare(this.currentUser.role || "", "Owner");
    }
  }
};
