<template lang="pug">
  .block.my-3(v-if="params.value === 'Project'")
    app-label(colour="green") {{ params.value }}
  .block.my-3(v-else)
    app-label {{ params.value }}

</template>

<script>
import Vue from "vue";

export default Vue.extend({});
</script>
