<template lang="pug">
  .flex-initial.justify-between.flex.mb-6(:class="comment.isCurrentUser ? 'flex-row-reverse' : 'flex-row'")
    app-avatar.flex-none(:user="comment.commenter")
    .flex-1.flex.flex-col(:class="comment.isCurrentUser ? 'mr-4 items-end' : 'ml-4 items-start'")
      .flex-1.px-4.py-2.rounded-lg(:class="commentClasses(comment)")
        p.comment-body(v-html="$sanitize(comment.body)")

      //- p.flex-initial.text-grey-60.text-sm.mt-2(:class="{ 'text-right': comment.isCurrentUser }") {{ comment.commenter.name }} - {{ comment.commentedAt }}
      .inline.text-grey-60.text-sm.mt-2(:class="{ 'text-right': comment.isCurrentUser }")
        strong.inline {{ comment.commenter.name }}
        p.inline  - {{ validateDate(comment.commentedAt) }}
</template>

<script>
import DateValidateMixin from "@/mixins/DateValidator.js";

export default {
  components: {},
  mixins: [DateValidateMixin],
  props: {
    comment: { type: Object, required: true, default: () => {} }
  },
  methods: {
    commentClasses(comment) {
      return [
        comment.isCurrentUser ? "bg-diving-sapphire" : "bg-grey-20",
        comment.isCurrentUser ? "text-white" : "text-grey-80",
        comment.isCurrentUser ? "ml-12" : "mr-12"
      ];
    }
  }
};
</script>

<style lang="postcss">
.comment-body {
  a {
    @apply font-bold no-underline;
    color: inherit;
    &:hover {
      @apply underline;
    }
  }
}
</style>
