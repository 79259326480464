<template lang="pug">
  router-view
</template>

<script>
import { AgGridVue } from "ag-grid-vue";

export default {
  name: "Reports",
  components: {
    AgGridVue
  }
};
</script>
