import { FINANCE_MANAGEMENT, USER_LIMIT } from "@/config/constants";

import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

import AssignUserLicenseMutation from "@/graphql/mutations/users/AssignUserLicense.gql";
import RevokeUserLicenseMutation from "@/graphql/mutations/users/RevokeUserLicense.gql";

import NoLicensesDialog from "@/components/users/users_table/NoLicensesDialog.vue";
import PurchaseLicenseDialog from "@/components/users/users_table/PurchaseLicenseDialog.vue";
import AssignLicenseDialog from "@/components/users/users_table/AssignLicenseDialog.vue";
import NonStripeSubscriptionDialog from "@/components/subscriptions/NonStripeSubscriptionDialog.vue";
import CannotAssignLicenseDialog from "@/components/subscriptions/CannotAssignLicenseDialog.vue";

export default {
  computed: {
    previousLicenses() {
      return this.subscription.userLicenses || 0;
    },

    userLicenses() {
      return this.subscription ? this.subscription.userLicenses : 0;
    },

    licensesRemaining() {
      return this.subscription?.licensesRemaining || 0;
    },

    companyLicenses() {
      return this.subscription?.companyLicenses || [];
    },

    addonLicense() {
      const addonFeatures = this.companyLicenses
        ? this.companyLicenses.filter(item => {
            return item.typeName.toUpperCase() === FINANCE_MANAGEMENT;
          })
        : [];

      return addonFeatures.length > 0 ? addonFeatures[0] : null;
    },

    userLicense() {
      const financeFeatures = this.companyLicenses
        ? this.companyLicenses.filter(item => {
            return item.typeName.toUpperCase() === USER_LIMIT;
          })
        : [];

      return financeFeatures.length > 0 ? financeFeatures[0] : null;
    }
  },
  methods: {
    assignLicense(user, confirmed = false) {
      if (confirmed) {
        this.$apollo
          .mutate({
            mutation: AssignUserLicenseMutation,

            variables: {
              input: {
                userId: user.id
              }
            }
          })
          .then(({ data }) => {
            const user = data.assignUserLicense.user;

            this.subscription.licensesRemaining--;
            this.gridApi().applyTransaction({
              update: [user]
            });
            this.refreshActions(user);

            this.$flash.success("License assigned successfully");
          })
          .catch(error => {
            this.$flash.error(gqlErrorMessage(error));
          });
      } else if (this.noLicensesAvailable && !this.subscription.isTrial) {
        if (this.subscription?.plan?.name?.toLowerCase() === "free") {
          this.$dialog.show(CannotAssignLicenseDialog).onOk(({ api }) => {
            api.hide();
          });
        } else if (!this.subscription?.managedByStripe) {
          this.$dialog.show(NonStripeSubscriptionDialog).onOk(({ api }) => {
            api.hide();
          });
        } else if (this.isCompanyOwner) {
          this.$dialog.show(PurchaseLicenseDialog).onOk(({ api }) => {
            api.hide();
            this.openSidebar("purchaseLicenseSidebar");
          });
        } else {
          this.$dialog.show(NoLicensesDialog);
        }
      } else {
        this.$dialog
          .show(AssignLicenseDialog, {
            props: {
              user,
              licensesRemaining: this.licensesRemaining,
              isTrial: this.subscription.isTrial
            }
          })
          .onOk(({ api }) => {
            api.hide();
            this.assignLicense(user, true);
          });
      }
    },
    revokeLicense(user) {
      this.$dialog
        .confirm({
          title: "Are you sure you want to revoke?",
          message: `If you revoke this license, "${user.name}" will no longer be able to access their account.
        You can re-assign a license at any time.`,
          confirmLabel: "Revoke license",
          cancelLabel: "Cancel",
          danger: true
        })
        .onOk(({ api }) => {
          this.$apollo
            .mutate({
              mutation: RevokeUserLicenseMutation,
              variables: {
                input: {
                  userId: user.id
                }
              }
            })
            .then(({ data }) => {
              api.hide();

              const { user } = data.revokeUserLicense;

              this.gridApi().applyTransaction({
                update: [user]
              });

              this.gridApi().refreshCells({
                rowNodes: [user],
                columns: ["actions"],
                force: true
              });
              this.subscription.licensesRemaining++;
              this.$flash.success("License revoked successfully");
            })

            .catch(error => {
              this.$flash.error(gqlErrorMessage(error));
            });
        });
    }
  }
};
