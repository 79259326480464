<template lang="pug">
  div.addon-tab
    .page.loader.text-center(v-if="loading")
      .circle
        .ring
    .text-grey-60(:class="loading ? 'opacity-25' : ''")
      .addon-tab-content(:class="loading ? 'invisible' : 'visible'")
        app-panel.panel.addon-item(secondary)
          #payment-form.subscription-card
            app-text-field(name="fullName",
                          class="mb-4",
                          label="Cardholder name",
                          type="text",
                          description="As it appears on the card",
                          v-model="billing.fullName",
                          :validator="$v.billing.fullName",
                          @blur="validateField('fullName')",
                          data-cy="fullName-input")

            label.mb-3(:class="errors.card.status == true ? 'text-withered-cherry font-bold' : ''",
                  for='card-element') Card number
            div
              #card-element.card-elements(data-cy="cardNumber-input")
              .text-right.text-withered-cherry.font-bold.mt-1.mb-4 {{ errors.card.status? errors.card.message : '' }}

            label(:class="errors.expiry.status == true ? 'text-withered-cherry font-bold' : ''",
                  for='expiry-element') Expiration date
            .text-sm.mb-3(:class="errors.expiry.status == true ? 'text-withered-cherry font-bold' : ''",
                  for='expiry-element') MM/YY
            div
              #expiry-element.card-elements(data-cy="expiry-input")
              .text-right.text-withered-cherry.font-bold.mt-1.mb-4 {{ errors.expiry.status ? errors.expiry.message : '' }}

            label(:class="errors.cvc.status == true ? 'text-withered-cherry font-bold' : ''",
                  for='cvc-element') CVV
            .text-sm.mb-3(:class="errors.cvc.status == true ? 'text-withered-cherry font-bold' : ''",
                  for='cvc-element') The 3/4 digit security number, usually found in the back of your card
            div
              #cvc-element.card-elements(data-cy="cvc-input")
              .text-right.text-withered-cherry.font-bold.mt-1.mb-4 {{ errors.cvc.status ? errors.cvc.message : '' }}

        .h-px.bg-grey-30.my-8.addon-item

        h2.addon-item.mb-8 Billing details

        app-panel.panel.addon-item(secondary)
          app-radio-field(name="billing-options",
                          :class="billingOptionClass"
                          label="Have billing details updated?",
                          :options="billingEditOptions",
                          v-model="option",
                          data-cy="billing-option-switch")

          template(v-if="billingOption == 1")
            app-text-field(name="firstName",
                            class="my-2",
                            label="First name",
                            type="text",
                            v-model="billing.billingForename",
                            :validator="$v.billing.billingForename",
                            :messages="messages",
                            @blur="validateField('billingForename')",
                            data-cy="firstName-input")

            app-text-field(name="lastName",
                          class="my-2",
                          label="Last name",
                          type="text",
                          :validator="$v.billing.billingSurname",
                          :messages="messages",
                          v-model="billing.billingSurname",
                          @blur="validateField('billingSurname')",
                          data-cy="lastName-input")

            app-dropdown-field(name="country",
                              label="Country",
                              value-attr="code",
                              label-attr="code",
                              v-model="country",
                              :validator="$v.country",
                              :messages="messages",
                              :options="availableCountries",
                              @input="$v.country.$touch()",
                              data-cy="country-input")

            app-text-field(name="address1",
                          class="my-2",
                          label="Address1",
                          type="text",
                          :validator="$v.billing.billingAddress1",
                          :messages="messages",
                          v-model="billing.billingAddress1",
                          @blur="validateField('billingAddress1')",
                          data-cy="address1-input")

            app-text-field(name="address2",
                          class="my-2",
                          label="Address2",
                          type="text",
                          :validator="$v.billing.billingAddress2",
                          :messages="messages",
                          v-model="billing.billingAddress2",
                          @blur="validateField('billingAddress2')",
                          data-cy="address2-input")

            app-text-field(name="citry",
                        class="my-2",
                        label="City",
                        type="text",
                        :validator="$v.billing.billingAddressCity",
                        :messages="messages",
                        v-model="billing.billingAddressCity",
                        @keyup.enter="onSubmit",
                        @blur="validateField('billingAddressCity')",
                        data-cy="city-input")

            app-text-field(name="postalCode",
                          class="my-2 no-bottom",
                          label="Postal code",
                          type="text",
                          :validator="$v.billing.billingAddressPostcode",
                          :messages="messages",
                          v-model="billing.billingAddressPostcode",
                          @blur="validateField('billingAddressPostcode')",
                          data-cy="postalCode-input")

        .addon-item.my-8
          app-button(primary
            class="w-full text-center focus:outline-none focus:shadow-outline mt-1 mb-4"
            @click.prevent="onSubmit()"
            data-cy="payment-submit"
          ) Change payment method
          a(href="#", @click.prevent="$emit('close')") Cancel

</template>

<script>
/* global $, Stripe */
/* eslint-disable */

import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { INPUT_TYPE_ERROR } from "@/config/help_messages/SubscriptionMessages";
import { STRIPE_ELEMENT_STYLE } from "@/config/constants";

export default {
  name: "PaymentTab",
  components: {},
  props: {
    subscription: {
      type: Object,
      required: true
    },
    availableCountries: {
      type: Array,
      required: true
    },
    cardData: {
      type: Object,
      required: true
    },
    billingOption: {
      type: Number,
      required: true
    }
  },
  data() {
    const {
      billingForename,
      billingSurname,
      billingAddress1,
      billingAddress2,
      billingAddressPostcode,
      billingAddressCountry,
      billingAddressCity
    } = this.subscription;

    return {
      card: {
        username: this.cardData.username,
        number: null,
        cvv: null,
        expiry: null
      },
      billing: {
        fullName: this.fullName(billingForename, billingSurname),
        billingForename: billingForename,
        billingSurname: billingSurname,
        billingAddress1: billingAddress1,
        billingAddress2: billingAddress2,
        billingAddressPostcode: billingAddressPostcode,
        billingAddressCountry: billingAddressCountry,
        billingAddressCity: billingAddressCity
      },
      country:
        billingAddressCountry && billingAddressCountry.code
          ? billingAddressCountry.code
          : null,
      messages: {
        maxLength: "{label} must contain less than {max} characters",
        required: "{label} is required",
        validField: "{label} is not valid"
      },
      billingEditOptions: [
        {
          name: "Yes",
          value: 1
        },
        {
          name: "No",
          value: 0
        }
      ],
      option: this.billingOption,
      stripe: null,
      form: null,
      elements: {
        card: null,
        expiry: null,
        cvc: null,
        ready: {
          card: false,
          expiry: false,
          cvc: false
        }
      },
      loading: true,
      errors: {
        card: {
          status: false,
          message: "Card number is required!",
          label: "Card number"
        },
        expiry: {
          status: false,
          message: "Expiration date is required!",
          label: "Expiration date"
        },
        cvc: {
          status: false,
          message: "CVV is required!",
          label: "CVV"
        }
      }
    };
  },
  validations() {
    return {
      billing: {
        fullName: { required },
        billingForename: { required, maxLength: maxLength(30) },
        billingSurname: { required, maxLength: maxLength(30) },
        billingAddress1: { required },
        billingAddressPostcode: { required, maxLength: maxLength(10) },
        billingAddressCity: { required }
      },
      country: { required }
    };
  },
  created() {
    this.form = $("#payment-form");

    const vm = this;
    let script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/";
    document.body.append(script);

    script.onload = () => {
      this.loading = false;

      vm.stripe = Stripe(this.subscription.stripePublishKey);
      vm.setupPaymentForm();
    };
  },
  computed: {
    stripeDetails() {
      return {
        name: `${this.billing.fullName}`,
        address_line1: this.billing.billingAddress1 || "",
        address_line2: this.billing.billingAddress2 || "",
        address_city: this.billing.billAddressCity || "",
        address_state: this.billing.billingAddressRegion || "",
        address_zip: this.billing.billingAddressPostcode || "",
        address_country: this.country || ""
      };
    },
    billingOptionClass() {
      return this.billingOption == 1 ? "mb-8" : "no-bottom";
    },
    isCardError() {
      const { elements, errors } = this;
      return (
        (elements.card && elements.card._empty) || errors.card.status == true
      );
    },
    isExpiryError() {
      const { elements, errors } = this;
      return (
        (elements.expiry && elements.expiry._empty) ||
        errors.expiry.status == true
      );
    },
    isCvcError() {
      const { elements, errors } = this;
      return (elements.cvc && elements.cvc._empty) || errors.cvc.status == true;
    }
  },
  watch: {
    option: {
      immediate: true,
      handler() {
        this.$emit("set-option", this.option);
      }
    }
  },
  methods: {
    onSubmit() {
      const {
        ready: { cvc, card, expiry }
      } = this.elements;

      this.$v.billing.$touch();
      this.$v.country.$touch();
      this.touchStripeElements();

      if (
        this.errors.expiry.status ||
        this.errors.expiry.status ||
        this.errors.expiry.status
      ) {
        this.$flash.error(INPUT_TYPE_ERROR);
      } else {
        if (this.showBillingEditForm == 1) {
          if (
            this.$v.billing.$invalid ||
            (this.$v.country && this.$v.country.$invalid)
          ) {
            this.$flash.error(INPUT_TYPE_ERROR);
          } else {
            this.stripe
              .createToken(this.elements.card, this.stripeDetails)
              .then(result => {
                this.$emit("update-card", result);
              });
          }
        } else {
          this.stripe.createToken(this.elements.card).then(result => {
            this.$emit("update-card", result);
          });
        }
      }
    },
    validateCardField(fieldName) {
      if (this.$v.card[fieldName]) {
        this.$v.card[fieldName].$touch();
      }
    },
    validateField(fieldName) {
      if (this.$v.billing[fieldName]) {
        this.$v.billing[fieldName].$touch();
      }
    },
    updateReadyState() {
      const { card, cvc, expiry } = this.elements.ready;

      if (card && cvc && expiry) {
        this.loading = false;
      }
    },
    checkCardValidation(e, type, eventType = "change") {
      const isComplete =
        eventType === "change" ? e.complete : this.elements[type]._complete;
      const isEmpty =
        eventType === "change" ? e.empty : this.elements[type]._empty;

      if (!isComplete) {
        this.errors[type].status = true;
        document
          .getElementById(`${type}-element`)
          .classList.add("border-withered-cherry");

        if (isEmpty === true) {
          this.errors[type].message = `${this.errors[type].label} is required`;
        } else {
          this.errors[type].message = `${this.errors[type].label} is invalid`;
        }
      } else {
        this.errors[type].status = false;
        document
          .getElementById(`${type}-element`)
          .classList.remove("border-withered-cherry");
      }
    },
    setupPaymentForm() {
      const vm = this;
      const elements = this.stripe.elements();
      const baseInputStyles = STRIPE_ELEMENT_STYLE;

      this.elements.card = elements.create("cardNumber", {
        style: baseInputStyles
      });
      this.elements.expiry = elements.create("cardExpiry", {
        style: baseInputStyles
      });
      this.elements.cvc = elements.create("cardCvc", {
        style: baseInputStyles
      });

      this.elements.card.on("ready", () => {
        vm.elements.ready.card = true;
        vm.updateReadyState();
      });

      this.elements.card.on("blur", e => {
        this.checkCardValidation(e, "card", "blur");
      });
      this.elements.card.on("change", e => {
        this.checkCardValidation(e, "card", "change");
      });

      this.elements.expiry.on("ready", () => {
        vm.elements.ready.expiry = true;
        vm.updateReadyState();
      });

      this.elements.expiry.on("blur", e => {
        this.checkCardValidation(e, "expiry", "blur");
      });
      this.elements.expiry.on("change", e => {
        this.checkCardValidation(e, "expiry", "change");
      });

      this.elements.cvc.on("ready", () => {
        vm.elements.ready.cvc = true;
        vm.updateReadyState();
      });

      this.elements.cvc.on("blur", e => {
        this.checkCardValidation(e, "cvc", "blur");
      });
      this.elements.cvc.on("change", e => {
        this.checkCardValidation(e, "cvc", "change");
      });

      this.elements.card.mount("#card-element");
      this.elements.expiry.mount("#expiry-element");
      this.elements.cvc.mount("#cvc-element");
    },
    fullName(billingForename, billingSurname) {
      const name = billingForename ? billingForename : "";
      const fullName = billingSurname ? `${name} ${billingSurname}` : name;
      return fullName.trim() === "" ? "--" : fullName;
    },
    touchStripeElements() {
      if (this.isCardError) this.errors.card.status = true;
      if (this.isExpiryError) this.errors.expiry.status = true;
      if (this.isCvcError) this.errors.cvc.status = true;
    }
  }
};
</script>

<style lang="postcss" scoped>
.card-elements {
  background: var(--white);
  width: 100% !important;
}
.payment-loading {
  position: absolute;
  top: calc(50% - 80px);
  left: calc(50% - 60px);
}
.StripeElement--invalid .InputElement {
  color: @apply (text-withered-cherry);
}
.visible {
  opacity: 1;
}
.invisible {
  opacity: 0;
}
::v-deep {
  .multiselect .block {
    color: var(--grey-60);
  }
}
.multiselect .block {
  color: var(--grey-60);
}
.addon-tab-content label {
  display: inline-block;
  font-size: 16px;
}
</style>
