<template lang="pug">
  div.addon-tab.bg-white.checkout-sidebar.mb-8
    .page.loader.text-center(v-if="loading")
      .circle
        .ring
    app-panel(compact :class="loading ? 'opacity-25' : ''")
      .flex.flex-row.justify-between.flex-initial.pt-8.px-8.p-4
        .flex-1.overflow-hidden
          h2.font-bold Subscription summary
          .text-grey-60 {{checkout.checkoutType}} plan

      .text-center.my-8.mt-4
        app-toggle-button(:options="checkout.billingOptions",
                          v-model="checkout.billingType"
                          data-cy="billing-type-switch")

      .addon-tab-content
        .addon-item
          .flex.flex-row.justify-between.flex-initial
            .flex-1.p-4
              .text-grey-80.font-bold {{licenseLabel}}
              span.text-sm.text-grey-60 {{billingLabel}}
            .flex.flex-initial.p-4
              span {{checkout.currency}}{{normalizePrice(totalLicensePrice)}}
          .flex.flex-row.justify-between.flex-initial.mt-4(v-if="checkout.financeManagementStatus")
            .flex-1.p-4
              .text-grey-80.font-bold Finance Management
              span.text-sm.text-grey-60 {{billingLabel}}
            .flex.flex-initial
              span.p-4 {{checkout.currency}}{{normalizePrice(totalFinanceManagementPrice)}}
          //- .flex.flex-row.justify-between.flex-initial.mt-4
          //-   .flex-1
          //-     .text-grey-80.font-bold Document Storage
          //-     span.text-sm.text-grey-60 {{billingLabel}}
          //-   .flex.flex-initial
          //-     span {{checkout.currency}}{{totalDocumentPrice}}
          .h-px.bg-grey-30.my-4
          .mt-4.p-4(v-if="checkout.voucher === null")
            .block.text-grey-60.text-lg.mb-3 Discount code
            .flex
              app-text-input.mr-2.flex-1(
                v-if="isDiscountFocus"
                data-cy="discount-code-input"
                v-model="discountCode"
                v-focus:set="isDiscountFocus"
                @keyup.enter="applyCode"
                @keyup.esc="editing = false"
              )
              app-text-input.mr-2.flex-1(
                v-else="isDiscountFocus"
                data-cy="discount-code-input"
                v-model="discountCode"
                @keyup.enter="applyCode"
                @keyup.esc="editing = false"
              )
              app-button.border-tribal-aqua.text-tribal-aqua.flex-initial.text-center(
                class="hover:bg-diving-sapphire hover:border-diving-sapphire hover:text-white"
                @click.prevent="applyCode"
              ) Apply
          .mt-4(v-else)
            .flex.p-4.bg-grey-20
              .flex-1
                .font-bold {{ checkout.voucher ? checkout.voucher.name : '' }}
                span.text-sm {{voucherDescription}}
              .flex-initial.my-auto
                a(href="#", @click.prevent="removeVoucher()") Remove

        .h-px.bg-grey-30.my-4.addon-item
        .addon-item.text-grey-60.p-4
          .flex.flex-row.justify-between.flex-initial.mt-3
            .flex-1
              .text-grey-60 Subtotal
            .flex-initial {{checkout.currency}}{{normalizePrice(subTotal)}}
          .flex.flex-row.justify-between.flex-initial.mt-3(v-if="checkout.billingType.value === 'a'")
            .flex-1
              .text-grey-60 Annual savings
            .flex-initial.text-subatomic-sky -{{checkout.currency}}{{normalizePrice(annualSavings)}}
          .flex.flex-row.justify-between.flex-initial.mt-3(v-if="checkout.voucher")
            .flex-1
              .text-grey-60 Discount voucher
            .flex-initial.text-subatomic-sky -{{checkout.currency}}{{normalizePrice(voucherPrice)}}
          .flex.flex-row.justify-between.flex-initial.mt-3
            .flex-1
              span.text-grey-60 {{taxLabel}}
            .flex-initial {{checkout.currency}}{{normalizePrice(sum * checkout.taxRate.rate / 100)}}

          .h-px.bg-grey-30.my-4
          span.text-sm You will be charged {{checkout.currency}}{{normalizePrice(totalPrice)}} today. Your next bill will be on {{dueDate}}

          .flex.flex-row.justify-between.flex-initial.my-8
            .flex-1
              .font-bold.text-xl.text-grey-80 Total
            .flex-initial.font-bold.text-xl.text-grey-80 {{checkout.currency}}{{normalizePrice(totalPrice)}}

        .addon-item.mb-4.pb-4.p-4
          app-button(primary
            class="w-full text-center justify-center focus:outline-none focus:shadow-outline mt-1 mb-2"
            @click.prevent="$emit('submit')"
            data-cy="checkout-sidebar-submit"
            :icon="icon !== null ? icon : ''"
          )
            span {{buttonText}}
          .mt-1.text-sm.text-center(v-if="footerText !== null") {{footerText}}
</template>
<script>
/* global $D */
/* eslint-disable */
import CheckoutWatchers from "@/mixins/CheckoutWatchers";
import GetVoucher from "@/graphql/queries/subscription/GetVoucher.gql";
import FiananceMixin from "@/mixins/FinanceValidator";
import { taxLabel } from "@/helpers/SubscriptionHelpers";

const NUMBER_OF_MONTH = 12;

export default {
  components: {},
  mixins: [CheckoutWatchers, FiananceMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    footerText: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      checkout: { ...this.value },
      discountCode: null,
      loading: false,
      isDiscountFocus: this.$parent.$parent.$parent.currentStep == "Add-ons"
    };
  },
  computed: {
    licenseLabel() {
      const { userLicenses } = this.checkout;
      const licensePluralFormat = userLicenses > 1 ? "licenses" : "license";
      return `${userLicenses} user ${licensePluralFormat}`;
    },
    billingLabel() {
      return this.checkout.billingType.value === "m"
        ? "Billing monthly"
        : "Billing annually";
    },
    totalLicensePrice() {
      const { userLicenses, userLicensePrice, billingType } = this.checkout;
      if (billingType.value === "m") {
        return userLicenses * userLicensePrice;
      }

      return userLicenses * userLicensePrice * NUMBER_OF_MONTH;
    },
    totalDocumentPrice() {
      const {
        documentStoragePrice,
        documentStorageLimit,
        documentStorageUnit,
        billingType
      } = this.checkout;

      const monthly_price =
        (documentStoragePrice * documentStorageLimit) / documentStorageUnit;
      return billingType.value === "m"
        ? monthly_price
        : monthly_price * NUMBER_OF_MONTH;
    },
    totalFinanceManagementPrice() {
      const {
        financeManagementPrice,
        billingType,
        financeManagementAnnualPrice
      } = this.checkout;
      return billingType.value === "m"
        ? financeManagementPrice
        : financeManagementAnnualPrice;
    },
    subTotal() {
      const { financeManagementStatus } = this.checkout;

      const subTotal = financeManagementStatus
        ? this.totalFinanceManagementPrice
        : 0;
      return subTotal + this.totalLicensePrice + this.totalDocumentPrice;
    },
    annualSavings() {
      const { userLicenseAnnualPrice, userLicenses } = this.checkout;
      return this.totalLicensePrice - userLicenseAnnualPrice * userLicenses;
    },
    sum() {
      const { billingType, voucher } = this.checkout;
      const price =
        billingType.value === "m"
          ? this.subTotal
          : this.subTotal - this.annualSavings;

      return voucher ? price - this.voucherPrice : price;
    },
    totalPrice() {
      const { taxRate } = this.checkout;
      return (this.sum * (taxRate.rate + 100)) / 100;
    },
    dueDate() {
      const currentDate = moment();
      const interval =
        this.checkout.billingType.value === "a" ? NUMBER_OF_MONTH : 1;
      var futureMonth = moment(currentDate).add(interval, "M");
      var futureMonthEnd = moment(futureMonth).endOf("month");

      if (
        currentDate.date() != futureMonth.date() &&
        futureMonth.isSame(
          futureMonthEnd.format($D.fn.datepicker.momentDateFormat)
        )
      ) {
        futureMonth = futureMonth.add(interval, "d");
      }
      return futureMonth.format($D.fn.datepicker.momentDateFormat);
    },
    isPaymentButton() {
      return this.$store.state.wizardState.currentStep === "Payment"
        ? true
        : false;
    },
    voucherDescription() {
      const { voucher, currency } = this.checkout;
      if (voucher) {
        const discount = voucher.percentOff
          ? `${voucher.percentOff}%`
          : `${currency}${voucher.amountOff}`;
        return `${discount} discount for life`;
      }
      return null;
    },
    voucherPrice() {
      const { voucher, billingType } = this.checkout;
      if (voucher) {
        const price =
          billingType.value === "m"
            ? this.subTotal
            : this.subTotal - this.annualSavings;

        return voucher.percentOff
          ? (price * voucher.percentOff) / 100
          : voucher.amountOff;
      }
      return 0;
    },
    buttonText() {
      switch (this.$parent.$options.name) {
        case "CheckoutAddons":
          return "Continue to billing";
        case "CheckoutBilling":
          return "Continue to payment";
        case "CheckoutPayment":
          return "Make secure payment";
        default:
          return "Continue to payment";
      }
    },
    taxLabel() {
      return taxLabel(this.checkout.taxRate);
    }
  },
  methods: {
    applyCode() {
      if (!this.discountCode || this.discountCode === "") {
        this.$flash.error("Discount code shouldn't be empty");
      } else {
        this.loading = true;
        this.$apollo
          .query({
            query: GetVoucher,
            variables: {
              name: this.discountCode
            }
          })
          .then(({ data: { voucher } }) => {
            if (voucher && voucher.valid) {
              this.checkout.voucher = voucher;
            }
            this.loading = false;
          })
          .catch(error => {
            this.$flash.error("Discount code is invalid");
            this.loading = false;
          });
      }
    },
    removeVoucher() {
      this.checkout.voucher = null;
    }
  }
};
</script>
<style lang="postcss" scoped>
.checkout-sidebar {
  position: sticky;
  top: 0;

  &::-webkit-scrollbar {
    width: 3px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.addon-tab-content {
  .addon-item {
    /* margin-left: 2rem;
    margin-right: 2rem;
    &.panel {
      @apply bg-grey-10;
    } */
  }
}
</style>
