<template lang="pug">
  .flex.flex-col
    app-header Timescale

    .mt-8
      app-radio-field(name="isDependent" v-model="isDependent" label="Does this task depend on other tasks?" :options="yesNoOptions")

    .my-4(v-if="isDependent == 'yes'" class="w-2/3")
      app-dropdown-field(name="dateCalculationType" attribute="date calculation type" label="Type of dependency" description="This determines how the start date of the task is calculated"
                    v-model="task.dateCalculationType" :options="dependencyTypes" track-by="value" value-attr="value" label-attr="name")
        template(v-slot:option="type")
          .text-grey-80.pb-2 {{ type.name }}

    .my-4(v-if="isDependent == 'yes'" class="w-2/3")
      app-dropdown-field(name="dependecyIds" label="Dependencies" description="The tasks that this task depends on." :multiple="true" :close-on-select="false"
                    v-model="task.dependencyIds" :options="parentTasks" track-by="id" value-attr="id" label-attr="name")
        template(v-slot:selection="{ values }")
          | {{ values.length }} tasks selected

    .my-4(v-else-if="!hasSubtasks" class="w-2/3")
      app-date-field(name="startsAt" label="Start date" v-model="task.startsAt" :messages="messages" :disable="disabledDates()")

    .my-4(v-if="!hasSubtasks" class="w-2/3")
      app-date-field(name="deadline" label="Deadline" v-model="task.deadline" :messages="messages" :disable="disabledDates()")

    .mt-8
      app-radio-field(name="enableReminders" v-model="enableReminders" label="do you want to enable reminders?"
                description="Do you want reminder emails sending when the task deadline is approaching?" :options="yesNoOptions")

</template>

<script>
import { disabledDates, isNotWorkingDay } from "@/helpers/DateHelpers";
import TaskDependencyOptions from "@/graphql/queries/core/tasks/TaskDependencyOptions.gql";

import TaskWatchersMixin from "@/mixins/TaskWatchers.js";

// const WEEK_DAYS = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

export default {
  mixins: [TaskWatchersMixin],

  props: {
    value: {
      type: Object,
      default: () => {}
    },
    hasDependencies: {
      type: Boolean,
      default: false
    },
    hasSubtasks: {
      type: Boolean,
      default: false
    },
    workingWeek: {
      type: Object,
      required: true
      // validator: ww => WEEK_DAYS.every(day => ww[day] !== undefined)
    }
  },

  apollo: {
    parentTasks: {
      query: TaskDependencyOptions,
      update({ dependencyOptions }) {
        return dependencyOptions;
      },
      variables() {
        const { projectId, parentId, id } = this.task;
        return { projectId, parentId, id };
      }
    }
  },

  data() {
    const task = { ...this.value };

    return {
      task,
      messages: {
        isValidDate: "Must be a valid date",
        beforeDeadline: 'Must be the same as or before "Deadline"',
        afterStartDate: 'Must be the same as or after "Start date"'
      },
      isDependent: null,
      dependecyIdsBackup: task.dependencyIds || [],
      yesNoOptions: [
        { name: "No", value: "no" },
        { name: "Yes", value: "yes" }
      ],
      dependencyTypes: [
        { name: "Start to start", value: "start_to_start" },
        { name: "Start to end", value: "start_to_end" },
        { name: "End to start", value: "end_to_start" },
        { name: "End to end", value: "end_to_end" }
      ]
    };
  },

  computed: {
    enableReminders: {
      get() {
        return this.task.enableReminders ? "yes" : "no";
      },

      set(value) {
        this.task.enableReminders = value == "yes";
      }
    }
  },

  watch: {
    hasDependencies: {
      handler(value) {
        this.isDependent = value ? "yes" : "no";
      },
      immediate: true
    },
    isDependent(value) {
      if (value === "yes") {
        this.task.dateIsCalculated = true;
        this.task.dependencyIds = this.dependecyIdsBackup;
      } else {
        this.task.dateIsCalculated = false;
        this.dependecyIdsBackup = this.task.dependencyIds;
        this.task.dependencyIds = [];
      }
    }
  },

  methods: {
    disabledDates() {
      if (this.$route.name === "editTaskWizard") {
        return [isNotWorkingDay(this.workingWeek)];
      } else {
        return disabledDates(this.workingWeek);
      }
    }
  }
};
</script>
