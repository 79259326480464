<template lang="pug">
  app-dialog.upload(@cancel="$emit('cancel', false)")
    template(#header)
      app-header
        | Upload a new document
        template(#subheader) to {{ uploadPath }}

    .uploads.flex.flex-row
      directory-listing.flex-initial.relative(:folder="currentFolder", :project="project", class="w-1/3")
      .h-full.flex-1.border-l.border-grey-30
        .filepond(ref="filepond")

    template(#footer)
      .flex.flex-row.items-center.justify-between
        .flex-initial.ml-2
          app-button(@click="addFolder()" icon="addFolder") Add folder
        .flex-initial.mr-2
          app-button(@click="$emit('cancel')") Close
</template>

<script>
import { mapState } from "vuex";
import DirectoryListing from "@/components/elements/DirectoryListing.vue";
import documentUploadModal from "@/store/root/modules/documentUploadModal";
import DocumentManager from "./DocumentManager";

export default {
  components: {
    DirectoryListing
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isShowing: this.value
    };
  },
  beforeCreate() {
    this.$store.registerModule("documentUploadModal", documentUploadModal);
  },
  created() {
    this.$store.commit("documentUploadModal/setActiveFolder", {
      folder: this.folder
    });
  },
  beforeDestroy() {
    this.$store.dispatch("documentManagement/uploadModalHidden");
    this.$store.unregisterModule("documentUploadModal");
  },
  mounted() {
    this.$store.dispatch(
      "documentManagement/mountUploadUI",
      this.$refs.filepond
    );
    this.$store.dispatch("documentManagement/uploadModalShown");
  },
  methods: {
    addFolder() {
      const parent = this.activeFolder.isRoot ? null : this.activeFolder;

      this.docManager.createFolder(parent).then(folder => {
        this.$store.commit("documentUploadModal/addNewFolder", folder);
      });
    }
  },
  computed: {
    ...mapState("documentManagement", ["project", "currentFolder"]),
    ...mapState("documentUploadModal", ["folders", "activeFolder"]),

    docManager() {
      return new DocumentManager(this.project);
    },

    uploadPath() {
      if (!this.activeFolder) {
        return "/";
      }

      return this.activeFolder.path;
    }
  }
};
</script>

<style lang="postcss" scoped>
.upload.dialog {
  width: 750px;

  .uploads {
    height: 250px;
  }
}
</style>
