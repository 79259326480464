<template>
  <div class="terms-and-service-content m-3">
    <h1>Terms and Conditions ("Terms")</h1>
    <p>V1.6 - dated 28 September 2018</p>
    <p>
      Please read these Terms and Conditions ("Terms", "Terms and Conditions")
      carefully before using our platform at https://app.manageplaces.com and
      any Websites and services (the "Services") operated by ManagePlaces
      Limited ("us", "we", or "our").
    </p>
    <p>
      Your access to and use of the Services is conditioned on your acceptance
      of and compliance with these Terms. These Terms apply to all visitors,
      users and others who access or use the Services.
    </p>
    <p>
      By accessing or using the Services you agree to be bound by these Terms.
      If you disagree with any part of the Terms then you may not access the
      Services.
    </p>
    <p><strong>Subscriptions</strong></p>
    <p>
      Some parts of the Services are billed on a subscription basis
      ("Subscription(s)"). You will be billed in advance on a recurring and
      periodic basis ("Billing Cycle"). Billing cycles are set either on a
      monthly or annual basis, unless otherwise notified, depending on the type
      of subscription plan you select when purchasing a Subscription and any
      promotional offers.
    </p>
    <p>
      At the end of each Billing Cycle, your Subscription will automatically
      renew under the exact same conditions unless you, or we, cancel it.
      Account owners may cancel a Subscription by contacting us through our
      customer support team or designated account managers with a written
      request which can also be electronic. The notice period for such
      cancellation request is 30 calendar days. If a payment is due within the
      notice period, this will be applied in full unless agreed by both parties.
    </p>
    <p>
      A valid payment method, including credit card, payment gateway/online or
      invoice payment, is required to process the payment for your Subscription.
      You shall provide ManagePlaces Limited with accurate and complete billing
      information including full name, address, state, post/zip code, telephone
      number, and a valid payment method information. By submitting such payment
      information, you automatically authorise us to charge all Subscription
      fees incurred through your account to any such payment instruments.
    </p>
    <p>
      Should automatic billing fail to occur for any reason, we will issue an
      electronic invoice indicating that you must proceed manually, within a
      certain deadline date, with the full payment corresponding to the billing
      period as indicated on the invoice.
    </p>
    <p><strong>Free Trial</strong></p>
    <p>
      ManagePlaces Limited may, at its sole discretion, offer a Subscription
      with a free trial for a limited period of time ("Free Trial").
    </p>
    <p>
      You may be required to enter your billing information in order to sign up
      for the Free Trial.
    </p>
    <p>
      If you do enter your billing information when signing up for the Free
      Trial, you will not be charged by us until the Free Trial has expired. On
      the last day of the Free Trial period, unless you cancelled your
      Subscription, you will be automatically charged the applicable
      Subscription fees for the type of Subscription you have selected.
    </p>
    <p>
      At any time and without notice, we reserve the right to (i) modify the
      terms and conditions of the Free Trial offer, or (ii) cancel such Free
      Trial or the offer of such Free Trial.
    </p>
    <p><strong>Fee Changes</strong></p>
    <p>
      ManagePlaces Limited, in its sole discretion and at any time, may modify
      the Subscription fees for the Subscriptions. Any Subscription fee change
      will become effective at the end of the then-current Billing Cycle.
    </p>
    <p>
      We will provide you with a reasonable prior notice of any change in
      Subscription fees to give you an opportunity to terminate your
      Subscription before such change becomes effective.
    </p>
    <p>
      Your continued use of the Services after the Subscription fee change comes
      into effect constitutes your agreement to pay the modified Subscription
      fee amount.
    </p>
    <p><strong>Refunds</strong></p>
    <p>
      Except when required by law, paid Subscription fees are non-refundable.
    </p>
    <p><strong>Content</strong></p>
    <p>
      Our Services may allow you to post, link, store, share and otherwise make
      available certain information, text, graphics, videos, or other material
      ("Content"). You are responsible for the Content that you post to the
      Services, including its legality, reliability, and appropriateness.
    </p>
    <p>
      By posting Content to the Services, you grant us the right and licence to
      hold such Content on the Services. You retain any and all of your rights
      to any Content you submit, post or display on or through the Services and
      you are responsible for protecting those rights.
    </p>
    <p>
      You represent and warrant that: (i) the Content is yours (you own it) or
      you have the right to use it and grant us the rights and licence as
      provided in these Terms, and (ii) the posting of your Content on or
      through the Services does not violate the privacy rights, publicity
      rights, copyrights, contract rights or any other rights of any person.
    </p>
    <p><strong>Accounts</strong></p>
    <p>
      When you create an account with us, you must provide us information that
      is accurate, complete, and current at all times. Failure to do so
      constitutes a breach of the Terms, which may result in immediate
      termination of your account on our Services.
    </p>
    <p>
      You are responsible for safeguarding the password that you use to access
      the Services and for any activities or actions under your password,
      whether your password is with our Services or a third-party service.
    </p>
    <p>
      You agree not to disclose your password to any third party. You must
      notify us immediately upon becoming aware of any breach of security or
      unauthorised use of your account.
    </p>
    <p>
      You may not use as a username the name of another person or entity or that
      is not lawfully available for use, a name or trade mark that is subject to
      any rights of another person or entity other than you without appropriate
      authorisation, or a name that is otherwise offensive, vulgar or obscene.
      You expressly agree that we cannot be held liable for any loss or damage
      arising out of any misrepresentations you make in this regard.
    </p>
    <p><strong>Intellectual Property</strong></p>
    <p>
      The Services and their original content (excluding Content provided by
      users), features and functionality are and will remain the exclusive
      property of ManagePlaces Limited and its licensors. The Services are
      protected by copyright, trademark, and other laws of both the United
      Kingdom and foreign countries. Our trademarks and trade dress may not be
      used in connection with any product or service without the prior written
      consent of ManagePlaces Limited.
    </p>
    <p><strong>Links To Other Web Sites</strong></p>
    <p>
      Our Services may contain links to third-party Websites or services that
      are not owned or controlled by ManagePlaces Limited.
    </p>
    <p>
      ManagePlaces Limited has no control over, and assumes no responsibility
      for, the content, privacy policies, or practices of any third-party
      Websites or services. You further acknowledge and agree that ManagePlaces
      Limited shall not be responsible or liable, directly or indirectly, for
      any damage or loss caused or alleged to be caused by or in connection with
      use of or reliance on any such content, goods or services available on or
      through any such Websites or services.
    </p>
    <p>
      We strongly advise you to read the terms and conditions and privacy
      policies of any third-party Websites or services that you visit.
    </p>
    <p><strong>Termination</strong></p>
    <p>
      We may terminate or suspend your account if you breach the Terms but
      always subject to you being able to remedy such breach in which case the
      account be made readily available. Upon termination, your right to use the
      Services will immediately cease. If you wish to terminate your account,
      you may simply discontinue using the Services.
    </p>
    <p>
      All provisions of the Terms which by their nature should survive
      termination shall survive termination, including, without limitation,
      ownership provisions, warranty disclaimers, indemnity and limitations of
      liability.
    </p>
    <p>
      Without limiting the foregoing, we may, in our sole discretion, publish
      policies whereby we delete your account for prolonged inactivity.
    </p>
    <p>
      Upon any termination of these Terms, we will have no obligation to
      maintain or provide Your Data. If your team's account is deleted, we will
      delete or destroy all copies of Your Data in our possession or control, in
      a reasonably expedient way, unless legally prohibited.
    </p>
    <p>
      All accrued rights to payment shall survive termination of these Terms.
    </p>
    <p><strong>Indemnification</strong></p>
    <p>
      You agree to defend, indemnify and hold harmless ManagePlaces Limited and
      its licensee and licensors, and their employees, contractors, agents,
      officers and directors, from and against any and all claims, damages,
      obligations, losses, liabilities, costs or debt, and expenses (including
      but not limited to attorney's fees), resulting from or arising out of a)
      your use and access of the Services, by you or any person using your
      account and password; b) a breach of these Terms, or c) Content posted on
      the Services.
    </p>
    <p><strong>Limitation Of Liability</strong></p>
    <p>
      n no event shall ManagePlaces Limited, nor its directors, employees,
      partners, agents, suppliers, or affiliates, be liable for any indirect,
      incidental, special, consequential or punitive damages, including without
      limitation, loss of profits, data, use, goodwill, or other intangible
      losses, resulting from (i) your access to or use of or inability to access
      or use the Services; (ii) any conduct or content of any third party on the
      Services; (iii) any content obtained from the Services; and (iv)
      unauthorised access, use or alteration of your transmissions or content,
      whether based on warranty, contract, tort (including negligence) or any
      other legal theory, whether or not we have been informed of the
      possibility of such damage, and even if a remedy set forth herein is found
      to have failed of its essential purpose.
    </p>
    <p><strong>Disclaimer</strong></p>
    <p>
      Your use of the Services is at your sole risk. The Services are provided
      on an "AS IS" and "AS AVAILABLE" basis. The Services are provided without
      warranties of any kind, whether express or implied, including, but not
      limited to, implied warranties of merchantability, fitness for a
      particular purpose, non-infringement or course of performance.
    </p>
    <p>
      ManagePlaces Limited its subsidiaries, affiliates, and its licensors do
      not warrant that a) the Services will function uninterrupted, secure or
      available at any particular time or location; b) any errors or defects
      will be corrected; c) the Services are free of viruses or other harmful
      components (particularly for content provided by users); or d) the results
      of using the Services will meet your requirements.
    </p>
    <p><strong>Exclusions</strong></p>
    <p>
      Without limiting the generality of the foregoing and notwithstanding any
      other provision of these terms, under no circumstances will ManagePlaces
      Limited ever be liable to you or any other person for any indirect,
      incidental, consequential, special, punitive or exemplary loss or damage
      arising from, connected with, or relating to your use of the Services,
      these Terms, the subject matter of these Terms, the termination of these
      Terms or otherwise, including but not limited to personal injury, loss of
      data, business, markets, savings, income, profits, use, production,
      reputation or goodwill, anticipated or otherwise, or economic loss, under
      any theory of liability (whether in contract, tort, strict liability or
      any other theory or law or equity), regardless of any negligence or other
      fault or wrongdoing (including without limitation gross negligence and
      fundamental breach) by ManagePlaces Limited or any person for whom
      ManagePlaces Limited is responsible, and even if ManagePlaces Limited has
      been advised of the possibility of such loss or damage being incurred.
    </p>
    <p><strong>Governing Law</strong></p>
    <p>
      These Terms shall be governed and construed in accordance with the laws of
      England and Wales, without regard to its conflict of law provisions.
    </p>
    <p>
      Our failure to enforce any right or provision of these Terms will not be
      considered a waiver of those rights. If any provision of these Terms is
      held to be invalid or unenforceable by a court, the remaining provisions
      of these Terms will remain in effect. These Terms constitute the entire
      agreement between us regarding our Services, and supersede and replace any
      prior agreements we might have between us regarding the Services.
    </p>
    <p><strong>Changes</strong></p>
    <p>
      We reserve the right, at our sole discretion, to modify or replace these
      Terms at any time. If a revision is material, we will try to provide at
      least 15 days’ notice prior to any new terms taking effect. What
      constitutes a material change will be determined at our sole discretion.
    </p>
    <p>
      By continuing to access or use our Services after those revisions become
      effective, you agree to be bound by the revised terms. If you do not agree
      to the new terms, you must stop using the Services.
    </p>
    <p><strong>Privacy Policy and GDPR</strong></p>
    <p>
      Please refer to our Privacy Policy, available on our Website, for
      information on your privacy, our cookies, summary privacy information and
      provisions for EU’s GDPR and the Data Protection Act 2018. You agree that
      they constitute part of these terms depending on your locality and
      applicable laws. Your use of our Services implies agreement with our
      Privacy Policy.
    </p>
    <p><strong>Contact Us</strong></p>
    <p>
      If you have any questions about these Terms, please contact us through our
      Website
      <a href="https://www.manageplaces.com">https://www.manageplaces.com</a> or
      via email at
      <a href="mailto:support@manageplaces.com">support@manageplaces.com</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: "TermsAndService"
};
</script>

<style scoped>
.terms-and-service-content {
  max-height: 400px;
  overflow-y: auto;
}
</style>
