<template lang="pug">
  .tiny.basic.right.pointing.dark.ui.no-selection.column.dropdown.button
    app-icon(icon="columns")
    .menu
      .ui.item.checkbox(v-for='(value, key) in availableColumns', @click='itemClicked(key)')
        input(type='checkbox', :value='key', v-model='enabledColumns')
        label {{ value }}
</template>

<script>
export default {
  props: {
    availableColumns: {
      type: Object,
      required: true
    },
    enabledColumns: {
      type: Array,
      required: true
    }
  },
  methods: {
    itemClicked(key) {
      this.$emit("toggle", key);
    }
  }
};
</script>
