/* global $, $D */

import { gantt } from "dhtmlx-gantt";
import UpdateTasksMutation from "@/graphql/mutations/tasks/UpdateTasksGantt.gql";
import { runMutation } from "@/helpers/GraphQLHelpers";
import { setLockVersion } from "@/helpers/TaskHelpers";
import store from "@/store";
import { formatDateApi } from "@/helpers/DateHelpers";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

const LINK_TYPES = {
  0: "end_to_start",
  1: "start_to_start",
  2: "end_to_end",
  3: "start_to_end"
};

export default class ProjectGanttPersistenceManager {
  constructor(projectId, vm) {
    this.projectId = projectId;
    this.vm = vm;

    this._resetData();
  }

  taskUpdated(task) {
    this._ifAcceptingUpdates(() => {
      this.updatedTasks[task.id] = task;
    });
  }

  /**
   * Adds a new dependency link between two tasks.
   * This will trigger a server request immediately
   */
  addLink(link) {
    this._ifAcceptingUpdates(() => {
      this.createdLinks[link.id] = link;
    });
  }

  /**
   * Deletes an existing link between two tasks. This
   * will trigger a server request immediately
   */
  linkDeleted(link) {
    this._ifAcceptingUpdates(() => {
      this.deletedLinks.push(link);
    });
  }

  positionChanged(task) {
    this._ifAcceptingUpdates(() => {
      this.updatedPositions[task.id] = {
        id: task.id,
        position: gantt.getTaskIndex(task.id) + 1 // server is 1-indexed not 0-indexed
      };
    });
  }

  /**
   * Executes the provided function, whilst ignore any data
   * given to the persistence manager. If for example you
   * want to make a change to the data in the gantt but you
   * don't want the persistence manager to attempt to save
   * this on the server, you can use this
   *
   *   this.persistence.ignore(() => {
   *     <Your code goes here>
   *   })
   */
  ignore(fn) {
    this.ignoreUpdates = true;
    fn();
    this.ignoreUpdates = false;
  }

  /**
   * Saves the data that has been recorded to the server
   * when there are no further changes. This will setup
   * a short timer to wait for any further updates, but
   * if nothing new arrives it will send the data
   */
  saveWhenReady() {
    if (this.lockTimer) {
      clearTimeout(this.lockTimer);
    }
    return new Promise((resolve, reject) => {
      this.lockTimer = setTimeout(() => {
        this._save()
          .then(() => {
            resolve(Object.values(this.updatedTasks).concat(this.taskParents));
            this._resetData();
          })
          .catch(reject);
      }, 250);
    });
  }

  _ifAcceptingUpdates(fn) {
    if (!this.ignoreUpdates) {
      fn();
    }
  }

  _resetData() {
    this.lockTimer = null;
    this.ignoreUpdates = false;
    this.createdLinks = {};
    this.deletedLinks = [];
    this.updatedTasks = {};
    this.updatedPositions = {};
    this.taskParents = [];
  }

  _save() {
    store.commit("taskManagement/setReadOnly", true);
    this.vm.requestStarted();

    const lockVersion = store.state.taskManagement.lockVersion;
    const params = {
      project: { id: this.projectId },
      updatedTasks: [],
      createdLinks: this._formatLinks(Object.values(this.createdLinks)),
      deletedLinks: this.deletedLinks.map(l => ({ id: l.id })),
      updatedPositions: Object.values(this.updatedPositions)
    };

    // The gantt doesn't let us know about changes to parents which
    // is super helpful, so we'll explicitly add them. Unfortunately,
    // this won't add them only if they've changed, but will just add
    // them regardless of what happened
    this._injectDependentTasks();
    const updatedTasks = Object.values(this.updatedTasks);
    this.taskParents = this._getParentTasks();
    params.updatedTasks = this._formatTasks(
      updatedTasks.concat(this.taskParents)
    );

    // return Promise.resolve();

    return runMutation(UpdateTasksMutation, params, {
      lockVersion
    })
      .then(({ data }) => {
        this._processSaveResponse(data);
        setLockVersion(data.updateTasksGantt.project.lockVersion);
        store.commit("taskManagement/setReadOnly", false);
        this.vm.requestFinished();
        gantt.ext.undo.clearUndoStack();
      })
      .catch(err => {
        store.commit("taskManagement/setReadOnly", false);
        this.vm.requestFinished();

        const errorMessage = gqlErrorMessage(err);
        if (errorMessage === "Stale object error.") {
          $D.fn.loadingOverlay.show($("#gantt-container"));
        } else {
          this.vm.$flash.error(errorMessage);
          this.ignore(() => {
            gantt.ext.undo.undo();
            gantt.ext.undo.clearUndoStack();
          });
        }

        throw err;
      });
  }

  _formatLinks(links) {
    return links.map(link => ({
      id: link.id,
      sourceTask: {
        id: link.source
      },
      targetTask: {
        id: link.target
      },
      lag: link.lag,
      linkType: LINK_TYPES[link.type]
    }));
  }

  _formatTasks(tasks) {
    return tasks.map(task => this._formatTask(task));
  }

  _formatTask(task) {
    return {
      id: task.id,
      startsAt: formatDateApi(task.start_date),
      deadline: formatDateApi(task.end_date),
      plannedStart: formatDateApi(task.planned_start),
      plannedEnd: formatDateApi(task.planned_end),
      duration: task.duration,
      parentId: task.parent === 0 ? null : task.parent,
      dependencies: this._formatTaskLinks(task.$target),
      dependentIds: task.$source.map(id => gantt.getLink(id).target),
      position: gantt.getTaskIndex(task.id) + 1 // server is 1-indexed not 0-indexed
    };
  }

  _formatTaskLinks(targets) {
    return targets.map(linkID => {
      const link = gantt.getLink(linkID);
      const task = gantt.getTask(link.source);

      return {
        startsAt: formatDateApi(task.start_date),
        deadline: formatDateApi(task.end_date),
        lag: link.lag,
        linkType: LINK_TYPES[link.type]
      };
    });
  }

  _getParentTasks() {
    let parents = {};

    Object.values(this.updatedTasks).forEach(task => {
      gantt.eachParent(parent => {
        if (!parents[parent.id] && !this.updatedTasks[parent.id]) {
          parents[parent.id] = parent;
        }
      }, task.id);
    });

    return Object.values(parents);
  }

  _injectDependentTasks() {
    Object.values(this.updatedTasks).forEach(task => {
      gantt.eachSuccessor(dep => {
        if (!this.updatedTasks[dep.id]) {
          this.updatedTasks[dep.id] = dep;
        }
      }, task.id);
    });
  }

  _processSaveResponse({ updateTasksGantt }) {
    const { createdLinks } = updateTasksGantt;
    if (createdLinks && createdLinks.length) {
      // We've created new links, so we need to update
      // the ID's in the gantt
      createdLinks.forEach(newLink => {
        const { oldId, newId } = newLink;
        gantt.changeLinkId(oldId, newId);
      });
    }
  }
}
