<script>
export default {
  functional: true,

  props: {
    href: {
      type: String,
      required: false,
      default: null
    },

    primary: {
      type: Boolean,
      default: false
    },

    danger: {
      type: Boolean,
      default: false
    }
  },

  render(createElement, { props, slots, data, listeners }) {
    const classes = [
      "cursor-pointer",
      "inline-block",
      "underline",
      "hover:no-underline"
    ];

    let element = "div";
    const newData = {
      class: classes,
      on: listeners
    };

    if (props.href) {
      element = "a";
      newData.attrs = { href: props.href };
    }

    if (props.danger) {
      classes.push("text-withered-cherry");
    } else {
      classes.push("text-tribal-aqua");
    }

    return createElement(element, { ...data, ...newData }, slots().default);
  }
};
</script>
