import { caseCompare } from "@/helpers/StringHelpers";
import store from "@/store";

export const ACTIONS = {
  RESEND_INVITATION: "resendInvitation",
  EDIT: "edit",
  CHANGE_ROLE: "changeRole",
  ASSIGN_LICENSE: "assignLicense",
  REVOKE_LICENSE: "revokeLicense",
  DELETE: "delete"
};

export const userMenuItems = user => {
  let actions = [];
  const { userLicense, roleName, id } = user;
  const isOwner = caseCompare(roleName, "Owner");
  const currentUser = store.state.currentUser;

  if ((isOwner && currentUser.id === id) || !isOwner) {
    actions.push({ label: "Edit user", icon: "edit", action: ACTIONS.EDIT });
  }

  if (!isOwner) {
    actions.push({
      label: "Change role",
      icon: "padlock",
      action: ACTIONS.CHANGE_ROLE
    });
  }

  if (user.pending) {
    actions.push({
      label: "Resend invitation",
      icon: "refresh",
      action: ACTIONS.RESEND_INVITATION
    });
  }

  if (actions.length) {
    actions.push({ type: "divider" });
  }

  if (!isOwner) {
    if (userLicense) {
      actions.push({
        icon: "padlock",
        label: "Revoke license",
        action: ACTIONS.REVOKE_LICENSE
      });
    } else {
      actions.push({
        icon: "padlock",
        label: "Assign license",
        action: ACTIONS.ASSIGN_LICENSE
      });
    }

    actions.push({ type: "divider" });
  }

  if (!isOwner) {
    actions.push({
      label: "Delete",
      icon: "delete",
      iconColour: "red",
      action: ACTIONS.DELETE
    });
  }

  return actions;
};

export const bulkActions = [
  {
    label: "Delete",
    icon: "delete",
    iconColour: "red",
    action: ACTIONS.DELETE
  }
];
