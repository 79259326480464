<script>
import indexOf from "ramda/src/indexOf";

export default {
  props: {
    billingFrequency: {
      type: String,
      default: "ANNUALLY"
    }
  },
  data() {
    return {
      frequency: this.billingFrequency
    };
  },

  methods: {
    changeBillingFrequency(freq) {
      this.frequency = freq;
    },

    isBillingFrequency(frequency) {
      return this.frequency === frequency.toUpperCase();
    },

    billingFrequencyAvailable(available) {
      return indexOf(this.frequency, available) !== -1;
    }
  }
};
</script>
