import Vue from "vue";
import AppNotificationsMenu from "@/components/collections/AppNotificationsMenu.vue";
import AppUserMenu from "@/components/collections/AppUserMenu.vue";
import AppHelpMenu from "@/components/collections/AppHelpMenu.vue";
import AppSearch from "@/components/global/AppSearch.vue";

import ShowTask from "@/views/tasks/Task.vue";
import TaskApprovers from "@/components/tasks/TaskApprovers.vue";
import TaskChecklist from "@/components/tasks/TaskChecklist.vue";
import TaskPriority from "@/components/tasks/TaskPriority.vue";
import TaskStatus from "@/components/tasks/TaskStatus.vue";
import TaskTeam from "@/components/tasks/TaskTeam.vue";

import ProjectStatusChart from "@/components/projects/ProjectStatusChart.vue";
import ProjectTeam from "@/components/projects/ProjectTeam.vue";
import ProjectTasks from "@/components/projects/ProjectTasks.vue";

import DocumentsTable from "@/components/documents/DocumentsTable.vue";
import FormDocuments from "@/components/documents/FormDocuments.vue";
import DirectoryListing from "@/components/elements/DirectoryListing.vue";
import UploadBox from "@/components/elements/UploadBox.vue";

import UsersTable from "@/components/users/UsersTable.vue";
import SubscriptionPage from "@/views/subscription/index.vue";
import CheckoutPage from "@/components/checkouts/wizard/Wizard.vue";

import ProjectsIndex from "@/components/projects/index/Projects.vue";

import ProjectPhasesSettings from "@/components/settings/ProjectPhasesSettings.vue";

import RolesTable from "@/components/roles/roles_table/RolesTable.vue";
import RolesForm from "@/components/roles/RolesForm.vue";

import ChangeOrdersTable from "@/components/finance/ChangeOrdersTable.vue";
import PurchaseOrdersTable from "@/components/finance/PurchaseOrdersTable.vue";
import BillsTable from "@/components/finance/BillsTable.vue";
import InvoicesTable from "@/components/finance/InvoicesTable.vue";
import EstimatesTable from "@/components/finance/EstimatesTable.vue";

import { VPopover } from "v-tooltip";

export default () => {
  Vue.component("AppNotificationsMenu", AppNotificationsMenu);
  Vue.component("AppUserMenu", AppUserMenu);
  Vue.component("AppHelpMenu", AppHelpMenu);
  Vue.component("AppSearch", AppSearch);

  Vue.component("Task", ShowTask);
  Vue.component("TaskApprovers", TaskApprovers);
  Vue.component("TaskChecklist", TaskChecklist);
  Vue.component("TaskPriority", TaskPriority);
  Vue.component("TaskStatus", TaskStatus);
  Vue.component("TaskTeam", TaskTeam);

  Vue.component("ProjectsIndex", ProjectsIndex);

  Vue.component("ProjectStatusChart", ProjectStatusChart);
  Vue.component("ProjectEditTeam", ProjectTeam);
  Vue.component("ProjectTasks", ProjectTasks);

  Vue.component("DocumentsTable", DocumentsTable);
  Vue.component("FormDocuments", FormDocuments);
  Vue.component("directory-listing", DirectoryListing);
  Vue.component("upload-box", UploadBox);

  Vue.component("UsersTable", UsersTable);
  Vue.component("checkout-page", CheckoutPage);
  Vue.component("subscription-page", SubscriptionPage);

  Vue.component("ProjectPhasesSettings", ProjectPhasesSettings);

  Vue.component("RolesTable", RolesTable);
  Vue.component("RolesForm", RolesForm);

  Vue.component("ChangeOrdersTable", ChangeOrdersTable);
  Vue.component("PurchaseOrdersTable", PurchaseOrdersTable);
  Vue.component("BillsTable", BillsTable);
  Vue.component("InvoicesTable", InvoicesTable);
  Vue.component("EstimatesTable", EstimatesTable);

  Vue.component("v-popover", VPopover);
};
