<template lang="pug">
  .tasks.h-full.flex.flex-col.overflow-hidden(v-show="activeTab === 'tasks'")
    tasks-table(ref="table" :project="project" :project-id="projectId" :active-task="currentTask" v-on="viewListeners('table')" :views="views" :is-visible="activeTab === 'tasks'")
      template(#buttons v-if="project && project.canCreateTask")
        app-split-button.ml-4(@click="addTask" :options="addTaskOptions") Add task

      template(#gantt="{ visible, searchTerm }")
        gantt(ref="gantt", :project-id="projectId", :project="project" :is-visible="visible" :search="searchTerm", :active-task="currentTask", v-on="viewListeners('gantt')")

    app-sidebar-container(:value="sidebarProps.isOpen")
      // task-sidebar(:taskId="sidebarProps.taskId" :reload="sidebarProps.reload" v-on="actionsCallers")
      task-sidebar(:task-id="sidebarProps.taskId" :reload="sidebarProps.reload" v-on="sidebarListeners" :renaming="sidebarProps.renaming")
</template>

<script>
/* global $D */

import TasksTable from "@/components/projects/ProjectTasksTable.vue";
import Gantt from "@/components/projects/ProjectGantt.vue";
import { mapGetters, mapState } from "vuex";
import AppTransition from "@/components/containers/AppTransition.vue";
import ProjectQuery from "@/graphql/queries/core/projects/ProjectTasksSummary.gql";
import TaskSidebar from "@/components/tasks/sidebar/TaskSidebar.vue";
import TaskSidebarMixin from "@/mixins/tasks/TaskSidebarMixin";
import TabWatcherMixin from "@/mixins/TabWatcherMixin";

export default {
  components: {
    AppTransition,
    TasksTable,
    TaskSidebar,
    Gantt
  },
  mixins: [TaskSidebarMixin, TabWatcherMixin],
  props: {
    projectId: {
      type: [Number, String],
      required: true
    },
    lockVersion: {
      type: Number,
      required: true
    }
  },
  apollo: {
    project: {
      query: ProjectQuery,
      variables() {
        return {
          id: this.projectId
        };
      },
      fetchPolicy: "no-cache"
    }
  },
  data() {
    return {
      project: null,
      layout: { value: "table", label: "Table", icon: "table" },
      searchTerm: "",
      currentTask: null,
      taskBeingMoved: null,
      addTaskOptions: [
        { label: "Add task", icon: "task", type: "task" },
        { label: "Add milestone", icon: "milestone", type: "milestone" }
      ],
      views: [
        {
          value: "table",
          label: "Table",
          icon: "table",
          wide: true
        },
        {
          value: "gantt",
          label: "Gantt",
          icon: "gantt",
          slot: "gantt",
          wide: true
        }
      ]
    };
  },
  computed: {
    ...mapState(["activeTab"]),
    ...mapState("taskManagement", ["sidebarProps", "readOnly"]),
    ...mapGetters("taskManagement", ["actionsCallers"]),
    sidebarListeners() {
      const vm = this;

      return {
        close() {
          vm.currentTask = null;
          vm.closeSidebar();
          vm.currentTask = null;
          vm.setSidebarReload(false);
          window.location.hash = "#/tasks";
        },
        move(task) {
          vm.taskBeingMoved = task;
          $D.fire("tasks:move", { id: task.id });
        },
        moved(newParentId) {
          vm.notifyViews("taskMoved", {
            task: vm.taskBeingMoved,
            newParentId
          });
        },
        deleted(task) {
          vm.notifyViews("tasksDeleted", [task]);
          vm.closeSidebar();
          vm.setSidebarReload(false);
        },
        renamed(task) {
          vm.notifyViews("taskRenamed", task);
        },
        "tasks-updated"(tasks) {
          vm.notifyViews("tasksUpdated", tasks);
        },
        reloaded() {
          vm.setSidebarReload(false);
        }
      };
    }
  },
  watch: {
    layout: {
      immediate: true,
      handler(newLayout) {
        this.$store.commit(
          "taskManagement/setSelectedTasksLayout",
          newLayout?.value
        );
      }
    }
  },
  mounted() {
    this.$store.commit("taskManagement/setLockVersion", this.lockVersion);
    $D.on("tasks:moved", evt => {
      if (evt.data.lockVersion) {
        this.$store.commit(
          "taskManagement/setLockVersion",
          evt.data.lockVersion
        );
      }

      if (this.taskBeingMoved) {
        this.notifyViews("taskMoved", {
          task: this.taskBeingMoved,
          newParentId: evt.data.parent_id
        });
      }
    });

    // const taskHash = this.$route.hash.split("/");
    // const tab = taskHash[1];
    // const taskId = taskHash[2];

    // if (tab === "tasks" && taskId === "gantt") {
    //   this.layout = this.layouts[1];
    // }
  },
  methods: {
    /**
     *  Initiates the task creation process, by delegating
     *  it to the active view
     **/
    addTask(opt) {
      if (!this.readOnly) {
        const type = opt ? opt.type : "task";
        const component = this.$refs[this.layout.value];
        component.addTask(null, type === "milestone");
      }
    },
    isLayout(layout) {
      if (!this.layout) {
        return false;
      }

      return this.layout.value === layout;
    },
    notifyViews(evt, data, triggerLayout = null) {
      this.views.forEach(layout => {
        if (!triggerLayout || triggerLayout !== layout.value) {
          const component = this.$refs[layout.value];
          component[evt](data);
        }
      });
    },
    tabChanged(evt) {
      const tab = evt.detail.tab;
      if (tab !== "tasks") {
        // Make sure our sidebar gets hidden if we move tabs
        this.closeSidebar();
      }
    },
    viewListeners(layout) {
      const vm = this;
      return {
        "move-task"(task) {
          vm.taskBeingMoved = task;
          $D.fire("tasks:move", task);
        },
        /**
         *
         **/
        "open-task"(task) {
          const { renaming } = task;
          delete task.renaming;

          vm.currentTask = task;
          vm.openSidebar(task.id, renaming);
        },

        "task-created"(task) {
          vm.notifyViews("taskCreated", task, layout);
        },

        "task-moved"(details) {
          vm.notifyViews("taskMoved", details, layout);
        },

        /**
         *  Ensures the sidebar is closed if the deleted was being
         *  viewed. Notifies the other views that tasks have been
         *  deleted
         **/
        "tasks-deleted"(tasks) {
          if (vm.currentTask) {
            const isSidebarTask = t => t.id === vm.currentTask.id;
            if (tasks.some(isSidebarTask)) {
              vm.currentTask = null;
              vm.closeSidebar();
              vm.setSidebarReload(false);
            }
          }

          vm.notifyViews("tasksDeleted", tasks, layout);
        },

        "tasks-updated"(tasks) {
          vm.notifyViews("tasksUpdated", tasks, layout);
          if (vm.currentTask) {
            vm.setSidebarReload(true);
          }
        }
      };
    }
  }
};
</script>
