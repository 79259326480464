import { addDays, format } from "date-fns";

/**
 * Formats the given date to the format used
 * by the API. This is in the format of
 * YYYY/MM/DD. If no date is provided, the
 * current date will be used
 *
 * @param {*} toFormat The date to be formatted
 */
export function formatDateApi(date = new Date(), dateFormat = null) {
  return formatDate(date, dateFormat || "yyyy/MM/dd");
}

export function shortDayName(d) {
  return formatDate(new Date(d), "E").toLowerCase();
}

export function formatDate(date, dateFormat) {
  if (!dateFormat) {
    throw "'dateFormat' is required";
  }

  return format(new Date(date), dateFormat);
}

export function disabledDates(workingWeek) {
  return [isNotWorkingDay(workingWeek)];
}

export function nextWorkingDay(workingDays, startDate) {
  let nextWorkingDay = (startDate && new Date(startDate)) || new Date();
  let daysInc = 1;

  while (daysInc++ < 6) {
    if (workingDays[shortDayName(nextWorkingDay)]) break;
    nextWorkingDay = addDays(nextWorkingDay, 1);
  }

  return nextWorkingDay;
}

export function isNotWorkingDay(workingDays) {
  return date => {
    const day = shortDayName(date);
    // console.log("!w", day, !workingDays[day], workingDays);
    return !workingDays[day];
  };
}

export function isWorkingDay(workingDays) {
  return date => {
    const day = shortDayName(date);
    // console.log("w", day, workingDays[day], workingDays);
    return workingDays[day];
  };
}

export function durationInText(duration) {
  const years = Math.floor(duration / 12),
    months = duration % 12;
  let yearsText,
    monthsText,
    text = [];

  if (years) {
    yearsText = years === 1 ? `${years} year` : `${years} years`;
    text.push(yearsText);
  }

  if (months) {
    monthsText = months === 1 ? `${months} month` : `${months} months`;
    text.push(monthsText);
  } else {
    text.push("less than 1 month");
  }

  return text.join(" and ");
}

export function isToday(date) {
  var inputDate = new Date(date);
  var todaysDate = new Date();
  if (inputDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
    return true;
  }
  return false;
}
