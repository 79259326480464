<template lang="pug">
  .flex.flex-col.items-center
    .flex-col
      h1.text-3xl.font-semibold Template Settings
    .flex-col
      .text-lg.text-gray-600
        | Copy over all or just some of the aspects of this project.
        | You can always make project-specific adjustments to an copied setting.

    .flex-col.font-bold.text-grey-70
      .block.my-3(v-for="setting in settings" :key="setting.value")
        input(type="checkbox" :id="setting.value" :value="setting.value" :checked="isSettingSelected(setting)"
            @change="selectSetting(setting, $event)" :indeterminate.prop="isIndeterminate(setting)")
        label.mx-2(:for="setting.value") {{ setting.name }}
        .block.mx-4(v-if="setting.subSettings")
          .block.my-3(v-for="subSetting in setting.subSettings" :key="subSetting.value")
            input(type="checkbox" :id="subSetting.value" :value="subSetting.value" :checked="isSettingSelected(subSetting)"
                @change="selectSetting(subSetting, $event)")
            label.mx-2(:for="subSetting.value") {{ subSetting.name }}

</template>
<script>
const removeSetting = (settings, set) => {
  const idx = settings.indexOf(set.value);
  settings.splice(idx, 1);
};

export default {
  props: {
    value: {
      type: Object,
      default: () => []
    }
  },

  data() {
    return {
      settings: [
        { value: "copy_project_workflow", name: "Copy project workflow" },
        {
          value: "copy_project_budget",
          name: "Copy project budget",
          subSettings: [
            {
              value: "include_details_budgets",
              name: "Include detail budgets?"
            },
            {
              value: "include_description_and_notes",
              name: "Include descriptions and notes?"
            },
            {
              value: "include_cost_category_breakdowns",
              name: "Include cost category breakdown?"
            },
            {
              value: "include_default_authorisers",
              name: "Include default authorisers?"
            }
          ]
        },
        {
          value: "copy_template_docs",
          name: "Copy template documents and file structure"
        },
        {
          value: "copy_team_roles",
          name: "Copy team roles and outside relationships",
          subSettings: [
            {
              value: "include_subcontractors",
              name: "Include subcontractors?"
            },
            { value: "include_clients", name: "Include clients?" }
          ]
        }
      ]
    };
  },

  computed: {
    childsParents() {
      const childsParents = new Map();

      this.settings.forEach(setting => {
        if (setting.subSettings) {
          setting.subSettings.forEach(subSet => {
            childsParents[subSet.value] = setting;
          });
        }
      });

      return childsParents;
    },

    project() {
      return { ...this.value };
    },

    selectedSettings() {
      return this.project.templateSettings;
    }
  },

  methods: {
    isIndeterminate(setting) {
      if (setting.subSettings) {
        const someChecked = setting.subSettings.some(this.checked);
        const allChildrenSelected = setting.subSettings.every(this.checked);

        return someChecked && !allChildrenSelected;
      }
    },

    checked(setting) {
      if (setting.subSettings) {
        return setting.subSettings.every(this.isSettingSelected);
      } else {
        return this.isSettingSelected(setting);
      }
    },

    isSettingSelected(setting) {
      return this.selectedSettings.indexOf(setting.value) > -1;
    },

    selectSetting(setting, e) {
      if (e.target.checked) {
        this.selectedSettings.push(setting.value);

        const parent = this.childsParents[setting.value];

        if (parent) {
          const allChildrenSelected = parent.subSettings.every(this.checked);

          if (allChildrenSelected) {
            this.selectedSettings.push(parent.value);
          }
        } else if (setting.subSettings) {
          setting.subSettings.forEach(subSet => {
            if (!this.isSettingSelected(subSet)) {
              this.selectedSettings.push(subSet.value);
            }
          });
        }
      } else {
        this.deselectSetting(setting);
      }

      this.$emit("input", this.project);
    },

    deselectSetting(setting) {
      removeSetting(this.selectedSettings, setting);

      const parent = this.childsParents[setting.value];

      if (setting.subSettings) {
        setting.subSettings.forEach(subSet =>
          removeSetting(this.selectedSettings, subSet)
        );
      } else if (parent) {
        const allUnchecked = !parent.subSettings.some(subSet =>
          this.isSettingSelected(subSet)
        );

        if (allUnchecked) {
          removeSetting(this.selectedSettings, parent);
        }
      }
    }
  }
};
</script>
