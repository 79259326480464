<template lang="pug">
  .flex.flex-row.h-full
    .flex-1.self-center(v-if="params.value")
      p.leading-none.block {{ params.value.phoneNumber }}
      p.leading-none.text-grey-60.text-sm.mt-1(v-if="params.value.count > 1") + {{ pluralize("other", params.value.count - 1) }}

    .flex.self-center.italic.text-grey-60(v-else) No phone numbers

</template>

<script>
import Vue from "vue";
import { pluralize } from "@/helpers/StringHelpers";

export default Vue.extend({
  methods: {
    pluralize
  }
});
</script>
