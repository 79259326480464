import { pluralize } from "@/helpers/StringHelpers";

export function confirmDelete(tasks, onOkCb, resourceType = "task") {
  tasks = Array.isArray(tasks) ? tasks : [tasks];

  const opts = {
    title: `Confirm deletion of ${pluralize(resourceType, tasks.length)}?`,
    message: `Deleting a ${resourceType} cannot be undone`,
    success: `${pluralize(resourceType, tasks.length)} successfully deleted`
  };

  this.$dialog
    .confirm({
      title: opts.title,
      message: opts.message,
      danger: true
    })
    .onOk(({ api }) => {
      api.hide();
      if (typeof onOkCb === "function") onOkCb(opts.success);
    });
}
