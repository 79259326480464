<template lang="pug">
  app-dialog(@cancel="$emit('cancel')" :dismissable="false")
    template(#title) Overwrite?

    p.text-grey-70.mb-8
      | A document with the name
      strong.mx-2 "{{ document.name }}"
      | already exists in this folder
      strong.mx-2 "{{ folder.name }}"
      | , do you want to overwrite it or keep both?

    app-checkbox.mb-8(v-model="doSameForAll", label="Do the same for all duplicate documents")

    .text-right
      app-button.mr-4(@click="$emit('ok', { action: 'skip', doSameForAll })") Skip
      app-button.mr-4(@click="$emit('ok', { action: 'keepBoth', doSameForAll })") Keep both
      app-button(danger @click="$emit('ok', { action: 'overwrite', doSameForAll })") Overwrite
</template>
<script>
import DocumentPreview from "@/components/documents/DocumentPreview.vue";

export default {
  components: {
    DocumentPreview
  },
  props: {
    document: {
      type: Object,
      required: true
    },
    folder: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      doSameForAll: false
    };
  }
};
</script>
