import TaskSidebar from "@/components/tasks/sidebar/TaskSidebar.vue";

export default {
  components: {
    TaskSidebar
  },

  methods: {
    setSidebarReload(reload) {
      this.$store.commit("taskManagement/setSidebarReload", reload);
    },

    setSidebarActions(actions) {
      this.$store.commit("taskManagement/setSidebarActions", actions);
    },

    openSidebar(taskId, renaming) {
      this.$store.dispatch("taskManagement/openSidebar", { taskId, renaming });
    },

    closeSidebar() {
      this.$store.commit("taskManagement/setSidebarTaskId", null);
    }
  }
};
