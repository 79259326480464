<template lang="pug">
  span
    .field.string.required
      label Country
      mp-multiselect(
        :options='countries'
        v-model='selectedCountry',
        label='name',
        track-by='code',
        @input='countryChanged()'
      )

    .field
      label County/State
      mp-multiselect(
        :options='regions',
        v-model='selectedRegion',
        :track-by='null',
        :label='null',
        @input='$emit("region-input", selectedRegion)'
      )

    .hidden.horizontal.ui.divider
</template>

<script>
import MpMultiselect from "@/components/inputs/dropdowns/MpMultiselect.vue";

export default {
  components: {
    MpMultiselect
  },
  props: {
    country: Object,
    region: String
  },
  data() {
    return {
      selectedCountry: this.country,
      selectedRegion: this.region,
      countries: [],
      regions: []
    };
  },
  watch: {
    country(newCountry) {
      this.selectedCountry = newCountry;
      this.updateRegions();
    },
    region(newRegion) {
      this.selectedRegion = newRegion;
    }
  },
  mounted() {
    const vm = this;

    fetch("/countries")
      .then(response => response.json())
      .then(countries => {
        vm.countries = countries;
      });
  },
  methods: {
    countryChanged() {
      this.selectedRegion = null;
      this.$emit("country-input", this.selectedCountry);
      this.$emit("region-input", this.selectedRegion);
    },
    updateRegions() {
      const vm = this;

      fetch(`/countries/${this.country.code}/regions`)
        .then(response => response.json())
        .then(regions => {
          vm.regions = regions.regions;
        });
    }
  }
};
</script>
