/* global $D */

import {
  zoomScale,
  columnDefinitions
} from "@/components/projects/gantt/ProjectGanttConfig";
import { workingWeekArray } from "@/components/projects/gantt/ProjectGanttUtils";
import GanttPersistenceManager from "@/components/projects/gantt/ProjectGanttPersistenceManager";
import { gantt } from "dhtmlx-gantt";

export default {
  data() {
    return {
      persistence: new GanttPersistenceManager(this.projectId, this),
      columnSizeData: {},
      workingWeek: workingWeekArray(this.$store.state.workingWeek)
    };
  },
  methods: {
    configureGantt() {
      gantt.plugins({
        auto_scheduling: true,
        marker: true,
        fullscreen: true,
        undo: true
      });
      gantt.ext.zoom.init({ levels: zoomScale });

      if ($D.configuration.gantt?.configuration) {
        gantt.config = Object.assign(
          gantt.config,
          $D.configuration.gantt.configuration
        );
      }

      // this.configureColumns();
      this.setConfigurationOptions();
      this.setQuarterFunctions();
      this.setTemplateFunctions();
      this.setWorkingTime();
      this.addMilestoneHack();
      this.setTypeRenderers();
      this.setupFunctions();
    },

    addMilestoneHack() {
      // Ugly hack to make milestones behave like they have a duration of 1 day
      // The original method is here:
      // https://github.com/DHTMLX/gantt/blob/fade84f348e8a688d609b0584c971f48331c9dd3/codebase/sources/dhtmlxgantt.js#L14926
      gantt._get_task_timing_mode = (task, force) => {
        const task_type = gantt.getTaskType(task.type);

        let state = {
          type: task_type,
          $no_start: false,
          $no_end: false
        };

        if (!force && task_type === task.$rendered_type) {
          state.$no_start = task.$no_start;
          state.$no_end = task.$no_end;
          return state;
        }

        if (task_type === gantt.config.types.project) {
          //project duration is always defined by children duration
          state.$no_end = state.$no_start = true;
        }

        return state;
      };
    },

    configureColumns(overrides, readonly = false) {
      const buttonHeader =
        '<div class="gantt_grid_head_cell add-task" onclick="gantt.createTask()"></div>';
      gantt.config.columns = columnDefinitions(overrides);

      if (!readonly) {
        gantt.config.columns.push({
          name: "buttons",
          label: buttonHeader,
          width: 70,
          template() {
            return '<div class="add-task"></div>';
          }
        });
      }

      // Need to also specify the grid_width option
      const gridWidth = gantt.config.columns.reduce(
        (acc, col) => acc + col.width,
        0
      );
      gantt.config.grid_width = gridWidth;
    },

    setConfigurationOptions() {
      gantt.config.auto_scheduling_strict = true;
      gantt.config.auto_scheduling_compatibility = true;
      gantt.config.show_progress = false;
      gantt.config.types.mp_milestone = "mp_milestone";
      gantt.config.types.grouping = "grouping";
      gantt.config.smart_scales = true;

      gantt.config.order_branch = "marker";
      gantt.config.order_branch_free = true;

      gantt.config.work_time = true;
      gantt.config.skip_off_time = false;
      gantt.config.static_background = true;

      gantt.config.row_height = 60;
    },

    setQuarterFunctions() {
      gantt.date.quarter_start = date => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const roundedMonth = Math.floor(month / 3) * 3;

        return new Date(year, roundedMonth);
      };

      gantt.date.add_quarter = (date, inc) => {
        const year = date.getFullYear();
        const month = date.getMonth() + inc * 3;
        const newYear = year + Math.floor(month / 12);
        const newMonth = month % 12;

        return new Date(newYear, newMonth);
      };
    },

    setWorkingTime() {
      let i;

      for (i = 0; i < 7; i++) {
        let hours = false;
        if (this.workingWeek[i]) {
          hours = [8, 17];
        }

        gantt.setWorkTime({ day: i, hours: hours });
      }
    },

    setTemplateFunctions() {
      gantt.templates.scale_cell_class = date => {
        if (!this.workingWeek[date.getDay()] && this.currentZoom === 0) {
          return "weekend";
        }
      };

      gantt.templates.timeline_cell_class = (item, date) => {
        if (!this.workingWeek[date.getDay()] && this.currentZoom === 0) {
          return "weekend";
        }
      };
    },

    setTypeRenderers() {
      gantt.config.type_renderers[gantt.config.types.project] = task => {
        const main_el = document.createElement("div");
        const size = gantt.getTaskPosition(task);
        let css = ["custom-project", "gantt_task_line"];

        main_el.setAttribute(gantt.config.task_attribute, task.id);
        main_el.innerHTML = [
          "<div class='project-left'></div>",
          "<div class='project-right'></div>",
          "<div class='gantt_side_content gantt_left'>" +
            gantt.templates.leftside_text(
              task.start_date,
              task.end_date,
              task
            ) +
            "</div>",
          "<div class='gantt_side_content gantt_right'>" +
            gantt.templates.rightside_text(
              task.start_date,
              task.end_date,
              task
            ) +
            "</div>"
        ].join("");

        if (gantt.hasChild(task.id)) {
          css.push("task-parent");
          // There is no need for `===` because we are comparing the values and not taking care of the datatype, i.e "2" == 2
          if (gantt.getSelectedId() == task.id) {
            css.push("gantt_selected");
          }
        }

        if (!task.$open && gantt.hasChild(task.id)) {
          css.push("task-collapsed");
        }

        main_el.className = css.join(" ");

        main_el.style.left = size.left + "px";
        main_el.style.top = size.top + 15 + "px";
        main_el.style.width = size.width + "px";

        return main_el;
      };

      gantt.config.type_renderers["mp_milestone"] = (task, defaultRenderer) => {
        const el = defaultRenderer(task);

        el.className += " gantt_milestone";
        el.style.width = "25px";

        return el;
      };
    },

    setupFunctions() {
      gantt.eachSuccessor = function(callback, root) {
        if (!this.isTaskExists(root)) return;

        // remember tasks we've already iterated in order to avoid infinite loops
        var traversedTasks = arguments[2] || {};
        if (traversedTasks[root]) return;
        traversedTasks[root] = true;

        var rootTask = this.getTask(root);
        var links = rootTask.$source;
        if (links) {
          for (var i = 0; i < links.length; i++) {
            var link = this.getLink(links[i]);
            if (
              this.isTaskExists(link.target) &&
              !traversedTasks[link.target]
            ) {
              callback.call(this, this.getTask(link.target));

              // iterate the whole branch, not only first-level dependencies
              this.eachSuccessor(callback, link.target, traversedTasks);
            }
          }
        }
      };
    }
  }
};
