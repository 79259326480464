<template lang="pug">
  table#read-only-table.ui.celled.tablet.stackable.very.compact.table
    tabular-form-header(
      :groups='groups',
      :labels='labels',
      :sortable='false',
      :fixed-column='fixedColumn')

    tabular-form-data(
      :rows='rows',
      :deleted-rows='deletedRows',
      :can-edit='false',
      :currency='currency',
      :sortable='false')

    tabular-form-summary-readonly(
      v-if="showSummary && !canEdit",
      :labels="labels",
      :rows="rows",
      :currency="currency",
      :sortable="false")
</template>

<script>
import any from "ramda/src/any";
import prop from "ramda/src/prop";
import TabularFormHeader from "../TabularFormHeader.vue";
import TabularFormData from "../TabularFormData.vue";
import TabularFormSummary from "../TabularFormSummary.vue";
import TabularFormSummaryReadonly from "./TabularFormSummaryReadonly.vue";

export default {
  components: {
    TabularFormHeader,
    TabularFormData,
    TabularFormSummary,
    TabularFormSummaryReadonly
  },
  props: [
    "groups",
    "labels",
    "rows",
    "deletedRows",
    "currency",
    "sortable",
    "fixedColumn",
    "canEdit"
  ],
  computed: {
    showSummary() {
      return (
        (any(prop("show_average"), this.labels) ||
          any(prop("show_sum"), this.labels)) &&
        this.rows.length > 0
      );
    }
  }
};
</script>
