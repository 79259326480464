<template lang="pug">
.flex-1.mt-4.flex.flex-col.overflow-hidden
  toolbar(v-on="toolbarListeners()" :exclude="excludedToolbarItems")
  .flex-1.bg-white.shadow(ref="gantt")
</template>

<script>
/* global gantt */

import GanttConfiguration from "@/components/projects/gantt/ProjectGanttConfiguration";
import ganttLayout from "@/components/projects/gantt/ProjectGanttLayout";
import GanttTemplates from "@/components/projects/gantt/ProjectGanttTemplateConfiguration";
import GanttInteractions from "@/components/projects/gantt/ProjectGanttInteraction";

import Toolbar from "@/components/projects/gantt/ProjectGanttToolbar.vue";
import { isOverdue } from "@/components/projects/gantt/ProjectGanttUtils";
import { sub } from "date-fns";

export default {
  components: {
    Toolbar
  },
  mixins: [GanttConfiguration, GanttTemplates, GanttInteractions],
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      currentZoom: 0,
      excludedToolbarItems: [
        "expand",
        "collapse",
        "baselines",
        "fullscreen",
        "print"
      ]
    };
  },
  watch: {
    isVisible(visible) {
      if (visible) {
        gantt.render();
      }
    }
  },
  mounted() {
    this.configureGantt();
    this.configureTemplates();
    gantt.config.auto_scheduling = false;
    gantt.config.readonly = true;
    gantt.config.layout = ganttLayout;
    gantt.config.branch_loading = true;
    // gantt.config.task_height = 25;
    gantt.config.work_time = false;
    gantt.config.correct_work_time = false;
    gantt.init(this.$refs.gantt);

    gantt.config.columns = [
      {
        name: "text",
        label: "Name",
        tree: true,
        width: 150,
        resize: true,
        template: function(task) {
          if (task.type === "grouping") {
            return `<div class="font-bold">${task.text}</div>`;
          } else if (task.type === "project_grouping") {
            return `<a href="/projects/${task.id}">${task.text}</a>`;
          }

          return task.text;
        }
      },
      {
        name: "start_date",
        label: "Starts",
        align: "left",
        width: 100,
        resize: true,
        template(task) {
          if (task.type === "mp_milestone" || task.type === "grouping") {
            return "";
          }

          return gantt.templates.grid_date_format(task.start_date);
        }
      },
      {
        name: "end_date",
        label: "Deadline",
        align: "left",
        width: 100,
        resize: true,
        template(task) {
          if (task.type === "grouping") {
            return "";
          }

          const overdue = isOverdue(task);
          const classes = overdue ? "text-withered-cherry font-bold" : "";
          const date = sub(task.end_date, { days: 1 });
          return `<div class='${classes}'>${gantt.templates.grid_date_format(
            date
          )}</div>`;
        }
      }
    ];

    gantt.config.types.grouping = "grouping";
    gantt.config.types.project_grouping = "project_grouping";
    gantt.config.type_renderers[gantt.config.types.grouping] = () => {
      return document.createElement("div");
    };

    gantt.config.type_renderers[gantt.config.types.project_grouping] =
      gantt.config.type_renderers[gantt.config.types.project];
    // Remove the duration on the task bars, as it might be wrong
    gantt.templates.leftside_text = () => "";
    gantt.templates.rightside_text = (_start, _end, task) => task.text;

    gantt.load("/gantt_chart_overview", "json");
  },

  methods: {
    applyZoomConfig(config) {
      gantt.config.scale_unit = config.scale_unit;
      if (config.date_scale) {
        gantt.config.date_scale = config.date_scale;
        gantt.templates.date_scale = null;
      } else {
        gantt.templates.date_scale = config.template;
      }

      gantt.config.step = config.step;
      gantt.config.subscales = config.subscales;
    }
  }
};
</script>

<style lang="postcss">
@import "gantt/gantt.css";
</style>
