<template lang="pug">
  tfoot#summary-read-only
    tr(v-if='haveSumRow')
      td(v-if='sortable')
      td(v-for='label in labels', :class='{ fixed: label.fixed_column }')
        span(v-if='isTitleCol(label)')
          strong Total

        div(
          style='min-width: 100px;',
          v-else-if='(label.field_type == "currency") && haveSum(label)')

          | {{ '&#163; ' + showFormattedValue(sum(label), label) }}

        div(
          style='min-width: 100px;',
          v-else-if='(label.field_type == "integer") && haveSum(label)')

          | {{ showFormattedValue(sum(label), label) }}

      td

    tr(v-if='haveAverageRow')
      td(v-if='sortable')
      td(v-for='label in labels', :class='{ fixed: label.fixed_column }')
        span(v-if='isTitleCol(label)')
          strong Average
        div(
          style='min-width: 100px;',
          v-else-if='(label.field_type == "currency") && haveAverage(label)')

          | {{ '&#163; ' + showFormattedValue(average(label), label) }}

        div(
          style='min-width: 100px;',
          v-else-if='(label.field_type == "integer") && haveAverage(label)')

          | {{ showFormattedValue(average(label), label) }}

      td
</template>

<script>
/* global $D */

import any from "ramda/src/any";
import prop from "ramda/src/prop";
import DataWrapper from "../DataWrapper";

export default {
  props: ["labels", "rows", "currency", "sortable"],
  computed: {
    haveAverageRow() {
      return any(prop("show_average"), this.labels);
    },
    haveSumRow() {
      return any(prop("show_sum"), this.labels);
    }
  },
  methods: {
    isTitleCol(label) {
      return label.slug === "A";
    },
    haveSum(label) {
      return label.show_sum;
    },
    haveAverage(label) {
      return label.show_average;
    },
    average(label) {
      const wrapper = new DataWrapper(this.rows);
      const { slug } = label;

      if (label.slug === "F") {
        return $D.fn.safeMath.div(wrapper.sum("E"), wrapper.sum("D"));
      }
      if (label.slug === "H") {
        return $D.fn.safeMath.div(wrapper.sum("G"), wrapper.sum("D"));
      }
      if (label.slug === "J") {
        const ifFn = status => status !== "Not Sold";
        const totalArea = wrapper.sumIf("D", "K", ifFn);
        const totalPrice = wrapper.sumIf("I", "K", ifFn);

        return $D.fn.safeMath.div(totalPrice, totalArea);
      }
      return wrapper.average(slug);
    },
    sum(label) {
      const wrapper = new DataWrapper(this.rows);
      return wrapper.sum(label.slug);
    },
    showFormattedValue(value, label) {
      let val = 0;

      if (value && !Number.isNaN(value)) {
        val = value;
      }

      return parseFloat(val).toFixed(label.decimal_places);
    }
  }
};
</script>
