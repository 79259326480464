import { runMutation } from "@/helpers/GraphQLHelpers";
import DeleteChangeOrders from "@/graphql/mutations/finance/change_orders/DeleteChangeOrders.gql";

export default class ChangeOrderManager {
  delete(changeOrders) {
    const ids = changeOrders.map(co => co.id);

    return runMutation(DeleteChangeOrders, { ids });
  }
}
