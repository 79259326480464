/* eslint-disable no-unused-vars */

import { mapState } from "vuex";

export default {
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    projectId: {
      type: [String, Number],
      required: true
    },
    project: {
      type: Object,
      required: false,
      default: null
    },
    search: {
      type: String,
      default: ""
    },
    activeTask: {
      type: Object,
      default: null,
      required: false
    }
  },
  data() {
    return {
      finishedLoadingCallbacks: []
    };
  },
  created() {
    // The project likely won't have finished loading when
    // the component is created, so we'll wait until it has.
    // When it's loaded a callback function will be called,
    // and it will stop watching the prop
    this.unwatchProjectProp = this.$watch("project", project => {
      this.projectLoaded(project);
      this.unwatchProjectProp();
    });
  },
  methods: {
    /**
     *  Called by the container component when the user
     *  pressed the `Add task` button. The active view
     *  will be informed that the user wishes to add a
     *  new task
     **/
    addTask(parent, isMilestone) {},

    openTask(task, renaming = false) {
      this.$emit("open-task", { id: task.id, renaming });
    },

    /**
     * Called when the project prop is loaded
     **/
    projectLoaded() {},

    /**
     *  Notifies the container component that a new task
     *  has been created. This will allow the container to
     *  synchronise data between the different views
     **/
    taskAdded(task) {
      this.$emit("task-added", task);
    },

    /**
     * The following are events that may be called by the
     * parent view when updates occur from other views or
     * from the sidebar
     */
    taskCreated(task) {},
    taskMoved(task) {},
    tasksDeleted(tasks) {},
    taskRenamed(task) {},
    tasksUpdated(tasks) {},

    /**
     * Request tracking methods
     */
    requestFinished() {
      this.$store.commit("taskManagement/requestFinished");

      // No more requests.
      if (
        this.$store.state.taskManagement.requestsInProgress === 0 &&
        this.finishedLoadingCallbacks.length
      ) {
        this.finishedLoadingCallbacks.forEach(cb => {
          cb();
        });

        this.finishedLoadingCallbacks = [];
      }
    },

    requestStarted() {
      this.$store.commit("taskManagement/requestStarted");
    },

    setReadOnly(readonly) {
      this.$store.commit("taskManagement/setReadOnly", readonly);
    },

    onFinishedLoading(cb) {
      this.finishedLoadingCallbacks.push(cb);
    }
  },

  computed: mapState("taskManagement", ["readOnly"]),

  watch: {
    search() {
      if (typeof this.filterTasks === "function") this.filterTasks();
    }
  }
};
