<template lang="pug">
  .flex.flex-col.justify-start
    app-radio-field(
      name="addCustomFields"
      v-model="addCustomFields"
      :options="yesNoOptions"
      :label="`Do you want to set any custom fields for this ${resourceType}?`"
      :description="`Custom fields let you record any data you like against this ${resourceType}.`")

    .my-4(v-if="addCustomFields == 'yes'" class="w-5/6")
      app-message(v-if="customFields.length === 0")
        template(#title) You don't have any custom fields

        p
          | Custom fields can be added in
          a.ml-1(href="/settings#/customise/custom_fields" target="blank" rel="noopener noreferrer") your settings.

        p You can still create your project and add custom fields later.

      template(v-else)
        template(v-if="resourceFields.length")
          .block(v-for="(resourceField, idx) in resourceFields" :key="fieldsKeys(resourceField)"
                :class="{ 'border-b border-grey-50': isLastField(idx), 'mt-5': isNewResourceField(resourceField) }")
            .flex.flex-row.w-full.justify-around.align-center(v-if="isBeingEdited(resourceField)")
              app-dropdown-field.flex-1.ml-2(name="customFieldId" label="Custom field" v-model="copyOf(resourceField).customFieldId" :show-optional="false"
                                            @input="clearValue(copyOf(resourceField))" :options="filteredCustomFields(resourceField)" track-by="id" value-attr="id" label-attr="name" class="w-3/5")
              component.mx-2(:is="customFieldComponent(copyOf(resourceField))" v-bind="componentProps(copyOf(resourceField))" class="flex-1"
                             @input="formatValue(copyOf(resourceField), $event)" :value="valueForInput(copyOf(resourceField).customFieldId, copyOf(resourceField).valueForInput)")


              template(v-if="resourceField !== newResourceField")
                .flex-initial
                  app-icon.cursor-pointer.ml-2.mb-8.self-end(icon="error" size="medium" colour="red" @click.native="quitEditingResourceField(resourceField)")
                  app-icon.cursor-pointer.ml-2.mb-8.self-end(icon="success" size="medium" colour="green" @click.native="saveResourceField(resourceField)")


            .flex.flex-row.w-full.justify-around.align-center.py-5(v-else)
              .flex-1.block.font-bold {{ customFieldName[resourceField.customFieldId] }}
              .flex-1.block(v-html="resourceFieldValue(resourceField)")
              .flex-initial.flex.flex-row.justify-end
                app-icon.cursor-pointer.ml-8.self-end(icon="edit", colour="lightGrey", @click.native="editResourceField(resourceField)")
                app-icon.cursor-pointer.ml-8.self-end(icon="delete", colour="red", @click.native="removeResourceField(resourceField)")

        .my-4.p-3.flex.flex-row.bg-grey-30.w-full(v-else)
          .mr-3
            app-icon(icon="info" size="large")
          .text
            .font-bold You have no custom fields
            .block Click the "Add custom field" button below to add a new field

        template(v-if="newResourceField === null")
          app-button.mt-5(slim primary v-if="!canAddCustomField" class="focus:outline-none focus:shadow-outline" @click.prevent="initResourceField()")
            | Add custom field
        template(v-else)
          .mt-5
            app-button(slim primary class="focus:outline-none focus:shadow-outline" @click.prevent="saveResourceField(newResourceField)")
              | Add custom field
            app-button.ml-3(slim class="focus:outline-none focus:shadow-outline" @click.prevent="quitEditingResourceField(newResourceField)")
              | Cancel

</template>

<script>
/* global accounting */

import uuid from "uuid";
import { parse } from "date-fns";
import { formatDateApi, formatDate } from "@/helpers/DateHelpers";

import CustomFields from "@/graphql/queries/core/company/CustomFields.gql";

import { resourceWatchers } from "@/helpers/WatcherHelpers.js";

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    resourceType: {
      type: String,
      required: true,
      validator: val => ["project", "task"].includes(val)
    }
  },

  apollo: {
    customFields: {
      query: CustomFields,
      variables() {
        return {
          scope: ["all", this.resourceType]
        };
      },
      update({ customFields }) {
        return customFields.edges.map(({ node }) => node);
      }
    }
  },
  data() {
    const resource = { ...this.value };
    const { resourceFieldsAttributes } = this.value;
    const addCustomFields = resourceFieldsAttributes.length ? "yes" : "no";

    return {
      addCustomFields,
      customFields: [],
      resource,
      newResourceField: null,
      resourceFieldsAttributesBackup: resourceFieldsAttributes,
      yesNoOptions: [
        { name: "No", value: "no" },
        { name: "Yes", value: "yes" }
      ],
      resourceFieldsBeingEdited: [],
      resourceFieldsCopies: []
    };
  },

  computed: {
    filteredCustomFields() {
      return currentRf => {
        return this.customFields.filter(cf => {
          const isThisSameCf = cf.id === currentRf.customFieldId;
          const isAlreadyUsed = this.resourceFields.some(rf => {
            if (rf === currentRf) return false;
            return rf.customFieldId == cf.id;
          });

          return isThisSameCf || !isAlreadyUsed;
        });
      };
    },
    componentProps() {
      const fieldTypeComponentProps = {};

      return resourceField => {
        const { customFieldId } = resourceField;

        if (fieldTypeComponentProps[customFieldId])
          return fieldTypeComponentProps[customFieldId];

        const fieldType = this.fieldType(customFieldId);

        const props = {
          name: "valueForInput",
          label: "Value",
          type: fieldType,
          "show-optional": false,
          showCaption: false
        };

        if (this.isList(customFieldId)) {
          props["value-attr"] = "id";
          props["track-by"] = "id";
          props["label-attr"] = "value";
          props["options"] = this.customFieldListItems[customFieldId];
          props["multiple"] = fieldType == "predefined_list_multi";
        }

        if (fieldType == "text") {
          props["type"] = "richtext";
        }

        if (fieldType == "currency") {
          props["type"] = "currency";
          props["currency"] = this.$store.state.defaultCurrency;
        }

        fieldTypeComponentProps[customFieldId] = props;

        return props;
      };
    },

    resourceFields() {
      const fields = [...this.resourceFieldsAttributes];
      if (this.newResourceField) {
        fields.push(this.newResourceField);
      }
      return fields;
    },

    fieldsKeys() {
      const keys = new WeakMap();

      return resourceField => {
        let key = keys.get(resourceField);

        if (!key) {
          key = uuid();
          keys.set(resourceField, key);
        }

        return key;
      };
    },

    fieldType() {
      const fieldTypes = {};
      this.customFields.forEach(cf => (fieldTypes[cf.id] = cf.type));

      return fieldId => fieldTypes[fieldId] || "string";
    },

    customFieldName() {
      const names = {};
      this.customFields.forEach(f => (names[f.id] = f.name));

      return names;
    },

    customFieldListItems() {
      const fieldItems = {};

      this.customFields.forEach(cf => {
        if (this.isList(cf.id)) {
          fieldItems[cf.id] = cf.customFieldListItems;
        }
      });

      return fieldItems;
    },

    resourceFieldsAttributes() {
      return this.resource.resourceFieldsAttributes || [];
    },

    copyOf() {
      const fieldsIdx = new WeakMap();

      return resourceField => {
        if (resourceField == this.newResourceField) return resourceField;

        let idx = fieldsIdx.get(resourceField);

        if (!idx) {
          idx = this.resourceFieldsBeingEdited.indexOf(resourceField);
          fieldsIdx.set(resourceField, idx);
        }

        return this.resourceFieldsCopies[idx];
      };
    },

    canAddCustomField() {
      return (
        this.resourceFieldsAttributes.length > 0 &&
        this.resourceFieldsAttributes.length == this.customFields.length
      );
    }
  },

  watch: {
    ...resourceWatchers("resource"),

    addCustomFields(newVal) {
      if (newVal === "no") {
        this.resource.resourceFieldsAttributes = [];
      } else {
        this.resource.resourceFieldsAttributes = this.resourceFieldsAttributesBackup;
      }
    }
  },

  methods: {
    clearValue(resourceField) {
      if (
        this.fieldType(resourceField.customFieldId) == "predefined_list_multi"
      ) {
        resourceField.valueForInput = null;
      } else {
        resourceField.valueForInput = null;
      }
    },

    isList(customFieldId) {
      const fieldType = this.fieldType(customFieldId);

      return ["list", "predefined_list", "predefined_list_multi"].includes(
        fieldType
      );
    },

    isPredefinedList(customFieldId) {
      return this.fieldType(customFieldId) == "predefined_list_multi";
    },

    isLastField(idx) {
      return this.newResourceField
        ? idx < this.resourceFields.length - 2
        : idx < this.resourceFields.length - 1;
    },

    isNewResourceField(field) {
      return field == this.newResourceField;
    },

    initResourceField() {
      if (!this.canAddCustomField) {
        this.newResourceField = {
          customFieldId: null,
          valueForInput: null
        };
        this.editResourceField(this.newResourceField);
      } else {
        this.newResourceField = null;
        this.editResourceField({
          customFieldId: null,
          valueForInput: null
        });
      }
    },

    resourceFieldValue(resourceField) {
      let { customFieldId, valueForInput } = resourceField;

      const fieldType = this.fieldType(customFieldId);

      if (valueForInput && this.isList(customFieldId)) {
        const fieldListItems = this.customFieldListItems[customFieldId];
        const selectedValues = valueForInput.split(",");

        let fieldItems = fieldListItems.filter(f =>
          selectedValues.includes(f.id)
        );

        return fieldItems.map(fi => fi.value).join(", ");
      } else if (fieldType === "date") {
        return formatDate(valueForInput, this.$store.state.dateFormats.dateFns);
      } else if (fieldType === "currency") {
        const currency = this.$store.state.defaultCurrency;
        return accounting.formatMoney(valueForInput, {
          symbol: currency.symbol,
          decimal: currency.decimalMark,
          thousand: currency.thousandsSeparator,
          precision: currency.decimalPlaces
        });
      } else {
        return !valueForInput ? valueForInput : this.$sanitize(valueForInput);
      }
    },

    saveResourceField(field) {
      if (field != this.newResourceField) {
        Object.assign(field, this.copyOf(field));
      }

      if (!field.customFieldId) return;

      if (!this.resourceFieldsAttributes.includes(field)) {
        if (
          this.fieldType(field.customFieldId) === "date" ||
          this.fieldType(field.customFieldId) === "DateField"
        ) {
          field.valueForInput = field.valueForInput
            ? field.valueForInput
            : formatDateApi(new Date());
        }
        this.resourceFieldsAttributes.push(field);
      }

      if (this.newResourceField == field) {
        this.initResourceField();
      }

      this.cancelEditResourceField(field);
    },

    editResourceField(field) {
      if (this.isBeingEdited(field)) return;
      this.resourceFieldsBeingEdited.push(field);
      this.resourceFieldsCopies.push({ ...field });
    },

    cancelEditResourceField(field) {
      const idx = this.resourceFieldsBeingEdited.indexOf(field);
      this.resourceFieldsBeingEdited.splice(idx, 1);
      this.resourceFieldsCopies.splice(idx, 1);
    },

    quitEditingResourceField(resourceField) {
      if (resourceField === this.newResourceField) {
        this.newResourceField = null;
      }
      this.cancelEditResourceField(resourceField);
    },

    isBeingEdited(field) {
      return this.resourceFieldsBeingEdited.includes(field);
    },

    removeResourceField(field) {
      const idx = this.resourceFieldsAttributes.findIndex(
        f => this.fieldsKeys(f) === this.fieldsKeys(field)
      );
      this.resourceFieldsAttributes.splice(idx, 1);
    },

    customFieldComponent(resourceField) {
      const { customFieldId } = resourceField;
      const fieldType = this.fieldType(customFieldId);

      if (this.isList(customFieldId)) {
        return "AppDropdownField";
      } else if (fieldType == "date") {
        return "AppDateField";
      } else if (fieldType === "currency") {
        return "AppNumberField";
      } else {
        return "AppTextField";
      }
    },

    formatValue(field, value) {
      if (value) {
        const type = this.fieldType(field.customFieldId);

        if (type === "date" || type === "DateField") {
          value = formatDateApi(parse(value, "yyyy/MM/dd", new Date()));
        } else if (type === "predefined_list_multi") {
          value = value.join(",");
        } else if (type === "currency") {
          // The server is expecting a string so we'll conver it
          value = value.toString();
        }
      }

      field.valueForInput = value;
    },

    valueForInput(customFieldId, valueForInput) {
      const fieldType = this.fieldType(customFieldId);

      if (valueForInput) {
        if (valueForInput && fieldType === "predefined_list_multi") {
          return valueForInput.split(",");
        }
      }

      return valueForInput;
    }
  }
};
</script>
