export default {
  // When the bound element is inserted into the DOM...
  inserted: function(el) {
    // Focus the element
    if (el.tagName === "INPUT") {
      el.focus();
    } else {
      const input = el.getElementsByTagName("input")[0];
      if (input) {
        input.focus();
      }
    }
  }
};
