class TaskTreeNode {
  constructor(task) {
    this.id = task.id;
    this.data = { ...task };
    this.children = [];
    this.isExpanded = task.isExpanded || false;
    this.childCount = task.childCount;
    this.parent = null;
    this._level = task.level || 0;
  }

  set expanded(expanded) {
    this.isExpanded = expanded;
    this.data.isExpanded = expanded;
  }

  set level(level) {
    this._level = level;
    this.data.level = level;
  }

  get level() {
    return this._level;
  }
}

export default TaskTreeNode;
