<template lang="pug">
  app-sidebar(@close="close")
    template(#title) {{ formTitle }}
    .p-8
      form-wrapper(:validator="$v.user")
        form(@submit.prevent="save")
          app-panel(secondary)
            app-header(size="h3") User details
            app-text-field(name="name" label="Name" ref="name" :required="true" v-model.trim="user.name" @blur="$v.user.name.$touch")
            app-text-field(name="email" label="Email address" ref="email" :required="true" :messages="messages" v-model.trim="user.email"
                          @change="checkEmailUnique")

            app-dropdown-field(class="w-2/3" name="role" label="Role" ref="role" v-model="user.role" required="true"
                              :options="companyRoles" value-attr="id" label-attr="name" @blur.native="$v.user.role.$touch" v-if="!isEditingOwner")

            template(v-if="features.financeManagement")
              app-text-field(name="costRate" label="Cost rate (optional)" description="Hourly rate it costs to use this person on a project"
                            :show-optional="false"
                            type="number" v-model="user.costRate")

                template(#default="{ value, listeners, hasErrors }")
                  app-text-input(class="w-1/3" :value="value" v-on="listeners" :error="hasErrors")

            app-dropdown-field(class="w-2/3" name="dateFormat" ref="dateFormat" label="Date format" v-model="user.dateFormat" required="true"
                              :options="dateFormatsOptions" @blur.native="$v.user.dateFormat.$touch" value-attr="value" label-attr="label")
            app-dropdown-field(class="w-2/3" name="timezone" ref="timezone" label="Timezone" v-model="user.timezone" required="true"
                              :options="timezonesOptions" @blur.native="$v.user.timezone.$touch" value-attr="value" label-attr="label")

        template(v-if="isNewUser")
          app-panel.mt-4(secondary)
            app-header(size="h3")
              | Licensing
              template(#subheader)
                span(v-if="!loadingSubscription && !onTrial") {{ remainingLicensesMessage }}
                span(v-else-if="loadingSubscription") Loading your licenses...

            .w-24.h-24.relative.mx-auto(v-if="loadingSubscription")
              app-loader(:loading="loadingSubscription")

            template(v-else)
              template
                .mb-3(v-if="noLicensesAvailable && !onTrial")
                  | You do not have any licenses available to assign to this user. They will not be able to access ManagePlaces
                  | until a license has been assigned.

                .mb-8(v-else)
                  | If you assign a license to this user, they will receive an invitation to ManagePlaces.
                  | If no license is assigned they will not be able to login.

                p(v-if="noLicensesAvailable && !isCompanyOwner && !onTrial")
                  | Please contact #[span.font-bold {{ companyOwner.name }}] to request more licenses.
                p(v-else-if="noLicensesAvailable && !subscription.managedByStripe && !onTrial")
                  | Your billing is managed by ManagePlaces. Please contact us if you wish to purchase more licenses
                .flex.flex-row(v-else)
                  app-button.mr-4.border(icon="addUserLicense" :class="licenseClasses(true)" @click="isLicenseAssigned = true")
                    span(v-if="shouldPurchaseLicense") Purchase license
                    span(v-else) Assign license
                  app-button.border(icon="removeUserLicense" :class="licenseClasses(false)" @click="isLicenseAssigned = false")
                    | Do not assign license

          license-tab(v-if="showLicenseTab"
                      :in-user-sidebar="true"
                      :show-header="false"
                      :subscription="subscription",
                      :user-license-price="userLicensePrice",
                      :add-on-price="financeAddOn.price",
                      :additional-licenses="additionalLicenses",
                      :financeManagementStatus="financeAddOn.status",
                      :voucher="subscription.voucher",
                      :vat-price="taxPrice",
                      :tax-rate="taxRate",
                      :user-license="userLicense",
                      :used-licenses="usedLicenses",
                      :currency="currency",
                      :next-payment="nextPayment",
                      :previous-licenses="previousLicenses",
                      only-additional
                      @update-feature="recordSubscriptionFeature")

        .mt-4
          app-button.inline-block.mr-4(:loading="saving" primary @click.prevent="save") {{ saveBtnText }}
          a.danger.block.my-4.underline.cursor-pointer(@click.prevent="close()", class="hover:text-split-crimson") Cancel
</template>

<script>
// import { requiredIf } from "vuelidate/lib/validators";
import SubscriptionMixin from "@/mixins/subscriptions/Subscription.js";
import LicenseMixin from "@/mixins/subscriptions/License.js";
import LicenseTab from "@/components/subscriptions/LicenseTab.vue";
import { required, email } from "vuelidate/lib/validators";
import { initCap, pluralize, caseCompare } from "@/helpers/StringHelpers";
import UserManager from "./UserManager.js";
import CompanyRolesQuery from "@/graphql/queries/core/company/Roles.gql";
import CompanyQuery from "@/graphql/queries/core/company/Company.gql";
import TimezonesQuery from "@/graphql/queries/core/general/Timezones.gql";
import DateFormatsQuery from "@/graphql/queries/core/general/DateFormats.gql";
import emailIsUniqueQuery from "@/graphql/queries/core/users/emailIsUnique.gql";
import {
  featureQuery,
  errorMessage as gqlErrorMessage
} from "@/helpers/GraphQLHelpers";

export default {
  name: "Sidebar",

  components: {
    LicenseTab
  },

  mixins: [SubscriptionMixin, LicenseMixin],

  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      subscriptionFeature: null,
      userManager: new UserManager(),
      editing: false,
      saving: false,
      user: { ...this.value },
      companyOwner: this.$store.state.companyOwner,
      isLicenseAssigned: !!this.value.userLicenseId,
      timezones: [],
      dateFormats: [],
      emailIsUnique: true,
      features: {},
      messages: {
        unique: "Email already has account on Manageplaces"
      },
      originalEmail: this.value.email
    };
  },

  apollo: {
    company: {
      query: CompanyQuery,
      result() {
        if (this.isNewUser && this.onTrial) {
          this.isLicenseAssigned = true;
        }
        if (this.isNewUser) {
          this.user.timezone = this.company.timezone;
          this.user.dateFormat = this.company.dateFormat;
        }
      }
    },
    features: {
      query: featureQuery(["financeManagement"]),
      update({ company }) {
        return company.features;
      }
    },
    timezones: TimezonesQuery,
    dateFormats: DateFormatsQuery,
    companyRoles: {
      query: CompanyRolesQuery,

      update(data) {
        return data.companyRoles.edges.map(edge => {
          const { id, name } = edge.node;
          return { id, name: initCap(name.replace("_", " ")) };
        });
      }
    }
  },

  validations() {
    const validations = {
      name: { required },
      email: { email, required, unique: () => this.emailIsUnique }
    };

    if (!this.isEditingOwner) {
      validations.role = { required };
    }

    validations.dateFormat = { required };
    validations.timezone = { required };

    return {
      user: validations
    };
  },

  computed: {
    isEditingOwner() {
      return caseCompare(this.user.roleName, "owner");
    },

    onTrial() {
      return this.company?.subscription?.isTrial;
    },

    shouldPurchaseLicense() {
      return (
        !this.onTrial &&
        this.noLicensesAvailable &&
        (this.isNewUser || !this.user.userLicense)
      );
    },

    showLicenseTab() {
      return (
        !this.onTrial &&
        this.isCompanyOwner &&
        this.noLicensesAvailable &&
        !this.loadingSubscription &&
        this.isLicenseAssigned
      );
    },

    remainingLicensesMessage() {
      const licenses = pluralize("license", this.licensesRemaining);
      return `You have ${licenses} available`;
    },

    validatedFields() {
      return ["name", "email", "role", "dateFormat", "timezone"];
    },

    timezonesOptions() {
      return this.timezones.map(tz => ({
        label: `GMT(${tz.formattedOffset}) ${tz.name}`,
        value: tz.name
      }));
    },

    dateFormatsOptions() {
      const { possibleValues = [] } = this.dateFormats;

      if (possibleValues) {
        return possibleValues.map(df => ({ label: df.value, value: df.value }));
      }

      return [];
    },

    isNewUser() {
      return !this.user?.id;
    },

    formTitle() {
      if (this.isNewUser) {
        return "Invite a user";
      } else {
        return "Edit user";
      }
    },

    saveBtnText() {
      if (this.isNewUser) {
        return "Invite user";
      } else {
        return "Update user";
      }
    }
  },

  watch: {
    user: {
      deep: true,
      handler(updatedUser) {
        this.$emit("input", updatedUser);
      }
    },

    value: {
      deep: true,
      handler(updatedValue, oldValue) {
        this.user = updatedValue;

        if (updatedValue?.id !== oldValue?.id) {
          this.isLicenseAssigned = !!updatedValue.userLicenseId;
          this.loadingSubscription = true;
          this.$apollo.queries.subscription.refetch();
        }
      }
    }
  },

  methods: {
    recordSubscriptionFeature(subscriptionFeature) {
      this.subscriptionFeature = subscriptionFeature;
    },

    close() {
      this.$emit("close");
    },

    checkEmailUnique() {
      // this is useful when editing a user, it should not check email uniqueness except
      // if it has changed

      // Make sure it's not actually the same address they had to start with
      if (!caseCompare(this.originalEmail, this.value.email)) {
        return this.$apollo
          .query({
            query: emailIsUniqueQuery,
            variables: {
              email: this.user.email
            },
            fetchPolicy: "no-cache"
          })
          .then(({ data }) => {
            this.emailIsUnique = data.emailIsUnique;
            this.$v.user.email.$touch();
          })
          .catch(error => {
            this.$flash.error(`Error validating email: ${error}`);
          });
      } else {
        this.emailIsUnique = true;
      }
    },

    licenseClasses(isLicenseAssigned) {
      if (this.isLicenseAssigned === isLicenseAssigned) {
        return "bg-yawning-snow border-tribal-aqua text-tribal-aqua";
      } else {
        return "border-grey-50 text-grey-50";
      }
    },

    async save() {
      await this.checkEmailUnique();

      this.$v.user.$touch();

      if (this.$v.$invalid) {
        this.validatedFields.some(f => {
          const fieldValidator = this.$v.user[f];

          if (fieldValidator && fieldValidator.$invalid) {
            this.$refs[f]?.$el.querySelector("input").focus();
            return true;
          }
        });
        return;
      }

      if (this.noLicensesAvailable && this.subscriptionFeature) {
        this.loadingSubscription = true;
        await this.updateSubscriptionFeature(this.subscriptionFeature);
        this.loadingSubscription = false;
      }

      const { isNewUser } = this;
      let user = { ...this.user, assignLicense: this.isLicenseAssigned };
      delete user.userLicenseId;

      const method = isNewUser ? "inviteUser" : "updateUser";

      if (this.features.financeManagement) {
        user.billableRate = Number(user.billableRate);
        user.costRate = Number(user.costRate);
      }

      // remove roleName from params
      delete user.roleName;

      this.saving = true;
      this.userManager[method](user)
        .then(({ data }) => {
          let user, message;

          this.saving = false;

          if (method === "inviteUser") {
            user = data.inviteUser.user;
            message = "User invited successfully";
          } else {
            user = data.updateCompanyUser.user;
            message = "User updated successfully";
          }

          this.$emit("saved", user);
          this.close();
          this.$flash.success(message);
        })
        .catch(error => {
          this.saving = false;
          // this.$flash.error(
          //   isNewUser ? "User invite failed" : "User update failed"
          // );
          this.$flash.error(gqlErrorMessage(error));
        });
    }
  }
};
</script>

<style lang="postcss" scoped>
.sidebar {
  overflow-y: auto;
}
</style>
