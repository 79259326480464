/* global gantt */

import { isOverdue } from "@/components/projects/gantt/ProjectGanttUtils";
import { sub } from "date-fns";

// The class used to enable/disable baselines.
export const baselineClass = "with-baselines";

export const zoomScale = [
  // Days
  {
    name: "day",
    scales: [
      { unit: "month", step: 1, format: "%M" },
      { unit: "day", step: 1, format: "%d" }
    ]
  },

  // Weeks
  {
    name: "week",
    min_column_width: 100,
    scales: [
      { unit: "month", step: 1, format: "%F" },
      {
        unit: "week",
        step: 1,
        format(date) {
          const dateToStr = gantt.date.date_to_str("%d");
          const endDate = gantt.date.add(
            gantt.date.add(date, 1, "week"),
            -1,
            "day"
          );

          return dateToStr(date) + " - " + dateToStr(endDate);
        }
      }
    ]
  },

  // months
  {
    name: "month",
    scales: [{ unit: "month", step: 1, format: "%M" }]
  },

  // quarters
  {
    name: "quarter",
    min_column_width: 100,
    scales: [
      { unit: "year", step: 1, format: "%Y" },
      {
        unit: "quarter",
        step: 1,
        format(date) {
          const dateToStr = gantt.date.date_to_str("%M");
          const endDate = gantt.date.add(
            gantt.date.add(date, 3, "month"),
            -1,
            "day"
          );
          return dateToStr(date) + " - " + dateToStr(endDate);
        }
      }
    ]
  },

  // year
  {
    name: "year",
    scales: [
      {
        unit: "year",
        step: 1,
        format: "%Y"
      }
    ]
  },

  // 5 years
  {
    name: "year",
    scales: [
      {
        unit: "year",
        step: 1,
        format(date) {
          const dateToStr = gantt.date.date_to_str("%Y");
          const endDate = gantt.date.add(
            gantt.date.add(date, 5, "year"),
            -1,
            "day"
          );
          return dateToStr(date) + " - " + dateToStr(endDate);
        }
      }
    ]
  },

  // decade
  {
    name: "decade",
    scales: [
      {
        unit: "year",
        step: 1,
        format(date) {
          const dateToStr = gantt.date.date_to_str("%Y");
          const endDate = gantt.date.add(
            gantt.date.add(date, 10, "year"),
            -1,
            "day"
          );
          return dateToStr(date) + " - " + dateToStr(endDate);
        }
      }
    ]
  }
];

export const columnDefinitions = overrides => [
  {
    name: "text",
    label: "Name",
    tree: true,
    width: overrides["text"] ? parseInt(overrides["text"]["new_width"]) : 260,
    resize: true,
    template: function(task) {
      return `<div class="task-name-cell cursor-pointer underline text-diving-sapphire" data-id="${task.id}">
              ${task.text}</div>`;
    }
  },
  {
    name: "start_date",
    label: "Starts",
    align: "left",
    width: overrides["start_date"]
      ? parseInt(overrides["start_date"]["new_width"])
      : 100,
    resize: true,
    template(task) {
      if (task.type === "mp_milestone") {
        return "";
      }

      return gantt.templates.grid_date_format(task.start_date);
    }
  },
  {
    name: "end_date",
    label: "Deadline",
    align: "left",
    width: overrides["end_date"]
      ? parseInt(overrides["end_date"]["new_width"])
      : 100,
    resize: true,
    template(task) {
      const overdue = isOverdue(task);
      const classes = overdue ? "text-withered-cherry font-bold" : "";
      const date = sub(task.end_date, { days: 1 });
      return `<div class='${classes}'>${gantt.templates.grid_date_format(
        date
      )}</div>`;
    }
  },
  {
    name: "duration",
    label: "Duration",
    align: "center",
    width: overrides["duration"]
      ? parseInt(overrides["duration"]["new_width"])
      : 80,
    resize: true,
    template(task) {
      if (task.type === gantt.config.types.mp_milestone) {
        return "";
      } else {
        return task.duration + (task.duration > 1 ? " days" : " day");
      }
    }
  }
];
