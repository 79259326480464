import TaskWizard from "@/components/tasks/wizard/Wizard";

export default [
  {
    path: "/tasks/new",
    name: "newTaskWizard",
    component: TaskWizard
  },
  {
    path: "/projects/:projectId/tasks/new",
    name: "newTaskWizard",
    component: TaskWizard
  },
  {
    path: "/tasks/:taskId/sub_tasks/new",
    name: "newSubtaskWizard",
    component: TaskWizard
  },
  {
    path: "/tasks/:id/edit",
    name: "editTaskWizard",
    component: TaskWizard
  }
];
