<template lang="pug">
  .flex.flex-col
    app-header Advanced
      template(#subheader)
        | From this screen you can set your KPI targets and custom fields for a task.

    KpiTargets(v-model="task" resource-type="task")
    CustomFields(v-model="task" resource-type="task")

    //- .mt-8
      app-radio-field(name="isBillable" description="Is this a billable task?" :options="yesNoOptions"
              v-model="isBillable" :messages="messages")
</template>

<script>
import KpiTargets from "@/components/collections/KpiTargets.vue";
import CustomFields from "@/components/collections/CustomFields.vue";

import TaskWatchersMixin from "@/mixins/TaskWatchers.js";

export default {
  components: {
    KpiTargets,
    CustomFields
  },
  mixins: [TaskWatchersMixin],

  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      task: { ...this.value }
    };
  }
};
</script>
