<template lang="pug">
  .flex.flex-row.mb-8
    .flex.flex-col
      strong.block.mb-2 Owner

      .flex.flex-row
        app-dropdown(trigger="hover", v-if="internalTask.owner", pointing)
          template(#trigger)
            app-avatar(slot="trigger", :avatar="internalTask.owner.avatar" bordered)

          template(#content)
            .w-64.p-4.border-grey-30
              app-team-member(:user="internalTask.owner")
              a.text-withered-cherry.ml-16.mt-4(v-if="task.canEdit", href="#", @click.prevent="removeOwner") Remove owner

        user-picker.flex-initial(v-if="task.canEdit", :class="{ '-ml-2': internalTask.owner }", :value="internalTask.owner", @input="ownerChanged", single)
          template(#title) Choose an owner
        p.italic.text-grey-60(v-else-if="!internalTask.owner") No owner

    .flex.flex-col.ml-8
      strong.block.mb-2 Your team

      .flex.flex-row
        app-dropdown(:class="{ '-ml-2': idx !== 0 }", trigger="hover", v-for="(user, idx) in internalTask.users", :key="user.id", pointing)
          template(#trigger)
            app-avatar(slot="trigger", :avatar="user.avatar" bordered)

          template(#content)
            .w-64.p-4.border-grey-30
              app-team-member(:user="user")
              a.text-withered-cherry.ml-16.mt-4(v-if="task.canEdit", href="#", @click.prevent="unassignUser(user)") Unassign user

        user-picker.flex-initial(v-if="task.canEdit", :class="{ '-ml-2': internalTask.users && internalTask.users.length }", :value="internalTask.users", @input="assigneesChanged")
        p.italic.text-grey-60(v-else-if="!internalTask.users || !internalTask.users.length") No users assigned
</template>

<script>
import UserPicker from "@/components/collections/AppUserPicker.vue";
import clone from "ramda/src/clone";
import SidebarComponentMixin from "@/mixins/tasks/TaskSidebarComponentMixin.js";

export default {
  components: {
    UserPicker
  },
  mixins: [SidebarComponentMixin],
  props: {
    task: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      internalTask: clone(this.task)
    };
  },
  methods: {
    ownerChanged(newOwner) {
      this.ifNotReadOnly(
        () => {
          const param = newOwner ? { id: newOwner.id } : null;
          this.taskManager
            .updateTable({ id: this.task.id, owner: param }, "owner")
            .then(tasks => {
              const task = tasks[0];
              this.internalTask.owner = task.owner;
              this.$emit("tasks-updated", [task]);
            })
            .catch(() => {
              this.internalTask.owner = this.task.owner;
              this.$flash.error("It was not possible to change the owner");
            });
        },
        () => {
          this.internalTask.owner = this.task.owner;
        },
        false
      );
    },
    assigneesChanged(newAssignees) {
      this.ifNotReadOnly(
        () => {
          const userIds = newAssignees.map(a => ({ id: a.id }));
          this.taskManager
            .updateTable({ id: this.task.id, users: userIds }, "users")
            .then(tasks => {
              const task = tasks[0];
              this.internalTask.users = task.users;
              this.$emit("tasks-updated", [task]);
            })
            .catch(() => {
              this.internalTask.users = this.task.users;
              this.$flash.error("It was not possible to change the assignees");
            });
        },
        () => {
          this.internalTask.users = this.task.users;
        },
        false
      );
    },
    removeOwner() {
      this.ownerChanged(null);
    },
    unassignUser(user) {
      const newAssignees = this.internalTask.users.filter(
        u => u.id !== user.id
      );

      this.assigneesChanged(newAssignees);
    }
  }
};
</script>
