
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WorkingWeek"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"WorkingWeek"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mon"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tue"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"wed"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"thu"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fri"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sat"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sun"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":82}};
    doc.loc.source = {"body":"fragment WorkingWeek on WorkingWeek {\n  mon\n  tue\n  wed\n  thu\n  fri\n  sat\n  sun\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
