import Subscription from "@/views/subscription";
import EndSubscription from "@/views/subscription/EndSubscription.vue";
import DowngradeSubscription from "@/views/subscription/DowngradeSubscription.vue";
import Wizard from "@/components/checkouts/wizard/Wizard.vue";
import Process3DSecure from "@/components/checkouts/wizard/Process3DSecure.vue";

export default [
  {
    path: "/subscriptions",
    name: "subscriptions",
    component: Subscription
  },
  {
    path: "/settings/subscriptions",
    name: "subscriptions",
    component: Subscription
  },
  {
    path: "/subscriptions/new",
    name: "checkoutWizard",
    component: Wizard
  },
  {
    path: "/subscriptions/cancellation",
    name: "End subscription",
    component: EndSubscription
  },
  {
    path: "/subscriptions/downgrade",
    name: "Downgrade subscription",
    component: DowngradeSubscription
  },
  {
    path: "/subscriptions/3d-process",
    name: "Process 3d secure",
    component: Process3DSecure
  }
];
