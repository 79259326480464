<template lang="pug">
  app-panel.relationships.mb-4(secondary)
    template(#header)
      .flex.flex-row.justify-between.cursor-pointer(@click="toggleExpanded")
        app-header(size="h3" :margin="expanded ? 'mb-8' : ''") Relationships
        app-icon(:icon="collapseIcon")

    template(#default)
      .flex.flex-col(v-show="expanded")
        template(v-if="task.parent")
          .block.font-bold.mb-2 Parent task
          .flex.justify-between.mb-6.items-start
            .flex-1 Belongs to #[app-link(@click="$emit('task-clicked', task.parent.id)") {{ task.parent.name }}]
            task-status(:task="task.parent" :dropdown="false" :slim="true")

        template(v-if="task.dependencies.length")
          .block.font-bold.mb-2 Dependencies
          .flex.justify-between.items-start(v-for="dep in task.dependencies" :key="dep.id")
            app-link(@click="$emit('task-clicked', dep.id)") {{ dep.name }}
            task-status(:task="dep" :dropdown="false" :slim="true")

</template>
<script>
import CollapsableSection from "@/mixins/CollapsableSection.js";

export default {
  mixins: [CollapsableSection],

  props: {
    task: {
      type: Object,
      required: true
    }
  }
};
</script>
