<template lang="pug">
  .grid.m-auto.col-gap-6.row-gap-4(class="xs:grid-cols-1 sm:grid-cols-6 md:grid-cols-8")
    app-header.col-span-1(class="sm:col-start-1 sm:col-span-7 md:col-span-9" margin="mb-4")
      | Set project parameters

    //- .col-span-1(class="sm:col-span-2 md:col-start-1")
      app-text-field(name="gdv" label="GDV" type="number" description="Projected value in GBP (£)"
              v-model="project.gdv" :messages="messages")

    //- .col-span-1(class="sm:col-span-2 md:col-start-5")
      app-text-field(name="expectedBudget" label="Expected budget" type="number" description="Cost of development in GBP (£)"
              v-model="project.expectedBudget" :messages="messages")

    //- .col-span-1(class="sm:col-span-7 md:col-span-9 md:col-start-1")
      app-radio-field(name="finishQuality" label="What quality of finish does this project require?" :options="qualitiesOfFinish"
              v-model="project.finishQuality")

    .col-span-1(v-if="isNewProject" class="sm:col-span-4 md:col-span-7 md:col-start-1")
      CheckboxesField.mb-4(name="workingWeekAttributes" description="What are the working days of the project?" :messages="messages"
              @input="workingDaysUpdated($event)" :value="checkedWorkingDays" :options="weekDays")
    .col-span-1(class="sm:col-span-4 md:col-span-7 md:col-start-1")
      app-radio-field(name="startToday" label="Does the project start today?" :options="startTodayOptions"
              v-model="startToday" :messages="messages")

    .col-span-1(v-if="startToday === 'no'" class="sm:col-span-4 md:col-span-7 md:col-start-1")
      app-date-field(name="startDate" label="Start date" :disable="disabledDates()"
              v-model="project.startDate" :messages="messages")

    .col-span-1(class="sm:col-span-4 md:col-span-7 md:col-start-1")
      app-date-field(name="deadline" label="Estimated completion" :disable="disabledDates()"
              v-model="project.deadline" :messages="messages")

    .col-span-1(v-if="permissions.kpi" class="sm:col-span-4 md:col-span-7 md:col-start-1")
      KpiTargets(v-model="project" resource-type="project")

    .col-span-1(v-if="permissions.customFields" class="sm:col-span-4 md:col-span-7 md:col-start-1")
      CustomFields(v-model="project" resource-type="project")
</template>
<script>
import { camelcase } from "@/helpers/StringHelpers";
import { startOfDay } from "date-fns";
import {
  formatDateApi,
  nextWorkingDay,
  disabledDates,
  isWorkingDay
} from "@/helpers/DateHelpers";

import CheckboxesField from "@/components/collections/CheckboxesField.vue";
import ProjectWatchers from "@/mixins/ProjectWatchers.js";
import KpiTargets from "@/components/collections/KpiTargets.vue";
import CustomFields from "@/components/collections/CustomFields.vue";
import gql from "graphql-tag";

let prevStartDate = null;

export default {
  components: {
    KpiTargets,
    CustomFields,
    CheckboxesField
  },

  apollo: {
    permissions: {
      query: gql`
        query permissions {
          trackablePermissions {
            canIndex
          }

          customFieldPermissions {
            canIndex
          }
        }
      `,
      update({ trackablePermissions, customFieldPermissions }) {
        return {
          customFields: trackablePermissions.canIndex,
          kpi: customFieldPermissions.canIndex
        };
      }
    }
  },

  mixins: [ProjectWatchers],

  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    const startToday = this.isNewProject ? "yes" : "no";
    const project = { ...this.value };

    return {
      project,
      messages: {
        isValidDate: "Must be a valid date",
        positive: "Must be a positive Integer",
        integer: "Must be a positive Integer",
        beforeDeadline: 'Must be the same as or before "Estimated completion"',
        afterStartDate: 'Must be the same as or after "Start date"',
        atLeastOneDay: "At least one day has to be selected"
      },
      // qualitiesOfFinish: [
      //   { name: "Low", value: "LOW" },
      //   { name: "Medium", value: "MEDIUM" },
      //   { name: "High", value: "HIGH" }
      // ],
      weekDays: [
        { name: "Sun", value: "sun" },
        { name: "Mon", value: "mon" },
        { name: "Tue", value: "tue" },
        { name: "Wed", value: "wed" },
        { name: "Thu", value: "thu" },
        { name: "Fri", value: "fri" },
        { name: "Sat", value: "sat" }
      ],
      startToday,
      changedDates: {
        startDate: false,
        deadline: false
      },
      startTodayOptions: [
        { name: "Yes", value: "yes" },
        { name: "No", value: "no" }
      ],
      permissions: {
        customFields: false,
        kpi: false
      }
    };
  },

  computed: {
    isNewProject() {
      return !this.project.id;
    },

    workingWeek() {
      return this.project.workingWeekAttributes;
    },

    // { sat: false, sun: false, mon: true, tue: ... }
    checkedWorkingDays() {
      let workingWeek = [];

      for (let day in this.workingWeek) {
        if (this.workingWeek[day]) {
          workingWeek.push(camelcase(day));
        }
      }

      return workingWeek;
    }
  },

  watch: {
    workingWeek: {
      handler(value) {
        let { startDate, deadline } = this.project;
        const workingDayCheck = isWorkingDay(value),
          futureWorkingDay = nextWorkingDay(value);
        startDate = new Date(startDate);
        deadline = new Date(deadline);

        if (
          (this.changedDates.startDate && futureWorkingDay < startDate) ||
          !workingDayCheck(startDate)
        ) {
          this.project.startDate = formatDateApi(futureWorkingDay);
          this.changedDates.startDate = true;
        }

        if (
          (this.changedDates.deadline && futureWorkingDay < deadline) ||
          !workingDayCheck(deadline)
        ) {
          this.project.deadline = formatDateApi(futureWorkingDay);
          this.changedDates.deadline = true;
        }
      },
      deep: true
    },

    startToday(newVal) {
      const nextValidStartDate = startOfDay(nextWorkingDay(this.workingWeek));
      let startDate = nextValidStartDate;

      if (newVal === "yes") {
        prevStartDate = this.project.startDate;
      } else if (prevStartDate) {
        startDate = prevStartDate;
      }

      this.project.startDate = formatDateApi(startDate);
    }
  },

  methods: {
    disabledDates() {
      const validators = disabledDates(this.workingWeek);
      return validators;
    },

    workingDaysUpdated(checkedDays) {
      for (let { value } of this.weekDays) {
        this.$set(this.workingWeek, value, checkedDays.includes(value));
      }
    }
  }
};
</script>
