<script>
import { Components } from "manageplaces-ui-kit";
import { initCap } from "@/helpers/StringHelpers";
import Vue from "vue";

export default Vue.extend({
  render(h) {
    const type = this.params.value;

    const icon = h(Components.AppIcon, {
      props: { icon: type },
      staticClass: "mr-2"
    });

    const text = h("span", initCap(type));
    return h("div", { staticClass: "flex flex-row items-center" }, [
      icon,
      text
    ]);
  }
});
</script>
