import { render, staticRenderFns } from "./DocumentsTableBreadcrumb.vue?vue&type=template&id=483d6cef&scoped=true&lang=pug&"
import script from "./DocumentsTableBreadcrumb.vue?vue&type=script&lang=js&"
export * from "./DocumentsTableBreadcrumb.vue?vue&type=script&lang=js&"
import style0 from "./DocumentsTableBreadcrumb.vue?vue&type=style&index=0&id=483d6cef&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "483d6cef",
  null
  
)

export default component.exports