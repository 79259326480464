import * as FilePond from "filepond";
import "filepond-polyfill";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";

import "filepond/dist/filepond.min.css";
import { apolloClient } from "@/vue-apollo";
import createDocument from "@/graphql/mutations/documents/createDocument.gql";
import createDocumentVersion from "@/graphql/mutations/documents/createDocumentVersion.gql";
import abortUploadMutation from "@/graphql/mutations/documents/abortUpload.gql";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import store from "@/store";

const FILE_EXT_BLACKLIST = /^(.*)\.(apk|ahk|xbe|run|cmd|jar|ipa|sk|exe|0xe|bin|workflow|8ck|bin|elf|air|xap|bat|scr|ac|app|gadget|u3p|com|out|widget|xlm|pif|script|gpe|73k|cgi|rxe|scb|scar|command|pafexe|xex|ex_|tcp|coffee|celx|epk|acc|ebs2|nexe|appimage|isu|rfu|fxp|action|ecf|89k|hta|vxp|plsc|xbap|rbf|ex4|fas|actc|icd|dld|cof|ws|js|ear|ps1|vpm|m3g|phar|x86_64|mlx|acr|jsx|a6p|wsh|applescript|zl9|vbs|ex_|mcr|a7r|rpj|x86|dek|ba_|caction|pex|server|pyc|iim|jsf|plx|vlx|pwc|wcm|exe1|mel|ms|mm|exe|snap|ex5|paf|ebm|mcr|prg|uvm|shb|bms|rgs|vbscript|otm|scpt|e_e|hms|frs|spr|mrc|widget|wiz|csh|cyw|fpi|ebs|mxe|mrp|gs|osx|pyo|xqt|sct|fky|azw2|fas|prg|kix|actm|app|app|es|rox|cel|seed|tms|vexe|arscript|n|vbe|tiapp|ezs|thm|beam|udf|wsf|mac|wpk|dmc|s2a|rfs|cheat|esh|ls|kx|mhm|ms|wpm|prc|sca|xys|scptd|shortcut|msl|jse|qit|lo|rbx|ds|mem|ebacmd|upx|dxl|mam|ksh|btm|exopc|eham|ygh|gpu|ipf|asb|vdo|mio|sapk|sbs|hpf|ita|ham|sts|prg|gm9|qpx|ezt|ore|smm|ncl|pvd)$/i;

const setOptions = () => {
  FilePond.registerPlugin(FilePondPluginFileValidateType);
  FilePond.registerPlugin(FilePondPluginImageExifOrientation);
  FilePond.setOptions({
    allowMultiple: true,
    allowRevert: false,
    allowImageExifOrientation: true,
    imageCropAspectRatio: "1:1",
    fileValidateTypeDetectType: (source, type) =>
      new Promise((resolve, reject) => {
        if (!FILE_EXT_BLACKLIST.test(source.name)) {
          return resolve("approved");
        } else {
          reject(type);
        }
      }),
    acceptedFileTypes: ["approved"],
    allowFileTypeValidation: true,
    fileValidateTypeLabelExpectedTypes: null,
    labelFileTypeNotAllowed: "File type not allowed",
    // fileValidateTypeDetectType: (source, type) =>
    //   new Promise((resolve, reject) => {

    //     if (store.state.documentManagement.mode === "version") {
    //       resolve(type);
    //     } else {
    //       // Do custom type detection here and return with promise
    //       var currentVersionFileType =
    //         store.state.documentManagement.newVersionDocument.currentVersion
    //           .upload.fileContentType;

    //       if (
    //         type.includes(currentVersionFileType) ||
    //         currentVersionFileType === null
    //       ) {
    //         resolve(type);
    //       } else {
    //         store._vm.$flash.error(
    //           "The new version file type should match the original"
    //         );
    //         reject(type);
    //       }
    //     }
    //   }),
    labelFileProcessingError(error) {
      if (error.body) {
        return error.body;
      }

      return "It was not possible to upload your document";
    },
    server: {
      process: (fieldName, file, metadata, load, error, progress, abort) => {
        let mutation;

        if (store.state.documentManagement.mode === "version") {
          mutation = createDocumentVersionMutation(file.name, file.size);
        } else {
          mutation = createDocumentMutation(file.name, file.size);
        }

        mutation
          .then(({ document, upload }) => {
            const formData = new FormData();
            const request = new XMLHttpRequest();
            const uuid = upload.uuid;

            formData.append(fieldName, file, file.name);
            formData.append("uuid", uuid);
            request.open("POST", "/uploads");
            request.setRequestHeader("X-CSRF-Token", getCSRF());

            request.upload.onprogress = e => {
              progress(e.lengthComputable, e.loaded, e.total);
            };

            request.onload = function() {
              try {
                const response = JSON.parse(request.response);
                if (request.status >= 200 && request.status < 300) {
                  load(document.id);
                } else {
                  error(response.error);
                }
              } catch (e) {
                error("An unexpected error occurred");
              }
            };
            request.send(formData);

            return {
              abort: () => {
                request.abort();
                abortUpload(formData.get("uuid"));
                abort();
              }
            };
          })
          .catch(e => {
            error(gqlErrorMessage(e));
          });
      },
      revert: null
    }
  });
};

const registerListeners = instance => {
  // Using `processfilestart` instead of `add` so that we
  // can track a file the is retried, not just when it gets
  // added the first time
  instance.on("processfilestart", file => {
    let { project, formLabel, standalone } = store.state.documentManagement;

    file.setMetadata({
      project: project,
      formLabel: formLabel
    });
    store.dispatch("documentManagement/addFile", file);

    if (standalone) {
      store.dispatch("documentManagement/showUploadSummary");
    }
  });

  // Keep track of the progress of the upload
  instance.on("processfileprogress", (file, progress) => {
    store.dispatch("documentManagement/uploadProgressChanged", {
      file,
      progress
    });
  });

  // Upload cancelled
  instance.on("onprocessfileabort", file => {
    store.dispatch("documentManagement/cancelUpload", file);
  });

  // Upload completed
  instance.on("processfile", (_error, file) => {
    store.dispatch("documentManagement/uploadComplete", file);
  });
};

const createFilepond = () => {
  setOptions();
  const instance = FilePond.create();
  registerListeners(instance);

  return instance;
};

function createDocumentVersionMutation(fileName, fileSize) {
  let variables = {
    input: {
      id: store.state.documentManagement.newVersionDocument.id,
      fileName: fileName,
      fileSize: fileSize
    }
  };

  return apolloClient
    .mutate({
      mutation: createDocumentVersion,
      variables
    })
    .then(response => {
      const { upload, document } = response.data.createDocumentVersion;
      return { upload, document };
    });
}

function createDocumentMutation(fileName, fileSize) {
  let { project, currentFolder } = store.state.documentManagement;
  if (store.state.documentUploadModal) {
    currentFolder = store.state.documentUploadModal.activeFolder;
  }

  let variables = {
    input: {
      name: fileName,
      fileName: fileName,
      fileSize: fileSize
    }
  };

  if (project) {
    variables.input.project = { id: project.id };
  }

  if (currentFolder && currentFolder.id) {
    variables.input.parent = { id: currentFolder.id };
  } else {
    variables.input.parent = null;
  }

  return apolloClient
    .mutate({
      mutation: createDocument,
      variables
    })
    .then(response => {
      const { upload, document } = response.data.createDocument;
      return { upload, document };
    });
}

function abortUpload(uuid) {
  return apolloClient.mutate({
    mutation: abortUploadMutation,
    variables: {
      input: {
        uuid: uuid
      }
    }
  });
}

function getCSRF() {
  let element = document.querySelector('meta[name="csrf-token"]');

  if (element) {
    return element.getAttribute("content");
  } else {
    return "";
  }
}

export { createFilepond };
