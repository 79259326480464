<template lang="pug">
  div(href='#', @click.prevent='clicked')
    app-icon.cursor-pointer(icon="delete", colour="red")
</template>

<script>
/* global $D */

export default {
  props: {
    confirmMessage: {
      default: "You are about to delete an item.",
      type: String
    },
    confirmTitle: {
      default: "Are you sure?",
      type: String
    }
  },
  methods: {
    clicked() {
      $D.fn.confirm(
        this.confirmTitle,
        this.confirmMessage,
        "Delete",
        "Cancel",
        false,
        () => {
          this.$emit("click");
        }
      );
    }
  }
};
</script>
