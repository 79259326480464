import Vue from "vue";
import VueRouter from "vue-router";

// import App from "@/App.vue";
import ProjectWizardRoutes from "./ProjectWizardRoutes";
import TaskWizardRoutes from "./TaskWizardRoutes";
import EstimateRoutes from "./EstimateRoutes";
import InvoiceRoutes from "./InvoiceRoutes";
import BillRoutes from "./BillRoutes";
import ChangeOrderRoutes from "./ChangeOrderRoutes";
import PurchaseOrderRoutes from "./PurchaseOrderRoutes";
import ReportsRoutes from "./ReportsRoutes";
import WelcomeRoutes from "./WelcomeRoutes";
import UserRoutes from "./UserRoutes";
import RolesRoutes from "./RolesRoutes";
import SubscriptionRoutes from "./SubscriptionRoutes";
import DocumentShareRoutes from "./DocumentShareRoutes";
import ContactRoutes from "./ContactRoutes";

Vue.use(VueRouter);

const routes = [
  ...ProjectWizardRoutes,
  ...TaskWizardRoutes,
  ...EstimateRoutes,
  ...BillRoutes,
  ...ChangeOrderRoutes,
  ...PurchaseOrderRoutes,
  ...InvoiceRoutes,
  ...ReportsRoutes,
  ...WelcomeRoutes,
  ...UserRoutes,
  ...SubscriptionRoutes,
  ...DocumentShareRoutes,
  ...ContactRoutes,
  ...RolesRoutes
];

// const vueRoutesSection = window.location.pathname.split("/")[1];
// const vueRouterMode = vueRoutesSection === "projects" ? "hash" : "history";

const router = new VueRouter({
  mode: "history",
  routes: routes
});

export default router;
