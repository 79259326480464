<template lang="pug">
  app-panel.panel.subscription-card.mb-4(v-if="showPaymentPanel")
    h2.font-bold.mb-4 Payment and invoices
    template(v-if="hasCardInfo")
      .flex.flex-row.justify-between
        .flex-1
          template
            .font-bold Last payment
            .text-grey-60 Payment successful, thank you

        .flex-initial
          span.font-bold.text-xl {{currency}}{{card && card.lastPaymentAmount ? normalizePrice(card.lastPaymentAmount) : '--'}}

      .h-px.bg-grey-30.my-4

    template(v-if="hasCardInfo")
      .flex.flex-row.justify-between
        .flex-1
          .font-bold Next payment
          .text-grey-60 Payment Due {{dueDate}}

        .flex-initial.ml-4.my-auto
          .font-bold.text-xl.my-auto {{nextPaymentSign}}{{currency}}{{card && card.nextPaymentAmount ? normalizePrice(card.nextPaymentAmount) : '--'}}

      .h-px.bg-grey-30.my-4

    .flex.flex-row.justify-between
      .flex-1
        .font-bold Current Payment Method
        credit-card.flex-1.mt-2(:last-digits="card.last4", :show-image="true", :brand="card.brand")
        .text-grey-60.font-bold.mt-2 {{card.username}}
        .text-grey-60 Expires {{expiry}}

      .flex-initial.ml-8(v-if="hasCardInfo")
        app-message(type="warning", v-if="isExpirable['status']")
          p Your card is going to expire in {{isExpirable['date']}}.

    .flex.flex-row.justify-between.flex-initial.mt-8
      .flex-1
        a(v-if="subscription.plan.name.toLowerCase() != 'free'"
          href="#", @click.prevent="onSubmit"
          data-cy="open-payment-submit") Change payment method
      .flex-initial
        a(href="#", @click.prevent="$emit('open-history')"
          data-cy="open-payment-history-submit") Payment history

</template>

<script>
/* global $D */
/* eslint-disable */
import moment from "moment";
import { normalizeDay } from "@/helpers/StringHelpers";
import CreditCard from "@/components/collections/CreditCard";
import FiananceMixin from "@/mixins/FinanceValidator";

export default {
  name: "SubscriptionPayment",
  components: {
    CreditCard
  },
  mixins: [FiananceMixin],
  props: {
    subscription: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    cardData: {
      type: Object,
      required: false
    },
    paymentHistory: {
      type: Object,
      required: false
    },
    totalPrice: {
      type: Number,
      required: false
    },
    currency: {
      type: String,
      required: true
    }
  },
  computed: {
    card() {
      return this.cardData
        ? this.cardData
        : {
            last4: null
          };
    },
    dueDate() {
      return this.card.currentPeriodEnd
        ? moment(this.card.currentPeriodEnd * 1000).format(
            $D.fn.datepicker.momentDateFormat
          )
        : null;
    },
    isExpirable() {
      const { expYear, expMonth } = this.card;
      const today = new Date();
      const endDate = moment([expYear, expMonth]);
      const duration = moment.duration(endDate.diff(today));
      const months = duration.asMonths();
      const days = Math.ceil(duration.asDays());

      if (this.subscription.billingFrequency === "a") {
        let date =
          Math.ceil(months) === 1 ? "1 month" : `${Math.ceil(months)} months`;
        if (months === 1 && days < 7) {
          date = days === 1 ? `${days} day` : `${days} days`;
        }

        return {
          date: date,
          status: months < 4 ? true : false
        };
      }

      return {
        date: days === 1 ? `${days} day` : `${days} days`,
        status: days < 7 ? true : false
      };
    },
    expiry() {
      const { expYear, expMonth } = this.card;
      return expYear && expMonth
        ? `${expYear} / ${normalizeDay(expMonth)}`
        : "--";
    },
    hasCardInfo() {
      return this.card ? true : false;
    },
    showPaymentPanel() {
      const { plan, isTrial, managedByStripe } = this.subscription;
      if (
        !managedByStripe ||
        isTrial ||
        (plan.name.toLowerCase() == "free" && !this.hasCardInfo)
      ) {
        return false;
      }

      return true;
    },
    nextPaymentSign() {
      if (card && card.nextPaymentAmount < 0) return "-";
      return "";
    }
  },
  methods: {
    onSubmit() {
      this.$emit("submit");
    }
  }
};
</script>
