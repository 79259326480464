<template lang="pug">
  app-dialog(@cancel="$emit('cancel')")
    template(#title) {{ document.name }}

    .flex-1.pt-8.overflow-hidden
      document-preview.preview(:document="document" :url="url")

    template(#footer)
      .text-right
        app-button(@click="$emit('cancel')") Close
</template>
<script>
import DocumentPreview from "@/components/documents/DocumentPreview.vue";

export default {
  components: {
    DocumentPreview
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  computed: {
    url() {
      return this.document.currentVersion.url;
    }
  }
};
</script>
<style lang="postcss" scoped>
.dialog {
  width: 1000px !important;
}

.preview {
  height: 50vh;
}
</style>
