<template lang="pug">
#search.ui.search.flex.items-center.absolute.top-0.right-0.bottom-0(ref="container", :class="{ open }" v-click-outside="hideSearch")
  input.h-10.text-grey-60.z-0(type="text" ref="search")
  app-icon.absolute.transform.cursor-pointer.right-0.z-10(
    icon="search"
    size="medium"
    colour="inherit"
    @click.native="openSearch")
  .results

</template>

<script>
/* global $, $D */

export default {
  data() {
    return {
      open: false
    };
  },
  mounted() {
    const el = this.$refs.container;
    this.initSearch(el);
  },
  methods: {
    initSearch(el) {
      $D.registerListener($D.GLOBAL, () => {
        $(el).search({
          type: "category",
          selector: {
            prompt: "input",
            searchButton: ".search.button",
            results: ".results",
            category: ".category",
            result: ".result"
          },
          apiSettings: {
            url: "/searches?search[query]={query}"
          },
          onSelect: function(res) {
            window.location.href = res.url;
          },
          cache: false,
          searchDelay: 400,
          showNoResults: true
        });
      });
    },
    openSearch() {
      this.open = true;
      this.$refs.search.focus();
    },
    hideSearch() {
      this.open = false;
      $(this.$refs.container)
        .data("searchModule")
        .hideResults();
    }
  }
};
</script>

<style lang="postcss" scoped>
.ui.search {
  position: absolute !important;

  &:not(.open) {
    .results {
      display: none;
    }
  }
  input[type="text"] {
    transition-property: all;
    transition-duration: 0.25s;
    transition-timing-function: ease;

    @apply bg-grey-90;
    @apply outline-none;
    @apply opacity-0;
    @apply cursor-default;
    width: 2rem;

    & + svg {
      @apply text-white;
      transform: scale(1);
      transform-origin: center;
      transition: transform 0.25s;
    }
  }

  &.open {
    input[type="text"] {
      @apply bg-grey-10;
      @apply border;
      @apply border-grey-70;
      @apply pl-4;
      @apply pr-12;
      @apply opacity-100;
      @apply cursor-auto;
      width: 300px;

      & + svg {
        @apply text-grey-60;
        transform: scale(0.57) translateX(-1rem);
      }
    }
  }
}
</style>
