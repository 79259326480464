<template lang="pug">
  app-panel(secondary)
    app-header(size="h3") Projects
      template(#subheader)
        | Permissions for managing projects

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")
      template(#options)
        app-checkbox.mb-6(label="View project" :value="true")
        app-checkbox.mb-6(v-if="selected === 'canManageAny'" label="Can create new projects" :value="permissions.createProject" @input="permissionChanged('createProject', $event)")
        app-checkbox.mb-6(label="Edit project" :value="permissions.editProject" @input="permissionChanged('editProject', $event)")
        app-checkbox.mb-6(label="Delete project" :value="permissions.removeProject" @input="permissionChanged('removeProject', $event)")
        app-checkbox.mb-6(label="Close project" :value="permissions.closeProject" @input="permissionChanged('closeProject', $event)")
        app-checkbox.mb-6(label="View KPI metrics" :value="permissions.viewTrackableData" @input="permissionChanged('viewTrackableData', $event)")
        app-checkbox.mb-6(label="Record KPI metrics" :value="permissions.createTrackableData" @input="permissionChanged('createTrackableData', $event)")
        template(v-if="features.developmentManagement")
          app-checkbox.mb-6(label="View project reports" :value="permissions.viewProjectReport" @input="permissionChanged('viewProjectReport', $event)")
          app-checkbox.mb-6(label="View forms" :value="permissions.viewFormValue" @input="permissionChanged('viewFormValue', $event)")
          app-checkbox.mb-6(label="Edit forms" :value="permissions.editFormValue" @input="permissionChanged('editFormValue', $event)")

</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    features: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> access projects",
          value: "cannotManage"
        },
        {
          label: "Can access <strong>assigned projects</strong> only",
          value: "canManageAssigned",
          slot: "options"
        },
        {
          label: "Can access <strong>any project</strong>",
          value: "canManageAny",
          slot: "options"
        }
      ],
      selected: "cannotManage",
      permissions: {
        createProject: false,
        viewProject: false,
        editProject: false,
        removeProject: false,
        closeProject: false,
        viewProjectReport: false,
        viewTrackableData: false,
        createTrackableData: false,
        viewFormValue: false,
        editFormValue: false
      },
      scope: "global"
    };
  },
  computed: {
    selectedPermissions() {
      if (!this.permissions.viewProject) {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: this.scope
      };
    }
  },
  mounted() {
    if (this.value.permissions.viewProject) {
      if (this.value && this.value.scope === "project") {
        this.selected = "canManageAssigned";
        this.scope = "project";
      } else {
        this.selected = "canManageAny";
        this.scope = "global";
      }
    }
  },
  methods: {
    optionChanged(value) {
      if (value !== "canManageAny") {
        this.permissions.createProject = false;
      }

      this.permissions.viewProject = value !== "cannotManage";
      this.scope = value === "canManageAssigned" ? "project" : "global";
      this.selected = value;
      this.notifyChange();
    },
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
