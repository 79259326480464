<template lang="pug">
  .flex.flex-col.justify-start
    app-radio-field(
      name="addKPI"
      v-model="addKPI"
      :options="yesNoOptions"
      :label="`Do you want to set KPI targets for this ${resourceType}?`"
      description="KPI targets let you set a limit or goal on any KPI in the system you wish to track.")

    .my-4(v-if="addKPI == 'yes'" class="w-5/6")
      app-message(v-if="trackables.length === 0")
        template(#title) You don't have any KPI metrics

        p
          | KPI metrics can be added in
          a.ml-1(href="/settings#/customise/kpi" target="blank" rel="noopener noreferrer") your settings.

        p You can still create your project and add KPI targets later


      template(v-else)
        template(v-if="trackingTargets.length")
          .block(v-for="(kpiTarget, idx) in trackingTargets" :key="fieldsKeys(kpiTarget)"
                :class="{ 'border-b border-grey-50': isLastTarget(idx), 'mt-5': isNewTrackingTarget(kpiTarget) }")
            .flex.flex-row.w-full.justify-around.align-center(v-if="isBeingEdited(kpiTarget)")
              app-dropdown-field.flex-1.ml-2(name="trackableId" label="KPI" description="Choose which KPI this target is for" v-model="copyOf(kpiTarget).trackableId"
                :show-optional="false" :options="filteredTrackables(kpiTarget)" track-by="id" value-attr="id" label-attr="subject" class="w-3/5")

              app-text-field.mx-2(name="quantity" label="Target" description="Specify the target for this KPI"
                type="number" v-model.number="copyOf(kpiTarget).quantity" class="w-2/5" :show-optional="false" @keyup.enter="saveTrackingTarget(newKpiTarget)")

              template(v-if="kpiTarget !== newKpiTarget")
                app-icon.cursor-pointer.ml-2.mb-8.self-end(icon="error" size="medium" colour="red" @click.native="quitEditingTrackingTarget(kpiTarget)")
                app-icon.cursor-pointer.ml-2.mb-8.self-end(icon="success" size="medium" colour="green" @click.native="saveTrackingTarget(kpiTarget)")
              .mr-16.pr-4(v-else)

            .flex.flex-row.w-full.justify-around.align-center.py-5(v-else)
              .flex-1.font-bold(class="w-2/4") {{ trackableName(kpiTarget.trackableId) }}
              .flex-1(class="w-1/4") {{ kpiTarget.quantity }}
              .flex-initial.flex.flex-row.justify-end
                app-icon.cursor-pointer.ml-8.self-end(icon="edit", colour="lightGrey", @click.native="editTrackingTarget(kpiTarget)")
                app-icon.cursor-pointer.ml-8.self-end(icon="delete", colour="red", @click.native="removeTrackingTarget(kpiTarget)")

        app-message(v-else)
          template(#title) You have no KPI targets
          | Click the "Add target" button below to add a new target

        template(v-if="newKpiTarget === null")
          app-button.mt-5(slim primary v-if="!canAddTarget" class="focus:outline-none focus:shadow-outline" @click.prevent="initTrackingTarget()")
            | Add target
        template(v-else)
          .mt-5
            app-button(primary slim class="focus:outline-none focus:shadow-outline" @click.prevent="saveTrackingTarget(newKpiTarget)")
              | Add target
            app-button.ml-3(slim class="focus:outline-none focus:shadow-outline" @click.prevent="quitEditingTrackingTarget(newKpiTarget)")
              | Cancel

</template>

<script>
import uuid from "uuid";

import { resourceWatchers } from "@/helpers/WatcherHelpers.js";

import Trackables from "@/graphql/queries/core/company/Trackables.gql";

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    resourceType: {
      type: String,
      required: true
    }
  },

  apollo: {
    trackables: {
      query: Trackables,
      update({ trackables }) {
        return trackables.edges.map(({ node }) => node);
      }
    }
  },

  data() {
    const resource = { ...this.value };
    const { trackingTargetsAttributes } = this.value;
    const addKPI = trackingTargetsAttributes.length ? "yes" : "no";

    return {
      addKPI,
      newKpiTarget: null,
      resource,
      trackingTargetAttributesBackup: trackingTargetsAttributes,
      yesNoOptions: [
        { name: "No", value: "no" },
        { name: "Yes", value: "yes" }
      ],
      trackables: [],
      targetsBeingEdited: [],
      targetsCopies: []
    };
  },

  computed: {
    canAddTarget() {
      return (
        this.trackingTargetsAttributes.length > 0 &&
        this.trackingTargetsAttributes.length == this.trackables.length
      );
    },
    trackingTargets() {
      const targets = [...this.trackingTargetsAttributes];
      if (this.newKpiTarget) {
        targets.push(this.newKpiTarget);
      }
      return targets;
    },

    filteredTrackables() {
      return currentRf => {
        return this.trackables.filter(cf => {
          const isThisSameCf = cf.id === currentRf.trackableId;
          const isAlreadyUsed = this.trackingTargets.some(rf => {
            if (rf === currentRf) return false;
            return rf.trackableId == cf.id;
          });

          return isThisSameCf || !isAlreadyUsed;
        });
      };
    },

    fieldsKeys() {
      const keys = new WeakMap();

      return kpiTarget => {
        let key = keys.get(kpiTarget);

        if (!key) {
          key = uuid();
          keys.set(kpiTarget, key);
        }

        return key;
      };
    },

    trackableName() {
      const names = {};
      this.trackables.forEach(t => (names[t.id] = t.subject));

      return id => names[id];
    },

    trackingTargetsAttributes() {
      return this.resource.trackingTargetsAttributes || [];
    },

    copyOf() {
      const fieldsIdx = new WeakMap();

      return target => {
        if (target == this.newKpiTarget) return target;

        let idx = fieldsIdx.get(target);

        if (!idx) {
          idx = this.targetsBeingEdited.indexOf(target);
          fieldsIdx.set(target, idx);
        }

        return this.targetsCopies[idx];
      };
    },

    canAddTargetField() {
      return (
        this.trackingTargetsAttributes.length > 0 &&
        this.trackingTargetsAttributes.length == this.trackables.length
      );
    }
  },

  watch: {
    ...resourceWatchers("resource"),

    addKPI(newVal) {
      if (newVal === "no") {
        this.resource.trackingTargetsAttributes = [];
      } else {
        this.resource.trackingTargetsAttributes = this.trackingTargetAttributesBackup;
      }
    }
  },
  methods: {
    isLastTarget(idx) {
      return this.newKpiTarget
        ? idx < this.trackingTargets.length - 2
        : idx < this.trackingTargets.length - 1;
    },

    isNewTrackingTarget(kpiTarget) {
      return kpiTarget == this.newKpiTarget;
    },

    initTrackingTarget() {
      if (!this.canAddTargetField) {
        this.newKpiTarget = {
          quantity: 0,
          trackableId: null
        };
        this.editTrackingTarget(this.newKpiTarget);
      } else {
        this.newKpiTarget = null;
        this.editTrackingTarget({
          quantity: null,
          trackableId: null
        });
      }
    },

    saveTrackingTarget(target) {
      if (target != this.newKpiTarget) {
        Object.assign(target, this.copyOf(target));
      }

      if (!target.trackableId) return;

      if (!this.trackingTargetsAttributes.includes(target)) {
        this.trackingTargetsAttributes.push(target);
      }
      if (this.newKpiTarget == target) {
        this.initTrackingTarget();
      }
      this.cancelEditTrackingTarget(target);
    },

    editTrackingTarget(target) {
      if (this.isBeingEdited(target)) return;
      this.targetsBeingEdited.push(target);
      this.targetsCopies.push({ ...target });
    },

    cancelEditTrackingTarget(target) {
      const idx = this.targetsBeingEdited.indexOf(target);
      this.targetsBeingEdited.splice(idx, 1);
      this.targetsCopies.splice(idx, 1);
    },

    quitEditingTrackingTarget(target) {
      if (target === this.newKpiTarget) {
        this.newKpiTarget = null;
      }
      this.cancelEditTrackingTarget(target);
    },

    isBeingEdited(target) {
      return this.targetsBeingEdited.includes(target);
    },

    removeTrackingTarget(target) {
      const idx = this.trackingTargetsAttributes.findIndex(
        tt => this.fieldsKeys(tt) === this.fieldsKeys(target)
      );
      this.trackingTargetsAttributes.splice(idx, 1);
    }
  }
};
</script>
