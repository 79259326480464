/* global $ */
/* eslint no-console: 0 */

/**
 * This directive can be used on a ui tab to change
 * the value of a hidden input field when the tab
 * changes. If effectively allows a tab to act like
 * a radio button.
 *
 * In order to use, this directive should be placed
 * on the tab menu itself. The menu should then contain
 * a hidden input as a child, and each menu item should
 * have a `data-value` attribute which will be used to
 * set the hidden inputs value.
 *
 * Example:
 *
 * .ui.tab.menu{ 'v-radio-tab' => '' }
 *   %a.item{ 'data-value' => '1' } 1
 *   %a.item{ 'data-value' => '2' } 2
 *   %input{ type: 'hidden', name: 'value' }
 */
export default el => {
  const $input = $(el).find("> input[type=hidden]");
  if (!$input.length) {
    console.warn("No input field found in tabs menu.");
    return;
  }

  $(el)
    .find("> .item")
    .on("tab:visible", function setVal() {
      const val = $(this).data("value");
      $input.val(val);
    });
};
