<template lang="pug">
  app-dialog.attach(@cancel="$emit('cancel')")
    template(#header)
      app-header
        | Attach to tasks
        template(#subheader) Select one or more tasks to attach to

    .mb-3.mt-4.text-lg Search for a task

    multiselect.mb-4(
      v-model="task",
      placeholder="Search for a task",
      label="name",
      track-by="name",
      :options="grouped"
      :show-labels="false"
      group-values="entries",
      group-label="label",
      @input="(task) => select(task)"
    )
      template(slot="option", slot-scope="props")
        .flex.ml-2.mr-2(v-if="props.option.id")
          app-checkbox(:value="isSelected(props.option.id)", label="")
          .flex.w-full.justify-between.items-center
            .flex.w-full
              .info
                .mb-1 {{props.option.name}}
                .path.text-grey-70 Last update: {{ formatDate(props.option.lastUpdate) }}
            .flex.w-40.justify-between
              .flex-1
                task-status.align-top.ml-2.w-24.text-center(:task="props.option" :dropdown="false")

      template(slot="noResult")
        | Nothing found

    app-panel.h-64.overflow-auto.plan-card-content(secondary)
      .h-full.flex.flex-col.justify-center.text-center(v-if="selected_tasks.length == 0") No tasks selected
      .div.mb-4(v-for="(task, index) in selected_tasks")
        .flex.justify-between.items-center
          .flex
            .info
              .mb-1 {{task.name}}
              .path.text-grey-70 Last update: {{formatDate(task.lastUpdate)}}
          .flex.w-40.justify-between
            .flex-1
              task-status.align-top.ml-2.w-24.text-center(:task="task" :dropdown="false")
            .remove
              app-icon.cursor-pointer(colour="red", icon="delete", @click="selected_tasks.splice(task, 1)")

    .flex-none.pt-6
      .flex.flex-row.justify-end
        app-button.mr-4(secondary @click="$emit('cancel')") Cancel
        app-button(primary @click="ok") Attach


</template>
<script>
import { formatDate } from "@/helpers/DateHelpers";

export default {
  props: {
    tasks: Array
  },
  data() {
    return { task: "", selected_tasks: [] };
  },
  computed: {
    grouped() {
      return [
        {
          label: "Tasks",
          entries: this.tasks
        }
      ];
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date, this.$store.state.dateFormats.dateFns);
    },
    isSelected(id) {
      return this.selected_tasks.filter(d => d.id == id).length > 0;
    },
    select(task) {
      this.task = null;
      let idx = this.selected_tasks.map(sd => sd.id).indexOf(task.id);
      if (idx > -1) {
        this.selected_tasks.splice(idx, 1);
        return;
      }
      this.selected_tasks.push(this.tasks.filter(t => t.id == task.id)[0]);
    },
    ok() {
      this.$emit("ok", {
        taskIds: this.selected_tasks.map(task => task.id)
      });
    }
  }
};
</script>
<style lang="postcss" scoped>
/deep/ .multiselect__option--group {
  @apply m-4;
  @apply border;
  @apply border-grey-30;
  @apply rounded-md;
  @apply py-2;
  @apply px-2;
  @apply bg-grey-10 !important;
  min-height: initial;
  color: var(--grey-80) !important;
}

.dialog.attach {
  min-height: 200px;
  width: 750px;
}
</style>
