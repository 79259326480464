/**
 * Gets the visible child rows for the given
 * row.
 **/
export function forEachChild(api, row, fn) {
  if (row.data.childCount === 0) {
    return;
  }

  const n = api.getLastDisplayedRow();
  let i = row.rowIndex + 1;

  for (i; i <= n; i++) {
    const child = api.getDisplayedRowAtIndex(i);

    // We don't want to select any siblings so break
    // if the level is the same or less than this node
    if (child.data.level <= row.data.level) {
      break;
    }

    fn(child);
  }
}

/**
 * Finds each parent in the visible hierarchy for the
 * given node
 **/
export function forEachParent(api, row, fn) {
  if (row.data.level === 0) {
    return;
  }

  let i = row.rowIndex - 1;

  // Going backwards up the hierarchy
  for (i; i >= 0; i--) {
    const node = api.getDisplayedRowAtIndex(i);
    if (node.data.level >= row.data.level) {
      // This isn't a parent it's a sibling or
      // a child of a sibling.
      continue;
    }

    fn(node);
  }
}

/**
 * Find the parent table node for the given row
 **/
export function getParent(api, row) {
  let i = row.rowIndex;
  for (i; i >= 0; i--) {
    const parent = api.getDisplayedRowAtIndex(i);
    if (parent.data.level < row.data.level) {
      return parent;
    }
  }

  return null;
}

function isTriggeringNode(node, trigger) {
  return node.id === trigger.id;
}

function isChild(child, parent) {
  return child.data.level > parent.data.level;
}

function buildNewParent(node) {
  return {
    node,
    displayed: 0,
    childCount: node.data.childCount,
    selected: 0,
    indeterminate: false
  };
}

function hasChildren(node) {
  return node.data.childCount > 0;
}

export function updateSelection(api, triggerRow) {
  let stack = [];
  let current = null;
  let i;
  let isTrigger = false;

  const n = api.getLastDisplayedRow();

  for (i = 0; i <= n; i++) {
    const node = api.getDisplayedRowAtIndex(i);

    if (isTriggeringNode(node, triggerRow)) {
      if (current && isChild(node, current.node)) {
        // There's a parent, and this is a child of that
        current.displayed++;
        if (node.isSelected()) {
          current.selected++;
        }
      }

      // If this is a parent, start a new parent and continue
      if (hasChildren(node)) {
        if (current && node.data.level <= current.node.data.level) {
          // There's already a parent, but this isn't a child of it
          // so we'll update that and start a new parent
          updateParentStatus(api, current);
          const indeterminate = current.indeterminate;
          current = stack.pop();

          // If our last parent was indeterminate, then it must cascade up
          if (current && indeterminate) {
            current.indeterminate = indeterminate;
          }
        }

        stack.push(current);
        current = buildNewParent(node);
        isTrigger = true;
      }

      continue;
    }

    // If we're currently skipping triggered children, then
    // just continue unless we're back to the parent
    if (isTrigger && isChild(node, triggerRow)) {
      node.indeterminate = false;
      current.displayed++;
      if (node.isSelected()) {
        current.selected++;
      }

      if (node.data.level > triggerRow.data.level + 1) {
        // It's a child of a child, so we need to tally up the counts
        // of things. If we don't then when the final parents are
        // updated, we will miss the indeterminate calculations
        if (hasChildren(node)) {
          current.childCount += node.data.childCount;
        } else {
          current.childCount++;
        }
      }

      continue;
    }

    // No longer a triggering row child
    isTrigger = false;

    // Now we're not looking at children of the triggered row, we
    // need to check if we're still in the current parent, or if
    // we're done with that one
    if (current && node.data.level <= current.node.data.level) {
      // We've gone back a level, so we've left the current parent.
      // We'll now update the parent node's status, and pop it from
      // the stack
      updateParentStatus(api, current);
      const indeterminate = current.indeterminate;
      current = stack.pop();

      // If our last parent was indeterminate, then it must cascade up
      if (current && indeterminate) {
        current.indeterminate = indeterminate;
      }
    }

    if (current) {
      current.displayed++;
      if (node.data.childCount === 0 && node.isSelected()) {
        current.selected++;
      }
    }

    if (node.data.childCount > 0) {
      // New parent here
      stack.push(current);
      current = buildNewParent(node);
    }
  }

  // If we've finished our run through, and we still have a
  // parent in the stack then we've got one more update to
  // do

  let parent = stack.pop();
  while (parent) {
    updateParentStatus(api, parent);

    const { indeterminate, childCount, selected, displayed, node } = parent;
    parent = stack.pop();

    if (parent) {
      // If our last parent was indeterminate, then it must cascade up
      if (indeterminate) {
        parent.indeterminate = indeterminate;
      }

      // Increment the child count of the parent to include the children.
      // This will ensure it correctly calculated indeterminate statuses
      // if needed
      if (displayed > 0) {
        parent.childCount += childCount;
      }
      parent.selected += selected;
      if (node.isSelected()) {
        parent.selected++;
      }
    }
  }
}

function updateParentStatus(api, parent) {
  const allChildrenSelected = parent.childCount === parent.selected;
  let selected;

  if (parent.indeterminate) {
    selected = false;
    parent.indeterminate = true;
  } else if (allChildrenSelected) {
    // All children are selected, so this parent will be marked as selected
    selected = true;
    parent.indeterminate = false;
  } else if (parent.displayed === 0) {
    // No children are actually displayed, so we won't change anything
    selected = parent.node.isSelected();
  } else if (parent.selected === 0) {
    // No children are selected, so the parent won't be
    selected = false;
    parent.indeterminate = false;
  } else {
    // One or more children are selected, but not all of them
    selected = false;
    parent.indeterminate = true;
  }

  parent.node.indeterminate = parent.indeterminate;
  setRowSelection(api, parent.node, selected);
}

export function setRowSelection(api, node, selected) {
  const currentlySelected = node.isSelected();
  // const indeterminate = node.indeterminate;

  node.setSelected(selected);

  if (currentlySelected === selected) {
    // We need to force an update by dispatching the event. Because
    // the row selection hasn't technically change, ag-grid won't
    // dispatch the event, but because we want to mark it as indeterminate
    // we need it to be notified
    api.dispatchEvent({ type: "rowSelected", node: node });
  }
}
