import SubtasksQuery from "@/graphql/queries/core/tasks/TaskSubtasks.gql";
import { setRowSelection } from "@/components/projects/tasks_table/helpers/ProjectTasksTableGridHelpers";

const ExpansionMixin = {
  methods: {
    /**
     * Each task in the tree must have a `level` to know how far
     * to indent it in the table. This function will apply the
     * appropriate level to the task
     **/
    setLevel(task, parent) {
      if (parent) {
        task.level = parent.level + 1;
      } else {
        task.level = 0;
      }

      if (task.children?.length) {
        task.children.forEach(t => this.setLevel(t, task));
      }
    },
    /**
     *  Expands or collapses the row for the given
     *  task. This will also handle the loading of
     (  sub tasks if they're not already loaded
     */
    toggleRowExpansion(task, expand = undefined) {
      const treeNode = this.taskTree.findNode(task.id);
      const expanding = expand || !treeNode.isExpanded;

      if (expanding) {
        return this.expandRow(treeNode);
      } else {
        return this.collapseRow(treeNode);
      }
    },

    /**
     *  Expand a row in the table. This will load
     *  sub tasks if they've not already been loaded
     *  before, and present them
     */
    expandRow(task) {
      if (task.isExpanded) {
        return Promise.resolve();
      }

      // Add a "level" to the task if it's missing. This will
      // be the root level
      if (!task.level) {
        task.level = 0;
      }

      // Find out if data has already been loaded
      if (!task.subtasksLoaded) {
        // Only load if the subtasks haven't already been loaded
        return this.$apollo
          .query({
            query: SubtasksQuery,
            variables: {
              taskId: task.id,
              root: true
            },
            fetchPolicy: "no-cache"
          })
          .then(result => {
            const children = this.setChildProperties(
              result.data.task.subtasks,
              task
            );

            task.expanded = true;
            task.subtasksLoaded = true;
            this.taskTree.insertNodes(children, task);
            this.gridApi().applyTransaction({
              update: [task.data]
            });
            this.refreshExpansionState(task);
            this.insertSubtasks(task);
          });
      } else {
        // Just expand, we've already loaded the subtasks
        return Promise.resolve().then(() => {
          task.expanded = true;
          this.insertSubtasks(task);
          this.refreshExpansionState(task);
        });
      }
    },

    /**
     *  Collapse a row in the table. This will not
     *  remove the actual task data, but it will
     *  remove it from the table's copy of the data
     */
    collapseRow(task) {
      // Get all child tasks that need to be removed
      return Promise.resolve().then(() => {
        task.expanded = false;
        const node = this.gridApi().getRowNode(task.id);
        const transaction = {
          remove: this.taskTree.getAllChildren(task),
          update: [task.data]
        };

        this.gridApi().applyTransaction(transaction);
        if (node.indeterminate) {
          node.indeterminate = false;
          setRowSelection(this.gridApi(), node, node.isSelected());
        }
        this.refreshExpansionState(task);
      });
    },

    /**
     * Inserts the subtasks of the given task into the
     * table.
     */
    insertSubtasks(task) {
      if (this.isSorted()) {
        // Because we've got sorting applied, we need to correctly calculate the position
        // of the children we're inserting. This is a pretty slow operation, but if we
        // don't do it then the table will be in some really strange order
        const sortedTasks = this.getSortedTasks();
        this.setRowData(sortedTasks);
      } else {
        const row = this.gridApi().getRowNode(task.id);
        const selected = row.isSelected();
        const children = this.taskTree.getAllChildren(task).map(c => c.data);
        const transaction = {
          add: children,
          addIndex: row.rowIndex + 1
        };
        const result = this.gridApi().applyTransaction(transaction);

        if (selected) {
          // Set the newly added rows to be selected since the parent was
          result.add.forEach(n => n.setSelected(true));
        }
      }
    },

    refreshExpansionState(task) {
      //const rowNode = this.gridApi().getRowNode(task.id);
      const rowNode = this.gridApi().getRowNode(task.id);
      rowNode.setData(task.data);
      this.gridApi().refreshCells({
        rowNodes: [rowNode],
        columns: ["name"],
        force: true
      });
    },

    /**
     * Sets the level/depth for the specified tasks
     */
    setChildProperties(tasks, parent) {
      tasks.forEach(task => {
        task.level = parent.level + 1;
      });

      return tasks;
    }
  }
};

export default ExpansionMixin;
