<template lang="pug">
  app-panel(secondary class="w-1/2")
    app-header(size="h3") Roles and permissions
      template(#subheader)
        | Permissions for controlling permission and access

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")
      template(#canManage)
        app-checkbox.mb-6(label="View roles" :value="true")
        app-checkbox.mb-6(label="Edit existing roles" :value="permissions.editRoles" @input="permissionChanged('editRoles', $event)")
        app-checkbox.mb-6(label="Delete roles" :value="permissions.removeRoles" @input="permissionChanged('removeRoles', $event)")
        app-checkbox(label="Create new roles" :value="permissions.createRoles" @input="permissionChanged('createRoles', $event)")
</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> access roles and permissions",
          value: "cannotManage"
        },
        {
          label: "<strong>Can</strong> access roles and permissions",
          value: "canManage",
          slot: "canManage"
        }
      ],
      selected: "cannotManage",
      permissions: {
        viewRoles: false,
        createRoles: false,
        editRoles: false,
        removeRoles: false
      }
    };
  },
  computed: {
    selectedPermissions() {
      if (!this.permissions.viewRoles) {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: "global"
      };
    }
  },
  mounted() {
    if (this.value.permissions.viewRoles) {
      this.selected = "canManage";
    }
  },
  methods: {
    optionChanged(value) {
      this.permissions.viewRoles = value === "canManage";
      this.selected = value;
      this.notifyChange();
    },
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
