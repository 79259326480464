import store from "@/store";

export default {
  computed: {
    modelName() {
      return this.resourceType.toLowerCase();
    },

    wizardState() {
      return this.$store.state.wizardState;
    },

    failedSteps() {
      return Array.from(this.wizardState.failedSteps);
    },

    failedFields() {
      return this.wizardState.failedFields;
    },

    steps() {
      return this.wizardState.steps;
    },

    progressSteps() {
      return this.steps.slice(1);
    },

    currentStep: {
      get() {
        return this.wizardState.currentStep;
      },

      set(step) {
        store.commit("setCurrentStep", step);
      }
    },

    currentComponent() {
      return (
        this.stepComponent[this.currentStep] ||
        `${this.resourceType}${this.currentStep}`
      );
    },

    stepComponent() {
      return {};
    },

    currentStepIdx() {
      return this.stepIdx(this.currentStep);
    },

    validatedFields() {
      return {};
    }
  },

  methods: {
    isLastStep(step = this.currentStep) {
      return this.stepIdx(step) == this.steps.length - 1;
    },

    isFirstStep(step = this.currentStep) {
      return this.stepIdx(step) == 0;
    },

    // this touches the fields of all the steps before this
    touchFieldsBefore(step) {
      const v = this.$v[this.modelName];
      const stepsBefore = this.stepsBefore(step);
      const { validatedFields } = this;

      stepsBefore.forEach(step => {
        const fields = validatedFields[step];
        if (!fields) return;

        fields.forEach(field => v[field] && v[field].$touch());
      });
      return stepsBefore;
    },

    stepIs(step) {
      return this.currentStep === step;
    },

    stepIdx(step) {
      return this.steps.indexOf(step);
    },

    stepsBefore(step) {
      const stepIdx = this.steps.indexOf(step);
      return this.steps.slice(0, stepIdx + 1);
    },

    findFailedStepsBefore(step) {
      const stepsBefore = this.stepsBefore(step);
      const { validatedFields } = this;

      stepsBefore.forEach(step => {
        const fields = validatedFields[step];
        if (!fields) return;

        this.initializeInvalidFields(step);

        fields.forEach(field => {
          if (this.fieldValid(field)) {
            this.setFieldValid(field, step);
          } else {
            this.setFieldInvalid(field, step);
          }
        });
      });
    },

    fieldValid(field) {
      const v = this.$v[this.modelName];

      if (!v[field]) return true;

      return !v[field].$anyError;
    },

    setCurrentStep(step) {
      this.touchFieldsBefore(step);
      this.findFailedStepsBefore(step);

      this.goToStep(step);
    },

    goToStep(step) {
      this.currentStep = step;
      this.$scrollTo(".container", 200, { container: "body" });
    },

    goNext() {
      this.touchFieldsBefore(this.currentStep);
      this.findFailedStepsBefore(this.currentStep);

      if (this.failedSteps.length) {
        this.goToStep(this.firstFailedStep());
      } else if (this.isLastStep()) {
        this.save();
      } else {
        const nextStepIdx = this.currentStepIdx + 1;
        this.goToStep(this.steps[nextStepIdx]);
      }
    },

    goBack() {
      if (this.isFirstStep()) {
        this.cancel();
      } else {
        const prevStepIdx = this.currentStepIdx - 1;
        this.goToStep(this.steps[prevStepIdx]);
      }
    },

    cancel() {
      // console.log(
      //   "Not implemented, this should go back to the resources list page"
      // );
    },

    firstFailedStep() {
      const failedSteps = this.failedSteps.sort((step1, step2) => {
        const idx1 = this.steps.indexOf(step1);
        const idx2 = this.steps.indexOf(step2);

        if (idx1 < idx2) return -1;
        if (idx1 > idx2) return 1;

        return 0;
      });
      return failedSteps[0];
    },

    setFieldInvalid(field, step) {
      this.$store.commit("setFieldInvalid", { step, field });
      this.setStepFailed(step);
    },

    setStepFailed(step) {
      this.$store.commit("setStepFailed", step);
    },

    setFieldValid(field, step) {
      this.$store.commit("setFieldValid", { step, field });

      if (!this.hasInvalidFields(step)) {
        this.setStepNotFailed(step);
      }
    },

    setStepNotFailed(step) {
      this.$store.commit("setStepSucceeded", step);
    },

    hasInvalidFields(step) {
      return !!this.failedFields.get(step).size;
    },

    stepInvalidFields(step) {
      return this.failedFields.get(step);
    },

    initializeInvalidFields(step) {
      this.$store.commit("initInvalidFieldsFor", step);
    }
  }
};
