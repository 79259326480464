import Wizard from "@/components/projects/wizard/Wizard.vue";

export default [
  {
    path: "/projects/new",
    name: "newProjectWizard",
    component: Wizard
  },
  {
    path: "/projects/:id/edit",
    name: "editProjectWizard",
    component: Wizard
  }
];
