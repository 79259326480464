const state = () => ({
  // General tracking
  activeFolder: null,
  newFolder: null,
  folders: []
});

const mutations = {
  setActiveFolder(state, { folder }) {
    state.activeFolder = folder;
  },
  setFolders(state, { folders }) {
    state.folders = folders;
  },
  addNewFolder(state, folder) {
    state.newFolder = folder;
    state.folders.push(folder);
  },
  clearNewFolder(state) {
    state.newFolder = null;
  },
  updateNewFolderName(state, name) {
    state.newFolder.name = name;
  }
};

const actions = {
  folderSelected({ commit }, { folder }) {
    commit("setActiveFolder", { folder });
  }
};

const getters = {
  folderTree(state) {
    if (!state.folders) {
      return {};
    }

    let treeNodes = {};
    let len = state.folders.length;

    for (let i = 0; i < len; i++) {
      (treeNodes[state.folders[i].parentPath] =
        treeNodes[state.folders[i].parentPath] || []).push(state.folders[i]);
    }

    return treeNodes;
  },
  subfolders(state, getters) {
    return folderPath => getters.folderTree[folderPath];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
