<template lang="pug">
#gantt-container.flex-1.flex.flex-col.overflow-hidden
  .inverted.ui.dimmer
    .flex.flex-col.h-full.items-center.justify-center
      app-message.text-left.shadow-lg(type="error")
        p.block.mb-4 Someone else has updated this project. Please reload the page and try again
        a.basic.ui.button(href="javascript:window.location.reload(true)") Reload
  toolbar(v-on="toolbarListeners()", :loading="isLoading")
  .flex-1.bg-white.shadow(ref="gantt")
</template>

<script>
/**
 * THINGS TO DO:
 *
 * - In data processor, replace table manager reference with an updated version
 * - Replace all jQuery references
 * - In gantt events, sort out the lightbox. Lots of issues there
 **/

/* global cssVars, $ */

import { mapState, mapGetters } from "vuex";

import { gantt } from "dhtmlx-gantt";
import GanttBaselines from "@/components/projects/gantt/ProjectGanttBaselines";
import GanttConfiguration from "@/components/projects/gantt/ProjectGanttConfiguration";
import GanttEvents from "@/components/projects/gantt/ProjectGanttEvents";
import GanttInteractions from "@/components/projects/gantt/ProjectGanttInteraction";
import GanttTemplates from "@/components/projects/gantt/ProjectGanttTemplateConfiguration";
import ganttLayout from "@/components/projects/gantt/ProjectGanttLayout";
import ProjectTaskView from "@/mixins/projects/ProjectTaskViewMixin";

import Toolbar from "@/components/projects/gantt/ProjectGanttToolbar.vue";
import {
  calculateWorkingDaysBetween,
  setTaskType
} from "@/components/projects/gantt/ProjectGanttUtils";
import moment from "moment";
import TaskManager from "@/components/projects/tasks_table/TaskManager";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import { humanize } from "@/helpers/StringHelpers";

export default {
  name: "ProjectGantt",
  components: {
    Toolbar
  },
  mixins: [
    ProjectTaskView,
    GanttBaselines,
    GanttConfiguration,
    GanttEvents,
    GanttInteractions,
    GanttTemplates
  ],
  data() {
    return {
      // api_v1_project_path
      projectUpdateUrl: `/api/projects/${this.projectId}`,
      // project_gantt_charts_path
      ganttUrl: `/projects/${this.projectId}/gantt_charts`,
      // update_tasks_project_gantt_charts_path
      ganttTasksUrl: `/projects/${this.projectId}/gantt_charts/update_tasks`,

      // startDate: new Date(this.target.data("startDate")), // Only used in the lightbox. Probably removable
      // endDate: new Date(this.target.data("endDate")), // Only used in the lightbox. Probably removable

      taskBeingMoved: null,
      taskFilterRegexp: null
    };
  },
  computed: {
    ...mapState("taskManagement", ["lockVersion", "readOnly"]),
    ...mapGetters("taskManagement", ["isLoading"])
  },
  mounted() {
    gantt.config.layout = ganttLayout;

    this.processStyles();
    this.configureGantt();
    this.configureTemplates();
    this.configureEvents();
    this.addTodayLine();

    this.setupInteractions();
    this.initBaselines();
    this.initGantt();

    // Need to add the export code
    this.injectExportScript();
  },
  methods: {
    updateTask(task) {
      if (gantt.isTaskExists(task.id)) {
        const ganttTask = gantt.getTask(task.id);
        const startDate = moment(task.startsAt).toDate();
        const endDate = moment(task.deadline)
          .add(1, "day")
          .toDate();

        ganttTask.text = ganttTask.name = task.name;
        ganttTask.starts_at = startDate;
        ganttTask.start_date = startDate;
        ganttTask.status = humanize(task.status);
        ganttTask.end_date = endDate;
        ganttTask.duration = calculateWorkingDaysBetween(startDate, endDate);

        // Only update its type if it's a task or milestone. Task types can't change
        if (ganttTask.type !== "project") {
          ganttTask.type = task.isMilestone ? "mp_milestone" : "task";
        }

        gantt.refreshTask(task.id);
      }
    },
    filterTasks() {
      this.taskFilterRegexp = this.search
        ? new RegExp(`\\b${this.search}`, "i")
        : null;
      gantt.render();
    },
    addTodayLine() {
      const dateToStr = gantt.date.date_to_str(gantt.config.task_date);
      const id = gantt.addMarker({
        start_date: new Date(),
        css: "today",
        title: dateToStr(new Date()),
        text: "Today"
      });

      const interval = setInterval(() => {
        const today = gantt.getMarker(id);

        if (!today) {
          clearInterval(interval);

          return;
        }

        today.start_date = new Date();
        today.title = dateToStr(today.start_date);
        gantt.updateMarker(id);
      }, 1000 * 60);
    },

    initGantt() {
      gantt.init(this.$refs.gantt);
      this.requestStarted();

      if (this.showBaselines) {
        this.$refs.gantt
          .querySelector(".gantt_layout_root")
          .classList.add("with-baselines");
      }

      gantt.load(this.ganttUrl, "json", () => {
        this.requestFinished();
        this.onGanttInitialised();
      });
    },

    injectExportScript() {
      const scriptTag = document.createElement("script");
      scriptTag.setAttribute("src", "https://export.dhtmlx.com/gantt/api.js");
      document.head.appendChild(scriptTag);
    },

    processStyles() {
      // Manually convert the gantt styling. We're doing this
      // explicitly so that we can easily get it again to send
      // to DHTMLX for printing the PDF version
      cssVars({
        onlyLegacy: false,
        include: "link[data-exclude]",
        updateDOM: false,
        onComplete: cssText => {
          const head = document.head;
          const style = document.createElement("style");

          style.type = "text/css";
          style.id = "ganttStyles";
          style.appendChild(document.createTextNode(cssText));

          head.appendChild(style);
        }
      });
    },

    taskCreated({ task, parent }) {
      const startDate = moment(task.startsAt).toDate();
      const endDate = moment(task.deadline)
        .add(1, "day")
        .toDate();

      this.persistence.ignore(() => {
        gantt.addTask(
          {
            id: task.id,
            baseline_locked: true,
            text: task.name,
            name: task.name,
            starts_at: startDate,
            start_date: startDate,
            planned_start: startDate,
            status: humanize(task.status),
            end_date: endDate,
            planned_end: endDate,
            duration: calculateWorkingDaysBetween(startDate, endDate),
            type: task.isMilestone ? "mp_milestone" : "task"
          },
          parent?.id
        );

        if (parent) {
          setTaskType(parent.id);
        }
      });
    },

    taskDeleted(taskId) {
      this.persistence.ignore(() => {
        if (gantt.isTaskExists(taskId)) {
          const task = gantt.getTask(taskId);
          const parentId = task.parent;
          gantt.deleteTask(taskId);

          if (parentId !== 0) {
            setTaskType(parentId);
          }
        }
      });
    },

    addTask(parent, isMilestone) {
      this.createAndAddTask(null, isMilestone);
    },

    /**
     *  Called when the gantt has finished loading the data
     *  and any other setup is finished.
     */
    onGanttInitialised() {
      // monkey patch the function that snaps tasks. This is a modified version
      // of https://github.com/DHTMLX/gantt/blob/master/codebase/sources/dhtmlxgantt.js#L8423
      gantt.$layout.$cells[0].$cells[2].$content._tasks_dnd._fix_dnd_scale_time = (
        task,
        drag
      ) => {
        // hardcode unit/step to "1 day" instead of getting this dynamically
        // depending on the zoom level
        // this is the only difference between the original and the monkey
        // patched version of this function
        let unit = "day";
        let step = 1;

        if (!gantt.config.round_dnd_dates) {
          unit = "minute";
          step = gantt.config.time_step;
        }

        const fixStart = task => {
          if (!gantt.isWorkTime(task.start_date, undefined, task))
            task.start_date = gantt.calculateEndDate({
              start_date: task.start_date,
              duration: -1,
              unit: gantt.config.duration_unit,
              task: task
            });
        };

        const fixEnd = task => {
          if (!gantt.isWorkTime(new Date(task.end_date - 1), undefined, task))
            task.end_date = gantt.calculateEndDate({
              start_date: task.end_date,
              duration: 1,
              unit: gantt.config.duration_unit,
              task: task
            });
        };

        if (drag.mode === gantt.config.drag_mode.resize) {
          if (drag.left) {
            task.start_date = gantt.roundDate({
              date: task.start_date,
              unit: unit,
              step: step
            });
            fixStart(task);
          } else {
            task.end_date = gantt.roundDate({
              date: task.end_date,
              unit: unit,
              step: step
            });
            fixEnd(task);
          }
        } else if (drag.mode === gantt.config.drag_mode.move) {
          task.start_date = gantt.roundDate({
            date: task.start_date,
            unit: unit,
            step: step
          });
          fixStart(task);

          task.end_date = gantt.calculateEndDate(task);
        }
      };

      // Attach onClick event to task name
      // to open task sidebar
      $(document).on("click", ".task-name-cell", e => {
        const task = $(e.target).data();
        this.openTask({ id: task.id });
      });
    },
    createAndAddTask(parent, isMilestone = false) {
      if (this.readOnly) {
        return;
      }

      const taskManager = new TaskManager({ ...this.project });
      taskManager
        .createTask(parent, { isMilestone })
        .then(task => {
          const endDate = moment(task.deadline)
            .add(1, "day")
            .toDate();
          const startDate = moment(task.startsAt).toDate();

          this.persistence.ignore(() => {
            gantt.addTask(
              {
                baseline_locked: false,
                duration: calculateWorkingDaysBetween(startDate, endDate),
                end_date: endDate,
                planned_end: endDate,
                id: task.id,
                start_date: startDate,
                planned_start: startDate,
                text: task.name,
                type: isMilestone ? "mp_milestone" : "task",
                status: humanize(task.status),
                parent: parent?.id
              },
              parent?.id || 0,
              parent ? undefined : gantt.getTaskCount() + 1
            );
            this.$emit("task-created", { task, parent });
            this.openTask(task, true);
          });
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    },
    tasksDeleted(tasks) {
      tasks.forEach(task => {
        if (gantt.isTaskExists(task.id)) {
          this.taskDeleted(task.id);
        }
      });
    },
    taskMoved({ task, newParentId }) {
      if (gantt.isTaskExists(task.id)) {
        gantt.moveTask(task.id, 0, newParentId);
      }
    },
    taskRenamed(task) {
      if (gantt.isTaskExists(task.id)) {
        const gTask = gantt.getTask(task.id);
        gTask.text = task.name;
        gantt.refreshTask(task.id);
      }
    },
    tasksUpdated(tasks) {
      tasks.forEach(task => {
        this.updateTask(task);
      });
    }
  }
};
</script>

<style lang="postcss">
@import "gantt/gantt.css";
</style>
