<script>
import Vue from "vue";
import { Components } from "manageplaces-ui-kit";

export default Vue.extend({
  extends: Components.BaseTableLinkCellRenderer,
  methods: {
    getName(h) {
      return h(
        "a",
        {
          staticClass: "truncate",
          attrs: { href: "#", title: this.params.value },
          on: {
            click: e => {
              e.preventDefault();
              this.onClick();
            }
          }
        },
        this.params.value
      );
    },
    getNameAndPath(h) {
      const name = h(
        "a",
        {
          staticClass: "leading-none block truncate",
          attrs: { href: "#", title: this.params.value },
          on: {
            click: e => {
              e.preventDefault();
              this.onClick();
            }
          }
        },
        this.params.value
      );

      const children = [
        h("span", "In"),
        h(
          "a",
          {
            staticClass: "ml-1 truncate",
            attrs: { href: "#", title: this.params.data.parentPath },
            on: {
              click: e => {
                e.preventDefault();
                this.params.onFolderClick(this.params.node);
              }
            }
          },
          `${this.params.data.parentPath}`
        )
      ];

      const path = h(
        "p",
        {
          staticClass:
            "flex leading-none text-grey-60 text-sm mt-2 overflow-hidden overflow-ellipsis"
        },
        children
      );

      return h(
        "div",
        { staticClass: "flex-1 self-center w-full overflow-hidden" },
        [name, path]
      );
    },
    shouldShowPath() {
      if (this.params.shouldShowPath) {
        return this.params.shouldShowPath();
      }

      return false;
    }
  },
  render(h) {
    const doc = this.params.data;
    const type = doc.__typename;
    const ext =
      type === "Folder" ? "folder" : doc.currentVersion.upload.fileExtension;

    let children = [
      h(Components.AppFileIcon, {
        props: { extension: ext },
        staticClass: "mr-4 flex-none"
      })
    ];

    if (this.shouldShowPath()) {
      children.push(this.getNameAndPath(h));
    } else {
      children.push(this.getName(h));
    }

    return h(
      "span",
      { staticClass: "flex flex-row items-center w-full" },
      children
    );
  }
});
</script>
