<template lang="pug">
  .flex.flex-row.mb-8
    .flex.flex-col
      strong.block.mb-2 Owner

      p.italic.text-grey-60(v-if="!project") Loading...
      .flex.flex-row(v-else)
        app-dropdown(trigger="hover", v-if="project.owner", pointing)
          template(#trigger)
            app-avatar(slot="trigger", :avatar="project.owner.avatar" bordered)

          template(#content)
            .w-64.p-4.border-grey-30
              app-team-member(:user="project.owner")
              a.text-withered-cherry.ml-16.mt-4(v-if="project.canEdit", href="#", @click="removeOwner") Remove owner

        user-picker.flex-initial(v-if="project.canEdit", :class="{ '-ml-2': project.owner }", :value="project.owner", @input="ownerChanged", single)
          template(#title) Choose an owner


    .flex.flex-col.ml-8
      strong.block.mb-2 Your team

      p.italic.text-grey-60(v-if="!project") Loading...
      .flex.flex-row(v-else)
        app-dropdown(:class="{ '-ml-2': idx !== 0 }", trigger="hover", v-for="(user, idx) in projectTeam", :key="user.id", pointing)
          template(#trigger)
            app-avatar(slot="trigger", :avatar="user.avatar" bordered)

          template(#content)
            .w-64.p-4.border-grey-30
              app-team-member(:user="user")
              a.text-withered-cherry.ml-16.mt-4(v-if="project.canEdit",href="#", @click="unassignUser(user)") Unassign user

        user-picker.flex-initial(v-if="project.canEdit", :class="{ '-ml-2': projectTeam && projectTeam.length }", :value="projectTeam", @input="assigneesChanged")
</template>
<script>
import gql from "graphql-tag";
import UserPicker from "@/components/collections/AppUserPicker.vue";
import ProjectQuery from "@/graphql/queries/core/projects/Project.gql";
import SetProjectOwner from "@/graphql/mutations/projects/SetProjectOwner.gql";
import SetProjectAssignees from "@/graphql/mutations/projects/SetProjectAssignees.gql";
import ProjectWatchers from "@/mixins/ProjectWatchers.js";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import { getLockVersion, setLockVersion } from "@/helpers/TaskHelpers";

export default {
  components: {
    UserPicker
  },
  mixins: [ProjectWatchers],

  apollo: {
    project: {
      query: ProjectQuery,
      variables() {
        const match = this.$route.path.match(/^\/?projects\/?(\w*)\/?$/);
        return {
          id: match[1]
        };
      }
    },
    companyUsers: {
      query() {
        return gql`
          query getUsers {
            companyUsersSummary {
              edges {
                node {
                  id
                  roleName
                  name
                  email
                  avatar {
                    url
                  }
                }
              }
            }
          }
        `;
      },

      update(data) {
        const { companyUsersSummary } = data;
        return companyUsersSummary.edges.map(({ node }) => node);
      }
    }
  },

  data() {
    return {
      project: null,
      nameTaken: false,
      companyUsers: [{}],
      searchAssignee: "",
      messages: {
        required: "You must give this project a name",
        unique: "Project name already taken"
      },

      icons: {
        Owner: ["fas", "user-tie"],
        Admin: ["fas", "user-cog"],
        Member: ["fas", "user"]
      }
    };
  },

  computed: {
    filteredUsers() {
      return this.companyUsers.filter(user => {
        if (!this.assigneesInp.includes(user)) return false;
        const regExp = new RegExp(this.searchAssignee, "ig");
        return regExp.test(user.name) || regExp.test(user.email);
      });
    },

    projectTeam() {
      if (this.project?.canEdit) {
        return this.filteredUsers;
      } else {
        return this.project.assignees;
      }
    },

    assigneesInp: {
      get() {
        return this.companyUsers.filter(u =>
          this.project.assigneesInp.includes(u.id)
        );
      },

      set(value) {
        this.project.assigneesInp = value;
      }
    }
  },

  methods: {
    roleColor(role) {
      switch (role) {
        case "Admin":
          return "text-diving-sapphire";
        case "Owner":
          return "text-laughing-amber";
        default:
          return "text-grey-60";
      }
    },

    iconFor(role) {
      const icon = this.icons[role];
      return icon || ["fas", "user"];
    },

    avatar(user) {
      if (user && user.avatar) {
        return user.avatar.url;
      }
    },

    showFilteredUsers(searchAssignee, kpop) {
      if (searchAssignee) kpop.show();
      else kpop.hide();
    },

    assigneeClassFor(user) {
      const classes = [];

      if (user.selected) {
        classes.push("selected");
      } else {
        classes.pop();
      }

      return classes;
    },

    ownerChanged(newOwner) {
      const projectId = this.project.id;
      this.$apollo
        .mutate({
          mutation: SetProjectOwner,
          variables: {
            input: {
              id: projectId,
              owner: newOwner
                ? {
                    id: newOwner.id
                  }
                : null
            },
            lockVersion: getLockVersion()
          },
          update(store, { data: { setProjectOwner } }) {
            const variables = { id: projectId };
            const data = store.readQuery({ query: ProjectQuery, variables });

            data.project.owner = setProjectOwner.project.owner;

            store.writeQuery({ query: ProjectQuery, variables, data });
          },
          optimisticResponse: {
            __typename: "Mutation",
            setProjectOwner: {
              __typename: "SetProjectOwnerMutationPayload",
              project: {
                __typename: "Project",
                id: projectId,
                owner: newOwner
              }
            }
          }
        })
        .then(({ data }) => {
          const lockVersion = data.setProjectOwner.project.lockVersion;
          setLockVersion(lockVersion);
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    },

    assigneesChanged(newAssignees) {
      const projectId = this.project.id;

      this.$apollo
        .mutate({
          mutation: SetProjectAssignees,
          variables: {
            input: {
              id: projectId,
              assigneesInp: newAssignees.map(a => a.id)
            },
            lockVersion: getLockVersion()
          },
          update(store, { data: { setProjectAssignees } }) {
            const variables = { id: projectId };
            const data = store.readQuery({ query: ProjectQuery, variables });

            data.project.assigneesInp =
              setProjectAssignees.project.assigneesInp;

            store.writeQuery({ query: ProjectQuery, variables, data });
          },
          optimisticResponse: {
            __typename: "Mutation",
            setProjectAssignees: {
              __typename: "SetProjectAssigneesMutationPayload",
              project: {
                __typename: "Project",
                id: projectId,
                assigneesInp: newAssignees.map(u => u.id)
              }
            }
          }
        })
        .then(({ data }) => {
          const lockVersion = data.setProjectAssignees.project.lockVersion;
          setLockVersion(lockVersion);
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    },

    removeOwner() {
      this.ownerChanged(null);
    },

    unassignUser(user) {
      const newAssignees = this.assigneesInp.filter(u => u.id !== user.id);
      this.assigneesInp = newAssignees;
      this.assigneesChanged(newAssignees);
    }
  }
};
</script>
