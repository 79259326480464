import Vue from "vue";
import PrintButton from "./buttons/PrintButton.vue";
import GeneratingModal from "./modals/GeneratingModal.vue";
import TabularForm from "./tabular-form/TabularForm.vue";
import Expenditure from "./expenditure/Expenditure";
import ColumnToggleHeader from "./expenditure/ColumnToggleHeader.vue";
import IntegrationSyncStatus from "./integrations/IntegrationSyncStatus.vue";
import PlanPicker from "./plans/PlanPicker.vue";
import PlanSetup from "./plans/PlanSetup.vue";
import GanttModal from "./gantt_modal/GanttModal.vue";
import GanttModalFieldRow from "./gantt_modal/GanttModalFieldRow.vue";
import DurationInput from "./directives/DurationInput";
import RadioTab from "./directives/RadioTab";
import ProjectSnapshot from "./ProjectSnapshot.vue";
import CountryDropdownInput from "./inputs/CountryDropdownInput.vue";

export default () => {
  Vue.component("print-button", PrintButton);
  Vue.component("generating-modal", GeneratingModal);
  Vue.component("tabular-form", TabularForm);
  Vue.component("expenditure-table", Expenditure);
  Vue.component("column-toggle-header", ColumnToggleHeader);
  Vue.component("integration-sync-status", IntegrationSyncStatus);
  Vue.component("plan-picker", PlanPicker);
  Vue.component("plan-setup", PlanSetup);
  Vue.component("gantt-modal-field-row", GanttModalFieldRow);
  Vue.component("gantt-modal", GanttModal);
  Vue.component("project-snapshot", ProjectSnapshot);
  Vue.component("country-picker", CountryDropdownInput);

  Vue.directive("duration-input", DurationInput);
  Vue.directive("radio-tab", RadioTab);
};
