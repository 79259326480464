<template lang="pug">
  app-panel(secondary)
    template(#header)
      .flex.justify-between.cursor-pointer(@click="toggleExpanded")
        app-header(size="h3" :margin="expanded ? 'mb-8' : ''") Settings
        app-icon(:icon="collapseIcon")

    template(#default)
      .flex.flex-col(v-show="expanded")
        app-toggle-switch-field(
          :value="taskToEdit.enableReminders"
          name="enableReminders"
          label="Enable reminders"
          description="Remind your team when this task is due to start or finish"
          true-label="Enabled"
          false-label="Disabled"
          @input="toggleReminders")
</template>

<script>
import CollapsableSection from "@/mixins/CollapsableSection";
import SidebarComponentMixin from "@/mixins/tasks/TaskSidebarComponentMixin";

export default {
  mixins: [CollapsableSection, SidebarComponentMixin],
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      expanded: false,
      taskToEdit: { ...this.task }
    };
  },
  methods: {
    toggleBillable(enabled) {
      this.taskToEdit.billable = enabled;
      this.ifNotReadOnly(
        () => this.fieldUpdated("billable"),
        () => (this.taskToEdit.billable = this.task.billable)
      );
    },
    toggleReminders(enabled) {
      this.taskToEdit.enableReminders = enabled;
      this.ifNotReadOnly(
        () => this.fieldUpdated("enableReminders"),
        () => (this.taskToEdit.enableReminders = this.task.enableReminders)
      );
    },
    fieldUpdated(field, saved = false) {
      this.$emit("field-changed", {
        task: this.taskToEdit,
        field: field,
        saved
      });
    }
  }
};
</script>
