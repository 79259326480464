<template lang="pug">
  app-panel.basic-details.mb-4(secondary)
    template(#header)
      .flex.justify-between.cursor-pointer(@click="toggleExpanded")
        app-header(size="h3" :margin="expanded ? 'mb-8' : ''") Basic details
        app-icon(:icon="collapseIcon")

    template(#default)
      form-wrapper(:validator="$v.taskToEdit")
        .flex.flex-col(v-show="expanded")
          .flex.flex-row(v-if="task.childCount === 0 && taskToEdit.canEdit")
            app-date-field.flex-1.mr-2(v-for="field in dateFields" :key="field.name" :name="field.name" :show-optional="false" v-model="taskToEdit[field.name]"
                                       :messages="messages" :disable="disabledDates" field-classes="w-full" :min-date="minDate(field.name)" :max-date="maxDate(field.name)" :label="field.label" :description="dateFieldCaption")

          .flex.flex-row.mb-8(v-else)
            .flex.flex-col.mr-8(v-for="field in dateFields" :key="field.name")
              strong {{ field.label }}
              span {{ formatDate(taskToEdit[field.name]) }}

          .flex.flex-row
            task-team(:task="task" @tasks-updated="usersUpdated")

          .flex.flex-row
            task-description-area.mb-5.w-full(:task="task" v-model="task.description")

          .flex.flex-row
            .block.mr-2(class="w-1/2")
              .text-grey-70.text-lg.mb-2 Status
              task-status(:task="task" v-model='taskToEdit.status' :slim="false" @tasks-updated="$emit('tasks-updated', $event)")

            .block(class="w-1/2" v-if="!task.isMilestone")
              .text-grey-70.text-lg.mb-2 Priority
              task-priority(:task="taskToEdit" v-model="taskToEdit.priority")

          .flex.flex-row(v-if="taskToEdit.childCount === 0")
            .block(v-if="taskToEdit.canEdit" class="w-1/2")
              app-dropdown-field(:options="typeOptions" label="What type of task is this?" valueAttr="type" v-model="taskToEdit.type" :show-optional="false")
            
            .block(v-else)
              .text-grey-70.text-lg.mb-2 Type of task
              .flex.flex-row.items-center.mb-4
                app-icon.mr-2(:icon="taskToEdit.type.toLowerCase()")
                span {{ initCap(taskToEdit.type) }}
</template>
<script>
import { parse } from "date-fns";
import { required, not } from "vuelidate/lib/validators";
import { isValidDate, before } from "@/helpers/Validators";
import {
  disabledDates,
  isNotWorkingDay,
  formatDate
} from "@/helpers/DateHelpers";
import TaskDescriptionArea from "./TaskDescriptionArea.vue";
import CollapsableSection from "@/mixins/CollapsableSection";
import SidebarComponentMixin from "@/mixins/tasks/TaskSidebarComponentMixin";
import { initCap } from "@/helpers/StringHelpers";

export default {
  components: {
    TaskDescriptionArea
  },

  mixins: [CollapsableSection, SidebarComponentMixin],
  props: {
    task: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      editing: false,
      actionMenuItems: [{ label: "Rename", icon: "edit", action: "RENAME" }],
      messages: {
        isValidDate: "Must be a valid date",
        beforeDeadline: 'Cannot be after "Deadline"',
        afterStartDate: 'Cannot be before "Start date"'
      },
      taskToEdit: { ...this.task },
      typeOptions: [
        { icon: "task", label: "Task", type: "TASK" },
        { icon: "milestone", label: "Milestone", type: "MILESTONE" }
      ]
    };
  },

  validations() {
    let validations = {
      taskToEdit: {
        deadline: {
          required,
          isValidDate
        }
      }
    };

    if (!this.taskToEdit.isMilestone) {
      validations.taskToEdit.deadline.afterStartDate = not(
        before("startsAt", d => parse(d, "yyyy/MM/dd", new Date()), true)
      );

      validations.taskToEdit.startsAt = {
        required,
        isValidDate,
        beforeDeadline: before("deadline", d => {
          return parse(d, "yyyy/MM/dd", new Date());
        })
      };
    }

    return validations;
  },

  computed: {
    isNewTask() {
      return !this.task.id;
    },

    dateFields() {
      let fields = [];

      if (!this.taskToEdit.isMilestone) {
        fields.push({ name: "startsAt", label: "Starts" });
      }

      fields.push({ name: "deadline", label: "Deadline" });
      return fields;
    },

    disabledDates() {
      const workingWeek = this.$store.state.workingWeek;

      if (this.isNewTask) {
        return disabledDates(workingWeek);
      } else {
        return [isNotWorkingDay(workingWeek)];
      }
    },

    dateFieldCaption() {
      const format = this.$store.state.dateFormat;
      return `Date format is ${format}`;
    }
  },

  watch: {
    "taskToEdit.startsAt": function() {
      if (!this.taskToEdit.isMilestone && !this.$v.taskToEdit.startsAt.$error) {
        this.ifNotReadOnly(
          () => this.fieldUpdated("startsAt"),
          () => (this.taskToEdit.startsAt = this.task.startsAt)
        );
      }
    },
    "taskToEdit.deadline": function() {
      if (!this.$v.taskToEdit.deadline.$error) {
        if (this.taskToEdit.isMilestone) {
          // We need to update the start date as well as they're the same
          this.taskToEdit.startsAt = this.taskToEdit.deadline;
        }
        this.ifNotReadOnly(
          () => {
            this.fieldUpdated("deadline");
            if (this.taskToEdit.isMilestone) {
              this.fieldUpdated("startsAt", true);
            }
          },
          () => {
            this.taskToEdit.deadline = this.task.deadline;
            this.taskToEdit.startsAt = this.task.deadline;
          }
        );
      }
    },
    // "taskToEdit.users": function() {
    //   this.ifNotReadOnly(
    //     () => this.fieldUpdated("users"),
    //     () => (this.taskToEdit.users = this.task.users)
    //   );
    // },
    "taskToEdit.priority": function() {
      this.ifNotReadOnly(
        () => this.fieldUpdated("priority"),
        () => (this.taskToEdit.priority = this.task.priority)
      );
    },
    "taskToEdit.status": function() {
      this.ifNotReadOnly(
        () => this.fieldUpdated("status", true),
        () => (this.taskToEdit.status = this.task.status)
      );
    },
    "taskToEdit.type": function() {
      const isMilestone = this.taskToEdit.type === "MILESTONE";
      const oldIsMilestone = this.task.type === "MILESTONE";

      this.ifNotReadOnly(
        () => {
          this.fieldUpdated("type");
          this.taskToEdit.isMilestone = isMilestone;
        },
        () => {
          this.taskToEdit.type = this.task.type;
          this.taskToEdit.isMilestone = oldIsMilestone;
        }
      );
    }
  },

  methods: {
    contextMenuItemClicked(item) {
      if (item.action === "RENAME") this.startRenaming();
      else this.$emit(item.action);
    },

    fieldUpdated(field, saved = false) {
      this.$emit("field-changed", {
        task: this.taskToEdit,
        field: field,
        saved
      });
    },

    initCap,

    mergeClasses(classes, customClasses) {
      return Array(classes, customClasses)
        .flat()
        .filter(x => !!x);
    },

    startRenaming() {
      this.editing = true;
    },

    stopRenaming() {
      this.editing = false;
    },

    renameTask() {
      this.stopRenaming();
    },

    formatDate(date) {
      return formatDate(date, this.$store.state.dateFormats.dateFns);
    },

    minDate(field) {
      if (this.taskToEdit.isMilestone) {
        return undefined;
      } else if (field === "deadline") {
        return this.taskToEdit.startsAt;
      }

      return undefined;
    },

    maxDate(field) {
      if (this.taskToEdit.isMilestone) {
        return undefined;
      }
      if (field === "startsAt") {
        return this.taskToEdit.deadline;
      }

      return undefined;
    },

    usersUpdated(tasks) {
      const task = tasks[0];
      this.taskToEdit.users = task.users;
      this.taskToEdit.owner = task.owner;
      this.fieldUpdated("users", true);
    }
  }
};
</script>
