<template lang="pug">
  .title.flex.flex-row.justify-between.items-start.w-full
    div(:class="{ 'w-full': !renaming, 'flex-1 flex flex-row': renaming }")
      slot(name="back-btn")
      app-header.mr-4.truncate(v-if="!renaming || readOnly" margin="mb-2") {{ taskToEdit.name }}
        //.flex.flex-row.w-full
        //  .truncate(:title="taskToEdit.name") {{ taskToEdit.name }}
        //  .flex-1
        //    task-status.align-top.ml-2.w-24.text-center(:task="taskToEdit" :dropdown="false")

      .flex.flex-row.text-lg.text-grey-70(v-if="!renaming")
        task-status.align-top.w-24.text-center.mr-2(:task="taskToEdit" :dropdown="false")
        template(v-if="taskToEdit.startsAt")
          span.mr-1 {{ formatDate(taskToEdit.startsAt) }}
          | -
          span.ml-1(:class="deadlineClass") {{ formatDate(taskToEdit.deadline) }}

      app-text-input.mr-4.flex-1(v-else v-focus v-model="taskToEdit.name" ref="nameInput"
                                 @keyup.enter="renameTask", @keyup.esc="stopRenaming" v-click-outside="renameTask")
</template>
<script>
import { formatDate } from "@/helpers/DateHelpers";
import { ACTIONS } from "@/components/projects/ProjectTasksTableContextMenuItems";
import SidebarComponentMixin from "@/mixins/tasks/TaskSidebarComponentMixin";

export default {
  mixins: [SidebarComponentMixin],
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    renaming: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    let actions = [
      { label: "Copy", icon: "copy", action: ACTIONS.COPY_TASK },
      { label: "Rename", icon: "edit", action: ACTIONS.RENAME }
    ];

    // if (!this.task.isMilestone) {
    //   actions.push({ label: "Move", icon: "move", action: ACTIONS.MOVE });
    // }

    actions.push(
      { type: "divider" },
      {
        label: "Delete",
        icon: "delete",
        iconColour: "red",
        action: ACTIONS.DELETE
      }
    );

    return {
      actionMenuItems: actions
    };
  },

  computed: {
    taskToEdit() {
      return { ...this.task };
    },
    deadlineClass() {
      return this.task.overdue ? "font-bold text-withered-cherry" : "";
    },
    formatDate() {
      const format = this.$store.state.dateFormats.dateFns;
      return date => formatDate(date, format);
    }
  },

  mounted() {
    if (this.renaming) {
      this.$nextTick(() => {
        this.$refs.nameInput.$el.select();
      });
    }
  },

  methods: {
    stopRenaming() {
      this.$emit("rename-cancelled");
    },
    renameTask() {
      this.$emit("renamed", this.taskToEdit);
    }
  }
};
</script>
