<script>
import { Components, Helpers } from "manageplaces-ui-kit";
import { humanize } from "@/helpers/StringHelpers";
import { formatDate } from "@/helpers/DateHelpers";

import UsersQuery from "@/graphql/queries/core/company/Users.gql";
import CompanyRolesQuery from "@/graphql/queries/core/company/Roles.gql";
import ResendInvitationMutation from "@/graphql/mutations/users/ResendInvitation.gql";

import { ACTIONS, userMenuItems, bulkActions } from "./ContextMenuItems.js";
import UserManager from "./UserManager.js";

import UserSidebar from "./Sidebar";

import UserNameCellRenderer from "./users_table/UserNameCellRenderer.vue";

import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

import SubscriptionMixin from "@/mixins/subscriptions/Subscription.js";
import LicenseMixin from "@/mixins/subscriptions/License.js";

import LicenseTab from "@/components/subscriptions/LicenseTab.vue";

export default {
  extends: Components.BaseTable,
  mixins: [SubscriptionMixin, LicenseMixin],
  apollo: {
    users: {
      query: UsersQuery,

      update(data) {
        return data.companyUsers.edges.map(({ node }) => {
          const { invitedBy } = node;

          if (invitedBy) {
            invitedBy.role = invitedBy.roleName;
          }

          return node;
        });
      },

      variables() {
        return { search: this.searchTerm || "*" };
      },

      result() {
        this.setRowData(this.users);
        this.stopLoading();
      },

      debounce: 1000,

      fetchPolicy: "no-cache"
    },

    companyRoles: {
      query: CompanyRolesQuery,
      update(data) {
        const roles = data.companyRoles.edges.map(edge => edge.node);
        this.roleNames = [];
        roles.forEach(role => {
          role.name = humanize(role.name);
          role.label = role.name;
          role.value = role.id;

          this.roleNames.push({
            value: role.name,
            label: role.name
          });
        });

        return roles;
      }
    }
  },

  data() {
    const vm = this;

    return {
      isSidebarOpen: false,
      sidebarType: null,
      userManager: new UserManager(),
      users: [],
      roleNames: [],
      selection: "multiple",
      userAttrs: [
        "id",
        "name",
        "email",
        "role",
        "roleName",
        "dateFormat",
        "timezone",
        "userLicenseId",
        "costRate",
        "billableRate"
      ],
      columns: [
        {
          colId: "checkbox",
          checkboxSelection: true,
          suppressMenu: true,
          headerCheckboxSelection: true,
          resizable: false,
          sortable: false,
          width: 50,
          canToggle: false
        },
        {
          headerName: "Name",
          field: "name",
          flex: 1.5,
          cellRenderer: "userName",
          cellRendererParams: {
            onClick(user) {
              this.selectedUser = user;
            }
          },
          valueGetter(params) {
            return params.data;
          },
          editable: false,
          sortable: true,
          comparator(a, b) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          },
          canToggle: false
        },
        {
          headerName: "Role",
          field: "roleName",
          editable: true,
          sortable: true,
          cellEditor: "dropdownCellEditor",
          cellEditorParams: {
            options() {
              return vm.roleNames;
            },
            dropdownProps: {
              allowEmpty: false,
              deselectLabel: "",
              valueAttr: "value",
              labelAttr: "label"
            }
          },
          flex: 1
        },
        {
          headerName: "Last login",
          field: "lastActivity",
          sortable: false,
          flex: 1.5,
          // cellRendererParams: { time: true },
          // cellRendererSelector({ data }) {
          //   if (!data.pending && data.lastActivity) {
          //     return { component: "date" };
          //   }
          // },
          valueGetter({ data }) {
            const format = vm.$store.state.dateFormats.dateFns;

            if (data.lastActivity) {
              return formatDate(data.lastActivity, format);
            } else if (data.pending) {
              if (data.invitationSentAt) {
                const invitationDate = formatDate(
                  data.invitationSentAt,
                  format
                );
                return `Invited at ${invitationDate}`;
              }
            }
          }
        },
        {
          headerName: "Invited by",
          field: "invitedBy",
          sortable: true,
          flex: 1,
          cellRendererSelector(params) {
            if (params.data.invitedBy) {
              return { component: "teamMember" };
            } else {
              return null;
            }
          },
          valueGetter(params) {
            return params.data.invitedBy || "N/A";
          }
        },
        {
          headerName: "License",
          field: "license",
          flex: 1,
          valueGetter(params) {
            return params.data.userLicense ? "Standard" : "None";
          }
        },
        Helpers.table.actionsCell()
      ],
      components: {
        vue: {
          userName: UserNameCellRenderer
        }
      },
      listeners: {
        cellValueChanged(evt) {
          if (evt.colDef.field == "roleName") {
            vm.updateUserRole(evt.data, evt.oldValue, evt.value);
          }
        }
      },
      selectedUser: null,
      selectedUsers: [],
      config: {
        resourceType: "user"
      }
    };
  },

  mounted() {
    this.startLoading();
  },

  methods: {
    performSearch() {
      this.startLoading();
    },
    extractUserAttrs(user) {
      if (!user) return null;

      const selectedUser = {};

      this.userAttrs.forEach(attr => (selectedUser[attr] = user[attr]));

      return selectedUser;
    },

    getButtons(h) {
      const buttons = [
        h(
          Components.AppButton,
          {
            props: { icon: "add", primary: true },
            on: { click: this.inviteUser }
          },
          "Invite user"
        )
      ];

      return buttons;
    },

    inviteUser() {
      this.selectedUser = {
        name: "",
        email: "",
        role: null,
        costRate: 0,
        billableRate: 0,
        timezone: "",
        dateFormat: ""
      };
      this.openSidebar("userSidebar");
    },

    updateUser(user) {
      return this.userManager.updateUser(this.extractUserAttrs(user));
    },

    updateUserRole(user, oldRoleName, newRoleName) {
      const newRole = this.companyRoles.find(role => role.name === newRoleName);

      user.role = newRole.id;
      user.roleName = newRole.name;

      return this.userManager
        .updateUserRole(user.id, newRole.id)
        .then(() => {
          this.$flash.success("User role updated successfully");
        })
        .catch(() => {
          const oldRole = this.companyRoles.find(
            role => role.name === oldRoleName
          );
          user.role = oldRole.id;
          user.roleName = oldRole.label;
          this.$flash.error("User role update failed");
        });
    },

    getOtherComponents(h) {
      return [this.getSidebar(h)];
    },

    getSidebar(h) {
      const vm = this;
      const children = [];

      if (vm.sidebarType) {
        const sidebar = vm[vm.sidebarType](h);

        if (sidebar) {
          children.push(sidebar);
        }
      }

      return h(
        Components.AppSidebarContainer,
        { props: { value: vm.isSidebarOpen } },
        children
      );
    },

    userSidebar(h) {
      const vm = this;

      return h(UserSidebar, {
        props: {
          value: vm.selectedUser
        },
        on: {
          input(value) {
            if (vm.selectedUser !== value)
              vm.selectedUser = vm.extractUserAttrs(value);
          },

          close() {
            vm.closeSidebar();
            vm.selectedUser = null;
            // if (vm.selectedUsers) {
            //   vm.selectedUsers.id = null;
            // } else {
            //   vm.selectedUser = null;
            // }
          },

          saved() {
            vm.$apollo.queries.users.refetch();
          }
        }
      });
    },

    purchaseLicenseSidebar(h) {
      if (!this.subscription) return;

      const vm = this;

      return h(
        "app-loader",
        {
          props: {
            value: vm.isSidebarOpen,
            loading: vm.sidebarLoading,
            compact: true
          }
        },
        [
          h(LicenseTab, {
            props: {
              subscription: vm.subscription,
              userLicensePrice: vm.userLicensePrice,
              addOnPrice: vm.financeAddOn.price,
              additionalLicenses: vm.additionalLicenses,
              financeManagementStatus: vm.financeAddOn.status,
              voucher: vm.subscription.voucher,
              taxPrice: vm.taxPrice,
              taxRate: vm.taxRate,
              userLicense: vm.userLicense,
              usedLicenses: vm.usedLicenses,
              currency: vm.currency,
              nextPayment: vm.nextPayment,
              previousLicenses: vm.previousLicenses,
              onlyAdditional: true
            },
            on: {
              close: vm.closeSidebar,
              "update-feature": async input => {
                await vm.updateSubscriptionFeature(input);
                this.assignLicense(this.selectedUser, true);
              }
            }
          })
        ]
      );
    },

    openSidebar(type) {
      this.isSidebarOpen = true;
      this.sidebarType = type;
    },

    closeSidebar() {
      this.isSidebarOpen = false;
      this.sidebarType = null;
    },

    getBulkActions() {
      return bulkActions;
    },

    getContextMenuItems(node) {
      return userMenuItems(node.data);
    },

    contextMenuItemClicked(item, row) {
      this.performUserAction(item, row);
    },

    performUserAction(menuItem, row) {
      const { data: user } = row;

      this.selectedUser = this.extractUserAttrs(user);

      switch (menuItem.action) {
        case ACTIONS.EDIT:
          this.openSidebar("userSidebar");
          break;
        case ACTIONS.CHANGE_ROLE:
          this.gridApi().startEditingCell({
            rowIndex: row.rowIndex,
            colKey: "roleName"
          });
          break;
        case ACTIONS.ASSIGN_LICENSE:
          this.assignLicense(user);
          break;
        case ACTIONS.REVOKE_LICENSE:
          this.revokeLicense(user);
          break;
        case ACTIONS.RESEND_INVITATION:
          this.resendInvitation(user);
          break;
        case ACTIONS.DELETE:
          this.delete(row, {
            title: "Are you sure you want to delete this user?",
            message: "Deleting a user cannot be undone.",
            success: "User successfully deleted"
          });
          break;
      }
    },

    performBulkAction(item) {
      switch (item.action) {
        case ACTIONS.DELETE:
          this.delete(this.gridApi().getSelectedNodes(), {
            title: "Are you sure you want to delete these users?",
            message: "Deleting a user cannot be undone",
            success: "Users successfully deleted"
          });
          break;
      }
    },

    delete(rows, opts) {
      rows = Array.isArray(rows) ? rows : [rows];

      const users = rows.map(row => row.data);
      this.$dialog.confirm({ ...opts, danger: true }).onOk(({ api }) => {
        api.hide();
        this.userManager
          .delete(users)
          .then(() => {
            this.$flash.success(opts.success);
            this.removeRows(rows);
          })
          .catch(e => {
            this.$flash.error(gqlErrorMessage(e));
          });
      });
    },

    removeRows(rows) {
      this.gridApi().applyTransaction({
        remove: rows
      });
    },

    resendInvitation(user) {
      this.$apollo
        .mutate({
          mutation: ResendInvitationMutation,
          variables: {
            input: {
              id: user.id
            }
          }
        })
        .then(() => {
          this.$flash.success("Invitation successfully re-sent");
        })
        .catch(() => {
          this.$flash.error("An unexpected error occurred. Please try again");
        });
    }
  }
};
</script>
