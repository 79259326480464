<template lang="pug">
  .folders.item
    Folder(:folder="root", :depth="0")
</template>

<script>
import Folder from "./Folder.vue";

export default {
  name: "Root",
  components: {
    Folder
  },
  props: {
    folder: Object
  },
  data() {
    return {
      root: {
        id: this.$attrs.folder_id,
        name: this.$attrs.name,
        leaf: false,
        expanded: false
      }
    };
  }
};
</script>
