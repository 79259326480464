import { runMutation } from "@/helpers/GraphQLHelpers";
import DeleteBills from "@/graphql/mutations/finance/bills/DeleteBills.gql";
import VoidBills from "@/graphql/mutations/finance/bills/VoidBills.gql";

export default class BillManager {
  delete(bills) {
    const ids = bills.map(co => co.id);

    return runMutation(DeleteBills, { ids });
  }

  void(bills) {
    const ids = bills.map(co => co.id);

    return runMutation(VoidBills, { ids });
  }
}
