<template lang="pug">
  div
    slot
</template>

<script>
import TaskQuery from "@/graphql/queries/core/tasks/Task.gql";

export default {
  apollo: {
    task: {
      query: TaskQuery,
      variables() {
        return {
          id: this.id
        };
      }
    }
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      task: null
    };
  },
  computed: {
    overdue() {
      return this.task && this.task.overdue && this.task.status !== "Complete";
    }
  }
};
</script>
