<template lang="pug">
  app-panel(secondary)
    app-header(size="h3") Change orders
      template(#subheader)
        | Permissions for change orders

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")
      template(#options)
        app-checkbox.mb-6(label="View change orders" :value="true")
        app-checkbox.mb-6(label="Edit existing change orders" :value="permissions.editChangeOrder" @input="permissionChanged('editChangeOrder', $event)")
        app-checkbox.mb-6(label="Delete change orders" :value="permissions.removeChangeOrder" @input="permissionChanged('removeChangeOrder', $event)")
        app-checkbox.mb-6(label="Create new change orders" :value="permissions.createChangeOrder" @input="permissionChanged('createChangeOrder', $event)")
        app-checkbox.mb-6(label="Authorise change orders" :value="permissions.authoriseChangeOrder" @input="permissionChanged('authoriseChangeOrder', $event)")
        app-checkbox.mb-6(label="Reject change orders" :value="permissions.rejectChangeOrder" @input="permissionChanged('rejectChangeOrder', $event)")

</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> access change orders",
          value: "cannotManage"
        },
        {
          label: "Can access for <strong>assigned projects</strong> only",
          value: "canManageAssigned",
          slot: "options"
        },
        {
          label: "Can access for <strong>any project</strong>",
          value: "canManageAny",
          slot: "options"
        }
      ],
      selected: "cannotManage",
      permissions: {
        viewChangeOrder: false,
        editChangeOrder: false,
        removeChangeOrder: false,
        createChangeOrder: false,
        authoriseChangeOrder: false,
        rejectChangeOrder: false
      },
      scope: "global"
    };
  },
  computed: {
    selectedPermissions() {
      if (this.selected === "cannotManage") {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: this.scope
      };
    }
  },
  mounted() {
    if (this.value.permissions.viewChangeOrder) {
      if (this.value && this.value.scope === "project") {
        this.selected = "canManageAssigned";
        this.scope = "project";
      } else {
        this.selected = "canManageAny";
        this.scope = "global";
      }
    }
  },
  methods: {
    optionChanged(value) {
      this.permissions.viewChangeOrder = value !== "cannotManage";
      this.scope = value === "canManageAssigned" ? "project" : "global";
      this.selected = value;
      this.notifyChange();
    },
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
