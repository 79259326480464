import { FINANCE_MANAGEMENT, USER_LIMIT } from "@/config/constants";
import { UPDATE_SUCCES } from "@/config/help_messages/SubscriptionMessages";

import Subscription from "@/graphql/queries/subscription/Subscription.gql";
import SubscriptionSummary from "@/graphql/queries/subscription/SubscriptionSummary.gql";
import UpdateSubscriptionFeature from "@/graphql/mutations/subscriptions/UpdateSubscriptionFeature.gql";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import CurrentUserMixin from "@/mixins/subscriptions/CurrentUser.js";

export default {
  apollo: {
    subscription: {
      query() {
        if (this.isCompanyOwner) {
          return Subscription;
        } else {
          return SubscriptionSummary;
        }
      },
      result(res) {
        if (res.data && res.data.subscription) {
          this.subscription = res.data.subscription;
          this.loading = false;
          this.error = false;

          this.previousFinanceStatus = this.financeAddOn.status;
        } else {
          this.error = true;
          this.loading = false;
        }
        this.loadingSubscription = false;
      },
      update(res) {
        if (this.isCompanyOwner) {
          return res.subscription;
        } else {
          return res.subscriptionSummary;
        }
      },
      error() {
        this.loading = false;
        this.error = true;
      },
      watchLoading(isLoading) {
        this.sidebarLoading = isLoading;
      }
    }
  },

  mixins: [CurrentUserMixin],

  data() {
    return {
      sidebarLoading: false,
      loadingSubscription: true
    };
  },

  computed: {
    currency() {
      if (!this.subscription) return "";
      const { currency } = this.subscription;
      return currency ? currency : "";
    },

    userLicensePrice() {
      if (!this.subscription) return 0;
      const {
        userLicensePrice,
        userLicenseAnnualPrice,
        billingFrequency
      } = this.subscription;

      return billingFrequency === "a"
        ? userLicenseAnnualPrice
        : userLicensePrice;
    },

    financeAddOn() {
      if (!this.subscription) return false;

      const financeFeatures = this.companyLicenses
        ? this.companyLicenses.filter(item => {
            return item.typeName.toUpperCase() === FINANCE_MANAGEMENT;
          })
        : [];

      return financeFeatures.length > 0
        ? {
            price: financeFeatures[0].price,
            status: financeFeatures[0].quantity !== 0
          }
        : {
            price: 0,
            status: false
          };
    },

    additionalLicenses() {
      return this.subscription && this.subscription.additionalLicenses > 0
        ? this.subscription.additionalLicenses
        : 0;
    },

    noLicensesAvailable() {
      return this.licensesRemaining < 1;
    },

    usedLicenses() {
      return this.subscription?.usedLicenses || 0;
    },

    taxPrice() {
      return (this.totalPrice * this.taxRate.rate) / 100;
    },

    taxRate() {
      return this.subscription.taxRate;
    },

    taxRateName() {
      return this.subscription?.taxRate ? this.subscription.taxRate.name : "";
    },

    nextPayment() {
      if (!this.subscription) return 0;
      const { card } = this.subscription;
      return card && card.currentPaymentAmount
        ? card.currentPaymentAmount
        : this.totalPrice;
    }
  },

  methods: {
    updateSubscriptionFeature(input) {
      this.sidebarLoading = true;
      return this.$apollo
        .mutate({
          mutation: UpdateSubscriptionFeature,

          variables: {
            input: {
              ...input,
              updateStripe: this.subscription.isTrial ? false : true
            }
          }
        })
        .then(({ data: { updateSubscriptionFeature } }) => {
          let userLicenses = 0;
          const companyLicenses = this.companyLicenses.map(item => {
            const licenseItem =
              item.licenseId !== input.licenseId
                ? item
                : {
                    ...item,
                    ...updateSubscriptionFeature.companyLicenses
                  };

            if (licenseItem.typeName.toUpperCase() === USER_LIMIT) {
              userLicenses = licenseItem.quantity;
            }
            return licenseItem;
          });
          this.subscription = {
            ...this.subscription,
            companyLicenses: companyLicenses,
            userLicenses: userLicenses,
            additionalLicenses: userLicenses - 1,
            licensesRemaining: userLicenses - this.usedLicenses,
            card: {
              ...this.subscription.card,
              ...updateSubscriptionFeature.subscription.card
            }
          };

          this.sidebarLoading = false;
          this.$flash.success(UPDATE_SUCCES);
          if (typeof this.closeSidebar === "function") this.closeSidebar();
        })
        .catch(error => {
          this.sidebarLoading = false;
          this.$flash.error(gqlErrorMessage(error));
        });
    }
  }
};
