<template lang="pug">
  div
    app-loader(:loading="loading")
      div(v-if="error")
      .grid.gap-4(v-else class="grid-cols-1 md:grid-cols-3")
        .col-span-1
          subscription-card(:subscription="subscription",
                            :loading="loading",
                            :trialDays="trialDays"
                            :subscription-fee="userLicensePrice"
                            :currency="currency",
                            @submit="subscribe",
                            @downgrade="onActivateFreePlan")

          subscription-usage(:subscription="subscription",
                            :additional-licenses="additionalLicenses",
                            :company="company",
                            :licenses-remaining="licensesRemaining",
                            @submit="subscribe")

          app-panel(v-if="subscription && subscription.managedByStripe")
            h2.font-bold.mb-4 End subscription
            p.mb-4 If you cancel your subscription, you will lose access to your data on ManagePlaces. You will be able to resume your subscription at any time.
            app-button.w-full.text-center(danger
              v-if="surveyQuestions != null"
              @click.prevent="cancelSubscription"
            ) Cancel my subscription

        div(class="col-span-1 md:col-span-2")
          subscription-license(:subscription="subscription",
                              :loading="loading",
                              :trial-days="trialDays",
                              :subscription-fee="userLicensePrice",
                              :user-licenses="userLicenses",
                              :used-licenses="usedLicenses",
                              :additional-licenses="additionalLicenses",
                              :licenses-remaining="licensesRemaining",
                              :currency="currency",
                              @submit="openSidebar('License')")

          subscription-add-ons(:subscription="subscription",
                              :loading="loading",
                              :subscription-fee="financeAddOn.price",
                              :user-licenses="userLicenses",
                              :financeManagementStatus="financeAddOn.status",
                              :currency="currency",
                              @submit="openSidebar('AddOns')")

          subscription-payment(:subscription="subscription",
                              :card-data="card",
                              :loading="loading",
                              :total-price="totalPrice",
                              :next-payment="nextPayment",
                              :currency="currency",
                              @submit="openSidebar('Payment')"
                              @open-history="openSidebar('PaymentHistory')")

          subscription-bill(:subscription="subscription",
                            :company="company",
                            :loading="loading",
                            :currency="currency",
                            @submit="openSidebar('Billing')"
                            @open-tax="openSidebar('Tax')")

      app-sidebar-container(v-model="isSidebarOpen")
        app-sidebar(class="overflow-y-hidden" v-model="isSidebarOpen", :compact="noSidebarPadding" @close="closeSidebar")
          template(#title) {{ sidebarTitle }}
          .p-8
            app-loader(:loading="sidebarLoading")
              template(v-if="sidebarType == 'License'")
                license-tab(:subscription="subscription",
                            :user-license-price="userLicensePrice",
                            :add-on-price="financeAddOn.price",
                            :additional-licenses="additionalLicenses",
                            :financeManagementStatus="financeAddOn.status",
                            :voucher="subscription.voucher",
                            :tax-price="taxPrice",
                            :taxRate="taxRate",
                            :user-license="userLicense",
                            :used-licenses="usedLicenses",
                            :currency="currency",
                            :next-payment="nextPayment",
                            :previous-licenses="previousLicenses",
                            @close="closeSidebar",
                            @update-feature="updateSubscriptionFeature")

              template(v-else-if="sidebarType == 'AddOns'")
                add-ons-tab(:subscription="subscription",
                            :user-license-price="userLicensePrice",
                            :add-on-price="financeAddOn.price",
                            :additional-licenses="additionalLicenses",
                            :total-price="totalPrice",
                            :financeManagementStatus="financeAddOn.status",
                            :voucher="subscription.voucher",
                            :voucher-price="voucherPrice",
                            :tax-price="taxPrice",
                            :taxRate="taxRate",
                            :add-on-license="addonLicense",
                            :currency="currency",
                            :next-payment="nextPayment",
                            :previous-finance-status="previousFinanceStatus",
                            @close="closeSidebar",
                            @update-feature="updateSubscriptionFeature")

              template(v-else-if="sidebarType == 'Payment'")
                payment-tab(:subscription="subscription",
                            :card-data="card",
                            :available-countries="availableCountries",
                            :currency="currency",
                            :billing-option="billingOption",
                            @set-option="setBillingOption",
                            @close="closeSidebar()",
                            @update-card="updateSubscriptionCard",
                            @update-billing="updateBillingDetails")

              template(v-else-if="sidebarType == 'PaymentHistory'")
                payment-history-tab(:subscription="subscription",
                                    :card-data="card",
                                    :currency="currency",
                                    @close="closeSidebar()")

              template(v-else-if="sidebarType == 'Billing'")
                billing-tab(:subscription="subscription",
                            :available-countries="availableCountries",
                            :currency="currency",
                            @close="closeSidebar()",
                            @submit="updateBillingDetails")

              template(v-else-if="sidebarType == 'Tax'")
                tax-tab(:company="company",
                        :subscription="subscription",
                        :available-countries="availableCountries",
                        :is-tax-invalid="isTaxInvalid",
                        :currency="currency",
                        :tax-option="taxOption",
                        @set-option="setTaxOption",
                        @close="closeSidebar()",
                        @submit="updateTax")
              template(v-else)
                .hidden
</template>
<script>
import SubscriptionMixin from "@/mixins/subscriptions/Subscription.js";
import LicenseMixin from "@/mixins/subscriptions/License.js";
import CountryQuery from "@/graphql/queries/core/company/AvailableCountries.gql";
import CompanyQuery from "@/graphql/queries/core/company/Company.gql";
import SurveyQuestions from "@/graphql/queries/subscription/SurveyQuestions.gql";
import UpdateBillingInfo from "@/graphql/mutations/subscriptions/UpdateBillingInfo.gql";
import UpdateTaxInfo from "@/graphql/mutations/subscriptions/UpdateTaxInfo.gql";
import UpdateCardMutation from "@/graphql/mutations/subscriptions/UpdatePaymentCard.gql";
import EndSubscriptionMutation from "@/graphql/mutations/subscriptions/EndSubscription.gql";
import ActivateFreePlanMutation from "@/graphql/mutations/subscriptions/ActivateFreePlan.gql";
import SubscriptionCard from "@/components/subscriptions/SubscriptionCard.vue";
import SubscriptionLicense from "@/components/subscriptions/SubscriptionLicense.vue";
import SubscriptionAddOns from "@/components/subscriptions/SubscriptionAddOns.vue";
import SubscriptionPayment from "@/components/subscriptions/SubscriptionPayment.vue";
import SubscriptionBill from "@/components/subscriptions/SubscriptionBill.vue";
import SubscriptionUsage from "@/components/subscriptions/SubscriptionUsage.vue";
import AddOnsTab from "@/components/subscriptions/AddOnsTab.vue";
import LicenseTab from "@/components/subscriptions/LicenseTab.vue";
import PaymentTab from "@/components/subscriptions/PaymentTab.vue";
import PaymentHistoryTab from "@/components/subscriptions/PaymentHistoryTab.vue";
import BillingTab from "@/components/subscriptions/BillingTab.vue";
import TaxTab from "@/components/subscriptions/TaxTab.vue";
import {
  UPDATE_SUCCES,
  END_SUBSCRIPTION_SUCCESS
} from "@/config/help_messages/SubscriptionMessages";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

const TRIAL_DAYS = 30;

export default {
  components: {
    SubscriptionCard,
    SubscriptionLicense,
    SubscriptionAddOns,
    SubscriptionPayment,
    SubscriptionBill,
    SubscriptionUsage,
    AddOnsTab,
    LicenseTab,
    PaymentTab,
    PaymentHistoryTab,
    BillingTab,
    TaxTab
  },
  mixins: [SubscriptionMixin, LicenseMixin],
  data() {
    return {
      isShowCancelModal: false,
      loading: true,
      sidebarLoading: false,
      trialDays: TRIAL_DAYS,
      isSidebarOpen: false,
      noSidebarPadding: true,
      sidebarType: 0, // 0: none, 1: license, 2: add-ons
      isTaxInvalid: false,
      error: false,
      taxOption: 1,
      billingOption: 0,
      previousFinanceStatus: false
    };
  },
  apollo: {
    availableCountries: {
      query: CountryQuery,
      result(res) {
        if (res.data && res.data.availableCountries) {
          this.availableCountries = res.data.availableCountries;
        } else {
          this.availableCountries = [];
        }
      }
    },
    company: {
      query: CompanyQuery,
      result(res) {
        if (res.data && res.data.company) {
          this.company = res.data.company;
        } else {
          this.company = null;
        }
      }
    },
    surveyQuestions: {
      query: SurveyQuestions,
      result(res) {
        if (res.data && res.data.surveyQuestions) {
          this.surveyQuestions = res.data.surveyQuestions;
        } else {
          this.surveyQuestions = null;
        }
      }
    }
  },
  computed: {
    sidebarTitle() {
      switch (this.sidebarType) {
        case "License":
          return "Add or remove user licenses";

        case "AddOns":
          return "Modify add-ons";

        case "Payment":
          return "Change payment method";

        case "PaymentHistory":
          return "Payment history";

        case "Billing":
          return "Billing details";

        case "Tax":
          return "Update tax information";

        default:
          return "";
      }
    },
    financePrice() {
      return this.financeAddOn.price;
    },
    sum() {
      if (this.subscription) {
        let sum = this.userLicensePrice * this.userLicenses;

        if (this.financeAddOn.status) {
          sum += this.financePrice;
        }

        return sum;
      }

      return 0;
    },
    totalPrice() {
      const price =
        this.subscription && this.subscription.voucher
          ? this.sum - this.voucherPrice
          : this.sum;

      return price;
    },
    card() {
      return this.subscription ? this.subscription.card : {};
    },
    voucherPrice() {
      if (!this.subscription) return 0;
      const { voucher } = this.subscription;
      let voucherPrice = 0;
      if (voucher) {
        if (voucher.percentOff) {
          voucherPrice = (this.sum * voucher.percentOff) / 100;
        } else {
          voucherPrice = voucher.amountOff;
        }
      }
      return voucherPrice;
    }
  },
  methods: {
    subscribe() {
      this.$router.push("/subscriptions/new");
    },
    openSidebar(type) {
      this.isSidebarOpen = true;
      this.sidebarType = type;
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      this.sidebarType = null;
    },
    updateBillingDetails(input) {
      this.sidebarLoading = true;
      this.$apollo
        .mutate({
          mutation: UpdateBillingInfo,
          variables: { input }
        })
        .then(({ data: { updateBillingInfo } }) => {
          this.subscription = {
            ...this.subscription,
            ...updateBillingInfo.subscription
          };

          this.sidebarLoading = false;
          this.$flash.success(UPDATE_SUCCES);
          this.closeSidebar();
        })
        .catch(error => {
          this.sidebarLoading = false;
          this.$flash.error(gqlErrorMessage(error));
        });
    },
    updateTax(input) {
      this.sidebarLoading = true;

      this.$apollo
        .mutate({
          mutation: UpdateTaxInfo,
          variables: { input }
        })
        .then(({ data: { updateTaxInfo } }) => {
          if (updateTaxInfo.status) {
            this.subscription = {
              ...this.subscription,
              ...input
            };

            if (input.country && input.taxNumber) {
              this.subscription.taxNumber = `${input.country}${input.taxNumber}`;
            } else {
              this.subscription.taxNumber = null;
            }

            this.sidebarLoading = false;
            this.$flash.success(UPDATE_SUCCES);
            this.isTaxInvalid = false;
            this.closeSidebar();
          } else {
            this.$flash.error(updateTaxInfo.message);
            this.isTaxInvalid = true;
          }
        })
        .catch(error => {
          this.sidebarLoading = false;
          this.$flash.error(gqlErrorMessage(error));
        });
    },
    updateSubscriptionCard(response) {
      this.sidebarLoading = true;

      this.$apollo
        .mutate({
          mutation: UpdateCardMutation,
          variables: {
            input: {
              cardToken: response.token.id
            }
          }
        })
        .then(({ data: { updatePaymentCard } }) => {
          const { subscription } = updatePaymentCard;

          this.sidebarLoading = false;
          if (subscription && subscription.status) {
            this.subscription = {
              ...this.subscription,
              ...subscription
            };

            this.$flash.success(UPDATE_SUCCES);
            this.closeSidebar();
          }
        })
        .catch(error => {
          this.sidebarLoading = false;
          this.$flash.error(gqlErrorMessage(error));
        });
    },
    endSubscriptionCard(input) {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: EndSubscriptionMutation,
          variables: { input }
        })
        .then(({ data: { endSubscription } }) => {
          this.subscription = {
            ...this.subscription,
            isStripeSubscription: !endSubscription.status
          };

          if (status) {
            this.$flash.success(END_SUBSCRIPTION_SUCCESS);
            this.isShowCancelModal = false;
            this.closeSidebar();
          }
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.$flash.error(gqlErrorMessage(error));
        });
    },
    onActivateFreePlan() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: ActivateFreePlanMutation
        })
        .then(({ data: { activateFreePlan } }) => {
          this.loading = false;
          if (activateFreePlan.status) {
            this.subscription = {
              ...this.subscription,
              ...activateFreePlan.subscription
            };
            this.$flash.success("Successfully downgraded");
            this.closeSidebar();
          }
        })
        .catch(error => {
          this.loading = false;
          this.$flash.error(gqlErrorMessage(error));
        });
    },
    cancelSubscription() {
      this.$router.push("/subscriptions/cancellation");
    },
    setTaxOption(val) {
      this.taxOption = val;
    },
    setBillingOption(val) {
      this.billingOption = val;
    }
  }
};
</script>

<style lang="postcss" scoped>
.subscription-page {
  width: initial !important;
  max-width: 1000px;
}
.subscription-card {
  padding: 2rem;
}
.card-submit {
  min-width: 250px;
}
.addon-tab {
  position: relative;
  height: 100%;
}
::v-deep {
  .progress-bar {
    height: 5px;
  }
  .addon-tab-content {
    height: calc(100% - 120px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .no-bottom {
      margin-bottom: 0 !important;
    }
  }
  /* .addon-item {
    margin-left: 2rem;
    margin-right: 2rem;

    &.panel {
      @apply bg-grey-10;
    }
  } */
  .custom-progress div {
    max-width: 100%;
  }
  .overflow-y-hidden {
    overflow-y: hidden !important;
  }
}
</style>
