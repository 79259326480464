import { runMutation } from "@/helpers/GraphQLHelpers";
import { buildTaskParams, setLockVersion } from "@/helpers/TaskHelpers";
import store from "@/store";

import CreateTask from "@/graphql/mutations/tasks/CreateTask.gql";
import DeleteTasks from "@/graphql/mutations/tasks/DeleteTasks.gql";
import UpdateTaskTable from "@/graphql/mutations/tasks/UpdateTaskTable.gql";
import UpdateTask from "@/graphql/mutations/tasks/UpdateTask.gql";

export default class TaskManager {
  constructor(project) {
    this.project = project;
  }

  /**
   *  Creates a new task with default values. The task table
   *  creates a new task first, and THEN lets the user make
   *  changes to speed up the process. Optionally a parent
   *  task can be supplied which will allow the task to be
   *  created as a subtask
   *
   *  @param parent The optional parent task
   **/
  createTask(parent, opts) {
    const params = buildTaskParams(this.project, parent, opts);

    return this._runMutation(CreateTask, "createTask", params).then(
      ({ data }) => data.createTask.task
    );
  }

  /**
   *  Deletes one or more specified tasks
   **/
  delete(tasks) {
    const taskIds = tasks.map(task => task.id);

    return this._runMutation(DeleteTasks, "deleteTasks", { taskIds });
  }

  /**
   * Updates the specified field of the task.
   * This will not change the value on the task
   * object itself, but instead make a request to
   * the server to update the value. Returns a
   * promise
   */
  updateTable(task, field) {
    const value = task[field];
    const params = {
      id: task.id,
      field: field,
      [field]: value
    };

    return this._runMutation(UpdateTaskTable, "updateTaskTable", params).then(
      ({
        data: {
          updateTaskTable: { tasks }
        }
      }) => tasks
    );
  }

  updateTask(task) {
    return this._runMutation(UpdateTask, "updateTask", task);
  }

  _lockVersionParam() {
    return { lockVersion: store.state.taskManagement.lockVersion };
  }

  _runMutation(mutation, mutationName, params) {
    store.commit("taskManagement/setReadOnly", true);
    return runMutation(mutation, params, this._lockVersionParam())
      .then(({ data }) => {
        setLockVersion(data[mutationName].project.lockVersion);
        store.commit("taskManagement/setReadOnly", false);
        return { data };
      })
      .catch(err => {
        store.commit("taskManagement/setReadOnly", false);
        throw err;
      });
  }
}
