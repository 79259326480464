import App from "../App.vue";

const NewEstimate = () =>
  import(
    /* webpackChunkName: "new-estimate" */ "../views/finances/estimates/New.vue"
  ).then(m => m.default);
const EditEstimate = () =>
  import(
    /* webpackChunkName: "edit-estimate" */ "../views/finances/estimates/Edit.vue"
  ).then(m => m.default);

export default [
  {
    name: "Estimates",
    path: "/estimates",
    component: App,
    children: [
      {
        path: "/projects/:id/estimates/new",
        component: NewEstimate,
        meta: { title: "Add estimate" }
      },
      { path: "new", component: NewEstimate, meta: { title: "Add estimate" } },
      {
        path: ":id/edit",
        component: EditEstimate,
        meta: { title: "Edit estimate" }
      }
    ]
  }
];
