<template lang="pug">
  app-panel(ref="panel")
    FormProgress(@step-clicked="setCurrentStep")

    .p-4.pt-8(ref="grid")
      form-wrapper(:validator="$v.taskToEdit")
        form(@submit.prevent="goNext")
          keep-alive
            component(v-if="taskToEdit" :is="currentComponent" @cancel="cancel" v-model="taskToEdit" v-bind="componentAttrs")

          .mt-24
            app-button.inline-block.mr-4(v-if="!isFirstStep()" @click.prevent="goBack")
              | Back
            app-button.inline-block.mr-4(:loading="saving" primary @click.prevent="goNext") {{ nextBtnText }}
            a.block.my-4(@click.prevent="cancel") Cancel

</template>

<script>
import { parse } from "date-fns";
import { required, requiredIf, maxLength, not } from "vuelidate/lib/validators";

import FormProgress from "@/components/elements/FormProgress.vue";

import TaskBasicDetails from "@/components/tasks/wizard/TaskBasicDetails.vue";
import TaskTimescale from "@/components/tasks/wizard/TaskTimescale.vue";
import TaskAdvanced from "@/components/tasks/wizard/TaskAdvanced.vue";

import { extractTaskFields } from "@/helpers/TaskHelpers";
import { nextWorkingDay, formatDateApi } from "@/helpers/DateHelpers";
import { isValidDate, before } from "@/helpers/Validators";
import WizardStepsMixin from "@/mixins/WizardSteps.js";

import CreateTask from "@/graphql/mutations/tasks/CreateTask.gql";
import UpdateTask from "@/graphql/mutations/tasks/UpdateTask.gql";
import ProjectQuery from "@/graphql/queries/core/projects/Project.gql";
import TaskQuery from "@/graphql/queries/core/tasks/Task.gql";

export default {
  name: "TaskWizard",

  components: {
    FormProgress,
    TaskBasicDetails,
    TaskTimescale,
    TaskAdvanced
  },

  mixins: [WizardStepsMixin],

  apollo: {
    project: {
      query: ProjectQuery,

      variables() {
        return {
          id: this.projectId
        };
      },

      skip() {
        return this.isEditTask || !this.projectId;
      }
    },

    task: {
      query: TaskQuery,

      skip() {
        return this.isNewTask;
      },

      variables() {
        return {
          id: this.taskId
        };
      },

      update(data) {
        const task = extractTaskFields(data.task);
        const { childCount, parent, project } = data.task;

        this.hasDependencies = !!task.dependencyIds.length;
        this.project = project;

        if (this.isNewSubtask) {
          this.subTask.projectId = task.projectId;
          this.subTask.parentId = task.id;
          this.isSubtask = true;
          this.hasSubtasks = false;
        } else {
          this.isSubtask = !!parent;
          this.hasSubtasks = !!childCount;
        }

        return task;
      }
    }
  },

  data() {
    const data = {
      resourceType: "Task",
      saving: false,
      isSubtask: null,
      hasSubtasks: false,
      hasDependencies: null,
      project: {}
    };
    const wizardType = this.$route.name;

    if (wizardType != "editTaskWizard") {
      const taskToEdit = {
        name: "",
        description: null,
        projectId: null,
        parentId: null,
        taskTypeId: null,
        billable: false,
        priority: "NORMAL",
        dateIsCalculated: false,
        dateCalculationType: "start_to_end",
        dependencyIds: [],
        startsAt: null,
        deadline: null,
        enableReminders: false,
        trackingTargetsAttributes: [],
        resourceFieldsAttributes: []
      };

      if (wizardType == "newSubtaskWizard") {
        data.subTask = taskToEdit;
      } else if (wizardType == "newTaskWizard") {
        taskToEdit.projectId = this.$route.params.projectId;
        data.task = taskToEdit;
      }
    }

    return data;
  },

  computed: {
    componentAttrs() {
      return {
        "is-subtask": this.isSubtask,
        "has-subtasks": this.hasSubtasks,
        "has-dependencies": this.hasDependencies,
        "working-week": this.workingWeek
      };
    },

    workingWeek() {
      return this.project.workingWeek || this.$store.state.workingWeek;
    },

    isNewTask() {
      return this.$route.name == "newTaskWizard";
    },

    isEditTask() {
      return this.$route.name == "editTaskWizard";
    },

    isNewSubtask() {
      return this.$route.name == "newSubtaskWizard";
    },

    projectId() {
      return this.taskToEdit.projectId;
    },

    taskId() {
      const { id, taskId } = this.$route.params;
      return taskId || id;
    },

    taskToEdit: {
      get() {
        if (this.isNewSubtask) return this.subTask;
        return this.task;
      },

      set(value) {
        if (this.isNewSubtask) this.subTask = value;
        this.task = value;
      }
    },

    modelName() {
      return "taskToEdit";
    },

    validatedFields() {
      return {
        "Basic details": ["name"],
        Timescale: ["startsAt", "deadline", "dateCalculationType"]
      };
    },

    stepComponent() {
      return {
        "Basic details": "TaskBasicDetails"
      };
    },

    nextBtnText() {
      if (this.saving) {
        return "Saving";
      } else if (this.isLastStep()) {
        return this.isEditTask ? "Update task" : "Create task";
      }

      return "Next";
    },

    cancelPath() {
      const { projectId, taskId } = this;

      if (this.isNewSubtask) {
        return `/tasks/${this.task.parentId}#subtasks`;
      } else if (taskId) {
        return `/tasks/${taskId}`;
      } else if (projectId) {
        return `/projects/${projectId}#tasks`;
      } else {
        return "/";
      }
    }
  },

  watch: {
    taskToEdit: {
      deep: true,
      handler() {
        this.findFailedStepsBefore(this.currentStep);
      }
    },
    workingWeek: {
      deep: true,
      handler(workingWeek) {
        if (!this.taskToEdit.startsAt)
          this.taskToEdit.startsAt = this.taskToEdit.deadline = formatDateApi(
            nextWorkingDay(workingWeek)
          );
      }
    }
  },

  validations() {
    return {
      taskToEdit: {
        name: { required, maxLength: maxLength(255) },
        startsAt: {
          required,
          isValidDate,
          beforeDeadline: before("deadline", d => {
            return parse(d, "yyyy/MM/dd", new Date());
          })
        },
        deadline: {
          required,
          isValidDate,
          afterStartDate: not(
            before("startsAt", d => parse(d, "yyyy/MM/dd", new Date()), true)
          )
        },
        dateCalculationType: {
          required: requiredIf(task => task.dateIsCalculated)
        }
      }
    };
  },

  beforeCreate() {
    this.$store.commit("clearWizardState");
    this.$store.commit("setStepsList", "taskWizardSteps");
  },

  methods: {
    cancel() {
      window.location = this.cancelPath;
    },

    save() {
      if (this.saving) return;

      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.saving = true;
      this.error = null;

      let task = { ...this.taskToEdit };

      let mutation = CreateTask;

      if (this.isEditTask) {
        mutation = UpdateTask;
      }

      this.$apollo
        .mutate({
          mutation: mutation,

          variables: {
            input: task
          }
        })
        .then(resp => {
          const { data } = resp;
          const mutationName = mutation.definitions[0].name.value;

          window.location = `/tasks/${data[mutationName].task.id}?show_flash=t`;
        })
        .catch(error => {
          this.saving = false;
          this.error = error;
        });
    }
  }
};
</script>
