<template lang="pug">
  .addresses
    .flex.flex-row
      app-icon(icon="address").mr-4
      .flex.flex-col.flex-grow
        .flex.flex-row
          strong Addresses
    
        span.italic(v-if="!addresses.length && !createAddressFormToggle") No addresses
        .mt-4(v-else-if="addresses.length")
          .address.flex.flex-row.border-b.border-grey-30.py-2(v-for="address in addresses", :key="address.key")
            template(v-if="!isEditing(address)")
              strong.flex-grow-0.mr-8.w-24 {{ humanize(address.addressType) }}
              .flex.flex-col.flex-grow
                span.text-grey-70 {{ address.line1 }}
                span.text-grey-70 {{ address.line2 }}
                span.text-grey-70 {{ address.line3 }}
                span.text-grey-70 {{ address.line4 }}
                span.text-grey-70 {{ address.city }}
                span.text-grey-70 {{ address.region }}
                span.text-grey-70 {{ address.postalCode }}
                span.text-grey-70 {{ address.country }}
              a(@click.prevent="editAddressToggle(address)") Edit

            template(v-else, nested, v-if="isEditing(address)")
              app-panel.flex-1(class="-ml-8")
                .flex.flex-row.mb-6
                  span.font-bold.text-lg.flex-1 Editing address
                  a.text-withered-cherry(@click.prevent="deleteContactAddress") Delete

                form-wrapper(:validator="$v.editingAddress")
                  .flex.flex-row
                    .flex.flex-col.flex-grow
                      app-text-field(v-model="editingAddress.name" label="Name" description="Give the address a name to identify it by later" name="name" attribute="name")
                      app-dropdown-field(name="addressType", label="Address type", v-model="editingAddress.addressType", :options="addressTypes", label-attr="name", value-attr="value", deselectLabel="" :allow-empty="false" attribute="address type")
                      app-text-field(v-model="editingAddress.line1" label="Street address" description="Building name/number, street name, etc" name="line1" attribute="street address")
                      app-text-field(v-model="editingAddress.line2" label="Apartment, suite, etc" description="Example: 3rd floor" name="line2")
                      app-text-field(v-model="editingAddress.city" label="City" name="city")
                      app-text-field(v-model="editingAddress.region" label="State/province" name="region")
                      app-text-field(v-model="editingAddress.country" label="Country" name="country")
                      app-text-field(v-model="editingAddress.postalCode" label="Zip/postal code" name="postalCode" attribute="zip/postal code")

                  .mt-8
                    app-button(primary slim @click.prevent="updateContactAddress") Update
                    a.block.mt-4(@click.prevent="cancelEdit") Cancel

        .mt-8(v-if="!createAddressFormToggle")
          app-button.border-tribal-aqua.flex-initial.text-center(primary slim @click="createAddressToggle") Add address

    app-panel.mt-4(nested v-if="createAddressFormToggle")
      .font-bold.text-lg.mb-8 Add a new address
      form-wrapper(:validator="$v.createAddress")
        app-text-field(v-model="createAddress.name" label="Name" description="Give the address a name to identify it by later" name="name" attribute="name")
        app-dropdown-field(name="addressType", label="Address type", v-model="createAddress.addressType", :options="addressTypes", label-attr="name", value-attr="value", deselectLabel="" :allow-empty="false" attribute="address type")
        app-text-field(v-model="createAddress.line1" label="Street address" description="Building name/number, street name, etc" name="line1" attribute="street address")
        app-text-field(v-model="createAddress.line2" label="Apartment, suite, etc" description="Example: 3rd floor" name="line2")
        app-text-field(v-model="createAddress.city" label="City" name="city")
        app-text-field(v-model="createAddress.region" label="State/province" name="region")
        app-text-field(v-model="createAddress.country" label="Country" name="country")
        app-text-field(v-model="createAddress.postalCode" label="Zip/postal code" name="postalCode" attribute="zip/postal code")

      .mt-8
        app-button(primary slim @click.prevent="createContactAddress") Add address
        a.mt-4.block(@click.prevent="cancelCreate") Cancel

</template>

<script>
import { humanize } from "@/helpers/StringHelpers";
import UpdateContactAddressMutation from "@/graphql/mutations/contact_management/contact_addresses/updateContactAddress.gql";
import CreateContactAddressMutation from "@/graphql/mutations/contact_management/contact_addresses/createContactAddress.gql";
import DeleteContactAddressMutation from "@/graphql/mutations/contact_management/contact_addresses/deleteContactAddress.gql";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editingAddress: null,
      addressTypes: [
        { name: "Work", value: "WORK" },
        { name: "Personal", value: "PERSONAL" },
        { name: "Other", value: "OTHER" }
      ],
      addresses: [],
      createAddress: {
        name: null,
        addressType: null,
        line1: null,
        line2: null,
        line3: null,
        line4: null,
        city: null,
        region: null,
        postalCode: null,
        country: null
      },
      createAddressFormToggle: false
    };
  },
  mounted() {
    this.addresses = this.contact.addresses.map(address => ({
      ...address,
      key: Math.random()
        .toString(36)
        .substr(2, 9)
    }));
  },
  methods: {
    humanize,
    editAddressToggle(address) {
      this.editingAddress = { ...address };
    },
    isEditing(address) {
      if (this.editingAddress) {
        return this.editingAddress.key === address.key;
      }

      return false;
    },
    updateContactAddress() {
      this.$v.editingAddress.$touch();
      if (this.$v.editingAddress.$invalid) {
        return;
      }
      let inputAddress = { ...this.editingAddress };
      delete inputAddress.__typename;
      delete inputAddress.key;
      return this.$apollo
        .mutate({
          mutation: UpdateContactAddressMutation,
          variables: {
            input: {
              ...inputAddress
            }
          }
        })
        .then(() => {
          this.$flash.success("Contact address updated");
          let address = this.addresses.find(
            address => address.key === this.editingAddress.key
          );
          Object.assign(address, this.editingAddress);
          this.editingAddress = null;
        });
    },
    createContactAddress() {
      this.$v.createAddress.$touch();
      if (this.$v.createAddress.$invalid) {
        return;
      }

      return this.$apollo
        .mutate({
          mutation: CreateContactAddressMutation,
          variables: {
            input: {
              ...this.createAddress,
              contactId: this.contact.id
            }
          }
        })
        .then(response => {
          const address = response.data.createContactAddress.contactAddress;
          delete address.contactId;
          this.$flash.success("Contact address created");
          var key = Math.random()
            .toString(36)
            .substr(2, 9);
          this.addresses.push({
            key: key,
            ...address
          });
          this.createAddressFormToggle = false;
          this.resetNewAddress();
        });
    },
    resetNewAddress() {
      this.$v.createAddress.$reset();
      this.createAddress = {
        addressType: null,
        line1: null,
        line2: null,
        line3: null,
        line4: null,
        city: null,
        region: null,
        postalCode: null,
        country: null
      };
    },
    deleteContactAddress() {
      this.$dialog
        .confirm({
          title: "Are you sure you want to delete this address?",
          message: "Deleting an address cannot be undone"
        })
        .onOk(({ api }) => {
          api.hide();
          return this.$apollo
            .mutate({
              mutation: DeleteContactAddressMutation,
              variables: {
                input: {
                  id: this.editingAddress.id
                }
              }
            })
            .then(() => {
              this.$flash.success("Contact address deleted");
              let addressIndex = this.addresses.findIndex(
                address => address.key === this.editingAddress.key
              );
              this.addresses.splice(addressIndex, 1);
              this.editingAddress = null;
            });
        });
    },
    cancelEdit() {
      this.editingAddress = null;
    },
    cancelCreate() {
      this.createAddressFormToggle = false;
      this.resetNewAddress();
      this.$v.createAddress.$reset();
    },
    createAddressToggle() {
      this.createAddressFormToggle = true;
    }
  },
  validations() {
    return {
      editingAddress: {
        name: {
          required
        },
        addressType: {
          required
        },
        line1: {
          required
        },
        postalCode: {
          required
        }
      },
      createAddress: {
        name: {
          required
        },
        addressType: {
          required
        },
        line1: {
          required
        },
        postalCode: {
          required
        }
      }
    };
  }
};
</script>

<style type="text/css">
.address:last-child {
  border: none;
}
</style>
