import App from "../App.vue";

const NewInvoice = () =>
  import(
    /* webpackChunkName: "new-invoice" */ "../views/finances/invoices/New.vue"
  ).then(m => m.default);
const EditInvoice = () =>
  import(
    /* webpackChunkName: "edit-invoice" */ "../views/finances/invoices/Edit.vue"
  ).then(m => m.default);

export default [
  {
    name: "Invoices",
    path: "/invoices",
    component: App,
    children: [
      {
        path: "/projects/:id/invoices/new",
        component: NewInvoice,
        meta: { title: "Add invoice" }
      },
      { path: "new", component: NewInvoice, meta: { title: "Add invoice" } },
      {
        path: ":id/edit",
        component: EditInvoice,
        meta: { title: "Edit invoice" }
      }
    ]
  }
];
