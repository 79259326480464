<template lang="pug">
  .sixteen.wide.divider
    a.small.basic.ui.button#add-button-tabular(@click.prevent='$emit("addRow", $event)') Add Row
</template>

<script>
export default {
  methods: {
    addRow() {
      this.$emit("addRow");
    }
  }
};
</script>
