export const categories = {
  accounts: ["viewAccount", "editAccount", "createAccount", "removeAccount"],
  bills: ["viewBill", "editBill", "createBill", "removeBill"],
  changeOrders: [
    "viewChangeOrder",
    "editChangeOrder",
    "removeChangeOrder",
    "createChangeOrder",
    "authoriseChangeOrder",
    "rejectChangeOrder"
  ],
  company: ["viewCompany", "editCompany"],
  companyDocuments: [
    "viewDocument",
    "editDocument",
    "removeDocument",
    "createDocument",
    "shareDocument"
  ],
  contacts: ["viewContact", "editContact", "removeContact", "createContact"],
  customFields: [
    "viewProperty",
    "editProperty",
    "removeProperty",
    "createProperty"
  ],
  estimates: [
    "viewEstimate",
    "editEstimate",
    "createEstimate",
    "removeEstimate"
  ],
  invoices: ["viewInvoice", "editInvoice", "createInvoice", "removeInvoice"],
  items: ["viewItem", "editItem", "createItem", "removeItem"],
  kpi: [
    "viewTrackables",
    "editTrackables",
    "removeTrackables",
    "createTrackables"
  ],
  miscellaneous: ["createComment", "importData"],
  projectDocuments: [
    "viewProjectDocument",
    "editProjectDocument",
    "removeProjectDocument",
    "createProjectDocument",
    "shareProjectDocument"
  ],
  projectPhases: [
    "viewProjectPhase",
    "removeProjectPhase",
    "createProjectPhase",
    "editProjectPhase"
  ],
  projects: [
    "viewProject",
    "createProject",
    "editProject",
    "removeProject",
    "closeProject",
    "viewProjectReport",
    "viewTrackableData",
    "createTrackableData",
    "viewFormValue",
    "editFormValue"
  ],
  purchaseOrders: [
    "viewPurchaseOrder",
    "editPurchaseOrder",
    "removePurchaseOrder",
    "createPurchaseOrder",
    "authorisePurchaseOrder",
    "rejectPurchaseOrder"
  ],
  reporting: ["viewReports"],
  roles: ["viewRoles", "editRoles", "removeRoles", "createRoles"],
  softwareIntegrations: [
    "viewIntegrations",
    "createIntegrations",
    "removeIntegrations"
  ],
  tasks: ["viewTask", "createTask", "editTask", "removeTask", "viewApprovers"],
  taskTypes: [
    "viewTaskTypes",
    "editTaskTypes",
    "removeTaskTypes",
    "createTaskTypes"
  ],
  taxRates: [
    "viewTaxRates",
    "editTaxRates",
    "removeTaxRates",
    "createTaxRates"
  ],
  timesheets: ["timesheet", "companyTimesheet"],
  timesheetEntries: [
    "viewTimesheetEntry",
    "createTimesheetEntry",
    "editTimesheetEntry",
    "removeTimesheetEntry"
  ],
  users: ["viewUsers", "editUsers", "removeUsers", "createUsers"]
};
