<template lang="pug">
  app-panel(secondary)
    app-header(size="h3") Bills
      template(#subheader)
        | Permissions for bills

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")
      template(#options)
        app-checkbox.mb-6(label="View bills" :value="true")
        app-checkbox.mb-6(label="Edit existing bills" :value="permissions.editBill" @input="permissionChanged('editBill', $event)")
        app-checkbox.mb-6(label="Delete bills" :value="permissions.removeBill" @input="permissionChanged('removeBill', $event)")
        app-checkbox.mb-6(label="Create new bills" :value="permissions.createBill" @input="permissionChanged('createBill', $event)")

</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> access bills",
          value: "cannotManage"
        },
        {
          label: "Can access for <strong>assigned projects</strong> only",
          value: "canManageAssigned",
          slot: "options"
        },
        {
          label: "Can access for <strong>any project</strong>",
          value: "canManageAny",
          slot: "options"
        }
      ],
      selected: "cannotManage",
      permissions: {
        viewBill: false,
        editBill: false,
        removeBill: false,
        createBill: false
      },
      scope: "global"
    };
  },
  computed: {
    selectedPermissions() {
      if (this.selected === "cannotManage") {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: this.scope
      };
    }
  },
  mounted() {
    if (this.value.permissions.viewBill) {
      if (this.value && this.value.scope === "project") {
        this.selected = "canManageAssigned";
        this.scope = "project";
      } else {
        this.selected = "canManageAny";
        this.scope = "global";
      }
    }
  },
  methods: {
    optionChanged(value) {
      this.permissions.viewBill = value !== "cannotManage";
      this.scope = value === "canManageAssigned" ? "project" : "global";
      this.selected = value;
      this.notifyChange();
    },
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
