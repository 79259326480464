<template lang="pug">
  div(v-if="dropdown && task.canEdit")
    app-dropdown-field(:options="options", :show-optional="false", :allow-empty="false", deselect-label="", :value="status", @input="statusChanged", value-attr="value")
  app-label.font-normal.mb-4(v-else :colour="statusColour")
    | {{ taskStatus  }}
</template>

<script>
import SetTaskStatus from "@/graphql/mutations/tasks/SetTaskStatus.gql";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import { humanize, caseCompare } from "@/helpers/StringHelpers";
import { Types } from "manageplaces-ui-kit";
import { mapGetters } from "vuex";
import { getLockVersion, setLockVersion } from "@/helpers/TaskHelpers";
import SidebarComponentMixin, {
  readOnlyError
} from "@/mixins/tasks/TaskSidebarComponentMixin";

const statusColours = {
  NOT_STARTED: Types.labels.GREY,
  STARTED: Types.labels.BLUE,
  COMPLETE: Types.labels.GREEN
};

export default {
  mixins: [SidebarComponentMixin],
  props: {
    task: {
      type: Object,
      required: true
    },
    dropdown: {
      type: Boolean,
      required: false,
      default: true
    },
    slim: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      status: this.task.status
    };
  },

  computed: {
    ...mapGetters("taskManagement", ["actionsCallers"]),
    taskStatus() {
      return this.task ? humanize(this.task.status) : "";
    },
    statusColour() {
      const status = this.task ? this.task.status : "";
      return statusColours[status] || Types.labels.GREY;
    },
    options() {
      if (!this.task) {
        return [];
      }

      if (this.task.isMilestone) {
        return [
          {
            label: "Not started",
            value: "NOT_STARTED",
            icon: "circleSolid",
            iconColour: "grey"
          },
          {
            label: "Complete",
            value: "COMPLETE",
            icon: "circleSolid",
            iconColour: "green"
          }
        ];
      } else {
        return [
          {
            label: "Not started",
            value: "NOT_STARTED",
            icon: "circleSolid",
            iconColour: "grey"
          },
          {
            label: "Started",
            value: "STARTED",
            icon: "circleSolid",
            iconColour: "blue"
          },
          {
            label: "Complete",
            value: "COMPLETE",
            icon: "circleSolid",
            iconColour: "green"
          }
        ];
      }
    }
  },
  methods: {
    statusChanged(opt) {
      if (this.readOnly) {
        this.$flash.warn(readOnlyError);
        return;
      }
      if (opt.toLowerCase() === "complete") {
        const finishedTasks = this.task.checklistItems.filter(
          item => item.completed
        );

        if (finishedTasks.length !== this.task.checklistItems.length) {
          this.$dialog
            .confirm({
              title: "Are you sure?",
              message:
                "There are incomplete checklist items in this task. Are you sure you want to mark this task as completed?"
            })
            .onOk(({ api }) => {
              api.hide();
              // Perform the actual change of status here
              this.changeTaskStatus(opt);
            });
        } else {
          this.changeTaskStatus(opt);
        }
      } else {
        this.changeTaskStatus(opt);
      }
    },

    changeTaskStatus(opt) {
      let confirm = false;
      const { approvers, subtasks } = this.task;
      let opts = { status: opt, forceCompleteAllSubtasks: false };

      this.errors = null;

      if (caseCompare(opt, "complete")) {
        const hadAnApproval = approvers.some(a => a.isApproved);
        if (approvers.length && !hadAnApproval) {
          this.$flash.error("This task has not yet been approved");
          return;
        }

        const haveSubtasksBeenApproved = subtasks.every(t =>
          t.approvers.length ? t.approvers.some(a => a.isApproved) : true
        );

        if (!haveSubtasksBeenApproved) {
          this.$flash.error("One or more subtasks have not yet been approved");
          return;
        }

        const hasIncompleteSubtasks = subtasks.some(
          t => !caseCompare(t.status, "complete")
        );
        confirm = hasIncompleteSubtasks;
      }

      if (confirm) {
        this.$dialog
          .confirm({
            title: "Are you sure?",
            message: `There are incomplete subtasks of this task.
          If you mark this task as completed all its subtasks will set completed as well.
          Do you want to continue?`,
            danger: true,
            confirmLabel: "Set all tasks complete"
          })
          .onOk(({ api }) => {
            api.hide();
            opts.forceCompleteAllSubtasks = true;
            this.performTaskUpdate(opts).then(() => {
              subtasks.forEach(t => (t.status = "Complete"));
            });
          });
      } else {
        this.performTaskUpdate(opts);
      }
    },

    performTaskUpdate(opts) {
      const vm = this;
      const { status, forceCompleteAllSubtasks } = opts;
      const oldStatus = this.status;
      this.status = status;

      return this.$apollo
        .mutate({
          mutation: SetTaskStatus,
          variables: {
            input: {
              id: this.task.id,
              status,
              forceCompleteAllSubtasks
            },
            lockVersion: getLockVersion()
          }
        })
        .then(
          ({
            data: {
              setTaskStatus: { tasks, project }
            }
          }) => {
            setLockVersion(project.lockVersion);
            vm.$emit("input", status);
            vm.$emit("tasks-updated", tasks);
          }
        )
        .catch(e => {
          this.status = oldStatus;
          vm.$flash.error(gqlErrorMessage(e));
        });
    }
  }
};
</script>
