export const ACTIONS = {
  EDIT: "edit",
  DELETE: "delete"
};

export const estimatesMenuItems = row => {
  let actions = [];

  if (!row.data.authorised && !row.data.project) {
    if (row.data.canEdit) {
      actions.push({
        label: "Edit estimate",
        icon: "edit",
        action: ACTIONS.EDIT
      });
      actions.push({ type: "divider" });
    }

    if (row.data.canDestroy) {
      actions.push({
        label: "Delete",
        icon: "delete",
        iconColour: "red",
        action: ACTIONS.DELETE
      });
    }
  }

  return actions;
};
