import App from "../App.vue";

const NewBill = () =>
  import(
    /* webpackChunkName: "new-bill" */ "../views/finances/bills/New.vue"
  ).then(m => m.default);
const EditBill = () =>
  import(
    /* webpackChunkName: "edit-bill" */ "../views/finances/bills/Edit.vue"
  ).then(m => m.default);

export default [
  {
    name: "Bills",
    path: "/bills",
    component: App,
    children: [
      {
        path: "/projects/:id/bills/new",
        component: NewBill,
        meta: { title: "Add bill" }
      },
      { path: "new", component: NewBill, meta: { title: "Add bill" } },
      { path: ":id/edit", component: EditBill, meta: { title: "Edit bill" } }
    ]
  }
];
