<template lang="pug">
  .flex.justify-between.flex-col
   .version.flex.justify-between.mb-3(v-for="version in document.versions")
     div.w-34
       p.font-black {{ version.id === document.currentVersion.id ? "Current version" : `Version ${version.version}` }}
       p {{ validateDate(version.createdAt) }}
     app-team-member(:user="version.uploader")

     app-icon.self-center.cursor-pointer(icon="download", @click.native="downloadVersion(version)")
     app-icon.self-center.cursor-pointer(v-if="permissions.canDestroy" icon="delete", @click.native="deleteVersion(version)")
</template>

<script>
import { triggerDownload } from "@/helpers/DownloadHelpers";
import DateValidateMixin from "@/mixins/DateValidator.js";

export default {
  name: "VersionsTab",
  mixins: [DateValidateMixin],
  props: {
    document: {
      type: Object,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  methods: {
    downloadVersion(version) {
      triggerDownload(version.url);
    },
    deleteVersion(version) {
      this.$emit("delete", version, {
        title: "Delete document version",
        message: "Are you sure you want to delete the version?",
        success: "Successfully deleted"
      });
    }
  }
};
</script>

<style scoped></style>
