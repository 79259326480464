<script>
import { Components, Helpers } from "manageplaces-ui-kit";
import DocumentQuery from "@/graphql/queries/document_management/Document.gql";
import FolderQuery from "@/graphql/queries/document_management/Folder.gql";
import UsersQuery from "@/graphql/queries/core/company/UsersSummary.gql";
import ProjectDocuments from "@/graphql/queries/core/projects/ProjectDocuments.gql";
import CompanyDocuments from "@/graphql/queries/core/company/CompanyDocuments.gql";
import ProjectTasks from "@/graphql/queries/core/tasks/ProjectTasks.gql";
import {
  documentMenuItems,
  folderMenuItems,
  bulkActions,
  ACTIONS
} from "./ContextMenuItems";
import DocumentManager from "./DocumentManager";
import DocumentBreadcrumbs from "@/components/documents/documents_table/DocumentsTableBreadcrumb.vue";
import DocumentSidebar from "@/components/documents/Sidebar.vue";
import DocumentsTableNameCellRenderer from "@/components/documents/documents_table/DocumentsTableNameCellRenderer.vue";
import DocumentsTableFileSizeCellRendererVue from "@/components/documents/documents_table/DocumentsTableFileSizeCellRenderer.vue";
import DocumentsUploadModal from "@/components/documents/DocumentsUploadModal.vue";
import DocumentsMoveModal from "@/components/documents/DocumentsMoveModal.vue";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import ShareDialog from "./ShareDialog";
import PreviewDialog from "./PreviewDialog";
import { isFolder } from "@/helpers/DocumentHelpers";
import { mapState, mapMutations } from "vuex";
import TabWatcherMixin from "@/mixins/TabWatcherMixin";
import AttachTask from "./dialogs/AttachTask.vue";
import gql from "graphql-tag";

const clipboardy = require("clipboardy");

export default {
  extends: Components.BaseTable,
  mixins: [TabWatcherMixin],
  apollo: {
    users: {
      query: UsersQuery,
      update(data) {
        return data.companyUsersSummary.edges.map(({ node }) => ({
          label: node.name,
          value: node.id
        }));
      },
      fetchPolicy: "no-cache"
    },
    documents: {
      query() {
        return this.query;
      },
      variables() {
        let vars = {};

        if (this.project) {
          vars.projectId = this.project.id;
        }

        if (this.searchTerm) {
          vars.search = this.searchTerm;
        } else if (this.currentFolder) {
          vars.folder = this.currentFolder.path;
        }

        vars.where = this.filters;

        return vars;
      },
      update(data) {
        let parent = data.project ? data.project : data;
        const { documentsConnection } = parent;

        if (documentsConnection) {
          return documentsConnection.edges.map(({ node }) => node);
        }

        return [];
      },
      fetchPolicy: "no-cache",
      result() {
        this.setRowData(this.documents);
        this.stopLoading();
      },
      skip() {
        return !this.readyToLoad;
      },
      debounce: 200
    },
    tasks: {
      query: ProjectTasks,
      variables() {
        let vars = {
          projectId: this.project.id
        };

        return vars;
      },
      skip() {
        return !this.project;
      },
      update({ project }) {
        return project.tasks.edges.map(edge => edge.node);
      },
      fetchPolicy: "no-cache"
    },
    permissions: {
      query() {
        const args = this.project ? "($id: GlobalID)" : "";
        const queryName = this.project
          ? "projectPermissions(id: $id)"
          : "companyPermissions";
        const query = `
          query permissions${args} {
            ${queryName} {
              canShareDocument
              canEditDocument
              canDeleteDocuments
              canCreateDocument
            }
          }
        `;

        return gql(query);
      },
      update(data) {
        const permissions =
          data[this.project ? "projectPermissions" : "companyPermissions"];
        return {
          canShare: permissions.canShareDocument,
          canUpdate: permissions.canEditDocument,
          canDestroy: permissions.canDeleteDocuments,
          canCreate: permissions.canCreateDocument
        };
      },
      variables() {
        if (this.project) {
          return {
            id: this.project.id
          };
        }

        return {};
      }
    }
  },
  props: {
    project: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    }
  },
  data() {
    const vm = this;

    return {
      // Internal configuration
      filters: {},
      query: null,
      docManager: new DocumentManager(this.project),
      path: "/",
      readyToLoad: false,
      dialogs: {
        moveDocument: false,
        copyDocument: false
      },
      currentDocument: null,
      selectedDocuments: [],
      readOnly: false,

      permissions: {
        canShare: false,
        canUpdate: false,
        canDelete: false
      },
      // Table configuration
      columns: [
        {
          colId: "checkbox",
          maxWidth: 50,
          checkboxSelection: true,
          suppressMenu: true,
          headerCheckboxSelection: true,
          resizable: false,
          sortable: false,
          canToggle: false
        },
        {
          // checkboxSelection: true,
          // headerCheckboxSelection: true,
          headerName: "Name",
          field: "name",
          cellRenderer: "name",
          cellRendererParams: {
            onClick(doc) {
              if (!isFolder(doc)) {
                vm.currentDocument = doc;
              }
            },
            shouldShowPath() {
              return !!vm.searchTerm;
            },
            onFolderClick(row) {
              const doc = row.data;
              vm.searchTerm = "";
              vm.popToFolder(doc.parentPath);
              vm.updateFoldersNavigationHistory(doc.parentPath);
            }
          },
          editable: true,
          flex: 1,
          cellEditorParams: {
            required: true
          },
          comparator(a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          },
          canToggle: false
        },
        {
          headerName: "Size",
          field: "size",
          // field: "currentVersion.upload.fileSize",
          cellRenderer: "fileSize",
          valueGetter(params) {
            if (isFolder(params.data)) {
              return params.data.size;
            }

            return params.data.currentVersion.upload.fileSize;
          },
          width: 125
        },
        {
          headerName: "Last upload",
          field: "currentVersion.upload.uploadedAt",
          cellRenderer: "date",
          cellRendererParams: { time: true },
          width: 180
        },
        {
          headerName: "Uploader",
          field: "currentVersion.uploader",
          cellRenderer: "teamMember"
        },
        Helpers.table.actionsCell()
      ],
      components: {
        vue: {
          name: DocumentsTableNameCellRenderer,
          fileSize: DocumentsTableFileSizeCellRendererVue
        }
      },
      documents: [],
      selection: "multiple",
      config: {
        resourceType: "document",
        filters: {
          date_uploaded: {
            type: "date",
            title: "Date uploaded",
            description: "Filter documents by date uploaded",
            field: "uploadedAt"
          },
          date_created: {
            type: "date",
            title: "Date created",
            description: "Filter documents by date created",
            field: "createdAt"
          },
          last_modified: {
            type: "date",
            title: "Last modified",
            description: "Filter documents by last modified date",
            field: "updatedAt"
          },
          type: {
            type: "option",
            title: "Type",
            description: "Filter documents by type",
            field: "fileCategory",
            options: () => {
              return [
                {
                  label: "Images",
                  value: "IMAGE"
                },
                {
                  label: "Video",
                  value: "VIDEO"
                },
                {
                  label: "Text",
                  value: "TEXT"
                },
                {
                  label: "PDF",
                  value: "PDF"
                },
                {
                  label: "Other",
                  value: "OTHER"
                }
              ];
            }
          },
          uploaded_by: {
            type: "option",
            title: "Uploaded by",
            description: "Filter documents by user uploaded",
            field: "uploadedBy",
            options: () => vm.users
          }
        }
      },
      listeners: {
        rowDragEnd: this.onRowDragEnd,
        cellDoubleClicked: this.openDocument,
        cellValueChanged(evt) {
          if (evt.colDef.field === "name") {
            // Renamed
            vm.onDocumentRenamed(evt);
          }
        }
      }
    };
  },
  computed: {
    tableBinds() {
      return {
        noRowsOverlayComponentParams: {
          title: "This folder is empty",
          message: "Drag and drop files into this window to upload"
        }
      };
    },
    ...mapState("documentManagement", ["currentFolder", "filePond"])
  },
  created() {
    this.query = this.project ? ProjectDocuments : CompanyDocuments;
  },
  mounted() {
    this.startLoading();
    this.$store.dispatch(
      "documentManagement/registerUploadCallback",
      this.onDocumentUploaded
    );
    if (this.project) {
      this.$store.dispatch("documentManagement/setProject", this.project);
    }
    this.startTrackingFolderNavigation();
  },
  beforeDestroy() {
    this.$store.dispatch(
      "documentManagement/removeUploadCallback",
      this.onDocumentUploaded
    );
    this.stopTrackingFolderNavigation();
  },
  methods: {
    performFiltering(filters) {
      this.startLoading();
      this.filters = filters;
      this.$apollo.queries.documents.refetch();
    },
    ...mapMutations("documentManagement", ["setCurrentFolder"]),
    startTrackingFolderNavigation() {
      if (window.onpopstate) return;
      const cdToFolder = () => {
        const hash = decodeURI(location.hash);
        const isDocumentsTab =
          hash.indexOf("#/documents") !== -1 ||
          hash.indexOf("#documents") !== -1;
        if (!this.project || isDocumentsTab) {
          // We're on the document listing page again, so we need to process the URL
          const path = this.folderPathFromHash(hash);
          const blankPath = path === "/" || path === "";
          if (blankPath && this.path !== "/") {
            // Most likely clicked the `documents` tab, so we'll replace the URL
            // with the actual path and do nothing more - we already have this data
            window.history.replaceState(
              { folderPath: this.path },
              "",
              this.urlHashForFolder(this.path)
            );
          } else if ((path || "/") !== this.path) {
            // Our path and the URL path are not the same, so we need to actually
            // change the data we have. Otherwise it is the same
            this.popToFolder(path);
          } else if (!this.readyToLoad) {
            this.popToFolder("/");
          }
        }
      };

      // cdToFolder();
      window.onpopstate = cdToFolder;
    },
    stopTrackingFolderNavigation() {
      if (window.onpopstate) {
        window.onpopstate = null;
      }
    },
    updateFoldersNavigationHistory(path, name = "", push = true) {
      const method = push ? "pushState" : "replaceState";
      window.history[method](
        { folderPath: path },
        name,
        this.urlHashForFolder(path)
      );
    },
    urlHashForFolder(path) {
      if (this.project) {
        return "#/documents".concat(path);
      } else {
        return "#".concat(path);
      }
    },
    folderPathFromHash(hash) {
      if (this.project) {
        return hash.replace("#/documents", "").replace("#documents", "");
      } else {
        return hash.substr(1);
      }
    },
    addFolder() {
      if (!this.readOnly) {
        this.readOnly = true;
        this.docManager
          .createFolder(this.currentFolder)
          .then(folder => {
            this.addRow(folder, true);
            this.readOnly = false;
          })
          .catch(e => {
            this.$flash.error(gqlErrorMessage(e));
            this.readOnly = false;
          });
      }
    },
    addRow(row, makeEditable = false) {
      const res = this.gridApi().applyTransaction({
        add: [row]
      });

      if (makeEditable) {
        const row = res.add[0];
        this.gridApi().startEditingCell({
          rowIndex: row.rowIndex,
          colKey: "name"
        });
      }
    },
    contextMenuItemClicked(item, row) {
      if (isFolder(row.data)) {
        this.performFolderAction(item, row);
      } else {
        this.performDocumentAction(item, row);
      }
    },
    attachTasks(row) {
      let dialog = this.$dialog.show(AttachTask, {
        props: {
          tasks: this.tasks
        }
      });

      dialog.onOk(({ api, data }) => {
        let params = {
          documentIds: [
            {
              id: row.data.id,
              resourceType: row.data.__typename == "Folder" ? "folder" : "file"
            }
          ],
          ...data
        };
        api.hide();
        this.docManager.attachDocument(params).catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
      });
    },
    share(rows) {
      this.docManager.generateDocumentShareUrl(rows).then(share => {
        var shareUrl = share.data.generateDocumentShareUrl.share.url;
        var expires = share.data.generateDocumentShareUrl.share.expires;
        clipboardy.write(shareUrl);
        this.$dialog.show(ShareDialog, {
          props: { share: shareUrl, expires: expires }
        });
      });
    },
    delete(rows, opts) {
      if (rows.constructor !== Array) {
        rows = [rows];
      }

      const docsAndFolders = rows.map(row => row.data);
      this.$dialog
        .confirm({
          title: opts.title,
          message: opts.message,
          danger: true
        })
        .onOk(({ api }) => {
          api.hide();
          this.docManager
            .delete(docsAndFolders)
            .then(() => {
              this.$flash.success(opts.success);
              this.removeRows(rows);
            })
            .catch(e => {
              this.$flash.error(gqlErrorMessage(e));
            });
        });
    },
    getActionBarContent(h) {
      return [
        h(DocumentBreadcrumbs, {
          props: { path: this.path },
          on: { click: [this.popToFolder, this.updateFoldersNavigationHistory] }
        })
      ];
    },
    getButtons(h) {
      let buttons = [];
      if (this.permissions?.canCreate) {
        buttons.push([
          h(
            Components.AppButton,
            { props: { icon: "addFolder" }, on: { click: this.addFolder } },
            "Add folder"
          ),
          h(
            Components.AppButton,
            {
              props: { icon: "upload", primary: true },
              on: { click: this.openFileUploadModal },
              staticClass: "ml-4"
            },
            "Upload document"
          )
        ]);
      }

      return buttons;
    },
    getBulkActions() {
      return bulkActions;
    },
    getContextMenuItems(node) {
      if (isFolder(node.data)) {
        return folderMenuItems;
      }

      return documentMenuItems(!!this.project, this.permissions);
    },
    getDialogs(h) {
      const vm = this;
      const dialogs = [];

      if (this.dialogs.copyDocument || this.dialogs.moveDocument) {
        const mode = this.dialogs.copyDocument ? "copy" : "move";

        dialogs.push(
          h(DocumentsMoveModal, {
            props: {
              value: vm.dialogs[`${mode}Document`],
              project: vm.project,
              documents: vm.selectedDocuments,
              mode
            },
            on: {
              input(isOpen) {
                vm.dialogs[`${mode}Document`] = isOpen;
              },
              moved(docsMoved) {
                if (mode === "move") {
                  const rowsToRemove = docsMoved.map(doc => {
                    return vm.gridApi().getRowNode(doc.id);
                  });

                  vm.gridApi().applyTransaction({
                    remove: rowsToRemove
                  });
                  vm.currentDocument = null;
                }
              }
            }
          })
        );
      }

      return dialogs;
    },
    getOtherComponents(h) {
      let components = [this.getDialogs(h)];
      if (this.currentDocument) {
        components.push(this.getSidebar(h));
      }

      return components;
    },
    getSidebar(h) {
      const vm = this;

      return h(
        Components.AppSidebarContainer,
        { props: { value: !!this.currentDocument } },
        [
          h(DocumentSidebar, {
            props: {
              document: this.currentDocument,
              listingView: true,
              project: this.project
            },
            on: {
              copy() {
                vm.selectedDocuments = [this.currentDocument];
                vm.openFileMoveModal("copy");
                vm.currentDocument = null;
              },
              move() {
                vm.selectedDocuments = [this.currentDocument];
                vm.openFileMoveModal("move");
                vm.currentDocument = null;
              },
              input(val) {
                if (!val) {
                  vm.currentDocument = null;
                }
              },
              renamed(newName) {
                vm.currentDocument.name = newName;
                vm.updateOrAddRow(vm.currentDocument);
              },
              deleted(doc) {
                vm.currentDocument = null;
                vm.gridApi().applyTransaction({
                  remove: [doc]
                });
              },
              updated(version) {
                const versions = vm.currentDocument.versions.filter(
                  v => v.id !== version.id
                );
                vm.currentDocument.versions = versions;
                if (version.id === vm.currentDocument.currentVersion.id) {
                  vm.currentDocument.currentVersion = version;
                }
                vm.updateOrAddRow(vm.currentDocument);
              },
              attachTasks() {
                vm.attachTasks({ data: vm.currentDocument });
              }
            }
          })
        ]
      );
    },
    fileDropped(row, evt) {
      const files = evt.dataTransfer.files;
      this.$store.dispatch("documentManagement/filesDropped", { files });
    },
    onDocumentRenamed(event) {
      const doc = event.data;
      this.docManager
        .rename(doc, event.newValue)
        .then(data => {
          if (
            doc.__typename === "Document" &&
            doc.currentVersion &&
            doc.currentVersion.url
          ) {
            doc.currentVersion.url =
              data.data.renameDocument.document.currentVersion.url;
          }
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
          doc.name = event.oldValue;
          this.gridApi().applyTransaction({ update: [doc] });
        });
    },
    onDocumentUploaded(documentID) {
      this.$apollo
        .query({
          query: DocumentQuery,
          variables: {
            id: documentID
          }
        })
        .then(response => {
          const doc = response.data.document;

          this.updateOrAddRow(doc);

          if (this.currentDocument && this.currentDocument.id === doc.id) {
            this.currentDocument = doc;
          }
        });
    },
    onRowDragEnd(event) {
      const overNode = event.overNode;
      if (!overNode || !isFolder(overNode)) {
        // Don't allow dragging unless it's into a folder
        return;
      }

      // OK we're dragging into a folder. Lets move the doc/folder
    },
    openDocument(row) {
      let doc = row.data;
      if (isFolder(doc)) {
        this.startLoading();
        // Folder double clicked, so we're gonna drill down
        this.searchTerm = "";
        this.setCurrentFolder(doc);
        this.path = doc.path;

        this.updateFoldersNavigationHistory(doc.path, doc.name);
      }
    },
    openFileUploadModal() {
      if (!this.readOnly) {
        this.$store.commit("documentManagement/setMode", "upload");
        this.$dialog.show(DocumentsUploadModal);
      }
    },
    openFileMoveModal(mode) {
      this.$store.commit("documentManagement/setMode", mode);
      // this.selectedDocuments = this.gridApi().getSelectedRows();
      // this.dialogs[`${mode}Document`] = true;

      const vm = this;
      this.$dialog.show(DocumentsMoveModal, {
        props: {
          value: this.dialogs[`${mode}Document`],
          project: this.project,
          documents: this.selectedDocuments,
          mode
        },
        on: {
          moved(docsMoved) {
            if (mode === "move") {
              const rowsToRemove = docsMoved.map(doc => {
                return vm.gridApi().getRowNode(doc.id);
              });

              vm.gridApi().applyTransaction({
                remove: rowsToRemove
              });
              vm.currentDocument = null;
            }
          }
        }
      });
    },
    performBulkAction(item) {
      switch (item.action) {
        case ACTIONS.DOWNLOAD:
          this.docManager.downloadDocuments(
            this.gridApi().getSelectedRows(),
            this.path
          );
          break;

        case ACTIONS.COPY:
          this.selectedDocuments = this.gridApi().getSelectedRows();
          this.openFileMoveModal("copy");
          break;

        case ACTIONS.MOVE:
          this.selectedDocuments = this.gridApi().getSelectedRows();
          this.openFileMoveModal("move");
          break;

        case ACTIONS.SHARE_URL:
          this.share(this.gridApi().getSelectedRows());
          break;

        case ACTIONS.DELETE:
          this.delete(this.gridApi().getSelectedNodes(), {
            title: "Are you sure you want to delete these items?",
            message: "Deleting a document or folder cannot be undone",
            success: "Items successfully deleted"
          });
          break;
      }
    },
    performDocumentAction(item, row) {
      const doc = row.data;

      switch (item.action) {
        case ACTIONS.DOWNLOAD:
          this.docManager.downloadDocuments(doc);
          break;

        case ACTIONS.COPY:
          this.selectedDocuments = [row];
          this.openFileMoveModal("copy");
          break;

        case ACTIONS.MOVE:
          this.selectedDocuments = [row];
          this.openFileMoveModal("move");
          break;

        case ACTIONS.ATTACH_TASKS:
          this.attachTasks(row);
          break;

        case ACTIONS.SHARE_URL:
          this.share(doc);
          break;

        case ACTIONS.DELETE:
          this.delete(row, {
            title: "Are you sure you want to delete this document?",
            message: "Deleting a document cannot be undone",
            success: "Document successfully deleted"
          });
          break;

        case ACTIONS.PREVIEW:
          this.$dialog.show(PreviewDialog, {
            props: {
              document: doc
            }
          });
          break;

        case ACTIONS.UPLOAD:
          this.$store.dispatch("documentManagement/browseForNewVersion", doc);
          break;

        case ACTIONS.RENAME:
          this.gridApi().startEditingCell({
            rowIndex: row.rowIndex,
            colKey: "name"
          });
          break;
      }
    },
    performFolderAction(item, row) {
      const folder = row.data;

      switch (item.action) {
        case ACTIONS.DELETE:
          this.delete(row, {
            title: "Are you sure you want to delete this folder?",
            message: "Deleting a folder cannot be undone",
            success: "Folder successfully deleted"
          });
          break;

        case ACTIONS.COPY:
          this.selectedDocuments = [row];
          this.openFileMoveModal("copy");
          break;

        case ACTIONS.MOVE:
          this.selectedDocuments = [row];
          this.openFileMoveModal("move");
          break;

        case ACTIONS.ATTACH_TASKS:
          this.attachTasks(row);
          break;

        case ACTIONS.SHARE_URL:
          this.share(folder);
          break;

        case ACTIONS.RENAME:
          this.gridApi().startEditingCell({
            rowIndex: row.rowIndex,
            colKey: "name"
          });
          break;

        case ACTIONS.DOWNLOAD:
          this.docManager.downloadDocuments(folder, this.path);
          break;
      }
    },
    // Override the performSearch function so it doesn't trigger
    // the tables own searching
    performSearch() {
      this.startLoading();
    },
    /**
     * Called when a folder in the breadcrumb bar
     * was clicked, and navigates up the hierarchy
     * to a previous folder
     */
    popToFolder(path) {
      if (!path || path === "/") {
        this.path = "/";
        if (this.currentFolder != null) {
          this.startLoading();
          this.setCurrentFolder(null);
        }

        this.readyToLoad = true;
      } else {
        this.$apollo
          .query({
            query: FolderQuery,
            variables: {
              path: path,
              project: this.project ? { id: this.project.id } : null
            }
          })
          .then(response => {
            this.startLoading();
            this.path = path;
            this.setCurrentFolder({ ...response.data.folder, path });
          })
          .catch(error => {
            if (this.path !== "/") this.path = "/";
            if (this.currentFolder) {
              this.startLoading();
              this.setCurrentFolder(null);
            }
            this.updateFoldersNavigationHistory("/", "", false);
            this.$flash.error(gqlErrorMessage(error));
          })
          .then(() => {
            this.readyToLoad = true;
          });
      }
    },
    removeRows(rows) {
      this.gridApi().applyTransaction({
        remove: rows
      });
    },
    tabChanged() {
      // Make sure our sidebar gets hidden if we move tabs
      this.currentDocument = null;
    },
    updateOrAddRow(doc) {
      const rowNode = this.gridApi().getRowNode(doc.id);
      if (rowNode) {
        // Update our data
        rowNode.setData(doc);
        this.gridApi().redrawRows([rowNode]);
      } else {
        // New record
        this.gridApi().applyTransaction({
          add: [doc]
        });
      }
    }
  }
};
</script>
