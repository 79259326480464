import { apolloClient } from "@/vue-apollo";
import gql from "graphql-tag";

export function errorMessage(err) {
  if (!err) {
    return "An unexpected error occurred";
  }

  return err.message.replace("GraphQL error: ", "");
}

/**
 * A helper function for running apollo mutations
 * outside of a component.
 **/
export function runMutation(
  mutation,
  variables = {},
  additionalInput = {},
  mutationOpts = {}
) {
  const opts = {
    mutation,
    variables: {
      input: {
        ...variables
      },
      ...additionalInput
    },
    ...mutationOpts
  };

  return apolloClient.mutate(opts);
}

/**
 * A helper function for running apollo queries
 * outside of a component
 **/
export function runQuery(query, variables) {
  return apolloClient.query({
    query,
    variables
  });
}

/**
 * Dynamically generates a query to fetch
 * enabled features
 */
export function featureQuery(features) {
  const fields = features.join("\n");
  return gql`query company {
      company {
        features {
          ${fields}
        }
      }
    }
  `;
}

/**
 * CACHE RELATED FUNCTIONS
 **/

/**
 * Invokes the provided function with the
 * apollo cache. This is useful for updating
 * the cache outside of a mutation such as when
 * using manager classes for modifying data
 **/
// export function cacheUpdate(fn) {
//   const cache = apolloClient.cache;
//   fn(cache);
// }

/**
 *  Fetches the apollo cache, invokes a given function to
 *  mutate the data, and then write the data back to the
 *  cache
 **/
export function cacheUpdate(updateFn, query, variables = undefined) {
  const cache = apolloClient.cache;
  const args = {
    query,
    variables
  };

  try {
    const data = cache.readQuery(args);
    const updatedData = updateFn(data);

    cache.writeQuery({ query, variables, data: updatedData });
  } catch (err) {
    // Cache miss. Not really an error though, we just won't update anything
    // console.error("Cache miss - will not attempt to update");
  }
}

/**
 * Append an item to the apollo cache
 **/
export function appendToCache(cachedData, key, items) {
  const cachedItems = cachedData[key];
  const isConnection = "edges" in cachedItems;
  const searchData = isConnection ? cachedItems.edges : cachedItems;
  const searchItemFn = isConnection ? edge => edge.node.id : item => item.id;
  const wrapItemToPush = isConnection
    ? item => ({
        node: item
      })
    : item => item;

  items.forEach(item => {
    if (searchData.findIndex(item => searchItemFn(item) === -1)) {
      // The item doesn't already exist, so lets add it
      searchData.push(wrapItemToPush(item));
    }
  });

  if (isConnection) {
    cachedData[key].edges = searchData;
  } else {
    cachedData[key] = searchData;
  }

  return cachedData;
}

export function updateCacheItem(
  cachedData,
  cacheKey,
  item,
  property,
  newValue
) {
  const itemToUpdateIdx = cachedData[cacheKey].findIndex(i => i.id === item.id);

  if (itemToUpdateIdx !== -1) {
    cachedData[cacheKey][itemToUpdateIdx][property] = newValue;
  }

  return cachedData;
}

export function deleteCacheItems(cachedData, key, items) {
  const cachedItems = cachedData[key];
  const isConnection = "edges" in cachedItems;

  let mapFn;
  if (isConnection) {
    mapFn = item => cachedItems.edges.findIndex(e => e.node.id === item.id);
  } else {
    mapFn = item => cachedItems.findIndex(i => i.id === item.id);
  }

  const itemsToRemoveIndices = items.map(mapFn).filter(i => i !== -1);

  itemsToRemoveIndices.forEach(idx => {
    if (isConnection) {
      cachedItems.edges.splice(idx, 1);
    } else {
      cachedItems.splice(idx, 1);
    }
  });

  cachedData[key] = cachedItems;
  return cachedData;
}
