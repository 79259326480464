import Node from "./ProjectTasksTableTaskTreeNode";

class TaskTree {
  constructor(vm) {
    this.vm = vm;
    this.nodes = [];
  }

  insertNode(task, parent, expanding = false) {
    const node = this._buildNode(task);

    if (!parent) {
      this.nodes.push(node);
    } else {
      const parentNode = this.findNode(parent);

      // If we don't have a parent node, then it's not been
      // loaded yet so there's nothing we can do
      if (parentNode) {
        // If the parent node has actually had it tasks loaded then we'll add the
        // new child. If not, we won't bother since it will be loaded when it nexts
        // gets expanded. We will however increment the child count so that we can
        // correctly show the expansion button
        if (parentNode.subtasksLoaded || expanding) {
          node.parent = parentNode;
          parentNode.children.push(node);
        }

        if (!expanding) {
          // if not expanding, then we'll increment the child count. If it's expanding
          // then the childCount is already set, and we're not actually adding newly
          // created nodes
          parentNode.childCount++;
          parentNode.data.childCount++;
        }

        this.vm.refreshExpansionState(parentNode);
      } else {
        node.parent = null;
      }
    }

    return node;
  }

  insertNodes(nodes, parent) {
    nodes.forEach(node => this.insertNode(node, parent, true));
  }

  findNode(param) {
    if (!param) {
      return;
    } else if (typeof param === "string") {
      return this._findNode(param);
    } else if (param.constructor === Node) {
      return param;
    } else {
      return this._findNode(param.id);
    }
  }

  /**
   * Retrieves a list of all subtasks recursively
   * for the given task. Note that only expanded
   * tasks will be traversed.
   */
  getAllChildren(task, position = 0) {
    const node = this.findNode(task);

    let children = (node && node.children) || [];
    children.forEach(c => {
      c.position = position;
      position++;

      if (c.isExpanded) {
        children = children.concat(this.getAllChildren(c));
      }
    });

    return children;
  }

  /**
   *  Retrieves a list of all visible tasks in the
   *  tree, in the order in which they are stored.
   *  This will replace the existing `position` with
   *  a new value based on the underlying data order
   **/
  getList() {
    let allNodes = [];
    let position = 0;
    this.nodes.forEach(node => {
      node.position = position;
      position++;

      allNodes.push(node);

      if (node.isExpanded) {
        allNodes = allNodes.concat(this.getAllChildren(node, position));
        position += allNodes.length;
      }
    });

    return allNodes;
  }

  getSortedList(colDef, direction, comparator) {
    return this._sortChildren(this.nodes, 0, colDef, direction, comparator);
  }

  /**
   *  Moves the given node to a new parent in the
   *  tree. If no new parent is specified, it will
   *  be moved into the root of the tree
   **/
  moveNode(task, newParent = null) {
    const treeNode = this.findNode(task);
    const newParentNode = newParent ? this.findNode(newParent) : null;

    if (!treeNode) return;

    this.removeNodes([treeNode]);
    this.insertNode(treeNode, newParentNode);
  }

  /**
   *  Finds and removes the specified nodes from
   *  the tree
   **/
  removeNodes(nodes) {
    // Find all parents
    nodes.forEach(node => {
      const treeNode = this.findNode(node);
      treeNode && this._removeNode(treeNode);
    });
  }

  /**
   * Completely wipe out the data from the tree
   **/
  clear() {
    this.nodes = [];
  }

  _buildNode(task) {
    if (task.constructor === Node) {
      return task;
    }

    return new Node(task);
  }

  _findChild(node, id) {
    if (!node.isExpanded) {
      return null;
    }

    let i = 0;
    for (i; i < node.children.length; i++) {
      let child = node.children[i];
      if (child.id === id) {
        return child;
      }

      child = this._findChild(node.children[i], id);

      if (child) {
        return child;
      }
    }

    return null;
  }

  _findNode(id) {
    let i = 0;
    for (i; i < this.nodes.length; i++) {
      let rootNode = this.nodes[i];

      if (rootNode.id === id) {
        return rootNode;
      }

      let node = this._findChild(rootNode, id);
      if (node) {
        return node;
      }
    }
  }

  _removeNode(node) {
    const parent = node.parent;

    if (parent) {
      const index = parent.children.findIndex(child => child.id === node.id);
      parent.childCount--;
      parent.data.childCount--;
      parent.children.splice(index, 1);

      if (parent.childCount === 0) {
        this.vm.refreshExpansionState(parent);
        parent.expanded = false;
        parent.subtasksLoaded = false;
      }
    } else {
      // No parent, so this is a root node
      const index = this.nodes.findIndex(n => n.id === node.id);
      this.nodes.splice(index, 1);
    }

    node.parent = null;
  }

  _sortChildren(nodes = [], position, colDef, direction, comparator) {
    // Sort this level by the desired column
    const getter = colDef.valueGetter || (node => node.data[colDef.field]);
    let sorted = nodes.sort((nodeA, nodeB) => {
      const compared = comparator(
        getter(nodeA),
        getter(nodeB),
        nodeA.data,
        nodeB.data
      );
      return direction === "desc" ? compared * -1 : compared;
    });

    // Now the children recursively
    let allSorted = [];

    sorted.forEach(node => {
      node.data.position = position;
      position++;

      allSorted.push(node);

      if (node.childCount > 0 && node.isExpanded) {
        const children = this._sortChildren(
          node.children,
          position,
          colDef,
          direction,
          comparator
        );
        allSorted = allSorted.concat(children);
        position += children.length;
      }
    });

    return allSorted;
  }
}

export default TaskTree;
