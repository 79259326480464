<template lang="pug">
  app-dialog.attach(@cancel="$emit('cancel')")
    template(#header)
      app-header
        | Attach documents
        template(#subheader) Select one or more documents to attach

    .mb-3.mt-4.text-lg Search for a document

    multiselect.mb-4(
      v-model="document",
      label="name",
      track-by="name",
      :options="grouped"
      :show-labels="false"
      group-values="entries",
      group-label="label",
      @input="(doc) => select(doc)"
    )
      template(slot="option", slot-scope="props")
        .flex.ml-2.mr-2(v-if="props.option.id")
          app-checkbox(:value="isSelected(props.option.id)", label="")
          .flex
            app-file-icon.mr-4(:extension="props.option['__typename'] == 'Folder' ? 'folder' : props.option.currentVersion.upload.fileExtension")
            .info
              .mb-1 {{props.option.name}}
              .path {{props.option.parentPath}}

      template(slot="noResult")
        | Nothing found

    app-panel.h-64.overflow-auto.plan-card-content(secondary)
      .h-full.flex.flex-col.justify-center.text-center(v-if="selected_documents.length == 0") No documents selected
      .div.mb-4(v-for="(document, index) in selected_documents")
        .flex.justify-between.items-center
          .flex.items-center
            app-file-icon.mr-4(:extension="document['__typename'] == 'Folder' ? 'folder' : document.currentVersion.upload.fileExtension")
            .info
              .mb-1 {{document.name}}
              .path {{document.parentPath}}
          .remove
            app-icon.cursor-pointer(colour="red", icon="delete", @click="selected_documents.splice(index, 1)")

    .flex-none.pt-6
      .flex.flex-row.justify-end
        app-button.mr-4(secondary @click="$emit('cancel')") Cancel
        app-button(primary @click="ok") Attach


</template>
<script>
import ProjectDocuments from "@/graphql/queries/core/projects/ProjectDocuments.gql";

export default {
  apollo: {
    documents: {
      query: ProjectDocuments,
      variables() {
        let vars = {
          projectId: this.projectId,
          root: false
        };

        return vars;
      },
      update(data) {
        const { documentsConnection } = data.project;

        if (documentsConnection) {
          return documentsConnection.edges.map(({ node }) => node);
        }

        return [];
      },
      fetchPolicy: "no-cache",
      debounce: 200
    }
  },
  props: {
    projectId: {
      required: true
    }
  },
  data() {
    return { document: "", selected_documents: [] };
  },
  computed: {
    grouped() {
      if (!this.documents) return [];
      return [
        {
          label: "Folders",
          entries: this.documents.filter(sd => sd.__typename == "Folder")
        },
        {
          label: "Documents",
          entries: this.documents.filter(sd => sd.__typename != "Folder")
        }
      ];
    }
  },
  methods: {
    isSelected(id) {
      return this.selected_documents.filter(d => d.id == id).length > 0;
    },
    select(doc) {
      this.document = null;
      let idx = this.selected_documents.map(sd => sd.id).indexOf(doc.id);
      if (idx > -1) {
        this.selected_documents.splice(idx, 1);
        return;
      }
      this.selected_documents.push(
        this.documents.filter(d => d.id == doc.id)[0]
      );
    },
    ok() {
      this.$emit("ok", {
        documentIds: this.selected_documents.map(doc => {
          return {
            id: doc.id,
            resourceType: doc.__typename == "Folder" ? "folder" : "file"
          };
        })
      });
    }
  }
};
</script>
<style lang="postcss" scoped>
/deep/ .multiselect__option--group {
  @apply m-4;
  @apply border;
  @apply border-grey-30;
  @apply rounded-md;
  @apply py-2;
  @apply px-2;
  @apply bg-grey-10 !important;
  min-height: initial;
  color: var(--grey-80) !important;
}

.dialog.attach {
  min-height: 200px;
  width: 750px;
}
</style>
