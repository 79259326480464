<template lang="pug">
  app-panel.p-4(:loading="!internalTask" compact)
    template(#header)
      .flex.flex-row.mb-4
        .flex-1
          app-header(size="h3")
            | Approvers

            template(slot="subheader") {{ subheader }}

    template(#default)
      template(v-if="hasApprovers")
        .mb-6(class="w-1/2")
          app-progress(:max="totalApprovers", :progress="totalApproved")

        .approvers
          .py-2.flex.flex-col.border-b.border-grey-30.flex-wrap(v-for="(item, idx) in internalTask.approvers", :key="item.id",
                                                          class="last:border-0")
            template(v-if="item.isApproved")
              .flex.flex-row.items-center
                .flex-none
                  app-checkbox(:value="true", label="")

                .flex-auto(class="w-1/3")
                  app-team-member(:user="item.user")

                .flex-none
                  span.grey-70 {{ formattedDate(item.approvedAt) }}

              .ml-6.p-4
                span.italic.text-grey-70(v-if="item.note") {{ item.note }}
                span.italic.text-grey-70(v-else) No notes given

            template(v-else)
              .flex.flex-row.items-center
                .flex-none
                  app-icon.-ml-1.mr-3(size="medium", icon="timesheetEntry")

                .flex-auto.max-w-sm
                  app-team-member(:user="item.user")

                .flex-none.self-center
                  span.italic.text-grey-70 Awaiting approval

              template(v-if="isCurrentUser(item)")
                .flex-grow.flex.flex-col.my-4(style="min-width:40%")
                  textarea.flex-grow.border.border-grey-50.resize-none.p-4.outline-none(rows="2", v-model="note" class="focus:border-tribal-aqua focus:border")
                  app-button.flex-initial.self-start.mt-4(primary slim @click="approve(item)") Approve


      template(v-else)
        p.mb-8.mt-8 There are currently no users who are are required to approve this task.


      .flex-initial.mt-8(v-if="internalTask && internalTask.canEdit")
        user-picker(:value="selectedApprovers", @input="approversChanged")
          template(#trigger)
            app-button(primary) Edit approvers

          template(#title) Add or remove approvers
</template>

<script>
import UserPicker from "@/components/collections/AppUserPicker.vue";
import { format, parseISO, formatISO } from "date-fns";
import ApproveTask from "@/graphql/mutations/tasks/ApproveTask.gql";
import SetTaskApprovers from "@/graphql/mutations/tasks/SetTaskApprovers.gql";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import clone from "ramda/src/clone";

export default {
  components: {
    UserPicker
  },
  props: {
    task: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      internalTask: clone(this.task),
      note: ""
    };
  },
  computed: {
    selectedApprovers() {
      return this.internalTask.approvers.map(t => t.user);
    },
    hasApprovers() {
      if (!this.internalTask) {
        return false;
      }

      return this.totalApprovers > 0;
    },
    totalApprovers() {
      return this.internalTask.approvers.length;
    },
    totalApproved() {
      return this.internalTask.approvers.filter(item => item.isApproved).length;
    },
    subheader() {
      if (this.hasApprovers) {
        return `${this.totalApproved} out of ${this.pluralised(
          this.totalApprovers
        )} approved this task`;
      } else {
        return "This task has no approvers";
      }
    }
  },
  methods: {
    approve(item) {
      const note = this.note;
      const taskId = this.internalTask.id;

      this.$apollo
        .mutate({
          mutation: ApproveTask,
          variables: {
            input: {
              id: item.id,
              note: this.note,
              taskId: taskId
            }
          },
          optimisticResponse: {
            __typename: "Mutation",
            approveTask: {
              __typename: "ApproveTaskMutationPayload",
              approver: {
                __typename: "ResourceApprover",
                id: item.id,
                note: note,
                isApproved: true,
                approvedAt: formatISO(new Date()),
                user: item.user
              }
            }
          }
        })
        .then(({ data: { approveTask } }) => {
          item.isApproved = approveTask.approver.isApproved;
          item.approvedAt = approveTask.approver.approvedAt;
          item.note = approveTask.approver.note;
          this.$emit("input", this.internalTask);
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    },
    approversChanged(newApprovers) {
      const taskId = this.internalTask.id;

      this.$apollo
        .mutate({
          mutation: SetTaskApprovers,
          variables: {
            input: {
              id: taskId,
              approvers: newApprovers.map(a => ({
                id: a.id
              }))
            }
          }
        })
        .then(({ data: { setTaskApprovers } }) => {
          this.internalTask.approvers = setTaskApprovers.task.approvers;
          this.$emit("input", this.internalTask);
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    },
    isCurrentUser(approver) {
      return approver.user.id === this.$store.state.currentUser.id;
    },
    formattedDate(date) {
      if (date === undefined || date === null) {
        return "";
      }

      return format(
        parseISO(date),
        `${this.$store.state.dateFormats.dateFns} HH:mm`
      );
    },
    pluralised(count) {
      if (count > 1) {
        return `${count} users have`;
      }

      return `${count} user has`;
    }
  }
};
</script>
