<template lang="pug">
  .form-documents
    div(v-for="document in documentsList", :key="document.id")
      .inline-block(:title="document.name" @click="documentClicked(document)")
        app-file-icon.image.inline-block(:extension="extension(document)")
        a.document-name.dark-text.hover-underline.ml-2.relative {{ document.name }}

    app-sidebar-container(v-model="isOpen")
      sidebar(@close="isOpen = flase", :document="document", :listing-view="true", @input="isOpen = false", :project="project", :read-only="this.readOnly")

    app-button(v-if="!readOnly", slim, @click="prepareAndBrowse", :loading="loading") Upload

</template>

<script>
import linkDocuments from "@/graphql/mutations/documents/linkDocuments.gql";
import DocumentQuery from "@/graphql/queries/document_management/Document.gql";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import createFormFolder from "@/graphql/mutations/documents/createFormFolder.gql";
import DocumentLinkQuery from "@/graphql/queries/document_management/DocumentLink.gql";
import Sidebar from "./Sidebar.vue";
import { isFolder } from "@/helpers/DocumentHelpers";

export default {
  components: {
    Sidebar
  },
  apollo: {
    documents: {
      query: DocumentLinkQuery,
      variables() {
        return {
          id: this.label,
          type: "FormLabel"
        };
      },
      update(response) {
        return response.documentLink;
      }
    }
  },
  props: {
    label: {
      type: Number,
      required: true
    },
    project: {
      type: Object,
      required: true
    },
    multi: {
      type: Boolean,
      required: true
    },
    form: {
      type: Number,
      required: true
    },
    multiSelect: {
      type: Boolean,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      documents: [],
      document: [],
      isOpen: false,
      loading: false
    };
  },
  computed: {
    documentsList() {
      return this.documents
        .map(d => {
          if (isFolder(d.linkedDocument)) {
            return d.linkedDocument.children;
          } else {
            return d.linkedDocument;
          }
        })
        .flat();
    }
  },
  mounted() {
    this.$store.dispatch(
      "documentManagement/registerUploadCallback",
      this.documentUploaded
    );
  },
  methods: {
    documentClicked(document) {
      this.isOpen = true;
      this.document = document;
    },
    extension(doc) {
      if (isFolder(doc)) {
        return "folder";
      }

      return doc.currentVersion.upload.fileExtension;
    },
    prepareAndBrowse() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: createFormFolder,
          variables: {
            input: {
              project: {
                id: this.project.id
              },
              formLabel: {
                id: this.label
              }
            }
          }
        })
        .then(response => {
          this.loading = false;
          this.$store.state.documentManagement.project = this.project;
          this.$store.state.documentManagement.currentFolder =
            response.data.createFormFolder.folder;
          // this.$store.state.documentManagement.formMultiInstance = this.multi;
          this.$store.state.documentManagement.formLabel = this.label;
          this.$store.dispatch(
            "documentManagement/browseForDocument",
            this.multiSelect
          );
          this.$store.dispatch("documentManagement/setStandalone", true);
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
          this.loading = false;
        });
    },
    documentUploaded(docID, file) {
      const metadata = file.getMetadata();
      if (metadata.formLabel === this.label) {
        // This is our file
        if (this.multiSelect) {
          // Already linked to a folder, so we'll just grab the document
          this.loadDocument(docID);
        } else {
          // New single doc, so we need to create the link
          this.createLink(docID);
        }
      }
    },
    createLink(docID) {
      this.$apollo
        .mutate({
          mutation: linkDocuments,
          variables: {
            input: {
              resources: [{ id: this.label, resourceType: "FormLabel" }],
              documents: [{ id: docID }],
              remove: true
            }
          }
        })
        .then(({ data: { linkDocuments } }) => {
          this.documents = [
            {
              linkedDocument: linkDocuments.documentLinks[0].linkedDocument,
              typename: "__DocumentLink"
            }
          ];
        });
    },
    loadDocument(docID) {
      this.$apollo
        .query({
          query: DocumentQuery,
          variables: {
            id: docID
          }
        })
        .then(result => {
          this.documents.push({
            linkedDocument: result.data.document,
            typename: "__DocumentLink"
          });
        });
    }
    // formatDocuments() {
    //   var docs = [];
    //   this.documents.forEach(function(e) {
    //     if (e.linkedDocument.__typename === "Folder") {
    //       docs = e.linkedDocument.children;
    //     } else if (e.linkedDocument.__typename === "Document") {
    //       docs.push(e.linkedDocument);
    //     }
    //   });

    //   return docs;
    // }
  }
};
</script>

<style lang="postcss" scoped>
.document-name {
  bottom: 10px;
}
</style>
