<template lang="pug">
  .ui.input.input-text
    input(
      type='text',
      :value='value',
      :placeholder='placeholder',
      @input='updateValue($event.target.value)')
</template>

<script>
export default {
  props: ["value", "placeholder"],
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    }
  }
};
</script>
