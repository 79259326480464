export const ACTIONS = {
  VIEW: "view",
  EDIT: "edit",
  EXPORT: "export",
  SNAPSHOT: "snapshot",
  REOPEN: "reopen",
  CLOSE: "close",
  DELETE: "delete"
};

export const projectMenuItems = project => {
  let actions = [];
  let { closed } = project;

  actions.push({
    label: "View project",
    icon: "preview",
    action: ACTIONS.VIEW
  });

  if (project.canEdit) {
    actions.push({
      label: "Edit project",
      icon: "edit",
      action: ACTIONS.EDIT
    });

    actions.push({ type: "divider" });
  }

  actions.push({
    label: "Export project",
    icon: "export",
    action: ACTIONS.EXPORT
  });

  actions.push({
    label: "Snapshot report",
    icon: "snapshot",
    action: ACTIONS.SNAPSHOT
  });

  actions.push({ type: "divider" });

  if (project.canClose) {
    if (closed)
      actions.push({
        label: "Re-open",
        icon: "refresh",
        action: ACTIONS.REOPEN
      });
    else
      actions.push({
        label: "Close",
        icon: "error",
        action: ACTIONS.CLOSE
      });
  }

  if (project.canDestroy) {
    actions.push({
      label: "Delete",
      icon: "delete",
      iconColour: "red",
      action: ACTIONS.DELETE
    });
  }

  if (actions[actions.length - 1].type === "divider") {
    actions.pop();
  }

  return actions;
};

export const bulkActions = [
  {
    label: "Close",
    icon: "error",
    action: ACTIONS.CLOSE
  },
  {
    label: "Delete",
    icon: "delete",
    iconColour: "red",
    action: ACTIONS.DELETE
  }
];
