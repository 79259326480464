<template lang="pug">
  app-panel(secondary)
    app-header(size="h3") Invoices
      template(#subheader)
        | Permissions for invoices

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")
      template(#options)
        app-checkbox.mb-6(label="View invoices" :value="true")
        app-checkbox.mb-6(label="Edit existing invoices" :value="permissions.editInvoice" @input="permissionChanged('editInvoice', $event)")
        app-checkbox.mb-6(label="Delete invoices" :value="permissions.removeInvoice" @input="permissionChanged('removeInvoice', $event)")
        app-checkbox.mb-6(label="Create new invoices" :value="permissions.createInvoice" @input="permissionChanged('createInvoice', $event)")

</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> access invoices",
          value: "cannotManage"
        },
        {
          label: "Can access for <strong>assigned projects</strong> only",
          value: "canManageAssigned",
          slot: "options"
        },
        {
          label: "Can access for <strong>any project</strong>",
          value: "canManageAny",
          slot: "options"
        }
      ],
      selected: "cannotManage",
      permissions: {
        viewInvoice: false,
        editInvoice: false,
        removeInvoice: false,
        createInvoice: false
      },
      scope: "global"
    };
  },
  computed: {
    selectedPermissions() {
      if (this.selected === "cannotManage") {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: this.scope
      };
    }
  },
  mounted() {
    if (this.value.permissions.viewInvoice) {
      if (this.value && this.value.scope === "project") {
        this.selected = "canManageAssigned";
        this.scope = "project";
      } else {
        this.selected = "canManageAny";
        this.scope = "global";
      }
    }
  },
  methods: {
    optionChanged(value) {
      this.permissions.viewInvoice = this.permissions.viewFinances =
        value !== "cannotManage";
      this.scope = value === "canManageAssigned" ? "project" : "global";
      this.selected = value;
      this.notifyChange();
    },
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
