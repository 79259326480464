/* global moment */
/* eslint-disable no-unused-vars */
import {
  calculateLag,
  calculateWorkingDaysBetween,
  checkParents,
  setTaskType,
  toggleElementVisibility,
  convertGanttTask
} from "@/components/projects/gantt/ProjectGanttUtils";
import isSameDay from "date-fns/isSameDay";

import TaskSidebarMixin from "@/mixins/tasks/TaskSidebarMixin";
import { gantt } from "dhtmlx-gantt";

export default {
  mixins: [TaskSidebarMixin],
  data() {
    return {
      isAutoScheduling: false,
      isUndoing: false,
      taskMoveOldParent: null,
      hasRendered: false
    };
  },
  methods: {
    configureEvents() {
      this.registerListener("onExpand");
      this.registerListener("onCollapse");
      this.registerListener("onBeforeTaskDisplay");
      this.registerListener("onBeforeTaskAdd");
      this.registerListener("onBeforeTaskChanged");
      this.registerListener("onBeforeTaskMove");
      this.registerListener("onAfterTaskAdd");
      this.registerListener("onAfterTaskUpdate");
      this.registerListener("onBeforeTaskUpdate");
      this.registerListener("onBeforeTaskAutoschedule");
      this.registerListener("onAfterTaskAutoSchedule");
      this.registerListener("onBeforeTaskDrag");
      this.registerListener("onTaskDrag");
      this.registerListener("onAfterTaskDrag");
      this.registerListener("onBeforeLinkAdd");
      this.registerListener("onAfterLinkDelete");
      this.registerListener("onAfterLinkAdd");
      this.registerListener("onBeforeRowDragEnd");
      this.registerListener("onRowDragEnd");
      this.registerListener("onTaskDblClick");
      this.registerListener("onTaskOpened");
      this.registerListener("onTaskClosed");
      this.registerListener("onContextMenu");
      this.registerListener("onBeforeUndo");
      this.registerListener("onAfterUndo");

      this.registerListener("onBeforeAutoSchedule");
      this.registerListener("onAfterAutoSchedule");

      this.registerOnBeforeGanttRender();
      gantt.showLightbox = this.showLightbox;
      gantt.hideLightbox = () => {};

      window.addEventListener("beforeunload", evt => {
        if (this.isLoading) {
          evt.preventDefault();
          // Chrome/Chromium based browsers still need this one.
          evt.returnValue = "o/";
        }
      });

      gantt.attachEvent("onTaskClick", (id, evt) => {
        const link = evt.target.closest(".add-task");
        if (link) {
          const parent = { id };
          this.createAndAddTask(parent);
          return false;
        }

        return true;
      });

      gantt.createTask = () => {
        this.createAndAddTask();
      };
    },

    onCollapse() {
      toggleElementVisibility("navigation", true);

      // The gantt library seems to mess up things and doesn't reset styles
      gantt.$root.style = {};
      gantt.render();
    },

    onExpand() {
      // Hide the nav bar
      toggleElementVisibility("navigation", false);
    },

    onAfterTaskAdd(id) {
      const parent = gantt.getParent(id);
      const task = gantt.getTask(id);

      task.$new = false;

      if (parent !== 0) {
        this.persistence.ignore(() => {
          setTaskType(parent);
        });
      }

      return true;
    },

    onTaskDblClick(taskId) {
      if (taskId) {
        this.openTask({ id: taskId });
        return false;
      }

      return true;
    },

    onTaskOpened(id) {
      this.changeTaskExpandedStatus([id], true);
    },

    onTaskClosed(id) {
      this.changeTaskExpandedStatus([id], false);
    },

    /**
     * Whenever a task is being moved (dragged vertically)
     * we need to check if the new parent is a milestone
     * and reject the move in that case
     */
    onBeforeTaskMove(_id, parent) {
      if (parent != 0 && gantt.getTask(parent).type == "mp_milestone") {
        return false;
      } else {
        return true;
      }
    },

    onBeforeTaskDrag(id, mode) {
      const modes = gantt.config.drag_mode;
      const task = gantt.getTask(id);

      // Don't allow milestone resizing
      if (task.isMilestone && mode === modes.resize) {
        return false;
      }

      task.auto_scheduling = false;

      return true;
    },

    onTaskDrag(taskId, mode, modifiedTask, originalTask) {
      const modes = gantt.config.drag_mode;

      const roundedModifiedStart = gantt.roundDate(modifiedTask.start_date);
      const roundedModifiedEnd = gantt.roundDate(modifiedTask.end_date);
      const roundedOriginalStart = gantt.roundDate(originalTask.start_date);
      const roundedOriginalEnd = gantt.roundDate(originalTask.end_date);

      if (
        mode === modes.resize &&
        (!isSameDay(roundedModifiedStart, roundedOriginalStart) ||
          !isSameDay(roundedModifiedEnd, roundedOriginalEnd))
      ) {
        const links = modifiedTask.$target;
        if (links.length > 0) {
          for (let i = 0; i < links.length; i++) {
            const link = gantt.getLink(links[i]);
            const source = gantt.getTask(link.source);
            const target = {
              start_date: roundedModifiedStart,
              end_date: roundedModifiedEnd
            };
            link.lag = calculateLag(source, target, link.type);
            gantt.updateLink(link.id);
          }
        }
      }
    },
    /**
     * Once a drag of a task has finished, we need to trigger
     * a date calculation again. This really should be done
     * automatically and normally is, but there appears to be
     * an issue in certain situations. This callback resolves
     * the issues and causes date calculation to function
     * correctly.
     */
    onAfterTaskDrag(id, mode) {
      // const task = gantt.getTask(id);
      const modes = gantt.config.drag_mode;
      const task = gantt.getTask(id);
      if (mode === modes.move) {
        gantt.autoSchedule();
      }
    },

    /**
     * Before a dependency is added, we want to make sure
     * that there isn't another dependency with a different
     * kind. Two tasks cannot have two or more dependencies
     * of different calculation types. If the link being
     * added is of a different type, then it will be rejected.
     * Otherwise it can be added.
     */
    onBeforeLinkAdd(id, link) {
      const target = gantt.getTask(link.target);
      const source = gantt.getTask(link.source);
      const sources = target.$target;
      let i;

      if (!checkParents(link.target, link.source)) {
        return false;
      }

      for (i = 0; i < sources.length; i++) {
        let sourceLink = gantt.getLink(sources[i]);
        if (parseInt(sourceLink.type) !== parseInt(link.type)) {
          this.$flash.error(
            "You cannot have multiple types of dependencies on a single task"
          );
          return false;
        }
      }

      target.auto_scheduling = false;

      link.lag = calculateLag(source, target, link.type);
      return true;
    },

    onAfterLinkDelete(_id, link) {
      this.persistence.linkDeleted(link);
      this.persistence.saveWhenReady();
    },

    onAfterLinkAdd(id, link) {
      const targetTask = gantt.getTask(link.target);

      targetTask.auto_scheduling = true;
      this.persistence.addLink(link);
      this.persistence.saveWhenReady();

      return true;
    },

    onBeforeTaskDisplay(id, task) {
      if (this.taskFilterRegexp) {
        return this.taskFilterRegexp.test(task.text);
      }

      return true;
    },

    onBeforeTaskAdd(id, task) {
      if (
        (task.type === "mp_milestone" && !task.end_date) ||
        (task.type !== "mp_milestone" &&
          (!task.duration || !task.start_date || !task.end_date))
      ) {
        return false;
      }

      if (!task.planned_start) {
        task.planned_start = task.start_date;
      }

      if (!task.planned_end) {
        task.planned_end = task.end_date;
      }

      task.baseline_locked = false;

      return true;
    },

    /**
     * Keeps track of how far a task has been dragged. This
     * is then used later to updated all dependent tasks
     * dates.
     */
    onBeforeTaskChanged(id, mode, originalTask) {
      const modes = gantt.config.drag_mode;
      const modifiedTask = gantt.getTask(id);
      let links;
      let i;

      if (!modifiedTask.baseline_locked) {
        modifiedTask.planned_start = modifiedTask.start_date;
        modifiedTask.planned_end = modifiedTask.end_date;
      }

      if (mode === modes.move) {
        // || mode === modes.resize) {
        links = modifiedTask.$target;
        if (links.length > 0) {
          for (i = 0; i < links.length; i++) {
            const link = gantt.getLink(links[i]);
            const source = gantt.getTask(link.source);
            link.lag = calculateLag(source, modifiedTask, link.type);
            gantt.updateLink(link.id);
            gantt.refreshLink(link.id);
            // gantt.autoSchedule(source);
          }
        }
      } else if (mode == modes.resize) {
        if (modifiedTask.duration < 1) {
          return false;
        }
      }

      return true;
    },

    onBeforeTaskUpdate(id, new_item) {},

    onAfterTaskUpdate(id, task) {
      // Make sure the parent tasks are included in the update. They don't get
      // notified if a child is updated

      // let tasks = [convertGanttTask(task)];
      // let parent = gantt.getParent(task.id);
      // while (parent !== 0) {
      //   const parentTask = gantt.getTask(parent);
      //   tasks.push(convertGanttTask(parentTask));
      //   parent = gantt.getParent(parentTask.id);
      // }

      task.auto_scheduling = true;

      if (!this.isUndoing) {
        this.persistence.taskUpdated(task);
        if (!this.isAutoScheduling) {
          // Make sure the data is saved to the server
          this.persistence.saveWhenReady().then(updatedTasks => {
            const updateEvent = updatedTasks.map(t => convertGanttTask(t));
            this.$emit("tasks-updated", updateEvent);
          });
        }
      }
      return true;
    },

    onAfterTaskAutoSchedule(task, start, link, predecessor) {
      if (!task.baseline_locked) {
        task.planned_start = task.start_date;
        task.planned_end = task.end_date;
      }
    },

    onBeforeTaskAutoschedule(task) {
      // Record the original parent values for later
    },

    onBeforeRowDragEnd(taskId, parentId, _index) {
      const task = gantt.getTask(taskId);
      const errorMessage = "A task cannot have dependencies with parent tasks";

      // Don't allow it if there is a link between the task
      // being moved, and any of its parents
      const targetLinks = task.$target.map(id => gantt.getLink(id).source);
      const sourceLinks = task.$source.map(id => gantt.getLink(id).target);

      if (
        targetLinks.indexOf(parentId) !== -1 ||
        sourceLinks.indexOf(parentId) !== -1
      ) {
        this.$flash.error(errorMessage);
        return false;
      }

      let valid = true;

      gantt.eachParent(p => {
        if (
          targetLinks.indexOf(p.id) !== -1 ||
          sourceLinks.indexOf(p.id) !== -1
        ) {
          valid = false;
        }
      }, parentId);

      if (!valid) {
        this.$flash.error(errorMessage);
        return false;
      }

      this.taskMoveOldParent = task.parent;
      this.taskDraggedToNewPosition(task, parentId);

      return true;
    },

    taskDraggedToNewPosition(task, newParentId) {
      const oldParentId = task.parent;
      const oldParent = oldParentId === 0 ? null : gantt.getTask(oldParentId);
      const newParent = newParentId === 0 ? null : gantt.getTask(newParentId);

      // const oldParent = gantt.getTask(task.parent);
      // const newParent = gantt.getTask(parentId);

      // Make sure the old parent is now the right type
      if (
        oldParent &&
        oldParentId !== newParentId &&
        gantt.getChildren(oldParentId).length === 1
      ) {
        // We're moving parents and this parent will become empty, so we'll mark
        // it as a task instead of a project
        oldParent.type = "task";
      }

      if (newParent && newParentId !== oldParentId) {
        newParent.type = "project";

        // We also need to make sure that if the parent is going to
        // move, that we also move all successors and update the
        // lag of any links it has
      }
    },

    onRowDragEnd(taskId) {
      const task = gantt.getTask(taskId);
      const newParentId = task.parent;
      const oldParentId = this.taskMoveOldParent;
      let updatedTasks = [];

      // The parent has changed, so the parent might have
      // been moved and other things need moving as well
      if (oldParentId !== newParentId) {
        const oldParent = oldParentId === 0 ? null : gantt.getTask(oldParentId);
        const newParent = newParentId === 0 ? null : gantt.getTask(newParentId);
        let parentsToUpdate = [];

        // Record which of our parent trees we need to update,
        // and we'll log all updates in them accordingly
        if (newParent) parentsToUpdate.push(newParent);
        if (oldParent) parentsToUpdate.push(oldParent);

        // If we're moving into a new parent, it's possible that the
        // parent will have completely moved to a new location. in this
        // instance, we need to update the `lag` of each link. This doesn't
        // apply to the old parent however, as this will just turn into a
        // normal task and not move anywhere
        if (newParent) {
          newParent.$target.forEach(linkId => {
            const link = gantt.getLink(linkId);
            const source = gantt.getTask(link.source);
            link.lag = calculateLag(source, task, link.type);
          });
        }

        // Now we're going to run the autoscheduler and make sure
        // everything is in the correct position, but we'll ignore
        // the updates since we're going to put those together ourselves
        this.persistence.ignore(() => {
          gantt.autoSchedule();
        });

        // Now on to building the update tree. For this we will loop through
        // all parents, and all successors of those parents record the data
        // as a change. it might not have actually changed, but there's no
        // simple way of tracking the actual changes so this is the safest
        // option
        parentsToUpdate.forEach(parentToUpdate => {
          // Record this parent as an updated task
          updatedTasks.push(convertGanttTask(parentToUpdate));
          this.persistence.taskUpdated(parentToUpdate);

          // Now record each sucessor
          gantt.eachSuccessor(successor => {
            updatedTasks.push(convertGanttTask(successor));
            this.persistence.taskUpdated(successor);
          }, parentToUpdate.id);

          // And now repeat for each parent
          gantt.eachParent(parent => {
            updatedTasks.push(convertGanttTask(parent));
            gantt.eachSuccessor(successor => {
              updatedTasks.push(convertGanttTask(successor));
              this.persistence.taskUpdated(successor);
            }, parent.id);
          }, parentToUpdate.id);
        });

        // Last up, we need to update the position of the chidlren in the
        // old parent, and the new parent as things will have shifted
        // around
        gantt.getChildren(newParentId).forEach(child => {
          this.persistence.positionChanged(gantt.getTask(child));
        });

        gantt.getChildren(oldParentId).forEach(child => {
          this.persistence.positionChanged(gantt.getTask(child));
        });
      } else {
        // The parent hasn't changed, so we've just changed positions.
        // this means however we need to update every task in this parent
        // so the position is recorded
        gantt.getChildren(newParentId).forEach(child => {
          this.persistence.positionChanged(gantt.getTask(child));
        });
      }

      // Finally, record the changes of the moved task itself
      this.persistence.taskUpdated(task);
      gantt.eachSuccessor(successor => {
        this.persistence.taskUpdated(successor);
      }, taskId);

      this.persistence.saveWhenReady().then(updatedTasks => {
        this.$emit("tasks-updated", updatedTasks);
      });

      // if (this.taskMoveOldParent !== 0) {
      //   setTaskType(this.taskMoveOldParent);
      //   persistTaskAndLinks(gantt.getTask(this.taskMoveOldParent));
      // }

      // // Need to record everything that's changed and save it!
      // persistTaskAndLinks(task);

      // Make sure we've got everything about dependencies as things

      this.onFinishedLoading(() => {
        this.$emit("task-moved", {
          task: convertGanttTask(task),
          newParentId: task.parent === 0 ? null : task.parent
        });
      });
    },

    registerListener(evtName) {
      gantt.attachEvent(evtName, this[evtName]);
      // const vm = this;
      // gantt.attachEvent(evtName, function() {
      //   console.log(`[EVT] ${evtName}`);
      //   return vm[evtName].apply(vm, arguments);
      // });
    },

    registerOnBeforeGanttRender() {
      const onBeforeGanttRender = gantt.attachEvent(
        "onBeforeGanttRender",
        () => {
          const view = gantt.$ui.getView("timeline");
          const originalGetItemPosition = view.getItemPosition.bind(view);

          gantt.$ui.getView("timeline").getItemPosition = (
            task,
            start_date,
            end_date
          ) => {
            let new_start;

            if (task.type === "mp_milestone") {
              // Add extra time to render it in the middle of the column. Note
              // we're adding 10 hours instead of 12, because if we add 12 then it
              // won't be perfectly centered. Instead, the left side of the milestone
              // will be in the middle. By making it 10 hours, it pushes it left
              // slightly so it's central. It's not exact as there's no real way
              // to perfectly calculate it, but it'll do

              new_start = moment(start_date || task.start_date)
                .clone()
                .add(10, "hours")
                .toDate();

              return originalGetItemPosition(task, new_start, new_start);
            } else {
              return originalGetItemPosition(task, start_date, end_date);
            }
          };

          // we want to decorate `getItemPosition` only once - otherwise the 12h shift
          // accumulates before each rendering
          gantt.detachEvent(onBeforeGanttRender);
        }
      );
    },

    onContextMenu() {
      return false;
    },

    onBeforeAutoSchedule() {
      this.isAutoScheduling = true;
    },
    onAfterAutoSchedule(taskId, updatedTaskIds) {
      // const updatedTasks = updatedTaskIds.map(id => gantt.getTask(id));
      this.isAutoScheduling = false;
    },

    onBeforeUndo() {
      this.isUndoing = true;
    },

    onAfterUndo() {
      this.isUndoing = false;
    },
    // showLightbox(id) {
    //   var task = gantt.getTask(id),
    //     parent;
    //   // with-baselines class is in the config
    //   task.baseline_locked = $(".gantt.chart .gantt_layout_root").hasClass(
    //     "with-baselines"
    //   );
    //   task.disable_base_line_dates = false;

    //   var savedCb = function(savedTask) {
    //     $.extend(task, savedTask);
    //     if (savedTask.$new) {
    //       gantt.addTask(task, task.parent);
    //     } else {
    //       gantt.updateTask(id);
    //     }

    //     gantt.autoSchedule(id);

    //     return true;
    //   };

    //   var deletedCb = function() {
    //     gantt.deleteTask(id);
    //   };

    //   var cancelledCb = function() {
    //     this.removeNewTask(task);
    //     gantt.refreshData();
    //   }.bind(this);

    //   if (!task.status) {
    //     task.status = "Not started";
    //   }

    //   if (task.$new) {
    //     task.type = "task";

    //     if (task.parent) {
    //       parent = gantt.getTask(task.parent);

    //       task.start_date = parent.start_date;
    //       task.end_date = parent.end_date;
    //       task.duration = parent.duration;
    //     } else {
    //       task.start_date = this.data.startDate;
    //       task.end_date = this.data.endDate;
    //       task.duration = gantt.calculateDuration(task);
    //     }

    //     task.planned_start = task.start_date;
    //     task.planned_end = task.end_date;
    //     task.baseline_locked = false;
    //   }

    //   if (this.canEdit) {
    //     mainAppVm.$refs["gantt-task-modal"].open(
    //       task,
    //       savedCb,
    //       deletedCb,
    //       cancelledCb
    //     );
    //   }
    // }
    // removeNewTask: function (task) {
    //   if (task.$new !== undefined) {
    //     gantt.$data.tasksStore.silent(function () {
    //       gantt.$data.tasksStore.removeItem(task.id);
    //       gantt._update_flags(task.id, null);
    //     });
    //   }
    // },
    projectLoaded(project) {
      this.columnSizeData = project.ganttColumns || {};
      gantt.config.readonly = !project.canUpdateTasks;
      // this.setReadOnly(!project.canUpdateTasks);
      this.setReadOnly(false);
      this.toggleBaselines(project.showBaselines, false);

      if (this.columnSizeData && this.hasRendered) {
        // It's already been rendered, so we'll adjust the columns
        this.configureColumns(this.columnSizeData, !project.canUpdateTasks);
        gantt.render();
      }
    }
  },
  watch: {
    isVisible(isVisible) {
      if (isVisible && !this.hasRendered) {
        this.configureColumns(this.columnSizeData, gantt.config.readonly);
        gantt.render();

        this.hasRendered = true;
      }
    }
  }
};
