<template lang="pug">
  modal(
    v-if='!dynamic'
    :name='name',
    :classes='allClasses',
    :adaptive='true',
    :height='height',
    @before-close='$emit("closing")')

    .loading.container(v-if='showLoadingSpinner')
      .mp.loading.spinner(:class='state')
        .checkmark(v-if='showCheckmark', :class='state')

      span(v-if='showLoadingMessage') {{ loadingMessage }}

    .small.close.button(@click='$modal.hide(name)')
    .title(v-if='title') {{ title }}

    slot

  div(v-else)
    .loading.container(v-if='showLoadingSpinner')
      .mp.loading.spinner(:class='state')
        .checkmark(v-if='showCheckmark', :class='state')

      span(v-if='showLoadingMessage') {{ loadingMessage }}

    .small.close.button(@click='$modal.hide(name)')
    .title(v-if='title') {{ title }}

    slot
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    classes: {
      type: String,
      required: false,
      default: ""
    },
    title: {
      type: String,
      required: false
    },
    state: {
      type: String,
      required: false,
      default: "none"
    },
    height: {
      type: null,
      default: "auto"
    },
    loadingMessage: String,
    dynamic: Boolean
  },
  computed: {
    allClasses() {
      return `mp modal ${this.classes}`;
    },
    showLoadingMessage() {
      return !this.showCheckmark && this.loadingMessage;
    },
    showLoadingSpinner() {
      return this.state !== "none";
    },
    showCheckmark() {
      return this.state === "success" || this.state === "error";
    }
  }
};
</script>

<style lang="postcss">
.v--modal-overlay {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: var(--overlay);
  z-index: 999;
  opacity: 1;
}
.v--modal-overlay.scrollable {
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.v--modal-overlay .v--modal-background-click {
  min-height: 100%;
  width: 100%;
  padding-bottom: 10px;
}
.v--modal-overlay .v--modal-box {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
.v--modal-overlay.scrollable .v--modal-box {
  margin-bottom: 2px;
  /* transition: top 0.2s ease; */
}
.mp.modal {
  background-color: var(--white);
  text-align: left;
  border-radius: 0;
  box-shadow: var(--box-shadow-50);
  padding: 2rem;

  .title {
    font-weight: 400;
    font-size: 1.75rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .loading.container {
    align-items: center;
    background: var(--white);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;

    .mp.loading.spinner {
      flex: 0 0 auto;
    }
  }

  &.v--modal-fullscreen {
    width: 100vw;
    height: 100vh;
    margin: 0;
    left: 0;
    top: 0;
  }
}

.v--modal-top-right {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
.overlay-fade-enter-active,
.overlay-fade-leave-active {
  transition: all 0.2s;
}
.overlay-fade-enter,
.overlay-fade-leave-active {
  opacity: 0;
}
.nice-modal-fade-enter-active,
.nice-modal-fade-leave-active {
  transition: all 0.4s;
}
.nice-modal-fade-enter,
.nice-modal-fade-leave-active {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
