import { isValid } from "date-fns";
import { helpers } from "vuelidate/lib/validators";

const isValidDate = date => {
  return isValid(new Date(date));
};
const positive = num => num >= 0;
const before = (prop, parser, strict = false) => {
  return helpers.withParams(
    { type: "before", prop, parser, strict },
    (value, validatedModel) => {
      let val = value;
      let otherVal = helpers.ref(prop, this, validatedModel);
      if (!value) return true;
      if (parser) {
        val = parser(val);
        otherVal = parser(otherVal);
      }

      if (strict) {
        return val < otherVal;
      } else {
        return val <= otherVal;
      }
    }
  );
};

export { isValidDate, positive, before };
