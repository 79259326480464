<template lang="pug">
  app-dialog(@cancel="$emit('cancel')" :dismissable="false")
    template(#title) You need to subscribe to a paid plan

    p.text-grey-70.mb-8
      | The free plan only permits one user. Please go to the #[a(href="/subscriptions") subscriptions page] to sign up to a paid plan and experience all the benefits that ManagePlaces has to offer.

    .text-right
      app-button(primary @click="$emit('cancel')") Dismiss
</template>

<script>
export default {
  name: "CannotAssignLicenseDialog"
};
</script>
