<template lang="pug">
  .contacts.h-full.overflow-hidden
    .flex.flex-row.mb-4
      app-text-input(class="w-2/3" v-model="search" standalone icon="search")
      .flex-1
      app-button(primary @click="addContact") Add contact

    app-split-view.h-full(
      :menu-options="menuOptions"
      @menu-item-clicked="menuItemClicked"
      @menu-item-added="contactGroupAdded"
      @menu-item-renamed="contactGroupRenamed"
      @menu-item-deleted="contactGroupDeleted")

      contacts-table(
        :contact-type="contactType"
        :contact-group="contactGroup"
        :contact-groups="contactGroups"
        :contact-form-open="creating"
        :search="search"
        ref="table")
</template>

<script>
import ContactsTable from "@/components/contact_management/ContactsTable.vue";
import NewContactSidebar from "@/components/contact_management/new_contact_sidebar/NewContactSidebar.vue";
import ContactGroups from "@/graphql/queries/contact_management/ContactGroups.gql";
import {
  errorMessage as gqlErrorMessage,
  cacheUpdate,
  appendToCache,
  deleteCacheItems,
  updateCacheItem
} from "@/helpers/GraphQLHelpers";
import ContactManager from "@/components/contact_management/ContactManager";

export default {
  components: {
    ContactsTable
  },
  apollo: {
    contactGroups: {
      query: ContactGroups
    }
  },
  data() {
    return {
      contactGroups: [],
      contactType: "ALL",
      contactGroup: null,
      search: "",
      contactManager: new ContactManager(),
      creating: false
    };
  },
  computed: {
    contactGroupMenuItems() {
      return this.contactGroups
        .map(group => ({
          id: group.id,
          label: group.name,
          menuItemType: "contactGroup"
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    menuOptions() {
      return [
        {
          label: "All contacts",
          value: "ALL",
          icon: "contacts",
          menuItemType: "contactType"
        },
        {
          label: "Customers",
          value: "CUSTOMER",
          icon: "invoice",
          menuItemType: "contactType"
        },
        {
          label: "Suppliers",
          value: "SUPPLIER",
          icon: "bill",
          menuItemType: "contactType"
        },
        {
          groupLabel: "Groups",
          allowAdditions: true,
          addLabel: "Add group",
          children: this.contactGroupMenuItems,
          allowRename: true,
          allowDeletion: true
        }
      ];
    }
  },
  methods: {
    addContact() {
      const vm = this;
      this.creating = true;
      this.$sidebar.open(NewContactSidebar, {
        on: {
          created(contact) {
            // New contact added.
            vm.$refs.table.contactAdded(contact);
            vm.creating = false;
          },
          close() {
            vm.creating = false;
          }
        }
      });
    },
    menuItemClicked(h) {
      if (h.menuItemType === "contactType") {
        this.contactGroup = null;
        this.contactType = h.value;
      } else {
        this.contactType = "ALL";
        this.contactGroup = h;
      }
    },
    contactGroupAdded(item) {
      this.contactManager
        .createGroup(item.label)
        .then(({ data: { createContactGroup } }) => {
          const { contactGroup } = createContactGroup;

          cacheUpdate(
            cachedData =>
              appendToCache(cachedData, "contactGroups", [contactGroup]),
            ContactGroups
          );
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    },
    contactGroupRenamed(item) {
      this.contactManager
        .renameGroup(item, item.label)
        .then(() => {
          cacheUpdate(
            cachedData =>
              updateCacheItem(
                cachedData,
                "contactGroups",
                item,
                "name",
                item.label
              ),
            ContactGroups
          );
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    },
    contactGroupDeleted(item) {
      this.contactManager
        .deleteGroup(item)
        .then(() => {
          cacheUpdate(
            cachedData => deleteCacheItems(cachedData, "contactGroups", [item]),
            ContactGroups
          );
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    }
  }
};
</script>
