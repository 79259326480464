<template lang="pug">
  .flex.flex-row.h-full
    app-avatar.flex-none.self-center(v-if="params.value", :avatar="params.value.avatar")
    .flex-1.ml-4.self-center(v-if="params.value")
      p.leading-none {{ params.value.name }}
      p.leading-none.text-grey-60.text-sm.mt-1(v-if="params.value.email")  {{ params.value.email }}

</template>

<script>
import Vue from "vue";

export default Vue.extend({});
</script>
