<template lang="pug">
  .relative.w-48
    flat-pickr.h-full.w-full.p-3(
      v-model="date"
      :config="config",
      :placeholder="placeholder",
      :wrap="true",
      v-on="$listeners"
    )
    app-icon.absolute.right-0.p-4(icon="calendar" size="small")
</template>

<script>
import FlatPickr from "vue-flatpickr-component";

export default {
  components: {
    FlatPickr
  },

  props: {
    classes: {
      type: String,
      default: ""
    },

    placeholder: {
      type: String,
      default: ""
    },

    value: {
      type: [String, Date],
      default: () => new Date()
    },

    disable: {
      type: [Array, Date, Function],
      default: () => []
    },

    minDate: {
      type: [String, Date],
      default: null
    },

    format: {
      type: String,
      default: ""
    }
  },

  data() {
    return {};
  },

  computed: {
    date: {
      get() {
        return this.value ? new Date(this.value) : new Date();
      },

      set(value) {
        this.$emit("input", value);
      }
    },

    dateFormat() {
      return this.format || this.$store.state.dateFormat;
    },

    config() {
      return {
        altInput: true,
        altFormat: this.dateFormat,
        dateFormat: "Y/m/d",
        defaultDate: new Date(),
        minDate: this.minDate,
        allowInput: true,
        disable: this.disable
      };
    }
  }
};
</script>

<style lang="stylus">
$calendarBackground = #fff
$calendarBorderColor = alpha(#484848, 0.2)

$monthForeground = #fff
$monthBackground = var(--tribal-aqua)

$weekdaysBackground = var(--tribal-aqua)

$dayForeground = #484848
$dayHoverBackground = #e2e2e2

$todayColor = #bbb

$selectedDayBackground = var(--tribal-aqua)
$selectedDayForeground = #fff

$noCalendarBorder = true

@require '~flatpickr/src/style/flatpickr.styl'
</style>
