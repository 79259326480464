<template lang="pug">
  .h-full.overflow-auto
    folder.absolute.top-0.left-0.min-w-full(v-if="folders", :folder="rootFolder")
</template>

<script>
import { mapState } from "vuex";

import Folder from "./DirectoryListingFolder.vue";

import ProjectFolders from "@/graphql/queries/core/projects/ProjectFolders.gql";
import CompanyFolders from "@/graphql/queries/core/company/CompanyFolders.gql";

export default {
  name: "DirectoryListing",
  components: {
    Folder
  },
  props: {
    project: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      rootFolder: {
        isRoot: true,
        name: "/",
        path: "/"
      }
    };
  },
  created() {
    this.$store.dispatch("documentUploadModal/folderSelected", {
      folder: this.currentFolder || this.rootFolder
    });
  },
  computed: {
    ...mapState("documentUploadModal", ["folders"]),
    ...mapState("documentManagement", ["currentFolder"])
  },
  apollo: {
    folders: {
      query() {
        return this.project ? ProjectFolders : CompanyFolders;
      },
      variables() {
        return this.project ? { id: this.project.id } : {};
      },
      update(data) {
        const { foldersConnection } = data.project || data;
        const folders = foldersConnection.edges.map(({ node }) => node);

        this.$store.commit("documentUploadModal/setFolders", { folders });
        return folders;
      },
      fetchPolicy: "no-cache"
    }
  }
};
</script>
