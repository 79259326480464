<template lang="pug">
  app-panel(secondary class="w-1/2")
    app-header(size="h3") Users
      template(#subheader)
        | Permissions for managing your team

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")
      template(#canManage)
        app-checkbox.mb-6(label="View users" :value="true")
        app-checkbox.mb-6(label="Edit existing users" :value="permissions.editUsers" @input="permissionChanged('editUsers', $event)")
        app-checkbox.mb-6(label="Delete users" :value="permissions.removeUsers" @input="permissionChanged('removeUsers', $event)")
        app-checkbox(label="Create new users" :value="permissions.createUsers" @input="permissionChanged('createUsers', $event)")
</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> access users",
          value: "cannotManage"
        },
        {
          label: "<strong>Can</strong> access users",
          value: "canManage",
          slot: "canManage"
        }
      ],
      selected: "cannotManage",
      permissions: {
        viewUsers: false,
        createUsers: false,
        editUsers: false,
        removeUsers: false
      }
    };
  },
  computed: {
    selectedPermissions() {
      if (!this.permissions.viewUsers) {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: "global"
      };
    }
  },
  mounted() {
    if (this.value.permissions.viewUsers) {
      this.selected = "canManage";
    }
  },
  methods: {
    optionChanged(value) {
      this.permissions.viewUsers = value === "canManage";
      this.selected = value;
      this.notifyChange();
    },
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
