<template lang="pug">
  app-panel(secondary)
    app-header(size="h3") Timesheet entries
      template(#subheader)
        | Permissions for financial timesheet entries

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")
      template(#options)
        app-checkbox.mb-6(label="View timesheet entries" :value="true")
        app-checkbox.mb-6(label="Edit existing timesheet entries" :value="permissions.editTimesheetEntry" @input="permissionChanged('editTimesheetEntry', $event)")
        app-checkbox.mb-6(label="Delete timesheet entries" :value="permissions.removeTimesheetEntry" @input="permissionChanged('removeTimesheetEntry', $event)")
        app-checkbox.mb-6(label="Create new timesheet entries" :value="permissions.createTimesheetEntry" @input="permissionChanged('createTimesheetEntry', $event)")

</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> access project timesheet entries",
          value: "cannotManage"
        },
        {
          label:
            "Can access <strong>assigned project</strong> timesheet entries",
          value: "canManageAssigned",
          slot: "options"
        },
        {
          label: "Can access <strong>any project</strong> timesheet entries",
          value: "canManageAny",
          slot: "options"
        }
      ],
      selected: "cannotManage",
      permissions: {
        viewTimesheetEntry: false,
        createTimesheetEntry: false,
        editTimesheetEntry: false,
        removeTimesheetEntry: false
      },
      scope: "global"
    };
  },
  computed: {
    selectedPermissions() {
      if (this.selected === "cannotManage") {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: this.scope
      };
    }
  },
  mounted() {
    if (this.value.permissions.viewTimesheetEntry) {
      if (this.value && this.value.scope === "project") {
        this.selected = "canManageAssigned";
        this.scope = "project";
      } else {
        this.selected = "canManageAny";
        this.scope = "global";
      }
    }
  },
  methods: {
    optionChanged(value) {
      this.permissions.viewTimesheetEntry = value !== "cannotManage";
      this.scope = value === "canManageAssigned" ? "project" : "global";
      this.selected = value;
      this.notifyChange();
    },
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
