<template lang="pug">
  .pdf-viewer.flex.flex-col.w-full
    .toolbar.p-4.bg-grey-20.rounded-t-lg.border.border-grey-30
      pdf-zoom(
        :scale="scale"
        @input="updateScale")

    pdf-data.pdf-viewer__main(
      :url="url"
      @page-count="updatePageCount"
      @page-focus="updateCurrentPage"
      @document-rendered="onDocumentRendered"
      @document-errored="onDocumentErrored")

      template(#document="{ pages }")
        pdf-document.pdf-viewer__document(
          v-bind="{pages, scale, optimalScale, fit, currentPage, pageCount, isPreviewEnabled}"
          @scale-change="updateScale")

</template>

<script>
// import PreviewIcon from "../assets/icon-preview.svg";

import PdfDocument from "./PDFDocument";
import PdfData from "./PDFData";
import PdfZoom from "./PDFZoom";
// import PDFPaginator from "./PDFPaginator";
// import PDFPreview from "./PDFPreview";
// import PDFZoom from "./PDFZoom";

function floor(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.floor(value * multiplier) / multiplier;
}

export default {
  name: "PDFViewer",

  components: {
    PdfDocument,
    PdfData,
    PdfZoom
    // PDFPaginator,
    // PDFPreview,
    // PDFZoom,
    // PreviewIcon
  },

  props: {
    url: String
  },

  data() {
    return {
      scale: 1.0,
      optimalScale: undefined,
      fit: undefined,
      currentPage: 1,
      pageCount: undefined,
      isPreviewEnabled: false
    };
  },

  watch: {
    url() {
      this.currentPage = undefined;
    }
  },

  mounted() {
    document.body.classList.add("overflow-hidden");
  },

  methods: {
    onDocumentRendered() {
      this.$emit("document-errored", this.url);
    },

    onDocumentErrored(e) {
      this.$emit("document-errored", e);
    },

    updateScale({ scale, isOptimal = false }) {
      const roundedScale = floor(scale, 2);
      if (isOptimal) this.optimalScale = roundedScale;
      this.scale = roundedScale;
    },

    updateFit(fit) {
      this.fit = fit;
    },

    updatePageCount(pageCount) {
      this.pageCount = pageCount;
    },

    updateCurrentPage(pageNumber) {
      this.currentPage = pageNumber;
    },

    togglePreview() {
      this.isPreviewEnabled = !this.isPreviewEnabled;
    }
  }
};
</script>

<style scoped>
.pdf-viewer__document {
  width: 100%;
  left: 0;
  top: 50px;
}

@media print {
  header {
    display: none;
  }
}
</style>
