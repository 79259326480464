<script>
export default {
  functional: true,
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  render(h, { props, slots }) {
    if (!props.loading) {
      return slots().default;
    }

    const ring = h("div", { staticClass: "ring" });
    const circle = h("div", { staticClass: "circle" }, [ring]);
    return h("div", { staticClass: "page loader" }, [circle]);
  }
};
</script>
