<template lang="pug">
  div
    .page.loader.text-center.page-loader(v-if="loading")
      .circle
        .ring
    .checkout-box.text-grey-60(:class="loading ? 'opacity-25' : ''")
      .checkout-box-content
        app-panel.mb-4
          app-header
            | Billing information
            template(#subheader) Please apply the billing details for your payment method

          app-text-field(name="firstName",
                        class="mb-2 mt-12",
                        label="First name",
                        type="text",
                        v-model="checkout.billingForename",
                        :validator="$v.checkout.billingForename",
                        :messages="messages",
                        @keyup.enter="updateBillingInfo",
                        @blur="validateField('billingForename')",
                        data-cy="firstName-input")

          app-text-field(name="lastName",
                        class="my-2",
                        label="Last name",
                        type="text",
                        :validator="$v.checkout.billingSurname",
                        :messages="messages",
                        v-model="checkout.billingSurname",
                        @keyup.enter="updateBillingInfo",
                        @blur="validateField('billingSurname')",
                        data-cy="lastName-input")

          app-dropdown-field(name="country",
                            label="Country",
                            value-attr="code",
                            label-attr="name",
                            v-model="checkout.country",
                            :validator="$v.checkout.country",
                            :messages="messages",
                            :options="availableCountries",
                            data-cy="country-input",
                            @input="$v.checkout.country.$touch()")

          app-text-field(name="address1",
                        class="my-2",
                        label="Address 1",
                        type="text",
                        :validator="$v.checkout.billingAddress1",
                        :messages="messages",
                        v-model="checkout.billingAddress1",
                        @keyup.enter="updateBillingInfo",
                        @blur="validateField('billingAddress1')"
                        data-cy="address1-input")

          app-text-field(name="address2",
                        class="my-2",
                        label="Address 2",
                        type="text",
                        :validator="$v.checkout.billingAddress2",
                        :messages="messages",
                        v-model="checkout.billingAddress2",
                        @keyup.enter="updateBillingInfo",
                        @blur="validateField('billingAddress2')"
                        data-cy="address2-input")

          app-text-field(name="citry",
                      class="my-2",
                      label="City",
                      type="text",
                      :validator="$v.checkout.billingAddressCity",
                      :messages="messages",
                      v-model="checkout.billingAddressCity",
                      @keyup.enter="updateBillingInfo",
                      @blur="validateField('billingAddressCity')",
                      data-cy="city-input")

          app-text-field(name="postalCode",
                        class="my-2",
                        label="Postal code",
                        type="text",
                        :validator="$v.checkout.billingAddressPostcode",
                        :messages="messages",
                        v-model="checkout.billingAddressPostcode",
                        @keyup.enter="updateBillingInfo",
                        @blur="validateField('billingAddressPostcode')",
                        data-cy="postalCode-input")
        app-panel
          app-header
            | Company tax information
            template(#subheader) Only applicable to companies

          app-text-field(name="companyNumber",
                        class="my-2",
                        label="Company number",
                        type="text",
                        v-model="taxDetail.companyNumber",
                        @keyup.enter="updateTaxInfo",
                        data-cy="companyNumber-input")

          app-radio-field.mt-8(name="option",
                              label="Do you have a valid tax number?",
                              :options="options",
                              v-model="checkout.taxOption",
                              data-cy="taxOption-switch")

          template(v-if="checkout.taxOption == 1")
            app-text-field.mt-8(name="taxNumber",
                              class="my-2",
                              label="Tax number",
                              type="text",
                              v-model="taxNumber",
                              :validator="$v.taxNumber",
                              :messages="messages",
                              @keyup.enter="updateTaxInfo",
                              data-cy="taxNumber-input")
      .checkout-box-sidebar.mt-4(class="md:mt-0")
        checkout-sidebar(v-model="checkout" @submit="goToNext()")
</template>
<script>
import { required, maxLength } from "vuelidate/lib/validators";
import CheckoutWatchers from "@/mixins/CheckoutWatchers";
import CountryQuery from "@/graphql/queries/core/company/AvailableCountries.gql";
import UpdateBillingInfo from "@/graphql/mutations/subscriptions/UpdateBillingInfo.gql";
import UpdateTaxInfo from "@/graphql/mutations/subscriptions/UpdateTaxInfo.gql";
import {
  BILLING_INPUT_TYPE_ERROR,
  TAX_INPUT_TYPE_ERROR
} from "@/config/help_messages/SubscriptionMessages";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import CheckoutSidebar from "./CheckoutSidebar.vue";

export default {
  name: "CheckoutBilling",
  components: {
    CheckoutSidebar
  },
  mixins: [CheckoutWatchers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      checkout: { ...this.value },
      title: "billing",

      // Tax details
      taxDetail: {
        companyNumber: this.value.companyNumber
      },
      country: this.value.billingAddressCountry
        ? this.value.billingAddressCountry.code
        : null,
      taxNumber: this.value.taxNumber,
      options: [
        {
          name: "Yes",
          value: 1
        },
        {
          name: "No",
          value: 0
        }
      ],
      messages: {
        validField: "This field is invalid",
        maxLength: "{label} must contain less than {max} characters",
        required: "{label} is required"
      },
      isTaxInvalid: false,
      loading: false
    };
  },
  apollo: {
    availableCountries: CountryQuery
  },
  validations() {
    return {
      checkout: {
        billingForename: { required, maxLength: maxLength(30) },
        billingSurname: { required, maxLength: maxLength(30) },
        billingAddress1: { required },
        billingAddressPostcode: { required, maxLength: maxLength(10) },
        billingAddressCity: { required },
        country: { required }
      },
      taxNumber: {
        required,
        validField: function() {
          return !this.isTaxInvalid;
        }
      },
      companyNumber: { required, maxLength: maxLength(30) }
    };
  },
  methods: {
    validateField(fieldName) {
      if (this.$v.checkout[fieldName]) {
        this.$v.checkout[fieldName].$touch();
      }
    },
    updateBillingInfo() {
      this.$v.$touch();
      if (this.$v.checkout.$invalid) {
        this.$flash.error(BILLING_INPUT_TYPE_ERROR);
      } else {
        if (this.checkout.taxOption === 1) {
          this.$v.$touch();
          if (this.$v.taxNumber.$invalid) {
            this.$flash.error(TAX_INPUT_TYPE_ERROR);
            return;
          }
        }

        const {
          billingForename,
          billingSurname,
          billingAddress1,
          billingAddressPostcode,
          billingAddressCity,
          country
        } = this.checkout;

        this.loading = true;
        this.$apollo
          .mutate({
            mutation: UpdateBillingInfo,
            variables: {
              input: {
                billingForename,
                billingSurname,
                billingAddress1,
                billingAddressPostcode,
                billingAddressCity,
                billingAddressCountry: {
                  code: country,
                  name: country
                }
              }
            }
          })
          .then(({ data: { updateBillingInfo } }) => {
            this.checkout = {
              ...this.checkout,
              ...updateBillingInfo.subscription,
              billingAddressCountry: {
                code: country,
                name: country
              }
            };

            this.updateTaxInfo();
          })
          .catch(error => {
            this.loading = false;
            this.$flash.error(gqlErrorMessage(error));
          });
      }
    },
    updateTaxInfo() {
      if (this.$v.checkout.$invalid) {
        this.$flash.error(BILLING_INPUT_TYPE_ERROR);
      } else {
        if (this.checkout.taxOption === 1) {
          this.$v.$touch();
          if (this.$v.taxNumber.$invalid) {
            this.$flash.error(TAX_INPUT_TYPE_ERROR);
            return;
          }
        }

        const input =
          this.checkout.taxOption === 1
            ? {
                ...this.taxDetail,
                country: this.checkout.country,
                taxNumber: this.taxNumber
              }
            : {
                companyNumber: this.taxDetail.companyNumber,
                country: this.checkout.country,
                taxNumber: null
              };

        this.$apollo
          .mutate({
            mutation: UpdateTaxInfo,
            variables: { input }
          })
          .then(({ data: { updateTaxInfo } }) => {
            if (updateTaxInfo.status) {
              this.checkout.companyNumber = input.companyNumber;
              this.checkout.taxNumber = input.taxNumber;

              if (updateTaxInfo.taxRate) {
                this.checkout.taxRate = updateTaxInfo.taxRate;
              }

              this.loading = false;
              this.isTaxInvalid = false;
              this.$emit("submit");
            } else {
              this.loading = false;
              this.$flash.error(updateTaxInfo.message);
              this.isTaxInvalid = true;
              this.$v.$touch();
            }
          })
          .catch(error => {
            this.loading = false;
            this.$flash.error(gqlErrorMessage(error));
          });
      }
    },
    goToNext() {
      this.$v.checkout.$touch();

      if (this.checkout.taxOption === 1) {
        this.$v.$touch();
      } else {
        this.$v.taxNumber.$reset();
      }

      this.updateBillingInfo();
    }
  }
};
</script>
<style lang="postcss" scoped>
.page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
}
</style>
