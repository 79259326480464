<template lang="pug">
  app-panel.custom-fields.mb-4(secondary)
    template(#header)
      .flex.flex-row.justify-between.cursor-pointer(@click="toggleExpanded")
        app-header(size="h3" :margin="expanded ? 'mb-8' : ''") Custom fields
        app-icon(:icon="collapseIcon")

    template(#default)
      .flex.flex-col(v-show="expanded")
        task-type(v-if="taskType" :task="resource")

        .block(v-for="(resourceField, idx) in resourceFields" :key="fieldsKeys(resourceField)")
          custom-field(:resource="resource" :value="resourceField" :resource-type="resourceType" :not-used-custom-fields="notUsedCustomFields" :custom-fields="customFields"
                       @saved="resourceFieldUpdated" @remove="removeResourceField")

        .border.border-grey-30.bg-white.p-3(v-if="newResourceField")
          custom-field(:resource="resource" :value="newResourceField" :resource-type="resourceType" :is-new="true" :not-used-custom-fields="notUsedCustomFields"
                       :custom-fields="customFields" @saved="resourceFieldCreated" @cancel="newResourceField = null")
        .mt-4(v-else-if="resource.canEdit")
          app-button(primary slim @click="addResourceField") Add custom field

</template>

<script>
import uuid from "uuid";
import { parse } from "date-fns";
import clone from "ramda/src/clone";
import sortBy from "ramda/src/sortBy";
import prop from "ramda/src/prop";

import CustomField from "./elements/CustomField.vue";
import TaskType from "./elements/TaskType.vue";

import { formatDateApi } from "@/helpers/DateHelpers";

import CustomFields from "@/graphql/queries/core/company/CustomFields.gql";
import CollapsableSection from "@/mixins/CollapsableSection.js";

export default {
  components: {
    CustomField,
    TaskType
  },

  mixins: [CollapsableSection],

  props: {
    resource: {
      type: Object,
      required: true
    },
    taskType: {
      type: Boolean,
      required: true
    },
    resourceType: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      editing: false,
      customFields: [],
      expanded: this.resource?.resourceFields?.length ? true : null,
      resourceFields: clone(this.resource.resourceFields),
      newResourceField: null
    };
  },

  apollo: {
    customFields: {
      query: CustomFields,

      variables() {
        return {
          scope: ["all", "task"]
        };
      },

      update({ customFields }) {
        const sortByName = sortBy(prop("name"));
        return sortByName(customFields.edges.map(({ node }) => node));
      }
    }
  },

  computed: {
    fieldsKeys() {
      const keys = new WeakMap();

      return resourceField => {
        let key = keys.get(resourceField);

        if (!key) {
          key = uuid();
          keys.set(resourceField, key);
        }

        return key;
      };
    },

    notUsedCustomFields() {
      const alreadyUsedCustomFields = this.resourceFields.map(
        rf => rf.customField.id
      );

      return this.customFields.filter(cf => {
        return !alreadyUsedCustomFields.some(
          customFieldId => customFieldId == cf.id
        );
      });
    },

    customFieldName() {
      const names = {};
      this.customFields.forEach(f => (names[f.id] = f.name));

      return names;
    }
  },

  methods: {
    customFieldComponent(resourceField) {
      const { customFieldId } = resourceField;
      const fieldType = this.fieldType(customFieldId);

      if (this.isList(customFieldId)) {
        return "AppDropdownField";
      } else if (fieldType == "DateField" || fieldType == "date") {
        return "AppDateField";
      } else {
        return "AppTextField";
      }
    },

    addResourceField() {
      this.newResourceField = {
        resourceId: this.resource.id,
        resourceType: this.resourceType,
        customField: {},
        valueForInput: null
      };
    },

    resourceFieldUpdated(resourceField) {
      const idx = this.resourceFields.findIndex(
        rf => rf.id === resourceField.id
      );
      this.resourceFields.splice(idx, 1, resourceField);
    },

    resourceFieldCreated(resourceField) {
      this.resourceFields.push(resourceField);
      this.newResourceField = null;
    },

    formatValue(field, value) {
      if (value) {
        const type = this.fieldType(field.customFieldId);

        if (type === "date" || type === "DateField") {
          value = formatDateApi(parse(value, "yyyy/MM/dd", new Date()));
        }
      }

      field.valueForInput = value;
    },

    removeResourceField(fieldId) {
      const idx = this.resourceFields.findIndex(rf => rf.id === fieldId);
      this.resourceFields.splice(idx, 1);
    }
  }
};
</script>
