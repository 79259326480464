<template lang="pug">
  app-panel.panel.subscription-card
    h2.font-bold Your Usage
    .mt-4
      div.text-grey-60 User licenses
      .flex.flex-row.justify-between
        .flex-1
          span.font-bold {{subscription.usedLicenses}}
        .flex-initial.text-grey-60 {{licenseRemaingLabel}}

      .progress.text-center
        app-progress.custom-progress(:progress="progressVal", :colour="colours")

    .mt-4
      div.text-grey-60 Document Storage
      .flex.flex-row.justify-between
        .flex-1
          span.font-bold {{parseFloat(storageUsed)}}GB
        .flex-initial.text-grey-60 {{storageLimits}}

      .progress.text-center
        app-progress.custom-progress(:progress="storageProgressVal", :colour="colours")

    .mt-4
      div.text-grey-60 Active projects
      .flex.flex-row.justify-between
        .flex-1
          span.font-bold {{company.projects}}
        .flex-initial.text-grey-60 {{maxProjects}}

      .progress.text-center
        app-progress.custom-progress(:progress="projectProgressVal", :colour="projectColours")
</template>

<script>
import { initCap } from "@/helpers/StringHelpers";

export default {
  name: "SubscriptionUsage",
  props: {
    subscription: {
      type: Object,
      required: true
    },
    additionalLicenses: {
      type: Number,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    licensesRemaining: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      usedStorage: 0,
      colours: {
        25: "green",
        75: "yellow",
        100: "red"
      },
      unlimitedColours: {
        25: "red",
        75: "yellow",
        100: "blue"
      },
      max: 100
    };
  },
  computed: {
    progressVal() {
      const { userLicenses } = this.subscription;
      return 100 - (this.licensesRemaining / userLicenses) * 100;
    },
    storageProgressVal() {
      const { storageLimits, storageUsed } = this.subscription;
      return (storageUsed / storageLimits) * 100;
    },
    projectProgressVal() {
      const { maxProjects } = this.subscription;
      const companyProjectCount = parseInt(this.company.projects);
      const maxProjectCount = parseInt(maxProjects);

      if (!maxProjects.includes("unlimited")) {
        return companyProjectCount === maxProjectCount
          ? 100
          : 100 - (companyProjectCount / maxProjectCount) * 100;
      }
      return 100;
    },
    maxProjects() {
      const { maxProjects } = this.subscription;
      if (!maxProjects.includes("unlimited")) {
        return parseInt(this.company.projects) === parseInt(maxProjects)
          ? "0 remaining"
          : `${parseInt(maxProjects) -
              parseInt(this.company.projects)} remaining`;
      }

      return initCap(maxProjects);
    },
    projectColours() {
      const { maxProjects } = this.subscription;
      return !maxProjects.includes("unlimited")
        ? this.colours
        : this.unlimitedColours;
    },
    storageLimits() {
      const { storageLimits, storageUsed } = this.subscription;
      return (
        parseFloat(
          ((storageLimits - storageUsed) / Math.pow(1024, 3)).toFixed(2)
        ) + "GB remaining"
      );
    },
    storageUsed() {
      const { storageUsed } = this.subscription;
      return (storageUsed / Math.pow(1024, 3)).toFixed(2);
    },
    licenseRemaingLabel() {
      return this.licensesRemaining === 1
        ? `${this.licensesRemaining} license remaining`
        : `${this.licensesRemaining} licenses remaining`;
    }
  },
  methods: {
    onSubmit() {
      this.$emit("submit");
    }
  }
};
</script>
