<template lang="pug">
  div.addon-tab
    div.addon-tab-content
      app-panel.panel.addon-item.history-panel(v-for="(item, index) in paymentHistory" :key="index" secondary)
        .flex.flex-row.justify-between.flex-initial.mb-3
          .flex-1
            .text-grey-80.font-bold {{convertDate(item.created)}}
          .flex.flex-initial
            template(v-if="item.status == 'paid'")
              app-label(colour="green") Paid
            template(v-else)
              app-label(colour="red") Declined

        .flex.flex-row.justify-between.flex-initial
          .flex-1
            credit-card(:last-digits="cardData.last4", :show-image="true", :brand="cardData.brand")
          .flex.flex-initial.pl-2
            span.text-xl.font-bold {{item.price}}

</template>

<script>
/* global $D */
/* eslint-disable */
import CreditCard from "@/components/collections/CreditCard";
import { FINANCE_MANAGEMENT } from "@/config/constants";

export default {
  name: "AddOnsTab",
  components: {
    CreditCard
  },
  props: {
    subscription: {
      type: Object,
      required: true
    },
    cardData: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      required: true
    }
  },
  computed: {
    paymentHistory() {
      const { paymentHistory } = this.subscription;
      return paymentHistory ? paymentHistory : [];
    }
  },
  methods: {
    onSubmit() {
      this.$emit(
        "update-feature",
        FINANCE_MANAGEMENT,
        !this.financeManagementStatus
      );
    },
    convertDate(value) {
      return moment(value * 1000).format($D.fn.datepicker.momentDateFormat);
    }
  }
};
</script>

<style lang="postcss" scoped>
.history-panel {
  padding: 1rem;
}
.payment-status {
  display: inline;
  height: min-content;
  border-radius: 5px;

  &.success {
    background-color: #6dffb1;
    color: #177d0c;
  }

  &.warning {
    background-color: var(--breezy-pearl);
  }
}
</style>
