<template lang="pug">
  .h-full.flex.flex-col.overflow-hidden
    projects-table(:views="views")
      template(#buttons v-if="permissions.canCreate")
        app-button.ml-4(primary @click="addProject") Add project

      template(#overview="{ visible, searchTerm }")
        projects-overview-gantt(:is-visible="visible", :search="searchTerm")
</template>

<script>
import ProjectsOverviewGantt from "@/components/projects/index/ProjectsOverviewGantt.vue";
import ProjectsTable from "@/components/projects/ProjectsTable.vue";
import gql from "graphql-tag";

export default {
  components: {
    ProjectsOverviewGantt,
    ProjectsTable
  },
  apollo: {
    permissions: {
      query: gql`
        query projects {
          projects {
            canCreate
          }
        }
      `,
      update({ projects }) {
        return {
          canCreate: projects.canCreate
        };
      }
    }
  },
  data() {
    return {
      permissions: {
        canCreate: false
      },
      views: [
        { value: "table", label: "Table", icon: "table" },
        {
          value: "overview",
          label: "Overview",
          icon: "gantt",
          slot: "overview",
          wide: true
        }
      ]
    };
  },
  methods: {
    addProject() {
      window.location.href = "/projects/new";
    }
  }
};
</script>
