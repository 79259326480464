/**
 *  Comparator function for sorting date columns
 **/
const dateComparator = (a, b) => {
  if (a === null && b === null) {
    return 0;
  }
  if (a === null) {
    return -1;
  }
  if (b === null) {
    return 1;
  }

  return new Date(a) - new Date(b);
};

const STATUS_ORDER = {
  NOT_STARTED: 0,
  STARTED: 1,
  COMPLETE: 2
};

const PRIORITY_ORDER = {
  LOW: 0,
  NORMAL: 1,
  HIGH: 2,
  URGENT: 3
};

/**
 * Comparator function for sorting status columns. These are sorted
 * in the logic order, rather than alphabetical
 **/
export const statusComparator = (a, b) => {
  return STATUS_ORDER[a] - STATUS_ORDER[b];
};

/**
 * Comparator function for sorting priority columns. These are sorted
 * in the logici order, rather than alphabetical
 **/
export const priorityComparator = (a, b) => {
  return PRIORITY_ORDER[a] - PRIORITY_ORDER[b];
};

export { dateComparator };
