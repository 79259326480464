/* global gantt */
import moment from "moment";
import { underscore } from "@/helpers/StringHelpers";

export const calculateLag = (source, target, linkType) => {
  let from;
  let to;
  linkType = parseInt(linkType);

  // end to start
  if (linkType === 0) {
    from = source.end_date;
    to = target.start_date;
  }
  // start to start
  else if (linkType === 1) {
    from = source.start_date;
    to = target.start_date;
  }
  // end_to_end
  else if (linkType === 2) {
    from = source.end_date;
    to = target.end_date;
  }
  // start_to_end
  else if (linkType === 3) {
    from = source.start_date;
    to = target.end_date;
  }

  return calculateWorkingDaysBetween(from, to);
};

/**
 * Calculates the number of working days between
 * the two given dates.
 */
export const calculateWorkingDaysBetween = (date1, date2) => {
  if (date1 > date2) {
    return -gantt.calculateDuration(date2, date1);
  } else {
    return gantt.calculateDuration(date1, date2);
  }
};

// checks if id1 is a parent of id2 or vice versa
export const checkParents = (id1, id2) => {
  const chain1 = _parentChain(id1);
  const chain2 = _parentChain(id2);

  return chain1.indexOf(id2) === -1 && chain2.indexOf(id1) === -1;
};

/**
 *  Generates a name the is valid for the PDF that will
 *  be generated by the DHTMLX gantt PDF generator
 **/
export const getPdfName = ({ name }) => {
  return name
    .replace(/[ -]/g, "_")
    .replace(/[,'"~#%&*{}\\:<>?/+|]/g, "")
    .concat(".pdf");
};

export const getTaskStatusClass = task => {
  return _statusToClass(task.status, task.end_date);
};

export const setTaskType = idParam => {
  const id = idParam.id ? idParam.id : idParam;
  const task = gantt.getTask(id);
  const type = gantt.hasChild(task.id)
    ? gantt.config.types.project
    : gantt.config.types.task;

  if (type !== task.type) {
    task.type = type;
    gantt.refreshTask(task.id);
  }
};

export const toggleElementVisibility = (el, visible) => {
  const element = document.getElementById(el);
  element.style.display = visible ? null : "none";
};

export const workingWeekArray = workingWeek => {
  return [
    workingWeek.sun,
    workingWeek.mon,
    workingWeek.tue,
    workingWeek.wed,
    workingWeek.thu,
    workingWeek.fri,
    workingWeek.sat
  ];
};

export const isOverdue = task => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return task.end_date <= today && task.status != "Complete";
};

export const convertGanttTask = task => {
  let converted = {
    id: task.id,
    startsAt: task.start_date,
    deadline: moment(task.end_date)
      .subtract(1, "day")
      .format("YYYY/MM/DD"),
    isMilestone: task.type === "mp_milestone",
    status: underscore(task.status).toUpperCase(),
    priority: task.priority,
    name: task.text
  };
  return converted;
};

const _parentChain = taskId => {
  let chain = [];
  let currentTask = gantt.getTask(taskId);

  while (currentTask) {
    chain.push(currentTask.id);

    if (currentTask.parent) {
      currentTask = gantt.getTask(currentTask.parent);
    } else {
      currentTask = false;
    }
  }

  return chain;
};

const _statusToClass = (status, end_date) => {
  let new_status;

  if (!status) {
    return "";
  }

  let today = new Date();
  today.setHours(0, 0, 0, 0);

  if (end_date <= today && status != "Complete") {
    new_status = status.toLowerCase().replace(" ", "_");
    return new_status + " overdue";
  }

  return status.toLowerCase().replace(" ", "_");
};
