import { runMutation } from "@/helpers/GraphQLHelpers";
import DeleteContacts from "@/graphql/mutations/contact_management/contacts/deleteContacts.gql";
import RenameContact from "@/graphql/mutations/contact_management/contacts/renameContact.gql";
import CreateGroup from "@/graphql/mutations/contact_management/contacts/CreateContactGroup.gql";
import RenameGroup from "@/graphql/mutations/contact_management/contacts/RenameContactGroup.gql";
import DeleteGroup from "@/graphql/mutations/contact_management/contacts/DeleteContactGroup.gql";
import AddContactsToGroups from "@/graphql/mutations/contact_management/contacts/AddContactsToGroups.gql";
import RemoveFromGroup from "@/graphql/mutations/contact_management/contacts/RemoveContactsFromGroup.gql";

export default class ContactManager {
  /**
   *  Assigns one or more contacts to one or more contact
   *  groups
   **/
  assignToGroups(contacts, groups) {
    const contactIds = contacts.map(c => ({ id: c.id }));
    const groupIds = groups.map(g => ({ id: g.id }));

    return runMutation(AddContactsToGroups, {
      contacts: contactIds,
      contactGroups: groupIds
    });
  }

  /**
   * Creates a new contact group with the given name
   */
  createGroup(name) {
    return runMutation(CreateGroup, { name });
  }

  /**
   *  Deletes one or more specified contats
   **/
  delete(contacts) {
    const contactIds = contacts.map(contact => contact.id);

    return runMutation(DeleteContacts, { id: contactIds });
  }

  deleteGroup(group) {
    return runMutation(DeleteGroup, {
      contactGroup: {
        id: group.id
      }
    });
  }

  rename(contact, newName) {
    return runMutation(RenameContact, {
      contact: {
        id: contact.id
      },
      name: newName
    });
  }

  renameGroup(group, newName) {
    return runMutation(RenameGroup, {
      contactGroup: {
        id: group.id
      },
      name: newName
    });
  }

  /**
   * Removes the specified contacts from the specified
   * contact group
   **/
  removeFromGroup(group, contacts) {
    const contactIds = contacts.map(contact => ({ id: contact.id }));

    return runMutation(RemoveFromGroup, {
      contactGroup: {
        id: group.id
      },
      contacts: contactIds
    });
  }
}
