<template lang="pug">
  div
    .checkout-progress.z-40.bg-white.border-b.border-grey-40.px-0(class="sm:px-8")
      FormProgress.progress-bar.w-full(@step-clicked="setCurrentStep" class="md:w-1/2")
    .flex.justify-center(v-if="type == 'CANCELLED'")
      app-panel.deprecated.mb-4.mt-8
        div
          h2.font-bold Your subscription has been cancelled.
          h3.text-grey-60 Select a plan to continue using your account
        .my-12
          span As requested your subscription has been cancelled, however, you can set up a new subscription if you’d like to continue working on your projects.
        app-button(primary
            class="text-center focus:outline-none focus:shadow-outline"
            @click.prevent="changeType()"
          ) Get started
    .flex.justify-center(v-else-if="type == 'UNPAID' || type == 'OVERDUE'")
      app-panel.deprecated.mb-4.mt-8
        div
          h2.font-bold Subscription suspended
          h3.text-grey-60 Your account is overdue
        .my-12
          span We have suspended your account due to unpaid bills. We have repeatedly attempted to collect the payment but have been unsuccessful every time.&nbsp;
            | Please update your payment details to continue accessing your account.
        app-button(primary
            class="text-center focus:outline-none focus:shadow-outline"
            @click.prevent="changeType('Billing')"
          ) Get started
    .flex.justify-center(v-else-if="type == 'TRIAL_EXPIRED' || type == 'EXPIRED'")
      app-panel.deprecated.mb-4.mt-8
        div
          h2.font-bold Your trial expired
          h3.text-grey-60 Select a plan to continue using your account
        .my-12
          span Your free trial has come to an end, but you can continue using your account by setting up a subscription today.
        app-button(primary
            class="text-center focus:outline-none focus:shadow-outline"
            @click.prevent="changeType()"
          ) Get started
    template(v-else)
      .pt-8.form-content.checkout-page.h-full(ref="grid")
        app-loader(:loading="loading")
          form-wrapper.h-full(:validator="$v.checkout")
            form.h-full(@submit.prevent="goNext")
              keep-alive
                .flex.mx-4.mt-8.checkout-wrapper.justify-center(class="sm:mx-8 md:mx-auto")
                  component(:is="currentComponent"
                    v-model="checkout" @cancel="cancel" @submit="goNext")

</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { templates } from "vuelidate-error-extractor";
import FormProgress from "@/components/elements/FormProgress.vue";
import CheckoutPlan from "@/components/checkouts/wizard/CheckoutPlan.vue";
import CheckoutAddons from "@/components/checkouts/wizard/CheckoutAddons.vue";
import CheckoutBilling from "@/components/checkouts/wizard/CheckoutBilling.vue";
import CheckoutPayment from "@/components/checkouts/wizard/CheckoutPayment.vue";
import WizardStepsMixin from "@/mixins/WizardSteps.js";
import SubscriptionQuery from "@/graphql/queries/subscription/Subscription.gql";

export default {
  name: "CheckoutPage",
  components: {
    FormWrapper: templates.FormWrapper,
    FormProgress,
    CheckoutPlan,
    CheckoutAddons,
    CheckoutBilling,
    CheckoutPayment
  },

  mixins: [WizardStepsMixin],

  props: {
    status: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      resourceType: "checkout",
      loading: true,
      checkout: {
        isSidebarOpen: true,
        noSidebarPadding: true,
        currency: "$",
        userLicenses: 12,
        userLicensePrice: 29,
        userLicenseAnnualPrice: 276,
        userAnnualPrice: 0,
        financeManagementStatus: false,
        financeManagementPrice: 35,
        financeManagementAnnualPrice: 35 * 12,
        documentStoragePrice: 0,
        documentStorageLimit: 0,
        documentStorageUnit: 50,
        usedLicenses: 0,
        taxRate: { name: "VAT", rate: 20.0, reverseCharge: false },
        voucher: null,
        billingType: {
          label: "Bill annually",
          value: "a"
        },
        billingOptions: [
          {
            label: "Bill annually",
            value: "a"
          },
          {
            label: "Bill monthly",
            value: "m"
          }
        ],
        checkoutType: "Free",
        isAddonValid: false,
        country: null,
        status: "TRIAL",
        numberOfCompanyUsers: 1,
        plan: {
          name: "Pro"
        },
        checkoutStatus: "TRIAL",
        taxOption: 1
      },
      skipQuery: false
    };
  },

  apollo: {
    subscription: {
      query: SubscriptionQuery,
      manual: true,
      result(res) {
        if (res.data && res.data.subscription) {
          const newValue = res.data.subscription;

          this.checkout = {
            ...this.checkout,
            userLicensePrice: newValue.userLicensePrice,
            financeManagementPrice: newValue.financeManagementPrice,
            financeManagementAnnualPrice: newValue.financeManagementAnnualPrice,
            isTrial: newValue.isTrial,
            isStripeSubscription: newValue.isStripeSubscription,
            exceedsLimits: newValue.exceedsLimits,
            billingForename: newValue.billingForename,
            billingSurname: newValue.billingSurname,
            billingEmail: newValue.billingEmail,
            billingAddress1: newValue.billingAddress1,
            billingAddress2: newValue.billingAddress2,
            billingAddressCity: newValue.billingAddressCity,
            billingAddressRegion: newValue.billingAddressRegion,
            billingAddressPostcode: newValue.billingAddressPostcode,
            billingAddressCountry: newValue.billingAddressCountry,
            country: newValue.billingAddressCountry
              ? newValue.billingAddressCountry.code
              : null,
            gatewayKey: newValue.stripePublishKey,
            currency: newValue.currency,
            userLicenses: newValue.numberOfCompanyUsers
              ? newValue.numberOfCompanyUsers
              : 1,
            documentStoragePrice: newValue.documentStoragePrice,
            usedLicenses: newValue.usedLicenses,
            userLicenseAnnualPrice: newValue.userLicenseAnnualPrice,
            taxRate: newValue.taxRate,
            status: newValue.trialExpired ? "TRIAL_EXPIRED" : newValue.status,
            numberOfCompanyUsers: newValue.numberOfCompanyUsers,
            plan: newValue.plan,
            taxNumber: newValue.taxNumber,
            companyNumber: newValue.companyNumber
          };

          if (!this.skipQuery) {
            this.checkout.checkoutStatus = this.checkout.status;
            this.skipQuery = true;
          }
          const { status } = this.checkout;
          if (status === "OVERDUE" || status === "UNPAID") {
            this.$store.commit("setStepsList", "checkoutOverdueSteps");
          }
        } else {
          this.$flash.error(res.error);
        }

        this.loading = false;
      },
      error(error) {
        this.loading = false;
        this.$flash.error(`Unable to checkout - ${error.message}`);
      }
    }
  },

  validations() {
    return {
      checkout: {
        billingForename: { required, maxLength: maxLength(30) },
        billingSurname: { required, maxLength: maxLength(30) },
        billingAddress1: { required },
        billingAddressPostcode: { required, maxLength: maxLength(10) },
        billingAddressCity: { required }
      }
    };
  },

  computed: {
    type() {
      return this.checkout.checkoutStatus.toUpperCase();
    },
    steps() {
      const steps = this.wizardState.steps;
      return steps;
    },
    stepComponent() {
      return {
        Plan: "CheckoutPlan",
        "Add-ons": "CheckoutAddons"
      };
    },
    validatedFields() {
      return {
        Billing: [
          "billingForename",
          "billingSurname",
          "billingAddress1",
          "billingAddressPostcode",
          "billingAddressCity"
        ]
      };
    }
  },

  watch: {
    checkout: {
      deep: true,
      handler() {
        this.findFailedStepsBefore(this.currentStep);
      }
    }
  },

  beforeCreate() {
    this.$store.commit("clearWizardState");
    this.$store.commit("setStepsList", "checkoutWizardSteps");
  },

  methods: {
    changeType(step = "Plan") {
      this.checkout.checkoutStatus = "ACTIVE";
      this.setCurrentStep(step);
    }
  }
};
</script>

<style lang="postcss" scoped>
.form-content {
  position: absolute;
  top: 9rem;
  right: 0;
  left: 0;
  width: 100%;
}
.checkout-page {
  .grid {
    height: 100%;
    grid-template-rows: auto;
  }
}
.checkout-wrapper {
  max-width: 1000px;
}
.checkout-progress {
  position: fixed;
  top: 4rem;
  left: 0;
  width: 100%;

  .progress-bar {
    width: 765px;
    margin: auto;
    border: none;

    @media (max-width: 1199px) {
      width: 100%;
    }
  }
}
.deprecated {
  max-width: 550px;
}
::v-deep {
  .checkout-box {
    display: block;
    .checkout-box-content,
    .checkout-box-sidebar {
      width: 328px;
      padding-left: 30px;
      padding-right: 30px;
    }
    @media (min-width: 450px) {
      .checkout-box-content,
      .checkout-box-sidebar {
        width: 450px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    @media (min-width: 1023px) {
      display: flex;
      max-width: 1000px;
      .checkout-box-content {
        width: 625px;
        padding-left: 0;
        padding-right: 0;
      }
      .checkout-box-sidebar {
        width: 328px;
        margin-left: 14px;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>
