<template lang="pug">
  app-panel(secondary)
    app-header(size="h3") Project phases
      template(#subheader)
        | Permissions for project phases

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")
      template(#options)
        app-checkbox.mb-6(label="View project phases" :value="true")
        app-checkbox.mb-6(label="Create new project phases" :value="permissions.createProjectPhase" @input="permissionChanged('createProjectPhase', $event)")
        app-checkbox.mb-6(label="Edit project phases", :value="permissions.editProjectPhase" @input="permissionChanged('editProjectPhase', $event)")
        app-checkbox.mb-6(label="Delete project phases" :value="permissions.removeProjectPhase" @input="permissionChanged('removeProjectPhase', $event)")

</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> access project phases",
          value: "cannotManage"
        },
        {
          label: "<strong>Can</strong> access project phases",
          value: "canManage",
          slot: "options"
        }
      ],
      selected: "cannotManage",
      permissions: {
        viewProjectPhase: false,
        createProjectPhase: false,
        removeProjectPhase: false,
        editProjectPhase: false
      }
    };
  },
  computed: {
    selectedPermissions() {
      if (this.selected === "cannotManage") {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: "global"
      };
    }
  },
  mounted() {
    if (this.value.permissions.viewProjectPhase) {
      this.selected = "canManage";
    }
  },
  methods: {
    optionChanged(value) {
      this.permissions.viewProjectPhase = value !== "cannotManage";
      this.selected = value;
      this.notifyChange();
    },
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
