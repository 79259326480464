<template lang="pug">
  .block.my-3
    app-label.capitalize(:colour="color") {{ params.value }}

</template>

<script>
import Vue from "vue";

const COLORS = {
  billed: "green",
  voided: "red",
  submitted: "yellow",
  authorised: "blue",
  draft: "grey"
};

export default Vue.extend({
  computed: {
    color() {
      return COLORS[this.params.value];
    }
  }
});
</script>
