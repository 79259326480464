<template lang="pug">
  app-panel.panel.subscription-card(v-if="!loading")
    .flex
      .flex-1
        h2.font-bold Your licenses
        .flex.flex-row.justify-between.flex-initial.mt-4
          .flex-1
            .flex
              span.font-bold.mr-2.license-title.mb-2 Owner license
              span.text-grey-60(v-if="additionalLicenses > 0") {{numberOfAdditional}}

            .progress.text-center
              app-progress.custom-progress(:progress="progressVal", :colour="colours")
              .text-right
                .text-grey-60.mt-1 {{licenseRemaingLabel}}
          .flex-initial.ml-48
            span.font-bold.text-xl {{currency}}{{licenseTotalPrice}}
            span.ml-1 {{billingTypeLabel}}

        template(v-if="!subscription.isTrial && subscription.plan.name.toLowerCase() != 'free' && subscription.managedByStripe")
          app-button(primary
            class="text-center focus:outline-none focus:shadow-outline mt-5 card-submit"
            @click.prevent="onSubmit"
            data-cy="open-licenses-submit"
          ) Add or remove licenses
</template>

<script>
import { normalizeFloat } from "@/helpers/StringHelpers";

export default {
  name: "SubscriptionLicense",
  props: {
    subscription: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    trialDays: {
      type: Number,
      required: true
    },
    subscriptionFee: {
      type: Number,
      required: true
    },
    additionalLicenses: {
      type: Number,
      required: true
    },
    licensesRemaining: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    userLicenses: {
      type: Number,
      required: true,
      default: 1
    }
  },
  data() {
    return {
      colours: {
        25: "green",
        75: "yellow",
        100: "red"
      }
    };
  },
  computed: {
    planLabel() {
      if (this.subscription.isTrial) {
        return `${this.subscription.plan.name} - Trial`;
      }
      return this.subscription.plan.name;
    },
    progressVal() {
      return 100 - (this.licensesRemaining / this.userLicenses) * 100;
    },
    numberOfAdditional() {
      return this.additionalLicenses !== 1
        ? `+${this.additionalLicenses} additional user licenses`
        : `${this.additionalLicenses} additional user license`;
    },
    licenseRemaingLabel() {
      return this.licensesRemaining !== 1
        ? `${this.licensesRemaining} licenses remaining`
        : `${this.licensesRemaining} license remaining`;
    },
    billingTypeLabel() {
      const { billingFrequency } = this.subscription;
      return billingFrequency === "a" ? "/ year" : "/ month";
    },
    licenseTotalPrice() {
      const { plan, isTrial } = this.subscription;
      if (plan.name.toLowerCase() === "free") return 0;
      return !isTrial
        ? normalizeFloat(this.subscriptionFee * this.userLicenses)
        : "--";
    }
  },
  methods: {
    onSubmit() {
      this.$emit("submit");
    }
  }
};
</script>

<style lang="postcss" scoped>
.license-title {
  line-height: 1.1;
}
</style>
