import compose from "ramda/src/compose";
import join from "ramda/src/join";
import juxt from "ramda/src/juxt";
import toUpper from "ramda/src/toUpper";
import toLower from "ramda/src/toLower";
import head from "ramda/src/head";
import tail from "ramda/src/tail";
import trim from "ramda/src/trim";
/**
 * Converts the first character of a given string
 * to upper case. Example "hello world" would become
 * "Hello wold"
 *
 * @param {*} str The string to capitalise the first letter of
 */
function initCap(str) {
  if (!str) return "";
  return compose(join(""), juxt([compose(toUpper, head), tail]), toLower)(str);
}

function humanize(str) {
  if (!str) return "";
  return initCap(str.replace(/[-_]/, " "));
}

/**
 * Converts a word to it's plural depending on the count
 *
 * @param {str} the word to pluralize
 * @param {count} the number of instances
 * @param {plural} the plural of the word if it's an irregular word
 *         the default is the word + 's'
 */
function pluralize(str, count, plural = str + "s") {
  if (count !== 1) str = plural;
  return `${count} ${str}`;
}

/**
 * Case incensitive compare of two strings
 *
 * @param {str1} the first string
 * @param {str2} the second string
 */
function caseCompare(str1, str2) {
  if (typeof str1 !== "string" || typeof str2 !== "string") return false;
  return toLower(trim(str1)) === toLower(trim(str2));
}

function underscore(str) {
  if (!str) return "";
  let underscored = str.match(/[a-z\d]+|[A-Z]+[^A-Z\s]*/g).join("_");
  return toLower(underscored);
}

function camelcase(str) {
  if (!str) return "";
  let strs = str.match(/[a-z\d]+|[A-Z]+[^A-Z\s]*/g);
  let firstStr = toLower(strs[0]);
  strs = strs.slice(1).map(str => {
    let lower = toLower(str);
    let chars = lower.split("");
    chars[0] = toUpper(chars[0]);
    return chars.join("");
  });

  strs.unshift(firstStr);

  return strs.join("");
}

function truncate(str, length, suffix) {
  return str.length > length ? str.substring(0, length) + suffix : str;
}

/**
 * Converts the month/day into 2 digits
 * Example "7" would become "07"
 *
 * @param {*} input day or month
 */
function normalizeDay(day) {
  return ("0" + Math.ceil(day)).slice(-2);
}

/**
 * Converts number into float with 2 fixed positions
 *
 * @param {*} input number
 */
function normalizeFloat(number) {
  return number
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    .replace(".00", "");
}

window.camelcase = camelcase;

export {
  initCap,
  pluralize,
  caseCompare,
  underscore,
  camelcase,
  humanize,
  truncate,
  normalizeDay,
  normalizeFloat
};
