/**
 * Test whether the provided resource is a folder.
 */
export function isFolder(docOrFolder) {
  return docOrFolder.__typename === "Folder";
}

export function mutationInputIDs(docsAndFolders) {
  let input = {
    folders: [],
    documents: []
  };

  if (!Array.isArray(docsAndFolders)) {
    docsAndFolders = [docsAndFolders];
  }

  docsAndFolders.forEach(docOrFolder => {
    const id = { id: docOrFolder.id };

    if (isFolder(docOrFolder)) {
      input.folders.push(id);
    } else {
      input.documents.push(id);
    }
  });

  return input;
}
