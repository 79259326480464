export default {
  watch: {
    project: {
      deep: true,
      handler(updatedProject) {
        this.$emit("input", updatedProject);
      }
    },

    value: {
      deep: true,
      handler(updatedValue) {
        this.project = updatedValue;
      }
    }
  }
};
