import { formatDateApi, nextWorkingDay } from "@/helpers/DateHelpers";
import store from "@/store";

/**
 * Checks whether a task supposedly has subtasks. This
 * is determined by the `childCount` value. If it's > 0
 * then this will return true, otherwise it'll return false
 **/

export const hasSubtasks = task => {
  return task.childCount > 0;
};

/**
 *  Generates an object of parameters to be used when creating
 *  a new task. Optionally a parent task can be supplied to
 *  ensure the task is made a subtask.
 **/

export const buildTaskParams = (project, parent, opts) => {
  const nextValidDate = formatDateApi(nextWorkingDay(project.workingWeek));

  let params = {
    name: opts.isMilestone ? "New milestone" : "New task",
    startsAt: nextValidDate,
    deadline: nextValidDate,
    projectId: project.id
  };

  if (parent) {
    params.parentId = parent.id;
  }

  return Object.assign({}, params, opts);
};

export function extractTaskFields(rawTask) {
  const {
    id,
    name,
    description,
    project,
    parent,
    taskType,
    priority,
    owner,
    billable,
    dateIsCalculated,
    dateCalculationType,
    startsAt,
    deadline,
    enableReminders,
    dependencies,
    trackingTargets,
    resourceFields,
    status
  } = rawTask;

  const task = {
    id,
    name,
    description,
    priority,
    projectId: project && project.id,
    parentId: parent && parent.id,
    taskTypeId: taskType && taskType.id,
    ownerId: owner && owner.id,
    billable,
    dateIsCalculated,
    dateCalculationType,
    startsAt,
    deadline,
    enableReminders,
    status
  };

  task.dependencyIds = dependencies.map(d => d.id);

  task.trackingTargetsAttributes = trackingTargets.map(
    ({ id, quantity, trackable: { id: trackableId } }) => ({
      id,
      quantity,
      trackableId
    })
  );

  task.resourceFieldsAttributes = resourceFields.map(
    ({ id, valueForInput, customField: { id: customFieldId } }) => ({
      id,
      valueForInput,
      customFieldId
    })
  );

  return task;
}

export const setLockVersion = version => {
  store.commit("taskManagement/setLockVersion", version);
};

export const getLockVersion = () => {
  return store.state.taskManagement.lockVersion;
};
