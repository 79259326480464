<script>
import { Components, Helpers } from "manageplaces-ui-kit";

import ProjectTasks from "@/graphql/queries/core/tasks/ProjectTasks.gql";
import TaskStatusSummaryQuery from "@/graphql/queries/core/projects/ProjectTaskStatusSummary.gql";
import DocumentManager from "@/components/documents/DocumentManager";

import TasksTableNameCellRenderer from "@/components/projects/tasks_table/cell_renderers/ProjectTasksTableNameCellRenderer.vue";
import TasksTableTeamCellRenderer from "@/components/projects/tasks_table/cell_renderers/ProjectTasksTableTeamCellRenderer.vue";
import TaskTableTypeCellRenderer from "@/components/projects/tasks_table/cell_renderers/ProjectTasksTableTypeCellRenderer.vue";
import ExpansionMixin from "@/mixins/projects/ProjectTasksTableExpansionMixin";
import ProjectTaskView from "@/mixins/projects/ProjectTaskViewMixin";
import TaskSidebarMixin from "@/mixins/tasks/TaskSidebarMixin";
import {
  taskMenuItems,
  milestoneMenuItems,
  bulkMenuItems,
  ACTIONS
} from "./ProjectTasksTableContextMenuItems";
import TaskManager from "@/components/projects/tasks_table/TaskManager";
import TaskTree from "@/components/projects/tasks_table/ProjectTasksTableTaskTree";
import priorityOptions from "@/components/projects/tasks_table/cell_editors/ProjectTasksTablePriorityCellEditorOptions";
import {
  taskStatusOptions,
  milestoneStatusOptions
} from "@/components/projects/tasks_table/cell_editors/ProjectTasksTableStatusCellEditorOptions";
import taskTypeOptions from "@/components/projects/tasks_table/cell_editors/ProjectTasksTableTypeCellEditorOptions";
import {
  dateComparator,
  priorityComparator,
  statusComparator
} from "@/components/projects/Comparators.js";
import TasksTableTeamCellEditor from "@/components/projects/tasks_table/cell_editors/ProjectTasksTableTeamCellEditor.vue";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

import { isNotWorkingDay } from "@/helpers/DateHelpers";
import { hasSubtasks } from "@/helpers/TaskHelpers";
import { confirmDelete } from "@/helpers/DialogHelpers";
import AttachDocument from "./dialogs/AttachDocument.vue";
import gql from "graphql-tag";
import {
  customFieldColumnDefinitions,
  flattenCustomFieldsToObject
} from "@/helpers/CustomFieldHelpers";

export default {
  name: "ProjectTasksTable",
  extends: Components.BaseTable,
  mixins: [ProjectTaskView, ExpansionMixin, TaskSidebarMixin],
  apollo: {
    customFields: {
      query: gql`
        {
          customFields(scope: ["task", "all"]) {
            edges {
              node {
                name
                type
              }
            }
          }
        }
      `,
      update(data) {
        return data.customFields.edges.map(({ node }) => node);
      },
      result() {
        // Add each custom field to the list of table columns. Only
        // do this for allowed types however
        const columns = customFieldColumnDefinitions(this.customFields);
        this.columns.push(...columns);
      }
    },
    permissions: {
      query: gql`
        query projectPermissions($id: GlobalID) {
          projectPermissions(id: $id) {
            canUpdateTasks
            canCreateTask
            canRemoveTask
            canCreateDocument
          }
        }
      `,
      update({ projectPermissions }) {
        return {
          updateTasks: projectPermissions.canUpdateTasks,
          createTask: projectPermissions.canCreateTask,
          removeTask: projectPermissions.canRemoveTask,
          createDocument: projectPermissions.canCreateDocument
        };
      },
      variables() {
        return {
          id: this.projectId
        };
      }
    },
    tasks: {
      query: ProjectTasks,
      variables() {
        let vars = {
          projectId: this.projectId
        };

        if (this.searchTerm) {
          vars.search = this.searchTerm;
          vars.root = false;
        } else {
          vars.root = true;
        }

        return vars;
      },
      // update({ project }) {
      //   return project.tasks.edges.map(edge => edge.node);
      // },
      update({ project }) {
        return project.tasks.edges.map(({ node }) => {
          const customFields = flattenCustomFieldsToObject(node.customFields);
          return {
            ...node,
            ...customFields
          };
        });
      },
      result() {
        if (!this.initialised) {
          // Only update the tree if the data is loading for the first
          // time. After this, we only ever refetch data if we search
          // for something, and we don't want to replace the tree in
          // that case as we'll want to restore it.
          this.tasks.forEach(task => {
            this.setLevel(task);
            this.taskTree.insertNode(task);
          });
          this.initialised = true;
          this.setCurrentTaskFromRoute();
        }
        this.setRowData(this.tasks);
        this.stopLoading();
      },
      fetchPolicy: "no-cache",
      skip() {
        if (!this.initialised) return false;

        const search = this.searchTerm ?? "";
        return search === "";
      }
    }
  },

  props: {
    projectId: {
      type: [String, Number],
      required: true
    }
  },

  data() {
    const vm = this;

    return {
      customFields: [],
      taskStatusSummary: null,
      documentManager: new DocumentManager(),
      initialised: false,
      taskTree: new TaskTree(this),
      reloadWhenReady: false,
      permissions: {
        updateTasks: false,
        createTask: false,
        removeTask: false,
        createDocument: false
      },
      defaultColumn: {
        sortable: true,
        cellEditor: "standardCellEditor",
        editable: () => !vm.readOnly,
        resizable: true,
        headerComponentParams: {
          customSort: this.sortBy
        }
      },
      columns: [
        {
          hide: true,
          field: "position",
          canToggle: false
        },
        {
          headerName: "Name",
          field: "name",
          flex: 1,
          minWidth: 400,
          headerCheckboxSelection: true,
          cellRenderer: "name",
          cellRendererParams: {
            // Toggle the open/closed state for this task
            toggle(task) {
              vm.toggleRowExpansion(task);
            },
            shouldShowPath() {
              return !!vm.searchTerm;
            },
            onClick(task) {
              vm.openTask(task);
            }
          },
          canToggle: false
        },
        {
          headerName: "Type",
          field: "type",
          cellRenderer: "typeOfTask",
          valueGetter({ data }) {
            return data.isMilestone ? "milestone" : "task";
          },
          width: 150,
          cellEditor: "dropdownCellEditor",
          cellEditorParams: {
            options: taskTypeOptions,
            dropdownProps: {
              allowEmpty: false,
              deselectLabel: "",
              valueAttr: "value",
              labelAttr: "label"
            }
          },
          editable: node => !vm.readOnly && !hasSubtasks(node.data)
        },
        {
          headerName: "Status",
          field: "status",
          width: 150,
          cellRenderer: "status",
          comparator: statusComparator,
          cellEditor: "dropdownCellEditor",
          cellEditorParams: {
            options(task) {
              if (task.isMilestone) {
                return milestoneStatusOptions;
              } else {
                return taskStatusOptions;
              }
            },
            dropdownProps: {
              allowEmpty: false,
              deselectLabel: "",
              valueAttr: "value",
              labelAttr: "label"
            }
          },
          editable(node) {
            return !vm.readOnly && !hasSubtasks(node.data);
          }
        },
        {
          headerName: "Starts",
          field: "startsAt",
          width: 150,
          cellRenderer: "date",
          comparator: dateComparator,
          valueGetter({ data }) {
            if (data.isMilestone) {
              return null;
            }

            return data.startsAt;
          },
          cellEditor: "dateCellEditor",
          cellEditorParams({ data }) {
            return {
              datePickerOpts: {
                maxDate: data.deadline,
                disable: vm.disabledDays
              }
            };
          },
          editable(node) {
            return (
              !vm.readOnly && !hasSubtasks(node.data) && !node.data.isMilestone
            );
          }
        },
        {
          headerName: "Deadline",
          field: "deadline",
          width: 150,
          cellRenderer: "date",
          cellRendererParams: {
            highlightOverdue: true
          },
          cellEditor: "dateCellEditor",
          comparator: dateComparator,
          cellEditorParams({ data }) {
            return {
              datePickerOpts: {
                minDate: data.isMilestone ? null : data.startsAt,
                disable: vm.disabledDays
              }
            };
          },
          editable(node) {
            return !vm.readOnly && !hasSubtasks(node.data);
          }
        },
        {
          headerName: "Priority",
          field: "priority",
          width: 150,
          cellRenderer: "priority",
          comparator: priorityComparator,
          cellEditor: "dropdownCellEditor",
          cellEditorParams: {
            options: priorityOptions,
            dropdownProps: {
              allowEmpty: false,
              deselectLabel: "",
              valueAttr: "value",
              labelAttr: "label"
            }
          }
        },
        {
          headerName: "Team",
          field: "users",
          width: 150,
          cellRenderer: "team",
          sortable: false,
          cellEditor: "teamCellEditor"
        },
        Helpers.table.actionsCell()
      ],
      components: {
        vue: {
          name: TasksTableNameCellRenderer,
          team: TasksTableTeamCellRenderer,
          teamCellEditor: TasksTableTeamCellEditor,
          typeOfTask: TaskTableTypeCellRenderer
        }
      },
      listeners: {
        cellValueChanged(evt) {
          vm.taskValueChanged(evt);
        }
      },
      options: {
        suppressClickEdit: false,
        singleClickEdit: false,
        suppressCellSelection: false,
        suppressRowClickSelection: true
      },
      selection: "multiple"
    };
  },

  computed: {
    taskManager() {
      return new TaskManager({ ...this.project });
    },

    disabledDays() {
      const workingWeek = this.$store.state.workingWeek;

      return [isNotWorkingDay(workingWeek)];
    },
    selectedRowsLabel() {
      let resourceLabel = "task";
      if (this.rowsSelected > 1) {
        resourceLabel = "tasks";
      }

      return `${this.rowsSelected} ${resourceLabel} + sub-tasks selected`;
    }
  },

  // watch: {
  //   search(term) {
  //     this.searchTerm = term;

  //     if (term === "") {
  //       // Search term cleared so we'll restore the previous state
  //       this.setRowData(this.getSortedTasks());
  //     } else {
  //       this.startLoading();
  //     }
  //   }
  // },

  mounted() {
    this.startLoading();
  },

  methods: {
    attachDocument(row) {
      let dialog = this.$dialog.show(AttachDocument, {
        props: {
          projectId: this.projectId
        }
      });

      dialog.onOk(({ api, data }) => {
        let params = {
          taskIds: [row.data.id],
          ...data
        };
        api.hide();
        this.documentManager.attachDocument(params).catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
      });
    },
    updateGridRow(task) {
      const rowNode = this.gridApi().getRowNode(task.id);
      rowNode?.setData(task);
    },

    projectLoaded({ taskStatusSummary }) {
      this.taskStatusSummary = taskStatusSummary;
    },

    setCurrentTaskFromRoute() {
      const taskHash = this.$route.hash.split("/");
      const tab = taskHash[1];
      const taskId = taskHash[2];

      if (!taskId || tab !== "tasks") return;
      if (taskId === "table" || taskId === "gantt") return;

      // We just need the id to open the Sidebar, the full task will get loaded by
      // the Sidebar
      this.$emit("task-selected");
      this.openTask(
        this.tasks.find(t => t.id === taskId) || {
          id: taskId
        }
      );

      this.$nextTick(() => {
        const agGridRow = this.gridApi().getRowNode(taskId);
        agGridRow?.setSelected(true);
      });
    },

    /**
     *  Called by the container view when the user
     *  requests to add a new task. This will create
     *  a new task immediately, and then start editing
     *  inline
     **/
    addTask(parent = undefined, isMilestone = false) {
      this.taskManager
        .createTask(parent, { isMilestone })
        .then(task => {
          const newNode = this.taskTree.insertNode(task, parent);
          this.setLevel(newNode, parent?.data);
          this.addRow(newNode.data, parent, true);
          this.taskAdded(newNode.data);
          this.refreshTaskStatusCount();

          if (newNode.parent) {
            this.refreshExpansionState(newNode.parent);
          }

          this.$emit("task-created", { task, parent });
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    },

    addRow(row, parent = undefined, makeEditable = false) {
      let transaction = {
        add: [row],
        update: []
      };

      let parentGridNode;

      if (parent) {
        const children = this.taskTree.getAllChildren(parent.id);
        parentGridNode = this.gridApi().getRowNode(parent.id);
        transaction.addIndex = parentGridNode.rowIndex + children.length;

        row.level = parent.data.level + 1;

        if (this.isSorted()) {
          // If the table has been sorted, then we need to ensure
          // the `position` value has been changed, since that's
          // what the table will actually be sorting by
          const sorted = this.getSortedTasks();
          sorted.forEach(t => {
            if (t.id === row.id) {
              // Set its position
              row.position = t.position;
            } else {
              // Update the task
              transaction.update.push(t);
            }
          });
        }
      }

      const res = this.gridApi().applyTransaction(transaction);

      if (makeEditable) {
        const row = res.add[0];
        this.gridApi().startEditingCell({
          rowIndex: row.rowIndex,
          colKey: "name"
        });
      }

      if (parentGridNode) {
        // If the parent node is selected, make sure this new child is also
        // selected
        if (!parentGridNode.indeterminate && parentGridNode.selected) {
          res.add[0].setSelected(true);
        }
      }
    },

    getActionBarContent(h) {
      const title = h(Components.AppHeader, { props: { margin: "" } }, "Tasks");
      if (!this.project) {
        return title;
      }

      const counts = this.taskStatusSummary;
      const notStarted = h(
        "span",
        { staticClass: "text-grey-60" },

        `${counts.notStarted} Not started, `
      );

      const started = h(
        "span",
        { staticClass: "text-tribal-aqua" },

        `${counts.started} Started, `
      );

      const complete = h(
        "span",
        { staticClass: "text-subatomic-sky" },

        `${counts.complete} Complete, `
      );

      const overdue = h(
        "span",
        { staticClass: "text-withered-cherry" },

        `${counts.overdue} Overdue`
      );

      const totals = [
        h("div", { staticClass: "flex-inline flex-row font-bold" }, [
          notStarted,
          started,
          complete,
          overdue
        ])
      ];

      return [
        h("div", { staticClass: "flex-inline flex-col" }, [title, totals])
      ];
    },

    getBulkActions() {
      return bulkMenuItems;
    },

    getSortedTasks() {
      let tasks;

      if (this.isSorted()) {
        // Table is sorted so we'll resort by that
        const sortModel = this.gridApi().getSortModel();
        const { colId, sort } = sortModel[1];
        const column = this.gridColumnApi().getColumn(colId);
        const comparator = this.getColumnComparator(column);
        tasks = this.taskTree.getSortedList(column.colDef, sort, comparator);
      } else {
        tasks = this.taskTree.getList();
      }

      return tasks.map(t => t.data);
    },

    contextMenuItemClicked(item, row) {
      switch (item.action) {
        case ACTIONS.PREVIEW:
          this.openTask(row);
          break;

        case ACTIONS.DELETE:
          this.deleteTasks([row]);
          break;

        case ACTIONS.ADD_SUBTASK:
          this.toggleRowExpansion(row, true).then(() => {
            this.addTask(row, false);
          });
          break;

        case ACTIONS.ADD_SUBTASK_MILESTONE:
          this.toggleRowExpansion(row, true).then(() => {
            this.addTask(row, true);
          });
          break;

        case ACTIONS.MOVE:
          // Open the old move modal
          this.$emit("move-task", { id: row.id });
          break;

        case ACTIONS.COPY_TASK:
          location.href = `/tasks/${row.id}/duplicate`;
          break;

        case ACTIONS.CONVERT_TO_MILESTONE:
          this.convertTask(row, "MILESTONE");
          break;

        case ACTIONS.CONVERT_TO_TASK:
          this.convertTask(row, "TASK");
          break;

        case ACTIONS.ATTACH_DOCUMENTS:
          this.attachDocument(row);
          break;
      }
    },

    convertTask(row, type) {
      const node = this.taskTree.findNode(row.id);
      const oldType = row.isMilestone ? "TASK" : "MILESTONE";

      node.type = node.data.type = type;

      this.updateTask(node, "type")
        .then(() => {
          this.refreshActions(row);
        })
        .catch(() => {
          node.type = oldType;
        });
    },

    deleteTasks(tasks) {
      const vm = this;
      confirmDelete.call(this, tasks, successMsg => {
        vm.taskManager
          .delete(tasks)
          .then(() => {
            vm.tasksDeleted(tasks);
            this.$emit("tasks-deleted", tasks);
            this.$flash.success(successMsg);
          })
          .catch(e => {
            vm.$flash.error(gqlErrorMessage(e));
          });
      });
    },

    getColumnComparator(column) {
      let comparator = column.colDef.comparator;

      if (!comparator) {
        comparator = (a, b) => {
          return a.localeCompare(b);
        };
      }

      return comparator;
    },

    getContextMenuItems(node) {
      if (node.data.isMilestone) {
        return milestoneMenuItems(node.data, this.permissions);
      }

      return taskMenuItems(node.data, this.permissions);
    },

    performBulkAction(item) {
      if (item.action === ACTIONS.DELETE) {
        const tasks = this.gridApi()
          .getSelectedNodes()
          .map(r => r.data);
        this.deleteTasks(tasks);
      }
    },

    /**
     *  Updates the count of the task statuses shown in the table
     *  header. this will request the counts from the server
     */
    refreshTaskStatusCount() {
      this.$apollo
        .query({
          query: TaskStatusSummaryQuery,
          variables: {
            id: this.project.id
          },
          fetchPolicy: "no-cache"
        })
        .then(({ data: { project } }) => {
          this.taskStatusSummary = project.taskStatusSummary;
        });
    },

    /**
     *  Called when a header is clicked to sort. We need to
     *  do a custom sort due to the tree structure
     */
    sortBy(direction, colId) {
      const column = this.gridColumnApi().getColumn(colId);
      const comparator = this.getColumnComparator(column);
      const sorted = this.taskTree.getSortedList(
        column.colDef,
        direction,
        comparator
      );

      this.gridApi().applyTransaction({
        update: sorted.map(n => n.data)
      });
    },

    moveTaskInTheTable(task, newParentId) {
      const taskBeingMoved = this.taskTree.findNode(task.id);

      // If we've got a parent ID, then we need to check if the
      // new parent is actually visible in the table and if we
      // have loaded it at all.
      const parentNode = newParentId
        ? this.taskTree.findNode(newParentId)
        : null;
      const parentTableNode = parentNode
        ? this.gridApi().getRowNode(newParentId)
        : null;

      // Move the node within the tree
      this.taskTree.moveNode(taskBeingMoved, parentNode);
      this.setLevel(taskBeingMoved, parentNode);

      // We're going to need to move all of the children of
      // this node as well, so get those as well
      const nodesToMove = [
        taskBeingMoved,
        ...this.taskTree.getAllChildren(taskBeingMoved)
      ].map(n => n.data);

      // Remove the old node from the table. We need to do this
      // first so we can get the correct index to re-insert it
      // if needed
      this.gridApi().applyTransaction({
        remove: nodesToMove
      });

      // Now check if the table is displaying this node. If not,
      // we'll simply remove it from the table, but if it is then
      // we'll move the task into its new position
      if (!newParentId) {
        // No parent ID, so we'll move it into the root of
        // the table
        this.gridApi().applyTransaction({
          add: nodesToMove,
          addIndex: this.gridApi().getDisplayedRowCount()
        });
      } else if (parentTableNode && parentNode.isExpanded) {
        // The parent tree node exists, so we will move
        this.gridApi().applyTransaction({
          add: nodesToMove,
          addIndex:
            parentTableNode.rowIndex +
            this.taskTree.getAllChildren(parentNode).length -
            nodesToMove.length +
            1
        });
      }
    },

    /**
     *  Called when a value of any cell in the table is updated
     *  inline.
     */
    taskValueChanged(evt) {
      const field = evt.colDef.field;
      const id = evt.data.id;
      const node = this.taskTree.findNode(id);
      let newVal = evt.data[field];
      const oldVal = node.data[field];

      if (field === "users") {
        newVal = newVal.map(u => ({
          id: u.id
        }));
      }

      node.data[field] = newVal;
      this.updateTask(node.data, field).catch(evt => {
        node.data[field] = oldVal;
        evt.data[field] = oldVal;
      });
    },

    updateTask(task, field) {
      return this.taskManager
        .updateTable(task, field)
        .then(tasks => {
          // Update successful, now update our data and update any
          // table cells as neeed
          this.updateNodeCells(tasks);
          this.refreshTaskStatusCount();
          this.$emit("tasks-updated", tasks);
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
          throw e;
        });
    },

    // Override the performSearch function so it doesn't trigger
    // the tables own searching
    performSearch() {
      if (this.searchTerm === "") {
        // Search term cleared so we'll restore the previous state
        this.setRowData(this.getSortedTasks());
      } else {
        this.startLoading();
      }
    },

    /**
     *  Removes the specified rows from the table. This will
     *  also search for any child tasks and remove those as
     *  well if applicable.
     **/
    removeGridTasks(tasks) {
      // We're going to reduce our list of tasks into an object so that we
      // can remove the duplicates. If for example we've selected a parent
      // and a child, then by getting all children of selected nodes we will
      // end up selecting the same task twice. By reducing these into an
      // object with the id as the key, we cannot have two fo the same task
      // due to object keys being unique
      const reducer = (acc, task) => {
        acc[task.id] = task;
        return acc;
      };
      const taskNodes = tasks
        .map(t => [t, ...this.taskTree.getAllChildren(t)])
        .flat()
        .reduce(reducer, {});

      const rowNodes = Object.values(taskNodes)
        .map(t => this.gridApi().getRowNode(t.id))
        .filter(n => !!n);

      this.gridApi().applyTransaction({
        remove: rowNodes
      });
      this.taskTree.removeNodes(tasks);
    },

    /**
     *  Updates the underlying data, and refreshes the table
     *  for the specified tasks, and field. This should be used
     *  when an update has been performed on a single task, as
     *  some changes cause cascading changes to other tasks which
     *  must be reflected
     */
    updateNodeCells(tasks) {
      const fields = [
        "name",
        "isMilestone",
        "status",
        "startsAt",
        "deadline",
        "priority",
        "users"
      ];
      let transaction = {
        update: []
      };

      tasks.forEach(task => {
        const treeNode = this.taskTree.findNode(task.id);

        if (treeNode) {
          fields.forEach(field => {
            // Update the tree node
            if (treeNode && task[field] !== undefined) {
              treeNode.data[field] = task[field];
            }
          });

          transaction.update.push(treeNode.data);
        }
      });

      const result = this.gridApi().applyTransaction(transaction);
      this.refreshActions(result.update);
    },

    isSorted() {
      const sortModel = this.gridApi().getSortModel();
      return sortModel.length > 0;
    },

    reloadTable() {
      if (this.searchTerm === "") {
        this.taskTree.clear();
        this.initialised = false;
      }
    },

    taskCreated({ task, parent }) {
      const newNode = this.taskTree.insertNode(task, parent);
      const parentNode = parent ? this.taskTree.findNode(parent.id) : null;

      this.setLevel(newNode, parentNode?.data);
      // Only add the row to the table if the parent node has actually
      // loaded its subtasks already, or no parent was specifid and is
      // therefore a root node
      if ((parent && parentNode) || !parent) {
        if (
          !parentNode ||
          (parentNode.subtasksLoaded && parentNode.isExpanded)
        ) {
          this.addRow(newNode.data, parentNode, false);
        }
      }
      this.refreshTaskStatusCount();

      if (parentNode) {
        this.refreshExpansionState(parentNode);
      }
    },
    tasksDeleted(tasks) {
      this.removeGridTasks(tasks);
      this.refreshTaskStatusCount();
    },
    taskMoved() {
      // Moving a task is very awkward at the moment due to the different data
      // stored in the gantt and the table, and the fact that the table loads
      // data dynamically. Instead we'll just reload the data. Not ideal, but
      // it'll do for now

      //this.moveTaskInTheTable(task, newParentId);

      this.reloadTable();
    },
    taskRenamed(task) {
      this.updateNodeCells([task]);
    },
    tasksUpdated(tasks) {
      this.updateNodeCells(tasks);
      this.refreshTaskStatusCount();
    }
  }
};
</script>
