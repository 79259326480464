import { runMutation } from "@/helpers/GraphQLHelpers";
import DeleteRole from "@/graphql/mutations/roles/DeleteRole.gql";
import RenameRole from "@/graphql/mutations/roles/RenameRole.gql";
import CreateRole from "@/graphql/mutations/roles/CreateRole.gql";
import UpdateRole from "@/graphql/mutations/roles/UpdateRole.gql";

export default class RolesManager {
  rename(id, name) {
    return runMutation(RenameRole, { id, name });
  }

  delete(role, newRoleId) {
    const roleId = role.id;

    return runMutation(DeleteRole, { roleId, newRoleId });
  }

  create(role) {
    return runMutation(CreateRole, role);
  }

  update(role) {
    return runMutation(UpdateRole, role);
  }
}
