<template lang="pug">
  .relative.w-full
    .relative.flex.flex-col.h-64.w-full(ref="container")
      canvas(ref="canvas")
    .flex.flex-row.flex-wrap.justify-center.mt-4
      .block.p-3(class="w-1/2" v-for="taskStatus in taskStatuses" :key="taskStatus")
        span.inline-block.align-middle.border-4.rounded-full.w-6.h-6(:style="statusStyle(taskStatus)")
        span.ml-2.inline-block.align-middle {{ chartData[taskStatus] }} {{ taskStatus }}
      .block.w-full.text-center.text-withered-cherry.font-bold {{ overdueTasksSentence }}
</template>

<script>
export default {
  props: {
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      taskStatuses: ["Started", "Not started", "Complete"],
      chart: null,
      ctx: null
    };
  },
  computed: {
    colours() {
      return this.$store.state.chartColours;
    },

    chartOpts() {
      return this.generateChartOpts();
    },

    overdueTasksSentence() {
      if (!this.hasOverdueTasks) return;

      const { total: tasksCount } = this.chartData.overdue;

      if (tasksCount === 1) {
        return "There is one task overdue";
      } else {
        return `There are ${tasksCount} overdue tasks`;
      }
    },

    hasOverdueTasks() {
      return !!this.chartData.overdue.total;
    }
  },
  mounted() {
    this.ctx = this.$refs.canvas.getContext("2d");
    this.chart = new window.Chart(this.ctx, this.chartOpts);
  },
  methods: {
    generateChartOpts() {
      return {
        type: "doughnut",
        data: {
          datasets: this.generateDatasets()
        },
        options: {
          maintainAspectRatio: false,
          tooltips: {
            filter(tooltipItem, data) {
              const isOverdueDataset = tooltipItem.datasetIndex !== 0;
              if (!isOverdueDataset) return true;

              const overdueDataset = data.datasets[tooltipItem.datasetIndex];
              if (overdueDataset.labels[tooltipItem.index] == "Complete")
                return false;

              return true;
            },
            callbacks: {
              label(tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const index = tooltipItem.index;
                return dataset.labels[index] + ": " + dataset.data[index];
              }
            }
          }
        }
      };
    },
    generateDatasets() {
      const { colours } = this;
      const dataSets = [];

      let statusDataset = {
        data: [],
        labels: this.taskStatuses,
        backgroundColor: [
          colours["Started"],
          colours["Not started"],
          colours["Complete"]
        ],
        weight: 10
      };

      statusDataset.labels.forEach(status => {
        statusDataset.data.push(this.chartData[status]);
      });

      dataSets.push(statusDataset);

      if (this.hasOverdueTasks) {
        let overdueDataset = {
          data: [],
          hoverBorderColor: "transparent",
          backgroundColor: [
            colours["Overdue"],
            "transparent",
            colours["Overdue"],
            "transparent",
            "transparent"
          ],
          labels: [
            "Overdue started",
            "Started and on target",
            "Overdue not started",
            "Not started and on target",
            "Complete"
          ],
          weight: 4
        };

        overdueDataset.data = [
          this.chartData.overdue.started,
          this.chartData.Started - this.chartData.overdue.started,
          this.chartData.overdue.notStarted,
          this.chartData["Not started"] - this.chartData.overdue.notStarted,
          this.chartData.Complete
        ];

        dataSets.push(overdueDataset);
      }

      return dataSets;
    },
    statusStyle(status) {
      return { borderColor: this.colours[status] };
    }
  }
};
</script>
