import forEach from "ramda/src/forEach";

/* global $, $D, accounting */
export default {
  data() {
    return {
      container: null,
      expenditure: {},
      showGDV: false,
      state: {
        categories: {},
        lineItems: {},
        change_orders: {},
        expected_costs: {},
        bills: {},
        purchase_orders: {},
        estimates: {},
        labour: {}
      },
      isReady: false,
      enabledColumns: [],
      preferenceId: null
    };
  },
  props: {
    project: Number,
    showDecimals: {
      default: true,
      type: Boolean
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.container = $(this.el).closest(".ui.tab");
      this.loadExpenditure();
    });
  },

  computed: {
    availableColumns() {
      return {
        estimated_amount: "Original Budget",
        approved_changes: "Approved Changes",
        approved_amount: "Current Approved Budget",
        projected_amount: "Up-to-date Projected Costs",
        spend_to_date: "Spend to Date",
        budget_left: "Budget Left",
        projected_overrun: "Projected Overrun",
        projected_overrun_percentage: "Projected Overrun %",
        progress_percentage: "Progress %"
      };
    },
    invertedLineItems() {
      const lineItems = [];
      let lastCategory = null;
      this.expenditure.line_items.forEach(row => {
        if (row.type === "category") {
          if (lastCategory !== null) {
            lineItems.push(lastCategory);
          }

          lastCategory = row;
        } else {
          lineItems.push(row);
        }
      });

      if (lastCategory !== null) {
        lineItems.push(lastCategory);
      }

      return lineItems;
    }
  },

  directives: {
    percentage: {
      bind(el, binding) {
        let { value } = binding;
        const element = el;

        if (value === "") {
          return;
        }
        if (value < 0) {
          element.classList.add("negative");
        }
        if (binding.modifiers.ceiling && value > 100) {
          element.classList.add("negative");
        }

        value = Math.round(value * 100) / 100;
        element.innerHTML = `${value}%`;
      }
    },

    currency: {
      bind(el, binding, vnode) {
        const { value } = binding;
        const { currency } = vnode.context.expenditure;
        const { showDecimals } = vnode.context;
        const element = el;

        if (value < 0) {
          element.classList.add("negative");
        }

        element.innerHTML = accounting.formatMoney(value, {
          symbol: currency.symbol,
          decimal: currency.decimal_mark,
          thousand: currency.thousands_separator,
          precision: showDecimals ? currency.decimal_places : 0
        });
      }
    }
  },

  methods: {
    budgetLeft(lineItem) {
      return lineItem.approved_amount - lineItem.spend_to_date;
    },

    contributorURL(contributor, type) {
      if (type === "estimates") {
        return `/estimates/${contributor.id}`;
      }

      return `/projects/${this.project}/${type}/${contributor.id}`;
    },

    loadExpenditure() {
      const vm = this;
      $.getJSON(`/api/projects/${this.project}/expenditure`)
        .then(vm.processExpenditure)
        .always(() => {
          vm.$nextTick(() => {
            $D.fn.dropdown.initDropdown(
              $(".mp.expenditure.table th .dropdown.button")
            );
          });
        });
    },

    expanderIconClass(lineItem, type) {
      let target;
      if (type === undefined) {
        target = lineItem.type === "category" ? "categories" : "lineItems";
        return this.state[target][lineItem.id] ? "minus" : "plus";
      }

      return (this.state[type] || {})[lineItem.id] ? "minus" : "plus";
    },

    hasContributor(lineItem, types) {
      let i;
      for (i = 0; i < types.length; i += 1) {
        if (Object.keys(lineItem.contributors[types[i]]).length > 0) {
          return true;
        }
      }

      return false;
    },

    isCategoryExpanded(categoryID) {
      return this.state.categories[categoryID];
    },

    isVisible(lineItem) {
      if (lineItem.type === "category") {
        return true;
      }
      return this.isCategoryExpanded(lineItem.category);
    },

    isExpanded(lineItem, type) {
      let target;
      if (type === undefined) {
        target = lineItem.type === "category" ? "categories" : "lineItems";
        return this.state[target][lineItem.id];
      }

      return !!(this.state[type] || {})[lineItem.id];
    },

    percentageProgress(lineItem) {
      if (lineItem.approved_amount === 0) {
        return "";
      }
      if (lineItem.spend_to_date === 0) {
        return "";
      }

      return (lineItem.spend_to_date / lineItem.approved_amount) * 100.0;
    },

    projectedCosts(lineItem) {
      return Math.max(lineItem.spend_to_date, lineItem.approved_amount);
    },

    projectedOverrun(lineItem) {
      return lineItem.approved_amount - lineItem.projected_amount;
    },

    projectedOverrunPercentage(lineItem) {
      if (lineItem.approved_amount === 0) {
        return 0;
      }
      return (
        (this.projectedOverrun(lineItem) / lineItem.approved_amount) * 100.0
      );
    },

    processExpenditure(data) {
      const vm = this;
      data.expenditure.line_items.forEach(item => {
        const target = item.type === "category" ? "categories" : "lineItems";
        vm.$set(vm.state[target], item.id, item.type === "category");
      });
      vm.$set(vm.state.categories, null, true);
      vm.expenditure = data.expenditure;
      if (data.expenditure.gdv) {
        vm.showGDV = true;
        vm.gdv = data.expenditure.gdv;
      }

      vm.preferenceId = data.preference_id;

      forEach(v => {
        vm.enableColumn(v);
      }, data.columns);

      vm.isReady = true;
    },

    toggleContributor(lineItem, type) {
      this.$set(this.state[type], lineItem.id, !this.state[type][lineItem.id]);
    },

    toggleLineItem(lineItem) {
      const target = lineItem.type === "category" ? "categories" : "lineItems";
      this.$set(
        this.state[target],
        lineItem.id,
        !this.state[target][lineItem.id]
      );
    },

    isColumnVisible(column) {
      return this.enabledColumns.indexOf(column) !== -1;
    },

    columnToggled(column) {
      const i = this.enabledColumns.indexOf(column);

      if (this.isColumnVisible(column)) {
        this.toggleColumnRemote(column, false);
        this.enabledColumns.splice(i, 1);
      } else {
        this.toggleColumnRemote(column, true);
        this.enableColumn(column);
      }
    },

    toggleColumnRemote(column, enabled) {
      const url = `/preferences/${this.preferenceId}/toggle_column`;

      $.ajax({
        url,
        method: "PUT",
        data: {
          "preference[column]": column,
          "preference[enable]": enabled
        }
      });
    },

    enableColumn(column) {
      this.enabledColumns.push(column);
      if (column === "estimated_amount") {
        this.$nextTick(() => {
          $D.components.tooltip.init(
            $(".mp.expenditure.table .hoverable.tooltip")
          );
        });
      }
    }
  }
};
