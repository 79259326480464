<template lang="pug">
.flex(v-if="lastDigits || lastDigits == ''")
  .logo-wrapper.mr-2.my-auto
    img.visa-logo(v-if="showImage", :src="image")
  span.flex.my-auto
    .dot
    .dot
    .dot
    .dot
  span.flex.ml-2.my-auto
    .dot
    .dot
    .dot
    .dot
  span.flex.ml-2.my-auto
    .dot
    .dot
    .dot
    .dot
  span.font-bold.last-digit.ml-2.my-auto {{lastDigits}}
</template>

<script>
import VisaIcon from "@/assets/svg/visa.svg";
import MasterIcon from "@/assets/svg/mastercard.svg";
import AmericanExpressIcon from "@/assets/svg/american_express.svg";
import JCBIcon from "@/assets/svg/jcb.svg";
import DinersClubIcon from "@/assets/svg/diners_club.svg";
import DiscoverIcon from "@/assets/svg/discover.svg";
import UniPayIcon from "@/assets/svg/unipay.svg";
import NoCard from "@/assets/svg/no-card.png";

export default {
  name: "AppAddInput",
  props: {
    showImage: Boolean,
    lastDigits: {
      type: [String, Number],
      required: false,
      default: null
    },
    brand: {
      type: String,
      required: false,
      default: "visa"
    }
  },
  computed: {
    image() {
      switch (this.brand.toLowerCase()) {
        case "visa":
          return VisaIcon;
        case "mastercard":
          return MasterIcon;
        case "jcb":
          return JCBIcon;
        case "american express":
          return AmericanExpressIcon;
        case "diners club":
          return DinersClubIcon;
        case "discover":
          return DiscoverIcon;
        case "unipay":
          return UniPayIcon;
        default:
          return NoCard;
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.logo-wrapper {
  @apply bg-grey-20;
  width: 30px;
  height: 20px;
}
.visa-logo {
  width: 100%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.dot {
  @apply bg-grey-60;
  width: 5px;
  height: 5px;
  width: 8px;
  height: 8px;
  margin: 2px;
  border-radius: 100%;
}
.last-digit {
  line-height: 1;
}
</style>
