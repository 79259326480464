export default {
  watch: {
    checkout: {
      deep: true,
      handler(updatedValue) {
        this.$emit("input", updatedValue);
      }
    },

    value: {
      deep: true,
      handler(updatedValue) {
        this.checkout = updatedValue;
      }
    }
  }
};
