import { formatDateApi } from "@/helpers/DateHelpers";
import { normalizeFloat } from "@/helpers/StringHelpers";

const FieldMixin = {
  methods: {
    validateDate(date) {
      if (date) {
        const format = this.$store.state.dateFormats.dateFns || "yyyy-MM-dd";
        return formatDateApi(
          new Date(date),
          format.indexOf("HH:mm") > -1 ? format : `${format} HH:mm`
        );
      }
      return date;
    },
    normalizePrice(price) {
      return Math.abs(normalizeFloat(price));
    }
  }
};

export default FieldMixin;
