<template lang="pug">
  table.ui.celled.tablet.stackable.very.compact.table
    tabular-form-header(
      :groups='groups',
      :labels='labels',
      :sortable='sortable',
      :fixed-column='fixedColumn')

    tabular-form-data(
      :rows='rows',
      :deleted-rows='deletedRows',
      :can-edit='canEdit',
      :currency='currency',
      :sortable='sortable')

    tabular-form-summary(
      v-if='showSummary && canEdit',
      :labels='labels',
      :rows='rows',
      :currency='currency',
      :sortable='sortable')
</template>

<script>
import any from "ramda/src/any";
import prop from "ramda/src/prop";
import TabularFormHeader from "./TabularFormHeader.vue";
import TabularFormData from "./TabularFormData.vue";
import TabularFormSummary from "./TabularFormSummary.vue";
import TabularFormSummaryReadonly from "./read_only/TabularFormSummaryReadonly.vue";

export default {
  components: {
    TabularFormHeader,
    TabularFormData,
    TabularFormSummary,
    TabularFormSummaryReadonly
  },
  props: [
    "groups",
    "labels",
    "rows",
    "deletedRows",
    "currency",
    "sortable",
    "fixedColumn",
    "canEdit"
  ],
  computed: {
    showSummary() {
      return (
        (any(prop("show_average"), this.labels) ||
          any(prop("show_sum"), this.labels)) &&
        this.rows.length > 0
      );
    }
  }
};
</script>
