<template lang="pug">
  div
    slot(name="description")
      label.block.text-grey-70.text-lg.mb-3(:class="errorClasses") {{ description }}

    .flex.flex-row
      .flex-0.mr-8(v-for="option in options" :key="option.value")
        input.hidden(type="checkbox" :id="option.value" :value="option.value" v-model="checkedOptions")
        label.flex.items-center.cursor-pointer.text-lg(:for="option.value" :class="optionClasses(option)"
                                                      @keyup.enter.space="checkOption(option, $event)" tabindex="0")
          span.w-8.h-8.inline-block.mr-4.rounded-sm.border.flex-no-shrink(v-if="showCheckbox")
          | {{ option.name }}

    slot(name="errors" v-if="hasErrors" :errors="activeErrors" :errorMessages="activeErrorMessages" :has-errors="hasErrors" :first-error-message="firstErrorMessage")
      div.font-bold(:class="errorClasses") {{ firstErrorMessage }}
</template>

<script>
import FieldMixin from "@/mixins/Field";

export default {
  mixins: [FieldMixin],

  props: {
    value: {
      type: Array,
      required: true
    },

    options: {
      type: Array,
      required: true
    },

    showCheckbox: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    checkedOptions: {
      get() {
        return this.value;
      },

      set(value) {
        if (this.preferredValidator.$touch) {
          this.preferredValidator.$touch();
        }

        this.$emit("input", value);
      }
    }
  },
  methods: {
    optionClasses(option) {
      let classes = [];

      if (this.optionChecked(option)) {
        classes.push("text-tribal-aqua font-bold");
      } else {
        classes.push("text-grey-50");
      }

      return classes;
    },

    checkOption(option) {
      const checkedOptions = [...this.checkedOptions];
      if (this.optionChecked(option)) {
        let idx = checkedOptions.indexOf(option.value);
        checkedOptions.splice(idx, 1);
      } else {
        checkedOptions.push(option.value);
      }

      this.checkedOptions = checkedOptions;
    },

    optionChecked(option) {
      return this.checkedOptions.indexOf(option.value) !== -1;
    }
  }
};
</script>

<style lang="postcss" scoped>
input[type="checkbox"] {
  & + label span {
    transition: background 0.2s, transform 0.2s;
    @apply border-grey-60;
  }

  &:checked + label span {
    @apply bg-tribal-aqua;
    @apply border-tribal-aqua;
    box-shadow: 0px 0px 0px 2px var(--white) inset;
  }
}
</style>
