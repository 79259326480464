<template lang="pug">
  app-dialog(@cancel="$emit('cancel')")
    template(#title) You have reached your project limit

    p.text-grey-70.mb-8
      | The free plan only allows you to have one active project at a time.
      | Either close your current project and create a new one or subscribe and enjoy the full ManagePlaces experience.

    .text-right
      app-button.inline-block.mr-4(@click="dismiss") Dismiss
      app-button.inline-block.mr-4(primary @click="subscribe") Subscribe
</template>

<script>
export default {
  name: "FreePlanNumberOfProjectsExcededDialog",

  methods: {
    subscribe() {
      this.$router.push("/subscriptions/new");
      this.$emit("ok");
    },
    dismiss() {
      window.location.href = "/projects";
      this.$emit("cancel");
    }
  }
};
</script>
