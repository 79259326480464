import { render, staticRenderFns } from "./TaskSidebarRelationships.vue?vue&type=template&id=4bea96c8&lang=pug&"
import script from "./TaskSidebarRelationships.vue?vue&type=script&lang=js&"
export * from "./TaskSidebarRelationships.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports