export default {
  required: "The {attribute} is required",
  decimal: "{attribute} must be a decimal number",
  inList: "The {attribute} is not a valid option",
  email: "The {attribute} is not a valid email address",
  positive: "The {attribute} must be >= 0",
  containsUppercase: "{label} must contain at least one uppercase character",
  containsLowercase: "{label} must contain at least one lowercase character",
  containsNumber: "{label} must contain at least one number",
  containsSpecial: "{label} must contain at least one special character"
};
