<template lang="pug">
  .pdf-zoom
    a(@click.prevent.stop="zoomIn")
      app-icon(icon="zoomIn" size="normal")

    a.ml-4(@click.prevent.stop="zoomOut")
      app-icon(icon="zoomOut" size="normal")
</template>

<script>
export default {
  props: {
    increment: {
      type: Number,
      required: false,
      default: 0.25
    },
    scale: {
      type: Number,
      required: true
    }
  },
  methods: {
    updateScale(scale) {
      this.$emit("input", { scale });
    },
    zoomIn() {
      this.updateScale(this.scale + this.increment);
    },
    zoomOut() {
      if (this.scale <= this.increment) return;

      this.updateScale(this.scale - this.increment);
    }
  }
};
</script>
