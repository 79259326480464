<template lang="pug">
  a.item(v-if='variation == "link"', href='#', @click.prevent='downloadFile')
    app-icon.mr-4(:icon='icon')
    | {{ label }}

  app-button(v-else, :icon="icon", @click.prevent="downloadFile")
    | {{ label }}
</template>

<script>
/* global $ */
import BaseModal from "@/components/modals/BaseModal.vue";

export default {
  components: {
    BaseModal
  },
  props: {
    label: {
      type: String,
      default: "Print"
    },
    icon: {
      type: String,
      default: "print"
    },
    url: String,
    type: String,
    variation: {
      type: String,
      default: "link"
    }
  },
  data() {
    return {
      dynamic: true,
      loadingMessage: `Generating ${this.type}, please wait...`,
      name: `${this.type
        .toLowerCase()
        .replace(" ", "-")}-file-generating-modal`,
      state: ""
    };
  },
  methods: {
    downloadFile() {
      const vm = this;
      // vm.$modal.show(vm.name);
      vm.showModal();

      $.fileDownload(this.url, {
        successCallback() {
          vm.state = "success";
          setTimeout(() => {
            vm.hideModal();
          }, 1000);
        },
        failCallback() {
          vm.state = "error";
          setTimeout(() => {
            vm.hideModal();
          }, 1000);
        }
      });
    },
    hideModal() {
      this.$modal.hide(this.name);
    },
    showModal() {
      if (this.dynamic) {
        this.$modal.show(BaseModal, this.$data, {
          name: this.name,
          height: 300,
          classes: "mp modal"
        });
      } else {
        this.$modal.show(this.name);
      }
    }
  }
};
</script>

/* globals $, $D */
