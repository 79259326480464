<script>
import Vue from "vue";
import { Components } from "manageplaces-ui-kit";
import {
  forEachChild,
  updateSelection
} from "@/components/projects/tasks_table/helpers/ProjectTasksTableGridHelpers";

const buttonSize = 21;
const remSize = 14;

export default Vue.extend({
  extends: Components.BaseTableLinkCellRenderer,
  data() {
    return {
      indeterminate: false,
      selected: false,
      selectedByCheckbox: false
    };
  },
  mounted() {
    this.indeterminate = !!this.params.node.indeterminate;
    this.selected = this.params.node.isSelected();
    this.params.api.addEventListener("rowSelected", this.onRowSelected);
  },
  beforeDestroy() {
    this.params.api.removeEventListener("rowSelected", this.onRowSelected);
  },
  methods: {
    getName(h) {
      const vm = this;
      const row = h(
        "a",
        {
          staticClass: "leading-none block",
          attrs: { href: "#", title: vm.params.value },
          on: {
            click: e => {
              e.preventDefault();
              this.params.onClick(vm.params.data);
            }
          }
        },
        vm.params.value
      );

      return row;
    },
    getExpandIcon(h) {
      const { isExpanded } = this.params.data;
      const vm = this;

      let classes =
        "rounded text-center mr-4 leading-none cursor-pointer flex flex-col justify-center items-center ";
      const icon = h(Components.AppIcon, {
        props: {
          icon: isExpanded ? "caretDown" : "caretRight",
          size: "xs",
          colour: isExpanded ? "white" : "grey"
        }
      });

      const button = h(
        "span",
        {
          staticClass:
            classes + (isExpanded ? "bg-tribal-aqua shadow" : "bg-grey-30"),
          style: `width: ${buttonSize}px; height: ${buttonSize}px;`,
          on: {
            click() {
              vm.params.toggle(vm.params.data);
            }
          }
        },
        [icon]
      );

      return [this.getIndentation(h), button];
    },
    getSpacer(h) {
      return this.getIndentation(h, 1);
    },
    getIndentation(h, additionalSpaces = 0) {
      const { level } = this.params.data;

      // Spacing provides the indentation for each level. This is calculated
      // as the level, multiplied by the width of the expansion buttons (20px)
      // and the spacing they use (1rem);
      const indentationSize =
        (level + additionalSpaces) * (buttonSize + remSize);
      return h("span", {
        style: `width: ${indentationSize}px`
      });
    },
    onRowSelected(p) {
      this.selected = this.params.node.isSelected();
      if (p.node === this.params.node) {
        this.selected = this.params.node.isSelected();
        this.indeterminate = !!this.params.node.indeterminate;

        if (this.selectedByCheckbox) {
          this.selected = this.params.node.isSelected();
          this.indeterminate = false;
          this.toggleChildren();
          this.updateParentStatus();
        }

        this.selectedByCheckbox = false;
      }
    },
    shouldShowPath() {
      if (this.params.shouldShowPath) {
        return this.params.shouldShowPath();
      }

      return false;
    },
    toggleChildren() {
      // this row was directly selected, so we'll select/deselect
      // the children as well
      const node = this.params.node;
      forEachChild(this.params.api, node, child => {
        child.setSelected(this.selected);
      });
    },
    updateParentStatus() {
      const { api, node } = this.params;
      updateSelection(api, node);
    }
  },
  render(h) {
    const vm = this;
    const checkbox = h(Components.AppCheckbox, {
      props: {
        value: this.selected,
        indeterminate: this.indeterminate
      },
      on: {
        input() {
          vm.selectedByCheckbox = true;
          vm.params.node.setSelected(!vm.selected);
        }
      }
    });

    let children = [];

    if (this.shouldShowPath()) {
      // Searching, so we'll hide everything but the name
      children.push(this.getName(h));
    } else {
      let beforeCheckbox;
      if (this.params.data.childCount === 0) {
        // No children, so it's a spacer
        beforeCheckbox = this.getSpacer(h);
      } else {
        beforeCheckbox = this.getExpandIcon(h);
      }

      children.push(beforeCheckbox, checkbox, this.getName(h));
    }
    return h("span", { staticClass: "flex flex-row items-center" }, children);
  }
});
</script>
