/* global $D */

import prop from "ramda/src/prop";
import find from "ramda/src/find";

export class RowWrapper {
  constructor(row) {
    this.row = row;
  }

  value(slug) {
    return prop("content", prop("value", this.cell(slug)));
  }

  cell(slug) {
    return find(cell => cell.label.slug.toString() === slug, this.row.cells);
  }
}

export function evalFormula(rowWrapper, formula) {
  let d;
  let e;
  let g;
  let i;

  if (formula === "E/D") {
    e = rowWrapper.value("E");
    d = rowWrapper.value("D");

    return $D.fn.safeMath.div(e, d);
  }
  if (formula === "G/D") {
    g = rowWrapper.value("G");
    d = rowWrapper.value("D");

    return $D.fn.safeMath.div(g, d);
  }
  if (formula === "I/D") {
    i = rowWrapper.value("I");
    d = rowWrapper.value("D");

    return $D.fn.safeMath.div(i, d);
  }
  if (formula === "G-E") {
    g = rowWrapper.value("G");
    e = rowWrapper.value("E");

    return $D.fn.safeMath.sub(g, e);
  }
  if (formula === "I-G") {
    i = rowWrapper.value("I");
    g = rowWrapper.value("G");

    return $D.fn.safeMath.sub(i, g);
  }
  if (formula === "I-E") {
    i = rowWrapper.value("I");
    e = rowWrapper.value("E");

    return $D.fn.safeMath.sub(i, e);
  }
  if (formula === "((I/E)-1)*100") {
    i = rowWrapper.value("I");
    e = rowWrapper.value("E");

    return $D.fn.safeMath.mul(
      $D.fn.safeMath.sub($D.fn.safeMath.div(i, e), 1),
      100
    );
  }

  return null;
}
