import isNil from "ramda/src/isNil";
import isEmpty from "ramda/src/isEmpty";

/**
 * Test whether the provided value is considered blank
 * or empty. If the provided value is null or undefined,
 * or for that type of data it is blank/empty this will
 * return true.
 */
export const isBlank = item => {
  return isNil(item) || isEmpty(item);
};
