<template lang="pug">
  app-panel(secondary)
    app-header(size="h3") Project documents
      template(#subheader)
        | Permissions for project documents

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")
      template(#options)
        app-checkbox.mb-6(label="View documents" :value="true")
        app-checkbox.mb-6(label="Edit existing documents" :value="permissions.editProjectDocument" @input="permissionChanged('editProjectDocument', $event)")
        app-checkbox.mb-6(label="Delete documents" :value="permissions.removeProjectDocument" @input="permissionChanged('removeProjectDocument', $event)")
        app-checkbox.mb-6(label="Upload new documents" :value="permissions.createProjectDocument" @input="permissionChanged('createProjectDocument', $event)")
        app-checkbox.mb-6(label="Share documents" :value="permissions.shareProjectDocument" @input="permissionChanged('shareProjectDocument', $event)")

</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> access any project documents",
          value: "cannotManage"
        },
        {
          label: "Can access for <strong>assigned projects</strong> only",
          value: "canManageAssigned",
          slot: "options"
        },
        {
          label: "Can access for <strong>any projects</strong>",
          value: "canManageAny",
          slot: "options"
        }
      ],
      selected: "cannotManage",
      permissions: {
        viewProjectDocument: false,
        editProjectDocument: false,
        removeProjectDocument: false,
        createProjectDocument: false,
        shareProjectDocument: false
      },
      scope: "global"
    };
  },
  computed: {
    selectedPermissions() {
      if (this.selected === "cannotManage") {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: this.scope
      };
    }
  },
  mounted() {
    if (this.value.permissions.viewProjectDocument) {
      if (this.value && this.value.scope === "project") {
        this.selected = "canManageAssigned";
        this.scope = "project";
      } else {
        this.selected = "canManageAny";
        this.scope = "global";
      }
    }
  },
  methods: {
    optionChanged(value) {
      this.permissions.viewProjectDocument = value !== "cannotManage";
      this.scope = value === "canManageAssigned" ? "project" : "global";
      this.selected = value;
      this.notifyChange();
    },
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
