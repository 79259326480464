<script>
import ContactsQuery from "@/graphql/queries/contact_management/Contacts.gql";
import { Components, Helpers } from "manageplaces-ui-kit";
import ContactsTableNameCellRenderer from "@/components/contact_management/cell_renderers/ContactsTableTableNameCellRenderer.vue";
import ContactsTableEmailCellRenderer from "@/components/contact_management/cell_renderers/ContactsTableEmailCellRenderer.vue";
import ContactsTablePhoneCellRenderer from "@/components/contact_management/cell_renderers/ContactsTablePhoneCellRenderer.vue";
import ContactSidebar from "@/components/contact_management/sidebar/ContactSidebar.vue";
import ContactManager from "@/components/contact_management/ContactManager";
import AddContactToGroupDialog from "@/components/contact_management/AddContactToGroupDialog.vue";
import {
  errorMessage as gqlErrorMessage,
  cacheUpdate,
  appendToCache,
  deleteCacheItems
} from "@/helpers/GraphQLHelpers";
import { pluralize } from "@/helpers/StringHelpers";

export default {
  extends: Components.BaseTable,
  apollo: {
    contacts() {
      return {
        query: ContactsQuery,
        variables() {
          return this.queryVariables;
        },
        watchLoading(isLoading) {
          if (isLoading) {
            this.startLoading();
          } else if (!isLoading) {
            this.stopLoading();
          }
        },
        update(data) {
          return data.contacts.edges.map(({ node }) => {
            return node;
          });
        },
        result() {
          this.setRowData(this.contacts);
          this.stopLoading();
        },
        debounce: 250
      };
    }
  },
  props: {
    contactFormOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    contactType: {
      type: String,
      required: true
    },
    contactGroup: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    },
    contactGroups: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    search: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    const vm = this;
    return {
      contacts: [],
      contactManager: new ContactManager(),
      selection: "multiple",
      config: {
        searchable: false,
        embedded: true,
        actionBar: false
      },
      columns: [
        {
          colId: "checkbox",
          maxWidth: 50,
          checkboxSelection: true,
          suppressMenu: true,
          headerCheckboxSelection: true,
          resizable: false,
          sortable: false,
          canToggle: false
        },
        {
          headerName: "Name",
          field: "name",
          flex: 1,
          cellRenderer: "name",
          cellRendererParams: {
            onClick(contact) {
              vm.showContact(contact);
            }
          },
          canToggle: false,
          comparator(nameA, nameB) {
            return nameA.toLowerCase().localeCompare(nameB.toLowerCase());
          }
        },
        {
          headerName: "Email address",
          field: "email",
          sortable: true,
          cellRenderer: "email",
          valueGetter({ data }) {
            if (!data.emailAddresses?.length) {
              return null;
            }

            return {
              email: data.emailAddresses[0].emailAddress,
              count: data.emailAddresses.length
            };
          }
        },
        {
          headerName: "Phone number",
          field: "phoneNumber",
          sortable: true,
          cellRenderer: "phone",
          valueGetter({ data }) {
            if (!data.phoneNumbers?.length) {
              return null;
            }

            return {
              phoneNumber: data.phoneNumbers[0].phoneNumber,
              count: data.phoneNumbers.length
            };
          }
        },
        Helpers.table.actionsCell()
      ],
      components: {
        vue: {
          name: ContactsTableNameCellRenderer,
          email: ContactsTableEmailCellRenderer,
          phone: ContactsTablePhoneCellRenderer
        }
      }
    };
  },
  computed: {
    queryVariables() {
      return {
        contactType: this.contactType,
        contactGroup: this.contactGroup && { id: this.contactGroup.id },
        search: this.search || "*"
      };
    }
  },
  mounted() {
    this.startLoading();
  },
  methods: {
    addContactsToGroup(contacts) {
      this.$dialog.show(AddContactToGroupDialog, {
        props: {
          contacts: contacts
        },
        on: {
          added({ groups }) {
            const vars = {
              contactType: "ALL",
              search: "*"
            };

            groups.forEach(group => {
              vars.contactGroup = { id: group.id };
              cacheUpdate(
                cachedData => appendToCache(cachedData, "contacts", contacts),
                ContactsQuery,
                vars
              );
            });
          }
        }
      });
    },
    contactAdded(newContact) {
      const vars = {
        search: "*",
        contactGroup: null,
        contactType: "ALL"
      };

      cacheUpdate(
        cachedData => appendToCache(cachedData, "contacts", [newContact]),
        ContactsQuery,
        vars
      );

      // Only add to the table if we've not searched, and we're
      // looking at the main contact list
      if (!this.search && !this.contactGroup) {
        this.gridApi().applyTransaction({ add: [newContact] });
      }
    },
    deleteContacts(contacts) {
      return this.contactManager
        .delete(contacts)
        .then(() => {
          const label = `${pluralize("Contact", contacts.length)} deleted`;
          this.$flash.success(label);

          const vars = {
            contactType: "ALL",
            contactGroup: null,
            search: "*"
          };

          // Wipe them out from the main tables cache
          cacheUpdate(
            cachedData => deleteCacheItems(cachedData, "contacts", contacts),
            ContactsQuery,
            vars
          );

          // Now remove from the cache of each groups listing
          this.contactGroups.forEach(group => {
            vars.contactGroup = { id: group.id };
            cacheUpdate(
              cachedData => deleteCacheItems(cachedData, "contacts", contacts),
              ContactsQuery,
              vars
            );
          });

          this.gridApi().applyTransaction({ remove: contacts });
        })
        .catch(e => {
          this.$flash.error(gqlErrorMessage(e));
        });
    },
    getContextMenuItems() {
      let actions = [
        { label: "View", icon: "preview", action: "view" },
        { label: "Add to group...", icon: "add", action: "addToGroup" }
      ];

      if (this.contactGroup) {
        // We're viewing a contact group, so we need the option to remove
        actions.push({
          label: "Remove from group",
          icon: "error",
          action: "removeFromGroup"
        });
      }

      actions.push(
        { type: "divider" },
        { label: "Delete", icon: "delete", action: "delete", iconColour: "red" }
      );

      return actions;
    },
    getOtherComponents(h) {
      const vm = this;
      return [
        h(
          Components.AppSidebarContainer,
          { props: { value: !!this.currentContact } },
          [
            h(ContactSidebar, {
              props: {
                contactId: this.currentContact?.id
              },
              on: {
                close() {
                  vm.currentContact = null;
                },
                deleted(contact) {
                  const transaction = {
                    remove: [contact]
                  };
                  vm.gridApi().applyTransaction(transaction);
                  vm.currentContact = null;
                }
              }
            })
          ]
        )
      ];
    },
    contextMenuItemClicked(item, row) {
      if (item.action === "view") {
        this.currentContact = row.data;
      } else if (item.action === "delete") {
        this.$dialog
          .confirm({
            title: "Delete contact?",
            message: "Deleting a contact cannot be undone",
            danger: true,
            confirmLabel: "Delete"
          })
          .onOk(({ api }) => {
            api.hide();
            this.deleteContacts([row]);
          });
      } else if (item.action === "addToGroup") {
        this.addContactsToGroup([row.data]);
      } else if (item.action === "removeFromGroup") {
        this.removeFromGroup([row.data]);
      }
    },
    getBulkActions() {
      let actions = [
        { label: "Add to group...", icon: "padlock", action: "addToGroup" }
      ];

      if (this.contactGroup) {
        actions.push({
          label: "Remove from group",
          icon: "error",
          action: "removeFromGroup"
        });
      }

      actions.push(
        { type: "divider" },
        { label: "Delete", icon: "delete", action: "delete", iconColour: "red" }
      );

      return actions;
    },
    performBulkAction(item) {
      if (item.action === "delete") {
        this.$dialog
          .confirm({
            title: "Delete contacts?",
            message: "Deleting contacts cannot be undone",
            danger: true,
            confirmLabel: "Delete"
          })
          .onOk(({ api }) => {
            api.hide();
            const contacts = this.gridApi()
              .getSelectedNodes()
              .map(node => node.data);

            this.deleteContacts(contacts);
          });
      } else if (item.action === "addToGroup") {
        this.$dialog.show(AddContactToGroupDialog, {
          props: {
            contacts: this.gridApi()
              .getSelectedNodes()
              .map(node => node.data)
          }
        });
      } else if (item.action === "removeFromGroup") {
        this.removeFromGroup(
          this.gridApi()
            .getSelectedNodes()
            .map(node => node.data)
        );
      }
    },
    removeFromGroup(rows) {
      this.$dialog
        .confirm({
          title: "Remove from group?",
          message: "Are you sure you want to remove contacts from this group?",
          danger: true,
          confirmLabel: "Remove"
        })
        .onOk(({ api }) => {
          api.hide();
          this.contactManager
            .removeFromGroup(this.contactGroup, rows)
            .then(() => {
              this.$flash.success("Successfully removed from group");

              cacheUpdate(
                cachedData => deleteCacheItems(cachedData, "contacts", rows),
                ContactsQuery,
                this.queryVariables
              );

              this.gridApi().applyTransaction({ remove: rows });
            });
        });
    },
    showContact(contact) {
      const vm = this;

      const openSidebar = () => {
        this.$sidebar.open(ContactSidebar, {
          props: {
            contactId: contact.id
          },
          on: {
            deleted() {
              const transaction = {
                remove: [contact]
              };
              vm.gridApi().applyTransaction(transaction);
            }
          }
        });
      };

      if (this.contactFormOpen) {
        this.$dialog
          .confirm({
            title: "Discard changes?",
            message:
              "You have not saved the new contact. Are you sure you want to discard these changes?",
            confirmLabel: "Discard",
            cancelLabel: "Keep"
          })
          .onOk(({ api }) => {
            api.hide();
            openSidebar();
          });
      } else {
        openSidebar();
      }
    }
  }
};
</script>
