<template lang="pug">
  app-panel(secondary)
    app-header(size="h3") Estimates
      template(#subheader)
        | Permissions for estimates

    app-radio-button-switcher(:options="options" :value="selected" @input="optionChanged")
      template(#options)
        app-checkbox.mb-6(label="View estimates" :value="true")
        app-checkbox.mb-6(label="Edit existing estimates" :value="permissions.editEstimate" @input="permissionChanged('editEstimate', $event)")
        app-checkbox.mb-6(label="Delete estimates" :value="permissions.removeEstimate" @input="permissionChanged('removeEstimate', $event)")
        app-checkbox.mb-6(label="Create new estimates" :value="permissions.createEstimate" @input="permissionChanged('createEstimate', $event)")

</template>

<script>
import PermissionsPanelMixin from "./RolesFormPermissionsPanelMixin";

export default {
  mixins: [PermissionsPanelMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          label: "<strong>Cannot</strong> access estimates",
          value: "cannotManage"
        },
        {
          label: "<strong>Can</strong> access estimates",
          value: "canManage",
          slot: "options"
        }
      ],
      selected: "cannotManage",
      permissions: {
        viewEstimate: false,
        editEstimate: false,
        removeEstimate: false,
        createEstimate: false
      }
    };
  },
  computed: {
    selectedPermissions() {
      if (this.selected === "cannotManage") {
        return {};
      }

      return {
        permissions: { ...this.permissions },
        scope: "global"
      };
    }
  },
  mounted() {
    if (this.value.permissions.viewEstimate) {
      this.selected = "canManage";
    }
  },
  methods: {
    optionChanged(value) {
      this.permissions.viewEstimate = value === "canManage";
      this.selected = value;
      this.notifyChange();
    },
    permissionChanged(permission, value) {
      this.permissions[permission] = value;
      this.notifyChange();
    },
    notifyChange() {
      this.$emit("input", this.selectedPermissions);
    }
  }
};
</script>
