import { runMutation } from "@/helpers/GraphQLHelpers";
import DeleteUsers from "@/graphql/mutations/users/DeleteUsers.gql";
import InviteUser from "@/graphql/mutations/users/InviteUser.gql";
import UpdateCompanyUser from "@/graphql/mutations/users/UpdateCompanyUser.gql";
import ChangeUserRole from "@/graphql/mutations/users/ChangeUserRole.gql";

export default class UserManager {
  delete(users) {
    const userIds = users.map(user => user.id);

    return runMutation(DeleteUsers, { userIds });
  }

  inviteUser(user) {
    return runMutation(InviteUser, user);
  }

  updateUser(user) {
    return runMutation(UpdateCompanyUser, user);
  }

  updateUserRole(userId, roleId) {
    return runMutation(ChangeUserRole, { userId, roleId });
  }
}
