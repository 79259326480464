<script>
/* global accounting */
/* eslint-disable */

import { Components, Helpers } from "manageplaces-ui-kit";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

import Estimates from "@/graphql/queries/finance/Estimates.gql";
import EstimatesManager from "./EstimatesManager";
import EstimateStatusCellRenderer from "./EstimateStatusCellRenderer.vue";

import { estimatesMenuItems, ACTIONS } from "./EstimateMenuItems";

export default {
  extends: Components.BaseTable,
  apollo: {
    estimates: {
      query: Estimates,
      variables() {
        let vars = {};

        if (this.searchTerm) {
          vars.search = this.searchTerm;
        }

        vars.where = this.filters;

        return vars;
      },
      update({ estimates }) {
        return estimates;
      },
      result() {
        this.setRowData(this.estimates);
        this.stopLoading();
      },
      debounce: 200,
      fetchPolicy: "no-cache"
    }
  },

  data() {
    const vm = this;

    return {
      filters: {},
      estimatesManager: new EstimatesManager(),
      columns: [
        {
          headerName: "#",
          field: "number",
          cellRenderer: "link",
          cellRendererParams: {
            onClick: estimate =>
              (window.location.href = `/estimates/${estimate.id}`)
          },
          width: 110
        },
        {
          headerName: "Name",
          field: "name",
          cellRenderer: "link",
          cellRendererParams: {
            onClick: estimate =>
              (window.location.href = `/estimates/${estimate.id}`)
          }
        },
        {
          headerName: "Status",
          field: "status",
          cellRenderer: "status",
          width: 150,
          valueGetter(params) {
            return params.data.status;
          }
        },
        {
          headerName: "Purchase amount",
          field: "costTotal",
          valueGetter({ data }) {
            return vm.formatMoney(data.costTotal, data.currency);
          },
          width: 190
        },
        {
          headerName: "Sale amount",
          field: "saleTotal",
          valueGetter({ data }) {
            return vm.formatMoney(data.saleTotal, data.currency);
          },
          width: 190
        },
        {
          headerName: "Contact",
          field: "contact",
          valueGetter({ data }) {
            return data.contact?.name;
          }
        },
        Helpers.table.actionsCell()
      ],
      config: {
        actionBar: false,
        resourceType: "Estimate",
        filters: {
          status: {
            type: "option",
            title: "Status",
            description: "Filter estimates by status",
            field: "status",
            options: () => [
              {
                label: "Draft",
                value: "draft"
              },
              {
                label: "Submitted",
                value: "submitted"
              },
              {
                label: "Authorised",
                value: "authorised"
              },
              {
                label: "Rejected",
                value: "rejected"
              }
            ]
          },
          name: {
            type: "text",
            title: "Number",
            description: "Filter estimates by number",
            field: "number"
          },
          name: {
            type: "text",
            title: "Name",
            description: "Filter estimates by name",
            field: "name"
          }
        }
      },
      components: {
        vue: {
          status: EstimateStatusCellRenderer
        }
      }
    };
  },

  computed: {},

  mounted() {
    this.startLoading();
  },

  methods: {
    performFiltering(filters) {
      this.startLoading();
      this.filters = filters;
      this.$apollo.queries.estimates.refetch();
    },
    performSearch() {
      this.startLoading();
    },
    formatMoney(money, currency) {
      return accounting.formatMoney(money, this.moneyOpts(currency));
    },
    moneyOpts(currency) {
      if (currency === null) {
        return {};
      }

      return {
        symbol: currency.symbol,
        decimal: currency.decimal_mark,
        thousand: currency.thousands_separator,
        precision: currency.decimal_places
      };
    },
    getButtons(h) {
      const buttons = [
        h(
          Components.AppButton,
          { props: { primary: true }, on: { click: this.newEstimate } },
          "Add estimate"
        )
      ];

      return buttons;
    },
    newEstimate() {
      if (this.projectId) {
        window.location.href = `/projects/${this.projectId}/estimates/new`;
      } else {
        window.location.href = "/estimates/new";
      }
    },
    getContextMenuItems(row) {
      return estimatesMenuItems(row);
    },
    contextMenuItemClicked(item, row) {
      switch (item.action) {
        case ACTIONS.EDIT:
          window.location.href = `/estimates/${row.data.id}/edit`;
          break;

        case ACTIONS.DELETE:
          this.delete(row, {
            title: "Are you sure you want to delete this estimate?",
            message: "Deleting an estimate cannot be undone",
            success: "Estimate successfully deleted"
          });
          break;
      }
    },
    delete(rows, opts) {
      if (rows.constructor !== Array) {
        rows = [rows];
      }

      const estimates = rows.map(row => row.data);
      this.$dialog
        .confirm({
          title: opts.title,
          message: opts.message
        })
        .onOk(({ api }) => {
          api.hide();
          this.estimatesManager
            .delete(estimates)
            .then(() => {
              this.$flash.success(opts.success);
              this.removeRows(rows);
            })
            .catch(e => {
              this.$flash.error(gqlErrorMessage(e));
            });
        });
    },
    removeRows(rows) {
      this.gridApi().applyTransaction({
        remove: rows
      });
    }
  }
};
</script>
