import { hasSubtasks } from "@/helpers/TaskHelpers";

export const ACTIONS = {
  PREVIEW: "preview",
  ADD_SUBTASK: "addSubtask",
  ADD_SUBTASK_MILESTONE: "addSubtaskMilestone",
  MOVE: "move",
  ATTACH_DOCUMENTS: "attachDocuments",
  DELETE: "delete",
  COPY_TASK: "COPY_TASK",
  CONVERT_TO_TASK: "convertToTask",
  CONVERT_TO_MILESTONE: "convertToMilestone"
};

const _buildContextMenuItems = type => {
  return (task, permissions) => {
    let items = [{ label: "View", icon: "preview", action: ACTIONS.PREVIEW }];

    if (permissions.createTask) {
      items.push({ label: "Copy", icon: "copy", action: ACTIONS.COPY_TASK });
    }

    if (type !== "milestone" && permissions.createTask) {
      items.push(
        { type: "divider" },

        {
          label: "Add subtask",
          icon: "task",
          action: ACTIONS.ADD_SUBTASK
        },

        {
          label: "Add milestone",
          icon: "milestone",
          action: ACTIONS.ADD_SUBTASK_MILESTONE
        }
      );

      if (!hasSubtasks(task) && task.canEdit) {
        items.push(
          { type: "divider" },

          {
            label: "Convert to milestone",
            icon: "convert",
            action: ACTIONS.CONVERT_TO_MILESTONE
          }
        );
      }
    } else if (permissions.createTask) {
      items.push(
        { type: "divider" },

        {
          label: "Convert to task",
          icon: "convert",
          action: ACTIONS.CONVERT_TO_TASK
        }
      );
    }

    if (task.canEdit && permissions.createDocument) {
      items.push(
        { type: "divider" },
        {
          label: "Attach documents",
          icon: "link",
          action: ACTIONS.ATTACH_DOCUMENTS
        }
      );
    }

    if (task.canDestroy) {
      items.push(
        { type: "divider" },

        {
          label: "Delete",
          icon: "delete",
          iconColour: "red",
          action: ACTIONS.DELETE
        }
      );
    }

    return items;
  };
};

export const milestoneMenuItems = _buildContextMenuItems("milestone");
export const taskMenuItems = _buildContextMenuItems("task");

export const bulkMenuItems = [
  {
    label: "Delete",
    icon: "delete",
    action: ACTIONS.DELETE,
    iconColour: "red"
  }
];
