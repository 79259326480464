const domain = "manageplaces.com";

export const SITE_KEY = {
  app: "6Lehc9IZAAAAAAnDIk6HSl65O9-M9afzuHTvss3r",
  staging: "6LckdNIZAAAAACYTFd8ocf3UwPbQGorJpLrcRW8H",
  dev: "6LctdNIZAAAAAL4PZBrsqqiUNn9yEfJYntKtyDWB",
  local: "6LctdNIZAAAAAL4PZBrsqqiUNn9yEfJYntKtyDWB"
};

export function getSiteKey(hostname) {
  if (hostname.indexOf(domain) > -1) {
    const _prefix = hostname.replace(`.${domain}`, "");
    return SITE_KEY[_prefix];
  }

  return SITE_KEY["local"];
}

export const STRIPE_ELEMENT_STYLE = {
  base: {
    fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
    color: "#747473",
    "::placeholder": {
      color: "#ffffff"
    }
  },
  invalid: {
    color: "#ff4b4b",
    ":-webkit-autofill": {
      color: "#ff4b4b"
    }
  }
};

export const FINANCE_MANAGEMENT = "FINANCE_MANAGEMENT";
export const USER_LIMIT = "USER_LIMIT";
