/* global gantt, $, $D */

import { getLockVersion, setLockVersion } from "@/helpers/TaskHelpers";

export default {
  data() {
    return {
      showBaselines: false
    };
  },
  methods: {
    initBaselines() {
      this.setupBaselineEventListeners();
      this.addBaselineBars();
    },

    addBaselineBars() {
      gantt.addTaskLayer(task => {
        let sizes;
        let container;
        let bar;
        let label;
        let taskClosure = task;
        let hasChildren = gantt.hasChild(task.id);

        if (!(task.planned_start && task.planned_end && this.showBaselines)) {
          return false;
        }

        container = document.createElement("div");
        bar = document.createElement("div");
        label = document.createElement("div");

        sizes = gantt.getTaskPosition(
          task,
          task.planned_start,
          task.planned_end
        );

        container.className = "baseline-bar " + task.type;
        container.className += task.baseline_locked ? " locked" : "";
        container.style.left = sizes.left + "px";
        // Top is the top of the entire row, so add the spacing
        container.style.top =
          sizes.top + 5 + gantt.config.bar_height + 12 + "px";

        if (task.type === "mp_milestone") {
          container.style.width = "10px";
        } else {
          container.style.width = sizes.width + "px";
        }

        bar.className = "bar";

        label = document.createElement("div");
        label.className = "label";

        if (hasChildren) {
          label.innerHTML = "";
        } else if (task.baseline_locked) {
          label.innerHTML = '<i class="lock icon"></i>';
        } else {
          label.innerHTML = '<i class="unlock icon"></i>';
        }
        label.innerHTML += task.text + " (baseline)";

        container.appendChild(bar);
        container.appendChild(label);

        container.addEventListener("click", () => {
          if (taskClosure.baseline_locked) {
            taskClosure.baseline_locked = false;
            container.classList.remove("locked");
            const icon = container.querySelector(".label .icon");
            icon.classList.add("unlock");
            icon.classList.remove("lock");
          } else {
            taskClosure.baseline_locked = true;
            container.classList.add("locked");
            const icon = container.querySelector(".label .icon");
            icon.classList.add("lock");
            icon.classList.remove("unlock");
          }
        });

        return container;
      });
    },

    setupBaselineEventListeners() {
      gantt.attachEvent("onTaskLoading", task => {
        task.planned_start = gantt.date.parseDate(
          task.planned_start,
          gantt.config.date_format
        );
        task.planned_end = gantt.date.parseDate(
          task.planned_end,
          gantt.config.date_format
        );
        task.baseline_locked = true;

        return true;
      });
    },

    /**
     * Updates the server when the user toggles baselines on
     * or off. This persists the setting so they're shown or
     * hidden when the page is next loaded
     */
    updateProjectBaslinesSetting() {
      const data = {
        project: { show_baselines: this.showBaselines },
        lock_version: getLockVersion()
      };
      $.ajax(this.projectUpdateUrl, {
        method: "PUT",
        data: data
      })
        .done(data => {
          setLockVersion(data.lock_version);
        })
        .error(() => {
          $D.fn.loadingOverlay.show($("#gantt-container"));
        });
    }
  }
};
