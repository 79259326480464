<template lang="pug">
  div(v-if="hasIntegration")
    .ui.message(:class='messageClass')
      .header Synchronisation status
      p(v-if="lastSyncedAt")
        | Last synced at
        |
        strong {{ lastSyncedAt }}
      template
        p(v-if='integrationIsActive')
          p(v-if='isPending')
            | This
            |
            strong {{ resourceName }}
            |
            | is being synchronised with
            |
            strong {{ integration.name }}
            |
            | on
            |
            strong {{ integration.integration_name }}

          p(v-else-if='isSuccessful')
            | This
            |
            strong {{ resourceName }}
            |
            | has successfully been synchronised with
            |
            strong {{ integration.name }}
            |
            | on
            |
            strong {{ integration.integration_name }}
            |
            | at {{ lastSyncDate }}
            |
          template(v-else-if='isFailed')
            p
              | This
              |
              strong {{ resourceName }}
              |
              | could not be saved on
              |
              strong {{ integration.name }}
              |
              | on
              |
              strong {{ integration.integration_name }}
              |
              | . The following error was encountered:
              |

            ul
              li
                strong {{ syncStatus.result }}

        p(v-if='!integrationIsActive')
          | This
          |
          strong {{ resourceName }}
          |
          | synchronised with
          |
          strong {{ integration.name }}
          |
          | on
          |
          strong {{ integration.integration_name }}.
          |
          | The current integration status between
          |
          strong {{ integration.name }}
          |
          | and
          |
          strong Manageplaces
          |
          | is currently
          |
          strong {{ integration.status}}
          | . You need to reconnect it.

    .ui.divider
</template>

<script>
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import "whatwg-fetch";

/* eslint no-trailing-spaces: 0 */

import { formatDateApi } from "@/helpers/DateHelpers";

export default {
  props: {
    resourceId: {
      type: Number,
      required: true
    },
    resourceType: {
      type: String,
      required: true
    },
    resourceName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      integration: {},
      lastSyncedAt: null,
      syncStatus: null
    };
  },
  computed: {
    hasIntegration() {
      return !isNil(this.integration) && !isEmpty(this.integration);
    },
    hasSyncStatus() {
      return !isNil(this.syncStatus) && !isEmpty(this.syncStatus);
    },
    integrationIsActive() {
      return this.integration.status === "active";
    },
    isPending() {
      return this.hasSyncStatus && this.syncStatus.status === "PENDING";
    },
    isSuccessful() {
      return this.hasSyncStatus && this.syncStatus.status === "SUCCEEDED";
    },
    isFailed() {
      return (
        this.hasSyncStatus &&
        (this.syncStatus.status === "FAILED" ||
          this.syncStatus.status === "DISCARDED" ||
          this.syncStatus.status === "IGNORED" ||
          this.syncStatus.status === "SKIPPED")
      );
    },

    isExpired() {
      return (
        this.hasIntegration &&
        this.integration.status.toUpperCase() === "EXPIRED"
      );
    },
    messageClass() {
      if (!this.integrationIsActive || this.isExpired || this.isFailed) {
        return "error";
      }

      return "success";
    }
  },
  mounted() {
    this.poll();
  },
  methods: {
    poll() {
      const vm = this;

      fetch(
        `/api/integrations/sync_status?resource_id=${this.resourceId}&resource_type=${this.resourceType}`
      )
        .then(response => response.json())
        .then(response => {
          vm.integration = response.integration;
          vm.syncStatus = response.sync_status;

          if (response.last_synced_at) {
            vm.lastSyncedAt = formatDateApi(new Date(response.last_synced_at));
          }

          if (vm.isPending) {
            window.setTimeout(vm.poll, 2500);
          }
        });
    }
  }
};
</script>
