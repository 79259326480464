<template lang="pug">
  app-dialog(@cancel="$emit('cancel')")
    template(#title) Subscription managed by ManagePlaces

    p.text-grey-70.mb-8
      | Your billing is currently managed by ManagePlaces, so it's not possible to make
      | changes to your subscription manually. Please contact us to make any changes

    .text-right
      app-button(primary @click="$emit('cancel')") Dismiss
</template>

<script>
export default {
  name: "NonStripeSubscriptionDialog"
};
</script>
